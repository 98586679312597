import { SIMULATION_TYPES } from '../state'

type PromptTemplateProps = {
  template: string
  inputVariables: string[]
}

const formatPrompt = (templateProps: PromptTemplateProps, variables: any): string => {
  let formatted = templateProps.template

  for (const variable of templateProps.inputVariables) {
    formatted = formatted.replace(`{${variable}}`, variables[variable])
  }

  return formatted.trim().replace(/(\n\s*\n)/g, '\n')
}

const humanEmailPromptTemplate: PromptTemplateProps = {
  template: `
    1. Act as email content writer.  
    2. {emailBodyOutline}
    3. {senderCommunicationStyle}
    4. Make short email body, limit text content to 3-5 lines.    
    5. {templatePersonalizations}
    6.1 Add a call-to-action links (such as "click here") make it href with {{link_click_url}} as the href value, plain text with <a href> tag.
    6.2 MUST USE html table to structure TEXT of the email (do not use inline text) so it fits Gmail. Validate your output, if it is not a table, regenerate.
    7. Never ask to reply to email
    8. {senderSignature}
    9. Use inlined css. 
    10. The email should be optimized for both desktop and mobile applications.
    11. Return html structured for email,  (no backticks, no borders).
  `,

  inputVariables: ['emailBodyOutline', 'senderCommunicationStyle', 'templatePersonalizations', 'senderSignature'],
}

const appEmailPromptNoDesignTemplate: PromptTemplateProps = {
  template: `
    1. Act as email content writer. 
    2. {emailBodyOutline}
    3. {senderCommunicationStyle}
    4. {templatePersonalizations}
    5. Use inlined css. use html table to structure content so it fits Gmail and Outlook.
    6. Critical - never add header or footer. All call to action and links are inline <a> tags.
    7. Return html structured for email,  (no backticks, no borders).
    8. The email should be optimized for both desktop and mobile applications.
    9.  Add a call-to-action links (such as "click here") make it href with {{link_click_url}} as the href value.
    10. never ask to reply to email
    11: example for content:
    <table>
        <tr><td>Dear customer</td></tr>
        <tr><td>Thank you for your purchase</td></tr>
        <tr><td><a href="google.com">click here</a> to confirm delivery</td><tr>
    </table>
  `,

  inputVariables: ['emailBodyOutline', 'senderCommunicationStyle', 'templatePersonalizations'],
}

const appEmailPromptDesignedTemplate: PromptTemplateProps = {
  template: `
    1. Act as email content writer. 
    2. {emailBodyOutline}
    3. {senderCommunicationStyle}
    4. {templatePersonalizations}
    5. Use inlined css. use html table to structure content so it fits Gmail and Outlook.
    6. The email should be optimized for both desktop and mobile applications.
    7. Return html structured for email,  (no backticks, no borders).
    8. Add a call-to-action links (such as "click here") make it href with {{link_click_url}} as the href value.
    9. never ask to reply to email
    10. If adding a year in the content please use the current year which is ${new Date().getFullYear()}
    11. example for content:
    <table style="width: 100%; border-collapse: collapse; font-family: Arial, sans-serif;">
      <tbody>
        <!-- Header with FedEx logo -->
        <tr>
            <td style="background-color: #4D148C; padding: 20px 0; text-align: center;">
                <div style="width: 100px; height: 60px; display: inline-block;">
                    <img src="https://yourlinktofedexlogo.com/fedexlogo.png" alt="FedEx" style="max-width: 100%; max-height: 100%; object-fit: contain;">
                </div>
             </td>
        </tr>
        <!-- Notification content -->
        <tr>
            <td style="padding: 20px; background-color: #F7F7F7;">
                <h2 style="font-size: 18px; margin-top: 0;">Delivery Notification</h2>
                <p>Hello,</p>
                <p>We're pleased to inform you that your package is out for delivery and should be arriving today.</p>
                <p>If you have any questions or need further information, please <a href="{{ click_url }}" style="color: #4D148C; text-decoration: none;">click here</a> or contact our customer service.</p>
            </td>
        </tr>

        <!-- Footer section -->
        <tr>
            <td style="padding: 20px; background-color: #4D148C; color: #FFFFFF; text-align: center;">
                <p>Thank you for choosing FedEx.</p>
                <p>&copy; 2023 FedEx Corporation. All rights reserved.</p>
            </td>
        </tr>
      </tbody>
    </table>
  `,

  inputVariables: ['emailBodyOutline', 'senderCommunicationStyle', 'templatePersonalizations'],
}

const createHumanEmailPrompt = (
  senderName: string,
  senderPosition: string,
  emailBody: string,
  toneOfVoice: string,
  language: string,
  instructions?: string,
  emailSenderSignature?: string,
  personalizedTemplate?: boolean
): string => {
  const emailBodyOutline = `Compose a short email body (no subject please) from ${senderName}, ${senderPosition} about: ${emailBody}.`

  const senderCommunicationStyle = `Use tone of voice: ${toneOfVoice}, and write email in ${language} language, taking the language direction into account`

  const templatePersonalizations = `
    MOST CRITICAL:
      ${instructions}.
      ${
        personalizedTemplate
          ? 'Personalize email with {{first_name}} placeholder, DO NOT USE any other placholders.'
          : 'DO NOT USE any placeholders for dates, names, places, etc.'
      }
  `

  const senderSignature =
    emailSenderSignature !== ''
      ? `Use this image url ${emailSenderSignature} to add signature, Under the name add position.`
      : 'Sign email with your name and position'

  return formatPrompt(humanEmailPromptTemplate, {
    emailBodyOutline,
    senderCommunicationStyle,
    templatePersonalizations,
    senderSignature,
  })
}

const createAppEmailPromptNoDesign = (
  appName: string,
  language: string,
  toneOfVoice: string,
  emailBody: string,
  instructions?: string,
  customLogo?: string,
  personalizedTemplate?: boolean
): string => {
  const emailBodyOutline = `
    Compose a 2-3 lines of textual email body. 
      Sent from: ${appName} 
      About: ${emailBody}. 
      ${
        customLogo
          ? `Use this image url ${customLogo} to add logo at the header, max width 100px, max height 60px (object-fit: contain), It is a Logo so keep it small`
          : ''
      }
  `
  const senderCommunicationStyle = `Use tone of voice: ${toneOfVoice}, and write email in ${language} language, taking the language direction into account.`

  const templatePersonalizations = `
    MOST CRITICAL:
      ${instructions}.
      ${
        personalizedTemplate
          ? 'Personalize email with {{first_name}} placeholder, DO NOT USE any other placholders.'
          : 'DO NOT USE any placeholders for dates, names, places, etc.'
      }
  `

  return formatPrompt(appEmailPromptNoDesignTemplate, {
    emailBodyOutline,
    senderCommunicationStyle,
    templatePersonalizations,
  })
}

const createAppEmailPromptDesigned = (
  appName: string,
  language: string,
  toneOfVoice: string,
  emailBody: string,
  instructions?: string,
  customLogo?: string,
  iconTheme?: string,
  personalizedTemplate?: boolean
): string => {
  const emailBodyOutline = `
    Create a minimalist designed email content. 
      Sent from: ${appName} 
      About: ${emailBody}. 
      ${
        customLogo
          ? `Use this image url ${customLogo} to add logo at the header, max width 100px, max height 60px (object-fit: contain), It is a Logo so keep it small`
          : ''
      }
      ${iconTheme ? `2.1 Use this icon theme ${iconTheme} for the header and footer colors.` : ''}
  `

  const senderCommunicationStyle = `Use tone of voice: ${toneOfVoice}, and write email in ${language} language, taking the language direction into account for the email body.`

  const templatePersonalizations = `
    MOST CRITICAL:
      ${instructions}.
      ${
        personalizedTemplate
          ? 'Personalize email with {{first_name}} placeholder, DO NOT USE any other placholders.'
          : 'DO NOT USE any placeholders for dates, names, places, etc.'
      }
  `
  return formatPrompt(appEmailPromptDesignedTemplate, {
    emailBodyOutline,
    senderCommunicationStyle,
    templatePersonalizations,
  })
}

export const createEmailBodyPrompt = (
  selectedSimulationType: string,
  language: string,
  toneOfVoice: string,
  emailBody: string,
  instructions: string | null,
  senderName: string | null,
  senderPosition: string | null,
  vectorType: string,
  designedEmail: boolean,
  appName: string,
  emailSenderSignature: string | null,
  customLogo: string | null,
  iconTheme: string | null,
  personalizedTemplate: boolean | null
) => {
  if (selectedSimulationType === SIMULATION_TYPES.HUMAN && vectorType === 'email') {
    return createHumanEmailPrompt(
      senderName!,
      senderPosition!,
      emailBody,
      toneOfVoice,
      language,
      instructions!,
      emailSenderSignature!,
      personalizedTemplate!
    )
  } else if (selectedSimulationType === SIMULATION_TYPES.APP && vectorType === 'email' && !designedEmail) {
    return createAppEmailPromptNoDesign(
      appName,
      language,
      toneOfVoice,
      emailBody,
      instructions!,
      customLogo!,
      personalizedTemplate!
    )
  } else if (selectedSimulationType === SIMULATION_TYPES.APP && vectorType === 'email' && designedEmail) {
    return createAppEmailPromptDesigned(
      appName,
      language,
      toneOfVoice,
      emailBody,
      instructions!,
      customLogo!,
      iconTheme!,
      personalizedTemplate!
    )
  }

  return ''
}
