import React from 'react'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'

import Alert from '../../common/components/Alert/Alert'
import TooFast from '../../assets/images/too-fast.png'

type UnfinishedAlertProps = {
  open: boolean
  handleClose: () => void
}

export default function UnfinishedAlert(props: UnfinishedAlertProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <Alert
      {...props}
      buttonLabel={t('trainingCampaignDashboard.reportAlert.acknowledge')}
      dialogContent={
        <div className={classes.root}>
          <img src={TooFast} className={classes.img} alt="too-fast icon" />
          <div className={classes.flex}>
            <div>
              <Typography gutterBottom variant="h6">
                {t('trainingCampaignDashboard.reportAlert.title')}
              </Typography>
              <Typography variant="body1">{t('trainingCampaignDashboard.reportAlert.message')}</Typography>
            </div>
          </div>
        </div>
      }
    />
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: { display: 'flex' },
    flex: {
      marginLeft: 30,
      display: 'flex',
      alignItems: 'center',
    },
    img: {
      left: 0,
      bottom: 0,
      width: 140,
    },
  })
)
