import { FC } from 'react'

type DataBasePlusIconProps = {
  color: string
  size: number
}
const DataBasePlusIcon: FC<DataBasePlusIconProps> = ({ color, size }: DataBasePlusIconProps) => {
  return (
    <div style={{ width: size, height: size }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
        <path
          fill={color}
          d="M0 96C0 43 100.3 0 224 0S448 43 448 96l0 102.6c-11.2 3.2-21.9 7.4-32 12.6l0-7.7 0 .1c-17.2 8.9-38.1 16.2-61.2 21.9C317.3 234.7 272.2 240 224 240s-93.3-5.3-130.8-14.6c-23.1-5.6-44-13-61.2-21.9l0 90.6c14.5 10.6 37.9 20.7 68.9 28.3C135.5 330.9 178 336 224 336c36.5 0 70.8-3.2 100.8-8.8c-2.6 10.8-4.1 21.9-4.6 33.3c-29.4 4.8-62 7.5-96.2 7.5c-48.2 0-93.3-5.3-130.8-14.6c-23.1-5.6-44-13-61.2-21.9L32 416c0 2.4 1 7.4 8.4 14.9c7.5 7.6 19.9 15.9 37.8 23.6C113.9 469.8 165.4 480 224 480c47.4 0 90.1-6.7 123.7-17.3c6.1 9.5 13.1 18.5 20.9 26.6C329.5 503.5 279.1 512 224 512C100.3 512 0 469 0 416L0 96zm32 0l0 70.1c14.5 10.6 37.9 20.7 68.9 28.3C135.5 202.9 178 208 224 208s88.5-5.1 123.1-13.6c31-7.7 54.4-17.7 68.9-28.3L416 96c0-2.4-1-7.4-8.4-14.9c-7.5-7.6-19.9-15.9-37.8-23.6C334.1 42.2 282.6 32 224 32S113.9 42.2 78.2 57.5c-17.9 7.7-30.3 16-37.8 23.6C33 88.6 32 93.6 32 96zM352 368c0-79.5 64.5-144 144-144s144 64.5 144 144s-64.5 144-144 144s-144-64.5-144-144zm32 0c0 61.9 50.1 112 112 112s112-50.1 112-112s-50.1-112-112-112s-112 50.1-112 112zm32 0c0-8.8 7.2-16 16-16l48 0 0-48c0-8.8 7.2-16 16-16s16 7.2 16 16l0 48 48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-48 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48-48 0c-8.8 0-16-7.2-16-16z"
        />
      </svg>
    </div>
  )
}
export default DataBasePlusIcon
