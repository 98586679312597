import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { Typography, ButtonBase, Avatar, useTheme } from '@mui/material'
import { faX, faCheck } from '@fortawesome/pro-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { intToChar } from '@/common/utils/intToChar'
import { FC } from 'react'

type AnswerProps = {
  isCorrect?: boolean
  answer: string
  questionIndex: number
  isRtl?: boolean
  handleClick: () => void
  disabled: boolean
}

const Answer: FC<AnswerProps> = ({ isCorrect, disabled, questionIndex, answer, isRtl = false, handleClick }) => {
  const classes = useStyles({ isCorrect })
  const theme = useTheme()
  return (
    <div>
      <ButtonBase onClick={handleClick} disabled={isCorrect !== undefined || disabled} classes={{ root: classes.root }}>
        <Avatar
          className={classes.icon}
          style={{
            color:
              isCorrect === undefined
                ? theme.palette.common.white
                : isCorrect
                ? theme.palette.success.main
                : theme.palette.error.main,
            background: isCorrect === undefined ? undefined : theme.palette.common.white,
          }}
        >
          {isCorrect === undefined ? (
            <Typography variant={'h6'}>{intToChar(questionIndex)}</Typography>
          ) : isCorrect ? (
            <FontAwesomeIcon icon={faCheck as IconProp} />
          ) : (
            <FontAwesomeIcon icon={faX as IconProp} />
          )}
        </Avatar>
        <Typography
          gutterBottom
          id={'subject'}
          variant={'subtitle2'}
          style={{
            textAlign: isRtl ? 'right' : 'left',
            direction: isRtl ? 'rtl' : 'ltr',
          }}
        >
          {answer}
        </Typography>
      </ButtonBase>
    </div>
  )
}

const useStyles = makeStyles<Theme, { isCorrect?: boolean }>((theme) =>
  createStyles({
    root: {
      minWidth: 400,
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.shape.borderRadius,
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      padding: theme.spacing(2, 3),
      marginBottom: theme.spacing(2),
      color: ({ isCorrect }) => (isCorrect === undefined ? theme.palette.text.primary : theme.palette.common.white),
      background: ({ isCorrect }) =>
        isCorrect === undefined
          ? theme.palette.common.white
          : isCorrect
          ? theme.palette.success.main
          : theme.palette.error.main,
      '&:hover': {
        background: ({ isCorrect }) => (isCorrect === undefined ? theme.palette.grey['100'] : undefined),
      },
    },
    icon: {
      marginRight: theme.spacing(2),
    },
  })
)
export default Answer
