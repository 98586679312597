import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Box, Card, CardMedia, Grid, Typography } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { config } from '@/common/config'
import CywarenessDefaultImage from '@/assets/images/cywareness_default.png'
import { resizeImage } from '@/common/utils/cloudinary-utils'
import { PhishingSimulation } from '@/types/phishingSimulations'
import { theme } from '@/theme/theme'

type SimulationPagePreviewsProps = {
  simulation: PhishingSimulation
}

const SimulationPagePreviews: FC<SimulationPagePreviewsProps> = ({ simulation }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  function openPreviewPage() {
    window.open(`/feedback/preview/${simulation.id}`, '_blank', 'noopener noreferrer')
  }

  const buildClaudinaryImageUrl = (path: string) => {
    const imageFolderUrl =
      import.meta.env.VITE_ENV === 'dev'
        ? config.cloudinaryCoverImagesFolderDevelopmentUrl
        : config.cloudinaryCoverImagesFolderProductionUrl

    return `${imageFolderUrl}/${path}`
  }

  return (
    <Box sx={{ mt: 4 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={4}>
          <Typography variant="subtitle1" gutterBottom>
            {t('simulationLibrary.simulationPage.emailTemplate')}
          </Typography>
          <Card>
            <CardMedia
              image={resizeImage(
                buildClaudinaryImageUrl(simulation.image_cloudinary_ids.message) ?? CywarenessDefaultImage,
                800,
                600
              )}
              className={classes.media}
            />
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="subtitle1" gutterBottom>
            {t('simulationLibrary.simulationPage.landingPage')}
          </Typography>

          <Card>
            <CardMedia
              image={resizeImage(
                buildClaudinaryImageUrl(simulation.image_cloudinary_ids.landing_page) ?? CywarenessDefaultImage,
                800,
                600
              )}
              className={classes.media}
            />
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <div className={classes.feedbackTitleContainer} onClick={openPreviewPage}>
            <Typography variant="subtitle1" gutterBottom>
              {t('simulationLibrary.simulationPage.feedbackPage')}
            </Typography>
            <FontAwesomeIcon icon={faArrowUpRightFromSquare as IconProp} style={{ marginBottom: theme.spacing(0.5) }} />
          </div>

          <Card className={classes.pointer} onClick={openPreviewPage}>
            <CardMedia
              image={resizeImage(
                buildClaudinaryImageUrl(simulation.image_cloudinary_ids.micro_training) ?? CywarenessDefaultImage,
                800,
                600
              )}
              className={classes.media}
            />
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

export default SimulationPagePreviews

const useStyles = makeStyles((theme) =>
  createStyles({
    pointer: {
      cursor: 'pointer',
    },
    media: {
      height: 200,
      backgroundSize: 'contain',
      '@media (min-width: 1400px)': {
        height: 250,
      },
    },
    feedbackTitleContainer: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      gap: theme.spacing(1),
    },
    previewIcon: {
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
    },
  })
)
