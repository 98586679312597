import axios from 'axios';
import { API_URL } from '../constants';
import { useMutation } from 'react-query';
import { ServerError } from '../types';

type ExportCSVParams = {
  startDate: number;
  endDate: number;
  groups: string[];
  offices: string[];
};

async function exportCSV(params: ExportCSVParams): Promise<string> {
  const url = `${API_URL}/analytics/export_user_campaign_analytics`;
  const { data } = await axios.post(url, { ...params }, { withCredentials: true });
  return data.data;
}

export function useExportUserScores() {
  return useMutation<string, ServerError, ExportCSVParams>(exportCSV, {
    throwOnError: true,
  });
}
