import { FC } from 'react'
import { Dialog, Grid, Box, DialogContent, DialogContentText, Typography, DialogActions, Button } from '@mui/material/'
import { makeStyles, createStyles } from '@mui/styles'

import DeleteIcon from '@/assets/images/Integrations/Modal/DeleteIcon.png'

type DeleteAlertProps = {
  confirmDeleteOpen: boolean
  handleCancelDelete: () => void
  deleteIntegrationHandler: () => void
  title: string
  description: string
  subtitle?: string
}

const DeleteAlert: FC<DeleteAlertProps> = ({
  confirmDeleteOpen,
  handleCancelDelete,
  deleteIntegrationHandler,
  title,
  description,
  subtitle,
}) => {
  const classes = useStyles()

  return (
    <Dialog
      open={confirmDeleteOpen}
      onClose={handleCancelDelete}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <Grid container spacing={2} className={classes.gridWrapperDelete}>
        <Grid item xs={3}>
          <Box component="img" src={DeleteIcon} alt="Delete" className={classes.deleteImageModal} />
        </Grid>
        <Grid item xs={9}>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{ marginTop: '-20px' }}>
              <Typography
                sx={{
                  color: '#000',
                  fontSize: '18px',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  lineHeight: 'normal',
                }}
              >
                {title}
              </Typography>
              <Typography
                sx={{
                  color: '#000',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                  marginTop: '10px',
                }}
              >
                {description}
              </Typography>
              <Typography
                sx={{
                  color: '#000',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                  marginTop: '10px',
                }}
              >
                {subtitle}
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.dialogDeleteWrapper}>
            <Button onClick={deleteIntegrationHandler} className={classes.proceed}>
              Delete
            </Button>
            <Button onClick={handleCancelDelete} className={classes.cancel}>
              Cancel
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </Dialog>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    gridWrapperDelete: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(3, 4),
    },

    deleteImageModal: {
      width: '113px',
      height: '124px',
    },

    dialogDeleteWrapper: {
      marginTop: '40px',
    },

    proceed: {
      height: '31px',
      padding: '6px 22px',
      gap: '10px',
      borderRadius: '15px',
      border: '1px solid #05DBF2',
      background: '#FFF',
      color: '#000',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 'normal',
    },

    cancel: {
      height: '31px',
      padding: '6px 22px',
      gap: '10px',
      borderRadius: '15px',
      border: '1px solid #05DBF2',
      background: '#05DBF2',
      color: '#000',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 'normal',
    },
  })
)

export default DeleteAlert
