import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { Button, LinearProgress, Typography } from '@mui/material'
import LogoText from '../../assets/images/logo-blue.svg'
import { useTranslation } from 'react-i18next'
import { faLock } from '@fortawesome/pro-light-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TrainingCampaignDemoTour from './TrainingCampaignDemoTour'

type TrainingCampaignDemoViewHeaderProps = {
  videoProgress: number
  startQuiz: () => void
  quizEnabled: boolean
  hasQuiz?: boolean
  hasAnsweredQuiz: boolean
}
export default function TrainingCampaignDemoViewHeader({
  videoProgress,
  startQuiz,
  quizEnabled = false,
  hasQuiz = true,
  hasAnsweredQuiz = false,
}: TrainingCampaignDemoViewHeaderProps) {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.root}>
      <div className={classes.flexBetween}>
        <div>
          <img
            alt={'Genesis Logo'}
            src={'http://content.cywareness.io/images/logos/genesis.png'}
            height={100}
            width={'auto'}
          />
          &nbsp;
          <span style={{ fontSize: '15px', margin: '0 5px', color: 'grey' }}>powered by</span>
          &nbsp;
          <img alt={'Cywareness Logo'} src={LogoText} height={10} width={'auto'} />
        </div>
        <TrainingCampaignDemoTour />
      </div>
      <div className={classes.description}>
        <Typography variant="body1">
          {hasQuiz ? t('trainingCampaignPage.description') : t('trainingCampaignPage.descriptionNoQuiz')}
        </Typography>
      </div>
      {hasQuiz ? (
        <div className={classes.progressContainer}>
          <div className={classes.progress}>
            <div className={classes.lock}>
              <FontAwesomeIcon icon={faLock as IconProp} />
            </div>

            <LinearProgress
              color="secondary"
              variant="determinate"
              value={videoProgress}
              classes={{ colorSecondary: classes.progressBar }}
            />
          </div>
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            onClick={startQuiz}
            disabled={!quizEnabled}
          >
            {hasAnsweredQuiz ? t('trainingCampaignPage.quizCompleted') : t('trainingCampaignPage.continue')}
          </Button>
        </div>
      ) : null}
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(4, 6, 2),
    },
    flexBetween: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    description: {
      border: `1px solid ${theme.palette.cyan[500]}`,
      padding: theme.spacing(2),
      margin: theme.spacing(2, 0),
      textAlign: 'center',
    },
    progressContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    progress: {
      width: '100%',
      marginRight: theme.spacing(4),
      position: 'relative',
    },
    progressBar: {
      backgroundColor: theme.palette.grey[300],
      height: 10,
    },
    lock: {
      position: 'absolute',
      right: '10%',
      zIndex: 10,
      width: 40,
      height: 40,
      top: -16,
      borderRadius: '50%',
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.cyan[500]}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    button: {
      minWidth: 'auto',
      whiteSpace: 'nowrap',
    },
  })
)
