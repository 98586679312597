import { API_URL } from '@/api/constants'
import { DefinedInitialDataOptions, useQuery } from '@tanstack/react-query'
import { PhishingSimulation } from '@/types/phishingSimulations'
import axios from 'axios'

const getCampaignAssets = async (id: string | undefined): Promise<PhishingSimulation[]> => {
  const url = `${API_URL}/campaigns/${id}/assets/`
  const { data } = await axios.get(url, { withCredentials: true })

  return data
}

export const useCampaignAssets = (
  id: string | undefined,
  options?: Partial<DefinedInitialDataOptions<PhishingSimulation[]>>
) => {
  return useQuery<PhishingSimulation[]>({
    queryKey: ['campaign', 'assets', id],
    queryFn: () => getCampaignAssets(id),
    enabled: !!id,
    ...options,
  })
}
