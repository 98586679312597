export enum Status {
  enabled = 'enabled',
  disabled = 'disabled',
  pending = 'pending',
}

type InvitedBy = {
  email: string
  name: string
}

export enum Role {
  admin = 'admin',
  support = 'support',
}
export type Admin = {
  _id: string
  username: string
  full_name?: string
  invited_by: InvitedBy
  email: string
  name: string
  joined_date: string
  last_sign_in: string | null
  phone_number: string | null
  role: string
  status: Status
  mfa_enabled: boolean
}
