import axios from 'axios'
import { API_URL } from '@/api/constants'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { CampaignRequest } from '@/types/campaigns'

const campaignCreate = async (campaignPayload: CampaignRequest) => {
  const { data } = await axios.post(`${API_URL}/campaigns/`, campaignPayload, {
    withCredentials: true,
  })

  return data
}

export const useCampaignCreate = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: campaignCreate,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['campaigns'],
      })
    },
  })
}
