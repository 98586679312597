import axios from 'axios'
import { API_URL } from '@/api/constants'
import { useMutation, useQueryClient } from '@tanstack/react-query'

const editCampaign = async (campaignPayload: any) => {
  const { data } = await axios.patch(`${API_URL}/campaigns/${campaignPayload._id}/`, campaignPayload, {
    withCredentials: true,
  })

  return data
}

export const useEditCampaign = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: editCampaign,
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: ['campaigns'],
      })
    },
  })
}
