import axios from "axios";
import { API_URL } from "../constants";
import { useMutation, useQueryCache } from "react-query";
import { ServerError } from "../types";
import { EducationModule } from "./modules";

export type NewEducationModuleParams = {
  title: string;
  description: string;
  thumbnail?: string;
  video_tracks: {
    [language: string]: string;
  };
  subtitle_tracks: {
    [language: string]: string;
  };
  duration?: number;
  tags: string[];
  languages: string[];
  copyrights?: boolean;
};

export async function createNewTemplate(
  params: NewEducationModuleParams
): Promise<EducationModule> {
  const url = `${API_URL}/education_modules/new`;
  const { data } = await axios.post(url, params, { withCredentials: true });
  return data.data;
}

export function useNewEducationModule() {
  const queryCache = useQueryCache();
  return useMutation<EducationModule, ServerError, NewEducationModuleParams>(
    createNewTemplate,
    {
      throwOnError: true,
      onMutate: (newTemplate) => {
        const predictedTemplate: EducationModule = {
          _id: "",
          duration: 0,
          ...newTemplate,
        };
        queryCache.cancelQueries("modules");
        const previousTemplates = queryCache.getQueryData("modules") ?? [];
        //@ts-ignore
        queryCache.setQueryData("modules", (old: EducationModule[]) =>
          old ? [...old, predictedTemplate] : [predictedTemplate]
        );
        return () => queryCache.setQueryData("modules", previousTemplates);
      },
      //@ts-ignore
      onError: (err, variables, rollback) => rollback(),
      onSettled: () => {
        queryCache.invalidateQueries("modules");
      },
    }
  );
}
