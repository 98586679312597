import { FC, useEffect, useState, useCallback, useMemo } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { AllReportsVariables, ReportObjectMin, ReportsResult, useAllReports } from '@/api/reports/reports'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import DateFilter from '@/common/components/Tables/DateFilter'
import { Typography, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import PaginatingTable from '@/common/components/Tables/PaginatingTable'
import { useQueryCache } from 'react-query'
import { TABLE_SIZE_REFERENCE_KEYS, getDefaultTableSize, setDefaultTableSize } from '@/common/utils/table-size'

type ReportsTableProps = {
  pendingReports?: number
}

const ReportsTable: FC<ReportsTableProps> = ({ pendingReports }) => {
  const classes = useStyles()
  const navigateTo = useNavigate()
  const queryCache = useQueryCache()
  const [queryFilters, setQueryFilters] = useState<AllReportsVariables>({
    startDate: null,
    endDate: null,
    offset: 10,
    startIndex: 0,
    sort_by: '',
    sort_order: '',
  })
  const { resolvedData: queryData, isLoading } = useAllReports(queryFilters)
  const { t } = useTranslation()
  const theme = useTheme()
  const defaultRowsPerPage = getDefaultTableSize(TABLE_SIZE_REFERENCE_KEYS.REPORTS_TABLE)

  const handleChangeRowsPerPage = (pageSize: number) => {
    setDefaultTableSize(TABLE_SIZE_REFERENCE_KEYS.REPORTS_TABLE, pageSize)
  }

  const getStatusColor = (state: string) => {
    switch (state) {
      case 'safe':
        return theme.palette.success.main
      case 'malicious':
        return theme.palette.error.main
      default:
        return theme.palette.text.primary
    }
  }

  const columns: { Header: string; accessor: keyof ReportObjectMin }[] = useMemo(
    () => [
      {
        Header: t('responseConsole.table.date'),
        accessor: 'date_reported',
        Cell: ({ value }: { value: number }) => dayjs.unix(value).format('DD/MM/YY'),
      },
      {
        Header: t('responseConsole.table.reportedBy'),
        accessor: 'reported_by',
        disableSortBy: true,
      },
      {
        Header: t('responseConsole.table.subject'),
        cellProps: {
          align: 'left',
        },
        accessor: 'subject',
        disableSortBy: true,
      },
      {
        Header: t('responseConsole.table.status'),
        accessor: 'is_phishing',
        Cell: ({ value }) => {
          const textId = value === false ? 'safe' : value ? 'malicious' : 'pending'
          const color =
            value === false ? theme.palette.green[900] : value ? theme.palette.pink[900] : theme.palette.blueDianne[900]
          return (
            <Typography variant={'subtitle2'} color={color}>
              {t(`responseConsole.table.${textId}`)}
            </Typography>
          )
        },
      },
    ],
    [t, getStatusColor]
  )

  const [data, setData] = useState<ReportsResult['items'] | []>([])
  const [pageCount, setPageCount] = useState(0)

  useEffect(() => {
    if (queryData) {
      setData(queryData.items)
    }
  }, [queryData])

  const count = queryData?.count ?? 0

  const fetchData = useCallback(
    ({
      pageSize,
      pageIndex,
      sortBy,
      sortOrder,
    }: {
      pageSize: number
      pageIndex: number
      sortBy: string
      sortOrder: '' | 'asc' | 'desc'
    }) => {
      setQueryFilters((prevState) => ({
        ...prevState,
        sort_by: sortBy,
        sort_order: sortOrder,
        startIndex: prevState.offset * pageIndex,
        offset: pageSize,
      }))
      // Only update the data if this is the latest fetch
      setPageCount(Math.ceil(count / pageSize))
      queryCache.prefetchQuery([
        'reports',
        {
          ...queryFilters,
          startIndex: pageSize * (pageIndex + 1),
          offset: pageSize,
        },
      ])
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [count]
  )

  const onRowClick = (row) => {
    const {
      original: { _id },
    } = row
    navigateTo(`/response-report/${_id}`)
  }

  const tableTitle = `${t('responseConsole.table.reports')} ${
    pendingReports ? t('responseConsole.table.pendingCount', { count: pendingReports }) : ''
  }`

  return (
    <div className={classes.table}>
      <PaginatingTable
        noResultsTextId={t('responseConsole.table.noResults')}
        columns={columns}
        data={data}
        manualSortBy
        fetchData={fetchData}
        loading={isLoading}
        pageCount={pageCount}
        count={count}
        TableToolbar={DateFilter}
        toolbarProps={{ queryFilters, setQueryFilters, label: tableTitle }}
        onRowClick={onRowClick}
        enableCheckbox={false}
        initialPageSize={defaultRowsPerPage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    table: {
      overflow: 'hidden',
      '& .MuiTableCell-head': {
        textAlign: 'center',
        paddingLeft: 40,
        '&:first-child': {
          paddingLeft: 15,
          textAlign: 'left',
        },
      },
      '& .MuiTableCell-body': {
        textAlign: 'center',
        '&:first-child': {
          textAlign: 'left',
        },
      },
    },
  })
)

export default ReportsTable
