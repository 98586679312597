import axios from 'axios'
import { useQuery, useQueryCache } from 'react-query'

import { API_URL } from './constants'
import { ServerError } from './types'
import { ClientDetailsResult } from './client/client'

export type DashboardAnalyticsObject = {
  awareness_score: number
  awareness_last_30_days: number[]
}

async function getDashboardAnalytics() {
  const baseUrl = `${API_URL}/organizations/details/`
  const { data: dashboardAnalytics } = await axios.get(baseUrl, {
    withCredentials: true,
  })
  return { ...dashboardAnalytics }
}

export function useDashboardAnalytics() {
  const queryCache = useQueryCache()
  const clientData: ClientDetailsResult | undefined = queryCache.getQueryData('organizations')
  return useQuery<DashboardAnalyticsObject, ServerError>('organizations', getDashboardAnalytics, {
    enabled: !!clientData?.onboarding,
  })
}
