import { useState, useMemo } from 'react'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import {
  Chip,
  Grid,
  InputBase,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
  Badge,
  AccordionDetails,
  Accordion,
  AccordionSummary,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import LoadingContainer from '../../common/components/LoadingContainer/LoadingContainer'
import { languages } from '../../assets/locales/languages'
import { faTag, faChevronDown } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { useQueryCache } from 'react-query'
import { EducationModule } from '../../api/education-modules/modules'
import { startCase } from 'lodash'
import moment from 'moment'
import VideoPlayerV2 from '../../common/components/VideoPlayerV2/VideoPlayerV2'

type ModulePickerProps = {
  modules: EducationModule[]
  selectModule: (moduleId: string) => void
  selectedModule: EducationModule
}

export default function ModulePicker({ modules, selectedModule, selectModule }: ModulePickerProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const queryCache = useQueryCache()
  const [searchFilter, setSearchFilter] = useState('')
  const [selectedTopic, setSelectedTopic] = useState('all')

  function showNewBadge(module: EducationModule) {
    if (!module.updated_at) return false
    const lastMonth = moment().subtract(1, 'month').unix()
    return lastMonth < module.updated_at
  }

  function handleSelectTemplate(moduleId: string) {
    selectModule(moduleId)
  }

  function handleSearch(value: string | undefined) {
    setSearchFilter(value ? value.toLowerCase() : '')
  }

  function handleSelectTopic(topic: string) {
    setSelectedTopic(topic)
  }

  const groupedModulesByTopic: {
    [topic: string]: EducationModule[]
  } = useMemo(() => {
    const groupedModules = {}
    modules.forEach((video) => {
      for (const tag of video.tags) {
        if (!groupedModules[tag]) {
          groupedModules[tag] = []
        }
        groupedModules[tag].push(video)
      }
    })
    return groupedModules
  }, [modules])

  const filteredModules = modules.filter(
    (module) =>
      module.title.toLowerCase().match(searchFilter) && (selectedTopic === 'all' || module.tags.includes(selectedTopic))
  )
  const topics = ['all', ...Object.keys(groupedModulesByTopic)] || []

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Paper className={classes.modulePaper}>
          <Typography className={classes.title} variant={'h6'}>
            {t('createTrainingCampaign.modules')}
          </Typography>
          <div className={classes.search}>
            <InputBase
              placeholder={searchFilter || 'Search'}
              value={searchFilter || ''}
              onChange={(e) => {
                handleSearch(e.target.value || undefined)
              }}
            />
          </div>
          <div className={classes.topicsFilter}>
            <Accordion defaultChecked>
              <AccordionSummary
                expandIcon={<FontAwesomeIcon icon={faChevronDown as IconProp} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography gutterBottom variant={'subtitle2'}>
                  {t('createTrainingCampaign.module.topics')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className={classes.chipArray}>
                  {topics.map((topic) => (
                    <Chip
                      onClick={() => handleSelectTopic(topic)}
                      variant={topic === selectedTopic ? 'default' : 'outlined'}
                      clickable
                      label={startCase(topic)}
                    />
                  ))}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
          <List>
            {filteredModules ? (
              <List className={classes.list}>
                {filteredModules.length > 0 ? (
                  filteredModules.map((module) => (
                    <ListItem
                      classes={{
                        root: classes.selectedTemplate,
                        selected: classes.selected,
                      }}
                      button
                      selected={selectedModule?._id === module._id}
                      onClick={() => handleSelectTemplate(module._id)}
                      onMouseEnter={() => queryCache.prefetchQuery(['templates', module._id])}
                    >
                      <ListItemText
                        primary={
                          <div>
                            {module?.title}{' '}
                            {showNewBadge(module) ? (
                              <Badge badgeContent={'New'} color="primary">
                                {' '}
                                <div style={{ width: 20 }} />
                              </Badge>
                            ) : null}{' '}
                          </div>
                        }
                        secondary={`${t('createTrainingCampaign.module.languages')}: ${module.languages
                          .map((lng) => languages[lng]?.name)
                          .join(', ')}`}
                      />
                      <ListItemSecondaryAction>
                        <Tooltip title={module.tags.join(', ')} placement={'right'}>
                          <FontAwesomeIcon icon={faTag as IconProp} fontSize={20} />
                        </Tooltip>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))
                ) : (
                  <div className={classes.noPreview}>
                    <Typography variant={'body2'} color={'textSecondary'}>
                      {t('createTrainingCampaign.module.noModules')}
                    </Typography>
                  </div>
                )}
              </List>
            ) : (
              <LoadingContainer />
            )}
          </List>
        </Paper>
      </Grid>
      <Grid item xs={12} md={8}>
        <Paper className={classes.paper}>
          {selectedModule ? (
            <VideoPlayerV2 videoTracks={selectedModule.video_tracks} subtitleTracks={selectedModule.subtitle_tracks} />
          ) : (
            <LoadingContainer />
          )}
        </Paper>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      height: 600,
      padding: theme.spacing(2, 0),
      overflow: 'hidden',
    },
    modulePaper: {
      height: 600,
      padding: theme.spacing(2, 0),
      overflow: 'auto',
    },
    title: {
      marginLeft: theme.spacing(1.5),
    },
    list: {
      height: '100%',
      overflowY: 'auto',
      maxHeight: 400,
      paddingBottom: theme.spacing(20),
    },
    selectedTemplate: {
      '&$selected': {
        // backgroundColor: theme.palette.blueGray[800],
        '&:hover': {
          // backgroundColor: theme.palette.blueGray[800],
        },
      },
    },
    selected: {},
    avatarContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color: theme.palette.common.white,
      marginRight: theme.spacing(1),
    },
    tabsContainer: {
      display: 'flex',
      justifyContent: 'center',
      '& .MuiTab-textColorPrimary.Mui-selected': {
        color: theme.palette.common.white,
      },
    },
    search: {
      color: theme.palette.text.secondary,
      padding: '0px 16px',
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${theme.palette.divider}`,
      width: '95%',
      margin: theme.spacing(1),
    },
    noPreview: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
    topicsFilter: { padding: theme.spacing(0, 1) },
    chipArray: { marginTop: theme.spacing(1) },
  })
)
