import { TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { useAllBrands } from '@/api/brands/brands'
import { useAiSimulationContext } from '../state'
import makeStyles from '@mui/styles/makeStyles'
import { FC } from 'react'

const AppNameSelection: FC<{ setAppNameLiftingState: (appNameLiftingState: string) => void }> = ({
  setAppNameLiftingState,
}) => {
  const {
    setAppName,
    setCustomLogo,
    setButtonColor,
    setButtonTextColor,
    setIconTheme,
    setAppLandingTemplate,
    appName,
  } = useAiSimulationContext()

  const { data: brands } = useAllBrands()
  const classes = useStyles()

  return (
    <Autocomplete
      classes={{
        clearIndicator: classes.clearIndicator,
      }}
      onInputChange={(event, newInputValue) => {
        setAppNameLiftingState(newInputValue)
        setAppName(newInputValue)
      }}
      onChange={(event, newValue: any) => {
        if (newValue) {
          setAppNameLiftingState('')
          setAppName(newValue.label)
          setCustomLogo(newValue.icon)
          setIconTheme(newValue.icon_theme)
          setButtonColor(newValue.button_color)
          setButtonTextColor(newValue.button_text_color)
          setAppLandingTemplate(newValue.landing_page)
        }
      }}
      size="small"
      fullWidth
      freeSolo
      clearIcon={null}
      id="free-solo-appName"
      options={brands || []}
      getOptionLabel={(option: any) => option.label || ''}
      renderOption={(props, option) => (
        <li {...props}>
          <img alt="" src={option.icon} style={{ width: '50px', height: 'auto', marginRight: '10px' }} />
          {option.label}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...(params as any)}
          label={appName ? 'App Name' : ''}
          placeholder="App Name"
          InputProps={{ ...params.InputProps }}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '10px',
            },
          }}
        />
      )}
    />
  )
}

const useStyles = makeStyles({
  clearIndicator: {
    '& > div': {
      display: 'none !important',
    },
  },
})
export default AppNameSelection
