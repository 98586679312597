import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faCircleExclamation } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, CircularProgress, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { string } from 'yup'

import { useRequestPasswordReset } from '@/api/auth/request-password-reset'
import Form from '@/common/components/Form/Form'
import useForm, { Field } from '@/common/components/Form/useForm'

const fields: Field[] = [
  {
    name: 'username',
    label: 'resetPassword.form.username',
    validationRule: string().required('resetPassword.errors.missingEmail').email('resetPassword.errors.invalidEmail'),
    type: 'email',
    autoComplete: 'email',
    required: true,
    autoFocus: true,
  },
]

export default function ResetPassword() {
  const { t } = useTranslation()
  const classes = useStyles()
  const navigateTo = useNavigate()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const { mutateAsync: requestPasswordReset, error, isPending, isError } = useRequestPasswordReset()

  async function handleResetPassword(formValues) {
    const recaptcha = (executeRecaptcha && (await executeRecaptcha('forgot_password'))) || ''
    await requestPasswordReset({ ...formValues, recaptcha })
    navigateTo(`/reset-password/verify/${formProps.values.username}`)
  }

  const { formProps, handleSubmit } = useForm({
    fields,
    onSubmit: handleResetPassword,
  })

  return (
    <div className={classes.container}>
      <Typography variant="h5">{t('resetPassword.resetPassword')}</Typography>
      <Typography variant="subtitle1">{t('resetPassword.enterUsername')}</Typography>
      <div className={classes.form}>
        {isError && (
          <div className={classes.errorMessage}>
            <FontAwesomeIcon icon={faCircleExclamation as IconProp} className={classes.errorIcon} />
            <Typography variant="subtitle2" color={'inherit'}>
              {error?.response?.data?.message}
            </Typography>
          </div>
        )}
        <Form {...formProps} />

        <Button onClick={handleSubmit} fullWidth variant="contained" color="primary" className={classes.submit}>
          {isPending ? <CircularProgress color={'secondary'} size={24} /> : t('resetPassword.sendEmail')}
        </Button>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorMessage: {
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.error.main,
    paddingBottom: theme.spacing(2),
  },
  errorIcon: {
    marginRight: 4,
  },
  spinnerContainer: {
    padding: '8px 11px',
  },
}))
