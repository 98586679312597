import React, { useCallback, useMemo, useState } from "react";
import Lottie from "react-lottie";

type LottieConfig = {
  loop: boolean;
  autoplay: boolean;
  animationData: any;
  rendererSettings: {
    preserveAspectRatio: string;
  };
};
type LottieProps = {
  animationData: any;
  options?: LottieConfig;
  height?: number;
  width?: number;
};

export default function UseLottie({
  animationData,
  options,
  height,
  width,
}: LottieProps) {
  const defaultOptions = useMemo(
    () => ({
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    }),
    [animationData]
  );

  const [isPaused, setIsPaused] = useState(false);
  const [isStopped, setIsStopped] = useState(false);

  const handlePlay = useCallback(() => {
    setIsStopped(false);
    setIsPaused(false);
  }, []);

  const handleStop = useCallback(() => {
    setIsStopped(true);
  }, []);

  const handlePause = useCallback(() => {
    setIsPaused(!isPaused);
  }, [isPaused]);

  const LottieAnimation = useMemo(() => {
    return (
      <div className="controlled">
        <Lottie
          options={options ? { ...defaultOptions, ...options } : defaultOptions}
          height={height ? height : 400}
          width={width ? width : 400}
          isStopped={isStopped}
          isPaused={isPaused}
        />
      </div>
    );
  }, [options, defaultOptions, height, width, isStopped, isPaused]);

  return { handlePause, handlePlay, handleStop, LottieAnimation } as const;
}
