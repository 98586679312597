import axios from 'axios'
import { useQuery } from 'react-query'
import { API_URL } from '../constants'
import { ServerError } from '../types'

export type CurrentUser = {
  id: string
  username: string
  full_name?: string
  mfa_enabled?: boolean | null
  current_client_id: string
  has_multiple_clients: boolean
  saved_assets?: string[]
  saved_asset_packages?: string[]
  role?: string
  accepted_latest_terms: boolean
}

export async function getCurrentUser() {
  const url = `${API_URL}/profile/`
  const { data } = await axios.get(url, { withCredentials: true })
  return data
}

export function useCurrentUser() {
  return useQuery<CurrentUser, ServerError>('user', getCurrentUser, {
    retry: false,
    notifyOnStatusChange: false,
    refetchOnWindowFocus: false,
  })
}
