import { useMemo } from 'react'
import { ResponsiveBar } from '@nivo/bar'
import { Typography, useMediaQuery, useTheme } from '@mui/material'
import { sortBy } from 'lodash'
import NoResultsContainer from '../../common/components/NoResultsContainer/NoResultsContainer'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment'
import boxImage from '@/assets/images/box.svg'

type ISODateString = string

type EventsBarChartProps = {
  campaignStart: ISODateString
  campaignEnd: ISODateString
  data: {
    [userId: string]: {
      started?: number
      completed?: number
    }
  }
}

export default function EventsBarChart({ data, campaignStart, campaignEnd }: EventsBarChartProps) {
  const theme = useTheme()
  const classes = useStyles()
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('xl'))
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))

  const formattedData = useMemo(() => {
    const objects: { [date: string]: { [key: string]: number } } = {}
    const startDate = moment(campaignStart).startOf('day')
    const endDate = moment(campaignEnd).startOf('day')
    const diff = endDate.diff(startDate, 'days')
    const lengthDays = diff >= 7 ? diff : 7
    for (let i = 0; i <= lengthDays; i++) {
      const date = moment(startDate).add(i, 'days').format('DD/MM')
      objects[date] = { started: 0, completed: 0 }
    }
    Object.entries(data).forEach(([userId, value]) => {
      Object.entries(value).forEach(([key, timestamp]) => {
        if (!timestamp) return

        const date = moment(timestamp).format('DD/MM')

        if (objects[date]) {
          objects[date][key] = objects[date][key] ? objects[date][key] + 1 : 1
        } else {
          objects[date] = { [key]: 1 }
        }
      })
    })

    const unsortedData = Object.entries(objects).map(([key, value]) => {
      return {
        date: key,
        started: value.started && value.completed ? value.started - value.completed : 0,
        completed: value.completed ? value.completed : 0,
      }
    })
    return sortBy(unsortedData, ({ date }) => moment(date, 'DD/MM').unix())
  }, [campaignEnd, campaignStart, data])

  function idToColor(id: string) {
    switch (id) {
      case 'started':
        return '#484e8e'
      case 'completed':
        return '#41c5c9'
      default:
        return theme.palette.cyan[500]
    }
  }

  function dataIsNotEmpty(data) {
    let sum = 0
    data.forEach((entry) => {
      sum = sum + entry.started
      sum = sum + entry.completed
    })
    return sum > 0
  }

  return dataIsNotEmpty(formattedData) ? (
    <>
      <ResponsiveBar
        groupMode="stacked"
        data={formattedData}
        keys={['completed', 'started']}
        indexBy="date"
        margin={{ top: 20, right: 8, bottom: 50, left: 8 }}
        theme={{
          tooltip: {
            container: { background: 'transparent', boxShadow: 'none' },
          },
        }}
        colors={['#41c5c9', '#484e8e']}
        enableLabel={false}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisLeft={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          legendPosition: 'middle',
          legendOffset: 32,
          tickRotation: isSmallScreen ? -45 : 0,
        }}
        enableGridY={false}
        labelSkipWidth={12}
        labelSkipHeight={12}
        borderRadius={0}
        padding={isLargeScreen ? 0.75 : 0.75}
        // innerPadding={3}
        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'top-right',
            direction: 'row',
            symbolShape: 'circle',
            justify: false,
            translateX: 20,
            translateY: -20,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 10,

            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        tooltip={(value) => {
          return (
            <div className={classes.tooltip}>
              <Typography variant={'caption'} color={'inherit'} noWrap>
                {moment(`${value.data.date}/${new Date().getFullYear()}`, 'DD-MM-YYYY').format('MMM DD')}
              </Typography>
              <div className={classes.flexContainer}>
                <div
                  style={{
                    height: 10,
                    width: 10,
                    background: idToColor(value.id.toString()),
                    marginRight: 8,
                  }}
                />
                <Typography variant={'subtitle2'} color={'inherit'} noWrap>
                  {value.id}:{' '}
                  {value.id === 'started' ? Number(value.value) + Number(value.data.completed) : value.value}
                </Typography>
              </div>
            </div>
          )
        }}
      />
    </>
  ) : (
    <NoResultsContainer text={'trainingCampaignDashboard.eventsChart.noResults'} image={boxImage} />
  )
}

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    tooltipContainer: {
      padding: theme.spacing(1, 2),
      borderRadius: theme.shape.borderRadius,
    },
    tooltip: {
      background: theme.palette.background.secondaryGradient,
      borderRadius: 100,
      minWidth: 150,
      padding: theme.spacing(1, 2),
      marginBottom: theme.spacing(1),
      color: theme.palette.common.white,
      fontWeight: 700,
      boxShadow: theme.shadows[6],
      boxSizing: 'border-box',
      border: `1px solid rgba(255,255,255,0.6)`,
    },
    flexContainer: {
      display: 'flex',
      flex: '1 1 auto',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
  })
)
