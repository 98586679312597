import { useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { EducationModule } from '../../../api/education-modules/modules'
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Tags from '../../../common/components/Tags/Tags'
import { languages } from '../../../assets/locales/languages'
import { faHeart as heartIcon, faPenToSquare, faTrash, faX } from '@fortawesome/pro-light-svg-icons'
import { faHeart as fullHeartIcon } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { useAuth } from '../../../context/Auth'
import VideoPlayerV2 from '../../../common/components/VideoPlayerV2/VideoPlayerV2'

type VideoPreviewModalProps = {
  video?: EducationModule
  handleClose: () => void
  open: boolean
  launchModule: (video: EducationModule) => void
  deleteModule: (video: EducationModule) => void
  saveModule: (video: EducationModule) => void
  editModule: (video: EducationModule) => void
  isSaved: boolean
}
export default function VideoPreviewModal({
  video,
  open,
  handleClose,
  launchModule,
  editModule,
  deleteModule,
  saveModule,
  isSaved,
}: VideoPreviewModalProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const { user } = useAuth()

  function handleLike() {
    if (video) {
      saveModule(video)
    }
  }

  function handleDelete() {
    if (video) {
      deleteModule(video)
      handleClose()
    }
  }

  function handleLaunch() {
    if (video) {
      launchModule(video)
      handleClose()
    }
  }

  function handleEdit() {
    if (video) {
      editModule(video)
      handleClose()
    }
  }

  return video ? (
    <Dialog className={classes.dialog} maxWidth={'lg'} fullWidth open={open} onClose={handleClose} scroll="body">
      <DialogTitle className={classes.header}>
        <div className={classes.flex}>
          <IconButton className={classes.closeButton} onClick={handleClose} size="large">
            <FontAwesomeIcon icon={faX as IconProp} />
          </IconButton>
          <Typography variant="subtitle2" color="inherit">
            <span style={{ color: theme.palette.grey[500] }}>{t('videoLibrary.topics')}</span> {'\u2022'}{' '}
            {video.tags.join(', ')}
          </Typography>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className={classes.flexContainer}>
          <Typography gutterBottom variant="h6">
            {video.title}
          </Typography>
          <Button className={classes.launchButton} size="small" onClick={handleLaunch} variant="contained">
            {t('videoLibrary.launch')}
          </Button>
        </div>

        <div className={classes.video}>
          <VideoPlayerV2 subtitleTracks={video.subtitle_tracks} videoTracks={video.video_tracks} />
        </div>
        <div className={classes.flexContainer}>
          <div className={classes.flex}>
            <div className={classes.stat}>
              <Typography variant="subtitle2" className={classes.statKey}>
                {t('videoLibrary.language')}:{' '}
              </Typography>
              <Tags
                useChips={false}
                tags={video.languages.map((language) => ({
                  label: languages[language]?.nativeName,
                  color: 'transparent',
                  textColor: theme.palette.text.secondary,
                }))}
                limit={2}
              />
            </div>
            <div className={classes.stat}>
              <Typography variant="subtitle2" className={classes.statKey}>
                {t('videoLibrary.subtitles')}:{' '}
              </Typography>
              <Tags
                useChips={false}
                tags={Object.keys(video.subtitle_tracks).map((track) => ({
                  label: languages[track]?.nativeName,
                  color: 'transparent',
                  textColor: theme.palette.text.secondary,
                }))}
                limit={2}
              />
            </div>

            <div className={classes.stat}>
              <Typography variant="subtitle2" className={classes.statKey}>
                {t('videoLibrary.topics')}:{' '}
              </Typography>
              <Tags
                useChips={false}
                tags={video.tags.map((tag) => ({
                  label: tag,
                  color: 'transparent',
                  textColor: theme.palette.text.secondary,
                }))}
                limit={2}
              />
            </div>
            {video.client_id === user?.current_client_id ? (
              <div className={classes.stat}>
                <Typography variant="subtitle2" className={classes.statKey}>
                  {t('videoLibrary.source')}:{' '}
                </Typography>
                <Typography variant="body2">{t('videoLibrary.custom')}</Typography>
              </div>
            ) : null}
          </div>
          <div className={classes.actionsContainer}>
            <IconButton
              disabled={video.client_id !== user?.current_client_id}
              className={classes.button}
              onClick={handleEdit}
              size="small"
              color={'inherit'}
            >
              <FontAwesomeIcon icon={faPenToSquare as IconProp} fontSize={22} />
            </IconButton>
            <IconButton className={classes.button} onClick={handleLike} size="small" color={'inherit'}>
              <FontAwesomeIcon
                icon={(isSaved ? fullHeartIcon : heartIcon) as IconProp}
                fontSize={22}
                color={theme.palette.pink[500]}
              />
            </IconButton>
            <IconButton
              disabled={video.client_id !== user?.current_client_id}
              className={classes.button}
              onClick={handleDelete}
              size="small"
              color={'inherit'}
            >
              <FontAwesomeIcon icon={faTrash as IconProp} fontSize={22} />
            </IconButton>
          </div>
        </div>
        <div className={classes.footer}>
          <Typography variant="body2">{video.description}</Typography>
          {video.quizzes?.length ? (
            <Typography variant="body2">
              {t('createVideoModal.includesQuiz')}:{' '}
              <Button
                variant="text"
                color="primary"
                onClick={() => window.open(`/content/quiz/preview/${video.quizzes?.[0]?._id}`, '_blank')}
              >
                {t('createVideoModal.previewQuiz')}
              </Button>
            </Typography>
          ) : null}
        </div>
      </DialogContent>
    </Dialog>
  ) : null
}

const useStyles = makeStyles((theme) =>
  createStyles({
    dialog: {
      '& .MuiDialog-paper': {
        border: 'none',
      },
    },
    header: {
      backgroundColor: theme.palette.blueGray[900],
      color: theme.palette.cyan[500],
      padding: theme.spacing(1),
    },
    flexContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(2),
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
    },
    launchButton: {
      minWidth: 100,
    },
    closeButton: {
      marginRight: theme.spacing(1),
      color: theme.palette.cyan[500],
    },
    video: { marginBottom: theme.spacing(0), minHeight: 300 },
    footer: {},
    description: {},
    stat: {
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(2),
    },
    statKey: { marginRight: theme.spacing(1) },
    button: { marginLeft: theme.spacing(1) },
    actionsContainer: { display: 'flex', alignItems: 'center' },
    tooltip: {
      padding: theme.spacing(1, 0.5),
    },
  })
)
