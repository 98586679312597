import { Box, Button, Typography, styled } from '@mui/material'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { theme } from '@/theme/theme'
import { withCommas } from '../utils'

const PlanPickerCardSection: FC<{
  year: number
  cost: number
  discount: number
  membersCount: number
  campaignsCount: number
  relatedtPlan: string
  setCurrentPlan: () => void
  setCrrentSubscriptionTime: () => void
  selected: boolean
}> = ({
  year,
  cost,
  discount,
  membersCount,
  campaignsCount,
  relatedtPlan,
  setCurrentPlan,
  setCrrentSubscriptionTime,
  selected,
}) => {
  const { t } = useTranslation()
  return (
    <Box display="flex">
      <StyledLeftPart
        color={
          selected
            ? theme.palette.blueGray[900]
            : year === 1
            ? theme.palette.blueDianne[700]
            : year === 2
            ? theme.palette.blueDianne[800]
            : theme.palette.blueDianne[900]
        }>
        <Typography fontSize={20} fontWeight={600} color={theme.palette.white}>
          {year} {t('pricingCalculator.planPicker.yearSubscription')}
        </Typography>
        <Typography fontSize={14} fontWeight={600} color={theme.palette.white}>
          ${cost * (relatedtPlan === 'Lite' ? campaignsCount : 1)} {t('pricingCalculator.planPicker.perMember')}
        </Typography>
        {discount && (
          <Typography fontSize={14} fontWeight={400} color={theme.palette.cyan[500]}>
            <b>{discount}%</b> {t('pricingCalculator.planPicker.discount')}
          </Typography>
        )}
      </StyledLeftPart>
      <StyledRightPart selected={selected}>
        <Typography fontSize={16} fontWeight={600} color={selected ? theme.palette.white : ''}>
          {t('pricingCalculator.planPicker.total')}
          {!!discount && (
            <s style={{ color: selected ? theme.palette.white : theme.palette.grey[400] }}>
              ${withCommas(cost * membersCount * campaignsCount)}
            </s>
          )}
        </Typography>
        <Typography fontSize={20} fontWeight={600} color={selected ? theme.palette.white : ''}>
          ${withCommas(cost * membersCount * campaignsCount * (1 - discount / 100))}{' '}
          <span style={{ fontWeight: 400, fontSize: 16 }}>{t('pricingCalculator.planPicker.perYear')}</span>
        </Typography>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            paddingTop: theme.spacing(1),
          }}>
          <Button
            variant="contained"
            sx={{
              background: theme.palette.cyan[600],
              borderRadius: 20,
              boxShadow: 'none',
              color: theme.palette.blueGray[900],
              textTransform: 'none',
              '&:hover': {
                boxShadow: 'none',
              },
              '&.Mui-active': {
                boxShadow: 'none',
              },
            }}
            onClick={() => {
              setCurrentPlan()
              setCrrentSubscriptionTime()
            }}>
            {selected ? 'Selected' : year + (year === 1 ? ' Year' : ' Years') + ' Plan'}
          </Button>
        </Box>
      </StyledRightPart>
    </Box>
  )
}

const StyledLeftPart = styled(Box)(({ color }: { color: string }) => ({
  background: color,
  padding: theme.spacing(1),
  width: '140px',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}))

const StyledRightPart = styled(Box)(({ selected }: { selected: boolean }) => ({
  background: selected ? theme.palette.blueGray[900] : theme.palette.white,
  padding: theme.spacing(2),
  width: '220px',
  paddingLeft: selected ? theme.spacing(4) : theme.spacing(2),
  borderBottom: `1px solid ${selected ? theme.palette.blueGray[900] : theme.palette.blue[100]}`,
}))
export default PlanPickerCardSection
