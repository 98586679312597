import Axios from 'axios'
import { DefinedInitialDataOptions, useQuery } from '@tanstack/react-query'

import { API_URL } from '@/api/constants'
import { OrganizationListItem } from '@/types/organizations'

const getOrganizations = async () => {
  const url = `${API_URL}/organizations/`
  const { data } = await Axios.get(url, { withCredentials: true })

  return data
}

const getOrganization = async (id: string) => {
  const url = `${API_URL}/organizations/${id}`
  const { data } = await Axios.get(url, { withCredentials: true })

  return data
}

export const useOrganizations = (options?: Partial<DefinedInitialDataOptions<OrganizationListItem[]>>) => {
  return useQuery<OrganizationListItem[]>({
    queryKey: ['organizations'],
    queryFn: getOrganizations,
    ...options,
  })
}

export const useOrganization = (id: string, options?: Partial<DefinedInitialDataOptions<OrganizationListItem>>) => {
  return useQuery<OrganizationListItem>({
    queryKey: ['organization', id],
    queryFn: () => getOrganization(id),
    ...options,
  })
}
