import axios from "axios";
import { API_URL } from "../constants";
import { useMutation, useQueryCache } from "react-query";
import { ServerError } from "../types";
import { CurrentUser } from "../profile/current-user";

type MfaParams = {
  token: string;
};

async function verifyMfa(params: MfaParams): Promise<CurrentUser> {
  const url = `${API_URL}/auth/verify-mfa/`;
  const { data } = await axios.post(
    url,
    { ...params },
    { withCredentials: true }
  );
  return data;
}

export function useMFA() {
  const queryCache = useQueryCache();
  return useMutation<CurrentUser, ServerError, MfaParams>(verifyMfa, {
    throwOnError: true,
    onSuccess: (data) => {
      queryCache.setQueryData("user", data);
    },
  });
}
