import { API_URL } from '../constants'
import axios from 'axios'
import { usePaginatedQuery } from 'react-query'
import { ServerError } from '../types'
import { User } from '../users/users'

export type GroupUsersQueryVariables = {
  _id: string
  first_name: string
  last_name: string
  email: string
  offices: string[]
  offset: number
  startIndex: number
}

export type GroupUsersResponse = {
  count: number
  items: User[]
}

export async function getGroupUsers(
  key,
  { _id = '', first_name = '', last_name = '', email = '', offices = [], offset = 5, startIndex = 0 }
) {
  const url = `${API_URL}/groups/users/${_id}`
  const { data } = await axios.post(
    url,
    { first_name, last_name, email, offices, offset, startIndex },
    { withCredentials: true }
  )
  return data.data
}

export function useGroupUsers(variables: GroupUsersQueryVariables) {
  return usePaginatedQuery<GroupUsersResponse, ServerError>(
    [['group', 'users', variables._id], { ...variables }],
    getGroupUsers
  )
}
