import { useDynamicGroups } from '@/api/dynamic-groups/get'
import LoadingContainer from '@/common/components/LoadingContainer/LoadingContainer'
import { FC } from 'react'
import DynamicGroupsTable from './DynamicGroupsTable'
import { Box } from '@mui/material'

type DynamicGroupsTabProps = {
  queryFilters: {
    search: string
    groups: string[]
    offices: string[]
    status: boolean[]
    limit: number
    skip: number
    sort_order: string
    sort_by: string
    scores: string[]
  }
}

const DynamicGroupsTab: FC<DynamicGroupsTabProps> = ({ queryFilters }) => {
  const { data: dynamicGroups = [], isLoading } = useDynamicGroups()
  if (isLoading) {
    return (
      <Box mt={2}>
        <LoadingContainer />
      </Box>
    )
  }

  return <DynamicGroupsTable dynamicGroups={dynamicGroups} queryFilters={queryFilters} />
}

export default DynamicGroupsTab
