import axios from "axios";
import { API_URL } from "../constants";
import { useMutation } from "react-query";
import { ServerError } from "../types";

type ResetPasswordParams = {
  username: string | undefined;
  password: string;
  token: string | undefined;
};

type ResetPasswordResponse = {};

async function resetPassword(
  params: ResetPasswordParams
): Promise<ResetPasswordResponse> {
  const url = `${API_URL}/auth/reset-password/`;
  const { data } = await axios.post(url, { ...params });
  return data;
}

export function useResetPassword(callback) {
  return useMutation<ResetPasswordResponse, ServerError, ResetPasswordParams>(
    resetPassword,
    {
      onSuccess: callback,
    }
  );
}
