import { Box, styled } from '@mui/material'

export const CheckboxWrapper = styled(Box)(() => ({
  '& span': {
    fontSize: '12px',
  },
}))
export const Lock = styled(Box)(() => ({
  position: 'absolute',
  right: '54px',
  transform: 'translate(0px, -30px)',
}))

export const FieldsWrapper = styled(Box)(() => ({
  padding: '10px',
  display: 'flex',
  gap: 16,
  flexDirection: 'column',
}))
export const ButtonsWrapper = styled(Box)(() => ({
  padding: '0px 20px 10px',
  display: 'flex',
  gap: 40,
  flexDirection: 'row',
  justifyContent: 'center',
}))
