export const SET_DIFFICULTY_LEVEL = "SET_DIFFICULTY_LEVEL";
export const RESET_SUMMARY_PAGE = "RESET_SUMMARY_PAGE";
export const SET_TOPICS = "SET_TOPICS";
export const SET_SIMULATION_NAME = "SET_SIMULATION_NAME";

export interface SummaryPageState {
  topics: string[];
  simulationName: string;
}

export type SummaryPageAction =
  | { type: typeof SET_TOPICS; payload: string[] }
  | { type: typeof SET_SIMULATION_NAME; payload: string }
  | { type: typeof RESET_SUMMARY_PAGE };
