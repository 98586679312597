import React, { FC, useState } from "react";
import { Box, Tooltip } from "@mui/material";
import MicroInitialImage from "../../../../assets/images/Micro-Training/micro.png";

const MicroTrainingOutput: FC = () => {
  const [hovered, setHovered] = useState(false);

  const baseStyle = {
    width: "100%",
    height: "100%",
    transition: "transform 0.3s",
  };

  const hoverStyle = {
    transform: "scale(1.05)",
    cursor: "pointer",
  };

  return (
    <Box width="100%" height="100%" overflow="hidden">
      <Tooltip
        title="Full micro-training will be available to preview after saving"
        PopperProps={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [-40, -10],
              },
            },
          ],
        }}
      >
        <img
          src={MicroInitialImage}
          alt="Micro Training"
          height={305}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          style={hovered ? { ...baseStyle, ...hoverStyle } : baseStyle}
        />
      </Tooltip>
    </Box>
  );
};

export default MicroTrainingOutput;
