import { LayoutContext } from '@/common/components/Layout/Layout'
import { useContext } from 'react'
import { useNavigate as useReactRouterNavigate } from 'react-router-dom'

//WithBlocker
const useNavigateWithLayoutBlocker: () => any = () => {
  const { navigationBlock, setBlockedNavigationRequest } = useContext(LayoutContext)
  const navigation = useReactRouterNavigate()
  if (navigationBlock) {
    return (destination: string) => {
      setBlockedNavigationRequest(destination)
    }
  }
  return navigation
}

export default useNavigateWithLayoutBlocker
