import { FC, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { CircularProgress, Paper, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useUpload } from '@/api/upload/upload'
import { faDesktop } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import useToast from '@/common/hooks/useToast'

type ThumbnailCaptureProps = {
  onCapture: (thumbnail: string) => void
  videoElementId: string
  height: number
  initialThumbnail?: string
}

function srcToFile(src: string, fileName: string, mimeType: string) {
  return fetch(src)
    .then(function (res) {
      return res.arrayBuffer()
    })
    .then(function (buf) {
      return new File([buf], fileName, { type: mimeType })
    })
}

const ThumbnailCapture: FC<ThumbnailCaptureProps> = ({ onCapture, videoElementId, height, initialThumbnail }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const [thumbnail, setThumbnail] = useState<string | null>(null)
  const [uploadFile, { isLoading: isUploading }] = useUpload()
  const { errorToast } = useToast()

  async function captureVideoThumbnail() {
    const canvas = document.createElement('canvas')
    let video = document.getElementById(videoElementId) as HTMLVideoElement
    if (!video) {
      video = document.getElementsByTagName('video')[0]
    }
    if (canvas && video) {
      canvas.height = 300
      canvas.width = 300 * (video.clientWidth / video.clientHeight)

      const context = canvas.getContext('2d')
      if (context) {
        context.drawImage(video, 0, 0, canvas.width, canvas.height)
        const dataURL = canvas.toDataURL('image/png')
        const file = await srcToFile(dataURL, 'thumbnail.png', 'image/png')
        const files = await uploadFile(file)
        if (files?.[0]) {
          setThumbnail(files[0].url)
          onCapture(files[0].url)
        } else {
          errorToast(t('createVideoModal.errors.failedToUploadThumbnail'))
        }
      }
    }
  }

  return (
    <Paper
      className={classes.root}
      style={
        thumbnail || initialThumbnail
          ? {
              backgroundImage: `url(${thumbnail || initialThumbnail})`,
            }
          : {}
      }
    >
      <div
        className={`${classes.thumbnail} ${thumbnail || initialThumbnail || isUploading ? classes.captureOverlay : ''}`}
        style={{ height }}
      >
        <Typography style={{ fontWeight: 'normal', fontSize: 18 }} variant="h6">
          {t('createVideoModal.captureInstructions')}
        </Typography>
        {isUploading ? (
          <CircularProgress />
        ) : (
          <div
            style={{
              fontSize: 18,
              textDecoration: 'underline',
              color: theme.palette.common.black,
              textShadow: '0px 5px 4px rgba(0, 0, 0, 0.25)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            className={classes.button}
            onClick={captureVideoThumbnail}
          >
            <FontAwesomeIcon icon={faDesktop as IconProp} fontSize={22} style={{ marginRight: 10 }} />
            {t('createVideoModal.captureThumbnail')}
          </div>
        )}
      </div>
    </Paper>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(0, 2),
      width: '100%',
      position: 'relative',
      overflow: 'hidden',
      minHeight: 300,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    },
    thumbnail: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      textAlign: 'center',
      backgroundSize: 'cover',
    },
    img: {
      width: '100%',
      height: '100%',
    },
    captureOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(255,255,255,0.85)',
      opacity: 0,
      transition: 'opacity 0.3s',
      '&:hover': {
        opacity: 1,
      },
    },
    button: {
      width: 150,
      marginTop: theme.spacing(1),
    },
  })
)
export default ThumbnailCapture
