import React from "react";

type SvgGradientProps = {
  cssId: string;
  startColor: string;
  endColor: string;
  rotate: number;
  offsetStart?: string;
  offsetEnd?: string;
};

export default function SvgGradient({
  cssId,
  rotate,
  startColor,
  endColor,
  offsetStart = "8.34%",
  offsetEnd = "95.26%",
}: SvgGradientProps) {
  let gradientTransform = `rotate(${rotate})`;
  return (
    <svg style={{ height: 0 }}>
      <defs>
        <linearGradient id={cssId} gradientTransform={gradientTransform}>
          <stop offset={offsetStart} stopColor={startColor} />
          <stop offset={offsetEnd} stopColor={endColor} />
        </linearGradient>
      </defs>
    </svg>
  );
}
