import axios from 'axios'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { API_URL } from '../constants'

type DisableMembersRequest = {
  users: string[]
}

const disableMembers = async (params: DisableMembersRequest) => {
  //TODO Replace this endpoint with members{PUT}
  const url = `${API_URL}/users/disable`
  const { data } = await axios.post(url, params, { withCredentials: true })

  return data
}

const useDisableMembers = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: disableMembers,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['members'],
      })
    },
  })
}

export default useDisableMembers
