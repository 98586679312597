export const TONE_OF_VOICE = {
  Urgent: { emoji: "🤯", recommended: true },
  Rewards: { emoji: "🤩", recommended: true },
  Formal: { emoji: "🫡", recommended: false },
  Excited: { emoji: "🥳", recommended: false },
  Angry: { emoji: "😡", recommended: false },
  Friendly: { emoji: "😃", recommended: false },
  Professional: { emoji: "🤓", recommended: false },
  Suspicious: { emoji: "🧐", recommended: false },
  Support: { emoji: "🤖", recommended: false },
};
