import { theme } from '@/theme/theme'
import { PhishingSimulation } from '@/types/phishingSimulations'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faAngleUp, faBoxOpen } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, TextField, Typography, styled } from '@mui/material'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import SimulationPackageCard from './SimulationPackageCard'

type SimulationPackagePreviewProps = {
  setPreview: (e: boolean) => void
  packageName?: string
  simulations?: PhishingSimulation[]
  maxHeight: number
}
const SimulationPackagePreview: FC<SimulationPackagePreviewProps> = ({
  setPreview,
  simulations,
  packageName,
  maxHeight,
}) => {
  const { t } = useTranslation()

  return (
    <StyledMainPreviewBox>
      <StyledInnerPreviewBox maxHeight={maxHeight}>
        <StyledTopwBox>
          <Typography
            color={theme.palette.white}
            fontSize={18}
            fontWeight={theme.typography.fontWeightBold}
            paddingBottom={'16px'}
          >
            {t('simulationPackage.previewPackage')}
          </Typography>
          <FontAwesomeIcon
            icon={faBoxOpen as IconProp}
            fontSize={72}
            color={theme.palette.cyan[500]}
            style={{
              mask: 'linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 90%)',
            }}
          />
          {simulations && simulations.length > 1 && (
            <Typography
              color={theme.palette.white}
              fontSize={16}
              fontWeight={theme.typography.fontWeightBold}
              padding={'16px 40px 12px'}
              align="center"
            >
              {t('simulationPackage.includes', { simulationsNumber: simulations?.length })}
            </Typography>
          )}
          <StyledTextFieldBoxWrapper>
            <StyledTextFieldBox>
              <StyledTextField
                size="small"
                value={packageName || t('simulationPackage.defaultPackageName')}
                label={'Package Name'}
                name="organizationName"
              />
            </StyledTextFieldBox>
          </StyledTextFieldBoxWrapper>
        </StyledTopwBox>
        <StyledCardsContainerBox>
          {simulations?.map((simulation, index) => (
            <SimulationPackageCard key={simulation.id} simulation={simulation} index={index + 1} />
          ))}
        </StyledCardsContainerBox>
        <FontAwesomeIcon
          color={theme.palette.white}
          fontSize={32}
          style={{ cursor: 'pointer' }}
          icon={faAngleUp as IconProp}
          onClick={() => {
            setPreview && setPreview(false)
          }}
        />
      </StyledInnerPreviewBox>
    </StyledMainPreviewBox>
  )
}

const StyledTextField = styled(TextField)(() => ({
  paddingBottom: '20px',
  pointerEvents: 'none',
  width: '100%',
  div: {
    borderRadius: '12px',
  },
  fieldset: {
    borderColor: theme.palette.cyan[500],
    '&.Mui-focused': {
      borderColor: theme.palette.cyan[500],
    },
  },
  input: {
    color: theme.palette.white,
    '&.Mui-focused': {
      color: theme.palette.white,
    },
  },
  label: {
    color: theme.palette.white,
    '&.Mui-focused': {
      color: theme.palette.white,
    },
  },
}))

const StyledInnerPreviewBox = styled(Box)(({ maxHeight }: { maxHeight: number }) => ({
  width: '100%',
  padding: '20px',
  maxHeight: maxHeight - 294 + 'px',
  background: theme.palette.blueGray[800],
  borderRadius: '15px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

const StyledMainPreviewBox = styled(Box)(() => ({
  width: '100%',
  height: '211px',
  background: 'red',
  borderRadius: '15px',
}))

const StyledTopwBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

const StyledTextFieldBoxWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  width: '100%',
  padding: '0px 10px',
  alignItems: 'stretch',
}))

const StyledTextFieldBox = styled(Box)(() => ({
  padding: '8px 10px 4px 0px',
}))

const StyledCardsContainerBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'scroll',
  overflowX: 'hidden',
  height: '100%',
  width: '100%',
  padding: '0px 10px',
  marginBottom: '20px',
  alignItems: 'stretch',
}))
export default SimulationPackagePreview
