import React, { useEffect, useState } from "react";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Typography } from "@mui/material";
import useLottie from "../../common/hooks/useLottie";
import { useAuth } from "../../context/Auth";
import animationData from "@/assets/lottie/fingerprint.json";

export default function LoadingScreen() {
  const classes = useStyles();
  const { isLoading } = useAuth();
  const [showScreen, setShowScreen] = useState(false);
  const { LottieAnimation } = useLottie({
    animationData: animationData,
    height: 300,
    width: 500,
  });

  useEffect(() => {
    if (isLoading) {
      setShowScreen(true);
      setTimeout(() => setShowScreen(false), 4000);
    }
  }, [isLoading]);

  return showScreen ? (
    <div className={classes.root}>
      {LottieAnimation}
      <Typography variant={"h3"}>Welcome</Typography>
    </div>
  ) : null;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      background: theme.palette.background.default,
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 9999999999,
    },
  })
);
