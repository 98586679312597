import { Box, Button, Modal, styled } from '@mui/material'
import mobileFrontFrameImage from '@/assets/images/MobilePreview/mobileFrontFrame.png'
import { FC, MutableRefObject, ReactNode, useLayoutEffect, useRef, useState } from 'react'
import LoadingAnimation from '@/common/components/MobilePhonePreview/LoadingAnimation'
import { faExpand } from '@fortawesome/pro-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type MobilePhonePreviewProps = {
  invisible?: boolean
  children?: ReactNode | ReactNode[]
  size?: number
  isLoading?: boolean
  fullScreenButton?: boolean
}
const MobilePhonePreview: FC<MobilePhonePreviewProps> = ({
  invisible = false,
  children,
  isLoading = false,
  fullScreenButton = false,
}) => {
  const [fontSize, setFontSize] = useState(20)
  const ref: MutableRefObject<{ offsetWidth: number } | undefined> = useRef()

  const [isFullscreen, setIsFullscreen] = useState(false)

  useLayoutEffect(() => {
    function updateSize() {
      ref.current && setFontSize(ref.current.offsetWidth / 20)
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [ref.current])

  return (
    <Container ref={ref}>
      {fullScreenButton && (
        <FullscreenButton
          onClick={() => setIsFullscreen(true)}
          variant={'contained'}
          color="secondary"
          disableElevation
          disabled={isLoading}
        >
          <FontAwesomeIcon icon={faExpand as IconProp} style={{ width: '12px' }} />
        </FullscreenButton>
      )}
      <MobileFrame invisible={invisible} />
      <Base size={fontSize} inMobileFrame={!invisible}>
        <Content isLoading={isLoading}>{children}</Content>
        {isLoading && <LoadingAnimation size={fontSize} />}
      </Base>
      <FullscreenModal open={isFullscreen} onClose={() => setIsFullscreen(false)}>
        <FullscreenModalContent>
          {/* fullScreenButton is always false for fullScreen component */}
          <MobilePhonePreview invisible={invisible} isLoading={isLoading}>
            {children}
          </MobilePhonePreview>
        </FullscreenModalContent>
      </FullscreenModal>
    </Container>
  )
}

const FullscreenButton = styled(Button)(() => ({
  left: '-8%',
  minWidth: '0px',
  minHeight: '0px',
  zIndex: '3',
  display: 'flex',
  padding: '5px 9px',
  position: 'absolute',
  borderRadius: '50px',
}))

const FullscreenModal = styled(Modal)(() => ({
  display: 'flex',
  justifyContent: 'center',
  background: 'rgba(0, 0, 0, 0.5)',
}))

const FullscreenModalContent = styled(Box)(() => ({
  height: '100%',
}))
const Container = styled(Box)(() => ({
  position: 'relative',
  aspectRatio: '0.5',
  flex: 1,
  display: 'flex',
  height: '100%',
}))
const Base = styled(Box)(({ inMobileFrame, size }: { inMobileFrame: boolean; size: number }) => ({
  position: 'relative',
  flex: 1,
  fontSize: `${size}px`,
  margin: '8% 8% 10% 8%',
  borderRadius: inMobileFrame ? '14% / 7%' : '0',
  overflow: 'hidden',
  backgroundColor: 'black',
}))
const MobileFrame = styled(Box)(({ invisible }: { invisible: boolean }) => ({
  position: 'absolute',
  height: '100%',
  width: '100%',
  top: '0',
  left: '0',
  backgroundImage: `url(${mobileFrontFrameImage})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  zIndex: 1,
  display: invisible ? 'none' : 'block',
  pointerEvents: 'none',
}))
const Content = styled(Box)(({ isLoading }: { isLoading: boolean }) => ({
  height: '100%',
  width: '100%',
  opacity: isLoading ? '40%' : '100%',
}))
export default MobilePhonePreview
