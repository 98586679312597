import Axios from 'axios'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { API_URL } from '@/api/constants'
import { OrganizationAcceptInviteRequest, OrganizationUpdateRequest } from '@/types/organizations'

const organizationAcceptInvite = async (token: OrganizationAcceptInviteRequest) => {
  const url = `${API_URL}/organizations/accept-invitation/`
  const { data } = await Axios.post(url, { token }, { withCredentials: true })

  return data
}

const organizationUpdate = async ({ id, params }: OrganizationUpdateRequest) => {
  const url = `${API_URL}/organizations/${id}`
  const { data } = await Axios.patch(url, params, { withCredentials: true })

  return data
}

const useOrganizationAcceptInvite = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: organizationAcceptInvite,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['organizations'],
      })
    },
  })
}

const useOrganizationUpdate = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: organizationUpdate,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['organizations'],
      })
    },
  })
}

export { useOrganizationAcceptInvite, useOrganizationUpdate }
