import { theme } from '@/theme/theme'
import { Box } from '@mui/material'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'

interface LandingPageOutputProps {
  text: string
  vectorType: string
  isModal: boolean
}

const LandingPageOutput: React.FC<LandingPageOutputProps> = ({ text = '', vectorType, isModal = false }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const [heightRatio, setHeightRatio] = useState(1)
  const [firstFit, setFirstFit] = useState(true)
  const updateSize = () => {
    firstFit && setFirstFit(false)
    if (iframeRef.current?.contentDocument) {
      setHeightRatio(iframeRef.current.scrollHeight / iframeRef.current.contentDocument.documentElement.scrollHeight)
    }
  }
  useEffect(() => {
    const iframe = iframeRef.current
    if (iframe) {
      const doc = iframe.contentDocument || iframe.contentWindow?.document
      if (doc) {
        doc.open()
        doc.write(text)
        doc.close()
      }
      iframe.contentWindow?.document.children[0].setAttribute('style', 'pointer-events: none;')
      firstFit && updateSize()
    }
  })

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize)
    return () => window.removeEventListener('resize', updateSize)
  }, [iframeRef.current])
  return (
    <Box style={{ background: theme.palette.white, height: '100%' }}>
      {!isModal && (
        <iframe
          title="landing-page-output"
          ref={iframeRef}
          style={{
            position: 'absolute',
            transform: `scale(${heightRatio})`,
            transformOrigin: 'top left',
            width: (1 / heightRatio) * 100 + '%',
            height: (1 / heightRatio) * 100 + '%',
            top: 0,
            left: 0,
            scrollbarWidth: 'thin',
          }}
        />
      )}
      {isModal && (
        <iframe
          title="landing-page-output"
          ref={iframeRef}
          style={{
            width: '100%',
            height: '100%',
            border: 'none',
            overflow: 'hidden',
            pointerEvents: 'none',
            scrollbarWidth: 'thin',
          }}
        />
      )}
    </Box>
  )
}

export default LandingPageOutput
