import axios from 'axios'
import { API_URL } from '../constants'
import { useMutation, useQueryCache } from 'react-query'
import { ServerError } from '../types'
import { ClientDetailsResult } from './client'

export async function verifyToken(token: number) {
  const url = `${API_URL}/organizations/whitelist_verify`
  await axios.post(url, { token }, { withCredentials: true })
}

export function useVerifyWhitelist() {
  const queryCache = useQueryCache()
  return useMutation<void, ServerError, number>(verifyToken, {
    throwOnError: true,
    onSuccess: () => {
      //@ts-ignore
      queryCache.setQueryData('organizations', (old: ClientDetailsResult) => ({ ...old, whitelist: true }))
    },
  })
}
