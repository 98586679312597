import { ReactElement } from 'react'
import { Chip, Tabs } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { FC, useMemo } from 'react'

export type Tag = {
  propKey: string
  value: string | number | boolean | null
  label: string
  icon?: ReactElement
}

type TagsFilterProps = {
  tags: Tag[]
  queryParams: any
  updateQueryParam: (newValues: any) => void
}

const TagsFilter: FC<TagsFilterProps> = ({ tags, queryParams, updateQueryParam }) => {
  const classes = useStyles()

  const uniqueTags = useMemo(() => {
    const seen = new Set<string>()
    return tags.filter((tag) => {
      const keyValue = `${tag.propKey}-${tag.value}`
      if (seen.has(keyValue)) {
        return false
      }
      seen.add(keyValue)
      return true
    })
  }, [tags])

  const propKeys = uniqueTags.map((tag) => tag.propKey)

  const isAllSelected = useMemo(
    () => propKeys.reduce((acc, key) => acc && (!queryParams[key] || queryParams[key].length === 0), true),
    [queryParams, propKeys]
  )

  const handleClickAll = () => {
    updateQueryParam(
      propKeys.reduce(
        (acc, key) => ({ ...queryParams, ...acc, [key]: Array.isArray(queryParams[key]) ? [] : null }),
        {}
      )
    )
  }

  const handleClick = (tag: Tag) => {
    const currentValue = queryParams[tag.propKey]

    if (Array.isArray(currentValue)) {
      if (currentValue.indexOf(tag.value) === -1) {
        updateQueryParam({
          ...queryParams,
          [tag.propKey]: [...currentValue, tag.value],
        })
      } else {
        updateQueryParam({
          ...queryParams,
          [tag.propKey]: currentValue.filter((value: string) => value !== tag.value),
        })
      }
    } else {
      updateQueryParam({
        ...queryParams,
        [tag.propKey]: currentValue === tag.value ? null : tag.value,
      })
    }
  }

  return (
    <div className={classes.root}>
      <Tabs
        value={0}
        variant="scrollable"
        scrollButtons
        classes={{ indicator: classes.indicator }}
        allowScrollButtonsMobile>
        <Chip
          className={`${classes.chip} ${isAllSelected ? classes.chipSelected : null}`}
          classes={{ root: classes.chip }}
          clickable
          label={'All'}
          variant="filter"
          color={isAllSelected ? 'secondary' : 'default'}
          onClick={handleClickAll}
        />
        {uniqueTags.map((tag, i) => {
          const selected = Array.isArray(queryParams[tag.propKey])
            ? queryParams[tag.propKey].includes(tag.value)
            : queryParams[tag.propKey] === tag.value

          return (
            <Chip
              key={i}
              className={`${classes.chip} ${selected ? classes.chipSelected : null}`}
              classes={{ root: classes.chip }}
              clickable
              variant="filter"
              icon={tag.icon}
              label={tag.label}
              color={selected ? 'secondary' : 'default'}
              onClick={() => handleClick(tag)}
            />
          )
        })}
      </Tabs>
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: theme.spacing(1, -1.5, 2),
      '& .MuiTabScrollButton-root': {
        '&.Mui-disabled': {
          opacity: 0.2,
        },
      },
    },
    indicator: {
      display: 'none',
    },
    chip: {
      backgroundColor: 'lightgray',
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(0.75),
      borderRadius: 5,
      height: 24,
      fontWeight: theme.typography.fontWeightMedium,
    },
    chipSelected: {
      backgroundColor: theme.palette.cyan[300],
    },
  })
)

export default TagsFilter
