import { FC } from 'react'
import { Box, Typography, styled, Tooltip } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { SubTitle, Title } from './ConfigurationsStyledComponents'
import { ConfigurationsProps } from '.'

const OrganizationCapacityBar: FC<ConfigurationsProps> = ({ organiztion }) => {
  const { t } = useTranslation()
  const totalMembers = organiztion?.members?.total ?? 0
  const activeMembers = organiztion?.members?.active ?? 0
  const membersQuota = organiztion?.users_quota ?? 0
  const inactiveMembers = totalMembers - activeMembers ?? 0
  const availableMembers = membersQuota - activeMembers ?? 0

  return (
    <Box mt={3}>
      <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Title>{t('settings.configurationTab.capacityBar.yourSeats')}</Title>
          <Tooltip
            title={<Trans i18nKey="settings.configurationTab.capacityBar.tooltip" components={{ 1: <strong /> }} />}
            placement="top"
          >
            <FontAwesomeIcon
              icon={faCircleInfo as IconProp}
              fontSize={20}
              style={{ color: '#979797', marginLeft: '5px', marginBottom: '15px' }}
            />
          </Tooltip>
        </Box>
        <SubTitle>
          <Trans
            i18nKey="settings.configurationTab.capacityBar.membersQuota"
            values={{
              membersQuota: membersQuota.toLocaleString(),
            }}
            components={{ 1: <strong /> }}
          />
        </SubTitle>
      </Box>
      <CapacityBar>
        <CapacityBarFill percentage={organiztion ? (activeMembers / membersQuota) * 100 : 0}></CapacityBarFill>
      </CapacityBar>
      <SubBarTitle>
        <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: '10px' }}>
          <Dot />
          <Typography>
            {t('settings.configurationTab.capacityBar.activatedMembers', {
              activeMembers: activeMembers.toLocaleString(),
            })}
          </Typography>
        </Box>
        <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Dot color="#AEB7C4" />
          <Typography>
            {t('settings.configurationTab.capacityBar.availableMembers', {
              availableMembers: availableMembers.toLocaleString(),
            })}
          </Typography>
        </Box>
      </SubBarTitle>
      <SubBarTitle>
        <Typography>
          {t('settings.configurationTab.capacityBar.inactivatedMembers', {
            inactive_members: inactiveMembers.toLocaleString(),
          })}
        </Typography>
      </SubBarTitle>
    </Box>
  )
}
const CapacityBar = styled(Box)(() => ({
  width: '100%',
  height: '30px',
  border: '3px solid #D1DBE9',
  borderRadius: '5px',
  background: '#AEB7C4',
  overflow: 'clip',
}))
const Dot = styled(Box)(({ color }: { color?: string }) => ({
  width: '20px',
  height: '20px',
  background: color ? color : '#666F7B',
  borderRadius: '50px',
  marginRight: '3px',
}))
const SubBarTitle = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  padding: '6px 0px',
}))
const CapacityBarFill = styled(Box)(({ percentage }: { percentage: number }) => ({
  width: `${percentage}%`,
  height: '100%',
  borderRadius: '2px',
  background: '#666F7B',
}))

export default OrganizationCapacityBar
