import React, { ReactElement } from 'react'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { Button, IconButton, Tooltip, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { theme } from '@/theme/theme'

type Action = {
  label: string
  disabled?: boolean
  onClick: (selectedItemIds: string[], selectedItems: any[]) => void
  callback?: () => void
}

interface ButtonAction extends Action {
  variant: 'button'
}

interface IconButtonAction extends Action {
  variant: 'iconButton'
  icon: () => ReactElement
}

export type MultiselectBarProps = {
  numSelected: number
  label: string
  actions: (ButtonAction | IconButtonAction)[]
  selectedRows: any[]
  selectedFlatRows: any[]
}

export default function MultiselectBar({
  label,
  actions,
  numSelected,
  selectedRows,
  selectedFlatRows,
}: MultiselectBarProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <div className={classes.root}>
      <Typography variant={'subtitle2'}>{t(label, { count: numSelected })}</Typography>
      <div className={classes.actionsContainer}>
        {actions.map((action) => {
          return (
            <div className={classes.buttonContainer} key={action.label}>
              {action.variant === 'button' ? (
                <Button
                  variant={'outlined'}
                  sx={{
                    border: `1px solid ${theme.palette.cyan[500]}`,
                  }}
                  onClick={() => {
                    action.onClick(selectedRows, selectedFlatRows)
                    action.callback?.()
                  }}
                >
                  {t(action.label, { count: numSelected })}
                </Button>
              ) : (
                <Tooltip title={t(action.label) as string}>
                  <IconButton onClick={() => action.onClick(selectedRows, selectedFlatRows)} size="large">
                    {action.icon()}
                  </IconButton>
                </Tooltip>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(2, 2),
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    actionsContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    buttonContainer: {
      marginLeft: theme.spacing(2),
    },
  })
)
