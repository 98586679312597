import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faCircleExclamation } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, CircularProgress, Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { string } from 'yup'

import { ServerError } from '@/api/types'
import Form from '@/common/components/Form/Form'
import useForm, { Field } from '@/common/components/Form/useForm'

const fields: Field[] = [
  {
    name: 'username',
    label: 'login.form.username',
    validationRule: string().required('login.errors.missingEmail').email('login.errors.invalidEmail'),
    type: 'email',
    autoComplete: 'email',
    required: true,
    autoFocus: true,
  },
  {
    name: 'password',
    label: 'login.form.password',
    validationRule: string().required('login.errors.missingPassword'),
    type: 'password',
    autoComplete: 'current-password',
    required: true,
    autoFocus: false,
  },
]

type LoginProps = {
  handleLogin: ({ username, password }: { [name: string]: string | string[] | number }) => void
  isLoading: boolean
  error: ServerError | null
}
export default function Login({ handleLogin, isLoading, error }: LoginProps) {
  const { t } = useTranslation()
  const classes = useStyles()
  const { formProps, handleSubmit } = useForm({ fields, onSubmit: handleLogin })

  return (
    <div className={classes.form}>
      {error && (
        <div className={classes.errorMessage}>
          <FontAwesomeIcon icon={faCircleExclamation as IconProp} className={classes.errorIcon} />
          <Typography variant="subtitle2" color={'inherit'}>
            {error?.response?.data?.message}
          </Typography>
        </div>
      )}
      <Form {...formProps} />
      <Button onClick={handleSubmit} fullWidth variant="contained" color="primary" className={classes.submit}>
        {isLoading ? <CircularProgress color={'secondary'} size={24} /> : t('login.signIn')}
      </Button>
      <Grid container>
        <Grid item xs>
          <Link to="/reset-password">{t('login.forgotPassword')}</Link>
        </Grid>
        <Grid item>
          <Link to="/signup">{t('login.noAccount')}</Link>
        </Grid>
      </Grid>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorMessage: {
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.error.main,
    paddingBottom: theme.spacing(2),
  },
  errorIcon: {
    marginRight: 4,
  },
  spinnerContainer: {
    padding: '8px 11px',
  },
}))
