import React, { useState } from "react";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Tab, Tabs } from "@mui/material/";
import { useTranslation } from "react-i18next";
import OfficeUsersTable from "./OfficeUsersTable";
import OfficeGroupsTable from "./OfficeGroupsTable";

type OfficeTablesProps = {
  currentOfficeId: string;
};

export default function OfficeTables({ currentOfficeId }: OfficeTablesProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(0);

  function handleTabChange(e, tab) {
    setCurrentTab(tab);
  }

  return (
    <div className={classes.root}>
      <Tabs
        className={classes.tabs}
        value={currentTab}
        textColor="inherit"
        onChange={handleTabChange}
      >
        <Tab
          classes={{ textColorInherit: classes.tab }}
          label={t("officeProfile.users")}
        />
        <Tab
          classes={{ textColorInherit: classes.tab }}
          label={t("officeProfile.groups")}
        />
      </Tabs>
      <div
        className={classes.container}
        style={{ display: currentTab === 0 ? "block" : "none" }}
      >
        <OfficeUsersTable currentOfficeId={currentOfficeId} />
      </div>
      <div
        className={classes.container}
        style={{ display: currentTab === 1 ? "block" : "none" }}
      >
        <OfficeGroupsTable currentOfficeId={currentOfficeId} />
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      paddingBottom: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      overflow: "visible",
    },
    container: {
      flex: "1 0 auto",
    },
    tabs: {
      marginBottom: theme.spacing(1),
    },
    flexContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    },
    tab: {
      textTransform: "capitalize",
    },
    button: {
      marginLeft: theme.spacing(1),
    },
  })
);
