import axios from 'axios'
import { API_URL } from '../constants'
import { useMutation, useQueryClient } from '@tanstack/react-query'

const addDomains = async (domains: string[]) => {
  const url = `${API_URL}/domains/new`
  const { data } = await axios.post(url, domains, { withCredentials: true })
  return data.data
}

export const useNewDomain = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: addDomains,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['domains'],
      })
    },
  })
}
