import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { useDropzone } from "react-dropzone";
import { CircularProgress, Typography, useTheme, Paper } from "@mui/material";
import useToast from "../../../../common/hooks/useToast";
import UploadVideoImg from "../../../../assets/images/upload-video.svg";
import { useTranslation } from "react-i18next";
import { useUploadVideoToCloudinary } from "../../../../api/upload/upload-cloudinary-direct";

type VideoUploadProps = {
  onUpload: (video: { url: string; name: string; duration?: number }) => void;
};

export default function VideoUploadComponent({ onUpload }: VideoUploadProps) {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();
  const { errorToast } = useToast();
  const [uploadFile, { isLoading }] = useUploadVideoToCloudinary();
  const {
    getInputProps,
    getRootProps,
    open: openFileSelector,
  } = useDropzone({
    onDrop: handleUpload,
    noDrag: false,
    multiple: false,
    accept: ".mp4, .mov, .avi",
  });

  async function handleUpload(acceptedFiles) {
    try {
      const file = await uploadFile(acceptedFiles[0]);
      if (file) {
        onUpload({
          name: acceptedFiles[0].name,
          url: file.url,
          duration: file.duration,
        });
      } else {
        errorToast(t("videoUpload.errors.failedToUpload"));
      }
    } catch (e) {
      console.log(e);
      errorToast(t("videoUpload.errors.failedToUpload"));
    }
  }

  return (
    <Paper>
      <div className={classes.root} {...getRootProps()}>
        <img alt={"upload video"} height={160} src={UploadVideoImg} />

        <Typography style={{ fontWeight: "normal", fontSize: 18 }} variant="h6">
          {t("videoUpload.title")}
        </Typography>

        <Typography variant={"body1"} style={{ fontSize: 12 }}>
          {t("videoUpload.acceptedFiles")} {t("videoUpload.limit")}
        </Typography>
        <Typography
          variant={"body1"}
          style={{ fontSize: 14, margin: theme.spacing(1, 0) }}
        >
          {t("videoUpload.or")}
        </Typography>

        {isLoading ? (
          <CircularProgress />
        ) : (
          <div
            style={{
              fontSize: 18,
              textDecoration: "underline",
              color: theme.palette.common.black,
              textShadow: "0px 5px 4px rgba(0, 0, 0, 0.25)",
            }}
            color="primary"
            onClick={(e) => {
              e.stopPropagation();
              openFileSelector();
            }}
          >
            {t("videoUpload.selectFile")}
          </div>
        )}

        <input id={"videoUpload"} {...getInputProps()} />
      </div>
    </Paper>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      cursor: "pointer",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingBottom: theme.spacing(4),
    },
    title: {
      fontWeight: "bold",
    },
    button: {
      minWidth: 300,
      marginTop: theme.spacing(2),
    },
  })
);
