import { DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { FC } from 'react'

import image1 from '@/assets/images/outelook-tutorial/image1.png'
import image2 from '@/assets/images/outelook-tutorial/image2.png'
import image3 from '@/assets/images/outelook-tutorial/image3.png'

const Outlook: FC = () => (
  <>
    <DialogTitle>Whitelisting Cywareness - Office365</DialogTitle>
    <DialogContent>
      <DialogContentText>
        <ol>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 'bold' }}>
              Go your Advanced Delivery using this{' '}
              <a
                href={
                  'https://www.google.com/url?q=https://security.microsoft.com/advanceddelivery&sa=D&source=editors&ust=1652803297942025&usg=AOvVaw26BofELUbsO4Ru0qrRj5yM'
                }
              >
                link
              </a>
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Press on the “Phishing Sumulation” tab (screenshot below) </span>
          </li>
          <img style={{ width: '100%', margin: '16px 0px' }} src={image2} alt={'example'} />
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Press on the “Add” button </span>
          </li>
          <img style={{ width: '100%', margin: '16px 0px' }} src={image3} alt={'example'} />
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Fill in the following information: </span>
            <ul>
              <li>
                <strong>Domain:</strong> em4167.authenticationn.com
              </li>
              <li>
                <strong>Sending IP:</strong> 149.72.52.80
              </li>
            </ul>
          </li>
          <img style={{ width: '100%', margin: '16px 0px' }} src={image1} alt={'example'} />
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>You are now ready to use the Cywarness phishing simulator. </span>
          </li>
        </ol>
      </DialogContentText>
    </DialogContent>
  </>
)

export default Outlook
