import { theme } from '@/theme/theme'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'
import { ElementType } from 'react'

type StepIconProps = {
  icon?: ElementType
  className?: string
}

const StepIcon = ({ icon: IconComponent, ...props }: StepIconProps) => {
  return <StyledIconWrapper {...props}>{IconComponent && <IconComponent />}</StyledIconWrapper>
}

const StyledIconWrapper = styled(Box)(() => ({
  zIndex: 1,
  color: theme.palette.white,
  width: 40,
  height: 40,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.black,
}))

export default StepIcon
