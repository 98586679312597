import axios from 'axios'
import { API_URL } from '../constants'
import { useMutation, useQueryClient } from '@tanstack/react-query'

//TODO : combine api with the disbaled
export async function enableMfa() {
  const url = `${API_URL}/profile/otp/setup`
  const { data } = await axios.post(url, {}, { withCredentials: true })
  return data
}

export function useEnableMfa() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: enableMfa,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['mfa'],
      })
    },
  })
}
