import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import Toolbar from '@mui/material/Toolbar'
import { Button, CircularProgress, IconButton, Tooltip, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import useForm, { Field } from '../Form/useForm'
import { string } from 'yup'
import Form from '../Form/Form'

import { faRotateRight } from '@fortawesome/pro-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type VerifyDomainsToolbarProps = {
  handleAddDomain: (domain: string) => void
  handleRefresh: () => void
  isFetching: boolean
}

const fields: Field[] = [
  {
    name: 'domain',
    placeholder: 'domainsTableComponent.enterDomain',
    validationRule: string().matches(/^\s*(http(s?):\/\/)?(www\.)?([A-Za-z0-9_-]+\.)+([A-Za-z0-9_-]+)+\s*$/gi, {
      message: 'domainsTableComponent.errors.invalidDomain',
    }),
    type: 'text',
    autoComplete: 'none',
    required: true,
    autoFocus: true,
    textFieldProps: {
      variant: 'outlined',
      margin: 'dense',
      fullWidth: false,
    },
    gridItemProps: {
      style: {
        display: 'flex',
        alignItems: 'center',
      },
    },
  },
]

export default function DomainsToolbar({ handleAddDomain, handleRefresh, isFetching }: VerifyDomainsToolbarProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { formProps, handleSubmit } = useForm({
    fields,
    onSubmit: ({ domain }) => handleAddDomain(domain as string),
  })
  return (
    <Toolbar className={classes.root}>
      <div className={classes.leftSideContainer}>
        <Typography className={classes.title} variant="subtitle1" id="tableTitle" noWrap>
          {t('domainsTableComponent.addDomain')}
        </Typography>
        <div>
          <Form gridContainerProps={{ spacing: 0 }} {...formProps} />
        </div>
      </div>
      <div className={classes.rightSideContainer}>
        <Button
          className={classes.button}
          onClick={handleSubmit}
          variant={'contained'}
          color={'primary'}
          disabled={!formProps.values.domain}
        >
          {t('domainsTableComponent.add')}
        </Button>
        <Tooltip title={t('domainsTableComponent.refresh') as string}>
          <IconButton onClick={handleRefresh} disabled={isFetching} size="large">
            {isFetching ? (
              <CircularProgress size={24} />
            ) : (
              <FontAwesomeIcon icon={faRotateRight as IconProp} fontSize={20} />
            )}
          </IconButton>
        </Tooltip>
      </div>
    </Toolbar>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(0, 2),
      borderBottom: `1px solid ${theme.palette.divider}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      position: 'sticky',
    },
    title: { marginRight: theme.spacing(2) },
    leftSideContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    rightSideContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    button: {
      marginRight: theme.spacing(1),
    },
  })
)
