import axios from "axios";
import { API_URL } from "../constants";
import { useQuery } from "react-query";
import { ServerError } from "../types";

export type AuthClient = {
  _id: string;
  name: string;
  logo: string;
};

export async function getAuthClients() {
  const url = `${API_URL}/profile/clients/`;
  const { data } = await axios.get(url, { withCredentials: true });
  return data || [];
}

export function useAuthClients() {
  return useQuery<AuthClient[], ServerError>("auth_clients", getAuthClients, {
    refetchOnWindowFocus: false,
  });
}
