import { useContext } from "react";
import { ContentLibraryContext } from "./ContentLibraryContext";

export const useContentLibrary = () => {
  const context = useContext(ContentLibraryContext);

  if (context === undefined) {
    throw new Error(
      "useContentLibrary must be used within a ContentLibraryProvider"
    );
  }

  return context;
};
