import { useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { MenuItem, Chip, Typography, Menu } from '@mui/material'
import { faGlobe } from '@fortawesome/pro-light-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export type LanguageSelectProps = {
  options: { label: string; value: any }[]
  value: any
  handleChange: (val: any) => void
}
export function LanguageSelect({ value, options, handleChange }: LanguageSelectProps) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  function onSelect(value: any) {
    handleClose()
    handleChange(value)
  }

  return (
    <div>
      <Chip
        size={'medium'}
        icon={<FontAwesomeIcon icon={faGlobe as IconProp} fontSize={20} style={{ paddingBottom: '3px' }} />}
        label={options.filter((option) => option.value === value)?.[0]?.label}
        clickable
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        classes={{ root: classes.chipRoot }}
        color={'default'}
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        {options.map((option) => (
          <MenuItem onClick={() => onSelect(option.value)} value={option.value}>
            <Typography variant={'subtitle2'} className={classes.optionText}>
              {option.label}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    chipRoot: {
      background: theme.palette.grey.A100,
      padding: theme.spacing(1, 2),
      height: 45,
      color: theme.palette.text.primary,
    },
    optionText: {
      marginRight: theme.spacing(3),
    },
  })
)
