import { Tab, Tabs } from "@mui/material";
import React from "react";
import { SIMULATION_TYPES, useAiSimulationContext } from "../state";

export const SimulationTypePreview = () => {
  const { simulationType } = useAiSimulationContext();

  return (
    <Tabs
      value={simulationType}
      sx={{ marginLeft: -1 }}
      indicatorColor="primary"
      textColor="primary"
    >
      <Tab
        disabled
        label="Impersonate an app"
        sx={{
          textTransform: "none",
          paddingRight: 2.5,
          paddingLeft: 0,
        }}
        value={SIMULATION_TYPES.APP}
      />
      <Tab
        disabled
        label="Impersonate a human"
        value={SIMULATION_TYPES.HUMAN}
        sx={{
          textTransform: "none",
          paddingLeft: 0,
        }}
      />
    </Tabs>
  );
};
