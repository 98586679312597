import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faCheck } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, Box, Container, Grid, Paper, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { Link, Route, Routes, useLocation, useMatch } from 'react-router-dom'
import { animated, useTransition } from 'react-spring'

import LogoIcon from '@/assets/images/logo.svg'
import ResetPassword from './ResetPassword'
import ResetPasswordSuccess from './ResetPasswordSuccess'
import SetPassword from './SetPassword'
import VerifyResetToken from './VerifyResetToken'

export default function ResetPasswordContainer() {
  const { t } = useTranslation()
  const isSuccessPage = (useMatch('/reset-password/success') && true) || false
  const classes = useStyles({ isSuccessPage })
  const location = useLocation()

  const transitions = useTransition(location, (location) => location.pathname, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  return (
    <Container component="main" maxWidth="xs">
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          {isSuccessPage ? (
            <FontAwesomeIcon icon={faCheck as IconProp} />
          ) : (
            <img alt={'Cywareness Logo'} src={LogoIcon} height={24} width={22} />
          )}
        </Avatar>
        <div className={classes.formContainer}>
          {transitions.map(({ item: location, props, key }) => (
            <animated.div key={key} style={props}>
              <Routes>
                <Route path="/" element={<ResetPassword />} />

                <Route path="verify/:username" element={<VerifyResetToken />} />

                <Route path="set-new/:username/:token" element={<SetPassword />} />

                <Route path="success" element={<ResetPasswordSuccess />} />
              </Routes>
            </animated.div>
          ))}
        </div>
        <Grid container justifyContent={'center'}>
          <Grid item>
            <Link to="/login">{t('resetPasswordContainer.toLogin')}</Link>
          </Grid>
        </Grid>
      </Paper>
      <Box mt={8}>
        <Typography variant="body2" color="textSecondary" align="center">
          {t('resetPasswordContainer.copyright') + ' © '}
          Cywareness {new Date().getFullYear()}
          {'.'}
        </Typography>
      </Box>
    </Container>
  )
}

type StyleProps = { isSuccessPage: boolean }

const useStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(8),
      padding: theme.spacing(4, 4, 2, 4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: ({ isSuccessPage }) =>
        isSuccessPage ? theme.palette.success.main : theme.palette.blueGray[900],
    },
    formContainer: {
      width: '100%', // Fix IE 11 issue.
      height: 300,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      position: 'relative',
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    errorMessage: {
      display: 'flex',
      justifyContent: 'center',
      color: theme.palette.error.main,
    },
    errorIcon: {
      marginRight: 4,
    },
  })
)
