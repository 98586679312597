import axios from 'axios';
import { API_URL } from '../constants';
import { useMutation, useQueryCache } from 'react-query';
import { ServerError } from '../types';
import { ActiveDirectoryUser, ActiveDirectoryBoardRequest } from './users';
import { User } from '../users/users';

export type OnboardUsersResponse = (User & { external_id: string })[] | undefined;
export async function onboardUsers(onboardingData: ActiveDirectoryBoardRequest): Promise<OnboardUsersResponse> {
  const url = `${API_URL}/active_directory/onboard`;
  const { data } = await axios.post(url, onboardingData, { withCredentials: true });
  return data.data;
}

export function useOnboardActiveDirectoryUsers() {
  const queryCache = useQueryCache();
  return useMutation<OnboardUsersResponse, ServerError, ActiveDirectoryBoardRequest>(onboardUsers, {
    throwOnError: true,
    onMutate: (users) => {
      queryCache.cancelQueries(['ad-users']);
      const previousUsers = (queryCache.getQueryData(['ad-users']) as ActiveDirectoryUser[]) ?? [];
      const userIds = users.users.map((user) => {
        return user.external_id;
      });

      const updatedUsers = previousUsers.map((user) =>
        userIds.includes(user.external_id) ? { ...user, onboarded: true } : user,
      );

      queryCache.setQueryData(['ad-users'], () => updatedUsers);
      return () => queryCache.setQueryData(['ad-users'], previousUsers);
    },
    //@ts-ignore
    onError: (err, variables, rollback) => rollback(),
    //@ts-ignore
    onSettled: () => {
      queryCache.invalidateQueries(['ad-users']);
    },
  });
}
