import axios from "axios";
import { API_URL } from "../constants";
import { useQuery } from "react-query";
import { ServerError } from "../types";

export type AlertObject = {
  _id: string;
  title: string;
  message: string;
  alert_time: number;
  read: boolean;
  client_id: string;
  icon: string;
};

async function getAlerts() {
  const url = `${API_URL}/alerts/`;
  const { data } = await axios.get(url, { withCredentials: true });
  return data.data;
}

export function useGetAlerts() {
  return useQuery<AlertObject[], ServerError>("alerts", getAlerts);
}
