import { Box, styled } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'

// import SettingsKYB from './SettingsKYB'
import ProtectedRoute from '@/Routes/ProtectedRoute'
import { useClientDetails } from '@/api/client/client'
import CustomBreadcrumbs from '@/common/components/Breadcrumbs/Breadcrumbs'
import LoadingContainer from '@/common/components/LoadingContainer/LoadingContainer'
import MainTab from '@/common/components/Tabs/MainTab'
import MainTabPage from '@/common/components/Tabs/MainTabPage'
import MainTabs from '@/common/components/Tabs/MainTabs'
import MainTabsWrapper from '@/common/components/Tabs/MainTabsWrapper'
import { indexToTab, tabToIndex } from '@/common/utils/tab-utils'
import { theme } from '@/theme/theme'
import AdminsTable from './Admins/AdminsTable'
import InviteAdminModal from './Admins/InviteAdminModal'
import ConfigurationsTab from './Configurations'
import Integrations from './Integrations/Integrations'
import Plugins from './Plugins/Plugins'
import WhitelistingSettings from './WhitelistingSettings/WhitelistingSettings'

const tabIndexMap = {
  '/settings/configurations': 0,
  '/settings/admins': 1,
  '/settings/plugins': 2,
  // '/settings/kyb': 3,
  '/settings/allowlisting': 3,
  '/settings/integrations': 4,
}

const Settings: FC = () => {
  const classes = useStyles()
  const navigateTo = useNavigate()
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const { data: clientDetails, isLoading } = useClientDetails()
  const [currentTab, setCurrentTab] = useState(0)
  const [createAdminModalIsOpen, setCreateAdminModalIsOpen] = useState(false)

  const handleTabChange = useCallback(
    (e: any, tab: number) => {
      setCurrentTab(tab)
      navigateTo(indexToTab(tabIndexMap, tab))
    },
    [setCurrentTab, navigateTo]
  )

  useEffect(() => {
    if (pathname === '/settings') {
      navigateTo('/settings/configurations')
    } else if (tabToIndex(tabIndexMap, pathname) !== currentTab) {
      handleTabChange(null, tabToIndex(tabIndexMap, pathname))
    }
  }, [currentTab, handleTabChange, navigateTo, pathname])

  const tabs = [
    {
      label: 'settings.configurationTab.configurations',
    },
    {
      label: 'settings.admins.admins',
      component: <AdminsTable />,
    },
    { label: 'settings.plugins.plugins', component: <Plugins /> },
    // {
    //   label: 'settings.kyb.kyb',
    //   component: <SettingsKYB client={clientDetails} />,
    // },
    {
      label: 'settings.whitelisting.whitelisting',
      component: <WhitelistingSettings />,
    },
    {
      label: 'settings.integrations.integrations',
      component: <Integrations />,
    },
  ]

  return (
    <div className={classes.root}>
      <CustomBreadcrumbs />

      <StyledActionsBox active={currentTab === 1}>
        {currentTab === 1 && <InviteAdminModal open={createAdminModalIsOpen} setOpen={setCreateAdminModalIsOpen} />}
      </StyledActionsBox>

      <MainTabsWrapper>
        <MainTabs value={currentTab} onChange={handleTabChange}>
          {tabs.map((tab, index) => (
            <MainTab key={index} disableRipple label={t(tab.label)} />
          ))}
        </MainTabs>
      </MainTabsWrapper>
      <MainTabPage minWidth={theme.spacing(123)}>
        {isLoading ? (
          <LoadingContainer container={'paper'} />
        ) : (
          <Routes>
            <Route
              path="configurations"
              element={
                <ProtectedRoute>
                  <ConfigurationsTab organiztion={clientDetails} />
                </ProtectedRoute>
              }
            />
            <Route path="admins" element={<AdminsTable />} />
            <Route
              path="plugins"
              element={
                <ProtectedRoute>
                  <Plugins />
                </ProtectedRoute>
              }
            />
            {/* <Route
              path="kyb"
              element={
                <ProtectedRoute>
                  <SettingsKYB client={clientDetails} />
                </ProtectedRoute>
              }
            /> */}
            <Route
              path="allowlisting"
              element={
                <ProtectedRoute>
                  <WhitelistingSettings />
                </ProtectedRoute>
              }
            />
            <Route
              path="integrations"
              element={
                <ProtectedRoute>
                  <Integrations />
                </ProtectedRoute>
              }
            />
          </Routes>
        )}
      </MainTabPage>
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      padding: theme.spacing(1, 2, 4),
    },
    title: {
      color: theme.palette.text.secondary,
      textTransform: 'capitalize',
    },
    titleContainer: {
      marginLeft: 4,
    },
  })
)

const StyledActionsBox = styled(Box)(({ active }: { active: boolean }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: active ? theme.spacing(-5) : 0,
  paddingLeft: theme.spacing(104),
}))

export default Settings
