import { FC, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useEducationModuleById } from '@/api/education-modules/module-by-id'
import { EducationModule } from '@/api/education-modules/modules'
import LaunchWizard from '@/common/components/LaunchWizard/LaunchWizard'
import VideoPageDetails from './VideoPageDetails'
import VideoPageTopBar from './VideoPageTopBar'
import VideoPageVideoPlayer from './VideoPageVideoPlayer'

export type VideoDetails = {
  video: EducationModule
}

export interface RouteParams {
  id: string
}

const VideoPage: FC = () => {
  const { id } = useParams<RouteParams>()
  const [launchWizardIsOpen, setLaunchWizardIsOpen] = useState(false)
  const { data: educationModule, isLoading, isError } = useEducationModuleById(id)

  const handleLaunch = () => {
    // pause video before
    ;(document.getElementById('video-player') as HTMLVideoElement)?.pause()
    setLaunchWizardIsOpen(true)
  }

  if (!educationModule || isLoading || isError) {
    return null
  }

  return (
    <>
      <VideoPageTopBar onLaunch={handleLaunch} />
      <VideoPageVideoPlayer video={educationModule} />
      <VideoPageDetails video={educationModule} />
      {educationModule && (
        <LaunchWizard
          open={launchWizardIsOpen}
          onClose={() => setLaunchWizardIsOpen(false)}
          assets={[educationModule]}
        />
      )}
    </>
  )
}

export default VideoPage
