import React, { useReducer, useContext, ReactNode } from 'react'
import { emailReducer } from './email/Reducers'
import {
  setEmailSubject,
  setEmailBody,
  setSenderName,
  setLanguage,
  setToneOfVoice,
  setSenderPosition,
  setInstructions,
  setSubjectOutput,
  setBodyOutput,
  setDesignedEmail,
  setAppName,
  setEmailSenderSignature,
  setCustomLogo,
  resetEmail,
  setIconTheme,
  setDifficultyLevel,
  setFindings,
  setFindingsInstructions,
  setButtonColor,
  setButtonTextColor,
  setPersonalizedTemplate,
  setAppLandingTemplate,
} from './email/Actions'
import { EmailState } from './email/Types'
import { SIMULATION_TYPES, WizardState } from './wizard/Types'
import { wizardReducer } from './wizard/Reducers'
import { setCurrentStep, setSimulationType, setVectorType } from './wizard/Actions'
import { LandingPageState } from './landing-page/Types'
import { landingPageReducer } from './landing-page/Reducers'
import {
  setLandingPageOutput,
  setLandingPageType,
  setLandingPageUrl,
  setPageInstructions,
  setRedirectOption,
} from './landing-page/Actions'
import { LandingPageOptionProps } from '../components/LandingPageTypeSelection'
import { SummaryPageState } from './summary-page/Types'
import { summaryPageReducer } from './summary-page/Reducers'
import { setSimulationName, setTopics } from './summary-page/Actions'
import { Findings } from '../components/DifficultyLevelSelector'
import { PhishingSimulationVector } from '@/types/phishingSimulations'

export interface AiSimulationContextType extends EmailState, WizardState, LandingPageState, SummaryPageState {
  // Wizard state
  setCurrentStep: (payload: number) => void
  setSimulationType: (payload: string) => void
  setVectorType: (payload: string) => void

  // Email state
  setLanguage: (payload: string) => void
  setEmailSubject: (payload: string) => void
  setEmailBody: (payload: string) => void
  setSenderName: (payload: string) => void
  setToneOfVoice: (payload: string) => void
  setSenderPosition: (payload: string) => void
  setInstructions: (payload: string) => void
  setSubjectOutput: (payload: string) => void
  setBodyOutput: (payload: string) => void
  setDesignedEmail: (payload: boolean) => void
  setAppName: (payload: string) => void
  setIconTheme: (payload: string) => void
  setButtonColor: (payload: string) => void
  setButtonTextColor: (payload: string) => void
  setAppLandingTemplate: (payload: string) => void
  setEmailSenderSignature: (payload: string) => void
  setCustomLogo: (payload: string) => void
  setDifficultyLevel: (payload: number) => void
  setFindings: (payload: Findings[]) => void
  setFindingsInstructions: (payload: string) => void
  setPersonalizedTemplate: (payload: boolean) => void
  resetEmail: () => void

  // LandingPage state
  setPageInstructions: (payload: string) => void
  setLandingPageType: (payload: LandingPageOptionProps | null) => void
  setLandingPageOutput: (payload: string) => void
  setLandingPageUrl: (payload: string) => void
  setRedirectOption: (payload: string) => void

  // SummaryPage state
  setTopics: (payload: string[]) => void
  setSimulationName: (payload: string) => void
}

interface Props {
  children: ReactNode
}

export const initialEmailState: EmailState = {
  language: 'English',
  emailSubject: '',
  emailBody: '',
  senderName: '',
  toneOfVoice: '',
  senderPosition: '',
  instructions: '',
  subjectOutput: '',
  bodyOutput: '',
  appName: '',
  designedEmail: false,
  emailSenderSignature: '',
  customLogo: '',
  iconTheme: '#ffffff',
  buttonColor: '#00ffff',
  buttonTextColor: '#ffffff',
  appLandingTemplate: '',
  difficultyLevel: 3,
  findings: [],
  findingsInstructions: '',
  personalizedTemplate: true,
}
export const initialWizardState: WizardState = {
  currentStep: 0,
  simulationType: SIMULATION_TYPES.APP,
  vectorType: PhishingSimulationVector.Email,
}
export const initialLandingPageState: LandingPageState = {
  pageInstructions: '',
  landingPageType: null,
  landingPageOutput: '',
  redirectOption: '',
  landingPageUrl: '',
}

export const initialSummaryPageState: SummaryPageState = {
  topics: [],
  simulationName: '',
}

const AiSimulationContext = React.createContext<AiSimulationContextType | undefined>(undefined)

export const AiSimulationProvider: React.FC<Props> = ({ children }) => {
  const [emailState, dispatchEmail] = useReducer(emailReducer, initialEmailState)
  const [wizardState, dispatchWizard] = useReducer(wizardReducer, initialWizardState)
  const [landingPageState, dispatchLandingPage] = useReducer(landingPageReducer, initialLandingPageState)
  const [summaryPageState, dispatchSummaryPage] = useReducer(summaryPageReducer, initialSummaryPageState)

  const contextValue: AiSimulationContextType = {
    ...emailState,
    setLanguage: (payload) => dispatchEmail(setLanguage(payload)),
    setEmailSubject: (payload) => dispatchEmail(setEmailSubject(payload)),
    setEmailBody: (payload) => dispatchEmail(setEmailBody(payload)),
    setSenderName: (payload) => dispatchEmail(setSenderName(payload)),
    setToneOfVoice: (payload) => dispatchEmail(setToneOfVoice(payload)),
    setSenderPosition: (payload) => dispatchEmail(setSenderPosition(payload)),
    setInstructions: (payload) => dispatchEmail(setInstructions(payload)),
    setSubjectOutput: (payload) => dispatchEmail(setSubjectOutput(payload)),
    setBodyOutput: (payload) => dispatchEmail(setBodyOutput(payload)),
    setDesignedEmail: (payload) => dispatchEmail(setDesignedEmail(payload)),
    setAppName: (payload) => dispatchEmail(setAppName(payload)),
    setIconTheme: (payload) => dispatchEmail(setIconTheme(payload)),
    setButtonColor: (payload) => dispatchEmail(setButtonColor(payload)),
    setButtonTextColor: (payload) => dispatchEmail(setButtonTextColor(payload)),
    setAppLandingTemplate: (payload) => dispatchEmail(setAppLandingTemplate(payload)),
    setEmailSenderSignature: (payload) => dispatchEmail(setEmailSenderSignature(payload)),
    setCustomLogo: (payload) => dispatchEmail(setCustomLogo(payload)),
    setDifficultyLevel: (payload) => dispatchEmail(setDifficultyLevel(payload)),
    setFindings: (payload) => dispatchEmail(setFindings(payload)),
    setFindingsInstructions: (payload) => dispatchEmail(setFindingsInstructions(payload)),
    setPersonalizedTemplate: (payload) => dispatchEmail(setPersonalizedTemplate(payload)),
    resetEmail: () => dispatchEmail(resetEmail()),

    ...wizardState,
    setCurrentStep: (payload) => dispatchWizard(setCurrentStep(payload)),
    setSimulationType: (payload) => dispatchWizard(setSimulationType(payload)),
    setVectorType: (payload) => dispatchWizard(setVectorType(payload)),

    ...landingPageState,
    setPageInstructions: (payload) => dispatchLandingPage(setPageInstructions(payload)),
    setLandingPageType: (payload) => dispatchLandingPage(setLandingPageType(payload)),
    setLandingPageOutput: (payload) => dispatchLandingPage(setLandingPageOutput(payload)),
    setLandingPageUrl: (payload) => dispatchLandingPage(setLandingPageUrl(payload)),
    setRedirectOption: (payload) => dispatchLandingPage(setRedirectOption(payload)),

    ...summaryPageState,
    setTopics: (payload) => dispatchSummaryPage(setTopics(payload)),
    setSimulationName: (payload) => dispatchSummaryPage(setSimulationName(payload)),
  }

  return <AiSimulationContext.Provider value={contextValue}>{children}</AiSimulationContext.Provider>
}

export function useAiSimulationContext() {
  const context = useContext(AiSimulationContext)
  if (!context) {
    throw new Error('AiSimulationContextType must be used within AiSimulationProvider')
  }
  return context
}
