import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faCaretDown, faCaretUp } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { useState } from 'react'

export type StatusFilterProps = {
  onChange: (values: string[]) => void
  values: string[]
  label: string
  statuses?: { [key: string]: string }
  width?: number
}

const STATUSES: { [key: string]: string } = {
  Active: 'active',
  Inactive: 'inactive',
}
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  getContentAnchorEl: null,
}

const StatusFilter = ({ values, onChange, label, statuses, width }: StatusFilterProps) => {
  const classes = useStyles()
  const [isFocused, setIsFocused] = useState(false)

  const statusesOptions = statuses || STATUSES
  function handleChange(e) {
    const selectedValues = e.target.value.map((status: string) => statusesOptions[status])
    onChange(selectedValues)
  }

  return (
    <FormControl className={classes.formControl} sx={{ width: width }}>
      <InputLabel size="small" margin="dense" variant="outlined" id={`${label}-label`}>
        {label}
      </InputLabel>
      <Select
        size="small"
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        margin="dense"
        fullWidth
        labelId={`${label}-label`}
        multiple
        // Update value prop to reflect the keys of STATUSES
        value={Object.keys(statusesOptions).filter((key) => values.includes(statusesOptions[key]))}
        onChange={handleChange}
        IconComponent={(props) => (
          <FontAwesomeIcon
            {...props}
            icon={(isFocused ? faCaretUp : faCaretDown) as IconProp}
            style={{ marginRight: '12px', fontSize: '24px' }}
            onClick={props.onClick}
          />
        )}
        input={
          <OutlinedInput
            notched={!!values?.length || isFocused}
            label={label}
            style={{ overflow: 'hidden' }}
            classes={{ notchedOutline: classes.notchedOutline }}
          />
        }
        renderValue={(selected) => (selected as String[]).join(', ')}
        //@ts-ignore
        MenuProps={MenuProps}
        variant="outlined"
        sx={{ height: 42 }}>
        {Object.keys(statusesOptions).map((status) => (
          <MenuItem key={status} value={status}>
            <Checkbox checked={values.includes(statusesOptions[status])} />
            <ListItemText primary={status} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    notchedOutline: {
      '&:hover': {
        borderColor: 'blue',
      },
      borderColor: `${theme.palette.cyan[500]} !important`,
      borderWidth: '1px !important',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 200,
    },
  })
)

export default StatusFilter
