import { useState } from 'react'

type QueryParams = {
  [key: string]: any
}

const useQueryParams = (initilaValues: QueryParams) => {
  const [queryParams, setQueryParams] = useState(initilaValues)

  const updateQueryParam = (newValues: QueryParams) => {
    // Add some extra logic here eg remove empty values

    // Issue with some fileds that required empty value, eg. dates
    // Move this logic to axios serializer

    // Object.keys(newValues).forEach((key) => {
    //   if (!newValues[key]) {
    //     setQueryParams((prevState) => {
    //       const newState = { ...prevState }
    //       delete newState[key]
    //       return newState
    //     })
    //   } else {
    //     setQueryParams((prevState) => ({
    //       ...prevState,
    //       ...newValues,
    //     }))
    //   }
    // })
    setQueryParams((prevState) => ({
      ...prevState,
      skip: 0, // Force to first page
      ...newValues, // If page is updated force will be orverwritten
    }))
  }

  return { queryParams, updateQueryParam }
}

export default useQueryParams
