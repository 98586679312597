import { Button } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment'
import { useState } from 'react'
import { CSVLink } from 'react-csv'
import { useTranslation } from 'react-i18next'

import { Activity } from '@/api/client-activity/client-activity'
import CustomBreadcrumbs from '@/common/components/Breadcrumbs/Breadcrumbs'
import ClientActivityTable from './ClientActivityTable'

export default function ClientActivityDashboard() {
  const classes = useStyles()
  const { t } = useTranslation()
  const [exportData, setExportData] = useState<Activity[]>([])

  const headers = [
    { label: t('clientActivityDashboard.action'), key: 'text' },
    { label: t('clientActivityDashboard.date'), key: 'date' },
  ]

  function parseData(data: Activity[]) {
    return data.map(({ text, timestamp }) => ({
      text,
      date: moment.unix(timestamp).format('DD/MM/YY HH:mm:ss'),
    }))
  }

  return (
    <div className={classes.root}>
      <div className={classes.flexContainer}>
        <CustomBreadcrumbs />
        <CSVLink
          filename={'Cywareness Activity Log'}
          data={parseData(exportData)}
          headers={headers}
          style={{ textDecoration: 'none' }}>
          <Button variant={'outlined'} color={'primary'}>
            {t('clientActivityDashboard.export')}
          </Button>
        </CSVLink>
      </div>
      <ClientActivityTable setExportData={setExportData} />
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1, 4, 4),
      display: 'flex',
      flexDirection: 'column',
      overflow: 'visible',
    },

    flexContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
  })
)
