import axios from 'axios'
import { API_URL } from '../constants'
import { useQuery } from 'react-query'
import { ServerError } from '../types'

// Moved to educationModules type, remove on refactor of api
export type Quiz = {
  _id: string
  name: string
  languages: string[]
  questions: {
    _id: string
    question: {
      [language: string]: string
    }
    answers: {
      answer: {
        [language: string]: string
      }
      _id: string
    }[]
    submitted_answer?: {
      is_correct: boolean
      correct_answers: string[]
    }
  }[]
}

type QuizByIdVariables = {
  quiz_id: string
  campaign: string
}

export async function getQuizById(key, params: QuizByIdVariables) {
  const url = `${API_URL}/quizzes/get_quiz_by_id/?campaign=${params.campaign}&quiz_id=${params.quiz_id}`
  const { data } = await axios.get(url, { withCredentials: true })
  return data
}

export function useQuizById(params: QuizByIdVariables) {
  return useQuery<Quiz, ServerError>(['quizzes', params], getQuizById, {
    enabled: !!params.quiz_id,
  })
}
