import { Box, styled, Typography } from '@mui/material'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { BulkUploadConflictMember } from '@/types/members'
import { theme } from '@/theme/theme'

type ConflictMemberCardProps = {
  member: BulkUploadConflictMember
  matches: string[]
}

const ConflictMemberCard: FC<ConflictMemberCardProps> = ({ member, matches }) => {
  const { t } = useTranslation()

  return (
    <Box border="1px solid gray" borderRadius="16px" padding={2} margin={1}>
      <Typography>
        {t('bulkUserUpload.exampleTable.firstName') + ': '}
        <StyledMemberPropety component="span" match={matches.includes('first_name')}>
          {member.first_name}
        </StyledMemberPropety>
      </Typography>

      <Typography>
        {t('bulkUserUpload.exampleTable.lastName') + ': '}
        <StyledMemberPropety component="span" match={matches.includes('last_name')}>
          {member.last_name}
        </StyledMemberPropety>
      </Typography>
      <Typography>
        {t('bulkUserUpload.exampleTable.username') + ': '}
        <StyledMemberPropety component="span" match={matches.includes('email')}>
          {member.email}
        </StyledMemberPropety>
      </Typography>
      <Typography>
        {t('bulkUserUpload.exampleTable.phone') + ': '}
        <StyledMemberPropety component="span" match={matches.includes('phone_number')}>
          {member.phone_number}
        </StyledMemberPropety>
      </Typography>
      <Typography>
        {t('bulkUserUpload.exampleTable.office') + ': '}
        <StyledMemberPropety component="span" match={matches.includes('office')}>
          {member.office}
        </StyledMemberPropety>
      </Typography>
      <Typography>
        {t('bulkUserUpload.exampleTable.groups') + ': '}
        {
          <StyledMemberPropety component="span" match={matches.includes('groups')}>
            {member.groups.join(', ')}{' '}
          </StyledMemberPropety>
        }
      </Typography>
    </Box>
  )
}

const StyledMemberPropety = styled(Typography)(({ match }: { match?: boolean }) => ({
  fontWeight: match ? 'bold' : 'normal',
  color: match ? theme.palette.pink[800] : 'gray',
}))

export default ConflictMemberCard
