import React, { createContext, useContext } from "react";

import { useCurrentUser, CurrentUser } from "../api/profile/current-user";

type AuthContextValue = {
  user: CurrentUser | undefined;
  isLoading: boolean;
};
export const AuthContext = createContext<AuthContextValue>({
  user: undefined,
  isLoading: true,
});

export default function AuthProvider({ children }) {
  const { data, isLoading } = useCurrentUser();
  return (
    <AuthContext.Provider
      value={{
        user: data,
        isLoading: isLoading && !data,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  return useContext(AuthContext);
};
