import endent from "endent";

const createPrompt = (
  emailBody: string,
  toneOfVoice: string,
  language: string
): string => {
  return endent`
    Act as a content writer.  
    Compose an short email subject about ${emailBody}. 
    Tone of voice should be ${toneOfVoice}, but don't mention it in the subject.
    Write email in ${language} language.
    Return subject only with no other comments, (no backticks, DO NOT wrap in quotations).
`.trim();
};

export const createEmailSubjectPrompt = (
  language: string,
  toneOfVoice: string,
  emailBody: string
) => {
  return createPrompt(emailBody, toneOfVoice, language);
};
