export const SET_WIZARD_CURRENT_STEP = 'SET_WIZARD_CURRENT_STEP'
export const SET_SIMULATION_TYPE = 'SET_SIMULATION_TYPE'
export const SET_VECTOR_TYPE = 'SET_VECTOR_TYPE'

export const SIMULATION_TYPES = {
  APP: 'app',
  HUMAN: 'human',
} as const

export enum VectorType {
  EMAIL = 'email',
  SMS = 'sms',
  WHATSAPP = 'whatsapp',
}

export interface WizardState {
  currentStep: number
  simulationType: string
  vectorType: string
}

export type WizardAction =
  | { type: typeof SET_WIZARD_CURRENT_STEP; payload: number }
  | { type: typeof SET_VECTOR_TYPE; payload: string }
  | { type: typeof SET_SIMULATION_TYPE; payload: string }
