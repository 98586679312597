import { palette } from '../palette'

export const MuiIconButton = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      '&:hover': {
        background: palette.cyan[900] + 20,
      },
      '&:active': {
        background: palette.cyan[900] + 40,
      },
    },
  },
}
