import { useState } from 'react'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { ReportObjectFull } from '../../api/reports/reports'
import { Typography, ListItem, Dialog, DialogTitle, DialogContent } from '@mui/material'
import { useTranslation } from 'react-i18next'

export default function ReportHeaders({ data }: { data: ReportObjectFull['headers'] }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const [selectedHeader, setSelectedHeader] = useState<{
    header: string
    value: string
  } | null>(null)

  function openModal(values: { header: string; value: string }) {
    setSelectedHeader(values)
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
    setSelectedHeader(null)
  }

  return (
    <>
      <div className={classes.root}>
        {Object.entries(data).map(([header, value]) => (
          <ListItem onClick={() => openModal({ header, value })} button key={header}>
            <Typography noWrap variant={'subtitle2'}>
              {header}: {value}
            </Typography>
          </ListItem>
        ))}
      </div>
      <Dialog maxWidth={false} onClose={closeModal} open={isOpen}>
        {selectedHeader ? (
          <>
            <DialogTitle>
              {t('reportPage.header')}: <div className={classes.header}>{selectedHeader.header}</div>
            </DialogTitle>
            <DialogContent>
              <Typography variant={'h6'}>{t('reportPage.value')}:</Typography>
              <div className={classes.codeblock}>
                <Typography variant={'body2'}>{selectedHeader.value}</Typography>
              </div>
            </DialogContent>
          </>
        ) : null}
      </Dialog>
    </>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      padding: theme.spacing(2, 0),
      overflowY: 'auto',
    },
    scoreContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(0.5, 1),
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.common.white,
    },
    header: {
      background: theme.palette.background.codeBlock,
      color: theme.palette.text.code,
      padding: theme.spacing(1, 2),
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${theme.palette.divider}`,
      minWidth: 400,
      marginTop: theme.spacing(1),
    },
    codeblock: {
      background: theme.palette.background.codeBlock,
      color: theme.palette.black,
      padding: theme.spacing(2, 2),
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${theme.palette.divider}`,
      minWidth: 400,
      margin: theme.spacing(1, 0),
    },
  })
)
