import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { Typography, Accordion, AccordionSummary, AccordionDetails, FormControlLabel, Switch } from '@mui/material'
import { faChevronDown } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { useTranslation } from 'react-i18next'
import { gmailText } from './gmailText'
import { outlookText } from './outlookText'
import { useSetting } from '@/api/settings/get-settings'
import { useUpdateSettings } from '@/api/settings/update-settings'
import useToast from '@/common/hooks/useToast'

export default function Plugins() {
  const classes = useStyles()
  const { t } = useTranslation()
  const { data: settings } = useSetting('reports')
  const [updateSettings] = useUpdateSettings()
  const { successToast, errorToast } = useToast()

  async function handleToggleReportPlugin(enabled: boolean) {
    await updateClientSettings({
      report_button: enabled,
      configuration: 'reports',
    })
  }

  async function updateClientSettings(values) {
    try {
      await updateSettings(values)
      successToast(t('settings.adminDetails.companyDetails.changesSaved'))
    } catch (e) {
      errorToast(t('settings.adminDetails.companyDetails.errors.failedToUpdate'))
    }
  }

  return (
    <div className={classes.root}>
      <Typography gutterBottom variant={'h6'}>
        {t('settings.plugins.reportPlugins')}
      </Typography>
      <Typography variant={'body1'}>{t('settings.plugins.pluginsInstructions')}</Typography>
      <div className={classes.panelsContainer}>
        <Accordion>
          <AccordionSummary
            expandIcon={<FontAwesomeIcon icon={faChevronDown as IconProp} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant={'subtitle1'}>{t('settings.plugins.gmail')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{gmailText()}</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<FontAwesomeIcon icon={faChevronDown as IconProp} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant={'subtitle1'}> {t('settings.plugins.outlook')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{outlookText()}</Typography>
          </AccordionDetails>
        </Accordion>
        <FormControlLabel
          sx={{ mt: 2 }}
          control={
            <Switch
              checked={settings?.report_button || false}
              onChange={() => handleToggleReportPlugin(!settings?.report_button)}
            />
          }
          label={t('settings.plugins.reportPlugin')}
        />
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      minHeight: 300,
    },
    panelsContainer: {
      paddingTop: theme.spacing(2),
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  })
)
