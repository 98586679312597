import { useMutation, useQueryCache } from 'react-query';
import { ServerError } from '../types';
import { API_URL } from '../constants';
import axios from 'axios';
import { App } from './apps';

async function setOnboarded(appKey: string) {
  const url = `${API_URL}/apps/onboard/`;
  const { data } = await axios.post(url, { app_key: appKey }, { withCredentials: true });
  return data.data;
}

export function useOnboardApp() {
  const queryCache = useQueryCache();
  return useMutation<string, ServerError, string>(setOnboarded, {
    throwOnError: true,
    onMutate: (appKey) => {
      queryCache.cancelQueries('apps');
      const previousAlerts = queryCache.getQueryData('apps') as App[];
      const updatedAlerts = (previousAlerts || []).map((app) => {
        return app.key === appKey ? { ...app, onboarded: true } : app;
      });
      //@ts-ignore
      queryCache.setQueryData('apps', () => updatedAlerts);
      return () => queryCache.setQueryData('apps', previousAlerts);
    },
    //@ts-ignore
    onError: (err, variables, rollback) => rollback(),
    onSettled: () => {
      queryCache.invalidateQueries('apps');
    },
  });
}
