import axios from "axios";
import { API_URL } from "../constants";
import { useMutation, useQueryCache } from "react-query";
import { ServerError } from "../types";

export type UpdateSettingsParams = {
  report_button?: boolean;
  configuration?: string;
};

export async function updateSettings(
  params: UpdateSettingsParams
): Promise<string> {
  const { configuration, ...bodyParams } = params;
  const url = `${API_URL}/settings/update/${configuration}/`;
  const { data } = await axios.post(
    url,
    { ...bodyParams },
    { withCredentials: true }
  );
  return data.data;
}

export function useUpdateSettings() {
  const queryCache = useQueryCache();
  return useMutation<string, ServerError, UpdateSettingsParams>(
    updateSettings,
    {
      throwOnError: true,
      onMutate: (settings) => {
        queryCache.cancelQueries("settings");
        const previousSettings = queryCache.getQueryData("settings");
        if (previousSettings) {
          const updatedSettings = {
            ...previousSettings,
            ...settings,
          };
          queryCache.setQueryData("settings", () => updatedSettings);
          return () => queryCache.setQueryData("settings", previousSettings);
        }
      },
      //@ts-ignore
      onError: (err, variables, rollback) => rollback(),
      onSettled: () => {
        queryCache.invalidateQueries("settings");
      },
    }
  );
}
