import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Typography, styled } from '@mui/material'
import { FC } from 'react'

import { theme } from '@/theme/theme'

type MainAccordionProp = {
  title: string
  defaultExpanded?: boolean
  children: any
}
const MainAccordion: FC<MainAccordionProp> = ({ title, defaultExpanded, children }) => {
  return (
    <Accordion
      elevation={0}
      sx={{ '&:before': { height: '0px' }, width: '100%', border: 'none' }}
      defaultExpanded={defaultExpanded}>
      <AccordionSummary
        expandIcon={<FontAwesomeIcon icon={faCaretDown as IconProp} width={24} />}
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{ borderTop: 'none' }}>
        <Box display="flex" width={'100%'}>
          <Box
            sx={{
              position: 'absolute',
              left: 8,
              background: theme.palette.cyan[500],
              width: '40px',
              height: '40px',
              borderRadius: '10px',
              marginTop: 0.3,
            }}
          />
          <CustomDivider
            sx={{
              width: '100%',
              alignItems: 'center',
            }}>
            <Typography fontSize={31} fontWeight={700}>
              {title}
            </Typography>
          </CustomDivider>
          <Box
            sx={{
              display: 'flex',
              position: 'absolute',
              right: 8,
              background: theme.palette.cyan[500],
              width: '40px',
              height: '40px',
              borderRadius: '10px',
              marginTop: 0.3,
            }}></Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box display="flex" flexDirection="column" alignItems="center">
          {children}
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}
const CustomDivider = styled(Divider)(() => ({
  borderColor: theme.palette.cyan[500],
  '&::before': {
    borderTop: `thin solid ${theme.palette.cyan[500]}`,
  },
  '&::after': {
    borderColor: theme.palette.cyan[500],
  },
  textAlign: 'center',
}))
export default MainAccordion
