import { Button, CircularProgress, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { mixed, string } from 'yup'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { faCircleExclamation } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import useForm, { Field } from '@/common/components/Form/useForm'
import Form from '@/common/components/Form/Form'
import { useResetPassword } from '@/api/auth/reset-password'

const fields: Field[] = [
  {
    name: 'password',
    label: 'setPassword.form.password',
    validationRule: string().required('setPassword.errors.missingPassword'),
    type: 'password',
    required: true,
    autoFocus: false,
  },
  {
    name: 'confirmPassword',
    label: 'setPassword.form.confirmPassword',
    validationRule: mixed().test('match', 'setPassword.errors.passwordsDontMatch', function (confirmPassword) {
      // @ts-ignore
      return confirmPassword === this?.options?.parent?.password
    }),
    type: 'password',
    required: true,
    autoFocus: false,
  },
]

export default function SetPassword() {
  const { t } = useTranslation()
  const classes = useStyles()
  const [setNewPassword, { isLoading, error }] = useResetPassword(() => navigateTo('/reset-password/success'))
  const { username, token } = useParams()
  const navigateTo = useNavigate()

  function handleSetPassword(formValues) {
    const { password } = formValues
    setNewPassword({ password, username, token })
  }

  const { formProps, handleSubmit } = useForm({
    fields,
    onSubmit: handleSetPassword,
  })

  return (
    <div className={classes.container}>
      <Typography variant="h5">{t('setPassword.setPassword')}</Typography>
      <Typography variant="subtitle1">{t('setPassword.setPasswordDescription')}</Typography>
      <div className={classes.form}>
        {error && (
          <div className={classes.errorMessage}>
            <FontAwesomeIcon icon={faCircleExclamation as IconProp} className={classes.errorIcon} />
            <Typography variant="subtitle2" color={'inherit'}>
              {error?.response?.data?.message}
            </Typography>
          </div>
        )}
        <Form {...formProps} />
        <Button onClick={handleSubmit} fullWidth variant="contained" color="primary" className={classes.submit}>
          {isLoading ? <CircularProgress color={'secondary'} size={24} /> : t('setPassword.setPassword')}
        </Button>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorMessage: {
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.error.main,
    paddingBottom: theme.spacing(2),
  },
  errorIcon: {
    marginRight: 4,
  },
}))
