export enum TABLE_SIZE_REFERENCE_KEYS {
  CAMPAIGNS_TABLE = "cw_campaigns_table_size",
  REPORTS_TABLE = "cw_reports_table_size",
  WIZARD_TABLE = "cw_wizard_table_size",
  ALL_USERS_TABLE = "cw_users_table_size",
  LOGS_TABLE = "cw_logs_table_size",
  SETTINGS_TABLE = "cw_settings_table_size",
  SIMULATIONS_TABLE = "cw_simulations_table_size",
  UPCOMINNG_CAMPAIGNS_TABLE = "cw_upcoming_campaigns_table_size",
}

const TABLE_SIZE_DEFAULTS = {
  [TABLE_SIZE_REFERENCE_KEYS.CAMPAIGNS_TABLE]: 25,
  [TABLE_SIZE_REFERENCE_KEYS.REPORTS_TABLE]: 25,
  [TABLE_SIZE_REFERENCE_KEYS.WIZARD_TABLE]: 5,
  [TABLE_SIZE_REFERENCE_KEYS.ALL_USERS_TABLE]: 25,
  [TABLE_SIZE_REFERENCE_KEYS.LOGS_TABLE]: 25,
  [TABLE_SIZE_REFERENCE_KEYS.SETTINGS_TABLE]: 10,
  [TABLE_SIZE_REFERENCE_KEYS.SIMULATIONS_TABLE]: 20,
  [TABLE_SIZE_REFERENCE_KEYS.UPCOMINNG_CAMPAIGNS_TABLE]: 5,
  DEFAULT: 10,
};

export function getDefaultTableSize(key: TABLE_SIZE_REFERENCE_KEYS) {
  const storedValue = localStorage.getItem(key);
  return storedValue
    ? parseInt(storedValue)
    : TABLE_SIZE_DEFAULTS[key] || TABLE_SIZE_DEFAULTS.DEFAULT;
}

export function setDefaultTableSize(
  key: TABLE_SIZE_REFERENCE_KEYS,
  value: number
) {
  localStorage.setItem(key, value.toString());
}
