import axios from "axios";
import { API_URL } from "../constants";
import { useQuery } from "react-query";
import { ServerError } from "../types";

export type BrandObject = {
  _id: string;
  icon: string;
  label: string;
  type?: string;
  value: string;
  icon_theme: string;
  button_color: string;
  button_text_color: string;
  landing_page: string;
};
export async function getAllBrands() {
  const url = `${API_URL}/templates/brands/`;
  const { data } = await axios.get(url, { withCredentials: true });
  return data;
}

export function useAllBrands() {
  return useQuery<BrandObject[], ServerError>("brands", getAllBrands);
}
