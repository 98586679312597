import { FC } from 'react'
import { useTheme } from '@mui/material/styles'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
type VideoDetails = {
  video: any
}
const VideoPageQuizzesLink: FC<VideoDetails> = ({ video }) => {
  const { t } = useTranslation()
  const theme = useTheme()

  if (!video) {
    return <></>
  }

  return (
    <div style={{ marginLeft: theme.spacing(2) }}>
      {video.quizzes?.length ? (
        <Typography>
          <b>{t('createVideoModal.includesQuiz')}: </b>
          <div
            style={{
              display: 'inline',
              cursor: 'pointer',
              color: theme.palette.blue[900],
              textTransform: 'lowercase',
              textDecoration: 'underline',
            }}
            color="primary"
            onClick={() => window.open(`/content/quiz/preview/${video.quizzes?.[0]?._id}`, '_blank')}
          >
            {t('createVideoModal.previewQuiz')}
          </div>
        </Typography>
      ) : null}
    </div>
  )
}

export default VideoPageQuizzesLink
