import { theme } from '@/theme/theme'
import { faExclamationSquare } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography } from '@mui/material'
import { FC, ReactNode } from 'react'

const HelperTextComponent: FC<{ hasError?: boolean; helperText?: ReactNode }> = ({ hasError, helperText }) => {
  return hasError ? (
    <Typography display="flex" alignItems={'center'} color={theme.palette.blueDianne[900]} gap={1} fontSize={'10px'}>
      <FontAwesomeIcon icon={faExclamationSquare} color={theme.palette.red[900]} fontSize={18}></FontAwesomeIcon>
      {helperText}
    </Typography>
  ) : (
    <></>
  )
}

export default HelperTextComponent
