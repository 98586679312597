import axios from 'axios'
import { API_URL } from '../constants'
import { useQuery } from '@tanstack/react-query'

export type DomainObject = {
  _id: string
  verified: boolean
  domain: string
  client_id: string
  verify_token: string
  has_members: boolean
}

export async function getDomains() {
  const url = `${API_URL}/domains/`
  const { data } = await axios.get(url, { withCredentials: true })
  return data
}

export const useDomains = () => {
  return useQuery({
    queryKey: ['domains'],
    queryFn: getDomains,
  })
}
