import { Typography } from '@mui/material'
import { useTheme } from '@mui/styles'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useToggleSavedContent } from '@/api/profile/toggle-saved-asset'
import { usePhishingSimulationDelete } from '@/api/phishing-simulations/delete'
import Alert from '@/common/components/Alert/Alert'
import useToast from '@/common/hooks/useToast'
import { useAuth } from '@/context/Auth'
import { PhishingSimulation } from '@/types/phishingSimulations'
import SimulationPageActions from './SimulationPageActions'
import SimulationPageTopics from './SimulationPageTopics'

type SimulationDetailsProps = {
  simulation: PhishingSimulation
  onEditSimulation: (simulation: PhishingSimulation) => void
  onDeleteSimulation: (simulation: PhishingSimulation) => void
  onSaveSimulation: (simulation: PhishingSimulation) => void
}

const SimulationDetails: FC<SimulationDetailsProps> = ({
  simulation,
  onEditSimulation,
  onDeleteSimulation,
  onSaveSimulation,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { user: { saved_assets = [] } = {} } = useAuth()

  const [saveAsset] = useToggleSavedContent()
  const { errorToast, successToast } = useToast()
  const { mutateAsync: deletePhishingSimulation } = usePhishingSimulationDelete()

  const [simulationForDeletion, setSimulationForDeletion] = useState<PhishingSimulation | null>(null)

  const [deleteSimulationAlertIsOpen, setDeleteSimulationAlertIsOpen] = useState(false)

  const openDeleteSimulationAlert = (simulation: PhishingSimulation) => {
    setSimulationForDeletion(simulation)
    setDeleteSimulationAlertIsOpen(true)
  }

  const closeDeleteSimulationAlert = () => {
    setSimulationForDeletion(null)
    setDeleteSimulationAlertIsOpen(false)
  }

  const confirmDeleteSimulationAlert = () => {
    simulationForDeletion && onDeleteSimulation(simulationForDeletion)
  }

  if (!simulation) {
    return <></>
  }

  return (
    <>
      <div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: theme.spacing(4),
          }}
        >
          <Typography fontSize={18} fontWeight={theme.typography.fontWeightBold}>
            {simulation.name}
          </Typography>
          <SimulationPageActions
            deleteSimulation={openDeleteSimulationAlert}
            editSimulation={onEditSimulation}
            saveSimulation={onSaveSimulation}
            isSaved={saved_assets.includes(simulation.id)}
            simulation={simulation}
          />
        </div>

        <Typography marginTop={theme.spacing(4)}>{t('simulationLibrary.simulationPage.about')}</Typography>
        <div
          style={{
            marginTop: theme.spacing(4),
          }}
        >
          <SimulationPageTopics simulation={simulation} />
        </div>
      </div>
      <Alert
        handleClose={closeDeleteSimulationAlert}
        open={deleteSimulationAlertIsOpen}
        onConfirm={confirmDeleteSimulationAlert}
        cancelButton
        buttonLabel={t('simulationLibrary.deleteAlert.delete')}
        dialogTitle={t('simulationLibrary.deleteAlert.title')}
        dialogText={t('simulationLibrary.deleteAlert.subtitle')}
        dialogContent={
          <Typography style={{ fontWeight: theme.typography.fontWeightBold }}>
            {t('simulationLibrary.deleteAlert.message', {
              value: simulationForDeletion?.name,
            })}
          </Typography>
        }
      />
    </>
  )
}

export default SimulationDetails
