import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function InvalidToken() {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigateTo = useNavigate();

  return (
    <div className={classes.root}>
      <Typography variant={"h3"} className={classes.title}>
        {t("feedbackPage.invalidTokenTitle")}
      </Typography>
      <Typography variant={"h6"} className={classes.message}>
        {t("feedbackPage.invalidTokenMessage")}
      </Typography>
      <Button
        onClick={() => navigateTo("/")}
        variant={"contained"}
        color={"primary"}
      >
        {t("feedbackPage.backHome")}
      </Button>
    </div>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: "100vh",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    title: { marginBottom: theme.spacing(2) },
    message: { marginBottom: theme.spacing(4) },
    button: {},
  })
);
