import axios from 'axios'
import { API_URL } from '../constants'
import { useQuery } from 'react-query'
import { ServerError } from '../types'
import { ReportObjectMin } from '../reports/reports'
import { CampaignListItem } from '@/types/campaigns'

export type AdminAnalytics = {
  pending_reports: {
    items: ReportObjectMin[]
    count: number
  }
  future_campaigns: {
    count: number
    items: CampaignListItem[]
  }
  active_campaigns: number
  awareness_score: number
  awareness_last_30_days: number[]
}

async function getAnalytics() {
  const url = `${API_URL}/dashboard/`
  const { data } = await axios.get(url, { withCredentials: true })
  return data
}

export function useAdminAnalytics() {
  return useQuery<AdminAnalytics, ServerError>('admin_analytics', getAnalytics)
}
