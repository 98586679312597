import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FC, ReactElement } from 'react'

type NoResultsProps = {
  textId?: string
  height?: number
  message?: ReactElement
}

const NoResults: FC<NoResultsProps> = ({ textId, height, message }) => {
  const classes = useStyles({ height })
  const { t } = useTranslation()
  return (
    <div className={classes.root}>
      <Typography variant={'subtitle1'} color={'textSecondary'}>
        {message}
        {t(textId)}
      </Typography>
    </div>
  )
}

export default NoResults
const useStyles = makeStyles<Theme, { height: number | undefined }>(() =>
  createStyles({
    root: {
      height: ({ height }) => height ?? 300,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      textAlign: 'center',
    },
  })
)
