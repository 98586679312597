import { useEffect, useRef, useState } from 'react'
import isEqual from 'lodash/isEqual'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Autocomplete from '@mui/material/Autocomplete'
import { uniqBy } from 'lodash'

type FilterProps = {
  filters: { key: string; label: string }[]
  onChange: (values) => void
  showClearButton?: boolean
}

export type FilterObject = {
  label: string
  key: string
  value: string
}

export default function Filter({ filters, onChange, showClearButton = true }: FilterProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [value, setValue] = useState<any>(null)
  const [open, toggleOpen] = useState(false)
  const [activeFilters, setActiveFilters] = useState<FilterObject[]>([])
  const inputRef = useRef<HTMLInputElement>()

  const prevAppliedFiltersRef = useRef([])

  const handleClose = () => {
    setDialogValue({
      label: '',
      key: '',
      value: '',
    })

    toggleOpen(false)
  }

  const [dialogValue, setDialogValue] = useState({
    label: '',
    key: '',
    value: '',
  })

  const handleSubmit = (event) => {
    event.preventDefault()
    setActiveFilters((prevState) => [
      ...prevState,
      {
        label: dialogValue.label,
        key: dialogValue.key,
        value: dialogValue.value,
      },
    ])

    handleClose()
  }

  function handleChange(event, newValue) {
    if (typeof newValue === 'string') {
      // ignore if nothing was selected from list
    } else if (newValue && newValue.inputValue) {
      //if filter is selected from list after typing query
      if (inputRef.current) {
        inputRef.current.blur()
      }
      setActiveFilters((prevState) => [
        ...prevState,
        {
          label: newValue.label,
          key: newValue.key,
          value: newValue.inputValue,
        },
      ])
    } else {
      //if filter is selected from list without entering a query
      toggleOpen(true)
      setDialogValue({
        label: newValue.label,
        key: newValue.key,
        value: '',
      })
    }
  }

  function handleDialogChange({ target: { value } }) {
    setDialogValue((prevState) => ({ ...prevState, value }))
  }

  function handleRemoveFilter(key: string) {
    const updatedFilters = activeFilters.filter((f) => f.key !== key)
    setActiveFilters(updatedFilters)
  }

  function handleClearFilters() {
    setActiveFilters([])
  }

  useEffect(() => {
    const appliedFilters = uniqBy([...activeFilters, ...filters], 'key')

    if (!isEqual(appliedFilters, prevAppliedFiltersRef.current)) {
      onChange(appliedFilters)
      prevAppliedFiltersRef.current = appliedFilters
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilters, filters])

  // @ts-ignore
  return (
    <>
      <div className={classes.root}>
        <ul className={classes.chipsContainer}>
          {activeFilters.map((filter) => (
            <li key={filter.key}>
              <Chip label={`${filter.label}: "${filter.value}"`} onDelete={() => handleRemoveFilter(filter.key)} />
            </li>
          ))}
        </ul>

        <Autocomplete
          size="small"
          value={value}
          onChange={handleChange}
          filterOptions={(options, params) => {
            const activeFilterKeys = activeFilters.map((filter) => filter.key)
            let filtered = options.filter((option) => !activeFilterKeys.includes(option.key))

            if (params.inputValue !== '') {
              filtered = filtered.map((option) => ({
                inputValue: params.inputValue,
                label: option.label,
                key: option.key,
                message: `${option.label} starts with "${params.inputValue}"`,
              }))
            }
            return filtered
          }}
          options={filters}
          getOptionLabel={(option) => {
            const { inputValue = '' } = option
            const { label = '' } = option
            return `${label} starts with ${inputValue}`
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          // renderOption={(option) => option.message ?? option.label}
          className={classes.input}
          freeSolo
          renderInput={(params) => {
            // @ts-ignore
            inputRef.current = params.inputProps?.ref.current
            return (
              <TextField
                {...params}
                placeholder={'Add Filter...'}
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                  className: classes.notchedOutline,
                }}
              />
            )
          }}
        />
        {showClearButton ? <Button onClick={handleClearFilters}>{t('filterComponent.clear')}</Button> : null}
      </div>
      <Dialog open={open} maxWidth={'xs'} fullWidth onClose={handleClose}>
        <DialogTitle>{dialogValue.label}</DialogTitle>
        <DialogContent>
          <TextField autoFocus onChange={handleDialogChange} fullWidth variant="outlined" value={dialogValue.value} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('createGroupModal.cancel')}
          </Button>
          <Button onClick={handleSubmit} color="primary">
            {t('createGroupModal.save')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1, 2),
      marginBottom: theme.spacing(1),
      border: `1.5px solid ${theme.palette.divider}`,
      borderRadius: theme.shape.borderRadius,
    },
    input: {
      flex: 1,
      minWidth: 200,
      height: '100%',
    },
    menuItem: {
      width: 300,
    },
    chipsContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexWrap: 'wrap',
      listStyle: 'none',
      margin: 0,
      padding: 0,
      paddingRight: theme.spacing(2),
    },
    notchedOutline: {
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
  })
)
