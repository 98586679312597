import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'

import React, { useEffect, useRef, useState } from 'react'
import { theme } from '@/theme/theme'

interface EmailSubjectProps {
  text: string
  isModal: boolean
}

const EmailBodyOutput: React.FC<EmailSubjectProps> = ({ text = '', isModal = false }) => {
  const classes = useStyles(isModal)
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const [iframe, setIfarame] = useState(iframeRef.current)

  if (iframe) {
    const doc = iframe.contentDocument || iframe.contentWindow?.document
    if (doc) {
      doc.open()
      doc.write(text)
      doc.close()
    }
  }
  useEffect(() => {
    setIfarame(iframeRef.current)
  }, [isModal])

  return (
    <Box style={{ background: theme.palette.white, height: '100%' }}>
      <iframe title="body-output" ref={iframeRef} className={classes.iFrameStyle} />
    </Box>
  )
}

export default EmailBodyOutput

const useStyles = makeStyles((isModal: boolean) =>
  createStyles({
    iFrameStyle: {
      width: '100%',
      height: '100%',
      transformOrigin: 'top left',
      fontSize: isModal === true ? 20 : 14,
      border: 0,
      position: 'absolute',
      top: 0,
      left: 0,
      pointerEvents: 'none',
    },
  })
)
