import { FC } from 'react'
import Modal from '@mui/material/Modal'
import trashImage from '@/assets/images/account-center/trach-can.svg'
import { useTranslation } from 'react-i18next'
import { ButtonsWrapper, ContentWrapper, ImageWrapper, ModalConent, StyledTypography } from './PopupsStyledComponents'
import useToast from '../../hooks/useToast'
import { getErrorMessage } from '@/api/utils/get-error'
import { ModalProps } from './TreeChart'
import { Button } from '@mui/material'

type DeleteModalProps = ModalProps & {
  deleteNode: () => void
}
const DeletePopup: FC<DeleteModalProps> = ({ open, setOpen, deleteNode, organization }) => {
  const { t } = useTranslation()
  const { successToast, errorToast } = useToast()
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <Modal open={open} onClose={handleClose}>
      <ModalConent>
        <ImageWrapper>
          <img src={trashImage} alt="trash" />
        </ImageWrapper>

        <ContentWrapper>
          <StyledTypography>
            <h4>{t('accountCenter.deletePopup.holdYourHorses')}</h4>
            <p>{t('accountCenter.deletePopup.areYouSure')}</p>
          </StyledTypography>

          <ButtonsWrapper>
            <Button variant="outlined" onClick={handleClose}>
              {t('accountCenter.deletePopup.cancel')}
            </Button>
            <Button
              onClick={() => {
                try {
                  deleteNode()

                  //TODO: delete function.
                  successToast('deleted')
                  handleClose()
                } catch (e) {
                  const errorMessage = getErrorMessage(e)
                  errorToast(
                    t('settings.admins.modal.errors.failedToSendInvitation', {
                      value: errorMessage ? errorMessage : '',
                    })
                  )
                }
              }}
              variant="contained"
            >
              {t('accountCenter.deletePopup.delete')}
            </Button>
          </ButtonsWrapper>
        </ContentWrapper>
      </ModalConent>
    </Modal>
  )
}

export default DeletePopup
