import axios from 'axios'
import { API_URL } from '../constants'
import { useQuery } from '@tanstack/react-query'
import { ServerError } from '../types'

const getBanners = async () => {
  const url = `${API_URL}/assets/library_banners/`
  const { data } = await axios.get(url, {
    withCredentials: true,
  })
  return data
}

export const useBanners = () => {
  return useQuery<any, ServerError>({
    queryKey: ['banners'],
    queryFn: () => getBanners(),
  })
}
