import React from "react";
import ComingSoon from "../../../common/components/ComingSoon/ComingSoon";

export default function NewslettersLibrary() {
  const details = [
    {
      title: "Upload your own newsletter",
      bullets: [
        "Choose your title",
        "Write your description",
        "Choose the languages supported",
        "Choose your learing topics",
      ],
    },
    {
      title: "Explore",
      bullets: [
        "Reading",
        "Summaries",
        "Advanced Search",
        "Classified by learning topics & leves",
      ],
    },
    {
      title: "Assign newsletters",
      bullets: [
        "To employees / groups  /offices",
        "Choose your localization by employees region & native languages",
      ],
    },
  ];
  return (
    <div>
      <ComingSoon details={details} />
    </div>
  );
}
