import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import { SENDER_POSITION } from "../constants/sender-position";
import { useAiSimulationContext } from "../state";

export const SenderPosition = () => {
  const { senderPosition, setSenderPosition } = useAiSimulationContext();

  return (
    <Autocomplete
      freeSolo
      fullWidth
      value={senderPosition}
      onChange={(event, newValue) => setSenderPosition(newValue || "")}
      onInputChange={(event, newInputValue) =>
        setSenderPosition(newInputValue || "")
      }
      options={SENDER_POSITION}
      clearIcon={null}
      renderInput={(params) => (
        <TextField
          {...(params as any)}
          size="small"
          label="Sender Position"
          InputProps={{ ...params.InputProps }}
          variant="outlined"
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "10px",
            },
          }}
        />
      )}
    />
  );
};

export default SenderPosition;
