import { Box, Button, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import cywarenessLogo from '@/assets/images/cywareness-calculator-logo.svg'
import { theme } from '@/theme/theme'

const Header = () => {
  const { t } = useTranslation()
  const navigateTo = useNavigate()
  return (
    <StyledHeaderBox>
      <StyledSideBox />
      <img src={cywarenessLogo}></img>
      <StyledSideBox>
        <Button
          variant="contained"
          onClick={() => {
            navigateTo('/account-center')
          }}>
          {t('pricingCalculator.header.back')}
        </Button>
      </StyledSideBox>
    </StyledHeaderBox>
  )
}

const StyledHeaderBox = styled(Box)(() => ({
  width: '100%',
  height: '96px',
  background: theme.palette.blueGray[900],
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const StyledSideBox = styled(Box)(() => ({
  width: '260px',
}))
export default Header
