import { InputAdornment, MenuItem, Select, TextField, Typography } from '@mui/material'
import { MuiTextFieldProps } from '@mui/x-date-pickers/internals'
import { useField } from 'formik'
import { FC } from 'react'
import {
  CountryIso2,
  defaultCountries,
  FlagImage,
  parseCountry,
  ParsedCountry,
  usePhoneInput,
} from 'react-international-phone'
import 'react-international-phone/style.css'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faLockKeyhole } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MuiLockedTextFieldSX, MuiTextFieldSX } from '../TextFieldV2/'
import HelperTextComponent from '../TextFieldV2/HelperTextComponent'
import { theme } from '@/theme/theme'

type PhoneNumberFieldProps = {
  name: string
  label: string
  value?: string
  disableFormik?: boolean
  isLocked?: boolean
  defaultCountry?: string
  onChange?: (newValue: string) => void
  disabled?: boolean
}

type CountrySelectProps = {
  country: ParsedCountry
  setCountry: (country: CountryIso2) => void
  disabled?: boolean
}

const PhoneNumberField: FC<PhoneNumberFieldProps> = ({
  name,
  label,
  value,
  disableFormik = false,
  defaultCountry = 'us',
  isLocked = false,
  onChange,
  ...otherProps
}) => {
  const [field, meta, helpers] = useField(name)

  const configTextField: MuiTextFieldProps = {
    ...otherProps,
    fullWidth: true,
    label: label,
    placeholder: label as string,
  }

  const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } = usePhoneInput({
    defaultCountry: defaultCountry,
    value: value || field.value,
    countries: defaultCountries,
    onChange: (data) => {
      if (!disableFormik) {
        helpers.setValue(data.phone)
      } else {
        onChange?.(data.phone)
      }
    },
  })

  if (!disableFormik) {
    // Merge Formik-related props if Formik is enabled
    Object.assign(configTextField, {
      ...field,
      error: meta.touched && Boolean(meta.error),
      helperText: <HelperTextComponent hasError={meta.touched && Boolean(meta.error)} helperText={meta.error} />,
    })
  }

  return (
    <TextField
      {...configTextField}
      value={!disableFormik ? inputValue : value}
      type="tel"
      onChange={handlePhoneValueChange}
      inputRef={inputRef}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" style={{ marginRight: '2px', marginLeft: '-8px' }}>
            <CountrySelect country={country} setCountry={setCountry} disabled={configTextField.disabled} />
          </InputAdornment>
        ),
        endAdornment: isLocked ? (
          <InputAdornment position="start" style={{ cursor: 'default', width: '15px' }}>
            <FontAwesomeIcon icon={faLockKeyhole as IconProp} color={theme.palette.blueDianne[900]} />
          </InputAdornment>
        ) : null,
      }}
      sx={{ ...MuiTextFieldSX, ...(isLocked && MuiLockedTextFieldSX) }}
    />
  )
}

const CountrySelect: FC<CountrySelectProps> = ({ country, setCountry, disabled }) => (
  <Select
    MenuProps={{
      style: {
        height: '300px',
        width: '360px',
        top: '10px',
        left: '-34px',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
      },
    }}
    disabled={disabled}
    sx={{
      width: 'max-content',
      // Remove default outline (display only on focus)
      fieldset: {
        display: 'none',
      },
      '&.Mui-focused:has(div[aria-expanded="false"])': {
        fieldset: {
          display: 'block',
        },
      },
      // Update default spacing
      '.MuiSelect-select': {
        padding: '8px',
        paddingRight: '24px !important',
      },
      svg: {
        right: 0,
      },
    }}
    value={country.iso2}
    onChange={(e) => setCountry(e.target.value as CountryIso2)}
    renderValue={(value) => <FlagImage iso2={value} style={{ display: 'flex' }} />}>
    {defaultCountries.map((c) => {
      const country = parseCountry(c)
      return (
        <MenuItem key={country.iso2} value={country.iso2}>
          <FlagImage iso2={country.iso2} style={{ marginRight: '8px' }} />
          <Typography marginRight="8px">{country.name}</Typography>
          <Typography color="gray">+{country.dialCode}</Typography>
        </MenuItem>
      )
    })}
  </Select>
)

export default PhoneNumberField
