import React, { useCallback, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ColumnInterface } from 'react-table'
import { AllUsersQueryVariables, useUsers } from '../../api/users/users'
import PaginatingTable from '../../common/components/Tables/PaginatingTable'
import { useQueryCache } from 'react-query'
import LoadingContainer from '../../common/components/LoadingContainer/LoadingContainer'
import Filter, { FilterObject } from '../../common/components/Tables/Filter'
import { Group } from '../../api/groups/groups'
import moment from 'moment'
import { Paper } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import HumanizedScore from '../../common/components/HumanizedScore/HumanizedScore'

type OfficeUsersTableProps = {
  groups?: Group[]
  currentOfficeId: string
}
const INITIAL_QUERY_FILTERS: {
  fullname: string
  email: string
  offices: string[]
  groups: string[]
  offset: number
  startIndex: number
} = {
  fullname: '',
  email: '',
  offices: [],
  groups: [],
  offset: 5,
  startIndex: 0,
}

export default function OfficeUsersTable({ currentOfficeId }: OfficeUsersTableProps) {
  INITIAL_QUERY_FILTERS.offices.push(currentOfficeId)
  const { t } = useTranslation()
  const navigateTo = useNavigate()
  const queryCache = useQueryCache()
  const [queryFilters, setQueryFilters] = useState<AllUsersQueryVariables>(INITIAL_QUERY_FILTERS)
  const { resolvedData: queryData, isLoading, isFetching } = useUsers(queryFilters)
  const { items: users, count } = queryData ?? { count: 0, items: [] }
  const [pageCount, setPageCount] = useState(0)

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      setQueryFilters((prevState) => ({
        ...prevState,
        startIndex: prevState.offset * pageIndex,
        offset: pageSize,
      }))
      // Only update the data if this is the latest fetch
      setPageCount(Math.ceil(count / pageSize))
      queryCache.prefetchQuery([
        'users',
        {
          ...queryFilters,
          startIndex: pageSize * (pageIndex + 1),
          offset: pageSize,
        },
      ])
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [count]
  )

  const columns: ColumnInterface[] = useMemo(
    () => [
      {
        Header: t('users.usersTable.name'),
        accessor: 'first_name',
        Cell: (data) => {
          const { first_name, last_name } = data.row.original
          return `${first_name} ${last_name}`
        },
      },
      {
        Header: t('users.usersTable.email'),
        accessor: 'username',
      },
      {
        Header: t('users.usersTable.score'),
        accessor: 'awareness_score',
        Cell: ({ value }) => <HumanizedScore score={value} />,
      },
      {
        Header: t('users.usersTable.lastCampaign'),
        accessor: 'last_campaign',
        Cell: ({ value }) => (value ? moment.unix(value).format('DD/MM/YY') : ''),
      },
      {
        Header: t('users.usersTable.status'),
        accessor: 'state',
        Cell: ({ value }) => (value == 'active' ? t('users.usersTable.active') : t('users.usersTable.inactive')),
      },
    ],
    [t]
  )

  const filters = [
    { key: 'fullname', label: 'Name', value: '' },
    { key: 'email', label: 'Email', value: '' },
  ]

  const handleFilterChange = useCallback(
    (values: FilterObject[]) => {
      const newFilters = {
        offices: [currentOfficeId],
      }
      values.forEach((appliedFilter) => {
        newFilters[appliedFilter.key] = appliedFilter.value
      })
      setQueryFilters((prevState) => ({
        ...INITIAL_QUERY_FILTERS,
        ...newFilters,
      }))
    },
    [currentOfficeId]
  )

  function handleNavigateToUserProfile({ original }) {
    navigateTo(`/recipients/users/${original._id}`)
  }

  return !isLoading ? (
    <div>
      <Filter filters={filters} onChange={handleFilterChange} />
      <Paper>
        <PaginatingTable
          enableCheckbox={false}
          noResultsTextId={'users.usersTable.noResults'}
          columns={columns}
          data={users ?? []}
          fetchData={fetchData}
          loading={isLoading || isFetching}
          pageCount={pageCount}
          count={count}
          onRowClick={handleNavigateToUserProfile}
        />
      </Paper>
    </div>
  ) : (
    <LoadingContainer />
  )
}
