import React, { useEffect, useMemo, useState } from "react";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import TrainingCampaignDetails from "./TrainingCampaignDetails";
import ModulePicker from "./ModulePicker";
import moment from "moment";
import useToast from "../../common/hooks/useToast";
import { useNavigate, useParams } from "react-router-dom";
import { useNewTrainingCampaign } from "../../api/training-campaigns/campaigns-new";
import { useEditTrainingCampaign } from "../../api/training-campaigns/campaigns-edit";
import { useEducationModules } from "../../api/education-modules/modules";
import { useTrainingCampaignById } from "../../api/training-campaigns/campaign-by-id";

export type AutoCompleteItem = {
  _id: string;
  label: string;
  type: "users" | "groups" | "offices";
};

export default function CreateTrainingCampaign() {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigateTo = useNavigate();
  const [createCampaign, { isLoading }] = useNewTrainingCampaign();
  const [editCampaign, { isLoading: editCampaignIsLoading }] =
    useEditTrainingCampaign();
  const { data: modules } = useEducationModules();
  const { errorToast } = useToast();
  const { _id } = useParams();
  const { data: campaignToEdit } = useTrainingCampaignById(_id);
  const [campaignName, setCampaignName] = useState<string>("");
  const [isDemoCampaign, setIsDemoCampaign] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<AutoCompleteItem[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<AutoCompleteItem[]>([]);
  const [selectedOffices, setSelectedOffices] = useState<AutoCompleteItem[]>(
    []
  );
  const [dates, setDates] = useState({
    start: moment(),
    end: moment().add(7, "days"),
  });
  const [moduleId, setModuleId] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);

  const educationModulesById = useMemo(() => {
    let modulesObject = {};
    modules &&
      modules?.length > 0 &&
      modules.forEach((module) => {
        modulesObject[module._id] = module;
      });
    return modulesObject;
  }, [modules]);

  async function handleLaunchCampaign() {
    const users = selectedUsers.map((user) => user._id);
    const groups = selectedGroups.map((group) => group._id);
    const offices = selectedOffices.map((office) => office._id);
    const scheduled = moment(dates.start).unix();
    const duration = moment(dates.end).unix() - moment(dates.start).unix();
    const education_module_id = moduleId;

    if (isEditMode) {
      try {
        const updatedCampaign = await editCampaign({
          name: campaignName,
          duration,
          scheduled,
          education_module_id,
          _id,
        });
        updatedCampaign &&
          navigateTo(`/training-campaigns/${updatedCampaign._id}`);
      } catch (e) {
        errorToast(t("createTrainingCampaign.errors.failedToUpdate"));
      }
    } else {
      try {
        const newCampaign = await createCampaign({
          name: campaignName,
          users,
          office_ids: offices,
          group_ids: groups,
          duration,
          scheduled,
          education_module_id,
        });
        newCampaign && navigateTo(`/training-campaigns/${newCampaign._id}`);
      } catch (e) {
        errorToast(t("createTrainingCampaign.errors.failedToCreate"));
      }
    }
  }

  function handleSelectTemplate(templateId: string) {
    setModuleId(templateId);
  }

  useEffect(() => {
    if (modules) {
      setModuleId(modules[0]._id);
    }
  }, [modules]);

  useEffect(() => {
    if (campaignToEdit?._id) {
      setIsEditMode(true);
      const users: AutoCompleteItem[] = campaignToEdit.users.map(
        ({ _id, username }) => ({
          _id,
          label: username,
          type: "users",
        })
      );
      setSelectedUsers(users);
      setDates({
        start: moment.unix(campaignToEdit.scheduled),
        end: moment.unix(campaignToEdit.scheduled + campaignToEdit.duration),
      });
      setModuleId(campaignToEdit.education_module_id);
      setCampaignName(campaignToEdit.name);
    }
  }, [campaignToEdit]);

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <Typography gutterBottom variant={"h5"}>
          {isEditMode
            ? t("createTrainingCampaign.editCampaign")
            : t("createTrainingCampaign.createCampaign")}
        </Typography>
        <Button
          disabled={
            isLoading ||
            editCampaignIsLoading ||
            [...selectedUsers, ...selectedGroups, ...selectedOffices].length < 1
          }
          variant={"contained"}
          color={"primary"}
          onClick={handleLaunchCampaign}
          className={classes.submitButton}
        >
          {isLoading ? (
            <CircularProgress color={"inherit"} size={24} />
          ) : isEditMode ? (
            t("createTrainingCampaign.update")
          ) : (
            t("createTrainingCampaign.launch")
          )}
        </Button>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TrainingCampaignDetails
            isEditMode={isEditMode}
            dates={dates}
            setDates={setDates}
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
            selectedGroups={selectedGroups}
            setSelectedGroups={setSelectedGroups}
            selectedOffices={selectedOffices}
            setSelectedOffices={setSelectedOffices}
            isDemoCampaign={isDemoCampaign}
            setIsDemoCampaign={setIsDemoCampaign}
            campaignName={campaignName}
            setCampaignName={setCampaignName}
          />
        </Grid>
        <Grid item xs={12}>
          <ModulePicker
            modules={modules ?? []}
            selectModule={handleSelectTemplate}
            selectedModule={educationModulesById[moduleId]}
          />
        </Grid>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: "100%",
      width: "100%",
      padding: theme.spacing(1, 2, 4),
    },
    titleContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: theme.spacing(2),
    },
    submitButton: {
      color: theme.palette.common.white,
      width: 200,
    },
  })
);
