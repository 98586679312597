import { FC, useEffect, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import LandingPageTypeSelection from './components/LandingPageTypeSelection'
import { LandingPageInstructions } from './components/LandingPageInstructions'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { OpenAIRequest } from '@/api/open-ai/openai-request'
import { createLandingPagePrompt } from './utils/createLandingPagePrompt'
import LandingPageOutput from './components/LandingPageOutput'
import MonitorPreview from './components/MonitorPreview'
import EmailPlaceholder from './components/EmailPlaceholder'
import AiLoader from './components/AiLoader'
import { SimulationTypePreview } from './components/SimulationTypePreview'
import { useAiSimulationContext, VectorType } from './state'
import { loadingPhrases } from './constants/loading-phrases'
import useToast from '@/common/hooks/useToast'
import VectorToggleButtons from './components/VectorToggleButtons'
import MobilePhonePreview from '@/common/components/MobilePhonePreview'
import BrowserAppPreview from '@/common/components/MobilePhonePreview/BrowserAppPreview'

const LOAD_TIME = 10000

const LandingPageBuilder: FC = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [randomPhrase, setRandomPhrase] = useState('')
  const [intervalId, setIntervalId] = useState()
  const { errorToast } = useToast()
  const [isPreviewInFullscreen, setIsPreviewInFullscreen] = useState(false)

  const [isGenerateClicked, setIsGenerateClicked] = useState(false)

  const {
    currentStep,
    setCurrentStep,
    appName,
    customLogo,
    pageInstructions,
    landingPageType,
    landingPageOutput,
    setLandingPageOutput,
    senderName,
    vectorType,
    iconTheme,
    buttonColor,
    buttonTextColor,
    appLandingTemplate,
  } = useAiSimulationContext()

  useEffect(() => {
    updateRandomPhrase(loading)
  }, [loading])

  const updateRandomPhrase = (loading: boolean) => {
    if (loading) {
      setRandomPhrase(getRandomPhrase())

      const id = setInterval(() => {
        setRandomPhrase(getRandomPhrase())
      }, LOAD_TIME)

      setIntervalId(id)
    } else {
      clearInterval(intervalId)
    }
  }

  const getRandomPhrase = () => {
    const randomIndex = Math.floor(Math.random() * loadingPhrases.length)
    return loadingPhrases[randomIndex]
  }

  const handleContinue = () => {
    setCurrentStep(currentStep + 1)
  }

  const createLandingPage = async () => {
    let data

    setLandingPageOutput('')
    setLoading(true)
    try {
      const response = await handleGenerateLandingPage()
      const message = await response.json()
      data = message?.choices[0]?.message?.content
      if (data) {
        setLandingPageOutput(data)
        setLoading(false)
      }
    } catch (error) {
      errorToast(t('contentLibrary.aiBuilder.errors.landingPageGenerateFail'))
    }
  }

  const handleGenerateLandingPage = async () => {
    const prompt = createLandingPagePrompt(
      landingPageType,
      pageInstructions,
      customLogo,
      appName,
      iconTheme,
      buttonColor,
      buttonTextColor,
      appLandingTemplate,
      vectorType !== VectorType.EMAIL
    )

    try {
      const stream = await OpenAIRequest({ prompt, maxTokens: 2500 })
      return new Response(stream)
    } catch (error) {
      console.error(error)
      return new Response('Error', { status: 500 })
    }
  }

  const isGenerated = landingPageOutput && true

  const renderGenerateOrRegenerate = () => {
    if (!landingPageOutput && !isGenerateClicked) {
      return (
        <Button
          onClick={async (e) => {
            await createLandingPage()
            e.stopPropagation()
          }}
          variant={'contained'}
          disabled={!landingPageType || loading}
        >
          {t('simulationLibrary.ai.generate')}
        </Button>
      )
    }

    return (
      <Button
        onClick={async (e) => {
          setIsGenerateClicked(true)
          setLandingPageOutput('')
          await createLandingPage()
          e.stopPropagation()
        }}
        disabled={!landingPageType || loading}
        variant={'outlined'}
      >
        {t('simulationLibrary.ai.regenerate')}
      </Button>
    )
  }

  return (
    <Box
      sx={{
        width: '100%',
        padding: '50px',
        background: '#FFFFFF',
        boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.25)',
        borderRadius: '15px',
      }}
    >
      <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="flex-start" gap={3.25}>
        {/* Form */}
        <Box display="flex" flexDirection="column" flex={1}>
          <Box display="flex" flexDirection="column" gap={1.5}>
            <SimulationTypePreview />
            <VectorToggleButtons disabled />
            <LandingPageTypeSelection />
            <LandingPageInstructions />
            {/* <RedirectLandingPage /> */}
          </Box>
        </Box>

        {/* Preview */}
        <Box display="flex" flexDirection="column" gap={3.75} flex={1} alignItems="center">
          <Box sx={{ marginTop: vectorType === VectorType.EMAIL ? 0 : '90px' }}>
            {vectorType === VectorType.EMAIL && (
              <>
                <MonitorPreview
                  pageType="landing"
                  isLoading={loading}
                  hasContent={!!isGenerated}
                  onFullscreenToggle={(isFullscreen) => setIsPreviewInFullscreen(isFullscreen)}
                >
                  {landingPageOutput ? (
                    <LandingPageOutput
                      text={landingPageOutput}
                      vectorType={vectorType}
                      isModal={isPreviewInFullscreen}
                    />
                  ) : (
                    <>
                      {loading ? (
                        <Box p={1}>
                          <Typography sx={{ textAlign: 'center', color: theme.palette.white }}>
                            {randomPhrase}
                          </Typography>
                          <AiLoader />
                        </Box>
                      ) : (
                        <EmailPlaceholder landingPage={true} isModal={isPreviewInFullscreen} />
                      )}
                    </>
                  )}
                </MonitorPreview>
              </>
            )}
            {(vectorType === VectorType.SMS || vectorType === VectorType.WHATSAPP) && (
              <Box style={{ width: '250px' }}>
                <MobilePhonePreview isLoading={loading} fullScreenButton>
                  <BrowserAppPreview fakeUrl={appName || senderName}>
                    <LandingPageOutput text={landingPageOutput} vectorType={vectorType} isModal={false} />
                  </BrowserAppPreview>
                </MobilePhonePreview>
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      {/* Spacer */}
      <Box mt={2.5}></Box>

      {/* Action Buttons */}
      <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="flex-start" gap={3.25}>
        <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center" width="50%">
          {renderGenerateOrRegenerate()}
        </Box>

        {!!landingPageOutput && (
          <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center" width="50%">
            <Button onClick={handleContinue} variant={'contained'} disabled={!landingPageOutput}>
              {t('simulationLibrary.ai.continue')}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default LandingPageBuilder
