import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { useQueryCache } from 'react-query'

import { API_URL } from '@/api/constants'

const toggleSavedAssetPackage = async (id: string | undefined) => {
  const { data } = await axios.post(
    `${API_URL}/profile/toggle-saved-asset-package/${id}/`,
    {},
    {
      withCredentials: true,
    }
  )

  return data
}

export const useToggleSavedAssetPackage = () => {
  const queryCache = useQueryCache()

  return useMutation({
    mutationFn: toggleSavedAssetPackage,
    onSuccess: async () => {
      // NOTE: Clear react query v2 user object
      queryCache.invalidateQueries('user')
    },
  })
}
