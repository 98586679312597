import React from "react";
import { TextField } from "@mui/material";
import { useAiSimulationContext } from "../state";

export const LandingPageInstructions = () => {
  const { pageInstructions, setPageInstructions } = useAiSimulationContext();

  return (
    <TextField
      fullWidth
      multiline
      rows={6}
      label="Instructions"
      placeholder="Here you can instruct the AI to add buttons, design, elements, placeholders, specific signatures and much more!"
      InputProps={{
        disableUnderline: true,
        style: {
          fontSize: 16,
          color: "gray",
          whiteSpace: "normal",
        },
      }}
      InputLabelProps={{
        shrink: true,
      }}
      value={pageInstructions}
      onChange={(e) => setPageInstructions(e.target.value)}
    />
  );
};
