import React from 'react'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { Button, CircularProgress, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useDomains } from '../../api/domains/get'

import DomainsTable from '../../common/components/DomainsTable/DomainsTable'
import { useUpdateClient } from '../../api/client/update-client'

type VerifyDomainsProps = {
  updateStep: (isCompleted: boolean) => void
}

export default function VerifyDomains({ updateStep }: VerifyDomainsProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const navigateTo = useNavigate()
  const { data: domains } = useDomains()
  const [updateClient, { isLoading }] = useUpdateClient()

  const isCompleted = !!(domains && domains?.length > 0)

  async function handleNextStep() {
    await updateClient({ onboarding: true })
    updateStep(isCompleted)
    navigateTo('/')
  }

  return (
    <div className={classes.root}>
      <div className={classes.body}>
        <Typography gutterBottom variant={'h6'}>
          {t('onboarding.verifyDomains.verifyDomains')}
        </Typography>
        <Typography variant={'body1'}>{t('onboarding.verifyDomains.mustverify')}</Typography>
        <Typography variant={'body1'}>{t('onboarding.verifyDomains.addRecords')}</Typography>
        <DomainsTable />
      </div>
      <div className={classes.footer}>
        <Button disabled={true} variant={'contained'} color={'primary'}>
          {t('onboarding.verifyDomains.back')}
        </Button>
        <Button disabled={!isCompleted} variant={'contained'} color={'primary'} onClick={handleNextStep}>
          {isLoading ? <CircularProgress color={'inherit'} size={24} /> : t('onboarding.verifyDomains.next')}
        </Button>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      padding: theme.spacing(8, 4, 4),
      display: 'flex',
      flexDirection: 'column',
    },
    body: {
      flexGrow: 1,
    },
    footer: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: theme.spacing(2, 0),
    },
  })
)
