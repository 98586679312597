//old
export function serializeArray<T extends { _id: string; [key: string]: any }>(array: T[]): Serialized<T> {
  const byId: { [key: string]: T } = {}
  array.forEach((item) => {
    byId[item._id] = item
  })
  return { array, byId }
}

export interface Serialized<T> {
  byId: { [key: string]: T }
  array: T[]
}

//new - reactQueryV5
export const paramsSerializer = (params: { [s: string]: unknown } | ArrayLike<unknown>) => {
  return Object.entries(Object.fromEntries(Object.entries(params).filter(([key, value]) => value !== '')))
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        const arrayValues = value.filter((v) => v != null).map((v) => `${key}=${v}`)
        return arrayValues.length > 0 ? arrayValues.join('&') : ''
      }
      if (value != null) {
        return `${key}=${value}`
      }
      return ''
    })
    .filter((part) => part !== '')
    .join('&')
}
