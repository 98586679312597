import { Box, styled } from '@mui/material'

const MainTabsWrapper = styled(Box)(() => ({
  borderRadius: '15px 15px 0px 0px',
  boxShadow: '-1px -0px 3px 0.1px lightgray',
  width: 'fit-content',
  background: 'white',
  marginTop: '30px',
}))

export default MainTabsWrapper
