import { useMemo, ReactElement } from 'react'
import moment from 'moment'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { useTranslation } from 'react-i18next'
import { ColumnInterface } from 'react-table'
import { AdminAnalytics } from '@/api/admin-analytics/admin-analytics'
import { useNavigate } from 'react-router-dom'
import SimpleTable from '@/common/components/Tables/SimpleTable'
import TruncatedText from '@/common/components/TruncatedText/TruncatedText'
import { faClapperboardPlay, faBlockQuestion, faFishingRod } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Typography } from '@mui/material'
import TimezoneTooltip from '@/common/components/InfoTooltip/TimezoneTooltip'
import { getDefaultTableSize, TABLE_SIZE_REFERENCE_KEYS, setDefaultTableSize } from '@/common/utils/table-size'
import { CampaignListItem } from '@/types/campaigns'

export enum ASSET_TYPE {
  phishing_simulation = 'phishing_simulation',
  training_video = 'training_video',
}

type UpcomingCampaignsProps = {
  campaigns: AdminAnalytics['future_campaigns']
}

const getIconsForCampaign = (campaign: CampaignListItem): ReactElement[] => {
  const icons: ReactElement[] = []

  if (campaign?.asset_metadata && campaign.asset_metadata.include_quiz) {
    icons.push(
      <div
        style={{
          padding: '0  10px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <FontAwesomeIcon icon={faBlockQuestion as IconProp} fontSize={24} color="#000" />
        <Typography variant="caption" color="primary">
          Quiz
        </Typography>
      </div>
    )
  }

  if (campaign.asset_type === ASSET_TYPE.phishing_simulation) {
    icons.push(
      <div
        style={{
          padding: '0  10px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <FontAwesomeIcon icon={faFishingRod as IconProp} fontSize={24} color="#000" />
        <Typography variant="caption" color="primary">
          Simulation
        </Typography>
      </div>
    )
  } else if (campaign.asset_type === ASSET_TYPE.training_video) {
    icons.push(
      <div
        style={{
          padding: '0 10px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <FontAwesomeIcon icon={faClapperboardPlay as IconProp} fontSize={24} color="#000" />
        <Typography variant="caption" color="primary">
          Video
        </Typography>
      </div>
    )
  }

  return icons
}

export default function UpcomingCampaigns({ campaigns }: UpcomingCampaignsProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const navigateTo = useNavigate()

  const defaultRowsPerPage = getDefaultTableSize(TABLE_SIZE_REFERENCE_KEYS.UPCOMINNG_CAMPAIGNS_TABLE)

  function handleChangeRowsPerPage(pageSize: number) {
    setDefaultTableSize(TABLE_SIZE_REFERENCE_KEYS.UPCOMINNG_CAMPAIGNS_TABLE, pageSize)
  }

  const columns: ColumnInterface[] = useMemo(
    () => [
      {
        Header: t('adminDashboard.campaignsTable.campaign'),
        accessor: 'name',
        disableSortBy: true,
        Cell: ({ value, ...rest }) => {
          const { is_test } = rest.row.original
          return (
            <>
              <TruncatedText text={value} maxLength={10} />
              {is_test && (
                <span className={classes.testCampaignTicket}>{t('adminDashboard.campaignsTable.testCampaign')}</span>
              )}
            </>
          )
        },
      },
      {
        Header: () => t('adminDashboard.campaignsTable.totalUsers'),
        accessor: 'users_count',
        disableSortBy: false,
        Cell: ({ value }) => value,
      },
      {
        Header: 'Starting',
        accessor: 'start_date',
        disableSortBy: false,
        Cell: ({ row }) => {
          const { start_date = '', start_tz = '' } = row.original
          return (
            <div style={{ textAlign: 'center', display: 'inline-flex' }}>
              {start_tz === 'per_user'
                ? moment.utc(start_date).format(`MMM DD, YYYY - HH:mm`)
                : moment.tz(start_date, start_tz).local().format(`MMM DD, YYYY - HH:mm`)}
              {start_tz === 'per_user' ? (
                <TimezoneTooltip title={t('adminDashboard.campaignsTable.campaignTimezone')} />
              ) : (
                <></>
              )}
            </div>
          )
        },
      },
      {
        Header: 'Ending',
        accessor: 'end_date',
        disableSortBy: false,
        Cell: ({ row }) => {
          const { end_date = '', end_tz = '' } = row.original
          return (
            <div style={{ textAlign: 'center' }}>
              {end_tz === 'per_user'
                ? moment.utc(end_date).format(`MMM DD, YYYY - HH:mm`)
                : moment.tz(end_date, end_tz).local().format(`MMM DD, YYYY - HH:mm`)}
            </div>
          )
        },
      },
      {
        Header: 'Type',
        disableSortBy: false,
        Cell: ({ row }) => {
          const data = row.original
          return (
            <div
              style={{
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {getIconsForCampaign(data)}
            </div>
          )
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  )

  function onRowClick(row) {
    const {
      original: { _id, asset_type },
    } = row
    if (asset_type === ASSET_TYPE.training_video) {
      navigateTo(`/training-campaigns/${_id}`)
    }
    if (asset_type === ASSET_TYPE.phishing_simulation) {
      navigateTo(`/campaign/${_id}`)
    }
  }

  return (
    <div className={classes.table}>
      <SimpleTable
        enableCheckbox={false}
        columns={columns}
        data={campaigns.items}
        noResultsTextId={'adminDashboard.campaignsTable.noCampaigns'}
        setData={() => {}}
        onRowClick={onRowClick}
        initialPageSize={defaultRowsPerPage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    table: {
      overflow: 'hidden',
      '& .MuiTableCell-head': {
        textAlign: 'center',
        paddingLeft: 40,
        '&:first-child': {
          paddingLeft: 15,
          textAlign: 'left',
        },
      },
      '& .MuiTableCell-body': {
        textAlign: 'center',
        '&:first-child': {
          textAlign: 'left',
        },
      },
    },
    usersTags: {
      display: 'flex',
      justifyContent: 'center',
    },
    testCampaignTicket: {
      background: theme.palette.cyan[500],
      marginLeft: '10px',
      borderRadius: '3px',
      padding: '2px 6px',
      fontSize: '10px',
    },
  })
)
