import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faCheck, faCircleExclamation, faGift } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, Box, Button, CircularProgress, Container, Grid, Paper, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { isPossiblePhoneNumber } from 'libphonenumber-js'
import { FC, useState, useEffect } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { mixed, string } from 'yup'

import { useSignup } from '@/api/auth/signup'
import LogoIcon from '@/assets/images/logo.svg'
import Form from '@/common/components/Form/Form'
import useForm, { Field } from '@/common/components/Form/useForm'
import { AppliedPromoConfigurations } from '@/types/promoCodes'
import { useGetLatestTerms } from '@/api/terms/get'
import { Terms } from '@/types/terms'

const fields: Field[] = [
  {
    name: 'full_name',
    label: 'signup.form.fullName',
    validationRule: string().required('signup.errors.missingFullName'),
    type: 'text',
    required: true,
    autoFocus: false,
    gridItemProps: {
      xs: 12,
    },
  },
  {
    name: 'company_name',
    label: 'signup.form.companyName',
    validationRule: string().required('signup.errors.missingCompanyName'),
    type: 'text',
    required: true,
    autoFocus: false,
    gridItemProps: {
      sm: 6,
      xs: 12,
    },
  },
  {
    name: 'domain',
    label: 'signup.form.domain',
    validationRule: string().required('signup.errors.missingDomain'),
    type: 'text',
    required: true,
    gridItemProps: {
      sm: 6,
      xs: 12,
    },
  },
  {
    name: 'phone_number',
    label: 'signup.form.phone',
    validationRule: string()
      .test({
        name: 'phone-number-validation',
        test: isPossiblePhoneNumber,
        message: 'signup.errors.invalidPhone',
      })
      .required('signup.errors.missingPhone'),
    type: 'text',
    required: true,
    autoFocus: false,
    gridItemProps: {
      sm: 6,
      xs: 12,
    },
  },
  {
    name: 'username',
    label: 'signup.form.username',
    validationRule: string().required('signup.errors.missingEmail').email('signup.errors.invalidEmail'),
    type: 'email',
    autoComplete: 'email',
    required: true,
    autoFocus: true,
    gridItemProps: {
      sm: 6,
      xs: 12,
    },
  },

  {
    name: 'password',
    label: 'signup.form.password',
    validationRule: string().required('signup.errors.missingPassword'),
    type: 'password',
    required: true,
    autoFocus: false,
    gridItemProps: {
      sm: 6,
      xs: 12,
    },
  },
  {
    name: 'confirmPassword',
    label: 'signup.form.confirmPassword',
    validationRule: mixed().test('match', 'signup.errors.passwordsDontMatch', function (confirmPassword) {
      // @ts-ignore
      return confirmPassword === this?.options?.parent?.password
    }),
    type: 'password',
    required: true,
    autoFocus: false,
    gridItemProps: {
      sm: 6,
      xs: 12,
    },
  },
  {
    name: 'promo_code',
    label: 'signup.form.promoCode',
    type: 'text',
    required: false,
    autoFocus: false,
    gridItemProps: {
      xs: 12,
    },
  },
  {
    name: 'accept_terms',
    label: 'signup.form.terms',
    type: 'legal_checkbox',
    initialValue: false,
    required: false,
    autoFocus: false,
    gridItemProps: {
      xs: 12,
    },
  },
]

const Signup: FC = () => {
  const { t } = useTranslation()
  const navigateTo = useNavigate()
  const [signup, { isLoading, error, isSuccess }] = useSignup()
  const classes = useStyles({ isSuccess })
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [configurations, setConfigurations] = useState<null | AppliedPromoConfigurations>(null)
  const { data: latestTerms } = useGetLatestTerms()

  async function handleSignup(formValues) {
    const recaptcha = (executeRecaptcha && (await executeRecaptcha('signup'))) || ''
    const { username, full_name, password, domain, company_name, phone_number, promo_code, accept_terms } = formValues
    const response = await signup({
      username,
      full_name,
      password,
      company_domains: [domain],
      recaptcha,
      company_name,
      phone_number,
      promo_code,
      accept_terms,
      terms: latestTerms?._id as string,
    })
    if (response?.used_promo_code) {
      setConfigurations(response?.applied_configurations)
    }
  }

  const { formProps, handleSubmit } = useForm({
    fields,
    onSubmit: handleSignup,
    clearOnSubmit: false,
  })

  return (
    <Container component="main" maxWidth="sm">
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          {isSuccess ? (
            <FontAwesomeIcon icon={faCheck as IconProp} />
          ) : (
            <img alt={'Cywareness Logo'} src={LogoIcon} height={24} width={22} />
          )}
        </Avatar>
        <Typography component="h1" variant="h5">
          {isSuccess ? t('signup.success') : t('signup.signup')}
        </Typography>
        <div className={classes.form}>
          {error && (
            <div className={classes.errorMessage}>
              <FontAwesomeIcon icon={faCircleExclamation as IconProp} className={classes.errorIcon} />
              <Typography variant="subtitle2" color={'inherit'}>
                {error?.response?.data?.message}
              </Typography>
            </div>
          )}
          {isSuccess ? (
            <div>
              <div className={classes.textContainer}>
                <Typography variant={'subtitle1'}>{t('signup.accountCreated')}</Typography>
                <Typography variant={'subtitle1'}>{t('signup.verifyEmail')}</Typography>
                {configurations ? <PromoBox configurations={configurations} /> : null}
              </div>
            </div>
          ) : (
            <Form {...formProps} gridContainerProps={{ spacing: 2 }} />
          )}
          <Button
            onClick={isSuccess ? () => navigateTo('/login') : handleSubmit}
            disabled={!formProps.values.accept_terms}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}>
            {isLoading ? (
              <CircularProgress color={'secondary'} size={24} />
            ) : isSuccess ? (
              t('signup.goToLogin')
            ) : (
              t('signup.signup')
            )}
          </Button>
          <Grid container justifyContent="center">
            <Grid item>
              <Link to={'/login'}>{t('signup.haveAnAccount')}</Link>
            </Grid>
          </Grid>
        </div>
      </Paper>
      <Box mt={5}>
        <Typography variant="body2" color="textSecondary" align="center">
          {t('signup.copyright') + ' © '}
          Cywareness {new Date().getFullYear()}
          {'.'}
        </Typography>
      </Box>
    </Container>
  )
}

const PromoBox: FC<{ configurations: AppliedPromoConfigurations }> = ({ configurations }) => {
  const { t } = useTranslation()
  const classes = useStyles({ isSuccess: true })
  return (
    <Paper className={classes.promoSuccessContainer}>
      <Box className={classes.giftBox}>
        <FontAwesomeIcon icon={faGift as IconProp} fontSize={24} />
      </Box>
      <Typography fontSize={16} marginTop={1}>
        {t('signup.congratulations')}
      </Typography>
      <br />
      <Typography fontSize={16}>
        {
          <Trans
            i18nKey={'signup.promoDescription'}
            values={{
              campaignsQuota: configurations?.campaigns_quota,
              usersQuota: configurations?.users_quota,
            }}
            components={{
              1: <strong />,
            }}
          />
        }
      </Typography>
    </Paper>
  )
}

type StyleProps = { isSuccess: boolean }

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: ({ isSuccess }) => (isSuccess ? theme.palette.success.main : theme.palette.blueGray[900]),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorMessage: {
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.error.main,
    marginBottom: theme.spacing(2),
  },
  errorIcon: {
    marginRight: 4,
  },
  spinnerContainer: {
    padding: '8px 11px',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(8),
  },
  promoSuccessContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: '1.5px solid rgba(0, 0, 0, 0.1)',
    height: 150,
    width: 520,
    marginTop: 50,
    marginBottom: -30,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  giftBox: {
    border: '1.5px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
    height: 50,
    marginTop: -30,
    backgroundColor: 'white',
  },
}))

export default Signup
