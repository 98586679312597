import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faComment, faEnvelope, faGlobe, faShapes, faGauge } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Tooltip, Typography } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Tags from '@/common/components/Tags/Tags'
import {
  PhishingSimulation,
  PhishingSimulationPackageExtended,
  PhishingSimulationVector,
} from '@/types/phishingSimulations'

type SimulationPageStatsProps = {
  simulation: PhishingSimulation | PhishingSimulationPackageExtended
}

const SimulationPageStats: FC<SimulationPageStatsProps> = ({ simulation }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const title = useMemo(() => {
    {
      return (
        (simulation?.vectors?.includes(PhishingSimulationVector.SMS)
          ? t('simulationLibrary.simulationPage.vectorSmsTooltip') + ' '
          : '') +
        (simulation?.vectors?.includes(PhishingSimulationVector.Email)
          ? t('simulationLibrary.simulationPage.vectorEmailTooltip') + ' '
          : '') +
        (simulation?.vectors?.includes(PhishingSimulationVector.WhatsApp)
          ? t('simulationLibrary.simulationPage.vectorWhatsappTooltip') + ' '
          : '')
      )
    }
  }, [simulation?.vectors])

  return (
    <div className={classes.root}>
      <div className={classes.item}>
        <FontAwesomeIcon icon={faGlobe as IconProp} fontSize={24} />
        <Tags
          justify="center"
          useChips={false}
          tags={(simulation?.languages?.message ?? []).map((language) => ({
            label: language.toUpperCase(),
          }))}
          limit={3}
        />
      </div>
      <div className={classes.item}>
        <FontAwesomeIcon icon={faGauge as IconProp} fontSize={24} />
        <Typography variant="body1">
          {simulation.difficulties.length > 1
            ? simulation.difficulties[0] + '-' + simulation.difficulties[simulation.difficulties.length - 1]
            : simulation.difficulties}{' '}
          {t('simulationLibrary.simulationPage.difficulty')}
        </Typography>
      </div>
      <Tooltip placement="top" title={title}>
        <div className={classes.item}>
          <Box style={{ display: 'flex', gap: '6px', justifyContent: 'center' }}>
            {simulation?.vectors?.includes(PhishingSimulationVector.SMS) && (
              <FontAwesomeIcon icon={faComment as IconProp} fontSize={20} />
            )}
            {simulation?.vectors?.includes(PhishingSimulationVector.Email) && (
              <FontAwesomeIcon icon={faEnvelope as IconProp} fontSize={20} />
            )}
            {simulation?.vectors?.includes(PhishingSimulationVector.WhatsApp) && (
              <FontAwesomeIcon icon={faWhatsapp as IconProp} fontSize={20} />
            )}
          </Box>
          <Typography variant="body1">
            {t('simulationLibrary.simulationPage.vector' + (simulation?.vectors.length > 1 ? 's' : ''))}
          </Typography>
        </div>
      </Tooltip>
      {simulation?.tags?.length ? (
        <Tooltip placement="top" title={simulation.tags.join(', ')}>
          <div className={classes.item} title={simulation.tags.join(', ')}>
            <FontAwesomeIcon icon={faShapes as IconProp} fontSize={24} />
            <Typography variant="body1">
              {simulation.tags.length}{' '}
              {t('simulationLibrary.simulationPage.topic', {
                count: simulation.tags.length,
              })}
            </Typography>
          </div>
        </Tooltip>
      ) : null}
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      margin: theme.spacing(2, 0),
    },
    item: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      marginRight: theme.spacing(4),
      color: '#294D55',
      fontWeight: 700,
    },
  })
)

export default SimulationPageStats
