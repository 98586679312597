import React, { FC } from "react";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import VideoPlayerV2 from "../../../../common/components/VideoPlayerV2/VideoPlayerV2";

const useStyles = makeStyles((theme) =>
  createStyles({
    video: {
      marginBottom: theme.spacing(0),
      minHeight: 300,
      padding: theme.spacing(0, 2),
    },
  })
);

interface VideoPageVideoPlayerProps {
  video: {
    subtitle_tracks: {
      [language: string]: string;
    };
    video_tracks: {
      [language: string]: string;
    };
  } | null;
}

const VideoPageVideoPlayer: FC<VideoPageVideoPlayerProps> = ({ video }) => {
  const classes = useStyles();

  return (
    <div className={classes.video}>
      {video && (
        <VideoPlayerV2
          subtitleTracks={video.subtitle_tracks}
          videoTracks={video.video_tracks}
        />
      )}
    </div>
  );
};

export default VideoPageVideoPlayer;
