import React, { FC, useCallback, useEffect, useState } from 'react'
import SimpleTable from '../Tables/SimpleTable'
import { Trans, useTranslation } from 'react-i18next'
import { ColumnInterface } from 'react-table'
import MoreMenu from '../MoreMenu/MoreMenu'
import { Office, OfficeWorkingHours } from '@/api/offices/offices'
import moment from 'moment'
import { theme } from '@/theme/theme'
import { filter, intersection } from 'lodash'
import { NavLink, useNavigate } from 'react-router-dom'
import { TABLE_SIZE_REFERENCE_KEYS, getDefaultTableSize, setDefaultTableSize } from '../../utils/table-size'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { languages } from '@/assets/locales/languages'
import { countriesArray } from '@/assets/countries'
import HumanizedScore from '../HumanizedScore/HumanizedScore'
import momentTz from 'moment-timezone'
import InTableMessageWithAnimation from '../Tables/InTableMessageWithAnimation'

type OfficesTableProps = {
  offices: any[]
  handleDeleteOffice: (_id: string) => void
  handleEditOffice: (office: Office) => void
  clickable?: boolean
  queryFilters: {
    search: string
    groups: string[]
    offices: string[]
    status: boolean[]
    limit: number
    skip: number
    sort_order: string
    sort_by: string
    scores: string[]
    languages: string[]
    timezones: string[]
  }
}

export default function OfficesTable({
  offices,
  handleDeleteOffice,
  handleEditOffice,
  clickable = false,
  queryFilters,
}: OfficesTableProps) {
  const { t } = useTranslation()
  const [data, setData] = useState<Office[]>(offices)
  const navigateTo = useNavigate()
  const defaultRowsPerPage = getDefaultTableSize(TABLE_SIZE_REFERENCE_KEYS.ALL_USERS_TABLE)

  function handleChangeRowsPerPage(pageSize: number) {
    setDefaultTableSize(TABLE_SIZE_REFERENCE_KEYS.ALL_USERS_TABLE, pageSize)
  }

  function getOfficeLanguage(languageCode: string) {
    try {
      const language = languages[languageCode]
      return language?.name
    } catch (e) {
      return ''
    }
  }

  function getOfficeCountry(countryCode: string) {
    let countryName = ''
    countriesArray.map((country) => {
      if (country.code === countryCode) {
        countryName = country?.name
      }
    })
    return countryName
  }

  function getOfficeTimeZone(timeZone: string) {
    try {
      const timeZoneName = momentTz.tz(timeZone).format('Z')
      return `GMT${timeZoneName.substring(0, 3)}`
    } catch (e) {
      return ''
    }
  }

  const formatWorkingHours = useCallback(
    ({ days, opening, closing }: OfficeWorkingHours): string => {
      let daysString = ''
      const daysArray = Object.keys(days)
      let i
      for (i = 0; i < daysArray.length; i++) {
        if (days[daysArray[i]]) {
          if (days[daysArray[i]] && !daysString) {
            daysString = t(`createOfficeModal.days.${daysArray[i]}`)
          } else {
            if (daysString && days[daysArray[i - 1]]) {
              if (days[daysArray[i + 1]]) {
              } else {
                daysString = daysString + ` - ${t(`createOfficeModal.days.${daysArray[i]}`)}`
              }
            } else {
              if (daysString && !days[daysArray[i - 1]]) {
                daysString = daysString + `, ${t(`createOfficeModal.days.${daysArray[i]}`)}`
              }
            }
          }
        }
      }

      return `${moment().hour(opening.h).minute(opening.m).format('HH:mm')}~${moment()
        .hour(closing.h)
        .minute(closing.m)
        .format('HH:mm')} | ${daysString}`
    },
    [t]
  )

  const columns: ColumnInterface[] = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'No. Of Members',
        accessor: 'user_count',
      },
      {
        Header: 'Language',
        accessor: 'language',
        align: 'center',
        Cell: (data) => (data.row.original?.language ? getOfficeLanguage(data.value) : ''),
      },
      {
        Header: 'Country',
        accessor: 'geo_location',
        align: 'center',
        Cell: (data) => (data.value ? getOfficeCountry(data.value) : ''),
      },
      {
        Header: t('officesTable.timezone'),
        accessor: 'time_zone',
        disableSortBy: true,
        align: 'center',
        Cell: (data) => (data.value ? getOfficeTimeZone(data.value) : ''),
      },
      {
        Header: t('officesTable.workingDays'),
        accessor: 'working_hours',
        disableSortBy: true,
        align: 'center',
        Cell: ({ value }) => (value.hasOwnProperty('days') ? formatWorkingHours(value) : value), //TODO remove this when working_hours strings are removed from the DB
      },
      {
        Header: t('users.groupsTable.score'),
        accessor: 'awareness_score',
        Cell: ({ value }) => {
          return <HumanizedScore score={value} />
        },
      },
      {
        id: 'delete',
        align: 'center',
        width: 80,
        Cell: (data) => {
          const office = data.row.original
          const officeId = office._id
          return (
            <MoreMenu
              items={[
                {
                  label: 'simulatorOnboarding.groups.groupsTable.edit',
                  onClick: () => handleEditOffice(office),
                },
                {
                  label: 'simulatorOnboarding.groups.groupsTable.remove',
                  textColor: theme.palette.error.main,
                  onClick: () => handleDeleteOffice(office),
                },
              ]}
              ariaLabel={'simulatorOnboarding.groups.groupsTable.moreActions'}
            />
          )
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  )

  function getOfficeTextualScore(score: number) {
    if (score < 0) {
      return '-'
    } else if (score >= 0 && score <= 25) {
      return 'bad'
    } else if (score > 25 && score <= 50) {
      return 'low'
    } else if (score > 50 && score <= 75) {
      return 'average'
    } else {
      return 'excellent'
    }
  }

  useEffect(() => {
    let filteredOffices = [...offices]
    if (queryFilters.search || queryFilters.groups || queryFilters.scores || queryFilters.languages) {
      filteredOffices = filter(offices, (office) => {
        const nameMatch = queryFilters.search
          ? office.name.toLowerCase().startsWith(queryFilters.search.toLowerCase())
          : true
        const groupMatch =
          queryFilters.groups.length > 0 ? intersection(office.groups, queryFilters.groups).length > 0 : true
        const scoreMatch =
          queryFilters.scores.length > 0
            ? queryFilters.scores.includes(getOfficeTextualScore(office.awareness_score))
            : true
        const languageMatch =
          queryFilters.languages.length > 0 ? queryFilters.languages.includes(office.language) : true
        const timezoneMatch =
          queryFilters.timezones.length > 0 ? queryFilters.timezones.includes(office.time_zone) : true
        return nameMatch && groupMatch && scoreMatch && languageMatch && timezoneMatch
      })
    }
    setData(filteredOffices)
  }, [offices, queryFilters])

  function handleNavigateToOfficeProfile({ original }) {
    navigateTo(`/recipients/offices/${original._id}`)
  }

  return (
    <SimpleTable
      stickyHeader
      enableCheckbox={false}
      columns={columns}
      data={data}
      setData={() => {}}
      noResultsContent={<NoResults />}
      onRowClick={handleNavigateToOfficeProfile}
      initialPageSize={defaultRowsPerPage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  )
}

const NoResults: FC = () => {
  const { t } = useTranslation()
  return (
    <InTableMessageWithAnimation>
      <p>{t('noResults.noOfficeAdded')}</p>
      <p>
        <Trans i18nKey={'noResults.addOffice'} components={{ 1: <NavLink to={'/recipients/offices'} /> }} />
      </p>
    </InTableMessageWithAnimation>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    table: {
      width: '98%',
      borderRadius: '10px',
      backgroundColor: '#FFF',
      boxShadow: '0px 6px 5px  0px rgba(0, 0, 0, 0.25)',
      marginBottom: '20px',
      margin: 'auto',
      overflow: 'hidden',
      '& .MuiTableCell-head': {
        textAlign: 'center',
        paddingLeft: 40,
        '&:first-child': {
          paddingLeft: 15,
          textAlign: 'left',
        },
      },
      '& .MuiTableCell-body': {
        textAlign: 'center',
        '&:first-child': {
          textAlign: 'left',
        },
      },
    },
  })
)
