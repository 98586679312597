import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faPenToSquare, faSitemap, faSlash, faTrash, faUpload } from '@fortawesome/pro-light-svg-icons'
import { faQuestion } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import dayjs from 'dayjs'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import '/node_modules/flag-icons/css/flag-icons.min.css'

import addIconHover from '@/assets/images/account-center-icons/add-hover.svg'
import addIcon from '@/assets/images/account-center-icons/add.svg'
import { OrganizationCardData } from '@/pages/AccountCenter'
import { theme } from '@/theme/theme'
import { OrganizationAccountType } from '@/types/organizations'

type TreeChartCardPros = {
  organization: OrganizationCardData
  depth: number
  opendChildren: any
  closedChildren: any
}

const cardBackgroundColors = [
  theme.palette.deepPurple[100],
  theme.palette.cyan[300],
  theme.palette.cyan[400],
  theme.palette.cyan[500],
  theme.palette.cyan[600],
  theme.palette.cyan[700],
  theme.palette.cyan[800],
  theme.palette.blueGray[200],
  theme.palette.indigo[200],
  theme.palette.indigo[100],
]

const TreeChartCard: FC<TreeChartCardPros> = ({ organization, depth, opendChildren, closedChildren }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  if (organization.id === 'fake_root') return <></>
  return (
    <Box>
      <Box
        id={organization.id + '_card'}
        className={classes.root + (organization._highlighted ? ' highlighted' : '')}
        style={{ background: cardBackgroundColors[depth % 10] }}>
        <Typography className={classes.organizationName}>{organization.name}</Typography>
        <Box className={classes.logoContainer}>
          {organization.logo ? (
            <img className={classes.companyLogo} src={organization.logo} alt={organization.name} />
          ) : (
            <Box id={organization.id + '_uploadImage'}>
              <FontAwesomeIcon icon={faUpload as IconProp} className={classes.uploadIcon} />
              <Typography className={classes.uploadText}>{t('accountCenter.card.uploadLogo')}</Typography>
            </Box>
          )}
        </Box>
        <Box className={classes.bottom}>
          <Box className={classes.flagContainer}>
            <Bulge />
            <Box className={classes.circleBox}>
              {organization.origin_country ? (
                <Box className={[classes.flag, `fi fi-${organization.origin_country?.toLowerCase()} fib`].join(' ')} />
              ) : (
                <FontAwesomeIcon icon={faQuestion as IconProp} style={{ width: '8px' }} />
              )}
            </Box>
          </Box>
          <Typography className={classes.detail}>
            {t('accountCenter.card.activeMembersTitle')}
            <br />
            <span>{organization.active_members}</span>
          </Typography>
          {(opendChildren || closedChildren) && (
            <Typography className={classes.detail}>
              {t('accountCenter.card.aggregatedActiveMembersTitle')}
              <br />
              <span>{organization.total_active_members}</span>
            </Typography>
          )}
        </Box>
      </Box>
      <Box className={classes.buttons}>
        <div
          id={organization.id + '_expandCollapse'}
          className={[classes.icon, classes.withTooltip, 'fa-layers'].join(' ')}>
          {opendChildren && <Typography>{t('accountCenter.card.collapse')}</Typography>}
          {closedChildren && <Typography>{t('accountCenter.card.expand')}</Typography>}
          {(closedChildren || opendChildren) && (
            <>
              <FontAwesomeIcon icon={faSitemap as IconProp} width={24} />
              {opendChildren && <FontAwesomeIcon icon={faSlash as IconProp} width={24} />}
            </>
          )}
        </div>
        <div
          id={organization.id + '_edit'}
          className={[
            classes.icon,
            classes.withTooltip,
            organization.is_admin_authorized ? '' : classes.disabledIcon,
          ].join(' ')}>
          {<Typography>{t('accountCenter.card.edit')}</Typography>}
          <FontAwesomeIcon icon={faPenToSquare as IconProp} width={24} />
        </div>

        <div
          id={organization.id + '_delete'}
          className={[classes.icon, classes.withTooltip, classes.disabledIcon].join(' ')}>
          {<Typography>{t('accountCenter.card.delete')}</Typography>}
          <FontAwesomeIcon icon={faTrash as IconProp} width={24} />
        </div>
      </Box>

      {organization.is_admin_authorized && organization.type !== OrganizationAccountType.client && (
        <div
          id={organization.id + '_add'}
          className={[classes.addIcon, organization._highlighted ? 'highlighted' : ''].join(' ')}
        />
      )}

      {dayjs(organization.created_at).isAfter(dayjs().subtract(30, 'days')) && (
        <Box className={[classes.newBadgeWrapper, classes.newTooltip].join(' ')}>
          <Typography>
            <b>
              {t('accountCenter.card.newTooltipTitle', { days: dayjs().diff(dayjs(organization.created_at), 'day') })}
            </b>
            <span>{t('accountCenter.card.newTooltipMessege')}</span>
          </Typography>
          <NewBadge />
        </Box>
      )}
    </Box>
  )
}

const Bulge = () => (
  <svg width="42" height="22" viewBox="0 0 42 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.7904 13.005C37.2989 13.1119 33.774 9.18773 31.2264 5.48704C28.9383 2.16342 25.2103 0 21 0C16.7897 0 13.0617 2.16342 10.7736 5.48704C8.22597 9.18773 4.70114 13.1119 0.209573 13.005L0 13V17.6V22H42V13L41.7904 13.005Z"
      fill="black"
    />
  </svg>
)

const NewBadge = () => (
  <svg width="63" height="26" viewBox="0 0 63 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M53 0V4L63 3.99996L53 0Z" fill={theme.palette.orange[800]} />
    <rect width="63" height="22" transform="translate(0 4)" fill={theme.palette.orange[400]} />
    <path
      d="M17.1494 9.8H19.7094V21H17.5814L11.9974 14.2V21H9.43738V9.8H11.5814L17.1494 16.6V9.8ZM24.9353 18.92H31.0312V21H22.3593V9.8H30.8233V11.88H24.9353V14.312H30.1353V16.328H24.9353V18.92ZM47.3854 9.8H49.8654L46.2014 21H43.4174L40.9534 13.416L38.4094 21H35.6414L31.9614 9.8H34.6494L37.1774 17.672L39.8174 9.8H42.2174L44.7774 17.736L47.3854 9.8ZM51.703 17.112L51.207 9.8H54.183L53.687 17.112H51.703ZM52.695 21.128C51.799 21.128 51.159 20.472 51.159 19.672C51.159 18.856 51.799 18.248 52.695 18.248C53.591 18.248 54.231 18.856 54.231 19.672C54.231 20.472 53.591 21.128 52.695 21.128Z"
      fill={theme.palette.blueDianne[900]}
    />
  </svg>
)
const useStyles = makeStyles(() =>
  createStyles({
    fakeRoot: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '420px',
      height: '140px',
      fontSize: '42px',
      borderRadius: '10px',
      border: '1px solid #B8B8B8',
      background: '#3AE2F9',
    },
    newBadgeWrapper: {
      position: 'absolute',
      top: '56px',
      right: '34px',
    },
    root: {
      margin: '12px 44px 0px 44px',
      borderRadius: '10px',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      border: '1px solid #B8B8B8',
      overflow: 'hidden',
      width: '192px',
      paddingTop: '10px',
      fontFamily: 'Montserrat',
      textAlign: 'center',
      '&.highlighted': {
        border: '4px solid #F8A33E',
      },
    },
    withTooltip: {
      '& p': {
        fontSize: '10px',
        fontWeight: theme.typography.fontWeightBold,
        position: 'absolute',
        transform: 'translate(26px,-9.5px)',
        padding: '6px 10px 6px',
        borderRadius: '20px',
        background: theme.palette.black + '90',
        color: 'white',
        opacity: 0,
      },
      '&:hover': {
        '& p': {
          opacity: 1,
        },
      },
    },
    newTooltip: {
      '& p': {
        width: '254px',
        fontSize: '14px',
        fontWeight: theme.typography.fontWeightRegular,
        position: 'absolute',
        transform: 'translate(-95px,20px)',
        padding: '6px 12px 8px',
        borderRadius: '10px',
        background: theme.palette.black + '90',
        color: 'white',
        pointerEvents: 'none',
        '& b': {
          fontWeight: theme.typography.fontWeightBold,
          lineHeight: 2,
        },
        opacity: 0,
      },
      '&:hover': {
        '& p': {
          opacity: 1,
        },
      },
    },
    buttons: {
      position: 'absolute',
      right: 0,
      top: '25%',
      display: 'flex',
      flexDirection: 'column',
    },
    icon: {
      width: '24px',
      height: '24px',
      margin: '10px 0px',
      cursor: 'pointer',
    },
    disabledIcon: {
      opacity: 0.2,
      pointerEvents: 'none',
      cursor: 'not-allowed',
    },
    addIcon: {
      backgroundImage: `url(${addIcon})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      width: '280px',
      height: '54px',
      '&.highlighted': {
        height: '55px',
      },
      '&:hover': {
        backgroundImage: `url(${addIconHover})`,
      },
    },
    organizationName: {
      fontWeight: '600',
      fontSize: 'min(16px,2vw)',
      width: '175px',
      height: '20px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      margin: '10px',
    },
    logoContainer: {
      background: 'white',
      width: '145px',
      height: '86px',
      display: 'flex',
      border: '1px solid #B8B8B8',
      borderRadius: '16px',
    },
    companyLogo: {
      width: '145px',
      padding: '10px',
      objectFit: 'contain',
    },
    uploadIcon: {
      width: '20px',
      height: '20px',
      margin: '-10px 0px 0px',
    },
    uploadText: {
      fontSize: '10px',
      margin: '-30px 2px',
    },
    bottom: {
      marginTop: '22px',
      padding: '7px 0px',
      height: '95px',
      width: '100%',
      background: 'black',
    },
    flagContainer: {
      marginTop: '-20px',
      position: 'absolute',
    },
    flag: {
      height: '22px',
      width: '30px',
      backgroundSize: 'cover',
      position: 'relative',
      marginLeft: '0.5px',
      marginBottom: '0.1px',
    },
    circleBox: {
      background: 'white',
      width: '20px',
      height: '20px',
      borderRadius: '50%',
      position: 'relative',
      margin: '-22px 10px 0px 11px',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'black',
    },
    detail: {
      color: 'white',
      fontWeight: '500',
      fontSize: '10px',
      '& span': {
        fontWeight: '800',
        fontSize: '12px',
      },
    },
  })
)

export default TreeChartCard
