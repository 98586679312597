import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ColumnInterface } from "react-table";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import PaginatingTable from "../../../common/components/Tables/PaginatingTable";
import { useQueryCache } from "react-query";
import Filter, { FilterObject } from "../../../common/components/Tables/Filter";
import { Group } from "../../../api/groups/groups";
import { Office } from "../../../api/offices/offices";
import { MultiselectBarProps } from "../../../common/components/Tables/MultiselectBar";
import { Serialized } from "../../../api/utils/serialize-array";
import { Paper, Button } from "@mui/material";
import { theme } from "../../../theme/theme";
import Tags from "../../../common/components/Tags/Tags";
import {
  ActiveDirectoryUser,
  ActiveDirectoryUsersQueryVariables,
  useActiveDirectoryUsers,
  ActiveDirectoryBoardRequest,
} from "../../../api/active-directory/users";
import AdStats from "./AdStats";

type UserTableProps = {
  handleOnboardUser: (users: ActiveDirectoryBoardRequest) => void;
  handleOffboardUser: (users: ActiveDirectoryBoardRequest) => void;
  handleOpenOnboardingModal: (users: ActiveDirectoryUser[]) => void;
  groups?: Group[];
  offices?: Serialized<Office>;
  loading: boolean;
};

export type GroupFilters = ActiveDirectoryUsersQueryVariables["status"];

const INITIAL_QUERY_FILTERS = {
  fullname: "",
  email: "",
  group_name: "",
  offset: 5,
  startIndex: 0,
};

export default function ActiveDirectoryUsersTable({
  handleOffboardUser,
  handleOnboardUser,
  handleOpenOnboardingModal,
  groups,
  offices,
  loading,
}: UserTableProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const queryCache = useQueryCache();
  const [indexedFetchedUsers, setIndexedFetchedUsers] = useState<{
    [external_id: string]: ActiveDirectoryUser;
  }>({});
  const [queryFilters, setQueryFilters] =
    useState<ActiveDirectoryUsersQueryVariables>(INITIAL_QUERY_FILTERS);
  const [selectedGroupFilter, setSelectedGroupFilter] =
    useState<GroupFilters>();
  const { resolvedData: queryData, isLoading } = useActiveDirectoryUsers({
    ...queryFilters,
    status: selectedGroupFilter,
  });
  const {
    items: users,
    total_users,
    total_onboarded_users,
    total_offboarded_users,
  } = queryData ?? {
    total_users: 0,
    total_onboarded_users: 0,
    total_offboarded_users: 0,
    items: [],
  };
  const [pageCount, setPageCount] = useState(0);

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      setQueryFilters((prevState) => ({
        ...prevState,
        startIndex: prevState.offset * pageIndex,
        offset: pageSize,
      }));
      // Only update the data if this is the latest fetch
      setPageCount(Math.ceil(total_users / pageSize));
      queryCache.prefetchQuery([
        "ad-users",
        {
          ...queryFilters,
          startIndex: pageSize * (pageIndex + 1),
          offset: pageSize,
        },
      ]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [total_users]
  );

  useEffect(() => {
    if (queryData?.items) {
      const usersMap = {};
      queryData.items.forEach((user) => {
        usersMap[user.external_id] = user;
      });
      setIndexedFetchedUsers((prevState) => ({ ...prevState, ...usersMap }));
    }
  }, [queryData]);

  function selectGroupFilter(filter: GroupFilters) {
    setSelectedGroupFilter(filter);
  }

  const columns: ColumnInterface[] = useMemo(
    () => [
      {
        Header: t("users.activeDirectory.usersTable.name"),
        accessor: "first_name",
        Cell: (data) => {
          const { first_name, last_name } = data.row.original;
          return `${first_name} ${last_name}`;
        },
      },
      {
        Header: t("users.activeDirectory.usersTable.email"),
        accessor: "username",
      },
      // no createAt field on users from AD
      // {
      //   Header: t('users.activeDirectory.usersTable.created'),
      //   accessor: 'created',
      //   Cell: ({ value }) => (value ? moment.unix(value).format('DD/MM/YY') : ''),
      // },
      {
        Header: t("users.activeDirectory.usersTable.groups"),
        accessor: "member_of",
        disableSortBy: true,
        Cell: ({ value }) => (
          <div className={classes.tags}>
            <Tags
              useChips={false}
              tags={
                value &&
                value.map((group) => ({
                  label: group.displayName,
                  color: theme.palette.success.light,
                }))
              }
              limit={2}
            />
          </div>
        ),
      },
      {
        Header: t("users.activeDirectory.usersTable.status"),
        accessor: "onboarded",
        Cell: ({ value }) =>
          t(
            value
              ? "users.activeDirectory.usersTable.onboarded"
              : "users.activeDirectory.usersTable.offboarded"
          ),
      },
      {
        id: "button",
        Cell: (data) => {
          const user = data.row.original;
          const userRequestObject: ActiveDirectoryBoardRequest = {
            users: [user],
          };
          const isOnboarded = user.onboarded;
          return (
            <Button
              onClick={() =>
                isOnboarded
                  ? handleOffboardUser(userRequestObject)
                  : handleOpenOnboardingModal([user])
              }
              variant={isOnboarded ? "outlined" : "contained"}
              color={isOnboarded ? "inherit" : "primary"}
              size={"small"}
            >
              {t(
                isOnboarded
                  ? "users.activeDirectory.usersTable.offboard"
                  : "users.activeDirectory.usersTable.onboard"
              )}
            </Button>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, offices]
  );

  const filters = [
    { key: "fullname", label: "Name", value: "" },
    { key: "email", label: "Email", value: "" },
    { key: "group_name", label: "Group", value: "" },
  ];

  const handleFilterChange = useCallback((values: FilterObject[]) => {
    const newFilters = {};
    values.forEach((appliedFilter) => {
      newFilters[appliedFilter.key] = appliedFilter.value;
    });
    setQueryFilters((prevState) => ({
      ...INITIAL_QUERY_FILTERS,
      ...newFilters,
    }));
  }, []);

  const multiSelectActions: MultiselectBarProps["actions"] = [
    {
      label: "users.activeDirectory.usersTable.enableUsers",
      onClick: (userIds) =>
        handleOpenOnboardingModal(userIds.map((id) => indexedFetchedUsers[id])),
      variant: "button",
    },
    {
      label: "users.activeDirectory.usersTable.disableUsers",
      onClick: (userIds) =>
        handleOffboardUser({
          users: userIds.map((id) => indexedFetchedUsers[id]),
        }),
      variant: "button",
    },
  ];

  async function onboardAllAdUsers() {
    await handleOnboardUser({ users: [], apply_all: true });
  }

  return (
    <>
      <AdStats
        onFilterSelect={selectGroupFilter}
        selectedFilter={selectedGroupFilter}
        data={{
          total_users,
          onboarded: total_onboarded_users,
          offboarded: total_offboarded_users,
        }}
        onboardAllUsers={onboardAllAdUsers}
      />
      <Filter filters={filters} onChange={handleFilterChange} />
      <Paper className={classes.table}>
        <PaginatingTable
          rowKey={"external_id"}
          multiSelectBarProps={{
            label: "users.activeDirectory.usersTable.user_count",
            actions: multiSelectActions,
          }}
          noResultsTextId={"users.activeDirectory.usersTable.noResults"}
          columns={columns}
          data={users ?? []}
          fetchData={fetchData}
          loading={isLoading || loading}
          pageCount={pageCount}
          count={total_users}
        />
      </Paper>
    </>
  );
}
const useStyles = makeStyles(() =>
  createStyles({
    table: {
      "& .MuiTableCell-head": {
        textAlign: "center",
        paddingLeft: 40,
        "&:first-child": {
          paddingLeft: 15,
          textAlign: "left",
        },
      },
      "& .MuiTableCell-body": {
        textAlign: "center",
        "&:first-child": {
          textAlign: "left",
        },
      },
    },
    tags: {
      display: "flex",
      justifyContent: "center",
    },
  })
);
