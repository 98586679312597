import feedbackImage from '@/assets/images/Micro-Training/micro.png'
import { CoverImages, PhishingSimulationVector } from '@/types/phishingSimulations'
import html2canvas from 'html2canvas'

async function captureScreenshot(
  htmlContent: string,
  widthPx: number = 800,
  heightPx: number | null = null
): Promise<string> {
  return new Promise((resolve, reject) => {
    const iframe = document.createElement('iframe')
    iframe.width = `${widthPx}px`
    iframe.height = heightPx ? `${heightPx}px` : '100%'
    iframe.style.opacity = '0'

    iframe.onload = async () => {
      const iframeDocument = iframe.contentDocument || iframe.contentWindow.document

      iframeDocument.open()
      iframeDocument.write(htmlContent)
      iframeDocument.close()

      try {
        const canvas = await html2canvas(iframeDocument.body, {
          onclone: () => {
            iframe.style.opacity = '1'
          },
        })
        document.body.removeChild(iframe)
        resolve(canvas.toDataURL('image/png'))
      } catch (err) {
        console.error('Error converting HTML to Canvas:', err)
        document.body.removeChild(iframe)
        reject(err)
      }
    }

    document.body.appendChild(iframe)
  })
}

export const generateScreenshots = async (
  vectorType: PhishingSimulationVector,
  emailTemplate: string,
  landingPage: string
): Promise<CoverImages> => {
  const feedbackPageHtml = Object.assign(new Image(), {
    src: feedbackImage,
  }).outerHTML
  try {
    const emailBase64Png = await captureScreenshot(
      emailTemplate,
      vectorType === 'sms' ? 400 : 800,
      vectorType === 'sms' ? 800 : null
    )
    const landingBase64Png = await captureScreenshot(landingPage, 800, vectorType === 'sms' ? 1600 : 600)
    const feedbackBase64Png = await captureScreenshot(feedbackPageHtml, 800)

    return Promise.resolve({
      message: emailBase64Png,
      landing_page: landingBase64Png,
      microtraining: feedbackBase64Png,
    })
  } catch (err) {
    console.error('Error uploading template cover images:', err)
    return Promise.reject(err)
  }
}
