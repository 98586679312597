import React, { useState } from 'react'
import { Box } from '@mui/material'
import { LanguageSelect } from '../components/LanguageSelect'
import { ToneOfVoiceSelect } from '../components/ToneOfVoiceSelect'
import { EmailBody } from '../components/EmailBody'
import { Instructions } from '../components/Instructions'
import AppDesignSelection from '../components/AppDesignSelection'
import AppNameSelection from '../components/AppNameSelection'
import AdvancedTemplateSettings from '../components/AdvancedTemplateSettings'
import AILinkToLogo from '../components/AILinkToLogo'
import { VectorType } from '../state'

export type AppEmailFormProps = {
  simulationVectorType: string
}
// TODO: consider change name of this component which used for sms and whatsapp simulations.

export const AppEmailForm = ({ simulationVectorType }: AppEmailFormProps) => {
  //need to lift the state from AppNameSelection so i can
  //conditionally render the AILinkToLogo component
  const [appName, setAppNameLiftingState] = useState('')

  return (
    <Box display="flex" flexDirection="column" gap={1.5}>
      <Box display="flex" justifyContent="space-between" gap={1.5}>
        {(simulationVectorType === VectorType.SMS || simulationVectorType === VectorType.WHATSAPP) && (
          <Box width={'48.4%'}>
            <LanguageSelect />
          </Box>
        )}
        {simulationVectorType === VectorType.EMAIL && (
          <>
            <LanguageSelect />
            <AppDesignSelection />
          </>
        )}
      </Box>
      <Box display="flex" justifyContent="space-between" gap={1.5}>
        <AppNameSelection setAppNameLiftingState={setAppNameLiftingState} />
        <ToneOfVoiceSelect />
      </Box>
      {appName.trim() !== '' && (
        <Box>
          <AILinkToLogo />
        </Box>
      )}
      <Box>
        <EmailBody simulationVectorType={simulationVectorType} />
      </Box>
      <Box>
        <Instructions simulationVectorType={simulationVectorType} />
      </Box>
      <Box>
        <AdvancedTemplateSettings />
      </Box>
    </Box>
  )
}

export default AppEmailForm
