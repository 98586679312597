import { useMemo } from "react";
import { useEducationModules } from "@/api/education-modules/modules";
import TagsFilter from "../../../common/components/TagsFilter/TagsFilter";
import { union } from "lodash";

type TagsFilterProps = {
  selectedTags: string[];
  onTagClick: (tag: string) => void;
};

export default function VideoTagsFilter({
  selectedTags,
  onTagClick,
}: TagsFilterProps) {
  const { data: education_modules } = useEducationModules();

  const tags = useMemo(() => {
    if (!education_modules) return [];
    return union(
      education_modules.flatMap((education_module) => education_module.tags)
    );
  }, [education_modules]);

  return (
    <TagsFilter
      tags={tags}
      selectedTags={selectedTags}
      onTagClick={onTagClick}
    />
  );
}
