import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Grid,
  Typography,
  InputLabel,
  FormHelperText,
  FormControlLabel,
  MenuItem,
  Checkbox,
  TextField,
  Link,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { faPlus, faMinus } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { useTranslation, Trans } from 'react-i18next'
import { string } from 'yup'

import useForm, { Field } from '@/common/components/Form/useForm'
import Form from '@/common/components/Form/Form'
import { EducationModule } from '@/api/education-modules/modules'
import { NewEducationModuleParams, useNewEducationModule } from '@/api/education-modules/create-module'
import { useEditEducationModule } from '@/api/education-modules/edit-module'
import { languages } from '@/assets/locales/languages'
import useToast from '@/common/hooks/useToast'
import ThumbnailCapture from './CreateVideoModal/ThumbnailCapture'
import FileUpload from '@/common/components/FileUpload/FileUpload'
import {
  generateAutoSubtitleTracksObject,
  generateUploadedSubtitleTracksObject,
} from './CreateVideoModal/utils/generateSubtitleTracksObject'
import VideoPageTopBar from './VideoPage/VideoPageTopBar'
import VideoUploadComponent from './CreateVideoPage/VideoUploadComponent'
import { Category, useCategories } from '@/api/categories/categories'
import VideoTagsFilter from './VideoTagsFilter'
import VideoPlayerV2 from '@/common/components/VideoPlayerV2/VideoPlayerV2'

type TagProps = {
  [key: string]: Category[]
}

type VideoEditableContentProps = {
  buttonClass?: string
  video?: EducationModule
  isModalOpen?: boolean
  setIsModalOpen?: (isOpen: boolean) => void
  hideButton?: boolean
  onLaunch?: () => void
}

function CategoryList({ categories }) {
  const { t } = useTranslation()
  const theme = useTheme()

  if (!categories) return <></>

  return (
    <div>
      <Typography>{t('createVideoModal.categories')}</Typography>
      <div
        style={{
          width: '100%',
          padding: theme.spacing(2),
          border: '1px solid #CBCBCB',
          borderRadius: 15,
          marginTop: theme.spacing(2),
        }}
      >
        {categories || ''}
      </div>
    </div>
  )
}

function createTagIndex(obj: TagProps) {
  return Object.values(obj).reduce((acc, items) => {
    items.forEach((item) => {
      item.tags.forEach((tag) => {
        if (!acc[tag]) {
          acc[tag] = []
        }
        if (!acc[tag].includes(item.name)) {
          acc[tag].push(item.name)
        }
      })
    })
    return acc
  }, {})
}

export default function VideoEditableContent({ video }: VideoEditableContentProps) {
  const navigateTo = useNavigate()
  const theme = useTheme()
  const { t } = useTranslation()
  const { errorToast, successToast } = useToast()
  const classes = useStyles()
  const [videoFile, setVideoFile] = useState<{
    name: string
    url: string
    duration?: number
  }>()
  const [thumbnail, setThumbnail] = useState<string>()
  const [isCopyrighted, setIsCopyrighted] = useState(false)
  const [useAutomaticSubtitles, setUseAutomaticSubtitles] = useState(true)
  const [selectedTopics, setSelectedTopics] = useState<string[]>([])
  const [numberOfSubtitleFiles, setNumberOfSubtitleFiles] = useState(1)
  const [subtitleValues, setSubtitleValues] = useState<{
    [key: string]: any
  }>({})
  const { data: categoriesData } = useCategories()
  const [categories, setCategories] = useState<string[]>([])

  const categoriesByTags = useMemo(() => {
    if (categoriesData?.categories_by_tag) {
      const tagIndex = createTagIndex(categoriesData.categories_by_tag)
      return tagIndex
    }
  }, [categoriesData])

  // on topic selection, set corresponding categories
  useEffect(() => {
    if (categoriesByTags && selectedTopics.length) {
      // make sure categories are unique
      const filteredCategories = new Set<string>()

      selectedTopics.forEach((topic) => {
        if (categoriesByTags[topic] && categoriesByTags[topic].length) {
          categoriesByTags[topic].forEach((v) => filteredCategories.add(v))
        }
      })
      setCategories([...Array.from(filteredCategories)])
    } else {
      setCategories([])
    }
  }, [selectedTopics, categoriesByTags])

  useEffect(() => {
    if (video) {
      setIsCopyrighted(!!video.copyrights)
      setSelectedTopics(video.tags)
      if (video.thumbnail) {
        setThumbnail(video.thumbnail)
      }
      setNumberOfSubtitleFiles(Object.keys(video?.subtitle_tracks)?.length || 1)
      const subValues = {}
      Object.entries(video?.subtitle_tracks).forEach(([key, value], index) => {
        subValues[`subtitleLang${index}`] = key
        subValues[`subtitleUrl${index}`] = value
      })
      setSubtitleValues(subValues)
    }
  }, [video])

  const [createNewVideo, { isLoading: isCreatingVideo }] = useNewEducationModule()

  const [updateVideo, { isLoading: isUpdatingVideo }] = useEditEducationModule()

  const detailsFields: Field[] = useMemo(
    () => [
      {
        name: 'title',
        initialValue: video?.title ?? '',
        label: 'createVideoModal.title',
        validationRule: string()
          .required('createVideoModal.errors.missingTitle')
          .max(30, 'createVideoModal.errors.titleTooLong'),
        type: 'text',
        required: true,
        autoFocus: false,
        gridItemProps: {
          xs: 12,
        },
        textFieldProps: {
          variant: 'outlined',
          inputProps: {
            maxLength: 30,
            showCharacterCounter: true,
          },
        },
      },
      {
        name: 'description',
        initialValue: video?.description ?? '',
        label: 'createVideoModal.description',
        validationRule: string()
          .required('createVideoModal.errors.missingDescription')
          .max(600, 'createVideoModal.errors.descriptionTooLong'),
        type: 'text',
        required: true,
        autoFocus: false,
        textFieldProps: {
          variant: 'outlined',
          multiline: true,
          rows: 5,
          inputProps: {
            maxLength: 600,
            showCharacterCounter: true,
          },
        },
        gridItemProps: {
          xs: 12,
        },
      },
    ],
    [video]
  )

  const elementsFields: Field[] = useMemo(() => {
    return [
      {
        name: 'videoLanguage',
        initialValue: video?.video_tracks ? Object.keys(video.video_tracks)[0] : '',
        label: 'createVideoModal.videoLanguage',
        validationRule: string().required('createVideoModal.errors.missingLanguage'),
        type: 'text',
        required: true,
        autoFocus: false,
        isAutoComplete: true,
        gridItemProps: {
          xs: 12,
        },
        textFieldProps: {
          variant: 'outlined',
          select: true,
        },
        options: Object.entries(languages).map(([key, value]) => ({
          label: value.name,
          value: key,
        })),
      },
    ]
  }, [video])

  const { formProps: detailsFormProps, handleSubmit: handleSubmitDetails } = useForm({
    fields: detailsFields,
    onSubmit: (values) => values,
    clearOnSubmit: false,
  })

  const { formProps: elementsFormProps, handleSubmit: handleSubmitElements } = useForm({
    fields: elementsFields,
    onSubmit: (values) => values,
    clearOnSubmit: false,
  })

  function handleVideoUpload(video: { name: string; url: string; duration?: number }) {
    setVideoFile(video)
  }

  const createSubtitleTracksObject = useCallback(() => {
    if (!video && videoFile && useAutomaticSubtitles) {
      const url = videoFile.url
      return generateAutoSubtitleTracksObject(url)
    } else {
      return generateUploadedSubtitleTracksObject(subtitleValues, numberOfSubtitleFiles)
    }
  }, [video, videoFile, useAutomaticSubtitles, numberOfSubtitleFiles, subtitleValues])

  function createVideoParams(values) {
    const subtitle_tracks = createSubtitleTracksObject()
    const params: NewEducationModuleParams = {
      title: values.title,
      description: values.description,
      video_tracks: {
        [values.videoLanguage]: video ? Object.values(video?.video_tracks)[0] : videoFile?.url || '',
      },
      subtitle_tracks,
      tags: selectedTopics,
      languages: [values.videoLanguage, ...Object.keys(subtitle_tracks)],
      duration: videoFile?.duration ? videoFile.duration : document.getElementsByTagName('video')?.[0]?.duration,
      copyrights: isCopyrighted,
      thumbnail,
    }
    return params
  }

  async function handleUpdateVideo(values) {
    if (video) {
      const params = createVideoParams(values)
      try {
        const data = await updateVideo({ _id: video._id, params })
        successToast(t('createVideoModal.videoUpdated'))
        return data
      } catch (e) {
        console.log(e)
        t('createVideoModal.errors.failedToSaveModule')
      }
    }
  }

  async function handleCreateVideo(values) {
    if (selectedTopics.length === 0) {
      return errorToast(t('createVideoModal.errors.missingTopic'))
    }
    if (!video && !videoFile) {
      return errorToast(t('createVideoModal.errors.failedToSaveModule'))
    }

    try {
      const params = createVideoParams(values)

      const data: EducationModule | undefined = await createNewVideo(params)

      successToast(t('createVideoModal.videoCreated'))

      return data
    } catch (e) {
      console.log(e)
      t('createVideoModal.errors.failedToCreate')
    }
  }

  async function handleSubmit() {
    if (!isCopyrighted) {
      return errorToast(t('createVideoModal.errors.missingCopyright'))
    }
    const detailsFormValues = await handleSubmitDetails()
    const elementsFormValues = await handleSubmitElements()

    if (!detailsFormValues || !elementsFormValues) {
      return
    }

    const values = { ...detailsFormValues, ...elementsFormValues }

    let submitted

    if (video) {
      submitted = await handleUpdateVideo(values)
    } else {
      submitted = await handleCreateVideo(values)
    }

    if (submitted?._id) {
      navigateTo(`/content-library/videos/${submitted._id}`)
    }
  }

  return (
    <>
      <VideoPageTopBar onSave={handleSubmit} loading={isUpdatingVideo || isCreatingVideo} />
      <div style={{ padding: theme.spacing(0, 3), marginTop: theme.spacing(0) }}>
        <Grid container spacing={8} className={classes.elementsContainer}>
          <Grid item xs={7} style={{ paddingTop: theme.spacing(0) }}>
            <Typography style={{ fontWeight: 'normal', marginBottom: theme.spacing(3) }} variant="h6">
              {t('createVideoModal.details')}
            </Typography>
            <Form {...detailsFormProps} />

            <Typography variant="h6" style={{ fontWeight: 'normal', margin: theme.spacing(3, 0) }}>
              {t('createVideoModal.elements')}
            </Typography>
            <Form {...elementsFormProps} />
            <div className={classes.autoSubtitleContainer}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={useAutomaticSubtitles}
                    onChange={() => setUseAutomaticSubtitles(!useAutomaticSubtitles)}
                    name="auto-subtitles"
                  />
                }
                label={t('createVideoModal.autoSubtitles')}
              />
            </div>
            {useAutomaticSubtitles
              ? null
              : [...Array(numberOfSubtitleFiles)].map((_, i) => {
                  return (
                    <Grid container spacing={3} className={classes.subtitleGrid}>
                      <Grid item xs={6}>
                        <TextField
                          label={t('createVideoModal.subtitleLanguage')}
                          variant={'outlined'}
                          margin={'none'}
                          fullWidth
                          select
                          onChange={(e) =>
                            setSubtitleValues((prevState) => ({
                              ...prevState,
                              [`subtitleLang${i}`]: e.target.value,
                            }))
                          }
                          value={subtitleValues[`subtitleLang${i}`] || ''}
                        >
                          {Object.entries(languages)
                            .map(([key, value]) => ({
                              label: value.name,
                              value: key,
                            }))
                            .map((option) => (
                              <MenuItem key={option.value} value={option.value} className={classes.multiSelectOption}>
                                {option.label}
                              </MenuItem>
                            ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={6}>
                        <FileUpload
                          label={t('createVideoModal.subtitleFile')}
                          onUpload={(url) =>
                            setSubtitleValues((prevState) => ({
                              ...prevState,
                              [`subtitleUrl${i}`]: url,
                            }))
                          }
                          textFieldProps={{
                            variant: 'outlined',
                            fullWidth: true,
                          }}
                          initialValue={subtitleValues[`subtitleUrl${i}`] || ''}
                        />
                      </Grid>
                    </Grid>
                  )
                })}
            {useAutomaticSubtitles ? null : (
              <div className={classes.flex}>
                <Button
                  onClick={() => setNumberOfSubtitleFiles(numberOfSubtitleFiles + 1)}
                  variant={'text'}
                  startIcon={<FontAwesomeIcon icon={faPlus as IconProp} />}
                >
                  {t('createVideoModal.addSubtitle')}
                </Button>
                {numberOfSubtitleFiles > 1 ? (
                  <Button
                    onClick={() => setNumberOfSubtitleFiles(numberOfSubtitleFiles - 1)}
                    variant={'text'}
                    startIcon={<FontAwesomeIcon icon={faMinus as IconProp} />}
                  >
                    {t('createVideoModal.deleteSubtitle')}
                  </Button>
                ) : null}
              </div>
            )}

            <div className={classes.tagsFilter}>
              <InputLabel
                required
                shrink
                style={{
                  fontSize: 20,
                  color: theme.palette.blueGray[900],
                }}
              >
                {t('createVideoModal.topics')}
              </InputLabel>
              <VideoTagsFilter
                selectedTags={selectedTopics}
                onTagClick={(tag) =>
                  selectedTopics.includes(tag)
                    ? setSelectedTopics(selectedTopics.filter((t) => t !== tag))
                    : setSelectedTopics([...selectedTopics, tag])
                }
              />
              {selectedTopics?.length ? null : (
                <FormHelperText error>{t('createVideoModal.errors.missingTopic')}</FormHelperText>
              )}
            </div>

            <CategoryList categories={categories.join(', ')} />
            <FormControlLabel
              style={{ marginTop: theme.spacing(3) }}
              labelPlacement="end"
              control={
                <Checkbox checked={isCopyrighted} onChange={() => setIsCopyrighted(!isCopyrighted)} name="copyrights" />
              }
              label={
                <Trans i18nKey={'createVideoModal.copyrightsReserved'}>
                  text
                  <Link
                    style={{
                      display: 'inline',
                      cursor: 'pointer',
                      color: theme.palette.blue[900],
                      textTransform: 'lowercase',
                      textDecoration: 'underline',
                    }}
                    rel="noopener"
                    href={'https://cywareness.io/terms-of-service/'}
                    target="_blank"
                  >
                    text
                  </Link>
                  text
                  <Link
                    style={{
                      display: 'inline',
                      cursor: 'pointer',
                      color: theme.palette.blue[900],
                      textTransform: 'lowercase',
                      textDecoration: 'underline',
                    }}
                    rel="noopener"
                    href={'https://cywareness.io/privacy-policy/'}
                    target="_blank"
                    className={classes.link}
                  >
                    text
                  </Link>
                </Trans>
              }
            />
          </Grid>
          <Grid item xs={5} style={{ paddingTop: theme.spacing(7) }}>
            {videoFile || video ? (
              <div>
                <VideoPlayerV2
                  id="upload-preview"
                  videoTracks={video?.video_tracks || { en: videoFile?.url || '' }}
                  subtitleTracks={video?.subtitle_tracks || {}}
                />
                <Typography variant="body2" color="textSecondary">
                  {t('createVideoModal.fileName')}: {videoFile?.name}
                </Typography>
              </div>
            ) : (
              <VideoUploadComponent onUpload={handleVideoUpload} />
            )}
            <div className={classes.thumbnailContainer} style={{ opacity: videoFile || video ? 1 : 0.3 }}>
              <ThumbnailCapture
                onCapture={setThumbnail}
                height={300}
                videoElementId={'upload-preview'}
                initialThumbnail={video?.thumbnail}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    dialog: {
      '& .MuiDialog-paper': {
        border: 'none',
      },
    },
    header: {
      backgroundColor: theme.palette.blueGray[900],
      color: theme.palette.cyan[500],
      padding: theme.spacing(1, 3),
    },
    dialogContent: {
      overflowX: 'hidden',
    },
    flexContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
    },
    launchButton: {
      minWidth: 100,
    },
    closeButton: {
      marginLeft: -theme.spacing(2),
      color: theme.palette.cyan[500],
    },
    elementsContainer: {
      marginTop: theme.spacing(0),
    },
    tagsFilter: {
      margin: theme.spacing(2, 0),
    },
    thumbnailContainer: {
      paddingTop: theme.spacing(2),
    },
    autoSubtitleContainer: {
      margin: theme.spacing(1, 0, 2),
    },
    multiSelectOption: {
      '&hover': {
        backgroundColor: 'rgba(0,0,0,0.1)',
      },
    },
    link: {
      color: theme.palette.cyan[500],
      cursor: 'pointer',
    },
    subtitleGrid: {
      marginBottom: theme.spacing(2),
    },
  })
)
