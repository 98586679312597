import React from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { useAiSimulationContext } from "../state";
import { languages } from "../../../../assets/locales/languages";

export const LanguageSelect = () => {
  const { language, setLanguage } = useAiSimulationContext();

  return (
    <FormControl
      fullWidth
      size="small"
      variant="outlined"
      sx={{ "& fieldset": { borderRadius: "10px" } }}
    >
      <InputLabel id="language-label" htmlFor="style-select">
        Language
      </InputLabel>
      <Select
        label="Language"
        labelId="language-label"
        value={language}
        onChange={(e) => setLanguage(e.target.value)}
      >
        {Object.entries(languages).map(([key, value]) => (
          <MenuItem value={value.name}>{value.name}</MenuItem>
        ))}
        {/* TODO: Load languages from server side or predefined list */}
      </Select>
    </FormControl>
  );
};
