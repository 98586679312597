import axios from 'axios'
import { API_URL } from '../constants'
import { useQuery } from '@tanstack/react-query'

type MemberRequest = {
  id: string
  queryParams: MemberParams
}

export type MemberParams = {
  skip: number
  limit: number
  sort_by: string
  sort_order: string
  start_date: string
  end_date: string
}

export type Campaign = {
  _id: string
  name: string
  score: number
  start_date: string
  status: string
  asset_type: string
  has_quiz: boolean
}

const getMemberCampaigns = async ({
  id,
  queryParams: { skip = 0, limit = 10, sort_by = 'start_date', sort_order = 'desc', start_date = '', end_date = '' },
}: MemberRequest) => {
  const url = `${API_URL}/members/campaigns/${id}/`
  const { data } = await axios.get(url, {
    withCredentials: true,
    params: {
      skip,
      limit,
      sort_by,
      sort_order,
      start_date,
      end_date,
    },
  })
  return data
}

export const useMemberCampaigns = ({ id, queryParams }: MemberRequest) => {
  return useQuery({
    queryKey: ['members', 'campaigns', id, { ...queryParams }],
    queryFn: () => {
      return getMemberCampaigns({ id, queryParams })
    },
    placeholderData: {
      total: 0,
      results: [],
    },
  })
}
