import { PhishingSimulationPackageUpdateRequest } from '@/types/phishingSimulations'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

import { API_URL } from '@/api/constants'

const updatePhishingSimulationPackage = async ({ id, params }: PhishingSimulationPackageUpdateRequest) => {
  const { data } = await axios.put(`${API_URL}/phishing-simulations/packages/${id}`, params, {
    withCredentials: true,
  })

  return data
}

export const usePhishingSimulationPackageUpdate = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updatePhishingSimulationPackage,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['phishing-simulations-packages'],
      })
    },
  })
}
