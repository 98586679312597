import { ReactNode } from 'react'
import React, { createContext, useContext, useState } from 'react'
import { useGetLatestTerms } from '@/api/terms/get'
import { useAcceptTerms } from '@/api/terms/post'
import { useLogout } from '@/api/auth/logout'

const TermsModalContext = createContext({
  showTermsModal: false,
  setShowTermsModal: (value: boolean) => {},
  handleAcceptTerms: () => {},
  handleDeclineTerms: () => {},
})

export const TermsModalProvider = ({ children }: { children: ReactNode }) => {
  const [showTermsModal, setShowTermsModal] = useState(false)
  const { mutateAsync: acceptTerms } = useAcceptTerms()
  const { data: latestTerms } = useGetLatestTerms()
  const [logout] = useLogout()

  const handleAcceptTerms = async () => {
    try {
      await acceptTerms({ terms_id: latestTerms?._id as string })
      setShowTermsModal(false)
    } catch (error) {
      console.error(error)
    }
  }

  const handleDeclineTerms = async () => {
    await logout()
  }

  return (
    <TermsModalContext.Provider value={{ showTermsModal, setShowTermsModal, handleAcceptTerms, handleDeclineTerms }}>
      {children}
    </TermsModalContext.Provider>
  )
}

export const useTermsModal = () => useContext(TermsModalContext)
