import { FC } from 'react'
import { ResponsivePie } from '@nivo/pie'
import { Box, Tooltip, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { faComputerMouse, faLockOpen, faEnvelopeOpen, faShield, faAtom } from '@fortawesome/pro-light-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import InfoTooltip from '@/common/components/InfoTooltip/InfoTooltip'
import { CampaignEventsStats, CampaignGroupEventsStats } from '@/common/types/campaign.types'
import { theme } from '@/theme/theme'

type CampaignGroupAnalyticsProps = {
  eventStats: CampaignEventsStats
  groupStats: CampaignGroupEventsStats
  hasDynamicGroups?: boolean
}

const EVENT_TYPES = ['email_open', 'email_link_click', 'submit', 'report']
const CampaignGroupAnalytics: FC<CampaignGroupAnalyticsProps> = ({
  eventStats,
  groupStats,
  hasDynamicGroups = false,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const renderEventTooltip = (eventType: (typeof EVENT_TYPES)[number]) => {
    switch (eventType) {
      case 'email_open':
        return <InfoTooltip title={t('campaignDashboard.outlookTooltip')} />
      default:
        return null
    }
  }

  const customStepIcon = (eventType: (typeof EVENT_TYPES)[number]) => {
    const icons = {
      email_open: <FontAwesomeIcon icon={faEnvelopeOpen as IconProp} fontSize={24} />,
      email_link_click: <FontAwesomeIcon icon={faComputerMouse as IconProp} fontSize={24} />,
      submit: <FontAwesomeIcon icon={faLockOpen as IconProp} fontSize={24} />,
      report: <FontAwesomeIcon icon={faShield as IconProp} fontSize={24} />,
    }

    return <Box className={`${classes.stepperIcon} ${classes.stepperIconActive}`}>{icons[eventType]}</Box>
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.chartsContainer}>
        {Object.keys(groupStats).length === 0 ? (
          <Typography>No group data</Typography>
        ) : (
          Object.entries(groupStats).map(([eventType, groupsData]) => (
            <Box className={classes.chartContainer}>
              <Box sx={{ position: 'relative' }}>
                <Box className={classes.pieContainer}>
                  <ResponsivePie
                    data={Object.entries(groupsData).map(([groupName, eventCount]) => ({
                      id: groupName,
                      label: groupName,
                      value: eventCount,
                    }))}
                    margin={{ top: 0, right: 0, bottom: 24, left: 0 }}
                    sortByValue={false}
                    innerRadius={0.5}
                    colors={{ scheme: 'paired' }}
                    borderWidth={1}
                    borderColor={{
                      from: 'color',
                      modifiers: [['darker', 0.2]],
                    }}
                    enableRadialLabels={false}
                  />
                </Box>
                {hasDynamicGroups && (
                  <Box className={classes.atomContainer}>
                    <Tooltip title={t('dynamicGroups.dynamicGroupInvolved')} placement="top-start">
                      <FontAwesomeIcon
                        icon={faAtom as IconProp}
                        fontSize={30}
                        style={{ marginBottom: theme.spacing(2.5) }}
                        color={theme.palette.purple[500]}
                      />
                    </Tooltip>
                  </Box>
                )}
              </Box>
              <Box className={classes.flex}>
                {customStepIcon(eventType)}
                <Typography variant={'subtitle2'}>
                  {eventStats[eventType].percentage}
                  {'% '}
                  {t(`campaignDashboard.groupAnalytics.${eventType}`)}
                </Typography>
                {renderEventTooltip(eventType)}
              </Box>
            </Box>
          ))
        )}
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
    },
    chartsContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-evenly',
    },
    chartContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    pieContainer: {
      width: 200,
      height: 200,
    },
    stepperIcon: {
      backgroundColor: '#ccc',
      zIndex: 1,
      color: '#fff',
      width: 50,
      height: 50,
      display: 'flex',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: theme.spacing(2),
    },
    stepperIconActive: {
      backgroundImage: 'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
      marginRight: theme.spacing(2),
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
    },
    atomContainer: {
      display: 'flex',
      position: 'absolute',
      top: 0,
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
)

export default CampaignGroupAnalytics
