import axios from 'axios';
import { API_URL } from '../constants';
import { useQuery } from 'react-query';
import { ServerError } from '../types';

export type ReportsAnalyticsObject = {
  total_reports: number[];
  malicious_reports: number[];
  average_response: number;
  report_accuracy: number;
  reported_last_30_days: number[];
  malicious_last_30_days: number[];
  pending_reports: number;
};

async function getReportsAnalytics() {
  const url = `${API_URL}/analytics/reports`;
  const { data } = await axios.get(url, { withCredentials: true });
  return data.data;
}

export function useReportsAnalytics() {
  return useQuery<ReportsAnalyticsObject, ServerError>(['analytics', 'reports'], getReportsAnalytics);
}
