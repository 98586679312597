import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faBookmark } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

import { find, throttle } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BigPlayButton, ClosedCaptionButton, ControlBar, PlaybackRateMenuButton, Player } from 'video-react'
import 'video-react/dist/video-react.css' // import css

import { languages } from '@/assets/locales/languages'
import useToast from '@/common/hooks/useToast'
import CustomPlayButton from './CustomPlayButton'
import SourceSelector from './SourceSelector'

type Bookmark = {
  title: string
  time: number
}

type VideoPlayerProps = {
  thumbnail?: string
  videoTracks: {
    [language: string]: string
  }
  subtitleTracks: {
    [language: string]: string
  }
  bookmarks?: Bookmark[]
  id?: string
  onSubtitleChange?: (language: string) => void
  onProgress?: (state: { currentTime: number; duration: number }) => void
  onPlay?: (state: { currentTime: number; duration: number }) => void
  onPause?: (state: { currentTime: number; duration: number }) => void
  disableSeek?: boolean
  defaultLanguage?: string
}

const VideoPlayerV2: FC<VideoPlayerProps> = ({
  thumbnail,
  videoTracks,
  subtitleTracks,
  bookmarks = [],
  onProgress,
  onPlay,
  onPause,
  id = 'video-player',
  disableSeek = false,
  onSubtitleChange = () => {},
  defaultLanguage,
}) => {
  const classes = useStyles()
  const { regularToast } = useToast()
  const { t } = useTranslation()
  const subtitles = Object.entries(subtitleTracks).map(([lang, track]) => ({
    kind: 'captions',
    src: track,
    srcLang: lang,
    label: languages[lang]?.name || lang,
    default: defaultLanguage ? lang === defaultLanguage : lang === 'en',
  }))

  const sources = Object.entries(videoTracks).map(([lang, source]) => ({
    source,
    language: lang,
    label: languages[lang]?.name,
    default: defaultLanguage ? lang === defaultLanguage : lang === 'en',
  }))

  const [selectedSource, setSelectedSource] = useState<(typeof sources)[number] | null>(
    sources.length ? find(sources, (source) => source.default) || sources[0] : null
  )

  const [playButtonVisible, setPlayButtonVisible] = useState(true)

  const videoRef = useRef<Player>(null)
  const videoStateRef = useRef({ secondsWatched: 0 })

  // disable right click to prevent users from downloading the video
  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault()
    }

    document.addEventListener('contextmenu', handleContextMenu)

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu)
    }
  }, [])

  const getVideoElement = useCallback(() => {
    if (videoRef.current) {
      return videoRef.current.video.video
    }
  }, [])

  const onSeek = useCallback(() => {
    if (disableSeek) {
      const video = getVideoElement()
      if (!video) return
      const delta = video.currentTime - videoStateRef.current.secondsWatched
      if (delta > 0.01) {
        videoRef.current?.seek?.(videoStateRef.current.secondsWatched)
        regularToast(t('trainingCampaignPage.seekDisabled'))
      }
    }
  }, [disableSeek, getVideoElement, regularToast, t])

  const onSourceChange = useCallback((source) => {
    if (source) {
      setSelectedSource(source)
      videoRef.current?.load?.()
    }
  }, [])

  const handlePlay = () => {
    if (videoRef.current) {
      videoRef.current.video.play()
    }
  }

  const handleSeek = (time: number) => {
    if (videoRef.current) {
      videoRef.current.video.seek(time)
    }
  }

  const throttledOnPlay = useMemo(
    () =>
      throttle((currentTime: number, duration: number) => {
        if (onPlay) {
          onPlay({ currentTime, duration })
        }
      }, 2000),
    [onPlay]
  )

  const handleOnPlay = useCallback(
    (currentTime: number, duration: number) => {
      throttledOnPlay(currentTime, duration)
    },
    [throttledOnPlay]
  )

  const throttledOnPause = useMemo(
    () =>
      throttle((currentTime: number, duration: number) => {
        if (onPause) {
          onPause({ currentTime, duration })
        }
      }, 2000),
    [onPause]
  )

  const handleOnPause = useCallback(
    (currentTime: number, duration: number) => {
      throttledOnPause(currentTime, duration)
    },
    [throttledOnPause]
  )

  const throttledOnProgress = useMemo(
    () =>
      throttle((currentTime: number, duration: number) => {
        if (onProgress) {
          onProgress({ currentTime, duration })
        }
      }, 1000),
    [onProgress]
  )

  const handleOnProgress = useCallback(
    (currentTime: number, duration: number) => {
      throttledOnProgress(currentTime, duration)
    },
    [throttledOnProgress]
  )

  const throttledOnSubtitleChange = useMemo(
    () =>
      throttle((language: string) => {
        onSubtitleChange(language)
      }, 1000),
    [onSubtitleChange]
  )

  const handleOnSubtitleChange = useCallback(
    (language: string) => {
      throttledOnSubtitleChange(language)
    },
    [throttledOnSubtitleChange]
  )

  const handleVideoStateChange = useCallback(
    (state, prevState) => {
      const { currentTime, duration, activeTextTrack, hasStarted, currentSrc } = state

      // if the video has started playing or doesn't have a source, hide the play button
      if (currentSrc !== prevState.currentSrc || hasStarted !== prevState.hasStarted) {
        if (!currentSrc || hasStarted) {
          setPlayButtonVisible(false)
        } else {
          setPlayButtonVisible(true)
        }
      }

      const flooredCurrentTime = Math.floor(currentTime)
      const flooredSecondsWatched = Math.floor(videoStateRef.current.secondsWatched)

      if (flooredCurrentTime !== Math.floor(prevState.currentTime)) {
        const delta = Math.round(flooredCurrentTime - flooredSecondsWatched)

        // Only update the seconds watched and call onProgress if the progress event is fired by the video playing and not a seek event
        if (delta === 1 || !disableSeek) {
          videoStateRef.current.secondsWatched = flooredCurrentTime
          handleOnProgress(currentTime, duration)
        }
      }

      // if subtitles have changed, call onSubtitleChange
      if (activeTextTrack?.language && activeTextTrack.language !== prevState?.activeTextTrack?.language) {
        handleOnSubtitleChange(activeTextTrack?.language)
      }
      // if the video has started playing, call onPlay
      if (!state.paused && prevState.paused) {
        handleOnPlay(currentTime, duration)
      }
      // if the video has been paused, call onPause
      if (state.paused && !prevState.paused) {
        handleOnPause(currentTime, duration)
      }
    },
    [disableSeek, handleOnPause, handleOnPlay, handleOnProgress, handleOnSubtitleChange]
  )

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.subscribeToStateChange(handleVideoStateChange)
    }
  }, [])

  useEffect(() => {
    const video = getVideoElement()
    video?.addEventListener('seeking', onSeek)
    if (video) {
      video.setAttribute('id', id)
    }
    return () => {
      video?.removeEventListener('seeking', onSeek)
    }
  })

  return (
    <>
      <div className={classes.root}>
        <Player fluid ref={videoRef} crossOrigin="anonymous" poster={thumbnail}>
          <source src={selectedSource?.source} />

          {subtitles.map((track) => (
            <track key={track.src} {...track} />
          ))}
          <CustomPlayButton onClick={handlePlay} visible={playButtonVisible} />
          <BigPlayButton disabled />

          <ControlBar autoHide={false}>
            <ClosedCaptionButton order={7.2} />
            {selectedSource ? (
              <SourceSelector order={7.3} sources={sources} onChange={onSourceChange} currentSource={selectedSource} />
            ) : null}

            <PlaybackRateMenuButton rates={[2, 1.5, 1, 0.75]} order={7.4} />
          </ControlBar>
        </Player>

        {bookmarks.length > 0 && (
          <Box className={classes.palyerBookmarks}>
            {bookmarks.map((bookmark, i) => (
              <Button
                key={i}
                variant="contained"
                color="secondary"
                sx={{ padding: '4px 32px' }}
                startIcon={<FontAwesomeIcon icon={faBookmark as IconProp} />}
                onClick={() => handleSeek(bookmark.time)}
              >
                {bookmark.title}
              </Button>
            ))}
          </Box>
        )}
      </div>
    </>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: '100%',
      borderRadius: theme.shape.borderRadius,
      paddingBottom: theme.spacing(2),
      '& .video-react-menu .video-react-menu-content': {
        fontFamily: theme.typography.fontFamily,
      },
      '& .video-react': {
        fontFamily: theme.typography.fontFamily,
        '& .video-react-play-progress': {
          backgroundColor: theme.palette.cyan[500],
        },
      },
    },
    palyerBookmarks: {
      padding: theme.spacing(2),
      display: 'flex',
      justifyContent: 'space-evenly',
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.blueGray[900],
    },
  })
)

export default VideoPlayerV2
