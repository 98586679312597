import React from "react";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { CircularProgress, Paper } from "@mui/material";

type LoadingContainerProps = {
  height?: number;
  container?: "div" | "paper";
};

export default function LoadingContainer({
  height,
  container = "div",
}: LoadingContainerProps) {
  const classes = useStyles({ height });
  return container === "div" ? (
    <div className={classes.root}>
      <CircularProgress size={24} color={"primary"} />
    </div>
  ) : (
    <Paper className={classes.root}>
      <CircularProgress size={24} color={"primary"} />
    </Paper>
  );
}

const useStyles = makeStyles<Theme, LoadingContainerProps>((theme) =>
  createStyles({
    root: {
      height: ({ height }) => (height ? height : "100%"),
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  })
);
