// TODO Refactor all the places where this is use
// and replace with TagsFilterNew component

import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { Chip, Tabs } from '@mui/material'
import { theme } from '@/theme/theme'

type TagsFilterProps = {
  tags: string[]
  selectedTags: string[]
  onTagClick: (tag: string) => void
}

export default function TagsFilter({ selectedTags, onTagClick, tags }: TagsFilterProps) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Tabs variant="scrollable" scrollButtons classes={{ indicator: classes.indicator }} allowScrollButtonsMobile>
        {tags.map((tag, i) => {
          const selected = selectedTags.includes(tag)
          return (
            <Chip
              key={i}
              className={`${classes.chip} ${selected ? classes.selectedChip : ''}`}
              classes={{ root: classes.chip }}
              clickable
              variant="filter"
              label={tag}
              color={selected ? 'secondary' : 'default'}
              onClick={() => onTagClick(tag)}
            />
          )
        })}
      </Tabs>
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& .MuiTabScrollButton-root': {
        '&.Mui-disabled': {
          opacity: 0.2,
        },
      },
    },
    indicator: {
      display: 'none',
    },
    chip: {
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(0.75),
      borderRadius: 5,
      height: 24,
      backgroundColor: 'lightgray',
    },
    selectedChip: {
      backgroundColor: theme.palette.cyan[300],
    },
  })
)
