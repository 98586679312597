import { Button, CircularProgress, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { string } from 'yup'
import useForm, { Field } from '../../common/components/Form/useForm'
import Form from '../../common/components/Form/Form'
import { faCircleExclamation } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { useVerifyPasswordResetToken } from '../../api/auth/verify-password-reset-token'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const fields: Field[] = [
  {
    name: 'token',
    label: 'verifyToken.form.code',
    validationRule: string().required('verifyToken.errors.missingCode'),
    type: 'text',
    required: true,
    autoFocus: true,
  },
]

export default function VerifyResetToken() {
  const { t } = useTranslation()
  const classes = useStyles()
  const navigateTo = useNavigate()
  const { username } = useParams()

  const [verifyToken, { isLoading, error }] = useVerifyPasswordResetToken(() =>
    navigateTo(`/reset-password/set-new/${username}/${formProps.values.token}`)
  )

  function handleVerifyToken(formValues) {
    verifyToken({ ...formValues, username })
  }

  const { formProps, handleSubmit } = useForm({
    fields,
    onSubmit: handleVerifyToken,
  })

  return (
    <div className={classes.container}>
      <Typography variant="h5">{t('verifyToken.enterCode')}</Typography>
      <Typography variant="subtitle1">{t('verifyToken.enterCodeDescription')}</Typography>
      <div className={classes.form}>
        {error && (
          <div className={classes.errorMessage}>
            <FontAwesomeIcon icon={faCircleExclamation as IconProp} />
            <Typography variant="subtitle2" color={'inherit'}>
              {error?.response?.data?.message}
            </Typography>
          </div>
        )}
        <Form {...formProps} />
        <Button onClick={handleSubmit} fullWidth variant="contained" color="primary" className={classes.submit}>
          {isLoading ? <CircularProgress color={'secondary'} size={24} /> : t('verifyToken.verify')}
        </Button>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorMessage: {
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.error.main,
    paddingBottom: theme.spacing(2),
  },
  errorIcon: {
    marginRight: 4,
  },
}))
