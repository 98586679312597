import { FC } from 'react'
import { Typography, Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import useLottie from '@/common/hooks/useLottie'
import LogoText from '@/assets/images/logo-stay-safe.svg'
import { useTranslation } from 'react-i18next'
import trainingCompleteAnimation from '@/assets/lottie/completeCheck.json'

const TrainingComplete: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { LottieAnimation } = useLottie({
    animationData: trainingCompleteAnimation,
    height: 300,
    width: 300,
    options: {
      loop: false,
      autoplay: true,
      animationData: trainingCompleteAnimation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    },
  })

  return (
    <Box className={classes.root}>
      <img alt={'Cywareness Logo'} src={LogoText} className={classes.cywarenessLogo} />
      <Typography className={classes.mainBanner}>{t('trainingCompleted.title')}</Typography>
      <Box className={classes.animation}>{LottieAnimation}</Box>
      <Typography className={classes.messageText}>{t('trainingCompleted.description')}</Typography>
      <Typography className={classes.messageText}>{t('trainingCompleted.beAware')}</Typography>
    </Box>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      textAlign: 'center',
      height: '100vh',
    },
    cywarenessLogo: {
      margin: 10,
      height: 72,
      width: 'auto',
    },
    mainBanner: {
      fontSize: 117,
      fontWeight: 400,
      fontFamily: 'Pattaya',
      background: 'linear-gradient(182.66deg, #96B3BA -10.31%, #294D55 63.43%, #1E1E1E 97.66%)',
      WebkitTextFillColor: 'transparent',
      WebkitBackgroundClip: 'text',
      wordWrap: 'break-word',
      padding: theme.spacing(0, 2, 2, 0),
      margin: theme.spacing(2),
    },
    animation: {
      margin: theme.spacing(0, 0, 5, 0),
    },
    messageText: {
      fontFamily: 'Montserrat',
      fontSize: 30,
      fontWeight: 500,
      wordWrap: 'break-word',
      textAlign: 'center',
      margin: theme.spacing(2),
    },
  })
)

export default TrainingComplete
