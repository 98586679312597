import { config } from "../config";

export function resizeImage(imageUrl: string, width: number, height: number) {
  if (!isCloudinaryUrl(imageUrl)) return imageUrl;
  const keyword = "upload/";
  const insertIndex = imageUrl.indexOf(keyword) + keyword.length;
  return `${imageUrl.substring(
    0,
    insertIndex
  )}w_${width}/h_${height}/${imageUrl.substring(insertIndex)}`;
}

export function isCloudinaryUrl(url: string) {
  return (
    url.includes(config.cloudinaryProductionDomain) ||
    url.includes(config.cloudinaryDevelopmentDomain)
  );
}
