import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  faCirclePlay,
  faInfoCircle,
  faPaintbrushPencil,
  faTrash,
  faHeart as heartIcon,
} from '@fortawesome/pro-light-svg-icons'
import { faHeart as fullHeartIcon } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Collapse, Fade, IconButton, Tooltip } from '@mui/material'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { EducationModule } from '@/api/education-modules/modules'
import CywarenessDefaultImage from '@/assets/images/cywareness_default.png'
import Tags from '@/common/components/Tags/Tags'
import { resizeImage } from '@/common/utils/cloudinary-utils'
import { secondsToTimestamp } from '@/common/utils/secondsToTimestamp'
import { shortenString } from '@/common/utils/shortenString'
import { useAuth } from '@/context/Auth'

type VideoCardProps = {
  video: EducationModule
  openPreviewModal: (video: EducationModule) => void
  launchModule: (video: EducationModule) => void
  deleteModule: (video: EducationModule) => void
  saveModule: (video: EducationModule) => void
  isSaved: boolean
  hoverState: -1 | 0 | 1 | null
  onMouseEnter: () => void
  onMouseLeave: () => void
}

const VideoCard: FC<VideoCardProps> = ({
  video,
  launchModule,
  deleteModule,
  saveModule,
  isSaved,
  hoverState,
  onMouseLeave,
  onMouseEnter,
}) => {
  const navigateTo = useNavigate()
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const { user } = useAuth()
  const isLesson = video.additional_resources?.lesson_plan
  const isCustom = video.client_id === user?.current_client_id
  const hoverClass =
    hoverState === null
      ? ''
      : hoverState === 0
      ? classes.scale
      : hoverState > 0
      ? classes.translateLeft
      : classes.translateRight

  function handleLike() {
    saveModule(video)
  }

  function handleDelete() {
    deleteModule(video)
  }

  function handleLaunch() {
    launchModule(video)
  }

  function openVideo() {
    if (!video?._id) {
      return
    }
    navigateTo(`/content-library/videos/${video._id}`)
  }

  return (
    <Box className={`${classes.root} ${hoverClass}`}>
      <Box overflow="visible" position="relative" width="max-content">
        {isCustom && !isLesson ? (
          <>
            <Box className={classes.customBadgeAnchor}>
              <FontAwesomeIcon icon={faPaintbrushPencil as IconProp} />
              <Typography variant="body2">{t('videoLibrary.custom')}</Typography>
            </Box>
            <Box className={classes.badgeWrap} />
          </>
        ) : null}
        {isLesson ? (
          <>
            <Box className={classes.lessonBadgeAnchor}>
              <Typography variant="body2">{t('videoLibrary.lesson')}</Typography>
            </Box>
            <Box className={classes.badgeWrap} />
          </>
        ) : null}
        <Card className={classes.card} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          <CardActionArea onClick={openVideo}>
            <Box className={classes.contentContainer}>
              <Box className={classes.overlay} />

              <Typography className={classes.duration} variant="body2">
                {secondsToTimestamp(video.duration)}
              </Typography>
              <Box className={classes.topActionsContainer}>
                <Fade in={video.client_id === user?.current_client_id && hoverState === 0}>
                  <IconButton
                    onClick={(e) => {
                      handleDelete()
                      e.stopPropagation()
                    }}
                    size="small"
                    color={'inherit'}>
                    <FontAwesomeIcon icon={faTrash as IconProp} fontSize={22} />
                  </IconButton>
                </Fade>
                <Fade in={isSaved === true || hoverState === 0}>
                  <IconButton
                    onClick={(e) => {
                      handleLike()
                      e.stopPropagation()
                    }}
                    size="small"
                    color={'inherit'}>
                    <FontAwesomeIcon
                      icon={(isSaved ? fullHeartIcon : heartIcon) as IconProp}
                      fontSize={22}
                      color={theme.palette.pink[500]}
                    />
                  </IconButton>
                </Fade>
              </Box>

              <CardMedia
                component="img"
                alt={`Image of ${video.title}`}
                height="200"
                image={resizeImage(video.thumbnail || CywarenessDefaultImage, 300, 200)}
                title={video.title}
              />
              <Box className={classes.playButton}>
                <FontAwesomeIcon icon={faCirclePlay as IconProp} fontSize={80} />
                {/* <PlayIcon size={80} variant="Bold" /> */}
              </Box>

              <Box className={classes.container}>
                <Box className={classes.titleContainer}>
                  <Typography className={classes.title} noWrap variant="h6">
                    {video.title}
                  </Typography>
                  <Tooltip
                    title={
                      <Box className={classes.tooltip}>
                        <Typography variant="subtitle2">{t('videoLibrary.summary')}</Typography>
                        <Typography variant="body1">{shortenString(video.description, 150)}</Typography>
                      </Box>
                    }
                    placement={'top'}>
                    <FontAwesomeIcon icon={faInfoCircle as IconProp} fontSize={20} />
                  </Tooltip>
                </Box>
              </Box>
            </Box>
            <Collapse in={hoverState === 0} collapsedSize={0}>
              <Box className={classes.buttonContainer}>
                <Box className={classes.stat}>
                  <Typography variant="subtitle2" className={classes.statKey}>
                    {t('videoLibrary.topics')}:{' '}
                  </Typography>
                  <Tags
                    useChips={false}
                    tags={video.tags.map((tag) => ({
                      label: tag,
                      color: 'transparent',
                      textColor: theme.palette.common.white,
                    }))}
                    limit={1}
                  />
                </Box>
                <Button
                  onClick={(e) => {
                    handleLaunch()
                    e.stopPropagation()
                  }}
                  variant={'contained'}
                  size="small">
                  {t('videoLibrary.launch')}
                </Button>
              </Box>
              {/* <CardActions className={classes.cardActions}>
            <Tooltip
              title={
                <div className={classes.tooltip}>
                  <Typography variant="subtitle2">
                    {t("videoLibrary.summary")}
                  </Typography>
                  <Typography variant="body1">{video.description}</Typography>
                </div>
              }
              placement={"top"}
            >
              <InfoIcon />
            </Tooltip> */}
              {/* TODO Uncomment once training programs exist */}
              {/* <Tooltip
            title={
              <div className={classes.tooltip}>
                <Typography variant="subtitle2">
                  {t("videoLibrary.partOf")}
                </Typography>
                <Typography variant="body1">training campaign</Typography>
              </div>
            }
            placement={"top"}
          >
            <SchoolIcon />
          </Tooltip> */}
              {/* </CardActions> */}
            </Collapse>
          </CardActionArea>
        </Card>
      </Box>
    </Box>
  )
}

// TODO: replace with styled component!
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 1),
    transition: 'transform 300ms ease 100ms',
    zIndex: 10,
    width: 'max-content',
  },
  card: {
    width: 350,
    minHeight: 200,
    position: 'relative',
  },
  customBadgeAnchor: {
    position: 'absolute',
    zIndex: 2,
    top: theme.spacing(6.5),
    right: theme.spacing(-1),
    background: theme.palette.cyan[100],
    color: theme.palette.blueDianne[900],
    padding: theme.spacing(0.5, 1),
    pointerEvents: 'none',
    display: 'flex',
    gap: theme.spacing(0.5),
    alignItems: 'center',
  },
  lessonBadgeAnchor: {
    position: 'absolute',
    zIndex: 2,
    top: theme.spacing(6.5),
    right: theme.spacing(-1),
    background: '#C2FA87',
    color: '#294D55',
    padding: theme.spacing(0.5, 2),
    pointerEvents: 'none',
  },
  badgeWrap: {
    position: 'absolute',
    zIndex: 1,
    top: theme.spacing(6),
    right: theme.spacing(-1.1),
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '4px 0 0 10px',
    borderColor: 'transparent transparent transparent #9bcad1',
    pointerEvents: 'none',
  },
  duration: {
    position: 'absolute',
    zIndex: 1,
    top: theme.spacing(1.5),
    left: theme.spacing(1.5),
    color: theme.palette.common.white,
  },
  topActionsContainer: {
    position: 'absolute',
    zIndex: 1,
    top: theme.spacing(1),
    right: theme.spacing(1.5),
    display: 'flex',
    color: theme.palette.common.white,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.palette.common.white,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    backgroundColor: theme.palette.common.black,
    padding: theme.spacing(1, 2),
  },
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
  },
  title: {
    marginRight: theme.spacing(1),
    color: theme.palette.common.white,
    zIndex: 1,
    fontWeight: 400,
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.common.white,
  },
  contentContainer: {
    position: 'relative',
    height: 200,
  },
  stat: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.common.white,
  },
  statKey: { marginRight: theme.spacing(1) },
  tooltip: {
    padding: theme.spacing(1, 0.5),
  },
  cardActions: {
    justifyContent: 'flex-end',
    backgroundColor: theme.palette.common.black,
    color: theme.palette.cyan[500],
    transition: 'all 300ms ease 100ms',
    padding: theme.spacing(1, 2),
  },
  translateLeft: {
    transform: 'translateX(-5%)',
  },
  translateRight: {
    transform: 'translateX(5%)',
  },
  scale: {
    transform: 'scale(1.05) translateY(-7%)',
  },
  overlay: {
    position: 'absolute',
    height: 80,
    bottom: 0,
    right: 0,
    left: 0,
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 80%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    pointerEvents: 'none',
  },
  playButton: {
    pointerEvents: 'none',
    color: theme.palette.common.white,
    position: 'absolute',
    zIndex: 1,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

export default VideoCard
