import axios from "axios";
import { API_URL } from "../constants";
import { useMutation, useQueryCache } from "react-query";
import { ServerError } from "../types";
import { NewEducationModuleParams } from "./create-module";
import { EducationModule } from "./modules";

export type EditEducationModuleParams = {
  _id: string;
  params: Partial<NewEducationModuleParams>;
};

export async function editModule({
  _id,
  params,
}: EditEducationModuleParams): Promise<EducationModule> {
  const url = `${API_URL}/education_modules/update/${_id}`;
  const { data } = await axios.post(url, params, { withCredentials: true });
  return data.data;
}

export function useEditEducationModule() {
  const queryCache = useQueryCache();
  return useMutation<EducationModule, ServerError, EditEducationModuleParams>(
    editModule,
    {
      throwOnError: true,
      onSettled: () => {
        queryCache.invalidateQueries("modules");
      },
    }
  );
}
