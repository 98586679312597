import { Box } from '@mui/material'
import { LanguageSelect } from '../components/LanguageSelect'
import { ToneOfVoiceSelect } from '../components/ToneOfVoiceSelect'
import { SenderName } from '../components/SenderName'
import { SenderPosition } from '../components/SenderPosition'
import { EmailBody } from '../components/EmailBody'
import { Instructions } from '../components/Instructions'
import { EmailSenderSignature } from '../components/EmailSenderSignature'
import AdvancedTemplateSettings from '../components/AdvancedTemplateSettings'
import { VectorType } from '../state'

type HumanEmailFormProps = {
  simulationVectorType: string
}

// TODO: consider change name of this component which used for sms and whatsapp simulations.

export const HumanEmailForm = ({ simulationVectorType }: HumanEmailFormProps) => {
  return (
    <Box display="flex" flexDirection="column" gap={1.5}>
      <Box display="flex" justifyContent="space-between" gap={1.5}>
        <LanguageSelect />
        <ToneOfVoiceSelect />
      </Box>
      <Box display="flex" justifyContent="space-between" gap={1.5}>
        <SenderName />
        <SenderPosition />
      </Box>
      <Box>
        <EmailBody simulationVectorType={simulationVectorType} />
      </Box>
      <Box>
        <Instructions simulationVectorType={simulationVectorType} />
      </Box>
      {simulationVectorType === VectorType.EMAIL && (
        <Box>
          <EmailSenderSignature />
        </Box>
      )}
      <Box>
        <AdvancedTemplateSettings />
      </Box>
    </Box>
  )
}
