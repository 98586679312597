import { FC, useEffect, useState, SyntheticEvent } from 'react'
import { Box, Tab, Tabs, Tooltip } from '@mui/material'
import MonitorPreview from './MonitorPreview'
import EmailBodyOutput from './EmailBodyOutput'
import LandingPageOutput from './LandingPageOutput'
import { useCategories } from '../../../../api/categories/categories'
import { OpenAIRequest } from '../../../../api/open-ai/openai-request'
import endent from 'endent'
import { useAiSimulationContext } from '../state'
import MicroTrainingOutput from './MicroTrainingOutput'
import MobilePhonePreview from '@/common/components/MobilePhonePreview'
import SmsAppPreview from '@/common/components/MobilePhonePreview/SmsAppPreview'
import { PhishingSimulationVector } from '@/types/phishingSimulations'
import WhatsAppPreview from '@/common/components/MobilePhonePreview/WhatsAppPreview'
import BrowserAppPreview from '@/common/components/MobilePhonePreview/BrowserAppPreview'
import microTrainingScreen from '@/assets/images/MobilePreview/MicroTrainingPreview/MicroTrainingScreen.png'

const SummaryTabs: FC = () => {
  const [tabValue, setTabValue] = useState(0)
  const {
    bodyOutput,
    setSimulationName,
    landingPageOutput,
    setTopics,
    emailBody,
    subjectOutput,
    simulationType,
    vectorType,
    appName,
    senderName,
  } = useAiSimulationContext()
  const { data: categoryData } = useCategories()
  const { categories_by_tag } = categoryData || {}
  const [tags, setTags] = useState<string[]>([])
  const [isPreviewInFullscreen, setIsPreviewInFullscreen] = useState(false)

  useEffect(() => {
    const tags = Object.keys(categories_by_tag || [])
    setTags(tags)
  }, [categories_by_tag])

  const handleTabChange = (_event: SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  useEffect(() => {
    if (tags.length) {
      const prompt = createTopicsPrompt(tags)
      generateTopics(prompt).then(async (response) => {
        const message = await response.json()
        const data: string = message?.choices[0]?.message?.content

        // TODO: add a safety check to make sure only topics are listed
        const returnedTopics =
          data
            .split(',')
            .map((topic) => topic.trim())
            .filter((topic) => tags.includes(topic)) || []
        setTopics(returnedTopics)
      })
    }
  }, [tags])

  useEffect(() => {
    const prompt = createSimulationNamePrompt()
    generateSimulationName(prompt).then(async (response) => {
      const message = await response.json()
      const data = message?.choices[0]?.message?.content

      setSimulationName(data)
    })
  }, [])

  const createTopicsPrompt = (tags: string | string[]) => {
    const prompt = endent`
    We are generating a simulated phishing simulation, we need to categorize this simulations using tags.\
    Here are some details:
    Email Subject: ${subjectOutput},
    Impersonation Type:  ${simulationType},
    Distributed by:  ${vectorType},
    Simulation generally based on the following prompt: ${emailBody}.
    return between 3-5 tags that best match where, the key is the tag and tag["name"] is a category:  ${categories_by_tag}
    We must use tags related to at least 2 categories, and at least 1 tag from each category.
    Possible output Tags: ${tags} - (Critical!: Strictly adhere to this list)
    (Critical!: each separated by comma, provide only the tags, not other information)`

    return prompt
  }

  const createSimulationNamePrompt = () => {
    const timestamp = Math.floor(Date.now() / 1000)
    const prompt = endent`
    Act as a content creator for a phishing simulation.
    You are creating a unique name for a landing page for a phishing simulation.
    Here are some details:
    Email Subject: ${subjectOutput},
    Email Sender: ${senderName},
    Impersonation Type:  ${simulationType},
    Distributed by:  ${vectorType},
    This name needs to be a very unique, readable, and descriptive short phrase using spaces.
    up to 50 characters long.
    Append the last 5 digits of ${timestamp} to the name to make it unique, and wrap in rounded brackets.
    Return ONLY the phrase (no code, no other details needed, do not wrap in apostraphe)
    `

    return prompt
  }

  const generateTopics = async (prompt: string) => {
    try {
      const stream = await OpenAIRequest({
        prompt,
        maxTokens: 2000,
        temperature: 0.7,
      })
      return new Response(stream)
    } catch (error) {
      console.error(error)
      return new Response('Error', { status: 500 })
    }
  }

  const generateSimulationName = async (prompt: string) => {
    try {
      const stream = await OpenAIRequest({ prompt, maxTokens: 500 })
      return new Response(stream)
    } catch (error) {
      console.error(error)
      return new Response('Error', { status: 500 })
    }
  }

  const TabContent = () => {
    switch (tabValue) {
      case 0:
        return (
          <Box>
            {vectorType === PhishingSimulationVector.Email ? (
              <MonitorPreview
                pageType="email"
                hasContent={true}
                isLoading={false}
                onFullscreenToggle={(isFullscreen) => setIsPreviewInFullscreen(isFullscreen)}
              >
                <EmailBodyOutput text={bodyOutput} isModal={isPreviewInFullscreen} />
              </MonitorPreview>
            ) : vectorType === PhishingSimulationVector.SMS ? (
              //add fullscreen
              <MobilePhonePreview>
                <SmsAppPreview sender={appName || senderName} message={bodyOutput} />
              </MobilePhonePreview>
            ) : (
              //add fullscreen
              <MobilePhonePreview>
                <WhatsAppPreview sender={appName || senderName} message={bodyOutput} />
              </MobilePhonePreview>
            )}
          </Box>
        )
      case 1:
        return (
          <Box>
            {vectorType === PhishingSimulationVector.Email ? (
              <MonitorPreview
                pageType="landing"
                hasContent={true}
                isLoading={false}
                onFullscreenToggle={(isFullscreen) => setIsPreviewInFullscreen(isFullscreen)}
              >
                <LandingPageOutput text={landingPageOutput} vectorType={vectorType} isModal={isPreviewInFullscreen} />
              </MonitorPreview>
            ) : (
              //add fullscreen
              <MobilePhonePreview>
                <BrowserAppPreview fakeUrl={appName || senderName}>
                  <LandingPageOutput text={landingPageOutput} vectorType={vectorType} isModal={false} />
                </BrowserAppPreview>
              </MobilePhonePreview>
            )}
          </Box>
        )
      case 2:
        return (
          <Box>
            {vectorType === PhishingSimulationVector.Email ? (
              <MonitorPreview
                pageType="micro-training"
                hasContent={true}
                isLoading={false}
                onFullscreenToggle={(isFullscreen) => setIsPreviewInFullscreen(isFullscreen)}
              >
                <MicroTrainingOutput />
              </MonitorPreview>
            ) : (
              //add fullscreen
              <MobilePhonePreview>
                <BrowserAppPreview fakeUrl={'micro-training'}>
                  <Box>
                    <Tooltip title="Full micro-training will be available to preview after saving">
                      <img src={microTrainingScreen} style={{ width: '100%', objectFit: 'cover' }}></img>
                    </Tooltip>
                  </Box>
                </BrowserAppPreview>
              </MobilePhonePreview>
            )}
          </Box>
        )
    }
  }
  return (
    <Box sx={{ margin: '0 auto' }}>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="summary tabs"
        sx={{
          width: 'fit-content',
          margin: '0 auto',
        }}
      >
        <Tab label="Template" />
        <Tab label="Landing Page" />
        <Tab label="Micro Training" />
      </Tabs>

      {<TabContent />}
    </Box>
  )
}

export default SummaryTabs
