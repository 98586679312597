import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { useParams, useNavigate } from 'react-router-dom'
import LoadingContainer from '@/common/components/LoadingContainer/LoadingContainer'
import { Typography } from '@mui/material'
import InvalidToken from './InvalidToken'
import useLogEvent from '@/common/hooks/useLogEvent'
import { EVENT_TYPES, CAMPAIGN_TYPES } from '@/api/events/log'
import { useTrainingByToken } from '@/api/training-campaigns/training-by-token'
import { useQuizById } from '@/api/quizzes/quiz-by-id'
import { useUrlSearchQuery } from '@/common/hooks/useUrlQuery'
import { isValidLanguageKey } from '@/common/utils/language-utils'
import VideoPlayerV2 from '@/common/components/VideoPlayerV2/VideoPlayerV2'
import TrainingCampaignViewHeader from './TrainingCampaignViewHeader'
import Logo from '@/assets/images/logo-clean-blue.svg'
import UnfinishedAlert from './UnfinishedAlert'

const TrainingCampaignView: FC = () => {
  const navigateTo = useNavigate()

  const classes = useStyles()
  const { token } = useParams()
  const queryParams = useUrlSearchQuery()
  const enableSkip = queryParams.enableSkip === 'true'
  const { data, isLoading, isError, error } = useTrainingByToken(token as string)

  useEffect(() => {
    if (error?.response?.status === 410) {
      navigateTo(`/expired-campaign/${token}`)
    }
  }, [error, navigateTo, token])

  const { data: quizData } = useQuizById({
    campaign: token,
    quiz_id: data?.quizzes?.[0]?._id || '',
  })
  const hasAnsweredQuiz = !!(
    quizData &&
    quizData.questions.length === quizData.questions.filter((question) => !!question.submitted_answer).length
  )
  const hasQuiz = !!data?.quizzes?.length
  const { log } = useLogEvent()
  const [currentLanguage, setCurrentLanguage] = useState(
    isValidLanguageKey(queryParams.language) ? queryParams.language : 'en'
  )
  const [hasViewedEntireVideo, setHasViewedEntireVideo] = useState(false)
  const [videoState, setVideoState] = useState({
    currentTime: 0,
    duration: data?.duration || 100,
  })
  const [alertIsOpen, setAlertIsOpen] = useState(false)
  const [hasMouseEntered, setHasMouseEntered] = useState(false)
  const alertRef = useRef(false)
  const isRtl = currentLanguage === 'he' || currentLanguage === 'ar'

  const percentagePlayedRef = useRef(0)

  const changeLanguage = useCallback(async (value) => {
    if (isValidLanguageKey(value)) {
      setCurrentLanguage(value)
    }
  }, [])

  useEffect(() => {
    if (isValidLanguageKey(queryParams.language)) {
      changeLanguage(queryParams.language)
    }
  }, [changeLanguage, queryParams.language])

  const onBlur = useCallback(() => {
    log({
      campaignToken: token,
      campaignType: CAMPAIGN_TYPES.TRAINING,
      eventType: EVENT_TYPES.VIDEO_TAB_BLUR,
    })
  }, [token, log])

  const onFocus = useCallback(() => {
    log({
      campaignToken: token,
      campaignType: CAMPAIGN_TYPES.TRAINING,
      eventType: EVENT_TYPES.VIDEO_TAB_FOCUS,
    })
  }, [token, log])

  const onTabClose = useCallback(() => {
    log({
      campaignToken: token,
      campaignType: CAMPAIGN_TYPES.TRAINING,
      eventType: EVENT_TYPES.VIDEO_TAB_CLOSE,
    })
  }, [token, log])

  const onMouseLeave = () => {
    if (!alertRef.current && hasMouseEntered) {
      alertRef.current = true
      setAlertIsOpen(true)
    }
  }

  const onMouseEnter = () => {
    if (!hasMouseEntered) {
      setHasMouseEntered(true)
    }
  }

  useEffect(() => {
    window.addEventListener('focus', onFocus)
    window.addEventListener('blur', onBlur)
    window.addEventListener('beforeunload', onTabClose)
    document.addEventListener('mouseenter', onMouseEnter)
    document.addEventListener('mouseleave', onMouseLeave)
    if (token) {
      log({
        campaignToken: token,
        campaignType: CAMPAIGN_TYPES.TRAINING,
        eventType: EVENT_TYPES.VIDEO_PAGE_LOAD,
      })
    }
    return () => {
      window.removeEventListener('focus', onFocus)
      window.removeEventListener('blur', onBlur)
      window.removeEventListener('beforeunload', onTabClose)
      document.removeEventListener('mouseenter', onMouseEnter)
      document.removeEventListener('mouseleave', onMouseLeave)
    }
  }, [hasMouseEntered])

  const handleOnPlay = useCallback(() => {
    log({
      campaignToken: token,
      campaignType: CAMPAIGN_TYPES.TRAINING,
      eventType: EVENT_TYPES.TRAINING_OPENED,
    })
  }, [log, token])

  const handleProgress = useCallback(
    (state: { currentTime: number; duration: number }) => {
      setVideoState(state)
      const percentage = Math.ceil((state.currentTime / state.duration) * 100)
      const seconds = Math.ceil(state.currentTime)
      percentagePlayedRef.current = percentage
      if (seconds > 0 && seconds % 5 === 0) {
        log({
          campaignToken: token,
          campaignType: CAMPAIGN_TYPES.TRAINING,
          eventType: EVENT_TYPES.VIDEO_RUNNING,
          additionalData: {
            seconds_played: seconds,
          },
        })
      }
      if (percentage >= 90) {
        setHasViewedEntireVideo((prev) => {
          // Only trigger the event once
          if (!prev) {
            log({
              campaignToken: token,
              campaignType: CAMPAIGN_TYPES.TRAINING,
              eventType: EVENT_TYPES.TRAINING_COMPLETED,
            })
          }
          return true
        })
      }
    },
    [log, token]
  )

  const handlePause = useCallback(() => {
    // Prevent pause event when video ends
    if (percentagePlayedRef.current !== 100) {
      log({
        campaignToken: token,
        campaignType: CAMPAIGN_TYPES.TRAINING,
        eventType: EVENT_TYPES.TRAINING_PAUSED,
        additionalData: {
          percent_completed: percentagePlayedRef.current,
        },
      })
    }
  }, [log, token])

  function handleContinue() {
    if (data?.quizzes?.length === 0) {
      navigateTo(`/content/completed-training`)
    }

    if (data?.quizzes?.length && !hasAnsweredQuiz) {
      navigateTo(
        `/content/quiz/${token}/${data.quizzes[0]._id}${
          queryParams.language ? `?language=${queryParams.language}` : ''
        }`
      )
    }
  }

  const onSubtitleChange = useCallback(
    (lang: string) => {
      log({
        campaignToken: token,
        campaignType: CAMPAIGN_TYPES.TRAINING,
        eventType: EVENT_TYPES.SUBTITLE_CHANGE,
        additionalData: {
          language: lang,
        },
      })
    },
    [log, token]
  )
  const videoPlayerProps = useMemo(() => {
    return {
      videoTracks: data?.video_tracks ? data.video_tracks : {},
      subtitleTracks: data?.subtitle_tracks ? data.subtitle_tracks : {},
      thumbnail: data?.thumbnail ? data?.thumbnail : '',
      onProgress: handleProgress,
      onPlay: handleOnPlay,
      onPause: handlePause,
      onSubtitleChange: onSubtitleChange,
      disableSeek: !enableSkip,
      defaultLanguage: currentLanguage,
    }
  }, [currentLanguage, data, handleOnPlay, handlePause, handleProgress, onSubtitleChange, enableSkip])

  return (
    <div className={classes.root}>
      <UnfinishedAlert open={alertIsOpen} handleClose={() => setAlertIsOpen(false)} />
      {!isLoading ? (
        isError || !data ? (
          <InvalidToken />
        ) : (
          <>
            <TrainingCampaignViewHeader
              hasAnsweredQuiz={hasAnsweredQuiz}
              enableContinue={hasViewedEntireVideo && !hasAnsweredQuiz}
              hasQuiz={hasQuiz}
              videoProgress={Math.ceil((videoState.currentTime / videoState.duration) * 100)}
              handleContinue={handleContinue}
            />
            <div className={classes.flexCenter}>
              <div className={classes.videoContainer}>
                <VideoPlayerV2 {...videoPlayerProps} />
                <Typography
                  gutterBottom
                  id={'subject'}
                  variant={'h6'}
                  style={{
                    textAlign: 'left',
                    direction: isRtl ? 'rtl' : 'ltr',
                  }}>
                  {data.title}
                </Typography>
              </div>
            </div>
          </>
        )
      ) : (
        <LoadingContainer />
      )}
      <img className={classes.logoWatermark} src={Logo} alt={'logo'} />
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      paddingBottom: theme.spacing(4),
      height: '100%',
      overflow: 'hidden',
    },
    container: {
      padding: theme.spacing(2, 4),
      marginBottom: theme.spacing(4),
    },
    flexCenter: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },

    videoContainer: {
      width: '100%',
      maxWidth: '60%',
      minHeight: 400,
    },
    logoWatermark: {
      position: 'absolute',
      bottom: -100,
      left: -50,
      opacity: 0.4,
      zIndex: -1,
      height: 400,
      transform: 'rotate(20deg)',
    },
  })
)

export default TrainingCampaignView
