import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { useDropzone } from 'react-dropzone'
import { CircularProgress, Typography, Box, styled, Button } from '@mui/material'
import useToast from '../../hooks/useToast'
import { useTranslation } from 'react-i18next'
import { useUploadVideoToCloudinary } from '../../../api/upload/upload-cloudinary-direct'
import { faUpload } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FC } from 'react'

type ImageUploadProps = {
  onUpload: (image: { url: string; name: string }) => void
}

const ImageUploadComponent: FC<ImageUploadProps> = ({ onUpload }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { errorToast } = useToast()
  const [uploadFile, { isLoading }] = useUploadVideoToCloudinary()
  const { getInputProps, getRootProps } = useDropzone({
    onDrop: handleUpload,
    noDrag: false,
    multiple: false,
    accept: '.jpeg, .png, .gif, .pdf',
  })

  async function handleUpload(acceptedFiles: any) {
    try {
      const file = await uploadFile(acceptedFiles[0])
      if (file) {
        onUpload({
          name: acceptedFiles[0].name,
          url: file.url,
        })
      } else {
        errorToast(t('uploadImageModal.errors.failedToUpload'))
      }
    } catch (e) {
      console.log(e)
      errorToast(t('uploadImageModal.errors.failedToUpload'))
    }
  }

  return (
    <div className={classes.root} {...getRootProps()}>
      {!isLoading ? (
        <>
          <FontAwesomeIcon style={{ padding: '20px' }} fontSize={35} icon={faUpload as IconProp} />
          <Typography style={{ fontWeight: 'normal', fontSize: 18 }}>{t('ImageUpload.title')}</Typography>
          <Typography style={{ fontWeight: 'normal', fontSize: 14 }}>Supported Formats</Typography>
          <ColorsBoxWrapper>
            <ColorBox color="#F0FAFF">
              <Typography style={{ fontWeight: 'normal', fontSize: 14 }}>.jpeg</Typography>
            </ColorBox>
            <ColorBox color="#EAF3FF">
              <Typography style={{ fontWeight: 'normal', fontSize: 14 }}>.png</Typography>
            </ColorBox>
            <ColorBox color="#E1EAF9">
              <Typography style={{ fontWeight: 'normal', fontSize: 14 }}>.gif</Typography>
            </ColorBox>
            <ColorBox color="#D1DBE9">
              <Typography style={{ fontWeight: 'normal', fontSize: 14 }}>.pdf</Typography>
            </ColorBox>
          </ColorsBoxWrapper>
          <Button variant="outlined" onClick={() => {}}>
            {t('uploadImageModal.selectFile')}
          </Button>
        </>
      ) : (
        <CircularProgress />
      )}

      <input id={'videoUpload'} {...getInputProps()} />
    </div>
  )
}

const ColorsBoxWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '1px',
  borderRadius: '15px',
  overflow: 'hidden',
  padding: '15px',
}))
const ColorBox = styled(Box)(({ color }: { color: string }) => ({
  background: color,
  color: 'black',
  padding: '3px 10px',
}))

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: '20px 30px 10px',
      padding: '10px 10px 30px',
      width: '440px',
      border: '1px dashed #05DBF2',
      borderRadius: '10px',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  })
)
export default ImageUploadComponent
