import { useTheme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import IconButton from '@mui/material/IconButton'
import { faChevronRight, faChevronLeft, faChevronsLeft, faChevronsRight } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

type TablePaginationActionsProps = {
  count: number
  onPageChange: (event: any, pageNumber: number) => void
  page: number
  rowsPerPage: number
}

export default function TablePaginationActions({
  count,
  page,
  rowsPerPage,
  onPageChange,
}: TablePaginationActionsProps) {
  const classes = useStyles()
  const theme = useTheme()

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0)
  }

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <div className={classes.root}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page" size="large">
        <FontAwesomeIcon
          icon={(theme.direction === 'rtl' ? faChevronsRight : faChevronsLeft) as IconProp}
          fontSize={22}
        />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page" size="large">
        <FontAwesomeIcon
          icon={(theme.direction === 'rtl' ? faChevronRight : faChevronLeft) as IconProp}
          fontSize={18}
        />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        size="large"
      >
        <FontAwesomeIcon
          icon={(theme.direction === 'rtl' ? faChevronLeft : faChevronRight) as IconProp}
          fontSize={18}
        />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
        size="large"
      >
        <FontAwesomeIcon
          icon={(theme.direction === 'rtl' ? faChevronsLeft : faChevronsRight) as IconProp}
          fontSize={22}
        />
      </IconButton>
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  })
)
