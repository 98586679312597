import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { useCallback, useMemo, useState } from 'react'

import { useAuth } from '@/context/Auth'
import { Typography } from '@mui/material'
import { intersection } from 'lodash'
import { useTranslation } from 'react-i18next'

import { useRecommendedModulesByName } from '@/api/education-modules/recommended-modules'
import { useCategories } from '@/api/categories/categories'
import { useDeleteEducationModule } from '@/api/education-modules/delete-module'
import { EducationModule, useEducationModules } from '@/api/education-modules/modules'
import { useToggleSavedContent } from '@/api/profile/toggle-saved-asset'
import Alert from '@/common/components/Alert/Alert'
import LaunchWizard from '@/common/components/LaunchWizard/LaunchWizard'
import LoadingContainer from '@/common/components/LoadingContainer/LoadingContainer'
import NoResultsContainer from '@/common/components/NoResultsContainer/NoResultsContainer'
import useToast from '@/common/hooks/useToast'
import CreateVideoModal from './CreateVideoModal/CreateVideoModal'
import VideoLibraryCardView from './VideoLibraryCardView'
import VideoLibraryTable from './VideoLibraryTable'
import VideoPreviewModal from './VideoPreviewModal'

type VideosLibraryProps = {
  isTableView: boolean
  queryFilters?: {
    name?: string
    languages?: string[]
    subtitles?: string[]
    tags?: string[]
  }
}

const RECOMMENDED_MODULES_PLAYLIST = 'recommended_training_playlist'

export default function VideosLibrary({ queryFilters, isTableView }: VideosLibraryProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { data: videos = [], isLoading } = useEducationModules()
  const { data: recommendedVideos = [] } = useRecommendedModulesByName(RECOMMENDED_MODULES_PLAYLIST)
  const { user: { saved_assets = [] } = {} } = useAuth()
  const { data: categoryData } = useCategories()

  const [deleteEducationModule] = useDeleteEducationModule()
  const [saveAsset] = useToggleSavedContent()
  const { errorToast, successToast } = useToast()
  const [previewModule, setPreviewModule] = useState<EducationModule | undefined>()
  const [previewModalIsOpen, setPreviewModalIsOpen] = useState(false)
  const [launchWizardIsOpen, setLaunchWizardIsOpen] = useState(false)

  const [moduleForDeletion, setModuleForDeletion] = useState<EducationModule | null>(null)
  const [deleteVideoAlertIsOpen, setDeleteVideoAlertIsOpen] = useState(false)
  const [editModule, setEditModule] = useState<EducationModule | undefined>()
  const [editModuleIsOpen, setEditModuleIsOpen] = useState(false)

  const groupedVideosByCategory: {
    [topic: string]: EducationModule[]
  } = useMemo(() => {
    const groupedVideos = {}

    if (Array.isArray(recommendedVideos) && recommendedVideos.length > 0) {
      const publicVideos = recommendedVideos.filter((video) => {
        return !video.client_id //.hasOwnProperty('client_id');
      })

      if (publicVideos.length > 0) {
        groupedVideos['Recommended Playlist'] = publicVideos
      }
    }

    if (!Array.isArray(videos)) {
      return groupedVideos
    }

    if (!categoryData) {
      groupedVideos['All'] = videos
      return groupedVideos
    }

    categoryData.categories.forEach((category) => {
      groupedVideos[category.name] = []
    })

    videos.forEach((video) => {
      for (const tag of video.tags) {
        const categories = categoryData.categories_by_tag[tag] || []
        categories.forEach(({ name }) => {
          if (!groupedVideos[name]) {
            groupedVideos[name] = []
          }
          const existingVideos = groupedVideos[name].map((video) => video._id)
          if (!existingVideos.includes(video._id)) {
            groupedVideos[name].push(video)
          }
        })
      }
    })
    return groupedVideos
  }, [categoryData, videos, recommendedVideos])

  const matchTopicFilter = useCallback(
    (topic: string) => {
      return (
        !queryFilters?.tags?.length ||
        (queryFilters?.tags?.length && queryFilters?.tags?.length > 0 && queryFilters.tags.includes(topic))
      )
    },
    [queryFilters]
  )

  const matchTagFilter = useCallback(
    (tags: string[]) => {
      return (
        !queryFilters?.tags?.length ||
        (queryFilters?.tags?.length &&
          queryFilters?.tags?.length > 0 &&
          intersection(tags, queryFilters.tags).length > 0)
      )
    },
    [queryFilters]
  )

  const matchTitleFilter = useCallback(
    (title: string, description?: string) => {
      return (
        !queryFilters?.name ||
        title.toLowerCase().includes(queryFilters?.name.toLowerCase()) ||
        (description && description.toLowerCase().includes(queryFilters?.name.toLowerCase()))
      )
    },
    [queryFilters]
  )

  const matchLanguageFilter = useCallback(
    (languages: string[], key = 'languages') => {
      return !queryFilters?.[key]?.length || intersection(languages, queryFilters?.[key]).length > 0
    },
    [queryFilters]
  )
  const filteredVideos: {
    [topic: string]: EducationModule[]
  } = useMemo(() => {
    const filteredVideoGroups = {}
    if (!isTableView) {
      Object.entries(groupedVideosByCategory).forEach(([topic, videos]) => {
        const filteredVideos = videos.filter(
          (video) =>
            matchTagFilter(video.tags) &&
            matchTitleFilter(video.title, video.description) &&
            matchLanguageFilter(video.languages) &&
            matchLanguageFilter(Object.keys(video?.subtitle_tracks || {}), 'subtitles')
        )
        if (filteredVideos.length > 0) {
          filteredVideoGroups[topic] = filteredVideos
        }
      })
    } else {
      filteredVideoGroups['all'] = videos.filter(
        (video) =>
          matchTitleFilter(video.title) &&
          matchLanguageFilter(video.languages) &&
          matchLanguageFilter(video?.subtitle_tracks ? Object.keys(video.subtitle_tracks) : [], 'subtitles') &&
          matchTagFilter(video.tags)
      )
    }

    return filteredVideoGroups
  }, [
    isTableView,
    groupedVideosByCategory,
    matchTopicFilter,
    matchTitleFilter,
    matchLanguageFilter,
    videos,
    matchTagFilter,
  ])

  function handleOpenPreviewModal(video: EducationModule) {
    setPreviewModule(video)
    setPreviewModalIsOpen(true)
  }

  function handleOpenLaunchWizard(video: EducationModule) {
    setPreviewModule(video)
    setLaunchWizardIsOpen(true)
  }

  function openDeleteVideoAlert(video: EducationModule) {
    setModuleForDeletion(video)
    setDeleteVideoAlertIsOpen(true)
  }

  function closeDeleteVideoAlert() {
    setModuleForDeletion(null)
    setDeleteVideoAlertIsOpen(false)
  }

  async function handleDeleteModule() {
    if (!moduleForDeletion) return
    try {
      await deleteEducationModule(moduleForDeletion.id)
      setPreviewModalIsOpen(false)
      successToast(t('videoLibrary.deleted'))
    } catch (error) {
      errorToast(t('videoLibrary.errors.failedToDeleteModule'))
    }
  }

  async function handleLaunchModule(module: EducationModule) {
    handleOpenLaunchWizard(module)
  }

  async function handleEditModule(module: EducationModule) {
    setEditModule(module)
    setEditModuleIsOpen(true)
    setPreviewModalIsOpen(false)
  }

  async function handleSaveModule(module: EducationModule) {
    try {
      await saveAsset(module._id)
    } catch (error) {
      errorToast(t('videoLibrary.errors.failedToSaveModule'))
    }
  }

  return (
    <div className={classes.root}>
      <VideoPreviewModal
        video={previewModule}
        open={previewModalIsOpen}
        handleClose={() => setPreviewModalIsOpen(false)}
        deleteModule={openDeleteVideoAlert}
        launchModule={handleLaunchModule}
        editModule={handleEditModule}
        saveModule={handleSaveModule}
        isSaved={previewModule ? saved_assets?.includes(previewModule.id) : false}
      />
      {previewModule && (
        <LaunchWizard open={launchWizardIsOpen} onClose={() => setLaunchWizardIsOpen(false)} assets={[previewModule]} />
      )}
      <CreateVideoModal
        hideButton
        isModalOpen={editModuleIsOpen}
        setIsModalOpen={setEditModuleIsOpen}
        video={editModule}
      />
      <Alert
        handleClose={closeDeleteVideoAlert}
        open={deleteVideoAlertIsOpen}
        onConfirm={handleDeleteModule}
        cancelButton
        buttonLabel={t('videoLibrary.deleteAlert.delete')}
        dialogTitle={t('videoLibrary.deleteAlert.title')}
        dialogText={t('videoLibrary.deleteAlert.subtitle')}
        dialogContent={
          <Typography style={{ fontWeight: 'bold' }}>
            {t('videoLibrary.deleteAlert.message', {
              value: moduleForDeletion?.title,
            })}
          </Typography>
        }
      />
      {isLoading ? (
        <LoadingContainer />
      ) : Object.values(filteredVideos).length ? (
        isTableView ? (
          <VideoLibraryTable
            openPreviewModal={handleOpenPreviewModal}
            deleteModule={openDeleteVideoAlert}
            launchModule={handleLaunchModule}
            saveModule={handleSaveModule}
            videosByTopic={filteredVideos}
            savedVideos={saved_assets}
          />
        ) : (
          <VideoLibraryCardView
            openPreviewModal={handleOpenPreviewModal}
            deleteModule={openDeleteVideoAlert}
            launchModule={handleLaunchModule}
            saveModule={handleSaveModule}
            videosByTopic={filteredVideos}
            savedVideos={saved_assets}
          />
        )
      ) : (
        <NoResultsContainer text={t('videoLibrary.noResults')} />
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: '100%',
    },
    bannerContainer: {
      marginTop: theme.spacing(2),
    },
  })
)
