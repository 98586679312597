import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  InputLabel,
  FormHelperText,
  FormControlLabel,
  CircularProgress,
  MenuItem,
  Checkbox,
  TextField,
  Link,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { useTranslation, Trans } from 'react-i18next'
import { string } from 'yup'
import { faFileArrowUp, faX, faPlus, faMinus } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import Form from '@/common/components/Form/Form'
import { EducationModule } from '@/api/education-modules/modules'
import { NewEducationModuleParams, useNewEducationModule } from '@/api/education-modules/create-module'
import { useEditEducationModule } from '@/api/education-modules/edit-module'
import { languages } from '@/assets/locales/languages'
import useToast from '@/common/hooks/useToast'
import VideoUpload from './VideoUpload'
import ThumbnailCapture from './ThumbnailCapture'
import FileUpload from '@/common/components/FileUpload/FileUpload'
import {
  generateAutoSubtitleTracksObject,
  generateUploadedSubtitleTracksObject,
} from './utils/generateSubtitleTracksObject'
import VideoTagsFilter from '../VideoTagsFilter'
import VideoPlayerV2 from '@/common/components/VideoPlayerV2/VideoPlayerV2'
import useForm, { Field } from '@/common/components/Form/useForm'

type CreateVideoModalProps = {
  buttonClass?: string
  video?: EducationModule
  isModalOpen?: boolean
  setIsModalOpen?: (isOpen: boolean) => void
  hideButton?: boolean
}

export default function CreateVideoModal({
  buttonClass,
  video,
  isModalOpen,
  setIsModalOpen,
  hideButton = false,
}: CreateVideoModalProps) {
  const { t } = useTranslation()
  const { errorToast, successToast } = useToast()
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [videoFile, setVideoFile] = useState<{
    name: string
    url: string
    duration?: number
  }>()
  const [thumbnail, setThumbnail] = useState<string>()
  const [isCopyrighted, setIsCopyrighted] = useState(false)
  const [useAutomaticSubtitles, setUseAutomaticSubtitles] = useState(true)
  const [selectedTopics, setSelectedTopics] = useState<string[]>([])
  const [numberOfSubtitleFiles, setNumberOfSubtitleFiles] = useState(1)
  const [subtitleValues, setSubtitleValues] = useState<{
    [key: string]: any
  }>({})

  useEffect(() => {
    if (video) {
      setIsCopyrighted(!!video.copyrights)
      setSelectedTopics(video.tags)
      if (video.thumbnail) {
        setThumbnail(video.thumbnail)
      }
      setNumberOfSubtitleFiles(Object.keys(video?.subtitle_tracks)?.length || 1)
      const subValues = {}
      Object.entries(video?.subtitle_tracks).forEach(([key, value], index) => {
        subValues[`subtitleLang${index}`] = key
        subValues[`subtitleUrl${index}`] = value
      })
      setSubtitleValues(subValues)
    }
  }, [video])

  const [createNewVideo, { isLoading: isCreatingVideo }] = useNewEducationModule()

  const [updateVideo, { isLoading: isUpdatingVideo }] = useEditEducationModule()

  const detailsFields: Field[] = useMemo(
    () => [
      {
        name: 'title',
        initialValue: video?.title ?? '',
        label: 'createVideoModal.title',
        validationRule: string()
          .required('createVideoModal.errors.missingTitle')
          .max(30, 'createVideoModal.errors.titleTooLong'),
        type: 'text',
        required: true,
        autoFocus: false,
        gridItemProps: {
          xs: 12,
        },
        textFieldProps: {
          variant: 'outlined',
          helperText: t('input.maxLength', { value: 30 }),
          inputProps: {
            maxLength: 30,
          },
        },
      },
      {
        name: 'description',
        initialValue: video?.description ?? '',
        label: 'createVideoModal.description',
        validationRule: string()
          .required('createVideoModal.errors.missingDescription')
          .max(600, 'createVideoModal.errors.descriptionTooLong'),
        type: 'text',
        required: true,
        autoFocus: false,
        textFieldProps: {
          variant: 'outlined',
          multiline: true,
          rows: 5,
          helperText: t('input.maxLength', { value: 600 }),
          inputProps: {
            maxLength: 600,
          },
        },
        gridItemProps: {
          xs: 12,
        },
      },
    ],
    [t, video]
  )

  const elementsFields: Field[] = useMemo(
    () => [
      {
        name: 'videoLanguage',
        initialValue: video?.video_tracks ? Object.keys(video.video_tracks)[0] : '',
        label: 'createVideoModal.videoLanguage',
        validationRule: string().required('createVideoModal.errors.missingLanguage'),
        type: 'text',
        required: true,
        autoFocus: false,
        isAutoComplete: true,
        gridItemProps: {
          xs: 12,
        },
        textFieldProps: {
          variant: 'outlined',
          select: true,
        },
        options: Object.entries(languages).map(([key, value]) => ({
          label: value.name,
          value: key,
        })),
      },
    ],
    [video]
  )

  const {
    formProps: detailsFormProps,
    handleSubmit: handleSubmitDetails,
    clearForm: clearDetailsForm,
  } = useForm({
    fields: detailsFields,
    onSubmit: (values) => values,
    clearOnSubmit: false,
  })

  const {
    formProps: elementsFormProps,
    handleSubmit: handleSubmitElements,
    clearForm: clearElementsForm,
  } = useForm({
    fields: elementsFields,
    onSubmit: (values) => values,
    clearOnSubmit: false,
  })

  function handleOpen() {
    if (setIsModalOpen) {
      setIsModalOpen(true)
    } else {
      setOpen(true)
    }
  }
  function handleClose() {
    if (setIsModalOpen) {
      setIsModalOpen(false)
    } else {
      setOpen(false)
    }
    clearDetailsForm()
    clearElementsForm()
    setSelectedTopics([])
    setVideoFile(undefined)
    setNumberOfSubtitleFiles(1)
    setSubtitleValues({})
    setUseAutomaticSubtitles(true)
  }

  function handleVideoUpload(video: { name: string; url: string; duration?: number }) {
    setVideoFile(video)
  }

  const createSubtitleTracksObject = useCallback(() => {
    if (!video && videoFile && useAutomaticSubtitles) {
      const url = videoFile.url
      return generateAutoSubtitleTracksObject(url)
    } else {
      return generateUploadedSubtitleTracksObject(subtitleValues, numberOfSubtitleFiles)
    }
  }, [video, videoFile, useAutomaticSubtitles, numberOfSubtitleFiles, subtitleValues])

  function createVideoParams(values) {
    const subtitle_tracks = createSubtitleTracksObject()
    const params: NewEducationModuleParams = {
      title: values.title,
      description: values.description,
      video_tracks: {
        [values.videoLanguage]: video ? Object.values(video?.video_tracks)[0] : videoFile?.url || '',
      },
      subtitle_tracks,
      tags: selectedTopics,
      languages: [values.videoLanguage, ...Object.keys(subtitle_tracks)],
      duration: videoFile?.duration ? videoFile.duration : document.getElementsByTagName('video')?.[0]?.duration,
      copyrights: isCopyrighted,
      thumbnail,
    }
    return params
  }

  async function handleUpdateVideo(values) {
    if (video) {
      const params = createVideoParams(values)
      try {
        updateVideo({ _id: video._id, params })
        handleClose()
        successToast(t('createVideoModal.videoUpdated'))
      } catch (e) {
        console.log(e)
        t('createVideoModal.errors.failedToSaveModule')
      }
    }
  }

  async function handleCreateVideo(values) {
    if (selectedTopics.length === 0 || !(video || videoFile))
      return errorToast(t('createVideoModal.errors.missingTopic'))

    try {
      const params = createVideoParams(values)
      await createNewVideo(params)
      handleClose()
      successToast(t('createVideoModal.videoCreated'))
    } catch (e) {
      console.log(e)
      t('createVideoModal.errors.failedToCreate')
    }
  }

  async function handleSumbit() {
    if (!isCopyrighted) return errorToast(t('createVideoModal.errors.missingCopyright'))
    const detailsFormValues = await handleSubmitDetails()
    const elementsFormValues = await handleSubmitElements()
    if (!detailsFormValues || !elementsFormValues) return
    const values = { ...detailsFormValues, ...elementsFormValues }
    if (video) {
      await handleUpdateVideo(values)
    } else {
      await handleCreateVideo(values)
    }
  }

  return (
    <>
      {hideButton ? null : (
        <IconButton onClick={handleOpen} className={buttonClass} size="large">
          <FontAwesomeIcon icon={faFileArrowUp as IconProp} fontSize={24} />
        </IconButton>
      )}
      <Dialog maxWidth={'lg'} fullWidth open={!!(open || isModalOpen)} onClose={handleClose} className={classes.dialog}>
        <DialogTitle className={classes.header}>
          <div className={classes.flex}>
            <IconButton className={classes.closeButton} onClick={handleClose} size="large">
              <FontAwesomeIcon icon={faX as IconProp} fontSize={20}></FontAwesomeIcon>
            </IconButton>
            <Typography variant="h6" color={'inherit'}>
              {video ? t('createVideoModal.editYourVideo') : t('createVideoModal.uploadYourVideo')}
            </Typography>
          </div>
        </DialogTitle>
        {videoFile || video ? (
          <>
            <DialogContent className={classes.dialogContent}>
              <div className={classes.flexContainer}>
                <Typography variant="h6">{t('createVideoModal.details')}</Typography>
                {isUpdatingVideo || isCreatingVideo ? (
                  <CircularProgress />
                ) : (
                  <Button
                    className={classes.launchButton}
                    size="small"
                    onClick={handleSumbit}
                    variant="contained"
                    color="primary"
                  >
                    {t('createVideoModal.save')}
                  </Button>
                )}
              </div>
              <Form {...detailsFormProps} />

              <Grid container spacing={8} className={classes.elementsContainer}>
                <Grid item xs={6}>
                  <Typography variant="h6" gutterBottom>
                    {t('createVideoModal.elements')}
                  </Typography>
                  <Form {...elementsFormProps} />
                  <div className={classes.autoSubtitleContainer}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={useAutomaticSubtitles}
                          onChange={() => setUseAutomaticSubtitles(!useAutomaticSubtitles)}
                          name="auto-subtitles"
                        />
                      }
                      label={t('createVideoModal.autoSubtitles')}
                    />
                  </div>
                  {useAutomaticSubtitles
                    ? null
                    : [...Array(numberOfSubtitleFiles)].map((_, i) => {
                        return (
                          <Grid container spacing={3} className={classes.subtitleGrid}>
                            <Grid item xs={6}>
                              <TextField
                                label={t('createVideoModal.subtitleLanguage')}
                                variant={'outlined'}
                                margin={'none'}
                                fullWidth
                                select
                                onChange={(e) =>
                                  setSubtitleValues((prevState) => ({
                                    ...prevState,
                                    [`subtitleLang${i}`]: e.target.value,
                                  }))
                                }
                                value={subtitleValues[`subtitleLang${i}`] || ''}
                              >
                                {Object.entries(languages)
                                  .map(([key, value]) => ({
                                    label: value.name,
                                    value: key,
                                  }))
                                  .map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                      className={classes.multiSelectOption}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                              </TextField>
                            </Grid>
                            <Grid item xs={6}>
                              <FileUpload
                                label={t('createVideoModal.subtitleFile')}
                                onUpload={(url) =>
                                  setSubtitleValues((prevState) => ({
                                    ...prevState,
                                    [`subtitleUrl${i}`]: url,
                                  }))
                                }
                                textFieldProps={{
                                  variant: 'outlined',
                                  fullWidth: true,
                                }}
                                initialValue={subtitleValues[`subtitleUrl${i}`] || ''}
                              />
                            </Grid>
                          </Grid>
                        )
                      })}
                  {useAutomaticSubtitles ? null : (
                    <div className={classes.flex}>
                      <Button
                        onClick={() => setNumberOfSubtitleFiles(numberOfSubtitleFiles + 1)}
                        variant={'text'}
                        startIcon={<FontAwesomeIcon icon={faPlus as IconProp} />}
                      >
                        {t('createVideoModal.addSubtitle')}
                      </Button>
                      {numberOfSubtitleFiles > 1 ? (
                        <Button
                          onClick={() => setNumberOfSubtitleFiles(numberOfSubtitleFiles - 1)}
                          variant={'text'}
                          startIcon={<FontAwesomeIcon icon={faMinus as IconProp} />}
                        >
                          {t('createVideoModal.deleteSubtitle')}
                        </Button>
                      ) : null}
                    </div>
                  )}

                  <div className={classes.tagsFilter}>
                    <InputLabel required shrink>
                      {t('createVideoModal.topics')}
                    </InputLabel>
                    <VideoTagsFilter
                      selectedTags={selectedTopics}
                      onTagClick={(tag) =>
                        selectedTopics.includes(tag)
                          ? setSelectedTopics(selectedTopics.filter((t) => t !== tag))
                          : setSelectedTopics([...selectedTopics, tag])
                      }
                    />
                    {selectedTopics?.length ? null : (
                      <FormHelperText error>{t('createVideoModal.errors.missingTopic')}</FormHelperText>
                    )}
                  </div>
                  <FormControlLabel
                    labelPlacement="end"
                    control={
                      <Checkbox
                        checked={isCopyrighted}
                        onChange={() => setIsCopyrighted(!isCopyrighted)}
                        name="copyrights"
                      />
                    }
                    label={
                      <Trans i18nKey={'createVideoModal.copyrightsReserved'}>
                        text
                        <Link
                          rel="noopener"
                          className={classes.link}
                          href={'https://cywareness.io/terms-of-use/'}
                          target="_blank"
                        >
                          text
                        </Link>
                        text
                        <Link
                          rel="noopener"
                          href={'https://cywareness.io/privacy-policy/'}
                          target="_blank"
                          className={classes.link}
                        >
                          text
                        </Link>
                      </Trans>
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <div>
                    <VideoPlayerV2
                      id="upload-preview"
                      videoTracks={video?.video_tracks || { en: videoFile?.url || '' }}
                      subtitleTracks={video?.subtitle_tracks || {}}
                    />
                    <Typography variant="body2" color="textSecondary">
                      {t('createVideoModal.fileName')}: {videoFile?.name}
                    </Typography>
                  </div>
                  <div className={classes.thumbnailContainer}>
                    <ThumbnailCapture
                      onCapture={setThumbnail}
                      height={300}
                      videoElementId={'upload-preview'}
                      initialThumbnail={video?.thumbnail}
                    />
                  </div>
                </Grid>
              </Grid>
            </DialogContent>
          </>
        ) : (
          <VideoUpload onUpload={handleVideoUpload} />
        )}
      </Dialog>
    </>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    dialog: {
      '& .MuiDialog-paper': {
        border: 'none',
      },
    },
    header: {
      backgroundColor: theme.palette.blueGray[900],
      color: theme.palette.cyan[500],
      padding: theme.spacing(1, 3),
    },
    dialogContent: {
      overflowX: 'hidden',
    },
    flexContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
    },
    launchButton: {
      minWidth: 100,
    },
    closeButton: {
      marginLeft: -theme.spacing(2),
      color: theme.palette.cyan[500],
    },
    elementsContainer: {
      marginTop: theme.spacing(0),
    },
    tagsFilter: {
      margin: theme.spacing(2, 0),
    },
    thumbnailContainer: {
      paddingTop: theme.spacing(2),
    },
    autoSubtitleContainer: {
      margin: theme.spacing(1, 0, 2),
    },
    multiSelectOption: {
      '&hover': {
        backgroundColor: 'rgba(0,0,0,0.1)',
      },
    },
    link: {
      color: theme.palette.cyan[500],
      cursor: 'pointer',
    },
    subtitleGrid: {
      marginBottom: theme.spacing(2),
    },
  })
)
