import { Box, Button, TextField, Typography, styled } from '@mui/material'

export const Title = styled(Typography)(() => ({
  fontFamily: 'inherit',
  paddingBottom: '15px',
  fontWeight: 600,
  fontSize: '18px',
}))

export const SubTitle = styled(Title)(() => ({
  paddingTop: '14px',
  fontWeight: 400,
  b: {
    fontWeight: 600,
  },
}))

export const FieldWrapper = styled(Box)(() => ({
  height: '55px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
}))
export const StyledTextField = styled(TextField)(() => ({
  height: '55px',
  width: '100%',
}))
