import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useQueryCache } from 'react-query'

import { API_URL } from '@/api/constants'

//TODO Move this to types when refactoring the profile
export type Profile = {
  full_name: string
  email: string
  current_password?: string
  phone_number: string
  change_email_token?: string
}

const editProfile = async (profilePayload: Partial<Profile>) => {
  const { data } = await axios.put(`${API_URL}/profile/`, profilePayload, {
    withCredentials: true,
  })

  return data
}

export const useEditProfile = () => {
  const queryClient = useQueryClient()
  const queryCache = useQueryCache()

  return useMutation({
    mutationFn: editProfile,
    onSuccess: async () => {
      // ReactQuery V2 support
      queryCache.invalidateQueries(['user'])
      queryClient.invalidateQueries({
        queryKey: ['admins'],
      })
    },
  })
}
