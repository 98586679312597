import allCoverImg from '@/assets/images/simulation-packages/all-cover.svg'
import emailCoverImg from '@/assets/images/simulation-packages/email-cover.svg'
import smsCoverImg from '@/assets/images/simulation-packages/sms-cover.svg'
import whatsappCoverImg from '@/assets/images/simulation-packages/whatsapp-cover.svg'
import emailSmsCoverImg from '@/assets/images/simulation-packages/email-sms-cover.svg'
import smsWhatsappCoverImg from '@/assets/images/simulation-packages/sms-whatsapp-cover.svg'
import emailWhatsappCoverImg from '@/assets/images/simulation-packages/email-whatsapp-cover.svg'
import { PhishingSimulationVector } from '@/types/phishingSimulations'

export const getPackageCover = (simulationVectors: PhishingSimulationVector[] | undefined) => {
  if (!simulationVectors) return allCoverImg
  const cleanVectorsSring = [...new Set(simulationVectors.sort())].join(',')
  switch (cleanVectorsSring) {
    case 'email':
      return emailCoverImg
    case 'sms':
      return smsCoverImg
    case 'whatsapp':
      return whatsappCoverImg
    case 'email,sms':
      return emailSmsCoverImg
    case 'sms,whatsapp':
      return smsWhatsappCoverImg
    case 'email,whatsapp':
      return emailWhatsappCoverImg
    default:
      return allCoverImg
  }
}
