import React, { useMemo } from "react";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { EducationModule } from "../../../api/education-modules/modules";
import Carousel from "../../../common/components/Carousel/Carousel";
import VideoCard from "./VideoCard";
import RenderIfVisible from "react-render-if-visible";
import { useAuth } from "../../../context/Auth";

type VideoLibraryCardViewProps = {
  videosByTopic: {
    [topic: string]: EducationModule[];
  };
  openPreviewModal: (video: EducationModule) => void;
  deleteModule: (video: EducationModule) => void;
  launchModule: (video: EducationModule) => void;
  saveModule: (video: EducationModule) => void;
  savedVideos: string[];
};

export default function VideoLibraryCardView({
  videosByTopic,
  openPreviewModal,
  deleteModule,
  launchModule,
  saveModule,
  savedVideos = [],
}: VideoLibraryCardViewProps) {
  const classes = useStyles();

  const [hoveredVideo, setHoveredVideo] = React.useState<{
    topic: string;
    index: number;
  } | null>(null);

  const topicsList = useMemo(
    () => Object.entries(videosByTopic),
    [videosByTopic]
  );

  const { user } = useAuth();
  const schoolUser = "650829ae0e241a85df7a0ba5";

  const shouldShowCarousel = (videos) => {
    const videosToDisplay = videos.filter((video) => {
      if (user?.current_client_id !== schoolUser) {
        return true;
      }
      return video.client_id === schoolUser;
    });

    return videosToDisplay.length > 0;
  };

  return (
    <div className={classes.root}>
      {topicsList.map(([topic, videos]) => {
        return (
          <RenderIfVisible defaultHeight={300} stayRendered key={topic}>
            <div className={classes.section}>
              {shouldShowCarousel(videos) && (
                <Carousel title={topic}>
                  {videos.map((video, index) => {
                    return (
                      <div>
                        <VideoCard
                          key={video._id}
                          video={video}
                          openPreviewModal={openPreviewModal}
                          deleteModule={deleteModule}
                          launchModule={launchModule}
                          saveModule={saveModule}
                          onMouseLeave={() => {
                            setHoveredVideo(null);
                          }}
                          onMouseEnter={() => {
                            setHoveredVideo({ topic, index });
                          }}
                          isSaved={savedVideos?.includes(video._id)}
                          hoverState={
                            topic === hoveredVideo?.topic
                              ? index === hoveredVideo?.index
                                ? 0
                                : hoveredVideo?.index > index
                                ? 1
                                : -1
                              : null
                          }
                        />
                      </div>
                    );
                  })}
                </Carousel>
              )}
            </div>
          </RenderIfVisible>
        );
      })}
    </div>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(4),
    },
    section: {
      marginBottom: theme.spacing(2),
    },
  })
);
