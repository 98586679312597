import React, { useEffect } from 'react'
import { Box, Slider, Typography, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  faFaceAnxiousSweat,
  faFaceSmileWink,
  faFaceSpiralEyes,
  faFaceTongueSweat,
  faGrimace,
} from '@fortawesome/pro-light-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { theme } from '@/theme/theme'
import { useAiSimulationContext } from '../state'
import { MICRO_TRAINING } from '../constants/micro-training'
interface DifficultyDropdownProps {
  defaultDifficulty?: number
  onDifficultyChange?: (value: number) => void
}
export interface Findings {
  title: string
  description: string
  command: string
}

const getRandomItem = (arr: Findings[]): Findings => {
  const randomIndex = Math.floor(Math.random() * arr.length)
  return arr[randomIndex]
}

const getItemsForDifficulty = (difficulty: number): Findings[] => {
  const results: Findings[] = []

  for (let i = difficulty; i < 5; i++) {
    if (MICRO_TRAINING[i] && MICRO_TRAINING[i].length) {
      const item = getRandomItem(MICRO_TRAINING[i])
      results.push(item)
    }
  }

  return results
}
const generateFindingsForSelectedItems = (selectedItems: Findings[]): string => {
  let findings = ''
  let findingCounter = 1

  for (const item of selectedItems) {
    if (item.command) {
      findings += `\n    - ${item.command}. Critical! Add it to the email body with <span id=finding${findingCounter}></span> around it`
      findingCounter++
    }
  }

  return findings
}

const DifficultyLevelSelector: React.FC<DifficultyDropdownProps> = () => {
  const { t } = useTranslation()
  const { difficultyLevel, setDifficultyLevel, setFindings, setFindingsInstructions } = useAiSimulationContext()

  useEffect(() => {
    // items to add to the findings object saved in DB
    const selectedItems = getItemsForDifficulty(difficultyLevel)
    setFindings(selectedItems)

    // the text to add to the prompt
    const findings = generateFindingsForSelectedItems(selectedItems)
    setFindingsInstructions(findings)
  }, [difficultyLevel])

  const handleChange = (event: any) => {
    const selectedDifficulty = event.target.value as number
    setDifficultyLevel(selectedDifficulty)
  }

  return (
    <Box style={{ paddingTop: '12px' }}>
      <Typography id="difficulty-select-label" sx={{ marginRight: '15px' }}>
        {t('simulationLibrary.ai.phishingIndicators')}
      </Typography>
      <StyledSlider
        level={difficultyLevel}
        onChange={handleChange}
        size="small"
        value={difficultyLevel}
        min={1}
        max={5}
        step={1}
        style={{ width: '100%', height: '8px' }}
        marks={[
          { value: 1, label: '5' },
          { value: 2, label: '4' },
          { value: 3, label: '3' },
          { value: 4, label: '2' },
          { value: 5, label: '1' },
        ]}
      />
      <StyledDifficultBox>
        {t('simulationLibrary.ai.difficulty') + ': ' + difficultyLevel}
        {[faFaceSmileWink, faGrimace, faFaceAnxiousSweat, faFaceTongueSweat, faFaceSpiralEyes].map((faIcon, index) => {
          index++
          return (
            <FontAwesomeIcon
              icon={faIcon as IconProp}
              fontSize={30}
              style={{ opacity: difficultyLevel === index ? 1 : 0.2 }}
              onClick={() => setDifficultyLevel(index)}
            />
          )
        })}
      </StyledDifficultBox>
    </Box>
  )
}

const StyledDifficultBox = styled(Box)(() => ({
  background: theme.palette.blueGray[50],
  padding: theme.spacing(2, 0.5),
  borderRadius: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
}))
const StyledSlider = styled(Slider)(({ level }: { level: number }) => ({
  marginRight: theme.spacing(0.5),
  '& .MuiSlider-rail': {
    paddingRight: theme.spacing(0.5),
    opacity: 0.5,
    backgroundColor: theme.palette.blueGray[100],
  },
  '& .MuiSlider-track': {
    backgroundImage: `linear-gradient(90deg,${
      theme.palette.blueDianne[600] +
      ',' +
      (level > 1 ? theme.palette.cyan[900] : '') +
      (level > 2 ? ',' + theme.palette.lime[900] : '') +
      (level > 3 ? ',' + theme.palette.red[700] : '') +
      (level > 4 ? ',' + theme.palette.red[900] : '')
    })`,
  },
  '& .MuiSlider-mark': {
    color: theme.palette.blueGray[200],
  },
  '& .MuiSlider-markLabel': {
    fontSize: 12,
  },
  '& .MuiSlider-thumb': {
    height: 20,
    width: 20,
    backgroundColor: theme.palette.cyan[500],
    border: `1px solid ${theme.palette.grey[500]}`,
  },
}))
export default DifficultyLevelSelector
