import axios from 'axios'
import { API_URL } from '@/api/constants'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { PhishingSimulation, PhishingSimulationRequest } from '@/types/phishingSimulations'

const phishingSimulationCreate = async (phishingSimulationPayload: PhishingSimulationRequest) => {
  const { data } = await axios.post(`${API_URL}/phishing-simulations/`, phishingSimulationPayload, {
    withCredentials: true,
  })

  return data
}

export const usePhishingSimulationCreate = (
  successHandler: (responseData: PhishingSimulation) => void,
  errorHandler: (error) => void
) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: phishingSimulationCreate,
    onSuccess: async (responseData) => {
      await queryClient.invalidateQueries({
        queryKey: ['phishing-simulations'],
      })
      successHandler && successHandler(responseData)
    },
    onError: errorHandler,
  })
}
