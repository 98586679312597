import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import DomainsTable from '../../../common/components/DomainsTable/DomainsTable'

export default function Domains() {
  const { t } = useTranslation()

  return (
    <>
      <Typography gutterBottom variant={'h6'}>
        {t('settings.whitelisting.domains')}
      </Typography>
      <Typography variant={'body1'}>{t('settings.whitelisting.domainInstructions')}</Typography>
      <DomainsTable />
    </>
  )
}
