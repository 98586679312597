import axios from 'axios'
import { API_URL } from '../constants'
import { useQuery } from 'react-query'
import { ServerError } from '../types'
import { serializeArray } from '../utils/serialize-array'

export type OfficeWorkingHours = {
  opening: {
    h: number
    m: number
  }
  closing: {
    h: number
    m: number
  }
  days: {
    sun: boolean
    mon: boolean
    tue: boolean
    wed: boolean
    thu: boolean
    fri: boolean
    sat: boolean
  }
}

export type Office = {
  _id: string
  name: string
  geo_location: string
  state?: string
  time_zone: string
  working_hours: OfficeWorkingHours
  language: string
  groups: any[]
  client_id: string
  awareness_score: number
  awareness_last_30_days: number[]
  user_count?: number
  users: string[]
  has_unverified_domain: boolean
  has_inactive_member: boolean
  disabled?: boolean
  campaign_stats?: {
    [key: string]: {
      passed: number
      failed: number
    }
  }
  has_member_with_missing_phone?: boolean
}

export const getAllOffices = async () => {
  const url = `${API_URL}/offices/all`
  const { data } = await axios.get(url, { withCredentials: true })
  return serializeArray<Office>(data.data ?? [])
}

type OfficesCache = {
  array: Office[]
  byId: { [id: string]: Office }
}

export const useOffices = () => {
  return useQuery<OfficesCache, ServerError>('offices', getAllOffices)
}
