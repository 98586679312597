import endent from "endent";

const createPrompt = (
  emailBody: string,
  toneOfVoice: string,
  language: string
): string => {
  return endent`
    Act as a content writer.  
    Compose a short SMS message regarding ${emailBody}. 
    Tone of voice should be ${toneOfVoice}, but don't mention it in the SMS.
    Write the message in ${language} language.
    Return SMS content only with no other comments, (no backticks, DO NOT wrap in quotations).
    Remember: SMS messages should be concise and clear.
`.trim();
};

export const createSMSSubjectPrompt = (
  language: string,
  toneOfVoice: string,
  emailBody: string
) => {
  return createPrompt(emailBody, toneOfVoice, language);
};
