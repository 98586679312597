import React from 'react'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import Domains from './Domains'
import useToast from '../../../common/hooks/useToast'
import { useWhitelist } from '../../../api/client/whitelist-request'
import { useVerifyWhitelist } from '../../../api/client/whitelist-verify'
import { useTranslation } from 'react-i18next'
import WhitelistingInstructions from './WhitelistingInstructions'
import { Typography } from '@mui/material'

export default function WhitelistingSettings(props) {
  const classes = useStyles()
  const { successToast, errorToast } = useToast()
  const { t } = useTranslation()
  const [sendWhitelistEmail, { isLoading: isSendingEmail }] = useWhitelist()
  const [verifyWhitelistToken, { isLoading: isVerifyingToken }] = useVerifyWhitelist()

  async function handleVerifyToken(token) {
    try {
      await verifyWhitelistToken(token)
      successToast(t('simulatorOnboarding.whitelisting.verified'))
    } catch (e) {
      errorToast(t('simulatorOnboarding.whitelisting.errors.badToken'))
    }
  }

  async function handleSendEmail() {
    try {
      await sendWhitelistEmail()
      successToast(t('simulatorOnboarding.whitelisting.testSent'))
    } catch (e) {
      console.log(e)
      errorToast(t('simulatorOnboarding.whitelisting.errors.failedToSendEmail'))
    }
  }
  return (
    <div className={classes.root}>
      <Domains />
      <div className={classes.whitelistingContainer}>
        <Typography gutterBottom variant={'h6'}>
          {t('settings.whitelisting.whitelisting')}
        </Typography>
        <WhitelistingInstructions
          handleSendEmail={handleSendEmail}
          handleVerifyToken={handleVerifyToken}
          isSendingEmail={isSendingEmail}
          isVerifyingToken={isVerifyingToken}
        />
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    whitelistingContainer: {
      marginTop: theme.spacing(4),
    },
  })
)
