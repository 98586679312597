import axios from "axios";
import { API_URL } from "../constants";
import { useQuery } from "react-query";
import { ServerError } from "../types";
import { EducationModule } from "./modules";

export async function getRecommendedModules(
  key: string,
  name: string
): Promise<EducationModule[]> {
  if (name) {
    const url = `${API_URL}/v1/training-videos/playlist/${name}/videos`;
    const { data = [] } = await axios.get(url, { withCredentials: true });
    return data;
  } else {
    return [];
  }
}

export function useRecommendedModulesByName(name: string) {
  return useQuery<EducationModule[], ServerError>(
    ["recommended", name],
    getRecommendedModules,
    {
      refetchOnWindowFocus: false,
    }
  );
}
