import axios from 'axios'
import { API_URL } from '@/api/constants'
import { useMutation } from '@tanstack/react-query'

export type VerifyTokenResponse = {
  email: string
  type: string
  verified: boolean
}

const verifyToken = async (token: null | string): Promise<VerifyTokenResponse> => {
  const { data } = await axios.post(`${API_URL}/auth/verify-token/`, { token })

  return data
}

export const useVerifyToken = () => {
  return useMutation({ mutationFn: verifyToken })
}
