import { theme } from '@/theme/theme'
import { styled } from '@mui/material'
import MuiStepConnector, { stepConnectorClasses } from '@mui/material/StepConnector'

export const StepConnector = styled(MuiStepConnector)(() => ({
  [`&.${stepConnectorClasses.root}`]: {
    position: 'absolute',
    height: '100%',
    width: '40px',
    top: '25px',
    margin: 0,
  },
  [`& .${stepConnectorClasses.line}`]: {
    position: 'absolute',
    left: 'calc(50% - 2px)',
    height: '100%',
    width: 3,
    border: 0,
    backgroundColor: theme.palette.black,
    borderRadius: 1,
  },
}))
