import axios from 'axios'
import { API_URL } from '../constants'
import { useMutation, useQueryClient } from '@tanstack/react-query'

const deleteDomain = async (domainId: string) => {
  const url = `${API_URL}/domains/${domainId}/`
  const { data } = await axios.delete(url, { withCredentials: true })
  return data
}

export const useDeleteDomain = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: deleteDomain,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['domains'],
      })
      await queryClient.invalidateQueries({
        queryKey: ['members'],
      })
    },
  })
}
