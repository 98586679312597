import {
  EmailAction,
  RESET_EMAIL_STATE,
  SET_APP_NAME,
  SET_BODY_OUTPUT,
  SET_CUSTOM_LOGO,
  SET_DESIGNED_EMAIL,
  SET_DIFFICULTY_LEVEL,
  SET_EMAIL_BODY,
  SET_EMAIL_LANGUAGE,
  SET_EMAIL_SENDER_SIGNATURE,
  SET_EMAIL_SUBJECT,
  SET_FINDINGS,
  SET_FINDINGS_INSTRUCTIONS,
  SET_INSTRUCTIONS,
  SET_SENDER_NAME,
  SET_SENDER_POSITION,
  SET_SUBJECT_OUTPUT,
  SET_TONE_OF_VOICE,
  SET_ICON_THEME,
  SET_BUTTON_COLOR,
  SET_APP_LANDING_TEMPLATE,
  SET_BUTTON_TEXT_COLOR,
  SET_PERSOANLIZED_TEMPLATE,
} from "./Types";
import { Findings } from "../../components/DifficultyLevelSelector";

export const setLanguage = (payload: string): EmailAction => ({
  type: SET_EMAIL_LANGUAGE,
  payload,
});

export const setEmailSubject = (payload: string): EmailAction => ({
  type: SET_EMAIL_SUBJECT,
  payload,
});

export const setEmailBody = (payload: string): EmailAction => ({
  type: SET_EMAIL_BODY,
  payload,
});

export const setSenderName = (payload: string): EmailAction => ({
  type: SET_SENDER_NAME,
  payload,
});

export const setToneOfVoice = (payload: string): EmailAction => ({
  type: SET_TONE_OF_VOICE,
  payload,
});

export const setSenderPosition = (payload: string): EmailAction => ({
  type: SET_SENDER_POSITION,
  payload,
});

export const setInstructions = (payload: string): EmailAction => ({
  type: SET_INSTRUCTIONS,
  payload,
});

export const setSubjectOutput = (payload: string): EmailAction => ({
  type: SET_SUBJECT_OUTPUT,
  payload,
});

export const setBodyOutput = (payload: string): EmailAction => ({
  type: SET_BODY_OUTPUT,
  payload,
});

export const setDesignedEmail = (payload: boolean): EmailAction => ({
  type: SET_DESIGNED_EMAIL,
  payload,
});

export const setIconTheme = (payload: string): EmailAction => ({
  type: SET_ICON_THEME,
  payload,
});

export const setButtonColor = (payload: string): EmailAction => ({
  type: SET_BUTTON_COLOR,
  payload,
});

export const setButtonTextColor = (payload: string): EmailAction => ({
  type: SET_BUTTON_TEXT_COLOR,
  payload,
});

export const setAppLandingTemplate = (payload: string): EmailAction => ({
  type: SET_APP_LANDING_TEMPLATE,
  payload,
});

export const setAppName = (payload: string): EmailAction => ({
  type: SET_APP_NAME,
  payload,
});

export const setEmailSenderSignature = (payload: string): EmailAction => ({
  type: SET_EMAIL_SENDER_SIGNATURE,
  payload,
});

export const setCustomLogo = (payload: string): EmailAction => ({
  type: SET_CUSTOM_LOGO,
  payload,
});

export const setDifficultyLevel = (payload: number): EmailAction => ({
  type: SET_DIFFICULTY_LEVEL,
  payload,
});

export const setFindings = (payload: Findings[]): EmailAction => ({
  type: SET_FINDINGS,
  payload,
});
export const setFindingsInstructions = (payload: string): EmailAction => ({
  type: SET_FINDINGS_INSTRUCTIONS,
  payload,
});

export const resetEmail = (): EmailAction => ({
  type: RESET_EMAIL_STATE,
});

export const setPersonalizedTemplate = (payload: boolean): EmailAction => ({
  type: SET_PERSOANLIZED_TEMPLATE,
  payload,
});
