import React from 'react';
export const gmailText = () => (
  <div>
    <p>
      <span>
        The Gsuite Cywareness report plugin is verified by Google and is listed privately on the Gsuite workspace
        marketplace.&nbsp;
      </span>
    </p>
    <p>
      <span>&nbsp;</span>
    </p>
    <p>
      <span>Steps to deploy the Gsuite Cywareness report plugin:</span>
    </p>
    <ol>
      <li>
        <span>While logged in to admin.google.com as an administrator in your Gsuite environment, go over to: </span>
        <a href="https://workspace.google.com/marketplace/app/cywareness_report/60229565947">
          <span>https://workspace.google.com/marketplace/app/cywareness_report/60229565947</span>
        </a>
      </li>
      <li>
        <span>Click on &ldquo;Domain install&rdquo;</span>
      </li>
      <li>
        <span>Click on &ldquo;Continue&rdquo;</span>
      </li>
      <li>
        <span>Check the &ldquo;I agree&rdquo; checkbox and click on &ldquo;Continue&rdquo;</span>
      </li>
      <li>
        <span>Click on &ldquo;Allow&rdquo;</span>
      </li>
    </ol>
    <p>
      <span>The plugin now starts the deploying process and should be deployed in the next 24 hours.</span>
    </p>
    <p>
      <br />
      <br />
      <br />
    </p>
  </div>
);
