import React, { ReactElement, FC } from "react";
import { Box, Step, StepLabel, Stepper } from "@mui/material";
import CustomBreadcrumbs from "../../../common/components/Breadcrumbs/Breadcrumbs";
import { useAiSimulationContext } from "./state";
import CustomParticles from "../../../common/components/Particles/CustomParticles";
import "@fortawesome/fontawesome-pro/css/all.css";

interface AiWizardProps {
  children: ReactElement[];
}

export const AiWizard: FC<AiWizardProps> = ({ children }) => {
  const { currentStep, setCurrentStep } = useAiSimulationContext();

  const steps = [
    { name: "Template", icon: "fa-face-shush" },
    { name: "Landing Page", icon: "fa-paper-plane" },
    { name: "Summary", icon: "fa-clipboard-list-check" },
  ];

  // @ts-ignore
  return (
    <>
      <Box
        sx={{
          zIndex: -1,
          position: "absolute",
        }}
      >
        <CustomParticles />
      </Box>

      <Box
        paddingX={2.25}
        display="flex"
        flexDirection="column"
        margin="auto"
        maxWidth="1440px"
      >
        <CustomBreadcrumbs
          currentStep={currentStep}
          steps={steps}
          setCurrentStep={setCurrentStep}
        />
        <Box
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            height: "84px",
            marginBottom: 5.5,
          }}
        >
          <Box
            sx={{
              width: "60%",
              m: "0 auto",
              my: 2,
              height: "100px",
            }}
          >
            <Stepper
              activeStep={currentStep}
              alternativeLabel
              sx={{
                ".MuiStepConnector-line": {
                  marginTop: 4.2,
                  borderWidth: "2px",
                  borderColor: "currentColor",
                },
              }}
            >
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepLabel
                    StepIconComponent={() => (
                      <div style={{ textAlign: "center", display: "block" }}>
                        <div style={{ marginBottom: "4px" }}>{step.name}</div>
                        <div
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                            justifyContent: "center",
                            border: "2px solid currentColor",
                            borderRadius: "50%",
                            width: "36px",
                            height: "36px",
                            backgroundColor:
                              index <= currentStep ? "black" : "transparent",
                          }}
                        >
                          <i
                            className={`fa-light ${step.icon}`}
                            style={{
                              color:
                                index <= currentStep
                                  ? "#05DBF2"
                                  : "currentColor",
                            }}
                          />
                        </div>
                      </div>
                    )}
                  />
                </Step>
              ))}
            </Stepper>
          </Box>
        </Box>
        <Box display="flex" justifyContent="center" width="100%">
          {children[currentStep]}
        </Box>
      </Box>
    </>
  );
};
