import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faAtom, faInfoCircle, faTriangleExclamation } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Checkbox, Link, Paper, Tooltip, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { filter } from 'lodash'
import { FC, useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { ColumnInterface } from 'react-table'
import { NavLink } from 'react-router-dom'
import { useDynamicGroups } from '@/api/dynamic-groups/get'
import { EducationModule } from '@/api/education-modules/modules'
import { TABLE_SIZE_REFERENCE_KEYS, getDefaultTableSize, setDefaultTableSize } from '@/common/utils/table-size'
import { theme } from '@/theme/theme'
import { Targets } from '@/types/campaigns'
import { DynamicGroup } from '@/types/dynamicGroups'
import { PhishingSimulation } from '@/types/phishingSimulations'
import HumanizedScore from '../HumanizedScore/HumanizedScore'
import LoadingContainer from '../LoadingContainer/LoadingContainer'
import SimpleSearchBar from '../SimpleSearchBar/SimpleSearchBar'
import NoResults from '../Tables/NoResults'
import SimpleTable from '../Tables/SimpleTable'
import DistributionSelect from './DistibutionSelect'
import { RecepientsType } from './UserPicker'
import { styled } from '@mui/system'
import { byPrefixAndName } from '@awesome.me/kit-39de79544e/icons'

type LaunchWizardGroupsTableProps = {
  handleAddDynamicGroups: (groupIds: string[], groups: DynamicGroup[]) => void
  handleRemoveFromList: (id: string | string[]) => void
  handleDistributionTargetsChange: (type: RecepientsType, itemId: string, value: string) => void
  distributionTargets: Targets
  assets?: PhishingSimulation[] | EducationModule[]
  clientEnforceDomain: boolean
  selectedDynamicGroups: string[]
}

const LaunchWizardDynamicGroupsTable: FC<LaunchWizardGroupsTableProps> = ({
  handleAddDynamicGroups,
  handleRemoveFromList,
  handleDistributionTargetsChange,
  distributionTargets,
  assets,
  clientEnforceDomain,
  selectedDynamicGroups,
}) => {
  const { t } = useTranslation()
  const [queryFilters, setQueryFilters] = useState({ name: '' })
  const { data: dynamicGroupsData = [], isLoading } = useDynamicGroups({ params: { check_unverified_domain: true } })
  const classes = useStyles()
  const defaultRowsPerPage = getDefaultTableSize(TABLE_SIZE_REFERENCE_KEYS.WIZARD_TABLE)
  const handleChangeRowsPerPage = (pageSize: number) => {
    setDefaultTableSize(TABLE_SIZE_REFERENCE_KEYS.WIZARD_TABLE, pageSize)
  }
  const isPackage = assets && assets.length > 1
  const onlyAssetsWithPhone = assets?.every((a) =>
    a.vectors?.every((vector) => vector === 'sms' || vector === 'whatsapp')
  )
  const handleFilterChange = useCallback((value: string) => {
    setQueryFilters((prevState) => ({ ...prevState, name: value }))
  }, [])

  const filteredGroups = useMemo(() => {
    return filter(dynamicGroupsData, (group: DynamicGroup) => {
      return queryFilters.name ? group.name.toLowerCase().includes(queryFilters.name.toLowerCase()) : true
    })
  }, [dynamicGroupsData, queryFilters])

  const columns: ColumnInterface[] = useMemo(() => {
    const columns = [
      {
        accessor: 'selection',
        Cell: ({ row }) => {
          const [checked, setChecked] = useState(selectedDynamicGroups.includes(row.original.id))
          function handleChange(e) {
            setChecked((previousState) => !previousState)
            if (e.target.checked) {
              handleAddDynamicGroups([row.original.id], [row.original])
            } else {
              handleRemoveFromList(row.original.id)
            }
          }
          return <Checkbox checked={checked} onChange={handleChange} />
        },
      },
      {
        Header: t('dynamicGroups.groupType'),
        accessor: 'name',
        Cell: ({ row }) => {
          const hasEmailSimulation = assets && assets.some((asset) => asset?.vectors?.includes('email'))
          const hasWarning = hasEmailSimulation && row.original.has_unverified_domain

          return (
            <Box display="flex" alignItems="center" gap={1}>
              <FontAwesomeIcon icon={faAtom as IconProp} color={theme.palette.purple[500]} fontSize={18} />
              {row.original.name}
              <Tooltip title={row.original.description} placement="top">
                <FontAwesomeIcon icon={faInfoCircle as IconProp} />
              </Tooltip>

              {hasWarning && (
                <Tooltip
                  title={
                    <Typography className={classes.tooltipText}>
                      <Trans
                        i18nKey={'launchWizard.dynamicGroupsTable.tooltips.unverifiedDomains.description'}
                        components={{
                          1: <Link href={'../../settings/allowlisting'} target="_blank" className={classes.tooltip} />,
                        }}
                      />
                    </Typography>
                  }
                  placement={'right'}
                  disableHoverListener={!hasWarning}>
                  <StyledFontAwesome icon={faTriangleExclamation as IconProp} />
                </Tooltip>
              )}
              {!row.original.has_member_with_missing_phone && onlyAssetsWithPhone ? (
                <Tooltip
                  title={
                    <Trans
                      i18nKey="launchWizard.dynamicGroupsTable.tooltips.invalidPhoneNumber"
                      components={{ 1: <NavLink to={`/recipients/members`} target="_blank" /> }}
                    />
                  }
                  placement="top">
                  <StyledFontAwesome icon={byPrefixAndName.fak['light-mobile-circle-xmark'] as IconProp} />
                </Tooltip>
              ) : null}
            </Box>
          )
        },
      },
      {
        Header: t('dynamicGroups.totalMembers'),
        accessor: 'count',
        Cell: ({ value }: { value: number }) => <Box marginLeft={theme.spacing(9)}>{value}</Box>,
      },
      {
        Header: <span style={{ marginRight: theme.spacing(3) }}>{t('dynamicGroups.score')}</span>,
        accessor: 'awareness_score',
        Cell: ({ value }: { value: number }) => (
          <Box marginRight={theme.spacing(3)}>
            <HumanizedScore score={value} />
          </Box>
        ),
      },
    ]

    if (isPackage) {
      columns.splice(2, 0, {
        Header: t('dynamicGroups.distribution'),
        accessor: 'distribution',
        Cell: ({ row }) => (
          <DistributionSelect
            type="dynamic_groups"
            onChange={handleDistributionTargetsChange}
            itemId={row.original.id}
            selectedItems={distributionTargets.dynamic_groups}
            assets={assets}
            disabled={row.original.disabled}
            disableUnverifiedDomain={row.original.has_unverified_domain && clientEnforceDomain}
          />
        ),
      })
    }

    return columns
  }, [t, selectedDynamicGroups, filteredGroups])

  return (
    <>
      <div style={{ marginBottom: theme.spacing(1), display: 'flex' }}>
        <SimpleSearchBar onChange={handleFilterChange} debounceTime={0} />
      </div>
      {isLoading ? (
        <LoadingContainer />
      ) : (
        <Paper className={classes.root}>
          <SimpleTable
            columns={columns}
            data={filteredGroups}
            setData={() => {}}
            noResultsContent={<NoResults />}
            enableCheckbox={false}
            customCheckbox={true}
            initialPageSize={defaultRowsPerPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </>
  )
}

const StyledFontAwesome = styled(FontAwesomeIcon)({
  color: theme.palette.orange[900],
  height: 18,
  display: 'inline-flex',
  alignItems: 'center',
  margin: 'auto',
  marginLeft: theme.spacing(0.5),
  marginRight: theme.spacing(0.5),
})

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& .MuiTableCell-head': {
        textAlign: 'left',
        paddingLeft: 40,
      },
      '& .MuiTableCell-body': {
        textAlign: 'left',
      },
    },
    tooltip: {
      textDecoration: 'underline #D1F6FF',
      color: '#D1F6FF',
    },
    tooltipText: {
      fontSize: '12px',
      fontWeight: 'normal',
    },
  })
)

export default LaunchWizardDynamicGroupsTable
