import { Box, Step, StepConnector, StepContent, StepLabel, Stepper, Typography } from '@mui/material'
import { stepConnectorClasses } from '@mui/material/StepConnector'
import { styled } from '@mui/system'
import { NavLink } from 'react-router-dom'

import logo from '@/assets/images/logo-be-awere-stay-safe.svg'
import fingerPrint from '@/assets/images/logo-light-blue.svg'
import rocket from '@/assets/images/rocket-with-line.png'
import { theme } from '@/theme/theme'

const CustomStepConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.root}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.blueGray[900],
      borderWidth: '0.5px',
      padding: 0,
    },
  },
}))

const BlueDotIcon = styled('div')(() => ({
  width: '25px',
  height: '25px',
  padding: '0',
  margin: '-12px 0px',
  boxShadow: `0 0 0 1px ${theme.palette.black}`,
  backgroundColor: theme.palette.cyan[500],
  borderRadius: '50%',
  border: `4px solid ${theme.palette.common.white}`,
  position: 'relative',
  zIndex: 1,
}))

const MicrosoftEntraIdInstructions = () => {
  return (
    <Box width={'100%'}>
      <Typography
        display="flex"
        width="100%"
        justifyContent="center"
        fontSize={22}
        fontWeight={theme.typography.fontWeightBold}
        marginTop={theme.spacing(8)}>
        {' '}
        How To Authenticate Microsoft Entra ID
      </Typography>
      <Box display="flex" padding={theme.spacing(6, 20)}>
        <Stepper orientation="vertical" connector={<CustomStepConnector />}>
          <Step key={'Tenant ID'} active={true}>
            <StepLabel StepIconComponent={() => <BlueDotIcon />}>{'Tenant ID'}</StepLabel>
            <StepContent>
              <Typography padding={theme.spacing(0.1, 1)}>
                • Sign into the Azure portal and navigate to the{' '}
                <NavLink to="https://portal.azure.com/#view/Microsoft_AAD_IAM/ActiveDirectoryMenuBlade/~/Overview">
                  Microsoft Entra ID
                </NavLink>{' '}
                section
              </Typography>
              <Typography padding={theme.spacing(0.1, 1)}>
                • Find your tenant ID and copy paste it in the Cywareness platform{' '}
              </Typography>
            </StepContent>
          </Step>
          <Step key={'Application ID'} active={true}>
            <StepLabel StepIconComponent={() => <BlueDotIcon />}>{'Application ID'}</StepLabel>
            <StepContent>
              <Typography padding={theme.spacing(0.1, 1)}>
                • Click on “App registrations” on your left panel and then “New registration”{' '}
              </Typography>
              <Typography padding={theme.spacing(0.2, 3)}>
                <b>Step 1:</b> Enter name for application (E.g. “Cywareness AD Integration”)
              </Typography>
              <Typography padding={theme.spacing(0.2, 3)}>
                <b>Step 2:</b> In supported account types choose: “Accounts in any organizational directory”
              </Typography>

              <Typography padding={theme.spacing(0.2, 3)}>
                <b>Step 3:</b> Redirect URI can be left empty
              </Typography>

              <Typography padding={theme.spacing(0.2, 3)}>
                <b>Step 4:</b> In the “Essentials” section find Application ID. Copy and paste it into Cywareness
                platform
              </Typography>
            </StepContent>
          </Step>
          <Step key={'Client Secret'} active={true}>
            <StepLabel StepIconComponent={() => <BlueDotIcon />}>{'Client Secret'}</StepLabel>
            <StepContent>
              <Typography padding={theme.spacing(0.1, 1)}>
                • After registering, navigate to the “API permissions” section on your left panel of the newly
                registered application
              </Typography>
              <Typography padding={theme.spacing(0.2, 3)}>
                <b>Step 1:</b> Click “Add a permission” and on the “Microsoft Graph” panel click “Application
                permission” and add a) Group.Read.All, b) User.Read.All
              </Typography>
              <Typography padding={theme.spacing(0.2, 3)}>
                <b>Step 2:</b> Click “Grant admin consent”
              </Typography>
              <Typography padding={theme.spacing(0.1, 1)}>
                • Once permissions are granted navigate to the “Certificates & Secrets” &gt; “Client Secrets” section on
                your left panel and click “New Client Secret”
              </Typography>
              <Typography padding={theme.spacing(0.2, 3)}>
                <b>Step 1:</b> Provide description (E.g. “Cywareness Client Secret”)
              </Typography>
              <Typography padding={theme.spacing(0.2, 3)}>
                <b>Step 2:</b> Set to Expire in (E.g. “365 days, 12 months”)
              </Typography>
              <Typography padding={theme.spacing(0.2, 3)}>
                <b>Step 3:</b> Click “Add”
              </Typography>
              <Typography padding={theme.spacing(0.1, 1)}>
                • Once added, copy the “Value” of the secret and paste it into the “Client Secret” field in the
                Cywareness platform
              </Typography>
            </StepContent>
          </Step>
          <Step key={'extra'} active={true}>
            <img src={rocket} style={{ marginTop: '-100px', marginLeft: '-88px' }}></img>
          </Step>
        </Stepper>
      </Box>
      <img
        src={logo}
        style={{ position: 'absolute', top: theme.spacing(1), left: theme.spacing(3), width: '125px' }}></img>
      <img src={fingerPrint} style={{ position: 'fixed', bottom: 0, right: 0, width: '350px' }}></img>
    </Box>
  )
}

export default MicrosoftEntraIdInstructions
