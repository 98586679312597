import { useMutation, useQueryCache } from 'react-query';
import { ServerError } from '../types';
import { API_URL } from '../constants';
import axios from 'axios';
import { AlertObject } from './alerts';

async function readAllAlerts() {
  const url = `${API_URL}/alerts/read_all`;
  const { data } = await axios.post(url, {}, { withCredentials: true });
  return data.data;
}

export function useReadAllAlerts() {
  const queryCache = useQueryCache();
  return useMutation<string, ServerError>(readAllAlerts, {
    throwOnError: true,
    onMutate: (_id) => {
      queryCache.cancelQueries('alerts');
      const previousAlerts = queryCache.getQueryData('alerts') as AlertObject[];
      const updatedAlert = (previousAlerts || []).map((alert) => ({ ...alert, read: true }));
      queryCache.setQueryData('alerts', updatedAlert);
      return () => queryCache.setQueryData('alerts', previousAlerts || []);
    },
    //@ts-ignore
    onError: (err, variables, rollback) => rollback(),
    onSettled: () => {
      queryCache.invalidateQueries('alerts');
    },
  });
}
