import React from "react";
import { TextField } from "@mui/material";
import { useAiSimulationContext } from "../state";

export const SenderName = () => {
  const { senderName, setSenderName } = useAiSimulationContext();

  return (
    <TextField
      size="small"
      fullWidth
      label="Sender Name"
      value={senderName}
      onChange={(e) => setSenderName(e.target.value)}
      variant="outlined"
      sx={{
        "& .MuiOutlinedInput-root": {
          borderRadius: "10px",
        },
      }}
    />
  );
};
