import { Grid, Paper } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

import { useReportsAnalytics } from '@/api/reports/reports-analytics'
import CustomBreadcrumbs from '@/common/components/Breadcrumbs/Breadcrumbs'
import LoadingContainer from '@/common/components/LoadingContainer/LoadingContainer'
import ReportsAnalytics from './ReportsAnalytics'
import ReportsTable from './ReportsTable'

export default function ResponseConsole() {
  const classes = useStyles()
  const { data, isLoading } = useReportsAnalytics()

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <CustomBreadcrumbs />
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {isLoading || !data ? (
            <LoadingContainer height={250} container={'paper'} />
          ) : (
            <ReportsAnalytics data={data} />
          )}
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <ReportsTable pendingReports={data?.pending_reports} />
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      padding: theme.spacing(0, 2, 4),
    },
    titleContainer: {
      margin: theme.spacing(2, 0),
    },
  })
)
