import Box from '@mui/material/Box'
import MuiStep from '@mui/material/Step'
import StepContent from '@mui/material/StepContent'
import StepLabel from '@mui/material/StepLabel'
import { styled } from '@mui/material/styles'
import { ElementType, FC, ReactNode } from 'react'

import { theme } from '@/theme/theme'
import { StepConnector } from './StepConnector'
import StepIcon from './StepIcon'

type StepProps = {
  label: string | JSX.Element
  icon?: ElementType
  children?: ReactNode
  index?: number
  active: boolean
}

const Step: FC<StepProps> = ({ label, icon, children, ...other }) => {
  const { index, active } = other

  return (
    active && (
      <Box position="relative">
        {index === 0 && <StepConnector />}
        <MuiStep {...other}>
          <StyledStepLabel StepIconComponent={(props) => <StepIcon {...props} icon={icon} />}>{label}</StyledStepLabel>
          <StyledStepContent>{children}</StyledStepContent>
        </MuiStep>
      </Box>
    )
  )
}

const StyledStepContent = styled(StepContent)(() => ({
  border: 'none',
  marginLeft: theme.spacing(5),
}))

const StyledStepLabel = styled(StepLabel)(() => ({
  '& .MuiStepLabel-label': {
    fontSize: '20px',
    paddingLeft: theme.spacing(1),
  },
}))

export default Step
