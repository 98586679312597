import axios from 'axios'
import { API_URL } from '../constants'
import { useMutation } from 'react-query'
import { ServerError } from '../types'

export async function whitelist() {
  const url = `${API_URL}/organizations/whitelist`
  await axios.post(url, {}, { withCredentials: true })
}

export function useWhitelist() {
  return useMutation<void, ServerError>(whitelist, {
    throwOnError: true,
  })
}
