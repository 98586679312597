import { usePhishingSimulationCreate } from '@/api/phishing-simulations/create'
import { getErrorMessage } from '@/api/utils/get-error'
import useToast from '@/common/hooks/useToast'
import { PhishingSimulation } from '@/types/phishingSimulations'
import { Box, Button, Chip, CircularProgress, TextField, Typography } from '@mui/material'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { AFTER_AI_SIMULATION_SAVE_ANIMATION_DELAY_TIME } from '@/api/constants'
import packageSaveAnimationData from '@/assets/lottie/rocketsPackaged.json'
import FeedbackAnimation from '@/common/animations/FeedbackAnimation'
import SummaryTabs from './components/SummaryTabs'
import { SummaryTitle } from './components/SummaryTitle'
import { useAiSimulationContext } from './state'
import { transformStateToDB } from './utils/templateTransformers'

const SummaryPage: FC = () => {
  const { t } = useTranslation()
  const { errorToast, successToast } = useToast()
  const navigateTo = useNavigate()
  const simulation = useAiSimulationContext()

  const { simulationType, vectorType, language, difficultyLevel, topics, simulationName, setSimulationName } =
    simulation

  const successHandler = (responseData: PhishingSimulation) => {
    const { id = '' } = responseData
    successToast(t('contentLibrary.aiBuilder.saveSuccess'))
    setTimeout(() => {
      navigateTo(`/content-library/simulations/${id}`)
    }, AFTER_AI_SIMULATION_SAVE_ANIMATION_DELAY_TIME)
  }

  const errorHandler = (error) => {
    const errorMessage = getErrorMessage(error)
    errorToast(errorMessage || t('contentLibrary.aiBuilder.error.saveError'))
  }

  const {
    mutate: createPhishingSimulation,
    isPending,
    isSuccess,
  } = usePhishingSimulationCreate(successHandler, errorHandler)

  const handleSaveSimulation = async () => {
    const payload = await transformStateToDB(simulation)
    createPhishingSimulation(payload)
  }

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        padding: '50px',
        gap: '26px',
        background: '#FFFFFF',
        boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.25)',
        borderRadius: '15px',
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          flex: 1,
        }}>
        <SummaryTabs />
      </Box>

      {/* box starts */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          flex: 1,
          paddingBottom: 1,
        }}>
        <Typography fontWeight={'bold'}>Simulation Information</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          flex: 1,
          mt: -4,
          gap: '8px',
        }}>
        <SummaryTitle title={simulationType === 'app' ? 'Impersonate an app' : 'Impersonate a human'} />
        <SummaryTitle title="Vector" value={vectorType.toUpperCase()} />
        <SummaryTitle title="Difficulty level" value={`${difficultyLevel}`} />
        <SummaryTitle title="Language" value={language} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          flex: 1,
          mt: -3.5,
          gap: '8px',
        }}>
        <SummaryTitle title="Topics:" />
        {topics.length ? topics.map((topic, i) => <Chip size="small" label={topic} key={i} />) : <></>}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          flex: 1,
          mt: -1.5,
        }}>
        <TextField
          size={'small'}
          fullWidth
          label="Simulation Name"
          placeholder="Enter simulation name"
          variant="outlined"
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '10px',
            },
            gap: '10px',
            alignSelf: 'stretch',
          }}
          value={simulationName}
          onChange={(e) => setSimulationName(e.target.value)}
        />
      </Box>
      {/* <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          flex: 1,
          mt: -3,
        }}
      >
        <FormControlLabel
          control={<Checkbox />}
          label="Share with Cywareness community"
          sx={{ fontWeight: 100, fontSize: 14 }}
        />
      </Box> */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
        }}>
        <Button onClick={handleSaveSimulation} variant={'contained'} disabled={false}>
          {isPending ? <CircularProgress color={'inherit'} size={24} /> : t('simulationLibrary.ai.save')}
        </Button>
      </Box>
      {isSuccess && <FeedbackAnimation animationData={packageSaveAnimationData} />}
    </Box>
  )
}

export default SummaryPage
