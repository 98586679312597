import React from "react";
import { TextField } from "@mui/material";
import { useAiSimulationContext } from "../state";

export const AILinkToLogo = () => {
  const { setCustomLogo } = useAiSimulationContext();

  return (
    <TextField
      placeholder="Link to logo"
      fullWidth
      size="small"
      variant="outlined"
      onChange={(e) => {
        setCustomLogo(e.target.value);
      }}
    />
  );
};

export default AILinkToLogo;
