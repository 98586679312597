import axios from "axios";
import { API_URL } from "../constants";
import { useQuery } from "react-query";
import { ServerError } from "../types";

export type SettingsObjectMin = {
  package: string;
};

export interface SettingsObjectFull extends SettingsObjectMin {
  report_button: boolean;
  updated_at: number;
}

export async function getSettingByName(key: string, configuration: string) {
  if (configuration) {
    const url = `${API_URL}/settings/${configuration}/`;
    const { data } = await axios.get(url, { withCredentials: true });
    return data.data;
  } else {
    return undefined;
  }
}

export function useSetting(configuration: string) {
  return useQuery<SettingsObjectFull, ServerError>(
    ["settings", configuration],
    getSettingByName
  );
}
