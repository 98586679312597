import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons'
import { FC } from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

interface HintProps {
  text: string
}

const Hint: FC<HintProps> = ({ text }) => {
  return (
    <Tooltip title={text}>
      <IconButton size="small" aria-label="info">
        <FontAwesomeIcon icon={faCircleInfo as IconProp} />
      </IconButton>
    </Tooltip>
  )
}

export default Hint
