import { styled } from '@mui/system'
import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import SimulationDoor from '@/assets/images/ExpiredCampaign/simulation_door.svg'
import Logo from '@/assets/images/logo-be-awere-stay-safe.svg'
import CornerImage from '@/assets/images/logo-clean-blue.svg'
import useDeviceDetection, { DeviceType } from '@/common/hooks/useDeviceDetection'
import { theme } from '@/theme/theme'
import { Box, Divider, Typography, Link } from '@mui/material'

const BackgroundContainer = styled(Box)(({ theme }) => ({
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  overflowX: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: -100,
    left: -100,
    width: '300px',
    height: '300px',
    background: `url(${CornerImage}) no-repeat`,
    backgroundSize: 'cover',
    transform: 'rotate(30deg)',
    opacity: 0.2,
    [theme.breakpoints.down('sm')]: {
      top: -50,
      left: -50,
      width: '150px',
      height: '150px',
    },
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: -90,
    right: -50,
    width: '300px',
    height: '300px',
    background: `url(${CornerImage}) no-repeat`,
    backgroundSize: 'cover',
    transform: 'rotate(-60deg)',
    opacity: 0.2,
    [theme.breakpoints.down('sm')]: {
      bottom: -45,
      right: -25,
      width: '150px',
      height: '150px',
    },
  },
}))

const RootContainer = styled(Box)(({ theme }) => ({
  maxWidth: '900px',
  margin: 'auto',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('sm')]: {
    width: '90%',
  },
}))

const StepsContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap',
})

const StepsList = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  height: '350px',
})

const LogoImage = styled('img')({
  height: '75px',
  margin: '10px auto',
})

const StepTitle = styled(Typography)({
  fontWeight: 'bold',
})

const ExpiredCampaign: FC = () => {
  const { t } = useTranslation()
  const deviceType = useDeviceDetection()

  return (
    <BackgroundContainer>
      <RootContainer>
        <LogoImage src={Logo} alt={t('expiredCampaign.altLogo')} />
        <Typography
          fontSize={deviceType === DeviceType.Mobile ? 20 : 36}
          fontWeight={theme.typography.fontWeightExtraBold}
          marginBottom={theme.spacing(1)}>
          {t('expiredCampaign.title')}
        </Typography>
        <Typography
          variant="body1"
          fontWeight={theme.typography.fontWeightRegular}
          lineHeight={'normal'}
          fontSize={deviceType === DeviceType.Mobile ? 14 : 16}>
          {t('expiredCampaign.description')}
        </Typography>
        {deviceType === DeviceType.Mobile && (
          <Box
            component="img"
            src={SimulationDoor}
            padding={theme.spacing(2, 10, 0)}
            alt={t('expiredCampaign.altSimulationDoor')}
          />
        )}
        <Divider sx={{ margin: theme.spacing(2, 0) }} />
        <StepsContainer>
          <Box sx={{ width: deviceType !== DeviceType.Mobile ? '50%' : '100%' }}>
            <Typography
              style={{ wordBreak: 'break-word', whiteSpace: 'normal' }}
              fontWeight={theme.typography.fontWeightBold}
              fontSize={deviceType === DeviceType.Mobile ? 16 : 20}>
              {t('expiredCampaign.nextSteps')}
            </Typography>
            <StepsList fontSize={deviceType === DeviceType.Mobile ? 14 : 16}>
              <Typography component="span" fontSize="inherit" style={{ wordBreak: 'break-word', whiteSpace: 'normal' }}>
                <Trans i18nKey="expiredCampaign.stepOne" components={{ 1: <StepTitle fontSize="inherit" /> }} />
              </Typography>
              <Typography component="span" fontSize="inherit" style={{ wordBreak: 'break-word', whiteSpace: 'normal' }}>
                <Trans
                  i18nKey="expiredCampaign.stepTwo"
                  components={{
                    1: <StepTitle fontSize="inherit" />,
                    2: (
                      <Link
                        href="https://cywareness.io"
                        target="_blank"
                        sx={{ color: '#0D47A1 !important', textDecoration: 'underline' }}
                      />
                    ),
                  }}
                />
              </Typography>
              <Typography component="span" fontSize="inherit" style={{ wordBreak: 'break-word', whiteSpace: 'normal' }}>
                <Trans i18nKey="expiredCampaign.stepThree" components={{ 1: <StepTitle fontSize="inherit" /> }} />
              </Typography>
            </StepsList>
          </Box>
          {deviceType !== DeviceType.Mobile && (
            <Box
              component="img"
              src={SimulationDoor}
              padding={theme.spacing(2)}
              alt={t('expiredCampaign.altSimulationDoor')}
            />
          )}
        </StepsContainer>
        <Divider sx={{ margin: theme.spacing(2, 0) }} />
        <Box fontSize={deviceType === DeviceType.Mobile ? 14 : 16}>
          <Typography component="span" fontSize="inherit">
            <Trans
              i18nKey="expiredCampaign.support"
              components={{
                1: (
                  <Link
                    href="mailto:support@cywareness.io"
                    sx={{ color: '#0D47A1 !important', textDecoration: 'underline' }}
                  />
                ),
              }}
            />
          </Typography>
          <Box sx={{ height: theme.spacing(2) }} />
          <Box sx={{ marginBottom: theme.spacing(2) }}>
            <Typography component="span" fontSize="inherit">
              <Trans i18nKey="expiredCampaign.signOff" components={{ 1: <br /> }} />
            </Typography>
          </Box>
        </Box>
      </RootContainer>
    </BackgroundContainer>
  )
}

export default ExpiredCampaign
