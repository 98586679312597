import LoadingButton from '@mui/lab/LoadingButton'
import { FC, ReactNode } from 'react'

type InputFileProps = {
  label: string
  onChange: (e: any) => void
  accept: string
  isLoading?: boolean
  endIcon?: ReactNode
}

// TODO Need to add Formik support
// TODO Consider using Loading button

const InputFile: FC<InputFileProps> = ({ label, onChange, accept, isLoading, endIcon }) => {
  return (
    <LoadingButton variant="contained" component="label" loading={isLoading} endIcon={endIcon}>
      {label}
      <input type="file" accept={accept} hidden onChange={onChange} />
    </LoadingButton>
  )
}

export default InputFile
