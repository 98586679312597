import { Switch, Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAiSimulationContext } from '../state'
import InfoTooltip from '@/common/components/InfoTooltip/InfoTooltip'
import { theme } from '@/theme/theme'

const PersonalizeTemplateToggle = () => {
  const { setPersonalizedTemplate, personalizedTemplate } = useAiSimulationContext()

  const { t } = useTranslation()

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
      <Typography>{t('simulationLibrary.ai.personalize')}</Typography>
      <Switch
        onChange={(e) => {
          setPersonalizedTemplate(e.target.checked)
        }}
        name="checked"
        color="primary"
        checked={personalizedTemplate}
      />
      <InfoTooltip
        title={t('simulationLibrary.ai.personalizeTooltip')}
        style={{ height: 15, color: theme.palette.blueGray[800], marginLeft: theme.spacing(-1) }}
      />
    </Box>
  )
}

export default PersonalizeTemplateToggle
