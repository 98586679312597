import axios from 'axios'
import { API_URL } from '@/api/constants'
import { useMutation } from '@tanstack/react-query'

type RequestPasswordResetPayload = {
  username: string
  password: string
  recaptcha: string
}

type RequestPasswordResetResponse = {}

const requestPasswordReset = async (
  requestPasswordResetPayload: RequestPasswordResetPayload
): Promise<RequestPasswordResetResponse> => {
  const { data } = await axios.post(`${API_URL}/auth/request-password-reset/`, requestPasswordResetPayload, {
    withCredentials: true,
  })

  return data
}

export const useRequestPasswordReset = () => {
  return useMutation({ mutationFn: requestPasswordReset })
}
