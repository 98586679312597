import React from "react";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Divider, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReportObjectFull } from "../../api/reports/reports";
import moment from "moment";

export default function ReportDetails({ data }: { data: ReportObjectFull }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <div className={classes.detailsColumn}>
        <Typography className={classes.key} variant={"body1"}>
          {t("reportPage.details.subject")}
        </Typography>{" "}
        <Typography className={classes.value} variant={"body1"}>
          {data.subject}
        </Typography>
      </div>
      <div className={classes.detailsColumn}>
        <Typography className={classes.key} variant={"body1"}>
          {t("reportPage.details.sender")}
        </Typography>{" "}
        <Typography variant={"body2"} className={classes.value}>
          {data.sender}
        </Typography>
      </div>
      <div className={classes.detailsColumn}>
        <Typography className={classes.key} variant={"body1"}>
          {t("reportPage.details.reportedBy")}
        </Typography>{" "}
        <Typography variant={"body2"} className={classes.value}>
          {data.reported_by}
        </Typography>
      </div>
      <div className={classes.detailsColumn}>
        <Typography className={classes.key} variant={"body1"}>
          {t("reportPage.details.dateRecieved")}
        </Typography>{" "}
        <Typography variant={"body2"} className={classes.value}>
          {moment.unix(data.date_recieved).format("HH:mm DD/MM/YY")}
        </Typography>
      </div>

      <div className={classes.detailsContainer}>
        <Tooltip
          placement={"top"}
          title={data.reciepents
            .slice(4)
            .map((recipient) => recipient)
            .join(", ")}
        >
          <Typography className={classes.key} variant={"body1"}>
            {t("reportPage.details.recipients", {
              value: data.reciepents.length,
            })}
          </Typography>
        </Tooltip>
        <Divider />
        <div className={classes.reciepentsContainer}>
          {data.reciepents.slice(0, 4).map((recipient) => (
            <Typography
              key={recipient}
              className={classes.reciepent}
              variant={"body2"}
            >
              {recipient}
            </Typography>
          ))}
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: "100%",
      width: "100%",
      padding: theme.spacing(2, 3),
    },
    detailsContainer: {
      marginTop: theme.spacing(2),
    },
    reciepentsContainer: {
      display: "flex",
      flexWrap: "wrap",
      marginTop: theme.spacing(1),
    },
    reciepent: {
      marginRight: theme.spacing(1),
    },
    detailsColumn: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    key: { flex: 1.75 },
    value: {
      flex: 3,
      fontWeight: 700,
    },
  })
);
