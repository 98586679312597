import { useMutation, useQueryCache } from 'react-query';
import { ServerError } from '../types';
import { API_URL } from '../constants';
import axios from 'axios';
import { AlertObject } from './alerts';

type ReadAlertParams = {
  alerts: string[];
};
async function readAlert({ alerts }: ReadAlertParams) {
  const url = `${API_URL}/alerts/read/`;
  const { data } = await axios.post(url, { alerts }, { withCredentials: true });
  return data.data;
}

export function useReadAlert() {
  const queryCache = useQueryCache();
  return useMutation<string, ServerError, ReadAlertParams>(readAlert, {
    throwOnError: true,
    onMutate: ({ alerts }) => {
      queryCache.cancelQueries('alerts');
      const previousAlerts = queryCache.getQueryData('alerts') as AlertObject[];
      const updatedAlerts = (previousAlerts || []).map((alert) => {
        return alerts.includes(alert._id) ? { ...alert, read: true } : alert;
      });
      //@ts-ignore
      queryCache.setQueryData('alerts', () => updatedAlerts);
      return () => queryCache.setQueryData('alerts', previousAlerts);
    },
    //@ts-ignore
    onError: (err, variables, rollback) => rollback(),
    onSettled: () => {
      queryCache.invalidateQueries('alerts');
    },
  });
}
