import React from "react";
import LanguageFilter, {
  LanguageFilterProps,
} from "../../../common/components/LanguageFilter/LanguageFilter";
import { useVideoLanguages } from "../../../api/education-modules/languages";

type VideoLanguageFilterProps = Omit<LanguageFilterProps, "languages">;

export default function VideoLanguagesFilter(props: VideoLanguageFilterProps) {
  const { data: videoLanguages } = useVideoLanguages();

  return <LanguageFilter languages={videoLanguages || []} {...props} />;
}
