import { HTML_CLEAN_REGEX } from '@/api/constants'

export const formatHtml = (html: string) => {
  var tab = '\t'
  var result = ''
  var indent = ''

  html.split(/>\s*</).forEach(function (element) {
    if (element.match(/^\/\w/)) {
      indent = indent.substring(tab.length)
    }

    result += indent + '<' + element + '>\r\n'

    // eslint-disable-next-line no-useless-escape
    if (element.match(/^<?\w[^>]*[^\/]$/)) {
      indent += tab
    }
  })

  return result.substring(1, result.length - 3)
}

export const stripHtml = (html: string) => {
  return html.replaceAll(HTML_CLEAN_REGEX, '')
}
