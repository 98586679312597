import { createContext, useState } from 'react'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import Menu from './Menu'
import QuickActions from '../QuickActions/QuickActions'
import { useClientDetails } from '@/api/client/client'
import { Outlet } from 'react-router-dom'
import { PhishingSimulation } from '@/types/phishingSimulations'
import useToast from '@/common/hooks/useToast'
import { useTranslation } from 'react-i18next'

const defaultLayoutContextValues: {
  blockedNavigationRequest: string
  setBlockedNavigationRequest: (e: string) => void
  navigationBlock: boolean
  setNavigationBlock: (e: boolean) => void
  menuExpandEnabled: boolean
  setMenuExpandEnabled: (e: boolean) => void
  isMenuOpen: boolean
  setIsMenuOpen: (e: boolean) => void
  wasMenuOpen: boolean
  setWasMenuOpen: (e: boolean) => void
  tempSimulationPackage: PhishingSimulation[]
  resetSimulationPackage: () => void
  addToSimulationPackage: (simulation: PhishingSimulation) => void
  removeFromSimulationPackage: (index: number) => void
  setTempSimulationPackage: (e: any) => void
  setTempSimulationPackageId: (e: string) => void
  setTempSimulationPackageName: (e: string) => void
  tempSimulationPackageId: string
  tempSimulationPackageName: string
} = {
  blockedNavigationRequest: '/',
  setBlockedNavigationRequest: (e: string) => {},
  navigationBlock: false,
  setNavigationBlock: (e: boolean) => {},
  menuExpandEnabled: true,
  setMenuExpandEnabled: (e: boolean) => {},
  isMenuOpen: true,
  setIsMenuOpen: (e: boolean) => {},
  wasMenuOpen: true,
  setWasMenuOpen: (e: boolean) => {},
  tempSimulationPackage: [],
  resetSimulationPackage: () => {},
  addToSimulationPackage: (simulation: PhishingSimulation) => {},
  removeFromSimulationPackage: (index: number) => {},
  setTempSimulationPackage: (e: any) => {},
  setTempSimulationPackageId: (e: string) => {},
  setTempSimulationPackageName: (e: string) => {},
  tempSimulationPackageId: '',
  tempSimulationPackageName: '',
}

export const LayoutContext = createContext(defaultLayoutContextValues)

export default function Layout() {
  const [navigationBlock, setNavigationBlock] = useState(false)
  const [blockedNavigationRequest, setBlockedNavigationRequest] = useState('/')
  const [menuExpandEnabled, setMenuExpandEnabled] = useState(true)
  const [isMenuOpen, setIsMenuOpen] = useState(true)
  const [wasMenuOpen, setWasMenuOpen] = useState(true)
  const [tempSimulationPackage, setTempSimulationPackage] = useState<any>([])
  const [tempSimulationPackageId, setTempSimulationPackageId] = useState<string>('')
  const [tempSimulationPackageName, setTempSimulationPackageName] = useState<string>('')
  const { errorToast } = useToast()
  const { t } = useTranslation()

  const resetSimulationPackage = () => {
    setTempSimulationPackage([])
  }
  const addToSimulationPackage = (simulation: PhishingSimulation) => {
    if (tempSimulationPackage.map((tempSimulation: PhishingSimulation) => tempSimulation.id).includes(simulation.id)) {
      errorToast(t('simulationPackage.alreadyExist'))
      return
    }

    setTempSimulationPackage((prevPackages: PhishingSimulation[]) => [...prevPackages, simulation])
  }

  const removeFromSimulationPackage = (index: number) => {
    setTempSimulationPackage(tempSimulationPackage.slice(0, index - 1).concat(tempSimulationPackage.slice(index)))
  }

  const classes = useStyles()
  const { data: clientDetails, isLoading } = useClientDetails()

  return (
    <div className={classes.root}>
      <LayoutContext.Provider
        value={{
          blockedNavigationRequest,
          setBlockedNavigationRequest,
          menuExpandEnabled,
          setMenuExpandEnabled,
          isMenuOpen,
          setIsMenuOpen,
          tempSimulationPackage,
          resetSimulationPackage,
          addToSimulationPackage,
          removeFromSimulationPackage,
          setTempSimulationPackage,
          setTempSimulationPackageId,
          setTempSimulationPackageName,
          tempSimulationPackageId,
          tempSimulationPackageName,
          navigationBlock,
          setNavigationBlock,
          wasMenuOpen,
          setWasMenuOpen,
        }}
      >
        <div className={classes.sidebar}>
          <Menu />
        </div>
        <main className={classes.content}>
          <div className={classes.container} id="root-container">
            <Outlet />
          </div>
        </main>
        {!clientDetails?.onboarding && !isLoading ? null : <QuickActions />}
      </LayoutContext.Provider>
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      position: 'relative',
      overflow: 'hidden',
      height: '100vh',
    },
    content: {
      position: 'relative',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
      overflowX: 'hidden',
      justifyContent: 'center',
    },
    container: {
      flex: 1,
      padding: theme.spacing(2, 1),
      maxHeight: '100vh',
    },
    toolbar: { flex: '0 1 auto' },
    sidebar: {},
  })
)
