import useLottie from '@/common/hooks/useLottie'

import animationData from '@/assets/lottie/fingerprint.json'
import { Box, styled } from '@mui/material'

const LoadingAnimation = ({ size = 15 }: { size?: number }) => {
  const { LottieAnimation } = useLottie({
    animationData: animationData,
    height: size * 20,
    width: size * 20,
  })

  return <Container id="123">{LottieAnimation}</Container>
}
const Container = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  display: 'flex',
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
}))

export default LoadingAnimation
