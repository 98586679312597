import React, { useMemo, memo } from "react";
import { Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import NoResultsContainer from "../NoResultsContainer/NoResultsContainer";
import { User } from "../../../api/users/users";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import GaugeChart from "react-gauge-chart";
import InfoTooltip from "../InfoTooltip/InfoTooltip";

export default memo(function GaugeScoremeter({
  score,
  hideTitle = false,
}: {
  score: User["awareness_score"];
  hideTitle?: boolean;
}) {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();

  const levels = useMemo(() => {
    return [
      {
        label: t("awarenessMeter.bad"),
        color: theme.palette.error.main,
      },
      {
        label: t("awarenessMeter.low"),
        color: theme.palette.warning.main,
      },
      {
        label: t("awarenessMeter.average"),
        color: theme.palette.warning.light,
      },
      {
        label: t("awarenessMeter.excellent"),
        color: theme.palette.success.main,
      },
    ];
  }, [
    t,
    theme.palette.error.main,
    theme.palette.success.main,
    theme.palette.warning.light,
    theme.palette.warning.main,
  ]);

  const percentage = useMemo(() => (score || 0) / 100, [score]);

  return score >= 0 ? (
    <div className={classes.root}>
      {hideTitle ? null : (
        <div className={classes.titleContainer}>
          <Typography variant={"subtitle2"} className={classes.title}>
            {t("awarenessMeter.title")}
          </Typography>
          <InfoTooltip title={t("awarenessMeter.tooltip")} />
        </div>
      )}
      <div className={classes.chartContainer}>
        <GaugeChart
          id="gauge-chart3"
          nrOfLevels={4}
          colors={[
            theme.palette.error.main,
            theme.palette.warning.main,
            theme.palette.warning.light,
            theme.palette.success.main,
          ]}
          arcWidth={0.3}
          arcPadding={0.04}
          percent={percentage}
          hideText
        />
      </div>
      <div className={classes.legendContainer}>
        {levels.map((level) => (
          <div className={classes.legendItem} key={level.label}>
            <div className={classes.dot} style={{ background: level.color }} />
            <Typography variant={"subtitle2"}>{level.label}</Typography>
          </div>
        ))}
      </div>
    </div>
  ) : (
    <NoResultsContainer text={"awarenessMeter.noResults"} />
  );
});

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    root: {
      // paddingBottom: theme.spacing(1),
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    title: {
      // marginTop: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
    titleContainer: {
      display: "flex",
      alignItems: "center",
      alignSelf: "flex-start",
      marginBottom: theme.spacing(3),
    },
    chartContainer: {
      maxWidth: 400,
    },
    legendContainer: {
      display: "flex",
      justifyContent: "space-evenly",
      width: "100%",
      maxWidth: 500,
    },
    legendItem: {
      display: "flex",
      alignItems: "center",
    },
    dot: {
      marginRight: theme.spacing(1),
      borderRadius: "50%",
      height: 12,
      width: 12,
    },
  })
);

// {/*<ReactSpeedometer*/}
// {/*  customSegmentLabels={[*/}
// {/*    { text: t('userProfile.bad') },*/}
// {/*    { text: t('userProfile.low') },*/}
// {/*    { text: t('userProfile.average') },*/}
// {/*    { text: t('userProfile.excellent') },*/}
// {/*  ]}*/}
// {/*  customSegmentStops={[0, 25, 50, 75, 100]}*/}
// {/*  segmentColors={[*/}
// {/*    theme.palette.error.dark,*/}
// {/*    theme.palette.error.main,*/}
// {/*    theme.palette.warning.main,*/}
// {/*    theme.palette.success.main,*/}
// {/*  ]}*/}
// {/*  paddingVertical={theme.spacing(1)}*/}
// {/*  ringWidth={24}*/}
// {/*  minValue={0}*/}
// {/*  maxValue={100}*/}
// {/*  value={score}*/}
// {/*  segments={4}*/}
// {/*/>*/}
