import {
  SET_SIMULATION_TYPE,
  SET_WIZARD_CURRENT_STEP,
  SET_VECTOR_TYPE,
  WizardAction,
} from "./Types";

export const setCurrentStep = (payload: number): WizardAction => ({
  type: SET_WIZARD_CURRENT_STEP,
  payload,
});
export const setSimulationType = (payload: string): WizardAction => ({
  type: SET_SIMULATION_TYPE,
  payload,
});
export const setVectorType = (payload: string): WizardAction => ({
  type: SET_VECTOR_TYPE,
  payload,
});
