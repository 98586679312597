import React, { Dispatch, SetStateAction, useState } from 'react'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import moment, { Moment } from 'moment'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TextField } from '@mui/material'
import dayjs from 'dayjs'
import { faCaretDown, faCaretUp } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

dayjs.extend(advancedFormat)

type ReportsTableToolbarProps = {
  queryFilters: any
  setQueryFilters: Dispatch<SetStateAction<any>>
  label?: string
  clearable?: boolean
}

export default function DateFilter({
  queryFilters,
  setQueryFilters,
  label,
  clearable = true,
}: ReportsTableToolbarProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [openStartDate, setOpenStartDate] = useState(false)
  const [openEndDate, setOpenEndDate] = useState(false)

  const handleDateChange = (key: string) => (date: Moment | null) => {
    setQueryFilters((prevState) => ({ ...prevState, [key]: date?.unix() }))

    setOpenStartDate(false)
    setOpenEndDate(false)
  }

  return (
    <Toolbar className={classes.root}>
      {label ? (
        <Typography className={classes.title} variant="h6" id="tableTitle">
          {label}
        </Typography>
      ) : null}
      <div className={classes.datePickerContainer}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            disableMaskedInput
            components={{
              OpenPickerIcon: () => (
                <FontAwesomeIcon icon={openStartDate ? faCaretUp : (faCaretDown as IconProp)} fontSize={16} />
              ),
            }}
            componentsProps={{
              actionBar: {
                actions: ['clear'],
              },
            }}
            maxDate={queryFilters.endDate ? moment.unix(queryFilters.endDate) : undefined}
            maxDateMessage={t('datePicker.maxDateError')}
            inputFormat="LL"
            autoOk
            open={openStartDate}
            label={t('dateFilterComponent.from')}
            value={queryFilters.startDate ? moment.unix(queryFilters.startDate) : null}
            onChange={handleDateChange('startDate')}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  mt: 2,
                  '& .MuiInputBase-input': {
                    padding: '14px 12px',
                  },
                  '& fieldset': { border: 0 },
                  '&:hover fieldset': { border: 0 },
                  '&& .Mui-focused fieldset': { border: 0 },
                }}
                inputProps={{ ...params.inputProps, readOnly: true }}
                onClick={(e) => setOpenStartDate(!openStartDate)}
              />
            )}
          />
        </LocalizationProvider>
      </div>
      <div className={classes.datePickerContainer}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            disableMaskedInput
            components={{
              OpenPickerIcon: () => (
                <FontAwesomeIcon icon={openEndDate ? faCaretUp : (faCaretDown as IconProp)} fontSize={16} />
              ),
            }}
            componentsProps={{
              actionBar: {
                actions: ['clear'],
              },
            }}
            minDate={queryFilters.startDate ? moment.unix(queryFilters.startDate) : undefined}
            minDateError={t('datePicker.minDateError')}
            inputFormat="LL"
            autoOk
            open={openEndDate}
            label={t('dateFilterComponent.to')}
            value={queryFilters.endDate ? moment.unix(queryFilters.endDate) : null}
            onChange={handleDateChange('endDate')}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  mt: 2,
                  '& .MuiInputBase-input': {
                    padding: '14px 12px',
                  },
                  '& fieldset': { border: 0 },
                  '&:hover fieldset': { border: 0 },
                  '&& .Mui-focused fieldset': { border: 0 },
                }}
                inputProps={{ ...params.inputProps, readOnly: true }}
                onClick={(e) => setOpenEndDate(!openEndDate)}
              />
            )}
          />
        </LocalizationProvider>
      </div>
    </Toolbar>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    title: {
      flex: '1 0 auto',
    },
    datePickerContainer: {
      width: '220px',
      marginRight: theme.spacing(3),
    },
  })
)
