import axios from "axios";
import { API_URL } from "../constants";
import { useMutation, useQueryCache } from "react-query";
import { ServerError } from "../types";

export async function deleteIntegration(integrationId) {
  const url = `${API_URL}/integrations/${integrationId}/`;
  const { data } = await axios.delete(url, { withCredentials: true });
  return data;
}

export default function useDeleteIntegration() {
  const queryCache = useQueryCache();
  return useMutation<any, ServerError, string>(deleteIntegration, {
    throwOnError: true,
    onSettled: () => {
      queryCache.invalidateQueries("integrations");
    },
  });
}
