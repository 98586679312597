import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useAiSimulationContext } from "../state";
import Tooltip from "@mui/material/Tooltip";

export type LandingPageOptionProps = {
  title: string;
  type: string;
  description: string;
  command: string;
  templateSpecificInstructions?: string;
} | null;

export const landingPageOptions: LandingPageOptionProps[] = [
  {
    title: "Login Page",
    type: "loginPage",
    description: "Simulates a login page, requiring username and password.",
    command:
      "Create a login page requiring username and password. Use a title such as 'Login to your account'.",
    templateSpecificInstructions:
      "Username/email and password are required inputs",
  },
  {
    title: "Account Verification",
    type: "accountVerification",
    description:
      "Requests users to verify their account details, including full name, email, and password.",
    command:
      "Create an account verification page requiring fullname, email and password.",
  },
  {
    title: "Gift Coupon",
    type: "giftCoupon",
    description:
      "Offers a fake gift coupon or reward in exchange for personal information (email, name, and phone number).",
    command: `Create a gift coupon page offering a fake gift coupon or reward in exchange for personal information (email, name, and phone number).`,
    templateSpecificInstructions: `
    Unless specified as an image - Create a colorful credit card-style component,  with brand logo (or name), with price on it. centered. Make it standalone component outside the form.
    `,
  },
  {
    title: "Shared Document",
    type: "sharedDocument",
    description: "Prompts users to log in to access a shared file.",
    command: `Create a shared document page, prompting users to log in to access a shared file.`,
    templateSpecificInstructions: `
      - Use a "Shared Document" title.
      - Use a "Login to access document" call-to-action button.
      - Provide a hint or "Forgot Password" option near the password input.
    `,
  },
  {
    title: "Emergency Update",
    type: "emergencyUpdate",
    description:
      "Claims an urgent software or security update is required, prompting users for personal information (username and password) to proceed.",
    command: `
      - Use title such as "Urgent Update"
      - Use a subtitle such as "Please login to acknowledge update
      - Add a login form prompting for username and password.
      - Add "Secure My Account" call-to-action button below form.
  `,
    templateSpecificInstructions: ``,
  },
  {
    title: "Prize Claim",
    type: "prizeClaim",
    description:
      "Informs users they've won a prize and need to provide their details (email, name, and phone number) to claim it.",
    command:
      "Create a website telling the user he won a prize, and as the user to login  (email, name, and phone number) to claim it.",
  },
  {
    title: "Security Check",
    type: "securityCheck",
    description:
      "Requests users to confirm their identity due to suspicious account activity, asking for various details (username, password, email, name, and phone number).",
    command:
      "Create a website asking the user to confirm their identity due to suspicious account activity, (username, password, email, name, and phone number).",
  },
  {
    title: "Online Shopping",
    type: "onlineShopping",
    description:
      "Mimics an e-commerce checkout page, prompting users to enter their payment and shipping information (credit card number, expiration date, CVV & ID).",
    command:
      "Create an e-commerce checkout page, prompting users to enter their payment and shipping information (credit card number, expiration date, CVV & ID)",
  },
  {
    title: "Survey Participation",
    type: "surveyParticipation",
    description:
      "Offers a chance to participate in a survey for a reward, asking for personal information (email, name, and phone number).",
    command:
      "Create a website desigened for survey, asking for personal information (email, name, and phone number) to answer survey",
  },
  // {
  //   title: "Event Registration",
  //   type: "eventRegistration",
  //   description:
  //     "Promotes a fake event, requiring users to provide their information to register (email, name, and phone number).",
  // },
  // {
  //   title: "Software Download",
  //   type: "softwareDownload",
  //   description:
  //     "Offers a free software download, asking users to enter their credentials to proceed (username & password).",
  // },
  // {
  //   title: "Tech Support",
  //   type: "techSupport",
  //   description:
  //     "Claims to be a tech support page where users must provide their information (username & password) for assistance.",
  // },
  // {
  //   title: "Financial Services",
  //   type: "financialServices",
  //   description:
  //     "Mimics a page from a financial institution, asking for account details(username, password & reCAPTCHA)).",
  // },
  // {
  //   title: "Social Media Access",
  //   type: "socialMediaAccess",
  //   description:
  //     "Pretends to be a social media login page, tricking users into sharing their credentials (username & password).",
  // },
  // {
  //   title: "Job Application",
  //   type: "jobApplication",
  //   description:
  //     "Offers a fake job opportunity, requesting personal (email, name & phone number) and professional details (Linkedin URL) to apply.",
  // },
];
const LandingPageTypeSelection: React.FC = () => {
  const { setLandingPageType } = useAiSimulationContext();

  return (
    <Autocomplete
      freeSolo
      clearIcon={null}
      onChange={(event, newValue: any) => {
        if (newValue) {
          setLandingPageType(newValue);
        }
      }}
      options={landingPageOptions} // Pass the entire objects
      getOptionLabel={(option: any) => option.title} // Get the title from each object
      renderInput={(params) => (
        <TextField
          {...(params as any)}
          label="Landing page type"
          variant="outlined"
          size="small"
          fullWidth
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "10px",
            },
          }}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <Tooltip title={option.description} placement="right">
          <li {...props}>{option.title}</li>
        </Tooltip>
      )}
    />
  );
};

export default LandingPageTypeSelection;
