import { FC } from 'react'
import { Box, Typography, styled } from '@mui/material'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import { HOUR_MINUTE_TIME } from '@/api/constants'

dayjs.extend(utc)
dayjs.extend(timezone)

type StatusBarProps = {
  backgroundColor?: string
  color?: string
}

const StatusBar: FC<StatusBarProps> = ({ backgroundColor = '#fff', color = '#000' }) => {
  const time = dayjs().format(HOUR_MINUTE_TIME)

  return (
    <Container backgroundColor={backgroundColor}>
      <Time color={color}>{time}</Time>
      <StatusBarImage color={color} />
    </Container>
  )
}

const StatusBarImage: FC<{ color: string }> = ({ color }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 326 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_649_984)">
        <path
          d="M75.6135 14.7061C75.3012 14.4627 74.8478 14.5191 74.6016 14.8278C74.3553 15.1365 74.4124 15.5846 74.7247 15.828L92.502 29.5995C92.8143 29.8429 93.2677 29.7865 93.514 29.4779C93.7602 29.1692 93.7031 28.721 93.3908 28.4776L90.6822 26.3793C90.6882 26.3674 90.6942 26.3525 90.7002 26.3407C90.8564 25.9994 90.7933 25.5987 90.5411 25.3197L90.3188 25.0733C89.3819 24.0286 88.8624 22.6841 88.8624 21.2862V20.7282C88.8624 18.4309 87.2108 16.5136 85.0187 16.0744V15.5045C85.0187 14.9792 84.5893 14.5547 84.0578 14.5547C83.5262 14.5547 83.0968 14.9792 83.0968 15.5045V16.0744C81.8176 16.3296 80.7245 17.0894 80.0338 18.1312L75.6135 14.7061ZM86.6463 26.9016L79.2531 21.1467V21.2891C79.2531 22.6841 78.7336 24.0316 77.7967 25.0763L77.5745 25.3227C77.3222 25.6016 77.2621 26.0023 77.4153 26.3436C77.5684 26.685 77.9138 26.9016 78.2921 26.9016H86.6463ZM85.4181 29.1959C85.7784 28.8397 85.9796 28.356 85.9796 27.8514H84.0578H82.1359C82.1359 28.356 82.3371 28.8397 82.6974 29.1959C83.0578 29.5521 83.5473 29.7509 84.0578 29.7509C84.5683 29.7509 85.0577 29.5521 85.4181 29.1959Z"
          fill={color}
        />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M241.124 16.7734H240.048C239.454 16.7734 238.972 17.2249 238.972 17.782V26.5232C238.972 27.0802 239.454 27.5318 240.048 27.5318H241.124C241.718 27.5318 242.2 27.0802 242.2 26.5232V17.782C242.2 17.2249 241.718 16.7734 241.124 16.7734ZM234.669 18.9252H235.745C236.339 18.9252 236.821 19.3876 236.821 19.9581V26.4992C236.821 27.0696 236.339 27.532 235.745 27.532H234.669C234.075 27.532 233.593 27.0696 233.593 26.4992V19.9581C233.593 19.3876 234.075 18.9252 234.669 18.9252ZM230.365 21.0762H229.29C228.696 21.0762 228.214 21.5578 228.214 22.1519V26.4547C228.214 27.0488 228.696 27.5304 229.29 27.5304H230.365C230.959 27.5304 231.441 27.0488 231.441 26.4547V22.1519C231.441 21.5578 230.959 21.0762 230.365 21.0762ZM224.986 23.2281H223.91C223.316 23.2281 222.834 23.7097 222.834 24.3038V26.4552C222.834 27.0493 223.316 27.5309 223.91 27.5309H224.986C225.58 27.5309 226.061 27.0493 226.061 26.4552V24.3038C226.061 23.7097 225.58 23.2281 224.986 23.2281Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M256.871 18.7045C259.255 18.7046 261.547 19.6206 263.275 21.2632C263.405 21.39 263.613 21.3884 263.741 21.2596L264.985 20.0044C265.05 19.9391 265.086 19.8506 265.085 19.7585C265.085 19.6665 265.048 19.5784 264.982 19.5139C260.447 15.1679 253.293 15.1679 248.758 19.5139C248.693 19.5784 248.655 19.6664 248.655 19.7585C248.654 19.8505 248.69 19.9391 248.755 20.0044L249.999 21.2596C250.127 21.3886 250.335 21.3902 250.465 21.2632C252.193 19.6205 254.486 18.7045 256.871 18.7045ZM256.871 22.7874C258.181 22.7873 259.444 23.2741 260.415 24.1533C260.546 24.2781 260.753 24.2754 260.881 24.1472L262.123 22.8921C262.189 22.8262 262.225 22.7369 262.224 22.6441C262.223 22.5513 262.185 22.4628 262.118 22.3983C259.162 19.648 254.583 19.648 251.626 22.3983C251.56 22.4628 251.521 22.5514 251.521 22.6442C251.52 22.737 251.556 22.8263 251.622 22.8921L252.864 24.1472C252.992 24.2754 253.198 24.2781 253.33 24.1533C254.3 23.2747 255.562 22.7879 256.871 22.7874ZM259.359 25.5351C259.361 25.6282 259.325 25.7179 259.258 25.7831L257.11 27.9513C257.047 28.0151 256.961 28.0509 256.871 28.0509C256.782 28.0509 256.696 28.0151 256.633 27.9513L254.484 25.7831C254.418 25.7178 254.381 25.6281 254.383 25.535C254.385 25.442 254.425 25.3539 254.494 25.2915C255.866 24.1309 257.876 24.1309 259.248 25.2915C259.317 25.3539 259.357 25.4421 259.359 25.5351Z"
        fill={color}
      />
      <path
        opacity="0.35"
        d="M274.409 15.6976H295.568C296.855 15.6976 297.899 16.7412 297.899 18.0286V26.2767C297.899 27.564 296.855 28.6077 295.568 28.6077H274.409C273.122 28.6077 272.078 27.5641 272.078 26.2767V18.0286C272.078 16.7412 273.122 15.6976 274.409 15.6976Z"
        stroke={color}
        stroke-width="1.07584"
      />
      <path
        opacity="0.4"
        d="M299.136 20.5393V24.8421C300.002 24.4777 300.565 23.63 300.565 22.6907C300.565 21.7515 300.002 20.9037 299.136 20.5393"
        fill={color}
      />
      <path
        d="M274.015 19.1037C274.015 18.3115 274.657 17.6693 275.449 17.6693H294.312C295.104 17.6693 295.747 18.3115 295.747 19.1037V25.1966C295.747 25.9888 295.104 26.631 294.312 26.631H275.449C274.657 26.631 274.015 25.9888 274.015 25.1966V19.1037Z"
        fill={color}
      />
      <defs>
        <clipPath id="clip0_649_984">
          <rect width="19.2187" height="15.1962" fill="white" transform="translate(74.448 14.5545)" />
        </clipPath>
      </defs>
    </svg>
  )
}

const Container = styled(Box)(({ backgroundColor }: { backgroundColor: string }) => ({
  backgroundColor: backgroundColor,
  width: '100%',
  height: '8.5%',
  display: 'flex',
  marginTop: '-3.5%',
  alignItems: 'center',
}))
const Time = styled(Typography)(({ color }: { color: string }) => ({
  position: 'absolute',
  left: '8%',
  top: '1.6%',
  color: color,
  fontSize: '65%',
  fontWeight: 'bold',
}))
export default StatusBar
