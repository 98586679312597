import Axios from 'axios'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { API_URL } from '@/api/constants'
import { OrganizationRequest } from '@/types/organizations'

const organizationCreate = async (organizationPayload: OrganizationRequest) => {
  const url = `${API_URL}/organizations/`
  const { data } = await Axios.post(url, organizationPayload, { withCredentials: true })

  return data
}

const useOrganizationCreate = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: organizationCreate,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['organizations'],
      })
    },
  })
}

export { useOrganizationCreate }
