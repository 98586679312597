import { Box } from '@mui/material'
import MuiStepper from '@mui/material/Stepper'
import { Children, FC, isValidElement, ReactNode } from 'react'

import { StepConnector } from './StepConnector'

type StepperProps = {
  children: ReactNode
}

const Stepper: FC<StepperProps> = ({ children }) => {
  const activeChildren = Children.toArray(children).filter((child) => isValidElement(child) && child.props.active)

  return (
    <Box>
      <MuiStepper sx={{ overflow: 'hidden' }} orientation="vertical" connector={<StepConnector />}>
        {children}
      </MuiStepper>
      {activeChildren.length > 1 && (
        <Box sx={{ ml: '18px', position: 'relative' }}>
          <Box
            sx={{
              width: '10%',
              height: '35px',
              borderLeft: '3px solid black',
              borderBottom: '3px solid black',
              borderBottomLeftRadius: '50px',
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              marginLeft: '50px',
              width: '65%',
              height: '3px',
              background:
                'linear-gradient(to right, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.6) 70%, rgba(0, 0, 0, 0) 100%)',
            }}
          />
        </Box>
      )}
    </Box>
  )
}

export default Stepper
