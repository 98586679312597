import axios from 'axios'
import { useMutation } from '@tanstack/react-query'
import { useQueryCache } from 'react-query'
import { API_URL } from '@/api/constants'
import { RedeemPromoCodeRequest } from '@/types/promoCodes'

const redeemPromoCode = async (redeemPromoCodePayload: RedeemPromoCodeRequest) => {
  const url = `${API_URL}/profile/redeem-promo-code/`
  const { data } = await axios.post(url, redeemPromoCodePayload, { withCredentials: true })

  return data
}

export const useRedeemPromoCode = () => {
  const queryCache = useQueryCache()

  return useMutation({
    mutationFn: redeemPromoCode,
    onSuccess: async () => {
      await queryCache.invalidateQueries(['organizations'])
    },
  })
}
