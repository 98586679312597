import React from "react";
import { Box } from "@mui/material";

const EmbeddedContent = (source) => {
  return (
    <Box style={styles.box}>
      <iframe src={source.video} style={styles.iframe} />
    </Box>
  );
};

export default EmbeddedContent;

const styles = {
  box: {
    marginTop: "20px",
  },
  iframe: {
    border: "1px solid lightgray",
    borderRadius: "16px",
    width: "100%",
    height: "579px",
  },
};
