import React from "react";
import * as Sentry from "@sentry/react";
import * as ReactDOM from "react-dom/client";

import "./i18n";
import "./styles.css";

import App from "@/App";
import { ENV, SENTRY_DSN } from "@/api/constants";

Sentry.init({
  environment:
    ENV === "dev" ? "staging" : ENV === "prod" ? "production" : "development",
  dsn: SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [
        "localhost",
        /^https:\/\/(?:[\w-]+\.)?cywareness\.io/,
      ],
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: ENV !== "development",
});

const root = ReactDOM.createRoot(document.getElementById("root")!);

root.render(<App />);
