import React, { FC } from "react";
import { useParams } from "react-router-dom";
import VideoEditableContent from "../VideoEditableContent";
import { RouteParams } from "../VideoPage/VideoPage";
import { useEducationModuleById } from "../../../../api/education-modules/module-by-id";

const EditVideoPage: FC = () => {
  const { id } = useParams<RouteParams>();
  const {
    data: educationModule,
    isLoading,
    isError,
  } = useEducationModuleById(id);

  if (!educationModule || isLoading || isError) {
    return <></>;
  }

  return <VideoEditableContent video={educationModule} />;
};

export default EditVideoPage;
