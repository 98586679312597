import React from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

export default function NoResultsContainer({
  text,
  image,
}: {
  text: string;
  image?: string;
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      {image && <img alt={text} src={image} height={68} width={68} />}
      <Typography variant={"h6"} color={"textSecondary"}>
        {t(text)}
      </Typography>
    </div>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  })
);
