import axios from "axios";
import { API_URL } from "../constants";
import { usePaginatedQuery } from "react-query";
import { ServerError } from "../types";

export type User = {
  _id: string;
  username: string;
  client_id: string;
  phone_number: string;
  first_name: string;
  last_name: string;
  enabled: boolean;
  member_of: string[];
  office_id: string;
  last_campaign: number;
  awareness_score: number;
  awareness_last_30_days: number[];
  engagement: { day: string; value: number }[];
  campaigns: {
    day: string;
    value: number;
    counter: number;
    events: {
      email_open: number;
      sms_link_click: number;
      email_link_click: number;
      submit: number;
      reported: number;
      feedback_link_click: number;
    };
  }[];
  exposure: { label: string; value: number }[];
  saved_assets?: string[];
};

export type AllUsersQueryVariables = {
  fullname: string;
  email: string;
  offices: string[];
  groups: string[];
  offset: number;
  startIndex: number;
};

export type AllUsersResponse = {
  count: number;
  items: User[];
};

export async function getAllUsers(
  key,
  {
    fullname = "",
    email = "",
    offices = [],
    groups = [],
    offset = 5,
    startIndex = 0,
  }
) {
  const url = `${API_URL}/users/all`;
  const { data } = await axios.post(
    url,
    { fullname, email, offices, groups, offset, startIndex },
    { withCredentials: true }
  );
  return data.data;
}

export function useUsers(variables?: AllUsersQueryVariables) {
  return usePaginatedQuery<AllUsersResponse, ServerError>(
    ["users", { ...variables }],
    getAllUsers,
    {
      keepPreviousData: true,
    }
  );
}
