import { toast, ToastOptions } from 'react-toastify'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import Typography from '@mui/material/Typography'
import { faCheck, faCircleExclamation, faTriangleExclamation, faX } from '@fortawesome/pro-light-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
const useStyles = makeStyles((theme) => ({
  message: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  regularClassName: {
    background: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    color: theme.palette.text.primary,
    borderRadius: theme.shape.borderRadius,
  },
  regularBodyClassName: {
    padding: theme.spacing(1),
  },
  regularProgressClassName: {
    background: theme.palette.blueGray[900],
  },
  successClassName: {
    background: theme.palette.success.main,
    boxShadow: theme.shadows[1],
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
  },
  successBodyClassName: { padding: theme.spacing(0) },
  successProgressClassName: {
    background: theme.palette.common.white,
  },
  errorClassName: {
    background: theme.palette.error.main,
    boxShadow: theme.shadows[1],
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
  },
  errorBodyClassName: { padding: theme.spacing(0) },
  errorProgressClassName: {
    background: theme.palette.common.white,
  },
  icon: {
    margin: theme.spacing(0, 1),
  },
}))

function useToast() {
  const classes = useStyles()

  const regularMessage = (message) => (
    <div className={classes.message}>
      <FontAwesomeIcon icon={faTriangleExclamation as IconProp} className={classes.icon} />
      <Typography variant={'subtitle2'} color={'inherit'}>
        {message}
      </Typography>
    </div>
  )

  const successMessage = (message) => (
    <div className={classes.message}>
      <FontAwesomeIcon icon={faCheck as IconProp} className={classes.icon} />
      <Typography variant={'subtitle2'} color={'inherit'}>
        {message}
      </Typography>
    </div>
  )

  const errorMessage = (message) => (
    <div className={classes.message}>
      <FontAwesomeIcon icon={faCircleExclamation as IconProp} className={classes.icon} />
      <Typography variant={'subtitle2'} color={'inherit'}>
        {message}
      </Typography>
    </div>
  )

  const regularToast = (message, options?: ToastOptions) =>
    toast(regularMessage(message), {
      className: classes.regularClassName,
      bodyClassName: classes.regularBodyClassName,
      progressClassName: classes.regularProgressClassName,
      closeButton: <FontAwesomeIcon icon={faX as IconProp} className={classes.icon} />,
      ...options,
    })

  const toastIdRef = React.useRef<string | number | null>()

  const progressToast = (message: string, options: ToastOptions) => {
    if (toastIdRef.current === null) {
      toastIdRef.current = toast(regularMessage(message), {
        className: classes.regularClassName,
        bodyClassName: classes.regularBodyClassName,
        progressClassName: classes.regularProgressClassName,
        onClose: () => (toastIdRef.current = null),
        closeButton: <FontAwesomeIcon icon={faX as IconProp} className={classes.icon} />,
        ...options,
      })
    } else {
      toastIdRef.current &&
        toast.update(toastIdRef.current, {
          progress: options.progress,
        })
    }
  }

  const successToast = (message, options?: ToastOptions) =>
    toast(successMessage(message), {
      className: classes.successClassName,
      bodyClassName: classes.successBodyClassName,
      progressClassName: classes.successProgressClassName,
      closeButton: <FontAwesomeIcon icon={faX as IconProp} className={classes.icon} />,
      ...options,
    })

  const errorToast = (message, options?: ToastOptions) =>
    toast(errorMessage(message), {
      className: classes.errorClassName,
      bodyClassName: classes.errorBodyClassName,
      progressClassName: classes.errorProgressClassName,
      closeButton: <FontAwesomeIcon icon={faX as IconProp} className={classes.icon} />,
      ...options,
    })

  return { regularToast, successToast, errorToast, progressToast }
}

export default useToast
