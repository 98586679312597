import { logEvent, LogEventParams } from '../../api/events/log';

export default function useLogEvent() {
  async function log(options: LogEventParams) {
    try {
      await logEvent(options);
    } catch (e) {
      console.log(e);
    }
  }
  return { log };
}
