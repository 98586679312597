import { ResponsiveLine } from '@nivo/line'
import { Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { ReportsAnalyticsObject } from '../../api/reports/reports-analytics'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import SvgGradient from '../../common/components/ScoreMeter/SvgGradient'

export default function ReportsChart({ data }: { data: ReportsAnalyticsObject }) {
  const theme = useTheme()
  const { t } = useTranslation()
  const classes = useStyles()

  const lastReported = data.reported_last_30_days ?? []
  const lastMalicious = data.malicious_last_30_days ?? []

  const formattedData = [
    {
      id: t('responseConsole.analytics.total'),
      data: Object.entries(lastReported).map(([key, value], index) => ({
        x: moment()
          .subtract(lastReported.length - index, 'days')
          .format('DD/MM/YY'),
        y: value,
      })),
    },
    {
      id: t('responseConsole.analytics.malicious'),
      data: Object.entries(lastMalicious).map(([key, value], index) => ({
        x: moment()
          .subtract(lastMalicious.length - index, 'days')
          .format('DD/MM/YY'),
        y: value,
      })),
    },
  ]

  function idToColor(id: string) {
    switch (id) {
      case 'total malicious':
        return theme.palette.error.main
      case 'total reports':
        return theme.palette.blueDianne[600]
      default:
        return theme.palette.cyan[500]
    }
  }

  const gradients = ['url(#secondaryGradient)', 'url(#errorGradient)']

  const lineColors = formattedData.map((dataSet, index) => {
    const noData = dataSet.data.filter((point) => point.y > 0).length <= 0
    return noData ? idToColor(dataSet.id.toLowerCase()) : gradients[index]
  })

  return (
    <>
      <SvgGradient cssId={'errorGradient'} startColor={'#cb2d3e'} endColor={'#ef473a'} rotate={0} />
      <SvgGradient
        cssId={'secondaryGradient'}
        startColor={'#464E93'}
        endColor={'rgba(173, 89, 164, 0.441435)'}
        rotate={90}
      />
      <ResponsiveLine
        data={formattedData}
        curve={'monotoneX'}
        enableGridX={false}
        enableGridY={false}
        margin={{ top: 20, right: 0, bottom: 40, left: 0 }}
        xScale={{ type: 'point' }}
        yScale={{
          type: 'linear',
          min: 'auto',
          max: 'auto',
          stacked: false,
          reverse: false,
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        axisLeft={null}
        enableSlices={'x'}
        sliceTooltip={({ slice }) => {
          return (
            <div className={classes.tooltipContainer}>
              <div className={classes.tooltip} style={{ justifyContent: 'center' }}>
                <Typography variant={'subtitle2'} color={'inherit'}>
                  {slice.points[0].data.x}
                </Typography>
              </div>
              {slice.points.map((point, key) => {
                return (
                  <div className={classes.tooltip} key={key}>
                    <div
                      style={{
                        height: 10,
                        width: 10,
                        background: idToColor(point.serieId.toString().toLowerCase()),
                        marginRight: 8,
                      }}
                    />
                    <Typography variant={'subtitle2'} color={'inherit'}>
                      {point.serieId}: {point.data.y}
                    </Typography>
                  </div>
                )
              })}
            </div>
          )
        }}
        colors={lineColors}
        lineWidth={4}
        enablePoints={false}
        isInteractive
        useMesh
      />
    </>
  )
}

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    tooltipContainer: {
      padding: theme.spacing(1, 2),
      borderRadius: theme.shape.borderRadius,
    },
    tooltip: {
      background: theme.palette.background.secondaryGradient,
      borderRadius: 100,
      display: 'flex',
      flex: '1 1 auto',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: theme.spacing(1, 2),
      marginBottom: theme.spacing(1),
      color: theme.palette.common.white,
      fontWeight: 700,
      boxShadow: theme.shadows[6],
      boxSizing: 'border-box',
      border: `1px solid rgba(255,255,255,0.6)`,
    },
  })
)
