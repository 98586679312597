import axios from 'axios';
import { API_URL } from '../constants';
import { useMutation, useQueryCache } from 'react-query';
import { ServerError } from '../types';

export type NewAdParams = {
  ad_client_id: string;
  ad_client_secret: string;
  tenant: string;
};

export async function createActiveDirectory(params: NewAdParams): Promise<string> {
  const url = `${API_URL}/active_directory/new`;
  const { data } = await axios.post(url, params, { withCredentials: true });
  return data.data;
}

export function useCreateActiveDirectory() {
  const queryCache = useQueryCache();
  return useMutation<string, ServerError, NewAdParams>(createActiveDirectory, {
    throwOnError: true,
    onSettled: () => {
      queryCache.invalidateQueries('active_directory');
    },
  });
}
