import { FC } from 'react'
import { Box, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'

import CywarenessLogo from '@/assets/images/logo-dark.svg'

interface HeaderProps {
  logo: string
}

const Header: FC<HeaderProps> = ({ logo = CywarenessLogo }) => {
  const { t } = useTranslation()

  return (
    <HeaderContainer>
      <LogoContainer>
        <Box
          component="img"
          src={logo}
          alt={'Logo'}
          height={73}
          onError={(e) => {
            const target = e.target as HTMLImageElement
            target.src = CywarenessLogo
          }}
        />
      </LogoContainer>
      <TitleContainer>{t('feedbackPage.cywarenessFeedback') as string}</TitleContainer>
    </HeaderContainer>
  )
}

const HeaderContainer = styled(Box)(() => ({
  width: '100%',
  textAlign: 'center',
  padding: '10px 0',
}))

const LogoContainer = styled(Box)(() => ({
  width: '100%',
  textAlign: 'center',
  padding: 10,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'center',
}))

const TitleContainer = styled(Box)(() => ({
  width: '100%',
  textAlign: 'center',
  padding: '10px 0',
  fontSize: 26,
  fontWeight: 600,
  fontFamily: 'Montserrat',
  backgroundColor: '#05DBF2',
}))

export default Header
