export const typography: {
  fontFamily: string
  fontWeightExtraBold: number
  fontWeightBold: number
  fontWeightSemiBold: number
  fontWeightMedium: number
  fontWeightRegular: number
  fontWeightLight: number
} = {
  fontFamily: ['Montserrat', 'Nunito', 'Roboto', 'sans-serif'].join(','),
  // usuing the default MUI weights
  fontWeightExtraBold: 900,
  fontWeightBold: 700,
  fontWeightSemiBold: 600,
  fontWeightMedium: 500,
  fontWeightRegular: 400,
  fontWeightLight: 300,
}
