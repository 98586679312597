import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

import { API_URL } from '@/api/constants'

const deletePhishingSimulation = async (id: string | undefined) => {
  const url = `${API_URL}/phishing-simulations/${id}`
  const { data } = await axios.delete(url, { withCredentials: true })

  return data
}

export const usePhishingSimulationDelete = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (id: string | undefined) => {
      return await deletePhishingSimulation(id)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['phishing-simulations'],
      })
    },
  })
}
