import { useState, useEffect } from 'react'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { Avatar, Box, Button, Container, Grid, Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { useUnlockUser } from '../../api/auth/unlock-user'
import LogoIcon from '../../assets/images/logo.svg'
import { useUrlQuery } from '../../common/hooks/useUrlQuery'

export default function UserUnlock() {
  const { t } = useTranslation()
  const classes = useStyles()
  const navigateTo = useNavigate()
  const urlQuery = useUrlQuery()
  const [unlockUser] = useUnlockUser()
  const [isUserUnlocked, setUserUnlocked] = useState(false)

  const token = urlQuery.get('token')

  useEffect(() => {
    const doUserUnlock = async (token) => {
      setUserUnlocked((await unlockUser(token)) ?? false)
    }
    if (token) {
      doUserUnlock(token)
    }
  }, [unlockUser, token])

  useEffect(() => {
    console.log(token)
    token && unlockUser(token)
  }, [unlockUser, token])

  return (
    <Container component="main" maxWidth="xs">
      <Paper className={classes.root}>
        <Avatar className={classes.avatar}>
          <img alt={'Cywareness Logo'} src={LogoIcon} height={24} width={22} />
        </Avatar>
        <div className={classes.textContainer}>
          <Typography gutterBottom variant={'h5'}>
            {t(isUserUnlocked ? 'unlockUser.unlocked' : 'unlockUser.locked')}
          </Typography>
          <Typography style={{ textAlign: 'center' }} variant={'subtitle1'}>
            {t(isUserUnlocked ? 'unlockUser.successMessage' : 'unlockUser.errorMessage')}
          </Typography>
        </div>
        <Button
          className={classes.button}
          fullWidth
          onClick={() => navigateTo('/login')}
          variant={'contained'}
          color={'primary'}
        >
          {t('unlockUser.toLogin')}
        </Button>
        <Grid container>
          <Grid item xs>
            <Link to="/reset-password">{t('login.forgotPassword')}</Link>
          </Grid>
          <Grid item>
            <Link to="/signup">{t('login.noAccount')}</Link>
          </Grid>
        </Grid>
      </Paper>
      <Box mt={8}>
        <Typography variant="body2" color="textSecondary" align="center">
          {t('login.copyright') + ' © '}
          Cywareness {new Date().getFullYear()}
          {'.'}
        </Typography>
      </Box>
    </Container>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: theme.spacing(12),
      padding: theme.spacing(4),
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: theme.spacing(2),
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.blueGray[900],
    },
    button: {
      marginBottom: theme.spacing(2),
    },
  })
)
