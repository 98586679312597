import React from "react";
import { Box, Typography } from "@mui/material";

const EmailPlaceholder = ({
  text = "",
  landingPage = false,
  isModal = false,
}) => {
  const isEmpty = !text || text === "";
  const emptyText = landingPage
    ? "Your Landing Page Will Be Displayed Here."
    : "Your Email Will Be Displayed Here";
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        zIndex={2}
        position="relative"
        textAlign="center"
        width="50%"
        color="white"
        marginTop="-70px"
      >
        <Typography
          style={isEmpty && isModal ? { fontSize: "40px" } : {}}
          variant="h6"
        >
          {text || emptyText}
        </Typography>
      </Box>
    </Box>
  );
};

export default EmailPlaceholder;
