import { Box, Typography, styled } from '@mui/material'
import { Dispatch, FC, SetStateAction } from 'react'

import { theme } from '@/theme/theme'
import PlanPickerCardSection from './PlanPickerCardSection'
import jsonData from '@/assets/pricingCalculatorSettings.json'

const PlanPickerCard: FC<{
  title: string
  color: string
  memberCost: number
  membersCount: number
  campaignsCount: number
  currentPlan: string | undefined
  currentSubscriptionTime: number | undefined
  setCurrentPlan: Dispatch<SetStateAction<string | undefined>>
  setCrrentSubscriptionTime: Dispatch<SetStateAction<number | undefined>>
}> = ({
  title,
  color,
  memberCost,
  membersCount,
  campaignsCount,
  currentPlan,
  currentSubscriptionTime,
  setCurrentPlan,
  setCrrentSubscriptionTime,
}) => {
  return (
    <StyledContainer>
      <StyledTitleBox color={color}>
        <Typography fontSize={20} fontWeight={700} textAlign="center" color={theme.palette.blueDianne[900]}>
          {title}
        </Typography>
      </StyledTitleBox>
      <PlanPickerCardSection
        year={jsonData.discounts.years.count[0]}
        cost={memberCost}
        discount={jsonData.discounts.years.discount[0]}
        membersCount={membersCount}
        campaignsCount={campaignsCount}
        relatedtPlan={title}
        setCurrentPlan={() => setCurrentPlan(title)}
        setCrrentSubscriptionTime={() => {
          setCrrentSubscriptionTime(jsonData.discounts.years.count[0])
        }}
        selected={currentPlan === title && currentSubscriptionTime === jsonData.discounts.years.count[0]}
      />
      <PlanPickerCardSection
        year={jsonData.discounts.years.count[1]}
        cost={memberCost}
        discount={jsonData.discounts.years.discount[1]}
        membersCount={membersCount}
        campaignsCount={campaignsCount}
        relatedtPlan={title}
        setCurrentPlan={() => setCurrentPlan(title)}
        setCrrentSubscriptionTime={() => {
          setCrrentSubscriptionTime(jsonData.discounts.years.count[1])
        }}
        selected={currentPlan === title && currentSubscriptionTime === jsonData.discounts.years.count[1]}
      />
      <PlanPickerCardSection
        year={jsonData.discounts.years.count[2]}
        cost={memberCost}
        discount={jsonData.discounts.years.discount[2]}
        membersCount={membersCount}
        campaignsCount={campaignsCount}
        relatedtPlan={title}
        setCurrentPlan={() => setCurrentPlan(title)}
        setCrrentSubscriptionTime={() => {
          setCrrentSubscriptionTime(jsonData.discounts.years.count[2])
        }}
        selected={currentPlan === title && currentSubscriptionTime === jsonData.discounts.years.count[2]}
      />
    </StyledContainer>
  )
}

const StyledContainer = styled(Box)(() => ({
  border: '1px solid',
  borderRadius: '20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  overflow: 'hidden',
}))

const StyledTitleBox = styled(Box)(({ color }: { color: string }) => ({
  background: color,
  width: '100%',
  height: '58px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))
export default PlanPickerCard
