import { Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'

import Megaphone from '@/assets/images/megaphone.png'
import Alert from '@/common/components/Alert/Alert'
// import useLottie from '@/common/hooks/useLottie'
// import campaignAlert from '@/assets/lottie/alert.json'

type UnfinishedAlertProps = {
  open: boolean
  handleClose: () => void
}

export default function UnfinishedAlert(props: UnfinishedAlertProps) {
  const classes = useStyles()
  const { t } = useTranslation()

  // const { LottieAnimation } = useLottie({
  //   animationData: campaignAlert,
  //   height: 200,
  //   width: 200,
  //   options: {
  //     loop: true,
  //     autoplay: true,
  //     animationData: campaignAlert,
  //     rendererSettings: {
  //       preserveAspectRatio: 'xMidYMid slice',
  //     },
  //   },
  // })

  return (
    <Alert
      {...props}
      closeOnClick={false}
      buttonLabel={t('trainingCampaignPage.alertDialogButton')}
      dialogContent={
        <div className={classes.root}>
          {/* <div className={classes.img}>{LottieAnimation}</div> */}
          <img src={Megaphone} className={classes.img} alt="megaphone icon" />
          <div className={classes.flex}>
            <div>
              <Typography gutterBottom variant="h6">
                {t('trainingCampaignPage.alertDialogTitle')}
              </Typography>
              <Typography variant="body1">{t('trainingCampaignPage.alertDialogMessage')}</Typography>
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                {t('trainingCampaignPage.alertDialogProgressLost')}
              </Typography>
            </div>
          </div>
        </div>
      }
    />
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: { paddingLeft: 160, paddingTop: theme.spacing(4) },
    flex: {
      display: 'flex',
      alignItems: 'center',
    },
    img: {
      position: 'absolute',
      left: 0,
      bottom: 0,
    },
  })
)
