import React, { Component } from "react";
import { Controlled as CodeMirror } from "react-codemirror2";
import "codemirror/lib/codemirror.css";
import "codemirror/addon/scroll/simplescrollbars.css";
import "codemirror/addon/scroll/simplescrollbars";
import "codemirror/addon/scroll/annotatescrollbar";
import "codemirror/addon/search/matchesonscrollbar";
import "codemirror/addon/search/matchesonscrollbar.css";
import "codemirror/addon/search/searchcursor";
import "codemirror/theme/material-palenight.css";
import { Editor } from "codemirror";
import "codemirror/mode/xml/xml";

type CodeEditorProps = {
  text: string;
  onChange?: (text) => void;
};

type CodeEditorState = {
  isMounted: boolean;
};

class CodeEditor extends Component<CodeEditorProps, CodeEditorState> {
  editor: Editor | null;
  constructor(props) {
    super(props);
    this.editor = null;
    this.state = {
      isMounted: false,
    };
  }

  render() {
    return (
      <CodeMirror
        value={this.props.text}
        options={{
          mode: "xml",
          theme: "material-palenight",
          scrollbarStyle: "overlay",
          lineWrapping: true,
        }}
        onBeforeChange={(editor, data, value) =>
          this.props.onChange ? this.props.onChange(value) : () => {}
        }
        onChange={() => {}}
        editorDidMount={(editor) => {
          this.editor = editor;
          this.setState({ isMounted: true });
        }}
        editorWillUnmount={() => {
          this.setState({ isMounted: false });
        }}
      />
    );
  }
}

export default CodeEditor;
