import axios from "axios";
import { API_URL } from "../constants";
import {
  useMutation,
  usePaginatedQuery,
  useQuery,
  useQueryCache,
} from "react-query";
import { ServerError } from "../types";

export type ReportObjectMin = {
  _id: string;
  reported_by: string;
  date_reported: number;
  is_phishing: boolean | null;
  subject: string;
  type: string;
};

export type ReportObjectFull = ReportObjectMin & {
  sender: string;
  reciepents: string[];
  overall_score: number;
  date_recieved: number;
  raw_html: string;
  headers: string[];
  is_simulated: boolean;
  findings: {
    [finding: string]: {
      title: string;
      description: string;
    };
  };
  links: {
    [domain: string]: number;
  };
  attachments: string[];
  client_id: string;
};

export type ReportsResult = {
  items: ReportObjectMin[];
  count: number;
};

export type AllReportsVariables = {
  startIndex: number;
  offset: number;
  startDate: number | null;
  endDate: number | null;
  sort_by: string;
  sort_order: "asc" | "desc" | "";
};

type useReportVariables = {
  _id: string;
};

const getAllReports = async (key: string[], params: any) => {
  const url = `${API_URL}/reports/all/`;
  const { data } = await axios.get(url, {
    params: { ...params },
    withCredentials: true,
  });
  return data?.data;
};

export const useAllReports = (variables: AllReportsVariables) => {
  return usePaginatedQuery<ReportsResult, ServerError>(
    ["reports", { ...variables }],
    getAllReports
  );
};

const getReportById = async (key: string, _id: string) => {
  if (_id) {
    const url = `${API_URL}/reports/${_id}`;
    const { data } = await axios.get(url, { withCredentials: true });
    return data.data;
  } else {
    return undefined;
  }
};

export const useReport = ({ _id }: useReportVariables) => {
  return useQuery<ReportObjectFull, ServerError>(
    ["reports", _id],
    getReportById
  );
};

type ChangeReportVariables = {
  is_phishing: boolean | null;
  _id: string;
};

export const changeReportStatus = async ({
  is_phishing,
  _id,
}: ChangeReportVariables) => {
  const url = `${API_URL}/reports/status/${_id}`;
  const { data } = await axios.post(
    url,
    { is_phishing },
    { withCredentials: true }
  );
  return data.data;
};

export const useChangeReportStatus = () => {
  const queryCache = useQueryCache();
  return useMutation<string, ServerError, ChangeReportVariables>(
    changeReportStatus,
    {
      onMutate: ({ is_phishing, _id }) => {
        queryCache.cancelQueries(["reports", _id]);
        const previousReports = queryCache.getQueryData(["reports", _id]);
        //@ts-ignore
        queryCache.setQueryData(["reports", _id], (old: ReportObjectFull) => ({
          ...old,
          is_phishing,
        }));
        return () => queryCache.setQueryData("reports", previousReports);
      },
      //@ts-ignore
      onError: (err, variables, rollback) => rollback(),
      onSettled: (data, error, { _id }) => {
        queryCache.invalidateQueries(["reports", _id]);
      },
    }
  );
};
