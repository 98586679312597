import {
  IntegrationType,
  MicrosoftEntraIdIntegrationType,
  googleIntegrationType,
  ldapIntegrationType,
} from '@/api/integrations/integrations'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import dayjs from 'dayjs'
import utc from 'dayjs-plugin-utc'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import IntegrationTypeIcon from '@/assets/images/Integrations/FormStages/IntegrationType'
import Modal from '@/common/components/Modal'
import Stepper from '@/common/components/Stepper'
import Step from '@/common/components/Stepper/Step'
import GoogleWorkspaceIntegration from './GoogleWorkspaceIntegration'
import MicrosoftEntraIdIntegration from './MicrosoftEntraIdIntegration'
import OnPremiseIntegration from './OnPremiseIntegration'
import IntegrationPicker from './components/IntegrationPicker'

dayjs.extend(utc)

export type IntegrationCardType = {
  label: string
  value: string
  icon: string
  footnote?: string
  disabled?: boolean
}

interface IntegrationModalProps {
  title: string
  isOpen: boolean
  onClose: () => void
  integration: IntegrationType | undefined
  integrationTypes: IntegrationCardType[]
  existIntegrationsTypes: string[]
}
const IntegrationModal: FC<IntegrationModalProps> = ({
  title,
  isOpen,
  onClose,
  integration,
  integrationTypes,
  existIntegrationsTypes,
}) => {
  const { t } = useTranslation()
  const [type, setType] = useState(integration?.type || '')

  const resetTypeOnClose = () => {
    onClose()
  }

  useEffect(() => {
    setType(integration?.type || '')
  }, [integration])
  return (
    <Modal title={title} isOpen={isOpen} onClose={resetTypeOnClose} withCloseButton>
      <Box sx={{ p: '16px', overflowY: 'auto', maxHeight: '90vh' }}>
        <Stepper>
          <Step label={t('settings.integrations.chooseIntegration')} icon={IntegrationTypeIcon} active={!integration}>
            <IntegrationPicker
              name="integration-type"
              value={type}
              integrationTypes={integrationTypes}
              existIntegrationsTypes={existIntegrationsTypes}
              onChange={(_, value) => setType(value)}
            />
          </Step>
          {type === 'ldap' && (
            <OnPremiseIntegration
              integration={integration?.type === 'ldap' ? (integration as ldapIntegrationType) : undefined}
              integrationTypes={integrationTypes}
              onClose={resetTypeOnClose}
            />
          )}
          {type === 'google-workspace' && (
            <GoogleWorkspaceIntegration
              integration={
                integration?.type === 'google-workspace' ? (integration as googleIntegrationType) : undefined
              }
              integrationTypes={integrationTypes}
              onClose={resetTypeOnClose}
            />
          )}
          {type === 'ms-graph' && (
            <MicrosoftEntraIdIntegration
              integration={
                integration?.type === 'ms-graph' ? (integration as MicrosoftEntraIdIntegrationType) : undefined
              }
              integrationTypes={integrationTypes}
              onClose={resetTypeOnClose}
            />
          )}
        </Stepper>
      </Box>
    </Modal>
  )
}

export const FormSection = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
}))

export const StyledButtonWrapper = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  transform: 'translate(0,-24px)',
  marginBottom: '-24px',
}))

export default IntegrationModal
