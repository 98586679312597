import { Tabs, styled } from '@mui/material'

const MainTabs = styled(Tabs)(() => ({
  position: 'relative',
  top: '-3px',
  background: 'transparent',
  width: 'fit-content',
  boxShadow: '0px 0px 0px 0px lightgray',
  padding: '0px',
  margin: '0px',
  borderRadius: '15px 15px 0px 0px',
  border: 'none',
  '.MuiTabs-scroller': {
    padding: '0px',
  },
  '.MuiTabs-indicator': {
    height: '100px',
    backgroundColor: '#05DBF2',
    top: '0px',
    zIndex: '-1',
  },
}))

export default MainTabs
