export const MICRO_TRAINING = {
  "1": [
    {
      title: "Number Sequence",
      description:
        "Numbers in an email are usually referral numbers used to identify the information contained in the email. These numbers are usually generated by a program and seem to be random. If your number has an ascending or descending sequence this can indicate a phishing email.",
      command:
        "If a number is in the email, replace the number with one that is sequential.",
    },
    {
      title: "Suspicious Link",
      description:
        "The link should not be associated with the business it represents. The link does not correspond to the domain with the business name, which suggests malicious intent.",
      command:
        "If the email contains a link, change the text in the <a> tag to a text that is unrelated to the email content without changing the link. this should still be semi-related to the cotnext of the email",
    },
  ],
  "2": [
    {
      title: "Improper Characters",
      description:
        "In order to bypass filters put in place by your email provider that are meant to protect you from phishing emails, sometimes attackers will replace letters with similar looking letters to try avoid being flagged as suspicious.",
      command:
        "Replace a single letter in the email with a similar looking letter, for example, replace 'o' with '0'. Do not be limited to the example I have set. DO NOT change any links",
    },
    {
      title: "URL Error",
      description:
        "The sender of the email doesn't match the URL that you are being sent to. This can indicate the attacker is trying to trick you by posing as someone they are not.",
      command:
        "If the email is from a company, change the <a> tag text to a different company's URL, DO NOT change the link. Make it sound similar to the company in the email.",
    },
    {
      title: "Suspicious Footer",
      description:
        "By law, companies are required to provide their full address in the email. In this email, we see no address in the email footer.",
      command: "Add a blank footer to the email, with NO information in it.",
    },
    {
      title: "Informal Text",
      description:
        "This is a very informal way to write. Large corporations generally have a review process in place before anything is sent out to the public.",
      command: "Change the email signature or the greeting to be informal.",
    },
  ],
  "3": [
    {
      title: "Email Signature",
      description:
        "The sender's actual identity does not match the information provided in the email signature, indicating that the attacker is trying to deceive you by impersonating someone else.",
      command:
        "Change the name in the email signature to a different name that is unrelated.",
    },
    {
      title: "Suspicious Greeting",
      description:
        "A greeting from an organization will generally be more personal, this means greeting you by your name. Impersonal greeting can be an indicator of a phishing email, although not necessarily.",
      command: "Chane the greeting to an impersonal one.",
    },
    {
      title: "Invalid Phone Number",
      description:
        "The phone number isn't valid or looks suspicious. This is done to bypass filters that may be put in place by your email service provider.",
      command:
        "Use a contact phone number in the email that is from a country code that does not exist",
    },
  ],
  "4": [
    {
      title: "Spelling Mistakes",
      description:
        "Spelling mistakes are a common indicator of phishing emails. Large corporations usually have a review process prior to sending anything to the public. This is likely because the sender is not native to the language in which the email was sent, or a way to avoid phishing filters that your email provider has.",
      command: "Misspell a word in the email, for example.",
    },
    {
      title: "Grammatical Error",
      description:
        "Grammatical errors are a common indicator of a phishing email. This is most probably due to the sender not being native in the language in which the email was sent. Large corporations generally have a review process in place before anything is sent out to the public.",
      command: "Use a grammatically incorrect sentence in the email.",
    },
    {
      title: "Incorrect Company Name",
      description:
        "Often, attackers use wrong or misspelled company names. This is an attempt to bypass phishing filters that will give them a higher chance of successfully reaching their target.",
      command:
        "If a company name is mentioned in the email, use a different company name.",
    },
    {
      title: "Incorrect Address",
      description:
        "In order to not be affiliated with a certain organization, an attacker will add fake information as to not get flagged by phishing filters that may be in place.",
      command:
        "Use an address in the email footer that doesn't exist, but sounds like it could be real.",
    },
  ],
};
