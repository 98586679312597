import axios from 'axios'
import { API_URL } from '../constants'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const resendAdminInvite = async (admin_id: string) => {
  const url = `${API_URL}/admins/resend-invite/`
  const { data } = await axios.post(url, { admin_id: admin_id }, { withCredentials: true })
  return data
}

export const useResendAdminInvite = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: resendAdminInvite,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['admins'],
      })
    },
  })
}
