import axios from 'axios'
import { useQueryCache } from 'react-query'
import { useMutation } from '@tanstack/react-query'

import { API_URL } from '../constants'
import { AcceptTermsRequest } from '@/types/terms'

const acceptTerms = async (acceptTermsRequest: AcceptTermsRequest) => {
  const url = `${API_URL}/terms/accept`
  const { data } = await axios.post(url, acceptTermsRequest, { withCredentials: true })
  return data
}

const useAcceptTerms = () => {
  const queryCache = useQueryCache()
  return useMutation({
    mutationFn: acceptTerms,
    onSuccess: () => {
      queryCache.invalidateQueries('user')
    },
  })
}

export { useAcceptTerms }
