import React, { useEffect } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Popper,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

import { useAiSimulationContext } from "../state";

export const AppDesignSelection = () => {
  const { designedEmail, setDesignedEmail } = useAiSimulationContext();

  useEffect(() => {
    if (!designedEmail) {
      setDesignedEmail(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const CustomPopper = (props) => (
    <Popper
      {...props}
      modifiers={[
        {
          name: "offset",
          options: {
            offset: [0, 30],
          },
        },
      ]}
    />
  );

  return (
    <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
      <FormControl
        fullWidth
        variant="outlined"
        size="small"
        sx={{ "& fieldset": { borderRadius: "10px" } }}
      >
        <InputLabel htmlFor="style-select">Style</InputLabel>
        <Select
          id="style-select"
          value={designedEmail}
          onChange={(e) => {
            const isDesigned =
              e.target.value === "true" || e.target.value === true;
            setDesignedEmail(isDesigned);
          }}
          label="Style"
        >
          <MenuItem value={true}>
            <Tooltip
              title="Visually designed phishing emails for apps"
              placement="bottom-start"
              PopperComponent={CustomPopper}
            >
              <div>Designed</div>
            </Tooltip>
          </MenuItem>
          <MenuItem value={false}>
            <Tooltip
              title="Text-base phishing emails for apps"
              placement="bottom-start"
              PopperComponent={CustomPopper}
            >
              <div>Textual</div>
            </Tooltip>
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default AppDesignSelection;
