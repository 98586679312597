import axios from "axios";
import { API_URL } from "../constants";
import { useMutation } from "react-query";
import { ServerError } from "../types";

type UploadResponse = {
  url: string;
  resource_type: "video" | "image" | "raw";
  duration?: number;
};

export async function uploadFile(file) {
  const url = `${API_URL}/assets/upload`;
  const formData = new FormData();
  formData.append("files", file);
  const { data } = await axios.post(url, formData, {
    withCredentials: true,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return data.data;
}

export function useUpload() {
  return useMutation<UploadResponse[], ServerError, File>(uploadFile);
}
