import React from "react";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { useParams } from "react-router-dom";
import { useChangeReportStatus, useReport } from "../../api/reports/reports";
import { Button, Grid, Paper, Typography } from "@mui/material";
import LoadingContainer from "../../common/components/LoadingContainer/LoadingContainer";
import NoResultsContainer from "../../common/components/NoResultsContainer/NoResultsContainer";
import { useTranslation } from "react-i18next";
import ReportDetails from "./ReportDetails";
import ReportFindings from "./ReportFindings";
import ReportCalendar from "./ReportCalendar";
import ReportHeaders from "./ReportHeaders";
import { theme } from "../../theme/theme";

export default function ResponseConsoleReport() {
  const { t } = useTranslation();
  const { _id } = useParams();
  const { data, isLoading } = useReport({ _id });
  const [changeReportStatus] = useChangeReportStatus();
  const status: "safe" | "pending" | "malicious" =
    data?.is_phishing === false
      ? "safe"
      : data?.is_phishing
      ? "malicious"
      : "pending";
  const classes = useStyles({ status });

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <div className={classes.flex}>
          <Typography gutterBottom variant={"button"}>
            {t("reportPage.reportPage")}: #{_id}
          </Typography>
        </div>
        <div className={classes.buttonsContainer}>
          <Button
            disabled={!data}
            onClick={() => changeReportStatus({ is_phishing: false, _id: _id })}
            className={classes.safeButton}
            variant={"contained"}
          >
            {t("reportPage.details.safe")}
          </Button>
          <Button
            disabled={!data}
            onClick={() => changeReportStatus({ is_phishing: true, _id: _id })}
            className={classes.badButton}
            variant={"contained"}
          >
            {t("reportPage.details.malicious")}
          </Button>
        </div>
      </div>

      {isLoading ? (
        <LoadingContainer />
      ) : data ? (
        <Grid container spacing={0} justifyContent={"space-between"}>
          <Grid container spacing={0} justifyContent={"space-between"}>
            <Grid item sm={6} xs={12} container spacing={2}>
              <Grid
                item
                xs={12}
                style={{ paddingTop: theme.spacing(3), paddingBottom: 0 }}
              >
                <Paper className={classes.nestedRow}>
                  <ReportDetails data={data} />
                </Paper>
              </Grid>
              <Grid item xs={12} style={{ paddingTop: theme.spacing(1) }}>
                <Paper className={classes.nestedRow}>
                  <Typography gutterBottom variant={"button"}>
                    {t("reportPage.headers", {
                      value: Object.keys(data.headers).length ?? 0,
                    })}
                  </Typography>
                  <ReportHeaders data={data.headers} />
                </Paper>
              </Grid>
            </Grid>
            <Grid item sm={6} xs={12} className={classes.calendarContainerRow}>
              <Paper className={classes.nestedCalendarRow}>
                <Typography gutterBottom variant={"button"}>
                  {t("reportPage.calendarTitle")}
                </Typography>
                <div className={classes.reportCalendarContainer}>
                  <ReportCalendar data={data} />
                </div>
              </Paper>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <div className={classes.findingsContainer}>
              <ReportFindings data={data} />
            </div>
          </Grid>
        </Grid>
      ) : (
        <NoResultsContainer text={"reportPage.noResults"} />
      )}
    </div>
  );
}

const useStyles = makeStyles<
  Theme,
  { status: "safe" | "pending" | "malicious" }
>((theme) =>
  createStyles({
    root: { height: "100%", width: "100%", padding: theme.spacing(0, 2, 4) },
    titleContainer: {
      color: theme.palette.text.secondary,
      display: "flex",
      justifyContent: "space-between",
      marginBottom: theme.spacing(1),
    },
    flex: {
      display: "flex",
      alignItems: "center",
    },
    reportCalendarContainer: {
      margin: "16px auto",
      maxHeight: 500,
      maxWidth: 500,
      border: "1.5px solid rgba(0,0,0,0.1)",
      borderRadius: "15px",
    },
    nestedRow: { height: 250, padding: theme.spacing(1, 2, 3), maxHeight: 250 },
    calendarContainerRow: { height: "auto", padding: theme.spacing(1, 2, 3) },
    nestedCalendarRow: { height: "auto", padding: theme.spacing(1, 2, 5) },

    scoreContainer: {
      height: 200,
      width: "100%",
      position: "relative",
    },
    buttonsContainer: {
      display: "flex",
      alignItems: "center",
    },
    safeButton: {
      height: 32,
      background: theme.palette.background.successGradient,
      opacity: ({ status }) => (status === "safe" ? 1 : 0.5),
      width: 110,
      marginRight: theme.spacing(2),
      color: theme.palette.common.white,
      transition: "opacity 0.2s ease-in-out",
      "&:hover": {
        opacity: 1,
      },
    },
    badButton: {
      height: 32,
      background: theme.palette.background.errorGradient,
      opacity: ({ status }) => (status === "malicious" ? 1 : 0.5),
      width: 110,
      color: theme.palette.common.white,
      transition: "opacity 0.2s ease-in-out",
      "&:hover": {
        opacity: 1,
      },
    },
    findingsContainer: {
      margin: theme.spacing(2, 0),
    },
  })
);
