import { FC, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { Button, CircularProgress, Grid, Typography } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation, faCircleCheck } from '@fortawesome/pro-light-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import { useRedeemPromoCode } from '@/api/profile/redeem-promo-code'
import { AppliedPromoConfigurations } from '@/types/promoCodes'
import { StyledTextField, FieldWrapper } from './ConfigurationsStyledComponents'

const RedeemPromoCode: FC = () => {
  const { t } = useTranslation()
  const [promoCode, setPromoCode] = useState('')
  const [promoCodeError, setPromoCodeError] = useState(false)
  const [redeemedCofiguration, setRedeemedCofiguration] = useState<AppliedPromoConfigurations | null>(null)
  const { mutateAsync: redeemPromoCode, isPending, isSuccess } = useRedeemPromoCode()

  const handleRedeemPromoCode = async () => {
    if (promoCode) {
      try {
        const response = await redeemPromoCode({ code: promoCode })
        setPromoCode('')
        setRedeemedCofiguration(response.configurations)
      } catch (e) {
        setPromoCodeError(true)
      }
    }
  }

  return (
    <Grid container mt={2} mb={2}>
      <Typography gutterBottom variant={'h6'}>
        {t('settings.adminDetails.promoCode.promoCode')}
      </Typography>
      {promoCodeError ? (
        <FieldWrapper>
          <FontAwesomeIcon
            icon={faTriangleExclamation as IconProp}
            color="red"
            fontSize={25}
            style={{ marginRight: '10px' }}
          />
          <Typography>
            <Trans i18nKey="settings.adminDetails.promoCode.errors.failedToRedeem" components={{ 1: <strong /> }} />
          </Typography>
        </FieldWrapper>
      ) : isSuccess ? (
        <FieldWrapper>
          <FontAwesomeIcon
            icon={faCircleCheck as IconProp}
            color="green"
            fontSize={25}
            style={{ marginRight: '10px' }}
          />
          <Typography>
            <Trans
              i18nKey="settings.adminDetails.promoCode.success.redeemed"
              values={{
                campaignsQuota: redeemedCofiguration?.campaigns_quota,
                usersQuota: redeemedCofiguration?.users_quota,
              }}
              components={{ 1: <strong /> }}
            />
          </Typography>
        </FieldWrapper>
      ) : null}
      <StyledTextField
        size="small"
        type="text"
        label={t('settings.adminDetails.promoCode.promoCodeLabel')}
        value={promoCode}
        name="currentPassword"
        placeholder={t('settings.adminDetails.promoCode.promoCodeLabel')}
        onChange={(value) => {
          setPromoCodeError(false)
          setPromoCode(value.target.value)
        }}
        error={promoCodeError}
      />
      <Button sx={{ width: '100%' }} onClick={handleRedeemPromoCode} disabled={!promoCode} variant="contained">
        {isPending ? <CircularProgress size={20} /> : t('settings.adminDetails.promoCode.redeem')}
      </Button>
    </Grid>
  )
}

export default RedeemPromoCode
