import { theme } from '@/theme/theme'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faX } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, IconButton, Modal as MuiModal, styled, Typography } from '@mui/material'
import { FC, ReactNode } from 'react'

type ModalSize = 'small' | 'medium' | 'large'

const modalSizeMap: Record<ModalSize, string> = {
  small: '432px',
  medium: '640px',
  large: '1216px',
}
interface ModalProps {
  title: string
  isOpen: boolean
  onClose: () => void
  size?: ModalSize
  withCloseButton?: boolean
  children: ReactNode
}

const Modal: FC<ModalProps> = ({ title, isOpen, onClose, children, size = 'medium', withCloseButton }) => {
  return (
    <MuiModal open={isOpen} onClose={onClose}>
      <StyledModalContent width={modalSizeMap[size]}>
        <StyledTitleBar>
          {withCloseButton && (
            <IconButton aria-label="close" onClick={onClose}>
              <FontAwesomeIcon icon={faX as IconProp} color={theme.palette.cyan[500]} fontSize={14} />
            </IconButton>
          )}
          <Typography variant="h6" component="h2" paddingLeft={withCloseButton ? 0 : 2}>
            {title}
          </Typography>
        </StyledTitleBar>
        <StyledContent>{children}</StyledContent>
      </StyledModalContent>
    </MuiModal>
  )
}

const StyledModalContent = styled(Box)(({ width }: { width: string }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: width,
  backgroundColor: 'white',
  p: 4,
  '&:focus-visible': {
    outline: 'none',
  },
  borderRadius: '10px',
}))

const StyledTitleBar = styled(Box)(() => ({
  display: 'flex',
  color: theme.palette.cyan[500],
  alignItems: 'center',
  padding: '10px 16px',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: 'normal',
  background: theme.palette.black,
  borderRadius: '10px 10px 0px 0px',
}))

const StyledContent = styled(Box)(() => ({
  maxHeight: '90vh',
  overflowY: 'auto',
  margin: '24px 8px 24px 32px',
  padding: '0 24px 0 0', //scroll bar offset
}))

export default Modal
