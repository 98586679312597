import axios from "axios";
import { API_URL } from "../constants";
import { useMutation, useQueryCache } from "react-query";
import { ServerError } from "../types";

export async function executeIntegration(integrationId: string) {
  const url = `${API_URL}/integrations/${integrationId}/execute`;
  const { data } = await axios.post(
    url,
    {},
    {
      withCredentials: true,
    }
  );
  return data;
}

export default function useExecuteIntegration() {
  const queryCache = useQueryCache();
  return useMutation<any, ServerError, string>(executeIntegration, {
    throwOnError: true,
    onSettled: () => {
      queryCache.invalidateQueries("integrations");
    },
  });
}
