import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Checkbox, Typography } from '@mui/material'
import { Dispatch, SetStateAction, useState } from 'react'

import { theme } from '@/theme/theme'
import { StyledTextField } from '../StyledTextField'
import { withCommas } from '../utils'

const CellContent = ({
  content,
  cost,
  special,
  setTailoredCost,
  setVideosCount,
  setVideosCost,
}: {
  content: any
  cost: number
  special?: number
  setTailoredCost: Dispatch<SetStateAction<number>>
  setVideosCount: Dispatch<SetStateAction<number>>
  setVideosCost: Dispatch<SetStateAction<number>>
}) => {
  const [checked, setChecked] = useState(false)
  const [value, setValue] = useState<number | undefined>()
  if (typeof content === 'boolean' && content) {
    return (
      <>
        <FontAwesomeIcon icon={faCheck as IconProp} fontSize={16}></FontAwesomeIcon>
        {special === 2 && (
          <Typography fontSize={12} fontWeight={700} color={theme.palette.cyan[500]}>
            **
          </Typography>
        )}
      </>
    )
  } else if (typeof content === 'string') {
    return (
      <Typography fontSize={12} fontWeight={700}>
        {content}
      </Typography>
    )
  } else if (typeof content === 'object') {
    if (content.checkbox !== undefined) {
      return (
        <Box display="flex" flexDirection="column" alignItems={'center'}>
          <Checkbox
            checked={checked}
            onClick={() => {
              if (checked) {
                setTailoredCost((e) => e - cost)
              } else {
                setTailoredCost((e) => e + cost)
              }
              setChecked((e: boolean) => !e)
            }}
          />
          <Typography fontSize={12}>
            <b>${cost}/</b>member
            {special === 2 && (
              <Typography component="span" fontSize={12} fontWeight={700} color={theme.palette.cyan[500]}>
                **
              </Typography>
            )}
          </Typography>
        </Box>
      )
    } else if (content.textfield !== undefined) {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            padding: theme.spacing(2, 0, 2),
          }}>
          <Typography fontSize={12} sx={{ width: 135 }} textAlign={'center'}>
            Select number of tailored videos
          </Typography>
          <StyledTextField
            placeholder="Enter Amount"
            value={value}
            onChange={(v) => {
              const newValue = Number(v.target.value)
              if (newValue > -1) {
                setVideosCount((prev) => {
                  return prev - (value || 0) + newValue
                })
                setVideosCost((prev) => {
                  return prev - (value || 0) * cost + newValue * cost
                })
                setValue(newValue)
              } else {
                setValue(0)
              }
            }}
            sx={{
              width: '135px ',
              margin: theme.spacing(1),
              fontSize: '12px',
              '& .MuiInputBase-input': {
                textAlign: 'center',
                fontSize: '14px',
                padding: '2px 8px',
              },
            }}
            type="number"
          />
          <Typography fontSize={12}>
            <b>${withCommas(cost)} /</b> video
          </Typography>
        </Box>
      )
    }
    return <></>
  }
  return <></>
}

export default CellContent
