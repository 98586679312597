import { Box, Slider, Typography } from '@mui/material'
import { Dispatch, FC, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import json from '@/assets/pricingCalculatorSettings.json'
import { theme } from '@/theme/theme'
import { StyledTextField } from './StyledTextField'
const discountData = json.discounts.campaigns

const CampaignsCountField: FC<{
  campaignsCount: number
  setCampaignsCount: Dispatch<SetStateAction<number>>
}> = ({ campaignsCount, setCampaignsCount }) => {
  const { t } = useTranslation()

  const marks = discountData.count.map((count: number) => {
    return {
      value: count,
      label: (
        <Typography fontSize={12} display="flex" flexDirection={'column'} alignItems={'center'} textAlign={'center'}>
          <b>{count}</b>
          {t('pricingCalculator.campaignsCount.campaigns')}
          <br />
          {t('pricingCalculator.campaignsCount.perYear')}
          {discountData.recommended && discountData.recommended === count && (
            <span
              style={{
                background: theme.palette.cyan[500],
                fontSize: 10,
                padding: theme.spacing(0.1, 1),
                borderRadius: '6px',
                marginTop: theme.spacing(0.5),
              }}>
              {t('pricingCalculator.campaignsCount.recommended')}
            </span>
          )}
        </Typography>
      ),
    }
  })
  return (
    <Box
      sx={{
        paddingBottom: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <Typography fontSize={20} fontWeight={400} margin={theme.spacing(-4, 0, 2)}>
        {t('pricingCalculator.campaignsCount.liteRequired')}
      </Typography>
      <StyledTextField
        label="Number of Campaigns"
        required
        type="number"
        value={campaignsCount}
        onChange={(e) => {
          const count = Number(e.target.value)
          if (count > 2) setCampaignsCount(count)
          else setCampaignsCount(3)
        }}
      />
      <Typography fontSize={12} fontWeight={400} color={theme.palette.grey[500]}>
        {t('pricingCalculator.campaignsCount.minimumCampaigns')}
      </Typography>
      <Box sx={{ width: '1100px', marginTop: theme.spacing(4) }}>
        <Slider
          value={campaignsCount || 3}
          marks={marks}
          valueLabelDisplay="off"
          min={0}
          max={28}
          sx={{
            margin: '20px 50px',
            pointerEvents: 'none',
            '& .MuiSlider-thumb': {
              width: 5,
              height: 5,
              background: theme.palette.cyan[500],
              '&:before': {
                boxShadow: 'none',
              },
              '&:hover': {
                boxShadow: 'none',
              },
              '&.Mui-active': {
                boxShadow: 'none',
              },
            },
            ' .MuiSlider-track': {
              background: theme.palette.cyan[500],
              border: `1px solid ${theme.palette.grey[400]}`,
            },
            ' .MuiSlider-rail': {
              background: theme.palette.cyan[50],
              border: `1px solid ${theme.palette.grey[400]}`,
            },
            ' .MuiSlider-mark': {
              background: theme.palette.grey[400],
            },
            '& .MuiSlider-valueLabel': {
              backgroundColor: 'primary.main',
              borderRadius: '10px',
              background: theme.palette.blueDianne[900],
              fontSize: 12,
            },
          }}
        />
      </Box>
    </Box>
  )
}
export default CampaignsCountField
