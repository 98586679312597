import { useMemo } from 'react'
import { Theme, useTheme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { ResponsiveLine } from '@nivo/line'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import SvgGradient from '../../../common/components/ScoreMeter/SvgGradient'
import moment from 'moment'

export type UserPerformanceChartData = {
  id: string
  data: {
    x: string
    y: number
  }[]
}

type PerformanceChartProps = {
  data: UserPerformanceChartData[]
}
export default function UserPerformanceChart({ data }: PerformanceChartProps) {
  const theme = useTheme()
  const { t } = useTranslation()
  const classes = useStyles()

  const formattedData: UserPerformanceChartData[] = useMemo(() => {
    return data
      ? data.map(({ id, data }) => ({
          id,
          data: data.map((dataPoint) => ({
            y: dataPoint.y,
            x: moment(dataPoint.x, 'MM-DD-YYYY').format('MMM DD'),
          })),
        }))
      : []
  }, [data])

  function idToColor(id: string) {
    switch (id) {
      case 'failed':
        return theme.palette.error.main
      case 'passed':
        return theme.palette.success.main
      default:
        return theme.palette.cyan[500]
    }
  }

  const gradients = ['url(#failedGradient)', 'url(#passedGradient)']
  const lineColors = formattedData.map((dataSet, index) => {
    const noData = dataSet.data.filter((point) => point.y > 0).length <= 0
    return noData ? idToColor(dataSet.id.toLowerCase()) : gradients[index]
  })

  return (
    <>
      <SvgGradient cssId={'failedGradient'} startColor={'#FF7979'} endColor={'#E34E81'} rotate={45} />
      <SvgGradient cssId={'passedGradient'} startColor={'#3EB7E5'} endColor={'#1EE0B7'} rotate={45} />
      <ResponsiveLine
        data={formattedData}
        curve={'monotoneX'}
        enableGridX={false}
        enableGridY={false}
        margin={{ top: 10, right: 32, bottom: 72, left: 32 }}
        xScale={{ type: 'point' }}
        yScale={{
          type: 'linear',
          min: 'auto',
          max: 'auto',
          stacked: false,
          reverse: false,
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: 'bottom',
          tickSize: 0,
          tickPadding: 16,
          tickRotation: 0,
          legendOffset: 36,
          legendPosition: 'middle',
        }}
        axisLeft={null}
        enableSlices={'x'}
        lineWidth={4}
        enablePoints={false}
        sliceTooltip={({ slice }) => {
          return (
            <div className={classes.tooltipContainer}>
              {slice.points.map((point) => {
                return (
                  <div className={classes.tooltip}>
                    <div
                      style={{
                        height: 10,
                        width: 10,
                        background: idToColor(point.serieId.toString().toLowerCase()),
                        marginRight: 8,
                      }}
                    />
                    <Typography variant={'caption'}>
                      {t(`userPerformanceChart.${point.serieId}`)}: {point.data.y}
                    </Typography>
                  </div>
                )
              })}
            </div>
          )
        }}
        colors={lineColors}
        pointSize={8}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabel="y"
        pointLabelYOffset={-12}
        isInteractive
        useMesh
      />
    </>
  )
}

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    tooltipContainer: {
      padding: theme.spacing(1, 2),
      borderRadius: theme.shape.borderRadius,
    },
    tooltip: {
      background: theme.palette.background.secondaryGradient,
      borderRadius: 100,
      display: 'flex',
      flex: '1 1 auto',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: theme.spacing(1, 2),
      marginBottom: theme.spacing(1),
      color: theme.palette.common.white,
      fontWeight: 700,
      boxShadow: theme.shadows[6],
      boxSizing: 'border-box',
      border: `1px solid rgba(255,255,255,0.6)`,
    },
  })
)
