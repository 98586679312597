export const sanitizeString = (input: string | undefined | null): string => {
  if (
    !input ||
    input.trim() === "" ||
    input === "undefined" ||
    input === "null"
  ) {
    return "";
  }
  return input;
};
