import axios from 'axios'
import { API_URL } from '../constants'
import { QueryConfig, useQuery } from 'react-query'
import { ServerError } from '../types'

export type IntegrationType = {
  id: string
  name: string
  type: string
  status: string
  is_running: boolean
  last_execution_status: string | null
  last_execution_timestamp: string | null
  last_successful_execution_timestamp: string | null
  next_execution_timestamp: string
  created_by: string
  created_at: string
  updated_at: string
}

type LDAPConfiguration = {
  domain: string
  schedule: string
  users_dn: string
  user_import_status: string
  default_user_office: string
  department_office_map: Record<string, string>
  department_group_map: Record<string, string>
}

type GoogleConfiguration = {
  schedule: string
  user_import_status: string
  default_user_office: string
  groups_map: Record<string, string>
}

type LDAPAuthentication = {
  host: string
  port: number
  use_ssl: boolean
  user: string
}

type GoogleAuthentication = {
  token: string
  refresh_token: string
}

type MicrosoftEnraIdAuthentication = {
  tenantId: string
  clientSecret: string
  clientId: string
}
export type ldapIntegrationType = IntegrationType & {
  configuration: LDAPConfiguration
  authentication: LDAPAuthentication
}

export type googleIntegrationType = IntegrationType & {
  configuration: GoogleConfiguration
  authentication: GoogleAuthentication
}

export type MicrosoftEntraIdIntegrationType = IntegrationType & {
  configuration: GoogleConfiguration
  authentication: MicrosoftEnraIdAuthentication
}

const getIntegrations = async () => {
  const url = `${API_URL}/integrations/`

  const { data } = await axios.get(url, { withCredentials: true })
  return data
}

export default function useIntegrations(options?: QueryConfig<any>) {
  return useQuery<(ldapIntegrationType | googleIntegrationType)[], ServerError>(
    'integrations',
    getIntegrations,
    options
  )
}
