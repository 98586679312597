import i18n from 'i18next'

import { languageCodeByName } from '@/assets/locales/utils'
import {
  PhishingSimulationRequest,
  PhishingSimulationState,
  PhishingSimulationVector,
  Sender,
} from '@/types/phishingSimulations'
import { AiSimulationContextType } from '../state'
import { generateScreenshots } from './generateScreenshots'

function getSenderName(state: AiSimulationContextType): string {
  if (state.simulationType === 'app') {
    const brand = state.appName.toLowerCase().replace(/\s/g, '').trim()
    return brand
  }
  return state.senderName.toLowerCase().replace(/\s/g, '').trim()
}

const getSender = (state: AiSimulationContextType): Sender => {
  const senderName = getSenderName(state)
  return {
    name: senderName,
    mailbox: `no-reply`,
    subdomain: `${senderName}`,
  }
}

const convertHtmlToPlainText = (html: string): string => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')

  let result: string[] = []

  doc.body.childNodes.forEach((node: ChildNode) => {
    if (node.nodeType === Node.TEXT_NODE) {
      const textContent = (node as Text).textContent?.trim() || ''
      if (textContent) {
        result.push(textContent)
      }
    } else if (node.nodeType === Node.ELEMENT_NODE && node.nodeName === 'P') {
      if (node instanceof HTMLElement) {
        let nodeText = node.textContent || ''

        const anchor = node.querySelector('a')
        if (anchor && anchor.textContent) {
          nodeText = nodeText.replace(anchor.textContent, `{{link_click_url}}`)
        }

        if (nodeText.trim()) {
          result.push(nodeText.trim())
        }
      }
    }
  })

  return result.join('\n').trim()
}

const parsePlaceholders = (html: string): string[] => {
  const pattern = /\{\{([^}]+)\}\}/g
  const params = []
  let match

  while ((match = pattern.exec(html)) !== null) {
    params.push(match[1])
  }

  return params
}

export const transformStateToDB = async (state: AiSimulationContextType): Promise<PhishingSimulationRequest> => {
  const {
    simulationName,
    subjectOutput,
    bodyOutput,
    vectorType,
    landingPageOutput,
    difficultyLevel,
    topics,
    language,
  } = state
  const sender = getSender(state)
  const coverImages = await generateScreenshots(vectorType, bodyOutput, landingPageOutput)
  const langCode = languageCodeByName(language)

  return {
    name: simulationName,
    subject: subjectOutput,
    sender: sender,
    html_contents: {
      message: vectorType === 'sms' ? convertHtmlToPlainText(bodyOutput) : bodyOutput,
      landing_page: landingPageOutput,
    },
    placeholders: {
      message: parsePlaceholders(bodyOutput),
      landing_page: parsePlaceholders(landingPageOutput),
    },
    phishing_indicators: {
      message: [
        {
          selector: '#sender',
          difficulty: difficultyLevel,
          title: {
            [langCode]: i18n.t('contentLibrary.findings.messageTitle'),
          },
          description: {
            [langCode]: i18n.t('contentLibrary.findings.messageDescription'),
          },
        },
        ...state.findings.map((finding, index) => ({
          selector: `#finding${index + 1}`,
          difficulty: difficultyLevel,
          title: {
            [langCode]: finding.title,
          },
          description: { [langCode]: finding.description },
        })),
      ],
      landing_page: [],
    },
    languages: {
      message: [langCode],
      landing_page: [langCode],
    },
    cover_images: coverImages,
    vectors: [vectorType as PhishingSimulationVector],
    tags: topics,
    ai_generated: true,
    is_public: false,
    difficulties: [difficultyLevel],
    state: PhishingSimulationState.Published,
  }
}
