import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'

type HumanizedScoreProps = {
  score: number
}

export default function HumanizedScore({ score }: HumanizedScoreProps): any {
  const theme = useTheme()
  const { t } = useTranslation()

  var humanizedScoreText = ''
  var humanizedScoreColor = ''

  if (score < 0) {
    humanizedScoreText = `-`
    humanizedScoreColor = theme.palette.text.primary
  } else if (score >= 0 && score <= 25) {
    humanizedScoreText = `${t('humanizedScore.bad')}`
    humanizedScoreColor = theme.palette.red[500]
  } else if (score > 25 && score <= 50) {
    humanizedScoreText = `${t('humanizedScore.low')}`
    humanizedScoreColor = theme.palette.orange[400]
  } else if (score > 50 && score <= 75) {
    humanizedScoreText = `${t('humanizedScore.average')}`
    humanizedScoreColor = theme.palette.amber[300]
  } else if (score > 75) {
    humanizedScoreText = `${t('humanizedScore.excellent')}`
    humanizedScoreColor = theme.palette.green[500]
  }

  return (
    <div style={{ textAlign: 'center', margin: 'auto' }}>
      {score < 0 ? null : <span style={{ color: humanizedScoreColor, fontSize: 20 }}>•</span>}
      <span style={{ marginLeft: '5px', fontSize: 14 }}>{humanizedScoreText}</span>
    </div>
  )
}
