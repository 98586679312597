import axios from "axios";
import { API_URL } from "../constants";
import { useQuery } from "react-query";
import { ServerError } from "../types";

export async function getLanguages() {
  const url = `${API_URL}/education_modules/languages`;
  const { data } = await axios.get(url, { withCredentials: true });
  return data.data;
}

export function useVideoLanguages() {
  return useQuery<string[], ServerError>(
    ["education_modules", "languages"],
    getLanguages
  );
}
