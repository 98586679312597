import { theme } from '@/theme/theme'
import { Box, Typography, styled } from '@mui/material'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useDroppable } from '@dnd-kit/core'

const DroppablePackageCard: FC<{ index: number }> = ({ index }) => {
  const { t } = useTranslation()
  const { setNodeRef, isOver } = useDroppable({
    id: index,
  })
  return (
    <StyledEmptyCardBox ref={setNodeRef}>
      <StyledEmptyCardTypography isOver={isOver} align="center">
        {t('simulationPackage.addToPackage')}
      </StyledEmptyCardTypography>
    </StyledEmptyCardBox>
  )
}

const StyledEmptyCardTypography = styled(Typography)(({ isOver }: { isOver: boolean }) => ({
  padding: isOver ? '40px 80px 50px' : '50px 90px',
  fontSize: '20px',
  color: isOver ? theme.palette.blueDianne[400] : theme.palette.white,
  border: 'solid ' + theme.palette.blueDianne[400] + '20',
  borderWidth: isOver ? '10px' : '0px',
  '&:hover': {
    borderWidth: '10px',
    color: theme.palette.blueDianne[400],
    padding: '40px 80px 50px',
  },
}))

const StyledEmptyCardBox = styled(Box)(() => ({
  zIndex: 1,
  margin: '8px 0px',
  width: '100%',
  minHeight: '200px',
  height: '200px',
  background: theme.palette.blueGray[700] + '90',
  boxShadow: 'inset 0 8px 10px' + theme.palette.cyan[500] + '90',
  overflow: 'hidden',
  borderRadius: '15px',
}))

export default DroppablePackageCard
