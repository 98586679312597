import axios from 'axios'
import { API_URL } from '@/api/constants'
import { DefinedInitialDataOptions, useQuery } from '@tanstack/react-query'
import { CampaignMicrotraining } from '@/types/campaigns'

const getCampaignMicrotraining = async (token: string | undefined) => {
  const { data } = await axios.get(`${API_URL}/campaigns/${token}/microtraining/`)

  return data
}

export const useCampaignMicrotraining = (
  token: string | undefined,
  options?: Partial<DefinedInitialDataOptions<CampaignMicrotraining>>
) => {
  return useQuery<CampaignMicrotraining>({
    queryKey: ['campaign', 'microtraining', token],
    queryFn: () => getCampaignMicrotraining(token),
    enabled: !!token,
    ...options,
  })
}
