import { useEffect, useState } from 'react'

export enum DeviceType {
  Mobile = 'Mobile',
  Tablet = 'Tablet',
  Desktop = 'Desktop',
}

const useDeviceDetection = () => {
  const [device, setDevice] = useState('')

  useEffect(() => {
    const handleDeviceDetection = () => {
      const userAgent = navigator.userAgent.toLowerCase()
      const isMobile = /iphone|ipad|ipod|android|blackberry|windows phone/g.test(userAgent)
      const isTablet = /(ipad|tablet|playbook|silk)|(android(?!.*mobile))/g.test(userAgent)

      if (isMobile) {
        setDevice(DeviceType.Mobile)
      } else if (isTablet) {
        setDevice(DeviceType.Tablet)
      } else {
        setDevice(DeviceType.Desktop)
      }
    }

    handleDeviceDetection()
    window.addEventListener('resize', handleDeviceDetection)

    return () => {
      window.removeEventListener('resize', handleDeviceDetection)
    }
  }, [])

  return device
}

export default useDeviceDetection
