import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import questionIcon from '@/assets/images/popup/question.svg'
import Popup from '@/common/components/Popup'

const InactiveUsersModal: FC<{ open: boolean; onClose: () => void; onConfirm: () => void }> = ({
  open,
  onClose,
  onConfirm,
}) => {
  const { t } = useTranslation()
  //Add to translations
  return (
    <Popup
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
      buttonLabels={{
        confirm: 'Proceed',
        cancel: 'Cancel',
      }}
      icon={questionIcon}>
      <h4>Hey Wait!!</h4>
      <p>
        Are you sure you want to sync those members as <b>inactive</b>?
      </p>
      <p>Inactive members can’t be used for anything!</p>
    </Popup>
  )
}

export default InactiveUsersModal
