import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { faCirclePlay } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

type CustomPlayButtonProps = {
  onClick: () => void
  visible: boolean
}
export default function CustomPlayButton({ onClick, visible }: CustomPlayButtonProps) {
  const classes = useStyles()
  return visible ? (
    <div className={classes.root} onClick={onClick}>
      <FontAwesomeIcon icon={faCirclePlay as IconProp} fontSize={80} />
    </div>
  ) : null
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 1000,
      cursor: 'pointer',
    },
  })
)
