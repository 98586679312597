import { Box, styled } from '@mui/material'

const MainTabPage = styled(Box)(() => ({
  padding: '50px',
  borderRadius: '0px 15px 15px 15px',
  boxShadow: '-1px -0px 3px 0.1px lightgray',
  position: 'relative',
  top: '-3px',
  background: 'white',
}))

export default MainTabPage
