import axios from 'axios'
import { API_URL } from '../constants'
import { useMutation } from 'react-query'
import { ServerError } from '../types'

type ChangePasswordVariables = {
  new_password: string
  current_password: string
}

const changePassword = async (params: ChangePasswordVariables) => {
  await axios.put(`${API_URL}/profile/password/`, params, {
    withCredentials: true,
  })
}

export const useChangePassword = () => {
  return useMutation<void, ServerError, ChangePasswordVariables>(changePassword, { throwOnError: true })
}
