export enum PhishingSimulationState {
  Published = 'published',
  Archived = 'archived',
  Draft = 'draft',
}

export enum PhishingSimulationVector {
  Email = 'email',
  SMS = 'sms',
  WhatsApp = 'whatsapp',
}

export type PhishingIndicator = {
  selector: string
  difficulty: number
  title: {
    [key: string]: string
  }
  description: {
    [key: string]: string
  }
}

type EmailContent = {
  message: string
  landing_page: string
}

export type Sender = {
  name: string
  mailbox: string
  subdomain: string
}

type Placeholders = {
  message: string[]
  landing_page: string[]
}

export type Languages = {
  message: string[]
  landing_page: string[]
}

export type CoverImages = {
  message: string
  landing_page: string
  microtraining: string
}

export type PhishingSimulationRequest = {
  name: string
  subject: string
  sender: Sender
  html_contents: EmailContent
  placeholders: Placeholders
  phishing_indicators: {
    message: PhishingIndicator[]
    landing_page: PhishingIndicator[]
  }
  languages: Languages
  cover_images: CoverImages
  vectors: PhishingSimulationVector[]
  tags: string[]
  ai_generated: boolean
  is_public: boolean
  difficulties: number[]
  state: PhishingSimulationState
}

export type PhishingSimulation = PhishingSimulationRequest & {
  id: string
  organization_id: string
  image_cloudinary_ids: {
    message: string
    landing_page: string
    micro_training: string
  }
  created_by: string
  created_at: string
  updated_at: string
  template_thumbnail?: string
}

//type PhishingSimulationListItem same as PhishingSimulation

export type PhishingSimulationPackageRequest = {
  name: string
  simulation_ids: string[]
}

//TODO : extended.
export type PhishingSimulationPackage = PhishingSimulationPackageRequest & {
  id: string
  organization_id: string
  simulations: PhishingSimulation[]
  created_by: string
  created_at: string
  updated_at: string
}

export type PhishingSimulationPackageExtended = PhishingSimulationPackage & {
  languages?: Languages
  difficulties?: number[]
  tags?: string[]
  vectors?: PhishingSimulationVector[]
}

export type PhishingSimulationPackageUpdateRequest = {
  id: string
  params: Partial<PhishingSimulationPackageRequest>
}
