import axios from "axios";
import { useMutation } from "react-query";
import { API_URL } from "../constants";
import { ServerError } from "../types";

type VerifyTokenParams = {
  username: string;
  token: string;
};

type VerifyTokenResponse = {};

async function verifyToken(
  params: VerifyTokenParams
): Promise<VerifyTokenResponse> {
  const url = `${API_URL}/auth/verify-password-reset-token/`;
  const { data } = await axios.post(url, { ...params });
  return data;
}

export function useVerifyPasswordResetToken(callback) {
  return useMutation<VerifyTokenResponse, ServerError, VerifyTokenParams>(
    verifyToken,
    {
      onSuccess: callback,
    }
  );
}
