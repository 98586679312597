import { useMemo, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { Grid, Paper, Typography, ButtonBase, Button, CircularProgress } from '@mui/material'
import { faUserGroup } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { GroupFilters } from './ConnectedActiveDirectory'
import { useTranslation } from 'react-i18next'

type AdStatsProps = {
  onFilterSelect: (selectedFilter: GroupFilters) => void
  selectedFilter: GroupFilters
  onboardAllUsers: () => void
  data: {
    total_users: number
    onboarded: number
    offboarded: number
  }
}

export default function AdStats({ onFilterSelect, selectedFilter, data, onboardAllUsers }: AdStatsProps) {
  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()
  const [onboardingInProgress, setOnboardingInProgress] = useState(false)
  const stats = useMemo<{ label: string; count: number; filter: GroupFilters }[]>(
    () => [
      {
        label: 'Total Members',
        count: data.total_users,
        filter: undefined,
      },
      {
        label: 'Users Onboarded',
        count: data.onboarded,
        filter: 'onboarded',
      },
      {
        label: 'Users Offboard',
        count: data.offboarded,
        filter: 'offboarded',
      },
    ],
    [data]
  )

  function handleSelectFilter(filter: GroupFilters) {
    onFilterSelect(filter)
  }

  async function handleOnboardAll() {
    setOnboardingInProgress(true)
    await onboardAllUsers()
    setOnboardingInProgress(false)
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        {stats.map((stat) => (
          <Grid key={stat.label} item>
            <ButtonBase className={classes.button} onClick={() => handleSelectFilter(stat.filter)}>
              <Paper
                className={classes.paper}
                style={{
                  borderColor: selectedFilter === stat.filter ? theme.palette.blueGray[900] : undefined,
                }}
              >
                <FontAwesomeIcon icon={faUserGroup as IconProp} className={classes.icon} />
                <Typography variant={'subtitle2'} className={classes.label}>
                  {stat.label}
                </Typography>
                <Typography variant={'subtitle2'}>{stat.count} </Typography>
              </Paper>
            </ButtonBase>
          </Grid>
        ))}
      </Grid>
      {data.offboarded > 0 ? (
        <Button onClick={handleOnboardAll} className={classes.onboardButton} variant={'contained'} color={'primary'}>
          {onboardingInProgress ? (
            <CircularProgress color={'inherit'} size={24} />
          ) : (
            t('users.activeDirectory.onboardAll')
          )}
        </Button>
      ) : null}
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: theme.spacing(2, 0),
      display: 'flex',
      justifyContent: 'space-between',
    },
    button: {
      borderRadius: theme.shape.borderRadius,
    },
    paper: {
      padding: theme.spacing(1, 2),
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    label: {
      marginRight: theme.spacing(4),
    },
    onboardButton: {
      flexShrink: 0,
      minWidth: 120,
    },
  })
)
