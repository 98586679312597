import { FC, useState } from 'react'

import useLocalStorage from '@/common/hooks/useLocalStorage'
import { AiWizard } from './AiWizard'
import EmailBuilder from './EmailBuilder'
import IntroScreen from './IntroScreen'
import LandingPageBuilder from './LandingPageBuilder'
import SummaryPage from './SummaryPage'
import { AiSimulationProvider } from './state'

export interface RouteParams {
  id: string
}

const AiSimulationBuilder: FC = () => {
  const [skipPreview, setSkipPreview] = useState(false)
  const { getLocalValue } = useLocalStorage()

  if (!getLocalValue('skipAiVideo') && !skipPreview) {
    return <IntroScreen setSkipPreview={setSkipPreview} />
  }

  return (
    <AiSimulationProvider>
      <AiWizard>
        <EmailBuilder />
        <LandingPageBuilder />
        <SummaryPage />
      </AiWizard>
    </AiSimulationProvider>
  )
}

export default AiSimulationBuilder
