export enum SortOrder {
  asc = 'asc',
  desc = 'desc',
}

export type PagedResult<T> = {
  total: number
  results: T[]
}

export type PagedQuery<SortBy> = {
  skip?: number
  limit?: number
  sort_by?: SortBy
  sort_order?: SortOrder
}

type Language = {
  name: string
  nativeName: string
}

export type LanguagesByCode = {
  [code: string]: Language
}

export type RowDataItem = {
  _id: string
  [key: string]: any
}
