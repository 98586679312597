import TableRow, { TableRowOwnProps } from '@mui/material/TableRow'
import makeStyles from '@mui/styles/makeStyles'
import TableCell from '@mui/material/TableCell'
import { ElementType, FC, useContext, useEffect } from 'react'
import { useDraggable } from '@dnd-kit/core'
import { CommonProps } from '@mui/material/OverridableComponent'
import { JSX } from 'react/jsx-runtime'

import { PhishingSimulation } from '@/types/phishingSimulations'
import { ContentLibraryContext } from '../ContentLibrary'

export type DraggedSimualtionTableRowType = {
  original: PhishingSimulation
  getRowProps: () => JSX.IntrinsicAttributes & { component: ElementType<any> } & TableRowOwnProps &
    CommonProps &
    Omit<any, 'style' | 'children' | 'className' | 'classes' | 'hover' | 'selected' | 'sx'>
  cells: any[]
}
const DraggedSimualtionTableRow: FC<{ row: DraggedSimualtionTableRowType }> = ({ row }) => {
  const classes = useStyles()
  const { setDraggedSimulation, setActiveTableRow } = useContext(ContentLibraryContext)
  const { attributes, listeners, setNodeRef, active } = useDraggable({
    id: row.original.id + '_table',
  })

  useEffect(() => {
    if (active?.id === row.original.id + '_table') {
      setDraggedSimulation(row.original)
      setActiveTableRow(row)
    }
  }, [active])

  return (
    <TableRow
      ref={active?.id === row.original.id + '_table' && !row.original.simulations ? setNodeRef : null}
      {...listeners}
      {...attributes}
      className={classes.row + (row.original.disabled ? ' isDisabled' : '')}
      {...row.getRowProps()}
    >
      {row.cells.map((cell) => {
        const cellProps = cell.column?.cellProps || {}
        return (
          <TableCell {...cell.getCellProps()} {...cellProps}>
            {cell.render('Cell')}
          </TableCell>
        )
      })}
    </TableRow>
  )
}

const useStyles = makeStyles({
  table: {
    overflow: 'hidden',
    '& .MuiTableCell-head': {
      textAlign: 'center',
      '&:first-child': {
        paddingLeft: 15,
        textAlign: 'left',
      },
    },
    '& .MuiTableCell-body': {
      textAlign: 'center',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      '&:first-child': {
        textAlign: 'left',
      },
    },
  },
  row: {
    '&:hover': {
      backgroundColor: '#EFFCFE !important',
    },
    '&.isDisabled': {
      backgroundColor: '#f2f2f2',
      '&:hover': {
        backgroundColor: '#E0EDEF !important',
      },
    },
  },
})

export default DraggedSimualtionTableRow
