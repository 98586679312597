import { useEffect, useMemo, useState, Dispatch, SetStateAction } from 'react'
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import Autocomplete from '@mui/material/Autocomplete'
import { useGroups } from '@/api/groups/groups'
import { useOffices } from '@/api/offices/offices'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import ChipsArray from '@/common/components/ChipsArray/ChipsArray'
import { ActiveDirectoryGroup, ActiveDirectoryUser, ActiveDirectoryBoardRequest } from '@/api/active-directory/users'
import { useAddUsersToGroup } from '@/api/groups/add-users-to-group'
import useToast from '@/common/hooks/useToast'
import { OnboardUsersResponse } from '@/api/active-directory/onboard'
import LinearProgress from '@mui/material/LinearProgress'

type OnboardUsersModalProps = {
  buttonClass?: string
  handleOnboard: (users: ActiveDirectoryBoardRequest) => Promise<OnboardUsersResponse>
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  onClose?: () => void
  users: ActiveDirectoryUser[]
}

type GroupItem = { label: string; _id: string }
type OfficeItem = { label: string; _id: string }

export default function OnboardUsersModal({
  buttonClass,
  handleOnboard,
  users,
  open,
  setOpen,
  onClose = () => {},
}: OnboardUsersModalProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { data: groups } = useGroups()
  const { data: offices } = useOffices()
  const [addUsersToGroup] = useAddUsersToGroup()
  const { errorToast } = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const [groupsQuery, setGroupsQuery] = useState('')
  const [officesQuery, setOfficesQuery] = useState('')
  const [groupsForAllUsers, setGroupsForAllUsers] = useState<GroupItem[]>([])
  const [officeForAllUsers, setOfficeForAllUsers] = useState<OfficeItem>()

  const [newAdGroups, setNewAdGroups] = useState<GroupItem[]>([])
  const [existingADGroups, setExistingADGroups] = useState<GroupItem[]>([])
  const [unexistingADGroups, setUnexistingADGroups] = useState<GroupItem[]>([])

  const userCount = users.length

  const groupsList = useMemo(() => {
    return groups
      ? groups
          .filter((g) => g.name.toLowerCase().includes(groupsQuery.toLowerCase()))
          .map((group) => ({ label: group.name, _id: group._id }))
      : []
  }, [groups, groupsQuery])

  const officesList = useMemo(() => {
    return offices
      ? offices.array
          .filter((o) => o.name.toLowerCase().includes(officesQuery.toLowerCase()))
          .map((office) => ({ label: office.name, _id: office._id }))
      : []
  }, [offices, officesQuery])

  const indexedGroupsByName = useMemo(() => {
    const groupsMap: { [id: string]: GroupItem } = {}
    ;(groupsList || []).forEach((group) => {
      groupsMap[group.label.toLowerCase()] = group
    })
    return groupsMap
  }, [groupsList])

  const usersByAdGroup = useMemo(() => {
    const adGroupsMap: {
      [groupName: string]: { userIds: string[]; group: ActiveDirectoryGroup }
    } = {}
    users.forEach((user) => {
      ;(user?.member_of || []).forEach((group) => {
        if (adGroupsMap[group.displayName.toLowerCase()]) {
          adGroupsMap[group.displayName.toLowerCase()].userIds = [
            ...adGroupsMap[group.displayName.toLowerCase()].userIds,
            user.external_id,
          ]
        } else {
          adGroupsMap[group.displayName.toLowerCase()] = {
            userIds: [user.external_id],
            group,
          }
        }
      })
    })
    return adGroupsMap
  }, [users])

  function addOfficeToUsers(users: ActiveDirectoryUser[]) {
    const updatedUsers = users.map((user) => {
      return {
        ...user,
        office_id: officeForAllUsers?._id || '',
      }
    })
    return updatedUsers
  }

  useEffect(() => {
    const newGroups = Object.entries(usersByAdGroup).map(([key, value]) => {
      if (indexedGroupsByName[key]) {
        return indexedGroupsByName[key]
      } else {
        return { label: value.group.displayName, _id: '' }
      }
    })
    setNewAdGroups(newGroups)
    setExistingADGroups(newGroups.filter((group) => group._id))

    setUnexistingADGroups(newGroups.filter((group) => !group._id))
  }, [usersByAdGroup, indexedGroupsByName])

  const setDefaultOfficeForAllUsers = () => {
    if (offices?.array.length && !officeForAllUsers) {
      setOfficeForAllUsers({
        label: offices.array[0].name,
        _id: offices.array[0]._id,
      })
    }
  }

  useEffect(() => {
    setDefaultOfficeForAllUsers()
  }, [offices])

  function handleClose() {
    onClose?.()
    setGroupsForAllUsers([])
    setOpen(false)
  }

  function handleGroupsQueryChange(event, newInputValue) {
    setGroupsQuery(newInputValue)
  }

  function handleOfficesQueryChange(event, newInputValue) {
    setOfficesQuery(newInputValue)
  }

  function handleChange(event, newValue) {
    setGroupsForAllUsers(newValue)
  }
  function handleOfficesChange(event, newValue) {
    setOfficeForAllUsers(newValue)
  }

  const renderGroupsChips = (
    totalGroups: GroupItem[],
    targetGroups: GroupItem[],
    targetGroupChangeEvent,
    title: string
  ) => {
    if (targetGroups.length > 0) {
      return (
        <>
          <DialogContentText>
            {t(title, {
              count: targetGroups.length,
              total: totalGroups.length,
            })}
          </DialogContentText>
          <div className={classes.section}>
            <ChipsArray setChipData={targetGroupChangeEvent} chipData={targetGroups} />
          </div>
        </>
      )
    }
  }

  async function handleSumbit() {
    try {
      setIsLoading(true)
      // create users
      const updatedUsersWithOffice = addOfficeToUsers(users)
      const adUsersRequest: ActiveDirectoryBoardRequest = {
        users: updatedUsersWithOffice,
      }
      const newOnboardedUsers = await handleOnboard(adUsersRequest)
      const usersExternalToInternalIdMap: { [externalId: string]: string } = {}
      if (newOnboardedUsers) {
        for (const user of newOnboardedUsers) {
          usersExternalToInternalIdMap[user.external_id] = user._id
        }

        // create new non-existing AD groups
        // await Promise.all(
        //   newAdGroups
        //     .filter((group) => !group._id)
        //     .map((group) => {
        //       return createNewGroup({
        //         name: group.label,
        //         users: usersByAdGroup[group.label.toLowerCase()].userIds.map(
        //           (externalId) => usersExternalToInternalIdMap[externalId],
        //         ),
        //         offices: [],
        //         roles: [],
        //       });
        //     }),
        // );

        // Add users to existing groups
        // await Promise.all(
        //   newAdGroups
        //     .filter((group) => group._id)
        //     .map((group) => {
        //       return addUsersToGroup({
        //         users: usersByAdGroup[group.label.toLowerCase()].userIds.map(
        //           (externalId) => usersExternalToInternalIdMap[externalId],
        //         ),
        //         _id: group._id,
        //       });
        //     }),
        // );

        // Add users to existing groups
        await Promise.all(
          groupsForAllUsers.map((group) => {
            return addUsersToGroup({
              users: users.map((user) => usersExternalToInternalIdMap[user.external_id]),
              _id: group._id,
            })
          })
        )
      }
    } catch (e) {
      errorToast(t('users.activeDirectory.errors.failedToOnboard', { count: userCount }))
    } finally {
      setIsLoading(false)
      handleClose()
    }
  }

  const autoCompleteOptions = useMemo(() => {
    return groupsList.filter(
      (option) =>
        !newAdGroups
          .filter((group) => group._id)
          .map((group) => group._id)
          .includes(option._id)
    )
  }, [groupsList, newAdGroups])

  return (
    <>
      <Dialog
        disableEscapeKeyDown={isLoading}
        maxWidth={'sm'}
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="onboard-users-dialog"
        classes={{ paperFullWidth: classes.dialogRoot }}
      >
        <DialogTitle id="group-dialog-title">
          {t('users.activeDirectory.modal.onboard_user_count', {
            count: userCount,
          })}
        </DialogTitle>
        <DialogContent style={{ overflowY: 'hidden' }}>
          <DialogContentText>{t('users.activeDirectory.modal.instructions')}</DialogContentText>

          {renderGroupsChips(
            newAdGroups,
            existingADGroups,
            setExistingADGroups,
            'users.activeDirectory.modal.existingGroup_count'
          )}
          {renderGroupsChips(
            newAdGroups,
            unexistingADGroups,
            setUnexistingADGroups,
            'users.activeDirectory.modal.newGroup_count'
          )}

          <div className={classes.section}>
            <DialogContentText>{t('users.activeDirectory.modal.existingGroupInsturction')}</DialogContentText>
            <Autocomplete
              value={groupsForAllUsers}
              onChange={handleChange}
              onInputChange={handleGroupsQueryChange}
              multiple
              id="tags-filled"
              options={autoCompleteOptions}
              filterSelectedOptions
              getOptionLabel={(option) => option.label}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  return <Chip label={option.label} {...getTagProps({ index })} />
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={t('users.activeDirectory.modal.selectExistingGroups')}
                  placeholder={t('users.activeDirectory.modal.searchGroups')}
                />
              )}
            />
          </div>
          <div className={classes.section}>
            <DialogContentText>{t('users.activeDirectory.modal.officeInstruction')}</DialogContentText>
            <Autocomplete
              value={officeForAllUsers}
              onChange={handleOfficesChange}
              onBlur={setDefaultOfficeForAllUsers}
              onInputChange={handleOfficesQueryChange}
              multiple={false}
              id="tags-filled"
              options={officesList}
              filterSelectedOptions
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={t('users.activeDirectory.modal.selectOffices')}
                  placeholder={t('users.activeDirectory.modal.searchOffices')}
                  required={true}
                />
              )}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            {t('users.activeDirectory.modal.cancel')}
          </Button>
          <Button onClick={handleSumbit} variant="contained">
            {t('users.activeDirectory.modal.onboard')}
          </Button>
        </DialogActions>
        {isLoading && (
          <div className={classes.blocker}>
            <LinearProgress />
          </div>
        )}
      </Dialog>
    </>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    dialogRoot: {
      position: 'relative',
    },
    section: {
      margin: theme.spacing(2, 0),
    },
    blocker: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      background: 'rgba(0,0,0,0.45)',
    },
  })
)
