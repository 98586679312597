import axios from 'axios'
import { API_URL } from '../constants'
import { useQuery } from 'react-query'
import { ServerError } from '../types'
import { Group } from './groups'

export interface FullGroupManagerObject {
  _id: string
  first_name: string
  last_name: string
  username: string
  enabled: boolean
  phone_number: string
}
export interface FullGroupObject extends Group {
  managers: FullGroupManagerObject[]
}

export async function getGroupById(key: string, _id: string) {
  const url = `${API_URL}/groups/${_id}`
  const { data } = await axios.get(url, { withCredentials: true })
  return data.data
}

export function useGroup(_id: string) {
  return useQuery<FullGroupObject, ServerError>(['groups', _id], getGroupById)
}
