import { FC } from 'react'
import { useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { IconButton } from '@mui/material'
import { faHeart as heartIcon, faPenToSquare, faTrash } from '@fortawesome/pro-light-svg-icons'
import { faHeart as fullHeartIcon } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { EducationModule } from '@/api/education-modules/modules'
import { useAuth } from '@/context/Auth'

const useStyles = makeStyles((theme) =>
  createStyles({
    actionsContainer: { display: 'flex', alignItems: 'center' },
    button: { marginLeft: theme.spacing(1) },
  })
)

interface VideoPageActionsProps {
  video: EducationModule
  editModule: (video: EducationModule) => void
  deleteModule: (video: EducationModule) => void
  saveModule: (video: EducationModule) => void
  isSaved: boolean
}

const VideoPageActions: FC<VideoPageActionsProps> = ({ video, editModule, deleteModule, saveModule, isSaved }) => {
  const classes = useStyles()
  const theme = useTheme()
  const { user } = useAuth()
  const isLesson = video.additional_resources?.lesson_plan
  const isCustom = video.client_id === user?.current_client_id

  function handleLike() {
    saveModule(video)
  }

  function handleDelete() {
    deleteModule(video)
  }

  function handleEdit() {
    editModule(video)
  }

  return (
    <div className={classes.actionsContainer}>
      {isCustom && !isLesson ? (
        <IconButton
          disabled={video.client_id !== user?.current_client_id}
          className={classes.button}
          onClick={handleEdit}
          size="small"
          color={'inherit'}
        >
          <FontAwesomeIcon icon={faPenToSquare as IconProp} fontSize={22} />
        </IconButton>
      ) : (
        <></>
      )}

      <IconButton className={classes.button} onClick={handleLike} size="small" color={'inherit'}>
        <FontAwesomeIcon
          icon={(isSaved ? fullHeartIcon : heartIcon) as IconProp}
          fontSize={22}
          color={theme.palette.pink[500]}
        />
      </IconButton>

      {isCustom && !isLesson ? (
        <IconButton
          disabled={video.client_id !== user?.current_client_id}
          className={classes.button}
          onClick={handleDelete}
          size="small"
          color={'inherit'}
        >
          <FontAwesomeIcon icon={faTrash as IconProp} fontSize={22} />
        </IconButton>
      ) : (
        <></>
      )}
    </div>
  )
}

export default VideoPageActions
