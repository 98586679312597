import { LandingPageOptionProps } from "../../components/LandingPageTypeSelection";

export const SET_PAGE_INSTRUCTIONS = "SET_PAGE_INSTRUCTIONS";
export const SET_LANDING_PAGE_TYPE = "SET_LANDING_PAGE_TYPE";
export const RESET_LANDING_PAGE = "RESET_LANDING_PAGE";
export const SET_LANDING_PAGE_OUTPUT = "SET_LANDING_PAGE_OUTPUT";
export const SET_LANDING_PAGE_URL = "SET_LANDING_PAGE_URL";
export const SET_REDIRECT_OPTION = "SET_REDIRECT_OPTION";

export interface LandingPageState {
  pageInstructions: string;
  landingPageType: LandingPageOptionProps | null;
  landingPageOutput: string;
  landingPageUrl: string;
  redirectOption: string;
}

export type LandingPageAction =
  | { type: typeof SET_PAGE_INSTRUCTIONS; payload: string }
  | {
      type: typeof SET_LANDING_PAGE_TYPE;
      payload: LandingPageOptionProps | null;
    }
  | { type: typeof SET_LANDING_PAGE_OUTPUT; payload: string }
  | { type: typeof SET_REDIRECT_OPTION; payload: string }
  | { type: typeof SET_LANDING_PAGE_URL; payload: string }
  | { type: typeof RESET_LANDING_PAGE };
