import { Form, Formik } from 'formik'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
//@ts-ignore
import * as Yup from 'yup'

import { Profile } from '@/api/profile/edit'
import { useEditProfileEmailConfirm } from '@/api/profile/edit-email-confirm'
import PasswordField from '@/common/components/Inputs/PasswordField'
import TextField from '@/common/components/Inputs/TextFieldV2'
import PopupLarge from '@/common/components/PopupLarge'
import useToast from '@/common/hooks/useToast'
import { theme } from '@/theme/theme'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faArrowRight } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Button, Divider, Typography } from '@mui/material'

type EmailConfirmModalProps = {
  title: string
  open: boolean
  onClose: () => void
  onConfirm: (profilePayload: Partial<Profile>) => void
  state?: Partial<Profile> & { old_email: string }
}

const EmailConfirmModal = ({ title, open, onClose, onConfirm, state }: EmailConfirmModalProps) => {
  const { t } = useTranslation()
  const [confirmationSubmitted, setConfirmationSubmitted] = useState(false)
  const { errorToast } = useToast()

  const validationSchemaPassword = Yup.object().shape({
    current_password: Yup.string().required(t('settings.admins.emailConfirmModal.currentPasswordRequired')),
  })

  const validationSchemaToken = Yup.object().shape({
    token: Yup.string().required(t('settings.admins.emailConfirmModal.tokenRequired')),
  })

  const { mutateAsync: editProfileEmailConfirm } = useEditProfileEmailConfirm()

  const handleSubmit = async (values: { token: string }) => {
    if (!state) return

    await onConfirm({
      full_name: state.full_name,
      email: state.email,
      phone_number: state.phone_number,
      change_email_token: values.token,
    })
  }

  const handleEmailChangeConfirmation = async (values: { current_password: string }) => {
    if (!state) return

    try {
      await editProfileEmailConfirm({
        email: state.email,
        current_password: values.current_password,
      })

      setConfirmationSubmitted(true)
    } catch (error) {
      errorToast(t('settings.admins.emailConfirmModal.invalidPasswordError'))
    }
  }

  const handleClose = () => {
    setConfirmationSubmitted(false)
    onClose()
  }

  return (
    <PopupLarge open={open} title={title} onClose={handleClose}>
      <Formik
        onSubmit={handleEmailChangeConfirmation}
        initialValues={{
          current_password: '',
        }}
        validationSchema={validationSchemaPassword}>
        {({ isSubmitting }) => (
          <Form>
            <Box display="flex" flexDirection="column">
              <Typography fontWeight={theme.typography.fontWeightBold}>
                {t('settings.admins.emailConfirmModal.stepOne')}
              </Typography>
              <Typography>{t('settings.admins.emailConfirmModal.message1')}</Typography>
              <Box display="flex" justifyContent="space-around" gap={theme.spacing(1)} marginTop={2} width="100%">
                <TextField label="Old Email" name="old_email" disabled value={state?.old_email} disableFormik />
                <FontAwesomeIcon icon={faArrowRight as IconProp} style={{ marginTop: theme.spacing(1.5) }} />
                <TextField label="New Email" name="new_email" disabled value={state?.email} disableFormik />
              </Box>
              <br />
              <Trans
                i18nKey={'settings.admins.emailConfirmModal.message2'}
                components={{
                  1: <Typography fontWeight={theme.typography.fontWeightSemiBold} />,
                  2: <ul />,
                  3: <li />,
                }}
              />
              <PasswordField
                label={t('settings.configurationTab.resetPassword.currentPassword')}
                name="current_password"
              />
              {!confirmationSubmitted && (
                <Box display="flex" justifyContent="flex-end">
                  <Button variant="outlined" onClick={handleClose}>
                    {t('settings.admins.emailConfirmModal.cancelButton')}
                  </Button>
                  <LoadingButton type="submit" loading={isSubmitting} variant="contained">
                    {t('settings.admins.emailConfirmModal.continueButton')}
                  </LoadingButton>
                </Box>
              )}
            </Box>
          </Form>
        )}
      </Formik>

      {confirmationSubmitted && (
        <>
          <Divider sx={{ marginY: 2 }} />

          <Formik
            onSubmit={handleSubmit}
            initialValues={{
              token: '',
            }}
            validationSchema={validationSchemaToken}>
            {({ isSubmitting }) => (
              <Form>
                <Typography fontWeight={theme.typography.fontWeightBold} marginBottom={2}>
                  {t('settings.admins.emailConfirmModal.stepTwo')}
                </Typography>
                <p>
                  <Trans
                    i18nKey={'settings.admins.emailConfirmModal.confirmToken'}
                    values={{ admin_email: state?.old_email }}
                    components={{
                      1: <strong />,
                      2: <br />,
                    }}
                  />
                </p>
                <TextField label="Confirmation Token" name="token" />
                <Box display="flex" justifyContent="flex-end">
                  <Button variant="outlined" onClick={handleClose}>
                    {t('settings.admins.emailConfirmModal.cancelButton')}
                  </Button>
                  <LoadingButton type="submit" loading={isSubmitting} variant="contained">
                    {t('settings.admins.emailConfirmModal.confirmButton')}
                  </LoadingButton>
                </Box>
              </Form>
            )}
          </Formik>
        </>
      )}
    </PopupLarge>
  )
}

export default EmailConfirmModal
