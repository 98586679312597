import { Button, CircularProgress, Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { string } from 'yup'
import useForm, { Field } from '../../common/components/Form/useForm'
import Form from '../../common/components/Form/Form'
import { faCircleExclamation } from '@fortawesome/pro-light-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useParams, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useMFA } from '../../api/auth/mfa'

const fields: Field[] = [
  {
    name: 'token',
    label: 'mfa.form.code',
    validationRule: string().required('mfa.errors.missingCode'),
    type: 'text',
    required: true,
    autoFocus: true,
  },
]

export default function MFA() {
  const { t } = useTranslation()
  const classes = useStyles()
  const { username } = useParams()

  const [verifyToken, { isLoading, error }] = useMFA()

  function handleVerifyToken(formValues) {
    verifyToken({ ...formValues, username })
  }

  const { formProps, handleSubmit } = useForm({
    fields,
    onSubmit: handleVerifyToken,
  })

  return (
    <div className={classes.container}>
      <Typography variant="h5">{t('mfa.enterCode')}</Typography>
      <Typography variant="subtitle1">{t('mfa.enterCodeDescription')}</Typography>
      <div className={classes.form}>
        {error && (
          <div className={classes.errorMessage}>
            <FontAwesomeIcon icon={faCircleExclamation as IconProp} className={classes.errorIcon} />
            <Typography variant="subtitle2" color={'inherit'}>
              {error?.response?.data?.message}
            </Typography>
          </div>
        )}
        <Form {...formProps} />
        <Button onClick={handleSubmit} fullWidth variant="contained" color="primary" className={classes.submit}>
          {isLoading ? <CircularProgress color={'secondary'} size={24} /> : t('mfa.verify')}
        </Button>
      </div>
      <Grid container>
        <Grid item xs>
          <Link to="/reset-password">{t('login.forgotPassword')}</Link>
        </Grid>
        <Grid item>
          <Link to="/signup">{t('login.noAccount')}</Link>
        </Grid>
      </Grid>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorMessage: {
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.error.main,
    paddingBottom: theme.spacing(2),
  },
  errorIcon: {
    marginRight: 4,
  },
}))
