import { PhishingSimulation } from '@/types/phishingSimulations'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faBoxOpen, faSquareXmark } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Typography, styled } from '@mui/material'
import { FC, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { buildClaudinaryUrl } from '@/api/phishing-simulations/get'
import removeImg from '@/assets/images/popup/remove.svg'
import { LayoutContext } from '@/common/components/Layout/Layout'
import Popup from '@/common/components/Popup'
import { theme } from '@/theme/theme'

export enum SimulationPackageCardType {
  Menu = 'menu',
  Wizard = 'wizard',
}

type SimulationPackageCardProps = {
  index?: number
  type?: SimulationPackageCardType
  packageName?: string
  simulation: PhishingSimulation
  coverImgage?: string
  setPreview?: (e: boolean) => void
}

const SimulationPackageCard: FC<SimulationPackageCardProps> = ({
  simulation,
  index,
  type,
  packageName,
  coverImgage,
  setPreview,
}) => {
  const [hover, setHover] = useState(false)
  const { removeFromSimulationPackage } = useContext(LayoutContext)
  const { t } = useTranslation()
  const [isRemovePopUpOpen, setIsRemovePopUpOpen] = useState(false)
  const claudinaryUrl = buildClaudinaryUrl(simulation.image_cloudinary_ids?.message)

  return (
    <SyledMainBox
      onMouseEnter={() => {
        setHover(true)
      }}
      onMouseLeave={() => {
        setHover(false)
      }}
    >
      <Popup
        open={isRemovePopUpOpen}
        onClose={() => {
          setIsRemovePopUpOpen(false)
        }}
        onConfirm={() => {
          index && removeFromSimulationPackage(index)
          setIsRemovePopUpOpen(false)
        }}
        buttonLabels={{
          confirm: t('simulationPackage.removePopup.confirmButton'),
          cancel: t('simulationPackage.removePopup.cancelButton'),
        }}
        icon={removeImg}
      >
        <h4>{t('simulationPackage.removePopup.title')}</h4>
        <p>{t('simulationPackage.removePopup.message', { simulationName: simulation.name })}</p>
      </Popup>

      <StyledCardWrapper hover={hover} type={type}>
        <StyledCard backgroundImage={coverImgage || claudinaryUrl}>
          <StyledSideBox>
            <StyledVerticalBox>
              <StyledVerticalTypography align="center">{packageName || simulation.name}</StyledVerticalTypography>
            </StyledVerticalBox>
            <StyledPackIcon icon={faBoxOpen as IconProp} fontSize={18} />
          </StyledSideBox>
          <StyledMenuHoverBox>
            {hover && type === SimulationPackageCardType.Menu && (
              <StyledFlexReversedBox>
                <StyledTrashIcon
                  icon={faSquareXmark as IconProp}
                  fontSize={20}
                  onClick={(e) => {
                    e.stopPropagation()
                    setIsRemovePopUpOpen(true)
                  }}
                />
              </StyledFlexReversedBox>
            )}
          </StyledMenuHoverBox>
        </StyledCard>
      </StyledCardWrapper>
      {hover && type === SimulationPackageCardType.Wizard && (
        <SyledCardHoverBox
          onClick={(e) => {
            e.stopPropagation()
            setPreview && setPreview(true)
          }}
        >
          <StyledHoverTypography align="center">{t('simulationPackage.previewClick')}</StyledHoverTypography>
        </SyledCardHoverBox>
      )}
    </SyledMainBox>
  )
}

const StyledMenuHoverBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'space-between',
}))

const StyledFlexReversedBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row-reverse',
}))
const StyledHoverTypography = styled(Typography)(() => ({
  color: theme.palette.blueDianne[900],
  width: '145px',
}))
const SyledCardHoverBox = styled(Box)(() => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const SyledMainBox = styled(Box)(() => ({
  position: 'relative',
}))
const StyledCardWrapper = styled(Box)(
  ({ hover, type }: { hover: boolean; type: SimulationPackageCardType | undefined }) => ({
    width: '100%',
    background: `linear-gradient(-90deg,${theme.palette.blueDianne[900]},${theme.palette.cyan[500]})`,
    margin: '8px 0px',
    borderRadius: '15px',
    display: 'flex',
    flexDirection: 'column',
    opacity: hover && type === SimulationPackageCardType.Wizard ? 0.2 : 1,
    transition: 'margin 0.5s ease',
  })
)
const StyledCard = styled(Box)(({ backgroundImage }: { backgroundImage?: string }) => ({
  zIndex: 1,
  margin: '2px 1px 1px 4px',
  minHeight: '200px',
  background: theme.palette.blueGray[700],
  backgroundImage: `url(${backgroundImage})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  overflow: 'hidden',
  borderRadius: '15px',
  display: 'flex',
  justifyContent: 'space-between',
  transition: 'min-height 0.5s ease',
}))

const StyledSideBox = styled(Box)(() => ({
  width: '32px',
  height: '200px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center',
  background: theme.palette.white,
  transition: 'height 0.5s ease',
}))
export const StyledVerticalBox = styled(Box)(() => ({
  transform: 'rotate(270deg)',
}))

export const StyledVerticalTypography = styled(Typography)(() => ({
  textAlign: 'left',
  width: '150px',
  marginLeft: '140px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontWeight: theme.typography.fontWeightBold,
  fontSize: 16,
  height: '22px',
}))

export const StyledPackIcon = styled(FontAwesomeIcon)(() => ({
  background: theme.palette.cyan[500],
  padding: '4px 6px',
  borderRadius: '0px 15px 0px 0px',
}))

export const StyledTrashIcon = styled(FontAwesomeIcon)(({ disabled }: { disabled?: boolean }) => ({
  background: theme.palette.blueDianne[900],
  padding: '6px 10px',
  borderRadius: '0px 0px 0px 15px',
  color: disabled ? theme.palette.grey[500] : theme.palette.cyan[500],
  cursor: disabled ? 'default' : 'pointer',
}))

export const StyledHeartIcon = styled(FontAwesomeIcon)(() => ({
  padding: '4px 10px',
  color: theme.palette.pink[500],
  cursor: 'pointer',
}))

export default SimulationPackageCard
