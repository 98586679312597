import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { Typography } from '@mui/material'
import { AuthClient } from '../../api/profile/clients'
import { useSwitchClient } from '../../api/profile/switch-client'
import cywarenessLogo from '@/assets/images/logo-clean-blue.svg'

type ClientCardProps = {
  client: AuthClient
}

export default function ClientCard({ client }: ClientCardProps) {
  const { name, logo, _id } = client
  const classes = useStyles()

  const [switchClient] = useSwitchClient()

  async function handleSwitchClient() {
    await switchClient(_id)
    window.location.href = '/'
  }

  return (
    <>
      <div className={classes.root} onClick={handleSwitchClient}>
        <div className={classes.tile}>
          {logo ? (
            <img className={classes.logo} alt={'client logo'} src={logo} />
          ) : (
            <img className={classes.icon} alt={'client logo'} src={cywarenessLogo} />
          )}
        </div>
        <Typography className={classes.title} variant={'subtitle2'}>
          {name}
        </Typography>
      </div>
    </>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: 'relative',
      minHeight: 160,
      width: 160,
      background: `rgba(0,0,0,0)`,
      margin: theme.spacing(2),
      borderRadius: 30,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      transitionDuration: '.4s',
      cursor: 'pointer',
      '&:hover': {
        transform: 'translate3d(0, -4px, 0)',
        '& $button': {
          transition: 'all .4s',
          opacity: 1,
          transform: 'translate3d(0, 0, 0)',
        },
        '& $icon': {
          transition: 'all .4s',
          transform: 'translate3d(0, -4px, 0)',
        },
        '& $info': {
          transition: 'all .4s',
          transform: 'translate3d(0, -4px, 0)',
          opacity: 1,
        },
        '& $tile': {
          transition: 'all .4s',
          backgroundColor: theme.palette.common.white,
        },
      },
    },
    tile: {
      height: 160,
      width: 160,
      borderRadius: 20,
      backgroundColor: theme.palette.common.white,
      boxShadow: '1px 17px 44px rgba(3, 2, 41, 0.07)',
      color: '#fff',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transition: 'all .4s',
      position: 'relative',
    },
    title: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    icon: {
      maxHeight: 60,
      width: 60,
      transition: 'all .4s',
    },
    logo: {
      maxHeight: 60,
      maxWidth: 100,
      transition: 'all .4s',
    },
    link: {
      color: theme.palette.cyan[500],
      cursor: 'pointer',
    },
  })
)
