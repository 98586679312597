import { Box } from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress'
import MaUTable from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import makeStyles from '@mui/styles/makeStyles'
import { FC, ReactElement, useEffect } from 'react'
import { Row, usePagination, useRowSelect, useSortBy, useTable } from 'react-table'

import IndeterminateCheckbox from './IndeterminateCheckbox'
import MultiselectBar, { MultiselectBarProps } from './MultiselectBar'
import TablePaginationActions from './TablePaginationActions'

const useStyles = makeStyles({
  table: ({ enableCheckbox, customCheckbox }: { enableCheckbox: boolean; customCheckbox: boolean }) => ({
    ...(enableCheckbox
      ? {
          overflow: 'hidden',
          '& .MuiTableCell-head': {
            '&>div': {
              display: 'flex',
              justifyContent: 'center',
              paddingLeft: 28,
            },
            '&:first-child, &:nth-child(2)': {
              '&>div': {
                justifyContent: 'flex-start',
                paddingLeft: 0,
              },
            },
          },
          '& .MuiTableCell-body': {
            textAlign: 'center',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            '&:first-child, &:nth-child(2)': {
              textAlign: 'left',
            },
          },
        }
      : {
          overflow: 'hidden',
          '& .MuiTableCell-head': {
            '&>div': {
              display: 'flex',
              justifyContent: 'center',
            },
            '&:first-child': {
              '&>div': {
                justifyContent: 'flex-start',
                paddingLeft: 0,
              },
            },
          },
          '& .MuiTableCell-body': {
            textAlign: 'center',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            '&:first-child': {
              textAlign: 'left',
            },
          },
        }),
    ...(customCheckbox
      ? {
          '& .MuiTableCell-head:nth-of-type(2)': {
            textAlign: 'left',
            paddingLeft: 15,
          },
          '& .MuiTableCell-body:nth-of-type(2)': {
            textAlign: 'left',
          },
        }
      : {}),
  }),
  row: {
    backgroundColor: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#EFFCFE !important',
    },
    '&.isInactive': {
      backgroundColor: '#f2f2f2',
      '&:hover': {
        backgroundColor: '#E0EDEF !important',
      },
    },
    '&.isDisabled': {
      backgroundColor: '#f2f2f2',
      '&:hover': {
        backgroundColor: '#E0EDEF !important',
      },
    },
  },
})

type PaginatingTableProps<DataObject, ToolbarProps> = {
  columns: { Header: string; accessor: keyof DataObject }[]
  data: DataObject[]
  TableToolbar?: FC<any>
  toolbarProps?: ToolbarProps
  manualSortBy?: boolean
  onRowClick?: (row: Row) => void
  fetchData: ({
    pageIndex,
    pageSize,
    sortBy,
    sortOrder,
  }: {
    pageIndex: number
    pageSize: number
    sortBy?: string
    sortOrder?: 'asc' | 'desc' | ''
  }) => void
  loading: boolean
  pageCount: number
  count?: number
  enableCheckbox?: boolean
  customCheckbox?: boolean
  disabledCheckbox?: boolean
  noResultsTextId: string
  noResultsContent: ReactElement
  multiSelectBarProps?: Pick<MultiselectBarProps, 'label' | 'actions'>
  cellClassname?: string
  rowKey?: string
  initialPageSize?: number
  currentPageIndex?: number
  onChangeRowsPerPage?: (numberOfRows: number) => void
}

const PaginagintTable: FC<PaginatingTableProps<DataObject, ToolbarProps>> = ({
  columns,
  data,
  TableToolbar,
  toolbarProps,
  manualSortBy = false,
  onRowClick,
  count = 0,
  pageCount: controlledPageCount,
  enableCheckbox = true,
  customCheckbox = false,
  disabledCheckbox = false,
  loading,
  fetchData,
  noResultsContent,
  multiSelectBarProps = {
    label: '',
    actions: [],
  },
  cellClassname,
  rowKey = '_id',
  initialPageSize = 10,
  currentPageIndex = 0,
  onChangeRowsPerPage,
}) => {
  const classes = useStyles({ enableCheckbox, customCheckbox })

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    selectedFlatRows,
    toggleAllRowsSelected,
    state: { pageIndex, pageSize, selectedRowIds, sortBy },
  } = useTable(
    {
      columns,
      data,
      manualSortBy: manualSortBy,
      initialState: { pageIndex: currentPageIndex, pageSize: initialPageSize },
      manualPagination: true,
      autoResetPage: false,
      pageCount: controlledPageCount,
      autoResetSelectedRows: false,
      getRowId: (row) => row[rowKey],
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        const extraColumns: {}[] = []
        if (enableCheckbox) {
          extraColumns.push({
            id: 'selection',
            Header: ({ getToggleAllPageRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} disabled={disabledCheckbox} />
              </div>
            ),
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox
                  {...row.getToggleRowSelectedProps()}
                  onClick={(e) => e.stopPropagation()}
                  disabled={disabledCheckbox}
                />
              </div>
            ),
          })
        }
        return [...extraColumns, ...columns]
      })
    }
  )

  // Listen for changes in pagination and use the state to fetch our new data
  useEffect(() => {
    fetchData({
      pageIndex,
      pageSize,
      sortBy: sortBy[0]?.id || '',
      sortOrder: sortBy[0] ? (sortBy[0].desc ? 'desc' : 'asc') : '',
    })
  }, [fetchData, pageIndex, pageSize, sortBy])

  const handleChangePage = (event, newPage) => {
    gotoPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    const size = Number(event.target.value)
    setPageSize(size)
    onChangeRowsPerPage && onChangeRowsPerPage(size)
  }

  const numSelected = Object.keys(selectedRowIds).length
  return (
    <div style={{ position: 'relative', paddingBottom: '50px' }}>
      <TableContainer>
        {enableCheckbox && numSelected > 0 ? (
          <MultiselectBar
            label={multiSelectBarProps?.label}
            actions={multiSelectBarProps?.actions?.map((action) => ({
              ...action,
              callback: () => toggleAllRowsSelected(false),
            }))}
            numSelected={numSelected}
            selectedRows={Object.keys(selectedRowIds) ?? []}
            selectedFlatRows={(selectedFlatRows || []).map((r) => r.original)}
          />
        ) : (
          TableToolbar && <TableToolbar numSelected={numSelected} selectedRowIds={selectedRowIds} {...toolbarProps} />
        )}
        {loading && <LinearProgress />}
        <MaUTable {...getTableProps()} className={classes.table}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <TableCell
                    {...(column.id === 'selection'
                      ? column.getHeaderProps()
                      : column.getHeaderProps(column.getSortByToggleProps()))}
                  >
                    <Box display="flex">
                      {column.render('Header')}
                      {column.id !== 'selection' ? (
                        <TableSortLabel
                          active={column.isSorted}
                          // react-table has a unsorted state which is not treated here
                          direction={column.isSortedDesc ? 'desc' : 'asc'}
                        />
                      ) : null}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {page.length > 0 ? (
              page.map((row, key) => {
                prepareRow(row)
                return (
                  <TableRow
                    key={key}
                    className={
                      classes.row +
                      (row.original.state === 'inactive' ? ' isInactive' : '') +
                      (row.original.disabled ? ' isDisabled' : '')
                    }
                    hover={!!onRowClick}
                    onClick={() => (onRowClick ? onRowClick(row) : {})}
                    {...row.getRowProps()}
                    style={{
                      cursor: onRowClick ? 'pointer' : 'initial',
                      backgroundColor:
                        row.original?.state === 'inactive' || row.original?.disabled ? '#f2f2f2' : 'initial',
                    }}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <TableCell
                          style={{ textAlign: cell.column?.align || 'auto' }}
                          className={cellClassname}
                          {...cell.getCellProps()}
                        >
                          {cell.render('Cell')}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })
            ) : (
              <TableCell colSpan={columns.length + Number(enableCheckbox)}>{noResultsContent}</TableCell>
            )}
          </TableBody>
        </MaUTable>
      </TableContainer>
      {(data.length > pageSize || count > pageSize) && (
        <TablePagination
          sx={{ minWidth: 600, position: 'absolute', right: '50px' }}
          component={'div'}
          align={'left'}
          rowsPerPageOptions={[5, 10, 25, 50]}
          colSpan={columns.length}
          count={count ?? data.length}
          rowsPerPage={pageSize}
          page={pageIndex}
          SelectProps={{
            inputProps: { 'aria-label': 'rows per page' },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      )}
    </div>
  )
}

export default PaginagintTable
