import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { faCheck } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { List, ListItem, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Step } from '../../../pages/Onboarding/Onboarding'
import { useLocation, useNavigate } from 'react-router-dom'

type OnboardingStepperProps = {
  title: string
  steps: Step[]
}

export default function SidebarStepper({ steps = [], title }: OnboardingStepperProps) {
  const { t } = useTranslation()
  const classes = useStyles()
  const navigateTo = useNavigate()
  const location = useLocation()

  function hasCompletedPreviousSteps(index: number): boolean {
    if (index > 0) {
      for (let i = 0; i < index; i++) {
        if (!steps[i].completed) {
          return false
        }
      }
      return true
    } else {
      return true
    }
  }
  return (
    <div className={classes.root}>
      <Typography variant={'h6'}>{t(title)}</Typography>
      <List className={classes.list}>
        {steps.map((step, index) => (
          <ListItem
            disabled={!hasCompletedPreviousSteps(index)}
            button
            className={classes.listItem}
            onClick={() => navigateTo(step.path)}
          >
            {step.completed ? (
              <div className={classes.statusIndicatorComplete}>
                <FontAwesomeIcon icon={faCheck as IconProp} />
              </div>
            ) : (
              <div
                className={`${classes.statusIndicatorEmpty} ${
                  location.pathname === step.path ? classes.statusIndicatorCurrent : ''
                }`}
              />
            )}
            <Typography
              variant={'subtitle1'}
              className={location.pathname === step.path ? classes.selectedFont : undefined}
            >
              {t(step.name)}
            </Typography>
          </ListItem>
        ))}
      </List>
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: 350,
      height: '100%',
      borderRight: `1px solid ${theme.palette.divider}`,
      padding: theme.spacing(2, 2),
    },
    list: {
      padding: theme.spacing(2, 0),
    },
    listItem: {
      display: 'flex',
      padding: theme.spacing(1, 1),
    },
    statusIndicatorEmpty: {
      width: 30,
      height: 30,
      marginRight: theme.spacing(2),
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: '50%',
    },
    statusIndicatorCurrent: {
      border: `1px solid ${theme.palette.success.main}`,
    },
    selectedFont: {
      fontWeight: theme.typography.fontWeightBold,
    },
    statusIndicatorComplete: {
      width: 30,
      height: 30,
      marginRight: theme.spacing(2),
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.common.white,
      background: theme.palette.success.main,
    },
  })
)
