import { Box, Chip, Tabs } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { useMemo } from 'react'

import { Group } from '@/api/groups/groups'
import { Office } from '@/api/offices/offices'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faBriefcase, faUserGroup } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type Tag = {
  id: string
  name: string
  type: 'group' | 'office' | 'score'
}

type TagsFilterProps = {
  selectedTags: string[]
  onTagClick: (tag: Tag) => void
  groups?: Group[]
  offices?: Office[]
  scores?: Tag[]
}

const TagsFilter = ({
  selectedTags,
  onTagClick,
  groups = [],
  offices = [],
  scores = [
    { id: 'bad', name: 'Bad', type: 'score' },
    { id: 'low', name: 'Low', type: 'score' },
    { id: 'average', name: 'Average', type: 'score' },
    { id: 'excellent', name: 'Excellent', type: 'score' },
  ],
}: TagsFilterProps) => {
  const classes = useStyles()

  const groupTags: Tag[] = useMemo(() => {
    return groups.map((group) => ({
      id: group._id,
      name: group.name,
      type: 'group',
    }))
  }, [groups])

  const officeTags: Tag[] = useMemo(() => {
    return offices.map((office) => ({
      id: office._id,
      name: office.name,
      type: 'office',
    }))
  }, [offices])

  const tags: Tag[] = scores.concat(groupTags).concat(officeTags) || []

  return (
    <div className={classes.root}>
      <Tabs variant="scrollable" scrollButtons classes={{ indicator: classes.indicator }} allowScrollButtonsMobile>
        {tags.map((tag, i) => {
          const selected = selectedTags.includes(tag?.id)
          return (
            <div>
              <Chip
                key={i}
                label={tag.name}
                variant="filter"
                icon={<ChipIcon type={tag.type} />}
                color={selected ? 'secondary' : 'default'}
                onClick={() => onTagClick(tag)}
              />
            </div>
          )
        })}
      </Tabs>
    </div>
  )
}

function ChipIcon({ type }: { type: string }) {
  switch (type) {
    case 'group':
      return (
        <Box ml={1}>
          <FontAwesomeIcon icon={faUserGroup as IconProp} />
        </Box>
      )
    case 'office':
      return (
        <Box ml={1} mt={0.4}>
          <FontAwesomeIcon icon={faBriefcase as IconProp} />
        </Box>
      )
    default:
      return null
  }
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& .MuiTabScrollButton-root': {
        '&.Mui-disabled': {
          opacity: 0.2,
        },
      },
    },
    indicator: {
      display: 'none',
    },
  })
)

export default TagsFilter
