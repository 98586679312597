import axios from 'axios'
import { useMutation } from 'react-query'
import { API_URL } from '../constants'
import { ServerError } from '../types'

export type OnPremiseAuthenticationData = {
  type: string
  authentication: {
    host: string
    user: string
    useSsl: boolean
    password: string
    port: number
  }
}

export type MicrosoftEntraIdAuthenticationData = {
  type: string
  authentication: {
    tenantId: string
    clientSecret: string
    clientId: string
  }
}

export async function testConnection(authData: OnPremiseAuthenticationData | MicrosoftEntraIdAuthenticationData) {
  const url = `${API_URL}/integrations/test-connection/`
  let params
  if (authData.type === 'ldap') {
    const ldapAuthData = (authData as OnPremiseAuthenticationData).authentication
    params = {
      type: authData.type,
      authentication: {
        host: ldapAuthData.host,
        user: ldapAuthData.user,
        use_ssl: ldapAuthData.useSsl,
        password: ldapAuthData.password,
        port: ldapAuthData.port,
      },
    }
  } else if (authData.type === 'ms-graph') {
    const adAzureAuthData = (authData as MicrosoftEntraIdAuthenticationData).authentication
    params = {
      type: authData.type,
      authentication: {
        tenant_id: adAzureAuthData.tenantId,
        client_secret: adAzureAuthData.clientSecret,
        client_id: adAzureAuthData.clientId,
      },
    }
  }
  const { data } = await axios.post(url, params, {
    withCredentials: true,
  })
  return data
}

export default function useTestAuthentication() {
  return useMutation<any, ServerError, OnPremiseAuthenticationData | MicrosoftEntraIdAuthenticationData>(
    testConnection,
    {
      throwOnError: true,
    }
  )
}
