import { Checkbox, Skeleton, TableCell, TableRow } from '@mui/material'
import { FC } from 'react'

type TableSceletonLoaderProps = {
  rowsCount: number
  columnsCount: number
  selectable?: boolean
}

const TableSceletonLoader: FC<TableSceletonLoaderProps> = ({ rowsCount, columnsCount, selectable }) => (
  <>
    {Array(rowsCount)
      .fill('_')
      .map((_, index) => (
        <TableRow key={index}>
          {selectable && (
            <TableCell padding="checkbox">
              <Checkbox disabled={true} />
            </TableCell>
          )}
          {Array(columnsCount)
            .fill('_')
            .map((_, index) => (
              <TableCell key={index}>
                <Skeleton variant="rectangular" width={'80%'} />
              </TableCell>
            ))}
        </TableRow>
      ))}
  </>
)

export default TableSceletonLoader
