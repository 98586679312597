import useLottie from '@/common/hooks/useLottie'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { FC } from 'react'

import simulationLaunchAnimationData from '@/assets/lottie/rocketLaunch.json'

const FeedbackAnimation: FC<{ animationData?: any }> = ({ animationData = simulationLaunchAnimationData }) => {
  const classes = useStyles()
  const { LottieAnimation } = useLottie({
    animationData: animationData,
    width: document.body.clientWidth,
    height: document.body.clientHeight,
    options: {
      loop: false,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    },
  })
  return <div className={classes.lottieContainer}>{LottieAnimation}</div>
}

const useStyles = makeStyles(() =>
  createStyles({
    lottieContainer: {
      position: 'fixed',
      width: '100%',
      height: '100%',
      bottom: '0px',
      left: '0px',
      zIndex: 1202,
      pointerEvents: 'none',
    },
  })
)

export default FeedbackAnimation
