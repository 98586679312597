const AUTO_SUBTITLE_LANGUAGES = ["en-US", "es", "fr", "he", "id", "pt"];

function parseSubtitlesUrl(url: string, language: string) {
  const urlParts = url.split("/");
  const fileName = urlParts[urlParts.length - 1];
  const fileNameParts = fileName.split(".");
  const fileNameWithoutExtension = fileNameParts[0];
  const newFileName = `${fileNameWithoutExtension}.${language}.vtt`;
  urlParts[4] = "raw";
  urlParts[urlParts.length - 1] = newFileName;
  return urlParts.join("/");
}

function stripCountryCode(language: string) {
  return language.split("-")[0];
}

export function generateAutoSubtitleTracksObject(url: string) {
  const tracks = {};
  AUTO_SUBTITLE_LANGUAGES.forEach((lang) => {
    tracks[stripCountryCode(lang)] = parseSubtitlesUrl(url, lang);
  });
  return tracks;
}

export function generateUploadedSubtitleTracksObject(
  subtitleValues: { [key: string]: string },
  numberOfSubtitleFiles: number
) {
  const subtitleTracks = {};
  for (let i = 0; i < numberOfSubtitleFiles; i++) {
    subtitleTracks[subtitleValues[`subtitleLang${i}`]] =
      subtitleValues[`subtitleUrl${i}`];
  }
  return subtitleTracks;
}
