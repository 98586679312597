import dayjs from 'dayjs'

export const cronToDayjs = (cronString: string | undefined) => {
  if (typeof cronString === 'undefined') {
    return dayjs()
  }

  const [minute, hour] = cronString.split(' ')

  return dayjs.utc().hour(parseInt(hour)).minute(parseInt(minute)).second(0).local()
}
