import { FC, useMemo } from 'react'

import { usePhishingSimulations } from '@/api/phishing-simulations/get'
import LanguageFilter, { LanguageFilterProps } from '@/common/components/LanguageFilter/LanguageFilter'

type SimulationsLanguageFilterProps = Omit<LanguageFilterProps, 'languages'>

const SimulationsLanguagesFilter: FC<SimulationsLanguageFilterProps> = (props) => {
  const { data: phishingSimulationData } = usePhishingSimulations({ limit: 3000000 }) // As a general rule BE doesn't allow limit = 0

  const languages = useMemo(() => {
    if (!phishingSimulationData) return []
    return [...new Set(phishingSimulationData.results.flatMap((simulation) => simulation.languages.message))]
  }, [phishingSimulationData])

  return <LanguageFilter languages={languages} {...props} />
}

export default SimulationsLanguagesFilter
