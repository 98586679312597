import { Box, styled } from '@mui/material'
import { FC } from 'react'

import EmailMessage from '@/common/components/DesktopPreview/EmailMessage'
import WhatsAppMessage from '@/common/components/DesktopPreview/WhatsAppMessage'
import MobilePhonePreview from '@/common/components/MobilePhonePreview'
import EmailAppPreview from '@/common/components/MobilePhonePreview/EmailAppPreview'
import SmsAppPreview from '@/common/components/MobilePhonePreview/SmsAppPreview'
import WhatsAppPreview from '@/common/components/MobilePhonePreview/WhatsAppPreview'
import { DeviceType } from '@/common/hooks/useDeviceDetection'
import { CampaignMicrotraining } from '@/types/campaigns'
import { PhishingSimulationVector } from '@/types/phishingSimulations'

type DevicePreviewProps = {
  deviceType: DeviceType
  attackVector: PhishingSimulationVector
  data: CampaignMicrotraining
  isRtl?: boolean
}
const DevicePreview: FC<DevicePreviewProps> = ({ deviceType, attackVector, data, isRtl = false }) => {
  //Mobile
  if (deviceType === DeviceType.Mobile) {
    switch (attackVector) {
      //Mobile  Email
      case PhishingSimulationVector.Email:
        return (
          <MobilePhonePreviewContainer>
            <MobilePhonePreview>
              <EmailAppPreview sender={data.sender} message={data.message.html} subject={data.subject} />
            </MobilePhonePreview>
          </MobilePhonePreviewContainer>
        )
      //Mobile  WhatsApp
      case PhishingSimulationVector.WhatsApp:
        return (
          <MobilePhonePreviewContainer>
            <MobilePhonePreview>
              <WhatsAppPreview sender={data.sender.name} message={data.message.html} isRtl={isRtl} />
            </MobilePhonePreview>
          </MobilePhonePreviewContainer>
        )
      //Mobile  SMS
      default:
        return (
          <MobilePhonePreviewContainer>
            <MobilePhonePreview>
              <SmsAppPreview sender={data.sender.name} message={data.message.html} isRtl={isRtl} />
            </MobilePhonePreview>
          </MobilePhonePreviewContainer>
        )
    }
  }
  // Desktop or Tablet (deviceType === DeviceType.Desktop || deviceType === DeviceType.Tablet)
  else {
    switch (attackVector) {
      //Desktop or Tablet  Email
      case PhishingSimulationVector.Email:
        return <EmailMessage sender={data.sender} subject={data.subject} message={data.message.html} />
      //Desktop or Tablet  WhatsApp
      case PhishingSimulationVector.WhatsApp:
        return (
          <WhatsAppMessage subject={data.subject} sender={data.sender.name} message={data.message.html} isRtl={isRtl} />
        )
      //Desktop or Tablet  SMS
      default:
        return (
          <DesktopSmsWrapper>
            <MobilePhonePreviewContainer style={{ width: '300px' }}>
              <MobilePhonePreview>
                <SmsAppPreview sender={data.sender.name} message={data.message.html} isRtl={isRtl} />
              </MobilePhonePreview>
            </MobilePhonePreviewContainer>
          </DesktopSmsWrapper>
        )
    }
  }
}

const DesktopSmsWrapper = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
}))
const MobilePhonePreviewContainer = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
}))
export default DevicePreview
