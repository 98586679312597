import React from "react";
import { CircularProgressProps, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import SvgGradient from "../ScoreMeter/SvgGradient";

interface CircularProgressWithLabelProps extends CircularProgressProps {
  value: number;
}

export default function CircularProgressWithLabel({
  value,
}: CircularProgressWithLabelProps) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <SvgGradient
        cssId={"secondaryGradient"}
        startColor={"#464E93"}
        endColor={"rgba(173, 89, 164, 0.441435)"}
        rotate={90}
      />
      <div className={classes.root}>
        <CircularProgressbarWithChildren
          value={value}
          styles={{
            // Customize the root svg element
            root: {},
            // Customize the path, i.e. the "completed progress"
            path: {
              // Path color
              stroke: `url(#secondaryGradient)`,
              // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
              strokeLinecap: "round",
              // Customize transition animation
              transition: "stroke-dashoffset 0.5s ease 0s",
              // Rotate the path
            },
            // Customize the circle behind the path, i.e. the "total progress"
            trail: {
              // Trail color
              stroke: "#D8D8D8",
              // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
              strokeLinecap: "round",
              // Rotate the trail
              transform: "rotate(0.625turn)",
              transformOrigin: "center center",
            },
            text: {
              fill: "#f88",
              fontSize: "16px",
            },
            background: {
              fill: "#3e98c7",
            },
          }}
        >
          <div className={classes.textContainer}>
            <Typography variant={"caption"} color={"inherit"}>
              {value}%
            </Typography>
          </div>
        </CircularProgressbarWithChildren>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: 58,
    },
    root: {
      height: 50,
      width: 50,
      marginRight: theme.spacing(1),
    },
    textContainer: {
      color: theme.palette.text.primary,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "50%",
    },
  })
);
