import json from '@/assets/pricingCalculatorSettings.json'
import { theme } from '@/theme/theme'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  faBullhorn,
  faClapperboardPlay,
  faClock,
  faHandsHoldingDiamond,
  faUsers,
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Typography, styled } from '@mui/material'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { withCommas } from './utils'

const membersDiscountData = json.discounts.members
const subscriptionTimeDiscountData = json.discounts.years

const Summary: FC<{
  currentPlan: string | undefined
  liteCost: number
  tailoredCost: number
  humanfirewallCost: number
  membersCount: number
  campaignsCount: number
  currentSubscriptionTime: number | undefined
  videosCount: number
  videosCost: number
}> = ({
  currentPlan,
  liteCost,
  tailoredCost,
  humanfirewallCost,
  membersCount,
  campaignsCount,
  currentSubscriptionTime,
  videosCount,
  videosCost,
}) => {
  const { t } = useTranslation()
  const membersDiscount = useMemo(() => {
    if (!!membersCount && membersCount >= membersDiscountData.count[0]) {
      for (let i = 1; i < membersDiscountData.count.length; i++) {
        if (membersCount < membersDiscountData.count[i]) return membersDiscountData.discount[i - 1]
      }
      return membersDiscountData.discount[membersDiscountData.count.length - 1]
    }
    return 0
  }, [membersCount, membersDiscountData])

  const subscriptionTimeDiscount = useMemo(() => {
    if (!!currentSubscriptionTime && currentSubscriptionTime >= subscriptionTimeDiscountData.count[0]) {
      for (let i = 1; i < subscriptionTimeDiscountData.count.length; i++) {
        if (currentSubscriptionTime < subscriptionTimeDiscountData.count[i])
          return subscriptionTimeDiscountData.discount[i - 1]
      }
      return subscriptionTimeDiscountData.discount[subscriptionTimeDiscountData.count.length - 1]
    }
    return 0
  }, [currentSubscriptionTime, membersDiscountData])

  const price =
    (currentPlan === 'Lite' ? liteCost : currentPlan === 'Tailored' ? tailoredCost : humanfirewallCost) *
    (membersCount || 0) *
    (currentPlan === 'Lite' ? campaignsCount || 3 : 1)

  const discount = 1 - membersDiscount / 100 - subscriptionTimeDiscount / 100
  return (
    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          paddingRight: theme.spacing(4),
        }}>
        <StyledSummaryLine>
          <StyledIconBox>
            <FontAwesomeIcon icon={faHandsHoldingDiamond as IconProp} />
          </StyledIconBox>
          <Typography whiteSpace={'nowrap'}> {t('pricingCalculator.summary.plan')}</Typography>
          <StyledDivider />
          {currentPlan && (
            <Typography whiteSpace={'nowrap'} fontSize={20} fontWeight={600}>
              {currentPlan} {t('pricingCalculator.summary.plan')}
            </Typography>
          )}
        </StyledSummaryLine>
        <StyledSummaryLine>
          <StyledIconBox>
            <FontAwesomeIcon icon={faUsers as IconProp} />
          </StyledIconBox>
          <Typography whiteSpace={'nowrap'}> Members Amount</Typography>
          <StyledDivider />
          <Typography whiteSpace={'nowrap'} fontSize={20} fontWeight={600}>
            {withCommas(membersCount)} Members
          </Typography>
          {membersDiscount > 0 && (
            <Typography
              sx={{
                whiteSpace: 'nowrap',
                background: theme.palette.blueGray[900],
                color: theme.palette.cyan[500],
                borderRadius: '10px',
                padding: theme.spacing(0.1, 1),
                fontSize: 12,
                fontWeight: 700,
              }}>
              {membersDiscount}% Saved
            </Typography>
          )}
        </StyledSummaryLine>
        {currentPlan === 'Lite' && (
          <StyledSummaryLine>
            <StyledIconBox>
              <FontAwesomeIcon icon={faBullhorn as IconProp} />
            </StyledIconBox>
            <Typography whiteSpace={'nowrap'}> Campaigns</Typography>
            <StyledDivider />
            <Typography whiteSpace={'nowrap'} fontSize={20} fontWeight={600}>
              {withCommas(campaignsCount)} Campaigns per Member/year
              <span style={{ color: theme.palette.cyan[500] }}>*</span>
            </Typography>
          </StyledSummaryLine>
        )}
        <StyledSummaryLine>
          <StyledIconBox>
            <FontAwesomeIcon icon={faClock as IconProp} />
          </StyledIconBox>
          <Typography whiteSpace={'nowrap'}> Subscription Time</Typography>
          <StyledDivider />
          {currentSubscriptionTime && (
            <Typography whiteSpace={'nowrap'} fontSize={20} fontWeight={600}>
              {currentSubscriptionTime} Years
            </Typography>
          )}
          {subscriptionTimeDiscount > 0 && (
            <Typography
              sx={{
                whiteSpace: 'nowrap',
                background: theme.palette.blueGray[900],
                color: theme.palette.cyan[500],
                borderRadius: '10px',
                padding: theme.spacing(0.1, 1),
                fontSize: 12,
                fontWeight: 700,
              }}>
              {subscriptionTimeDiscount}% Saved
            </Typography>
          )}
        </StyledSummaryLine>
        <StyledSummaryLine>
          {' '}
          <StyledIconBox>
            <FontAwesomeIcon icon={faClapperboardPlay as IconProp} />
          </StyledIconBox>
          <Typography whiteSpace={'nowrap'}>{t('pricingCalculator.summary.tailoredVideos')}</Typography>
          <StyledDivider />
          <Typography whiteSpace={'nowrap'} fontSize={20} fontWeight={600}>
            {withCommas(videosCount)} {t('pricingCalculator.summary.videos')}
            <span style={{ color: theme.palette.cyan[500] }}>*</span>
          </Typography>
        </StyledSummaryLine>
      </Box>
      <Box display={'flex'} flexDirection={'column'} gap={2}>
        <StyledSummarySideBox>
          {currentPlan ? (
            <>
              <Typography
                sx={{
                  background: theme.palette.cyan[500],
                  fontSize: 16,
                  fontWeight: 600,
                  padding: theme.spacing(1),
                  borderRadius: '10px',
                  margin: theme.spacing(2),
                }}>
                ${currentPlan === 'Lite' && liteCost}
                {currentPlan === 'Tailored' && withCommas(tailoredCost)}
                {currentPlan === 'Human Firewall' && humanfirewallCost} Per Member
              </Typography>
              {membersDiscount > 0 && (
                <Typography
                  sx={{
                    color: theme.palette.cyan[500],
                    background: theme.palette.blueGray[700],
                    padding: theme.spacing(1),
                    marginBottom: theme.spacing(1),
                    borderRadius: '8px',
                    fontWeight: 600,
                  }}>
                  {membersDiscount}
                  {t('pricingCalculator.summary.membersDiscount')}
                </Typography>
              )}
              {subscriptionTimeDiscount > 0 && (
                <Typography
                  sx={{
                    color: theme.palette.cyan[500],
                    background: theme.palette.blueGray[700],
                    padding: theme.spacing(1),
                    marginBottom: theme.spacing(1),
                    borderRadius: '8px',
                    fontWeight: 600,
                  }}>
                  {subscriptionTimeDiscount}
                  {t('pricingCalculator.summary.durationDiscount')}
                </Typography>
              )}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}>
                <Typography fontSize={31} fontWeight={400} color="white">
                  Total{' '}
                </Typography>
                {discount !== 1 && (
                  <Typography fontSize={31} fontWeight={400} color="white">
                    <s style={{ color: theme.palette.grey[400] }}>${withCommas(price)}</s>
                  </Typography>
                )}
                <Typography fontSize={31} fontWeight={400} color="white" whiteSpace="nowrap">
                  <b>${withCommas(price * discount)}</b>/ year
                </Typography>
              </Box>
            </>
          ) : (
            <Typography
              color={theme.palette.blue[50]}
              lineHeight={'32px'}
              fontSize={'32px'}
              textAlign={'center'}
              fontWeight={theme.typography.fontWeightBold}
              padding={theme.spacing(10, 4)}>
              {t('pricingCalculator.summary.pickYourPlan')}
            </Typography>
          )}
        </StyledSummarySideBox>
        {videosCost > 0 && (
          <StyledSummarySideBox>
            <Typography
              sx={{
                color: theme.palette.blue[50],
                fontWeight: theme.typography.fontWeightSemiBold,
                lineHeight: '26px',
              }}>
              One Time
            </Typography>
            <Typography
              sx={{
                color: theme.palette.cyan[50],
                fontWeight: theme.typography.fontWeightSemiBold,
                fontSize: 24,
              }}>
              ${withCommas(videosCost)}
            </Typography>
            <Typography
              sx={{
                color: theme.palette.blue[50],
              }}>
              Custom Video Series Producing
            </Typography>
          </StyledSummarySideBox>
        )}
      </Box>
    </Box>
  )
}

const StyledSummarySideBox = styled(Box)(({ theme }) => ({
  background: theme.palette.blueGray[900],
  padding: theme.spacing(2, 2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  border: `2px solid ${theme.palette.cyan[500]}`,
  borderRadius: '40px',
  minWidth: '304px',
  gap: theme.spacing(1),
}))

const StyledSummaryLine = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  gap: theme.spacing(1),
  padding: theme.spacing(2),
}))

const StyledIconBox = styled(Box)(({ theme }) => ({
  minWidth: '32px',
  height: '32px',
  background: theme.palette.cyan[500],
  borderRadius: '5px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const StyledDivider = styled(Box)(({ theme }) => ({
  width: '100%',
  background: theme.palette.cyan[500],
  height: '1px',
  margin: theme.spacing(1),
}))

export default Summary
