import PasswordStrengthEvaluation from '@/pages/Settings/Admins/PasswordStrengthEvaluation'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, InputAdornment } from '@mui/material'
import MuiTextField, { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField'
import { FieldHookConfig, useField } from 'formik'
import { ChangeEvent, FC, FocusEvent, useState } from 'react'

import { MuiTextFieldSX } from '../TextFieldV2/'
import HelperTextComponent from '../TextFieldV2/HelperTextComponent'

interface PasswordFieldProps
  extends Omit<MuiTextFieldProps, 'name' | 'value' | 'error' | 'onChange' | 'onBlur'>,
    Pick<FieldHookConfig<string>, 'name'> {
  withStrengthIndicator?: boolean
  disableFormik?: boolean
  label: MuiTextFieldProps['label']
  value?: MuiTextFieldProps['value']
  onChange?: MuiTextFieldProps['onChange']
  onBlur?: MuiTextFieldProps['onBlur']
}

const PasswordField: FC<PasswordFieldProps> = ({
  name,
  label,
  InputProps,
  InputLabelProps,
  withStrengthIndicator = false,
  disableFormik = false,
  onChange,
  onBlur,
  ...otherProps
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [field, meta] = useField(name)

  const configTextField: MuiTextFieldProps = {
    ...otherProps,
    fullWidth: true,
    label: label,
    placeholder: '********',
  }

  if (!disableFormik) {
    // Merge Formik-related props if Formik is enabled
    Object.assign(configTextField, {
      ...field,
      className: disableFormik ? '' : 'validate',
      error: meta.touched && Boolean(meta.error),
      helperText: <HelperTextComponent hasError={meta.touched && Boolean(meta.error)} helperText={meta.error} />,
      onChange: onChange
        ? (event: ChangeEvent<HTMLInputElement>) => {
            field.onChange(event)
            onChange(event)
          }
        : field.onChange,
      onBlur: onBlur
        ? (event: FocusEvent<HTMLInputElement>) => {
            field.onBlur(event)
            onBlur(event)
          }
        : field.onBlur,
    })
  } else {
    // Attach provided onChange and onBlur handlers for non-Formik usage
    if (onChange) configTextField.onChange = onChange
    if (onBlur) configTextField.onBlur = onBlur
  }

  return (
    <Box width="100%">
      <MuiTextField
        {...configTextField}
        type={isPasswordVisible ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start" style={{ cursor: 'default', width: '15px' }}>
              <FontAwesomeIcon
                onClick={() => setIsPasswordVisible((e) => !e)}
                icon={(isPasswordVisible ? faEyeSlash : faEye) as IconProp}
              />
            </InputAdornment>
          ),
        }}
        sx={MuiTextFieldSX}
      />

      {withStrengthIndicator && field.value && <PasswordStrengthEvaluation size="small" password={field.value} />}
    </Box>
  )
}

export default PasswordField
