import { API_URL } from '@/api/constants'
import { useMutation } from '@tanstack/react-query'
import axios from 'axios'

const authenticateToGWS = async () => {
  const { data } = await axios.post(
    `${API_URL}/integrations/google-workspace/authenticate/`,
    {},
    {
      withCredentials: true,
    }
  )

  return data
}

const useAuthenticateToGWS = () => {
  // const queryClient = useQueryClient()

  return useMutation({
    mutationFn: authenticateToGWS,
    onSuccess: async () => {
      // console.log(123)
    },
  })
}

export { useAuthenticateToGWS }
