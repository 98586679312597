import AppBar from '@mui/material/AppBar'
import Button from '@mui/material/Button'
import Toolbar from '@mui/material/Toolbar'
import { createStyles, makeStyles } from '@mui/styles'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import CustomBreadcrumbs from '@/common/components/Breadcrumbs/Breadcrumbs'

type SimulationPageTopBarProps = {
  onLaunch?: () => void
  onSave?: () => void
  loading?: boolean
}
const SimulationPageTopBar: FC<SimulationPageTopBarProps> = ({ onLaunch, onSave, loading }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <AppBar position="relative" className={classes.appBar} elevation={0}>
      <Toolbar className={classes.toolbar}>
        <CustomBreadcrumbs />
        <div>
          {onLaunch && !loading ? (
            <Button disableElevation onClick={onLaunch} variant={'contained'}>
              {t('videoLibrary.launch')}
            </Button>
          ) : (
            <></>
          )}

          {onSave && !loading ? (
            <Button
              disableElevation
              onClick={(e) => {
                onSave()
              }}
              variant={'outlined'}
              size="small"
              className={classes.saveButton}
            >
              {t('createVideoModal.save')}
            </Button>
          ) : (
            <></>
          )}
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default SimulationPageTopBar

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      height: 66,
      width: '100%',

      background: theme.palette.background.default,
      border: 'none',
    },
    toolbar: {
      padding: '0 30px',
      minHeight: 66,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    saveButton: {
      color: theme.palette.common.black,
      borderColor: theme.palette.common.black,
      fontWeight: 'normal',
      padding: theme.spacing(0, 2),
      fontSize: 18,
    },
  })
)
