import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { useDropzone } from "react-dropzone";
import { CircularProgress, Button, Typography } from "@mui/material";
import useToast from "../../../../common/hooks/useToast";
import UploadVideoImg from "../../../../assets/images/upload-video.svg";
import { useTranslation } from "react-i18next";
import { useUploadVideoToCloudinary } from "../../../../api/upload/upload-cloudinary-direct";

type VideoUploadProps = {
  onUpload: (video: { url: string; name: string; duration?: number }) => void;
};

export default function VideoUpload({ onUpload }: VideoUploadProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { errorToast } = useToast();
  const [uploadFile, { isLoading }] = useUploadVideoToCloudinary();
  const {
    getInputProps,
    getRootProps,
    open: openFileSelector,
  } = useDropzone({
    onDrop: handleUpload,
    noDrag: false,
    multiple: false,
    accept: ".mp4, .mov, .avi",
  });

  async function handleUpload(acceptedFiles) {
    try {
      const file = await uploadFile(acceptedFiles[0]);
      if (file) {
        onUpload({
          name: acceptedFiles[0].name,
          url: file.url,
          duration: file.duration,
        });
      } else {
        errorToast(t("videoUpload.errors.failedToUpload"));
      }
    } catch (e) {
      console.log(e);
      errorToast(t("videoUpload.errors.failedToUpload"));
    }
  }

  return (
    <div className={classes.root} {...getRootProps()}>
      <img alt={"upload video"} height={250} src={UploadVideoImg} />
      <Typography className={classes.title} gutterBottom variant={"h5"}>
        {t("videoUpload.title")}
      </Typography>
      <Typography gutterBottom variant={"body1"}>
        {t("videoUpload.terms")}
      </Typography>
      <Typography gutterBottom variant={"body1"}>
        {t("videoUpload.copyrights")}
      </Typography>
      <Typography gutterBottom variant={"body1"}>
        {t("videoUpload.acceptedFiles")}
      </Typography>
      <Typography gutterBottom variant={"body1"}>
        {t("videoUpload.limit")}
      </Typography>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={(e) => {
            e.stopPropagation();
            openFileSelector();
          }}
        >
          {t("videoUpload.selectFile")}
        </Button>
      )}

      <input id={"videoUpload"} {...getInputProps()} />
    </div>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingBottom: theme.spacing(4),
    },
    title: {
      fontWeight: "bold",
    },
    button: {
      minWidth: 300,
      marginTop: theme.spacing(2),
    },
  })
);
