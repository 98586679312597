import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { IconButton, Typography } from '@mui/material'
import { faX } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import moment from 'moment'
import { AlertObject } from '../../../../api/alerts/alerts'
import { useReadAlert } from '../../../../api/alerts/read-alert'
import { useTranslation } from 'react-i18next'
import useToast from '../../../hooks/useToast'

type AlertProps = {
  alert: AlertObject
}

export default function Alert({ alert }: AlertProps) {
  const classes = useStyles()
  const [readAlert] = useReadAlert()
  const { t } = useTranslation()
  const { errorToast } = useToast()

  async function handleReadAlert(_id: string) {
    try {
      await readAlert({ alerts: [_id] })
    } catch (e) {
      errorToast(t('dashboard.alerts.errors.failedToReadAlert'))
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.card}>
        <div className={classes.cardHeader}>
          <Typography variant={'subtitle2'}>{alert.title}</Typography>
          <IconButton size={'small'} onClick={() => handleReadAlert(alert._id)}>
            <FontAwesomeIcon icon={faX as IconProp} />
          </IconButton>
        </div>
        <Typography variant={'body2'}>{alert.message} </Typography>
        <div className={classes.cardFooter}>
          <Typography variant={'caption'} color={'textSecondary'}>
            {moment.unix(alert.alert_time).format('LT DD/MM/YY')}
          </Typography>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(2),
    },
    card: {
      textAlign: 'left',
      width: '100%',
      padding: theme.spacing(2, 2, 1, 2),
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${theme.palette.divider}`,
    },
    cardHeader: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    cardFooter: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {
      height: 20,
      width: 20,
    },
  })
)
