import {
  LandingPageAction,
  LandingPageState,
  RESET_LANDING_PAGE,
  SET_LANDING_PAGE_OUTPUT,
  SET_LANDING_PAGE_TYPE,
  SET_LANDING_PAGE_URL,
  SET_PAGE_INSTRUCTIONS,
  SET_REDIRECT_OPTION,
} from "./Types";
import { initialLandingPageState } from "../Context";

export function landingPageReducer(
  state: LandingPageState,
  action: LandingPageAction
): LandingPageState {
  switch (action.type) {
    case SET_PAGE_INSTRUCTIONS:
      return { ...state, pageInstructions: action.payload };

    case SET_LANDING_PAGE_TYPE:
      return { ...state, landingPageType: action.payload };

    case SET_LANDING_PAGE_OUTPUT:
      return { ...state, landingPageOutput: action.payload };

    case SET_LANDING_PAGE_URL:
      return { ...state, landingPageUrl: action.payload };

    case SET_REDIRECT_OPTION:
      return { ...state, redirectOption: action.payload };

    case RESET_LANDING_PAGE:
      return initialLandingPageState;

    default:
      return state;
  }
}
