import { useState } from 'react'
import { Typography, Box, Divider, styled } from '@mui/material'
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import DifficultyLevelSelector from './DifficultyLevelSelector'
import PersonalizeTemplateToggle from './PersonalizeTemplateToggle'
import { theme } from '@/theme/theme'

const AdvancedTemplateSettings = () => {
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(false)

  return (
    <Box>
      <StyledExpandBox expanded={expanded} onClick={() => setExpanded((prev) => !prev)}>
        <Typography fontWeight="bold" marginRight={theme.spacing(1)}>
          {t('simulationLibrary.ai.advancedSettings')}{' '}
        </Typography>
        <FontAwesomeIcon icon={(expanded ? faChevronUp : faChevronDown) as IconProp} fontSize={12} />
      </StyledExpandBox>
      {expanded && (
        <Box>
          <PersonalizeTemplateToggle />
          <Divider />
          <DifficultyLevelSelector />
        </Box>
      )}
    </Box>
  )
}

const StyledExpandBox = styled(Box)(({ expanded }: { expanded: boolean }) => ({
  display: 'flex',
  alignItems: 'center',
  color: expanded ? theme.palette.blueDianne[600] : theme.palette.black,
}))
export default AdvancedTemplateSettings
