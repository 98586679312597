import axios from 'axios'
import { API_URL } from '../constants'
import { useMutation, useQueryCache } from 'react-query'
import { useQueryClient } from '@tanstack/react-query'
import { User } from './users'

export type NewUserParams = Pick<
  User,
  'username' | 'phone_number' | 'first_name' | 'last_name' | 'office_id' | 'member_of'
>

export type DuplicateUserErrorData = {
  existing_user: Pick<
    User,
    '_id' | 'username' | 'first_name' | 'last_name' | 'phone_number' | 'office_id' | 'member_of'
  >
  user_input: NewUserParams
  error: string
}

export type AddUsersError = {
  status: string
  status_code: number
  message: string
  data?: {
    failed_users: DuplicateUserErrorData[]
    failed_user_count: number
  }
}

export async function createNewUser(params: NewUserParams[]): Promise<User[]> {
  const url = `${API_URL}/users/signup`

  params.forEach((param) => {
    if (typeof param.member_of === 'string') {
      param.member_of = [param.member_of]
    }
  })

  const { data } = await axios.post(url, params, { withCredentials: true })
  return data.data
}

export function useNewUser() {
  const queryCache = useQueryCache()
  const queryClient = useQueryClient()
  return useMutation<User[], AddUsersError, NewUserParams[]>(createNewUser, {
    throwOnError: true,
    onSettled: () => {
      queryCache.invalidateQueries('users')
      queryCache.invalidateQueries('offices')
      queryCache.invalidateQueries('organizations')
      queryClient.invalidateQueries({ queryKey: ['members'] })
    },
  })
}
