import axios from 'axios'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { API_URL } from '../constants'
import { useQueryCache } from 'react-query'

type EditMemberRequest = {
  _id: string | undefined
  memberPayload: any
}

// export type EditUserParams = { _id: string; params: Partial<NewUserParams> }

const editMember = async ({ _id, memberPayload }: EditMemberRequest) => {
  //TODO Replace this endpoint with members{PUT}
  const url = `${API_URL}/users/edit/${_id}`
  const { data } = await axios.post(url, memberPayload, {
    withCredentials: true,
  })

  return data
}

const useEditMember = () => {
  const queryClient = useQueryClient()
  const queryCache = useQueryCache()

  return useMutation({
    mutationFn: editMember,
    onSuccess: () => {
      // ReactQuery v2 support
      queryCache.invalidateQueries('users')
      queryClient.invalidateQueries({ queryKey: ['members'] })
    },
  })
}

export default useEditMember
