import axios from 'axios';
import { API_URL } from '../constants';
import { usePaginatedQuery } from 'react-query';
import { ServerError } from '../types';

export type ActiveDirectoryGroup = {
  id: string;
  createdDateTime: string;
  description: null;
  displayName: string;
  groupTypes: string[];
  mail: string;
  mailNickname: string;
};

export type ActiveDirectoryUser = {
  username: string;
  first_name: string;
  last_name: string;
  source: 'AD';
  external_id: string;
  member_of: ActiveDirectoryGroup[];
  office_id: string;
  onboarded: boolean;
};

export type ActiveDirectoryBoardRequest = {
  users: ActiveDirectoryUser[];
  apply_all?: boolean;
};

export type ActiveDirectoryUsersQueryVariables = {
  fullname: string;
  email: string;
  group_name?: string;
  status?: 'onboarded' | 'offboarded';
  offset: number;
  startIndex: number;
};

export type ActiveDirectoryUsersResponse = {
  total_users: number;
  total_onboarded_users: number;
  total_offboarded_users: number;
  items: ActiveDirectoryUser[];
};

export async function getActiveDirectoryUsers(
  key,
  { fullname = '', email = '', group_name, status, offset = 5, startIndex = 0 },
) {
  const url = `${API_URL}/active_directory/users`;
  const { data } = await axios.post(
    url,
    { fullname, email, status, group_name, offset, startIndex },
    { withCredentials: true },
  );
  return data.data;
}

export function useActiveDirectoryUsers(variables?: ActiveDirectoryUsersQueryVariables) {
  return usePaginatedQuery<ActiveDirectoryUsersResponse, ServerError>(
    ['ad-users', { ...variables }],
    getActiveDirectoryUsers,
  );
}
