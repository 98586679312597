import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons'
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  styled,
} from '@mui/material'
import { Dispatch, SetStateAction } from 'react'

import { theme } from '@/theme/theme'
import CellContent from './CellContent'

const TableSection = ({
  section,
  title,
  setTailoredCost,
  setVideosCount,
  setVideosCost,
}: {
  section: any
  title: string
  setTailoredCost: Dispatch<SetStateAction<number>>
  setVideosCount: Dispatch<SetStateAction<number>>
  setVideosCost: Dispatch<SetStateAction<number>>
}) => {
  return (
    <Accordion
      defaultExpanded
      elevation={0}
      sx={{
        border: 'none',
        '&:before': { height: '0px' },
        width: '100%',
        '&.Mui-expanded': {
          margin: 0,
        },
      }}>
      <AccordionSummary
        expandIcon={<FontAwesomeIcon icon={faCaretDown as IconProp} width={24} />}
        sx={{
          borderTop: `1px solid ${theme.palette.cyan[500]}`,
          borderBottom: `1px solid ${theme.palette.cyan[500]}`,
          margin: theme.spacing(0, 1.2),
          padding: theme.spacing(0, 0, 0, 1),
          background: theme.palette.cyan[100],
          minHeight: '35px',
          '&.Mui-expanded': {
            minHeight: '35px',
          },
          '& .MuiAccordionSummary-content': {
            margin: 0,
            '&.Mui-expanded': {
              margin: 0,
            },
          },
        }}>
        <Typography fontSize={16} fontWeight={600}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          margin: theme.spacing(-1, -0.6, -2),
        }}>
        <Table padding="none" sx={{ width: '100%' }}>
          <TableBody sx={{ width: '100%' }}>
            {section.map((row: any) => {
              return (
                <TableRow
                  key={row.name}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                    '&:last-child td, &:last-child th': { border: 0 },
                    margin: '0 1px',
                    padding: 0,
                    height: '100%',
                  }}>
                  <TableCell component="th" scope="row" sx={{ width: '100%' }}>
                    {row.name && (
                      <Box
                        sx={{
                          display: 'flex',
                          marginLeft: theme.spacing(1),
                          alignItems: 'center',
                          height: '100%',
                          minWidth: '100%',
                          width: '100%',
                        }}>
                        <Typography fontSize={16} fontWeight={600}>
                          {row.name}
                        </Typography>
                        {row.special === 1 && (
                          <Typography marginLeft={0.5} fontSize={12} fontWeight={700} color={theme.palette.cyan[500]}>
                            *
                          </Typography>
                        )}
                        {row.special === 2 && (
                          <Typography marginLeft={0.5} fontSize={12} fontWeight={700} color={theme.palette.cyan[500]}>
                            **
                          </Typography>
                        )}
                        <Tooltip title={row.description}>
                          <FontAwesomeIcon icon={faInfoCircle as IconProp} width={40} height={20} />
                        </Tooltip>
                      </Box>
                    )}
                    {row.notes && (
                      <Box sx={{ height: '28px' }}>
                        {Object.keys(row.notes).map((note: string) => {
                          return (
                            <Box display="flex">
                              <Typography
                                color={theme.palette.cyan[500]}
                                fontSize={12}
                                fontWeight={700}
                                sx={{ width: '15px' }}>
                                {note}
                              </Typography>
                              <Typography fontSize={12} fontWeight={700}>
                                {row.notes[note]}
                              </Typography>
                            </Box>
                          )
                        })}
                      </Box>
                    )}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      padding: 0,
                      margin: 0,
                      width: '240px',
                      minWidth: '240px',
                      minHeight: '60px',
                      height: 'auto !important',
                      paddingLeft: theme.spacing(2.4),
                    }}>
                    <StyledBodyCell>
                      <CellContent
                        content={row.lite}
                        cost={row.cost}
                        special={row.special}
                        setTailoredCost={setTailoredCost}
                        setVideosCost={setVideosCost}
                        setVideosCount={setVideosCount}
                      />
                    </StyledBodyCell>
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      border: row.noBorder ? 'none' : undefined,
                      margin: 0,
                      width: '240px',
                      minWidth: '240px',
                      paddingLeft: theme.spacing(2.4),
                      minHeight: '60px',
                      height: 'auto !important',
                    }}>
                    <StyledBodyCell>
                      <CellContent
                        content={row.tailored}
                        cost={row.cost}
                        special={row.special}
                        setTailoredCost={setTailoredCost}
                        setVideosCost={setVideosCost}
                        setVideosCount={setVideosCount}
                      />
                    </StyledBodyCell>
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      padding: 0,
                      margin: 0,
                      width: '240px',
                      minWidth: '240px',
                      minHeight: '60px',
                      paddingLeft: theme.spacing(2.4),

                      height: 'auto !important',
                    }}>
                    <StyledBodyCell sx={{ borderWidth: '4px', borderColor: theme.palette.cyan[500] }}>
                      <CellContent
                        content={row.humanFirewall}
                        cost={row.cost}
                        special={row.special}
                        setTailoredCost={setTailoredCost}
                        setVideosCost={setVideosCost}
                        setVideosCount={setVideosCount}
                      />
                    </StyledBodyCell>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  )
}

const StyledBodyCell = styled(Box)(({ theme }) => ({
  border: `2px solid ${theme.palette.grey[400]}`,
  borderBottom: 'none',
  borderTop: 'none',
  overflow: 'hidden',
  display: 'flex',
  minHeight: '60px',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  boxSizing: 'border-box',
  padding: theme.spacing(0),
}))

export default TableSection
