import { Box, Button, Checkbox, FormControlLabel, Typography } from '@mui/material'
import { Dispatch, FC, MouseEvent, SetStateAction } from 'react'

import CustomParticles from '@/common/components/Particles/CustomParticles'
import VideoPlayerV2 from '@/common/components/VideoPlayerV2/VideoPlayerV2'
import useLocalStorage from '@/common/hooks/useLocalStorage'
import { theme } from '@/theme/theme'

import { useTranslation } from 'react-i18next'

type IntroScreenProps = {
  setSkipPreview: Dispatch<SetStateAction<boolean>>
}

const IntroScreen: FC<IntroScreenProps> = ({ setSkipPreview }) => {
  const { t } = useTranslation()
  const { setLocalValue } = useLocalStorage()

  const handleClick = (event: MouseEvent<HTMLInputElement>) => {
    setLocalValue('skipAiVideo', event.target.checked)
  }

  const handleSkip = () => {
    setSkipPreview(true)
  }

  return (
    <>
      <Box
        sx={{
          zIndex: -1,
          position: 'absolute',
        }}
      >
        <CustomParticles />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography fontWeight={theme.typography.fontWeightBold} fontSize={60}>
            Welcome
          </Typography>
          <Typography fontWeight={theme.typography.fontWeightBold} fontSize={25}>
            to
          </Typography>
          <Typography fontWeight={theme.typography.fontWeightBold} fontSize={30}>
            Cywareness AI
          </Typography>
        </Box>
        <Box
          sx={{
            width: '100vh',
            marginTop: theme.spacing(2),
          }}
        >
          <VideoPlayerV2
            subtitleTracks={{}}
            videoTracks={{
              en: 'https://media.cywareness.io/video/upload/v1706695564/media/AI/0072-VID-AI-Simulation-Builder-V1-4_1.mp4',
            }}
            bookmarks={[
              { title: 'Template', time: 27 },
              { title: 'Landing page', time: 155 },
              { title: 'Summary', time: 181 },
            ]}
            disableSeek={false}
          />
        </Box>
        <Box
          sx={{
            width: '100vh',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'right',
          }}
        >
          <FormControlLabel
            sx={{ background: 'rgba(255, 255, 255, 0.7)', borderRadius: theme.shape.borderRadius }}
            label={<span style={{ fontWeight: '700' }}>{t('contentLibrary.aiBuilder.intro.dontShow')}</span>}
            control={<Checkbox onClick={handleClick} />}
          />
          <Button
            variant="outlined"
            onClick={handleSkip}
            sx={{
              width: '110px',
            }}
          >
            Skip
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default IntroScreen
