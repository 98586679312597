import { Dispatch, FC, SetStateAction, useState } from 'react'
import { Box, Button, TextField, Typography, styled } from '@mui/material'
import Modal from '@mui/material/Modal'
import { useTranslation } from 'react-i18next'
import ImageUploadComponent from './ImageUploadComponent'

type UploadImageModalProps = {
  buttonClass?: string
  onFinishUpload: (url: string) => void
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

const UploadImageModal: FC<UploadImageModalProps> = ({ onFinishUpload, open, setOpen }) => {
  const { t } = useTranslation()
  const [url, setUrl] = useState('')

  function handleClose() {
    setOpen(false)
  }

  const handleUpdate = () => {
    onFinishUpload(url)
    handleClose()
  }
  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <ModalConent>
          <TitleBar>{t('uploadImageModal.title')}</TitleBar>
          <ImageUploadComponent
            onUpload={(image) => {
              setUrl(image.url)
            }}
          ></ImageUploadComponent>
          <Typography>Or</Typography>
          <StyledTextField
            size="small"
            value={url}
            label={'Load from URL'}
            onChange={(value) => {
              setUrl(value.target.value)
            }}
          />
          <ButtonsWrapper>
            <Button variant="outlined" onClick={handleClose}>
              {t('uploadImageModal.cancel')}
            </Button>
            <Button onClick={handleUpdate} disabled={!url.length} variant="contained">
              {t('uploadImageModal.upload')}
            </Button>
          </ButtonsWrapper>
        </ModalConent>
      </Modal>
    </>
  )
}

const StyledTextField = styled(TextField)(() => ({
  height: '55px',
  width: '440px',
  marginTop: '5px',
}))

const ButtonsWrapper = styled(Box)(() => ({
  padding: '20px 20px 30px',
  display: 'flex',
  gap: 10,
  flexDirection: 'row',
  justifyContent: 'flex-end',
  width: '100%',
}))
// const StyledButton = styled(Button)(() => ({
//   px: '30px',
//   py: '10px',
//   width: '110px',
//   height: '30px',
//   fontSize: '16px',
//   fontWeight: 500,
//   color: 'black',
//   boxShadow: 'none',
// }))

const TitleBar = styled(Box)(() => ({
  display: 'flex',
  color: '#05DBF2',
  alignItems: 'center',
  padding: '15px 26px',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  background: '#000',
  borderRadius: '10px 10px 0px 0px',
  width: '100%',
}))
const ModalConent = styled(Box)(() => ({
  fontFamily: 'Montserrat',
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -40%)',
  width: 500,
  backgroundColor: 'white',
  p: 4,
  '&:focus-visible': {
    outline: 'none',
  },
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

export default UploadImageModal
