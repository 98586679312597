import { FC } from 'react'
import { makeStyles, createStyles } from '@mui/styles'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import { Link } from 'react-router-dom'
import { Box, styled } from '@mui/material'

import { useBanners } from '@/api/banners/get'
import { theme } from '@/theme/theme'

type BannerCarouselProps = {
  interval?: number //milliseconds
}
const BannerCarousel: FC<BannerCarouselProps> = ({ interval }) => {
  const classes = useStyles()
  const { data: banners, isLoading } = useBanners()
  if (isLoading) {
    return <></>
  }
  return (
    <Carousel
      autoPlay={!!interval}
      interval={interval}
      infiniteLoop
      showThumbs={false}
      showArrows={false}
      showIndicators={true}
      showStatus={false}
      renderIndicator={(
        clickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void,
        isSelected: boolean,
        index: number,
        label: string
      ) => {
        return (
          <li
            style={{
              background: isSelected ? theme.palette.cyan[500] : theme.palette.grey[700],
              opacity: 1,
              border: 'solid 0.5px' + theme.palette.white,
              width: '10px',
              height: '10px',
            }}
            onClick={clickHandler}
            className={isSelected ? 'dot selected' : 'dot'}
            value={index}
            role="button"
            aria-label={label}></li>
        )
      }}>
      {banners?.map((banner: any, i: number) => {
        return (
          <Link to={banner.cta_link} key={i}>
            <Banner backgroundUrl={banner.background_url}>
              {banner.content_url && <img className={classes.image} src={banner.content_url} />}
            </Banner>
          </Link>
        )
      })}
    </Carousel>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    image: {
      position: 'relative',
      height: '100%',
      maxWidth: '100%',
      objectFit: 'contain',
      pointerEvents: 'none',
    },
  })
)

const Banner = styled(Box)(({ backgroundUrl }: { backgroundUrl: string }) => ({
  height: 260,
  position: 'relative',
  borderRadius: 20,
  backgroundImage: `url(${backgroundUrl})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  boxShadow: 'inset dimGray 0px 0px 60px -12px',
  overflow: 'hidden',
}))
export default BannerCarousel
