import { LandingPageOptionProps } from '../components/LandingPageTypeSelection'

type PromptTemplateProps = {
  template: string
  inputVariables: string[]
}

const formatPrompt = (templateProps: PromptTemplateProps, variables: any, isMobile: boolean): string => {
  let formatted = templateProps.template

  for (const variable of templateProps.inputVariables) {
    formatted = formatted.replace(`{${variable}}`, variables[variable])
  }

  return formatted.trim().replace(/(\n\s*\n)/g, '\n')
}

const landingPagePromptTemplate: PromptTemplateProps = {
  template: `
    - Act as a senior HTML & CSS developer.
      {headerFooter}
      {yearInstructions}
      {logoUsage}
      {themeUsage}
      {command}
      {styling}
    {constructFormInstructions}
    {constructPageSizeInstructions}
    {specificInstructions}
    {additionalInstructions}
    {htmlConstraints}
    `,
  inputVariables: [
    'headerFooter',
    'yearInstructions',
    'logoUsage',
    'themeUsage',
    'command',
    'styling',
    'specificInstructions',
    'additionalInstructions',
    'constructPageSizeInstructions',
    'constructFormInstructions',
    'htmlConstraints',
  ],
}

type PageSizeProps = {
  width: string
  height: string
}

export const createLandingPagePrompt = (
  landingPageType: LandingPageOptionProps | null,
  pageInstructions: string,
  customLogo?: string,
  appName?: string,
  iconTheme?: string,
  buttonColor?: string,
  buttonTextColor?: string,
  appLandingTemplate?: string,
  isMobile: boolean = false
): string => {
  const brandedAppName = appName
    ? `${landingPageType?.title} for ${appName} app. Here is a description of the login page: ${appLandingTemplate}, Please use the following as a guide to create the ${landingPageType?.title} page. Don't add dimensions to the page in plain text`
    : `${landingPageType?.title} page`

  const htmlConstraints = `- Return HTML only ! NO any other comments (no backticks, NO name attribute ins in inputs, DO NOT wrap in quotations, no (\\\'\`). Use designs similar to material or bootstrap, using rounded borders and blending colors.`

  const pageSizeInstructions = `- Set the page width to a maximum of 100vw and height to a minimum of 100vh respectively. Align the header and footer to the top and bottom.`

  const formInstructions = `- Input fields MUST BE IN A FORM and should span a maximum of 80% of the container and be centered, container max 400px. All fields should be centered. Always include a call to action button to submit the form. Add relevant instructions above the form.`

  const headerFooter = isMobile
    ? `Create a well-designed mobile compatible ${brandedAppName} with header and footer.`
    : `Create a well-designed ${brandedAppName} with header and footer.`

  const logoUsage = customLogo
    ? `- The logo at the header should have ${
        isMobile ? 'max-width 60%' : 'max height 100px'
      } and use this image url ${customLogo}`
    : ''

  const themeUsage = iconTheme
    ? `- Use this theme ${iconTheme} for the header & footer. ${buttonColor} for the button background, and ${buttonTextColor} for the button text`
    : ''

  const yearInstructions = `- If adding a year in the page please use the current year which is ${new Date().getFullYear()}`

  const additionalInstructions = pageInstructions ? `- Additional instructions: ${pageInstructions}` : ''

  const specificInstructions = landingPageType?.templateSpecificInstructions
    ? `- Additional guidance: ${landingPageType.templateSpecificInstructions}`
    : ''

  return formatPrompt(
    landingPagePromptTemplate,
    {
      headerFooter,
      yearInstructions,
      logoUsage,
      themeUsage,
      command: landingPageType?.command,
      specificInstructions,
      additionalInstructions,
      constructPageSizeInstructions: pageSizeInstructions,
      constructFormInstructions: formInstructions,
      htmlConstraints,
    },
    isMobile
  )
}
