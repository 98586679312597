import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ActiveDirectoryUsersTable from "./ActiveDirectoryUsersTable";
import useToast from "../../../common/hooks/useToast";
import OnboardUsersModal from "./OnboardModal";
import {
  OnboardUsersResponse,
  useOnboardActiveDirectoryUsers,
} from "../../../api/active-directory/onboard";
import { useOffboardActiveDirectoryUsers } from "../../../api/active-directory/offboard";
import {
  ActiveDirectoryUser,
  ActiveDirectoryUsersQueryVariables,
  ActiveDirectoryBoardRequest,
} from "../../../api/active-directory/users";

export type GroupFilters = ActiveDirectoryUsersQueryVariables["status"];

export default function ConnectedActiveDirectory() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { errorToast, successToast } = useToast();
  const [onboard] = useOnboardActiveDirectoryUsers();
  const [offboard, { isLoading: isOffboarding }] =
    useOffboardActiveDirectoryUsers();
  const [selectedUsers, setSelectedUsers] = useState<ActiveDirectoryUser[]>([]);
  const [onboardingModalIsOpen, setOnboardingModalIsOpen] = useState(false);

  async function handleOnboardUsers(
    activeDirectoryUsersRequest: ActiveDirectoryBoardRequest
  ): Promise<OnboardUsersResponse> {
    try {
      const users = await onboard(activeDirectoryUsersRequest);
      successToast(
        t(
          `users.activeDirectory.usersTable.${
            activeDirectoryUsersRequest.apply_all
              ? "onboardAllSuccess"
              : "onboardSuccess"
          }`
        )
      );
      return users;
    } catch (e) {
      errorToast(t("users.activeDirectory.errors.failedToOnboard"));
    }
  }

  async function handleOffboardUsers(
    activeDirectoryUsersRequest: ActiveDirectoryBoardRequest
  ) {
    try {
      await offboard(activeDirectoryUsersRequest);
      successToast(t("users.activeDirectory.usersTable.offboardSuccess"));
    } catch (e) {
      errorToast(t("users.activeDirectory.errors.failedToOffboard"));
    }
  }

  function handleOpenOnboardingModal(users: ActiveDirectoryUser[]) {
    setSelectedUsers(users);
    setOnboardingModalIsOpen(true);
  }

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <Typography variant={"h5"} className={classes.title}>
          {t("users.activeDirectory.activeDirectory")}
        </Typography>
      </div>
      <OnboardUsersModal
        setOpen={setOnboardingModalIsOpen}
        open={onboardingModalIsOpen}
        handleOnboard={handleOnboardUsers}
        users={selectedUsers}
      />
      <ActiveDirectoryUsersTable
        handleOnboardUser={handleOnboardUsers}
        handleOpenOnboardingModal={handleOpenOnboardingModal}
        handleOffboardUser={handleOffboardUsers}
        loading={isOffboarding}
      />
    </div>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    titleContainer: {
      display: "flex",
      alignItems: "center",
    },
    title: {
      marginRight: theme.spacing(1),
      fontWeight: "bold",
    },
    sync: {
      marginRight: theme.spacing(4),
      alignSelf: "flex-end",
    },
    button: {
      marginRight: theme.spacing(2),
      minWidth: 120,
    },
  })
);
