import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, Tooltip, Typography, useTheme } from '@mui/material'
import { startCase } from 'lodash'
import moment from 'moment'
import { FC, useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ColumnInterface } from 'react-table'
import { CampaignUsersQuery, useCampaignUsers } from '@/api/campaigns/getUsers'
import { useClientDetails } from '@/api/client/client'
import PaginatingTable from '@/common/components/Tables/PaginatingTable'
import { CampaignUser } from '@/common/types/campaign.types'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import TableRowScore from './TableRowScore'

type CampaignUsersTableProps = {
  campaignId: string | undefined
  hasQuiz: boolean
}

const TrainingCampaignUsersTable: FC<CampaignUsersTableProps> = ({ campaignId, hasQuiz }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const navigateTo = useNavigate()
  const [pageCount, setPageCount] = useState(0)

  const [queryFilters, setQueryFilters] = useState<CampaignUsersQuery>({ id: campaignId, limit: 10, skip: 0 })

  const { data: usersData, isPending } = useCampaignUsers(queryFilters, { refetchInterval: 10_000 })

  const { results: users, total: count } = usersData ?? { count: 0, users: [] }
  const { data: clientDetails } = useClientDetails()

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      setQueryFilters((prevState) => {
        return {
          ...prevState,
          skip: (prevState.limit as number) * pageIndex || 0,
          limit: pageSize,
        }
      })
      setPageCount(Math.ceil((count as number) / pageSize))
    },
    [count]
  )

  const columns: ColumnInterface[] = useMemo(
    () => [
      {
        Header: startCase(t('campaignDashboard.name')),
        accessor: 'first_name',
        disableSortBy: true,
        Cell: (data) => {
          const user = data.row.original

          return `${user.first_name} ${user.last_name}`
        },
      },
      {
        Header: startCase(t('trainingCampaignDashboard.usersTable.email')),
        accessor: 'username',
        disableSortBy: true,
        Cell: (data) => {
          const allowUnverified = clientDetails?.allow_unverified_domains
          const user = data.row.original

          if (!user.domain_verified && !allowUnverified) {
            user.disabled = true
          }

          return (
            <div>
              <span>{user.username}</span>
              {user.disabled ? (
                <Tooltip title={<TableBodyTooltip member={user} />} placement="right">
                  <FontAwesomeIcon icon={faTriangleExclamation as IconProp} className={classes.warningIcon} />
                </Tooltip>
              ) : (
                ''
              )}
            </div>
          )
        },
      },
      {
        Header: startCase(t('trainingCampaignDashboard.usersTable.started')),
        accessor: 'events.training_opened',
        disableSortBy: true,
        Cell: ({ value }) => (value ? moment(value).format('DD/MM/YY') : ''),
      },
      {
        Header: startCase(t('trainingCampaignDashboard.usersTable.completed')),
        accessor: 'events.training_completed',
        disableSortBy: true,
        Cell: (data) => {
          const user = data.row.original as CampaignUser
          const completedTimestamp = user.events.training_completed
          const completedQuizTimestamp = user.events.quiz_completed

          return (completedTimestamp && completedQuizTimestamp) || (completedTimestamp && !hasQuiz)
            ? moment(completedTimestamp as string).format('DD/MM/YY')
            : ''
        },
      },
      {
        Header: startCase(t('trainingCampaignDashboard.usersTable.score')),
        accessor: 'additional_data',
        disableSortBy: true,
        Cell: (data) => {
          const user = data.row.original as CampaignUser
          const quizScore = Math.ceil(user.campaign_score) || 0
          return (
            <div className={classes.score}>
              {' '}
              <TableRowScore color={theme.palette.blueGray[900]} value={quizScore} />
            </div>
          )
        },
      },
    ],
    [t, hasQuiz, theme.palette.blueGray[900], classes.score]
  )

  const handleNavigateToUserProfile = ({ original }) => {
    navigateTo(`/recipients/users/${original._id}`)
  }

  return (
    <div className={classes.table}>
      <PaginatingTable
        enableCheckbox={false}
        columns={columns}
        data={users ?? []}
        fetchData={fetchData}
        loading={isPending}
        pageCount={pageCount}
        count={count}
        noResultsTextId={'trainingCampaignDashboard.usersTable.noResults'}
        onRowClick={handleNavigateToUserProfile}
      />
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    table: {
      '& .MuiTableCell-head': {
        textAlign: 'center',
        paddingLeft: 40,
        '&:first-child': {
          paddingLeft: 15,
          textAlign: 'left',
        },
      },
      '& .MuiTableCell-body': {
        textAlign: 'center',
        '&:first-child': {
          textAlign: 'left',
        },
      },
    },
    score: {
      height: 40,
      width: 40,
      margin: 'auto',
    },
    tooltip: {
      textDecoration: 'underline #D1F6FF',
      color: '#D1F6FF',
    },
    tooltipText: {
      fontSize: '12px',
      fontWeight: 'normal',
    },
    warningIcon: {
      color: '#F5A623',
      height: 18,
      margin: theme.spacing(0, 0.5),
    },
  })
)

const TableBodyTooltip: FC<{ member: CampaignUser }> = ({ member }) => {
  const classes = useStyles()
  return (
    <Typography className={classes.tooltipText}>
      <Trans
        i18nKey={!member.domain_verified ? 'campaignDashboard.usersTable.unverifiedDomain' : ''}
        components={{
          1: (
            <Link
              href={!member.domain_verified ? '../../settings/allowlisting' : ''}
              target="_blank"
              className={classes.tooltip}
            />
          ),
        }}
      />
    </Typography>
  )
}

export default TrainingCampaignUsersTable
