import { FC } from 'react'

type ShieldWithMagnifyingGlassIconProps = {
  color: string
  size: number
}
const ShieldWithMagnifyingGlassIcon: FC<ShieldWithMagnifyingGlassIconProps> = ({ color, size }) => {
  return (
    <div style={{ width: size, height: size }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
        <path
          fill={color}
          d="M16.6 113l1.2-20.5L36.9 85 244.4 4.5 256 0l11.6 4.5L475.1 85c6.4 2.5 12.8 4.9 19.2 7.5l1.2 20.5c.9 15.7 .8 33.9-.9 53.9c-10.2-3.1-20.7-5.2-31.6-6.2c1.2-17 1.3-32.5 .5-45.8L256 34.3 48.5 114.9C45.8 160.5 53 231.7 83.2 301.2s83.4 137.3 172.8 175.9c29.2-12.7 54.6-28.4 76.5-46.3c7.5 7.8 15.8 14.9 24.8 21.1c-25.1 21.2-54.4 39.9-88.5 54.6L256 512l-12.7-5.5C144.9 463.9 86.5 389.2 53.9 313.9C21.4 239.3 13.6 162.9 16.6 113zM322 320.2c0-70.7 57.3-128 128-128s128 57.3 128 128c0 29.6-10 56.8-26.9 78.5l78.1 77.7 11.3 11.3-22.6 22.7L606.7 499c-26.1-25.9-52.1-51.9-78.2-77.7c-21.7 16.9-48.9 26.9-78.5 26.9c-70.7 0-128-57.3-128-128zm32 0c0 34.3 18.3 66 48 83.1s66.3 17.1 96 0s48-48.8 48-83.1s-18.3-66-48-83.1s-66.3-17.1-96 0s-48 48.8-48 83.1z"
        />
      </svg>
    </div>
  )
}
export default ShieldWithMagnifyingGlassIcon
