import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { AdminAnalytics } from '@/api/admin-analytics/admin-analytics'
import { useReportsAnalytics } from '@/api/reports/reports-analytics'

type PendingAlertsProps = {
  reports: AdminAnalytics['pending_reports']['items']
}
const PendingAlerts: FC<PendingAlertsProps> = ({ reports }) => {
  const classes = useStyles()
  const { data } = useReportsAnalytics()
  const { t } = useTranslation()
  const navigateTo = useNavigate()

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <Typography variant={'subtitle2'} whiteSpace="nowrap">
          {t('dashboard.pendingAlerts.title')} ({data?.pending_reports ?? 0})
        </Typography>
        <Button onClick={() => navigateTo('/response-console')} variant={'outlined'} size={'small'}>
          {t('dashboard.pendingAlerts.seeAll')}
        </Button>
      </div>
      {reports.length < 1 ? (
        <div className={classes.noResults}>
          <Typography variant={'subtitle2'} color={'textSecondary'}>
            {t('dashboard.pendingAlerts.noResults')}
          </Typography>
        </div>
      ) : (
        <List className={classes.list}>
          {reports.slice(0, 2).map((report) => (
            <ListItem button onClick={() => navigateTo(`/response-report/${report._id}`)}>
              <Box display="flex" gap="20px">
                <ListItemAvatar className={classes.avatar}>
                  <FontAwesomeIcon icon={faTriangleExclamation as IconProp} fontSize={30} />
                </ListItemAvatar>
                <ListItemText primary={report.reported_by} secondary={report.subject} />
                <Typography variant={'caption'} color={'textSecondary'} whiteSpace="nowrap">
                  {moment.unix(report.date_reported).fromNow()}
                </Typography>
              </Box>
            </ListItem>
          ))}
        </List>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    titleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    noResults: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    list: {
      width: '100%',
    },
    avatar: {
      color: theme.palette.error.main,
    },
  })
)

export default PendingAlerts
