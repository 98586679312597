import axios from "axios";
import { API_URL, CLOUDINARY_API_KEY } from "../constants";
import { useMutation } from "react-query";
import { ServerError } from "../types";

type UploadResponse = {
  url: string;
  resource_type: "video" | "image" | "raw";
  duration?: number;
};

export async function uploadVideoToCloudinary(file) {
  const XUniqueUploadId = +new Date();

  const signature_url = `${API_URL}/assets/generate_video_upload_signature`;
  const { data: signatureResponse } = await axios.get(signature_url, {
    withCredentials: true,
  });

  const signature = signatureResponse.data;

  return await processFile();

  async function processFile() {
    const size = file.size;
    const sliceSize = 20000000;
    let start = 0;

    return await loop();

    async function loop() {
      let end = start + sliceSize;

      if (end > size) {
        end = size;
      }
      const s = await slice(file, start, end);
      const data = await send(s, start, end - 1, size);
      if (data?.secure_url) {
        return {
          url: data.secure_url,
          resource_type: "video",
          duration: data.duration,
        } as UploadResponse;
      }
      if (end < size) {
        start += sliceSize;
        return await loop();
      }
    }
  }

  async function send(piece, start, end, size) {
    if (signature && CLOUDINARY_API_KEY) {
      const upload_url = `https://api.cloudinary.com/v1_1/${signature.cloud_name}/upload`;

      const formData = new FormData();
      formData.append("api_key", CLOUDINARY_API_KEY as string);
      formData.append("timestamp", signature.timestamp);
      formData.append("signature", signature.signature);
      formData.append("folder", signature.folder);
      formData.append("raw_convert", "google_speech:vtt:en-US");
      formData.append("notification_url", signature.notification_url);
      formData.append("file", piece);

      const { data } = await axios.post(upload_url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Content-Range": "bytes " + start + "-" + end + "/" + size,
          "X-Unique-Upload-Id": XUniqueUploadId,
        },
      });

      return data;
    }
  }

  function slice(file, start, end) {
    var slice = file.mozSlice
      ? file.mozSlice
      : file.webkitSlice
      ? file.webkitSlice
      : file.slice
      ? file.slice
      : noop;

    return slice.bind(file)(start, end);
  }

  function noop() {}
}

export function useUploadVideoToCloudinary() {
  return useMutation<UploadResponse | undefined, ServerError, File>(
    uploadVideoToCloudinary
  );
}
