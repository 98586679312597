import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { API_URL } from '@/api/constants'

const editAdminRole = async (_id: string, role: string) => {
  const url = `${API_URL}/admins/${_id}`
  const { data } = await axios.put(url, { role: role }, { withCredentials: true })

  return data
}

export const useEditAdminRole = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ id, role }: { id: string; role: string }) => editAdminRole(id, role),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['admins'],
      })
    },
  })
}
