import { TimePickerProps as MuiTimePickerProps } from '@mui/lab/TimePicker'
import MuiTextField from '@mui/material/TextField'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import dayjs from 'dayjs'
import { FieldHookConfig, useField } from 'formik'
import { FC } from 'react'

interface TimePickerProps
  extends Omit<MuiTimePickerProps<dayjs.Dayjs>, 'name' | 'value' | 'error' | 'onChange' | 'onBlur'>,
    Pick<FieldHookConfig<string>, 'name'> {
  disableFormik?: boolean
}

const FormikTimePicker: FC<TimePickerProps> = ({
  name,
  disableFormik = false,
  InputProps,
  InputLabelProps,
  ...otherProps
}) => {
  const [field, meta, helpers] = useField(name)

  const handleChange = (value: dayjs.Dayjs | null) => {
    helpers.setValue(value)
  }

  const handleBlur = () => {
    helpers.setTouched(true)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        ampm={false}
        {...field}
        {...otherProps}
        onChange={handleChange}
        renderInput={(params) => (
          <MuiTextField
            {...params}
            onBlur={handleBlur}
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            sx={{
              width: '100%',
              '& .MuiInputBase-input': {
                height: '5px',
                paddingX: '16px 4px',
              },
            }}
            InputLabelProps={{
              shrink: true,
              ...InputLabelProps,
              children: undefined,
            }}
          />
        )}
      />
    </LocalizationProvider>
  )
}

export default FormikTimePicker
