import { Container, useTheme } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { usePhishingSimulationDelete } from '@/api/phishing-simulations/delete'
import { usePhishingSimulation } from '@/api/phishing-simulations/get'
import { useToggleSavedContent } from '@/api/profile/toggle-saved-asset'
import LaunchWizard from '@/common/components/LaunchWizard/LaunchWizard'
import LoadingContainer from '@/common/components/LoadingContainer/LoadingContainer'
import useToast from '@/common/hooks/useToast'
import { AssetType } from '@/types/campaigns'
import { PhishingSimulation } from '@/types/phishingSimulations'
import SimulationPageDetails from './SimulationPageDetails'
import SimulationPagePreviews from './SimulationPagePreviews'
import SimulationPageStats from './SimulationPageStats'
import SimulationPageTopBar from './SimulationPageTopBar'

const SimulationPage = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { id = '' } = useParams<'id'>()
  const { errorToast, successToast } = useToast()

  const [saveAsset] = useToggleSavedContent()
  const { data: simulation, isPending } = usePhishingSimulation(id)
  const { mutateAsync: deletePhishingSimulation } = usePhishingSimulationDelete()

  const [launchWizardIsOpen, setLaunchWizardIsOpen] = useState(false)

  const handleDeleteSimulation = async (simulation: PhishingSimulation) => {
    if (!simulation) return

    try {
      await deletePhishingSimulation(simulation.id)
      successToast(t('simulationLibrary.deleted'))

      navigate('/content-library/simulations')
    } catch (error) {
      errorToast(t('simulationLibrary.errors.failedToDelete'))
    }
  }

  const handleEditSimulation = async (simulation: PhishingSimulation) => {
    navigate(`/content-library/simulations/edit/${simulation.id}`)
  }

  const handleSaveSimulation = async (simulation: PhishingSimulation) => {
    try {
      await saveAsset(simulation.id)
    } catch (error) {
      errorToast(t('simulationLibrary.errors.failedToSave'))
      console.log(error)
    }
  }

  if (!simulation && !isPending) {
    navigate('/content-library/simulations')
  }

  if (!simulation) {
    return <LoadingContainer />
  }

  return (
    <>
      <LaunchWizard
        campaignType={AssetType.phishing_simulation}
        open={launchWizardIsOpen}
        onClose={() => setLaunchWizardIsOpen(false)}
        assets={[simulation]}
      />
      <SimulationPageTopBar onLaunch={() => setLaunchWizardIsOpen(true)} />
      <Container style={{ padding: theme.spacing(0, 4), maxWidth: 1600 }}>
        <SimulationPageStats simulation={simulation} />
        <SimulationPagePreviews simulation={simulation} />
        <SimulationPageDetails
          simulation={simulation}
          onDeleteSimulation={handleDeleteSimulation}
          onSaveSimulation={handleSaveSimulation}
          onEditSimulation={handleEditSimulation}
        />
      </Container>
    </>
  )
}

export default SimulationPage
