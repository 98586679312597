import React, { FC } from 'react'
import { useTheme } from '@mui/material/styles'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Tags from '../../../../common/components/Tags/Tags'
import { VideoDetails } from './VideoPage'

const VideoPageTopics: FC<VideoDetails> = ({ video }) => {
  const { t } = useTranslation()
  const theme = useTheme()

  if (!video) {
    return <></>
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginRight: theme.spacing(2),
      }}
    >
      <Typography fontWeight={theme.typography.fontWeightBold} style={{ marginRight: theme.spacing(1) }}>
        {t('videoLibrary.topics')}:
      </Typography>

      <Tags
        useChips={false}
        tags={video.tags.map((tag) => ({
          label: tag,
          color: 'transparent',
          textColor: theme.palette.text.secondary,
        }))}
        limit={2}
      />
    </div>
  )
}

export default VideoPageTopics
