import axios from "axios";
import { API_URL } from "../constants";
import { useMutation } from "react-query";
import { ServerError } from "../types";

export async function unlockUser(token: string): Promise<boolean> {
  try {
    await axios.post(`${API_URL}/auth/unlock-user/`, { token });
    return true;
  } catch (error) {
    return false;
  }
}

export function useUnlockUser() {
  return useMutation<boolean, ServerError, string>(unlockUser);
}
