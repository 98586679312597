import React from 'react'
import Autocomplete, { AutocompleteProps, AutocompleteChangeReason } from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { useField, useFormikContext } from 'formik'
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import CircularProgress from '@mui/material/CircularProgress'
import InputAdornment from '@mui/material/InputAdornment'

interface FormikAutocompleteProps<T> {
  name: string
  options: T[]
  required?: boolean
  getOptionLabel: (option: T) => string
  disableFormik?: boolean
  label?: string
}

const FormikAutocomplete = <T,>({
  name,
  options,
  required,
  getOptionLabel,
  disableFormik = false,
  value,
  ...props
}: FormikAutocompleteProps<T> &
  Omit<AutocompleteProps<T, false, false, false>, 'options' | 'renderInput' | 'getOptionLabel'>) => {
  const [field, meta] = useField(name)
  const { setFieldValue } = useFormikContext()

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: T | null,
    reason: AutocompleteChangeReason
  ) => {
    if (!disableFormik) {
      setFieldValue(name, value)
    }

    if (props.onChange) {
      props.onChange(event, value, reason)
    }
  }

  return (
    <Autocomplete
      {...props}
      options={options}
      getOptionLabel={getOptionLabel}
      value={field.value || value}
      onChange={handleChange}
      sx={{
        '& .MuiAutocomplete-input': {
          height: '5px',
          paddingX: '4px',
        },
      }}
      renderInput={(params) => {
        const { InputLabelProps, InputProps, inputProps, ...rest } = params

        return (
          <TextField
            {...rest}
            name={name}
            required={required}
            label={props.label}
            placeholder={props.placeholder}
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            InputLabelProps={{
              shrink: true,
              ...InputLabelProps,
              children: undefined,
            }}
            InputProps={{
              ...InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faMagnifyingGlass as IconProp} width={16} />
                </InputAdornment>
              ),

              endAdornment: (
                <>
                  {props.loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            inputProps={{
              ...inputProps,
            }}
          />
        )
      }}
    />
  )
}

export default FormikAutocomplete
