import { theme } from '@/theme/theme'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faBoxOpen, faComment, faEnvelope, faHeart, faTrash } from '@fortawesome/pro-light-svg-icons'
import { faHeart as faSolidHeart } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, Typography, keyframes, styled } from '@mui/material'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import deleteImg from '@/assets/images/popup/delete.svg'
import LaunchWizard from '@/common/components/LaunchWizard/LaunchWizard'
import Popup from '@/common/components/Popup'
import Tags from '@/common/components/Tags/Tags'
import { useAuth } from '@/context/Auth'
import { AssetType } from '@/types/campaigns'
import {
  PhishingSimulationPackage,
  PhishingSimulationPackageExtended,
  PhishingSimulationVector,
} from '@/types/phishingSimulations'
import {
  StyledHeartIcon,
  StyledPackIcon,
  StyledTrashIcon,
  StyledVerticalBox,
  StyledVerticalTypography,
} from './SimulationPackageCard'
import { getPackageCover } from './SimulationPackageCardUtils'

type SimulationPackageCarouselCardProps = {
  simulationPackage: PhishingSimulationPackageExtended
  isSaved?: boolean
  showPreview: (simulation: PhishingSimulationPackage) => void
  deleteSimulationPackage: (simulationPackage: PhishingSimulationPackage) => void
  saveSimulationPackageToggle: (simulationPackage: PhishingSimulationPackage) => void
}

const SimulationPackageCarouselCard: FC<SimulationPackageCarouselCardProps> = ({
  simulationPackage,
  isSaved,
  showPreview,
  deleteSimulationPackage,
  saveSimulationPackageToggle,
}) => {
  const [hover, setHover] = useState(false)
  const { t } = useTranslation()
  const [isDeletePopUpOpen, setIsDeletePopUpOpen] = useState(false)
  const [launchWizardIsOpen, setLaunchWizardIsOpen] = useState(false)
  const { user } = useAuth()
  return (
    <>
      <LaunchWizard
        campaignType={AssetType.phishing_simulation}
        open={launchWizardIsOpen}
        onClose={() => setLaunchWizardIsOpen(false)}
        assets={simulationPackage.simulations}
        packageName={simulationPackage.name}
      />
      <Popup
        open={isDeletePopUpOpen}
        onClose={() => {
          setIsDeletePopUpOpen(false)
        }}
        onConfirm={() => {
          deleteSimulationPackage(simulationPackage)
          setIsDeletePopUpOpen(false)
        }}
        buttonLabels={{
          confirm: t('simulationPackage.deletePopup.confirmButton'),
          cancel: t('simulationPackage.deletePopup.cancelButton'),
        }}
        icon={deleteImg}
      >
        <h4>{t('simulationPackage.deletePopup.title')}</h4>
        <p>{t('simulationPackage.deletePopup.message', { simulationName: simulationPackage.name })}</p>
      </Popup>

      <StyledCardWrapper
        expanded={hover}
        onMouseEnter={() => {
          setHover(true)
        }}
        onMouseLeave={() => {
          setHover(false)
        }}
      >
        <StyledCard
          backgroundImage={getPackageCover(simulationPackage.vectors)}
          expanded={hover}
          onClick={() => {
            showPreview(simulationPackage)
          }}
        >
          <StyledSideBox expanded={hover}>
            <StyledVerticalBox>
              <StyledVerticalTypography align="center">{simulationPackage.name}</StyledVerticalTypography>
            </StyledVerticalBox>
            <StyledPackIcon icon={faBoxOpen as IconProp} fontSize={18} />
          </StyledSideBox>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            {hover && (
              <>
                <StyledFlexReversedBox
                  sx={{
                    animation: `${keyframes`0% {opacity:0}100% {opacity:1}`} 1s forwards`,
                  }}
                >
                  <StyledTrashIcon
                    icon={faTrash as IconProp}
                    disabled={simulationPackage.organization_id !== user?.current_client_id}
                    onClick={(e) => {
                      e.stopPropagation()
                      if (simulationPackage.organization_id !== user?.current_client_id) return
                      setIsDeletePopUpOpen(true)
                    }}
                  />
                  <StyledHeartIcon
                    icon={isSaved ? faSolidHeart : (faHeart as IconProp)}
                    fontSize={24}
                    onClick={(e) => {
                      e.stopPropagation()
                      saveSimulationPackageToggle(simulationPackage)
                    }}
                  />
                </StyledFlexReversedBox>
                <StyledWhiteBottomBox>
                  <StyledInfoBox
                    sx={{
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography
                        sx={{
                          marginRight: theme.spacing(1),
                          fontSize: '12px',
                          fontWeight: theme.typography.fontWeightSemiBold,
                        }}
                      >
                        {t('simulationLibrary.topics')}:
                      </Typography>
                      <Tags
                        useChips={false}
                        fontSize={14}
                        tags={
                          simulationPackage.tags?.map((tag) => ({
                            label: tag,
                            color: 'transparent',
                            textColor: theme.palette.common.black,
                          })) || []
                        }
                        limit={1}
                        inPackageCard
                      />
                    </Box>
                    <Button
                      size="small"
                      variant="contained"
                      onClick={(e) => {
                        e.stopPropagation()
                        setLaunchWizardIsOpen(true)
                      }}
                    >
                      {t('simulationPackage.launch')}
                    </Button>
                  </StyledInfoBox>
                  <StyledInfoBox>
                    <StyledFlexCenterBox>
                      <Typography
                        sx={{
                          marginRight: theme.spacing(1),
                          fontSize: '12px',
                          fontWeight: theme.typography.fontWeightSemiBold,
                        }}
                      >
                        {t('simulationLibrary.difficulty')}:
                      </Typography>
                      <Typography sx={{ marginRight: theme.spacing(1), fontSize: '12px' }}>
                        {simulationPackage.difficulties &&
                          simulationPackage.difficulties[0] +
                            '-' +
                            simulationPackage.difficulties[simulationPackage.difficulties.length - 1]}
                      </Typography>
                    </StyledFlexCenterBox>
                    <StyledFlexCenterBox>
                      <Typography
                        sx={{
                          marginRight: theme.spacing(1),
                          fontSize: '12px',
                          fontWeight: theme.typography.fontWeightSemiBold,
                        }}
                      >
                        {t('simulationLibrary.vector')}:
                      </Typography>
                      <StyledVectorBox>
                        {simulationPackage.vectors?.includes(PhishingSimulationVector.SMS) && (
                          <FontAwesomeIcon icon={faComment as IconProp} fontSize={16} />
                        )}
                        {simulationPackage.vectors?.includes(PhishingSimulationVector.Email) && (
                          <FontAwesomeIcon icon={faEnvelope as IconProp} fontSize={16} />
                        )}
                        {simulationPackage.vectors?.includes(PhishingSimulationVector.WhatsApp) && (
                          <FontAwesomeIcon icon={faWhatsapp as IconProp} fontSize={16} />
                        )}
                      </StyledVectorBox>
                    </StyledFlexCenterBox>
                  </StyledInfoBox>
                </StyledWhiteBottomBox>
              </>
            )}
          </Box>
        </StyledCard>
      </StyledCardWrapper>
    </>
  )
}

const StyledVectorBox = styled(Box)(() => ({
  display: 'flex',
  gap: '4px',
}))

const StyledWhiteBottomBox = styled(Box)(() => ({
  width: '100%',
  height: '52px',
  background: theme.palette.white,
  animation: `${keyframes`0% {opacity:0}100% {opacity:1}`} 1s forwards`,
}))

const StyledInfoBox = styled(Box)(() => ({
  display: 'flex',
  paddingLeft: '4px',
}))

const StyledFlexCenterBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}))

const StyledFlexReversedBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row-reverse',
}))

const StyledCardWrapper = styled(Box)(({ expanded }: { expanded?: boolean }) => ({
  width: '100%',
  background: `linear-gradient(-90deg,${theme.palette.blueDianne[900]},${theme.palette.cyan[500]})`,
  margin: expanded ? '-12px 0px 8px' : '8px 0px',
  borderRadius: '15px',
  display: 'flex',
  flexDirection: 'column',
  transition: 'margin 0.5s ease',
  cursor: 'pointer',
}))

const StyledCard = styled(Box)(({ backgroundImage, expanded }: { backgroundImage?: string; expanded?: boolean }) => ({
  zIndex: 1,
  margin: '2px 1px 1px 4px',
  minHeight: expanded ? '240px' : '200px',
  background: theme.palette.white,
  backgroundImage: `url(${backgroundImage})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  overflow: 'hidden',
  borderRadius: '15px',
  display: 'flex',
  justifyContent: 'space-between',
  transition: 'min-height 0.5s ease',
}))

const StyledSideBox = styled(Box)(({ expanded }: { expanded?: boolean }) => ({
  width: '32px',
  height: expanded ? '240px' : '200px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center',
  background: theme.palette.white,
  transition: 'height 0.5s ease',
}))

export default SimulationPackageCarouselCard
