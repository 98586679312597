import axios from 'axios'
import { API_URL } from '../constants'
import { useMutation, useQueryClient } from '@tanstack/react-query'

//TODO : combine api with the enbaled
export async function disableMfa() {
  const url = `${API_URL}/profile/otp/disable`
  const { data } = await axios.post(url, {}, { withCredentials: true })
  return data
}

export function useDisableMfa() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: disableMfa,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['mfa'],
      })
    },
  })
}
