import axios from "axios";
import { API_URL } from "../constants";
// import { usePaginatedQuery } from 'react-query';
import { useQuery } from "@tanstack/react-query";
// import { ServerError } from "../types";

export type Activity = {
  text: string;
  timestamp: number;
};

export type ClientActivityQueryVariables = {
  startDate?: number;
  endDate?: number;
  offset: number;
  startIndex: number;
};

export type ClientActivityResponse = {
  count: number;
  items: Activity[];
};

const getClientActivity = async ({
  startDate,
  endDate,
  offset = 5,
  startIndex = 0,
}: ClientActivityQueryVariables) => {
  const url = `${API_URL}/client_activity/search`;
  const { data } = await axios.post(
    url,
    { startDate, endDate, offset, startIndex },
    { withCredentials: true }
  );
  return data.data;
};
// React Query V2
// export function useClientActivity(variables?: ClientActivityQueryVariables) {
//   return usePaginatedQuery<ClientActivityResponse, ServerError>(
//     ['client-activity', { ...variables }],
//     getClientActivity,
//   );
// }

export const useClientActivity = (
  queryFilters: ClientActivityQueryVariables
) => {
  return useQuery({
    queryKey: ["client-activity", { ...queryFilters }],
    queryFn: () => getClientActivity(queryFilters),
  });
};
