import { useMutation } from '@tanstack/react-query'
import axios from 'axios'

import { API_URL } from '@/api/constants'

export type ChangeEmailRequest = {
  email: string
  current_password: string
}

const editProfileEmailConfirm = async (profilePayload: Partial<ChangeEmailRequest>) => {
  const { data } = await axios.put(`${API_URL}/profile/request_email_change/`, profilePayload, {
    withCredentials: true,
  })

  return data
}

export const useEditProfileEmailConfirm = () => {
  return useMutation({
    mutationFn: editProfileEmailConfirm,
  })
}
