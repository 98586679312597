import React from "react";
import { useTranslation } from "react-i18next";
import { Typography, Button } from "@mui/material";

export default function ErrorBoundary() {
  const { t } = useTranslation();

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography style={{ marginBottom: 16 }} variant={"h3"}>
        {t("errorScreen.errorOccured")}
      </Typography>
      <Typography style={{ marginBottom: 32 }} variant={"h6"}>
        {t("errorScreen.teamNotified")}
      </Typography>
      <Button
        variant={"outlined"}
        color={"primary"}
        onClick={() => {
          window.location.href = "/";
        }}
      >
        {t("errorScreen.back")}
      </Button>
    </div>
  );
}
