import { useTheme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import {
  Checkbox,
  Grid,
  GridProps,
  MenuItem,
  OutlinedTextFieldProps,
  Select,
  TextField,
  Typography,
  FormControlLabel,
  FormHelperText,
} from '@mui/material'
import { Field } from './useForm'
import { ValidationError } from 'yup'
import { faCircleExclamation } from '@fortawesome/pro-light-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation, Trans } from 'react-i18next'
import Autocomplete from '@mui/material/Autocomplete'
import FileUpload from '../FileUpload/FileUpload'
import { isArray } from 'lodash'
import { NavLink } from 'react-router-dom'

export type FormProps = {
  fields: Field[]
  values: { [name: string]: string | string[] }
  errors: { [name: string]: ValidationError }
  handleChange: (
    name: string,
    value: string | boolean | { label: string; value: string } | { label: string; value: string }[]
  ) => void
  containerClassName?: string
  gridContainerProps?: GridProps
}

type CharacterCounterProps = {
  maxLength: number
  count: number
  bottom?: number
  right?: number
}
const CharacterCounter = ({ maxLength, count, bottom = 10, right = 10 }: CharacterCounterProps) => {
  return (
    <div style={{ fontSize: 10, position: 'absolute', bottom, right }}>
      {count}/{maxLength}
    </div>
  )
}

export default function Form({
  values,
  handleChange,
  errors,
  fields,
  containerClassName,
  gridContainerProps = { spacing: 2 },
}: FormProps) {
  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()

  const defaultGridItemProps: GridProps = {
    xs: 12,
  }

  const defaultTextFieldProps: OutlinedTextFieldProps = {
    variant: 'outlined',
    margin: 'none',
    fullWidth: true,
  }

  function getAutoselectValues(field: Field) {
    if (field.isAutoComplete && field?.options) {
      const vals = isArray(values[field.name]) ? values[field.name] : [values[field.name]]
      const formattedValues = field.options.filter((op) => vals.includes(op.value))
      const autoSelectedValue = field.multiple ? formattedValues : formattedValues[0]

      return autoSelectedValue
    }
  }

  return (
    <div className={containerClassName ?? classes.form}>
      <Grid container {...gridContainerProps}>
        {fields.map((field) => {
          const autoSelectedValue = getAutoselectValues(field)
          const gridItemProps: GridProps = field?.gridItemProps ? field?.gridItemProps : defaultGridItemProps
          const textFieldProps: OutlinedTextFieldProps = field?.textFieldProps
            ? field.textFieldProps
            : { variant: 'outlined' }
          const disabled = field?.disabled ? field.disabled(values) : false

          const { inputProps = {} } = textFieldProps
          const { maxLength, showCharacterCounter = false } = inputProps

          return (
            <Grid item {...gridItemProps} key={field.name}>
              {field.isAutoComplete && field.options ? (
                <Autocomplete
                  key={`${field.initialValue}` || field.name}
                  value={autoSelectedValue}
                  onChange={(event, newValue) =>
                    //@ts-ignore
                    handleChange(field.name, newValue || '')
                  }
                  //@ts-ignore
                  getOptionLabel={(option) => option.label}
                  options={field.options}
                  fullWidth
                  disabled={disabled}
                  multiple={field.multiple}
                  defaultValue={field.options.find((option) => option.value === field?.initialValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={field?.label ? t(field.label) : undefined}
                      error={errors[field.name]}
                      required={field?.required}
                      variant="outlined"
                      id={'stop-autofill'} // disable autocomplete and autofill
                      disabled={disabled}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              ) : field.type === 'upload' ? (
                <FileUpload
                  label={field?.label ? t(field.label) : ''}
                  onUpload={(url) => handleChange(field.name, url)}
                  textFieldProps={textFieldProps}
                  initialValue={field?.initialValue as string}
                />
              ) : field.type === 'select' ? (
                <Select
                  displayEmpty
                  variant={'outlined'}
                  margin={'none'}
                  fullWidth
                  onChange={(e) => handleChange(field.name, (e?.target?.value as string) || '')}
                  value={values[field.name] || ''}>
                  <MenuItem key={''} value={''} className={classes.multiSelectOption}>
                    {field?.label ? t(field.label) : undefined}
                  </MenuItem>
                  {field?.options &&
                    field.options.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        className={classes.multiSelectOption}
                        style={
                          values[field.name]?.includes(option.value)
                            ? {
                                color: theme.palette.text.primary,
                                fontWeight: 700,
                                backgroundColor: 'rgba(92, 101, 175, 0.1)',
                              }
                            : {}
                        }>
                        {option.label}
                      </MenuItem>
                    ))}
                </Select>
              ) : field.type === 'legal_checkbox' ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => handleChange(field.name, e.target.checked)}
                      checked={Boolean(values[field.name]) || Boolean(field?.initialValue)}
                      required={field?.required}
                    />
                  }
                  label={
                    field?.label ? (
                      <Trans
                        i18nKey={field?.label}
                        components={{
                          1: <NavLink to={'https://cywareness.io/terms-of-service/'} target="_blank" />,
                          2: <NavLink to={'https://cywareness.io/privacy-policy/'} target="_blank" />,
                        }}
                      />
                    ) : (
                      ''
                    )
                  }
                  labelPlacement="end"
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontSize: '12px',
                    },
                  }}
                />
              ) : (
                <div style={{ position: 'relative' }}>
                  <TextField
                    value={field?.autoComplete ? values[field.name] : values[field.name] || ''}
                    onChange={({ target: { value } }) => {
                      value = field?.onChange ? field.onChange(value) : value
                      return handleChange(field.name, value)
                    }}
                    error={errors[field.name]}
                    required={field?.required}
                    label={field?.label ? t(field.label) : undefined}
                    placeholder={field?.placeholder ? t(field.placeholder) : undefined}
                    autoComplete={field?.autoComplete}
                    autoFocus={field?.autoFocus}
                    type={field?.type}
                    disabled={disabled}
                    {...defaultTextFieldProps}
                    {...textFieldProps}>
                    {textFieldProps.hasOwnProperty('select') &&
                      field?.options &&
                      field.options.map((option) => (
                        <MenuItem
                          key={option.value}
                          value={option.value}
                          className={classes.multiSelectOption}
                          style={
                            values[field.name]?.includes(option.value)
                              ? {
                                  color: theme.palette.text.primary,
                                  fontWeight: 700,
                                  backgroundColor: 'rgba(92, 101, 175, 0.1)',
                                }
                              : {}
                          }>
                          {option.label}
                        </MenuItem>
                      ))}
                  </TextField>
                  {maxLength && showCharacterCounter && (
                    <CharacterCounter count={values[field?.name]?.length || 0} maxLength={maxLength} />
                  )}
                </div>
              )}

              {errors[field.name] && (
                <div className={classes.errorMessage}>
                  <FontAwesomeIcon
                    className={classes.errorIcon}
                    icon={faCircleExclamation as IconProp}></FontAwesomeIcon>
                  <Typography variant="subtitle2" color={'inherit'}>
                    {t(errors[field.name].message)}
                  </Typography>
                </div>
              )}
            </Grid>
          )
        })}
      </Grid>
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    fieldContainer: {},
    errorMessage: {
      display: 'flex',
      color: theme.palette.error.main,
      marginTop: 4,
    },
    errorIcon: {
      marginRight: 4,
    },
    multiSelectOption: {
      '&hover': {
        backgroundColor: 'rgba(0,0,0,0.1)',
      },
    },
  })
)
