import { SET_SIMULATION_NAME, SET_TOPICS, SummaryPageAction } from "./Types";

export const setTopics = (payload: string[]): SummaryPageAction => ({
  type: SET_TOPICS,
  payload,
});

export const setSimulationName = (payload: string): SummaryPageAction => ({
  type: SET_SIMULATION_NAME,
  payload,
});

// export const resetEmail = (): EmailAction => ({
//   type: RESET_EMAIL_STATE,
// });
