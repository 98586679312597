import React from "react";
import { Theme } from "@mui/material/styles";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

type DotsProps = {
  length: number;
  handleChange: (index: number) => void;
  activeStep: number;
  color?: string;
};

export default function Dots({
  length,
  handleChange,
  activeStep,
  color,
}: DotsProps) {
  const classes = useStyles({ color });
  const steps = new Array(length).fill(0);
  return (
    <div className={classes.root} style={{ width: length * 16 }}>
      {steps.map((v, index) => (
        <div
          onClick={() => handleChange(index)}
          key={index}
          className={`${classes.dot} ${
            activeStep === index ? classes.active : ""
          }`}
        />
      ))}
    </div>
  );
}

const useStyles = makeStyles<Theme, { color?: string }>((theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "space-between",
    },
    dot: {
      height: 10,
      width: 10,
      borderRadius: 5,
      background: theme.palette.grey["400"],
      cursor: "pointer",
    },
    active: {
      background: ({ color }) =>
        color ? color : theme.palette.background.secondaryGradient,
    },
  })
);
