export const MuiTable = {
  defaultProps: {
    size: 'small',
  },
  styleOverrides: {
    root: {
      boxShadow: 'none',
    },
  },
}

export const MuiTableRow = {
  styleOverrides: {
    root: { height: 61 },
  },
}

export const MuiTableCell = {
  styleOverrides: {
    body: {
      color: '#294D55',
    },
    footer: {
      border: 'none',
      borderTop: '1.5px solid rgba(0,0,0,0.1)',
    },
  },
}
