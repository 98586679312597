import Hotjar from '@hotjar/browser'
import { CssBaseline } from '@mui/material'
import { StyledEngineProvider, Theme, ThemeProvider } from '@mui/material/styles'
import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { QueryCache, ReactQueryCacheProvider } from 'react-query' // React Query V2
import { ReactQueryDevtools as ReactQueryDevtoolsV2 } from 'react-query-devtools' // React Query V2
import { Slide, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import { HOTJAR_SITE_ID, HOTJAR_VERSION, RECAPTCHA_KEY } from '../src/api/constants'
import AppRoutes from './Routes/Routes'
import ErrorBoundary from './common/components/ErrorBoundary/ErrorBoundary'
import AuthProvider from './context/Auth'
import LoadingScreen from './pages/LoadingScreen/LoadingScreen'
import { theme } from './theme/theme'
import { TermsModalProvider } from './context/TermsContext'

const siteId = Number(HOTJAR_SITE_ID)
const hotjarVersion = Number(HOTJAR_VERSION)

Hotjar.init(siteId, hotjarVersion)

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const defaultOptions = {
  queries: {
    staleTime: 60_000, // 1 minutes
    retry: false,
    // retryDelay: (attemptIndex: number) => 2000 ** attemptIndex, //retry after 2, 4, 8 seconds
  },
}

const queryCache = new QueryCache({
  defaultConfig: defaultOptions,
}) // React Query V2

const queryClient = new QueryClient({
  defaultOptions,
})

const errorBoundry = <ErrorBoundary />

function App() {
  return (
    <>
      <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryCacheProvider queryCache={queryCache}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <AuthProvider>
                  <LoadingScreen />
                  <Sentry.ErrorBoundary fallback={errorBoundry}>
                    <TermsModalProvider>
                      <AppRoutes />
                    </TermsModalProvider>
                  </Sentry.ErrorBoundary>
                </AuthProvider>
              </ThemeProvider>
            </StyledEngineProvider>
            <ReactQueryDevtoolsV2 />
            <ReactQueryDevtools buttonPosition="bottom-right" />
          </ReactQueryCacheProvider>
        </QueryClientProvider>
      </GoogleReCaptchaProvider>
      <ToastContainer position={'bottom-left'} transition={Slide} newestOnTop={true} />
    </>
  )
}

export default App
