import LogoIcon from '@/assets/images/logo-clean-blue.svg'
import { theme } from '@/theme/theme'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faBoxOpen, faFloppyDisk, faX } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, Drawer, TextField, Tooltip, keyframes, styled } from '@mui/material'
import { FC, useContext, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { usePhishingSimulationPackageCreate } from '@/api/phishing-simulations/packages/create'
import { usePhishingSimulationPackageUpdate } from '@/api/phishing-simulations/packages/update'
import stopImg from '@/assets/images/popup/stop.svg'
import LaunchWizard from '@/common/components/LaunchWizard/LaunchWizard'
import { LayoutContext } from '@/common/components/Layout/Layout'
import Popup from '@/common/components/Popup'
import useToast from '@/common/hooks/useToast'
import { AssetType } from '@/types/campaigns'
import SimulationPackageCard, { SimulationPackageCardType } from './SimulationPackageCard'
import DroppablePackageCard from './DroppablePackageCard'

type SimulationPackageMenuProps = {
  isMultiSimulationSideMenuOpen: boolean
  setIsMultiSimulationSideMenuOpen: (enabled: boolean) => void
}

const SimulationPackageMenu: FC<SimulationPackageMenuProps> = ({
  isMultiSimulationSideMenuOpen,
  setIsMultiSimulationSideMenuOpen,
}) => {
  const {
    blockedNavigationRequest,
    setIsMenuOpen,
    isMenuOpen,
    setMenuExpandEnabled,
    tempSimulationPackage,
    tempSimulationPackageId,
    tempSimulationPackageName,
    setTempSimulationPackageName,
    setTempSimulationPackageId,
    setNavigationBlock,
    setBlockedNavigationRequest,
    resetSimulationPackage,
    wasMenuOpen,
    setWasMenuOpen,
  } = useContext(LayoutContext)
  const { t } = useTranslation()
  const [isPopUpOpen, setIsPopUpOpen] = useState(false)
  const [launchWizardIsOpen, setLaunchWizardIsOpen] = useState(false)
  const navigateTo = useNavigate()
  const { errorToast, successToast } = useToast()

  const { mutateAsync: createPhishingSimulationPackage } = usePhishingSimulationPackageCreate()
  const { mutateAsync: updatePhishingSimulationPackage } = usePhishingSimulationPackageUpdate()

  const getSimulationsIds = () => {
    const simulationsIds: string[] = []
    tempSimulationPackage.forEach((simulation: { id: string }) => {
      simulationsIds.push(simulation.id)
    })
    return simulationsIds
  }

  const handleExit = () => {
    setIsMultiSimulationSideMenuOpen(false)
    setNavigationBlock(false)
    setMenuExpandEnabled(true)
    setBlockedNavigationRequest('')
    setIsPopUpOpen(false)
    if (blockedNavigationRequest !== 'close') {
      navigateTo(blockedNavigationRequest)
    }
  }
  const handleSavePackage = async () => {
    const phishingSimulationPackagePayload = {
      name: tempSimulationPackageName,
      simulation_ids: getSimulationsIds(),
    }

    try {
      if (!tempSimulationPackageId) {
        const newSimulation = await createPhishingSimulationPackage(phishingSimulationPackagePayload)
        navigateTo(`/content-library/simulations-package/${newSimulation.id}`)
      } else {
        await updatePhishingSimulationPackage({
          id: tempSimulationPackageId,
          params: phishingSimulationPackagePayload,
        })
      }
      successToast(t('simulationPackage.saved'))
    } catch (error) {
      errorToast(t('simulationPackage.failedSave'))
    }
    setIsMultiSimulationSideMenuOpen(false)
    setNavigationBlock(false)
    setMenuExpandEnabled(true)
    setBlockedNavigationRequest('')
  }

  useEffect(() => {
    if (isMultiSimulationSideMenuOpen) {
      setNavigationBlock(true)
      setMenuExpandEnabled(!isMultiSimulationSideMenuOpen)
      setWasMenuOpen(isMenuOpen)
      setIsMenuOpen(!isMultiSimulationSideMenuOpen)
    } else {
      setTempSimulationPackageName('')
      setTempSimulationPackageId('')
      setMenuExpandEnabled(true)
      setIsMenuOpen(wasMenuOpen)
      setNavigationBlock(false)
      resetSimulationPackage()
    }
  }, [isMultiSimulationSideMenuOpen])

  useEffect(() => {
    if (isMultiSimulationSideMenuOpen && blockedNavigationRequest !== '') {
      if (tempSimulationPackage.length > 0) {
        setIsPopUpOpen(true)
      } else {
        handleExit()
      }
    }
  }, [blockedNavigationRequest])

  const EmptyCards = () => {
    const emptyCards = []
    const count = Math.max(2 - tempSimulationPackage.length, 0)
    for (let i = -1; i < count; i++) {
      emptyCards.push(<DroppablePackageCard index={i + 1} />)
    }
    return emptyCards
  }

  return (
    <StyledDrawer variant="persistent" open={isMultiSimulationSideMenuOpen}>
      <Popup
        open={isPopUpOpen}
        onClose={() => {
          setIsPopUpOpen(false)
          setBlockedNavigationRequest('')
        }}
        onConfirm={handleExit}
        buttonLabels={{
          confirm: t('simulationPackage.exitPopup.confirmButton'),
          cancel: t('simulationPackage.exitPopup.cancelButton'),
        }}
        icon={stopImg}>
        <h4>{t('simulationPackage.exitPopup.title')}</h4>
        <p>{t('simulationPackage.exitPopup.message1')}</p>
        <p>
          <Trans i18nKey="simulationPackage.exitPopup.message2" components={{ 1: <b /> }} />
        </p>
      </Popup>
      <LaunchWizard
        campaignType={AssetType.phishing_simulation}
        open={launchWizardIsOpen}
        onClose={() => setLaunchWizardIsOpen(false)}
        assets={tempSimulationPackage}
        packageName={tempSimulationPackageName}
        launchAnimation
      />
      {isMultiSimulationSideMenuOpen && (
        <StyledMainBox>
          <StyledFrontBox>
            <StyledTopBox>
              <StyledIconButton>
                <FontAwesomeIcon
                  icon={faX as IconProp}
                  fontSize={18}
                  onClick={() => {
                    tempSimulationPackage.length > 0
                      ? setBlockedNavigationRequest('close')
                      : setIsMultiSimulationSideMenuOpen(false)
                  }}
                />
              </StyledIconButton>
              <StyledInnerTopBox>
                {tempSimulationPackage.length > 1 && tempSimulationPackageName ? (
                  <SaveButton disabled={false} saveFunction={handleSavePackage} />
                ) : (
                  <Tooltip
                    title={
                      <Box width={'140px'} textAlign={'center'}>
                        {t(`simulationPackage.saveTooltip.${tempSimulationPackageName ? 'addSimulations' : 'addName'}`)}
                      </Box>
                    }>
                    <Box>
                      <SaveButton disabled={true} saveFunction={handleSavePackage} />
                    </Box>
                  </Tooltip>
                )}
                {tempSimulationPackage.length > 1 ? (
                  <LaunchButton disabled={false} launchFunction={() => setLaunchWizardIsOpen(true)} />
                ) : (
                  <Tooltip
                    title={
                      <Box width={'140px'} textAlign={'center'}>
                        {t(`simulationPackage.saveTooltip.addSimulations`)}
                      </Box>
                    }>
                    <Box>
                      <LaunchButton disabled={true} launchFunction={() => setLaunchWizardIsOpen(true)} />
                    </Box>
                  </Tooltip>
                )}
              </StyledInnerTopBox>
            </StyledTopBox>
            <StyledCenterBox>
              <FontAwesomeIcon
                icon={faBoxOpen as IconProp}
                fontSize={72}
                color={theme.palette.cyan[500]}
                style={{
                  mask: 'linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 90%)',
                }}
              />
              <StyledTextFieldBoxWrapper>
                <StyledTextFieldBox>
                  <StyledTextField
                    size="small"
                    value={tempSimulationPackageName}
                    label={'Package Name'}
                    name="organizationName"
                    autoComplete="off"
                    onChange={(value) => {
                      setTempSimulationPackageName(value.target.value)
                    }}
                  />
                </StyledTextFieldBox>
              </StyledTextFieldBoxWrapper>
              <StyledCardsContainerBox>
                {tempSimulationPackage.map((simulation, index) => {
                  return (
                    <SimulationPackageCard
                      key={simulation.id}
                      simulation={simulation}
                      index={index + 1}
                      type={SimulationPackageCardType.Menu}
                    />
                  )
                })}
                <EmptyCards />
              </StyledCardsContainerBox>
            </StyledCenterBox>
          </StyledFrontBox>
          <StyledLogoBox>
            <img
              alt={'Cywareness Logo'}
              src={LogoIcon}
              height={300}
              width={260}
              style={{ position: 'absolute', left: -114, bottom: -50 }}
            />
          </StyledLogoBox>
        </StyledMainBox>
      )}
    </StyledDrawer>
  )
}

const SaveButton: FC<{ disabled: boolean; saveFunction: () => void }> = ({ disabled, saveFunction }) => {
  return (
    <StyledIconButton disabled={disabled}>
      <FontAwesomeIcon
        icon={faFloppyDisk as IconProp}
        fontSize={28}
        onClick={() => {
          !disabled && saveFunction()
        }}
      />
    </StyledIconButton>
  )
}

const LaunchButton: FC<{ disabled: boolean; launchFunction: () => void }> = ({ disabled, launchFunction }) => {
  const { t } = useTranslation()
  return (
    <Button
      disabled={disabled}
      variant="contained"
      onClick={() => {
        launchFunction()
      }}>
      {t('simulationPackage.launch')}
    </Button>
  )
}

const StyledIconButton = styled(Button)(() => ({
  color: theme.palette.white,
  borderRadius: '4px',
  margin: 0,
  padding: '4px',
  '&:hover': {
    color: theme.palette.blueDianne[400],
    background: 'none',
  },
}))

const StyledCenterBox = styled(Box)(() => ({
  marginTop: '20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '16px',
  height: '100%',
  width: '100%',
}))

const StyledInnerTopBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}))

const StyledTextFieldBox = styled(Box)(() => ({
  padding: '8px 10px 24px 0px',
}))

const StyledTextFieldBoxWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  width: '100%',
  padding: '0px 10px',
  alignItems: 'stretch',
}))

const StyledTopBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const StyledCardsContainerBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'scroll',
  overflowX: 'hidden',
  height: '100%',
  width: '100%',
  padding: '0px 10px',
  marginBottom: '96px',
  alignItems: 'stretch',
}))

const StyledLogoBox = styled(Box)(() => ({
  position: 'fixed',
  height: '100%',
  width: '100%',
  overflow: 'hidden',
  bottom: 0,
  pointerEvents: 'none',
  opacity: 0,
  animation: `${keyframes`0% {opacity: 0;}100% {opacity: 0.2;}`} 2s forwards`,
}))

const StyledDrawer = styled(Drawer)(() => ({
  '& .MuiDrawer-paper': {
    width: '400px',
    boxSizing: 'content-box',
    marginLeft: '62px',
    top: '-1px',
    overflow: 'visible',
    background: 'none',
  },
}))

const StyledMainBox = styled(Box)(() => ({
  position: 'absolute',
  width: '100%',
  height: '101%',
  top: '-1px',
  background: theme.palette.blueGray[800],
  animation: `${keyframes`0% {opacity: 0;}100% {opacity: 1;}`} 1s forwards`,
}))

const StyledFrontBox = styled(Box)(() => ({
  padding: '20px',
  height: '100%',
  width: '90%',
}))

const StyledTextField = styled(TextField)(() => ({
  width: '100%',
  div: {
    borderRadius: '12px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderWidth: '1px',
      borderColor: theme.palette.cyan[500],
    },
    '&:hover fieldset': {
      borderWidth: '1px',
      borderColor: theme.palette.cyan[500],
    },
    '&.Mui-focused fieldset': {
      borderWidth: '1px',
      borderColor: theme.palette.cyan[500],
    },

    '& input': {
      color: theme.palette.white,
    },
    '&:hover input': {
      color: theme.palette.white,
    },
    '&.Mui-focused input': {
      color: theme.palette.white,
    },
  },
  label: {
    color: theme.palette.white,
    backgroundColor: theme.palette.blueGray[800],
    '&.Mui-focused': {
      color: theme.palette.white,
    },
  },
}))

export default SimulationPackageMenu
