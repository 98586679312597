import { Tab, styled } from '@mui/material'

const MainTab = styled(Tab)(() => ({
  boxShadow: '-0px -2px 5px lightgray inset',
  padding: '14px 20px',
  marginTop: '3px',
  background: '#F2F5F7',
  '&.Mui-selected': {
    marginTop: '0px',
    boxShadow: 'none',
    background: '#FFF',
    border: 'solid #05DBF2 3px',
    borderRight: '0px',
    borderLeft: '0px',
    borderBottom: '0px',
  },
  '&.MuiTab-selected': {
    background: '#000',
  },
  '::selection': {
    background: '#000',
  },
  '&$tabSelected': {
    background: '#000',
  },
}))

export default MainTab
