import { useMemo, useState } from 'react'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { useDomains, DomainObject } from '@/api/domains/get'
import { useNewDomain } from '@/api/domains/create'
import { useDeleteDomain } from '@/api/domains/delete'
import { useClientDetails } from '@/api/client/client'
import { getErrorMessage } from '@/api/utils/get-error'
import { Button, Paper } from '@mui/material'
import DomainsToolbar from './DomainsToolbar'
import SimpleTable from '../Tables/SimpleTable'
import { ColumnInterface } from 'react-table'
import DeleteAlert from '../Alert/DeleteAlert'
import useToast from '@/common/hooks/useToast'

export default function DomainsTable() {
  const classes = useStyles()
  const { t } = useTranslation()
  const { successToast, errorToast } = useToast()

  const { data: domainsData, refetch, isFetching } = useDomains()
  const { mutateAsync: addDomains, isPending } = useNewDomain()
  const { mutateAsync: deleteDomain } = useDeleteDomain()
  const [deleteDomainModalOpen, setDeleteDomainModalOpen] = useState(false)
  const [deleteDescription, setDeleteDescription] = useState('')
  const [deleteDomainId, setDeleteDomainId] = useState<string>('')
  const { data: clientDetails } = useClientDetails()

  async function handleAddDomain(domain: string) {
    try {
      await addDomains([domain])
      successToast(t('domainsTableComponent.addDomainSuccessful', { domain }))
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      errorToast(errorMessage || t('domainsTableComponent.errors.failedToAddDomain', { domain }))
    }
  }

  const handleDeleteDomainModalOpen = (domainId: string) => {
    setDeleteDomainId(domainId)
    setDeleteDomainModalOpen(true)
  }

  const handleDeleteDomainModalClose = () => {
    setDeleteDomainId('')
    setDeleteDescription('')
    setDeleteDomainModalOpen(false)
  }

  const handleDeleteDescription = (domain: DomainObject) => {
    if (domain.has_members && !clientDetails?.allow_unverified_domains) {
      setDeleteDescription(t('domainsTableComponent.deleteDescription'))
    }
    if (domain.has_members && clientDetails?.allow_unverified_domains) {
      setDeleteDescription(t('domainsTableComponent.deleteDescriptionVerified'))
    }
    if (!domain.has_members && clientDetails?.allow_unverified_domains) {
      setDeleteDescription('')
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRemoveDomain = async () => {
    try {
      await deleteDomain(deleteDomainId)
      handleDeleteDomainModalClose()
    } catch (e) {
      console.log(e)
    }
  }

  const columns: ColumnInterface[] = useMemo(
    () => [
      {
        Header: t('domainsTableComponent.domainName'),
        accessor: 'domain',
        disableSortBy: true,
      },
      {
        Header: t('domainsTableComponent.record'),
        accessor: 'verify_token',
        disableSortBy: true,
        Cell: ({ value }) => `CY=${value}`,
      },
      {
        Header: t('domainsTableComponent.verified'),
        accessor: 'verified',
        Cell: ({ value: isVerified }) => (
          <div className={isVerified ? classes.statusIndicatorVerified : classes.statusIndicatorPending} />
        ),
      },
      {
        id: 'delete',
        Cell: (data) => {
          const domainId = data.row.original._id
          return (
            <Button
              variant={'text'}
              className={classes.deleteButton}
              onClick={async () => {
                await handleDeleteDescription(data.row.original)
                handleDeleteDomainModalOpen(domainId)
              }}
            >
              {t('domainsTableComponent.remove')}
            </Button>
          )
        },
      },
    ],
    [
      classes.statusIndicatorPending,
      classes.statusIndicatorVerified,
      classes.deleteButton,
      handleDeleteDomainModalOpen,
      t,
    ]
  )

  return (
    <Paper className={classes.tableContainer}>
      <DeleteAlert
        confirmDeleteOpen={deleteDomainModalOpen}
        handleCancelDelete={handleDeleteDomainModalClose}
        deleteIntegrationHandler={handleRemoveDomain}
        title={t('domainsTableComponent.deleteTitle')}
        description={deleteDescription}
        subtitle={t('domainsTableComponent.deleteSubtitle')}
      />
      <DomainsToolbar handleAddDomain={handleAddDomain} handleRefresh={refetch} isFetching={isFetching || isPending} />
      <SimpleTable
        stickyHeader
        maxContainerHeight={400}
        enableCheckbox={false}
        columns={columns}
        data={domainsData ?? []}
        setData={() => {}}
        noResultsTextId={'domainsTableComponent.noResults'}
      />
    </Paper>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    body: {
      flexGrow: 1,
    },
    statusIndicatorVerified: {
      height: 30,
      width: 30,
      borderRadius: '50%',
      background: theme.palette.success.main,
      margin: 'auto',
    },
    statusIndicatorPending: {
      height: 30,
      width: 30,
      borderRadius: '50%',
      background: theme.palette.warning.main,
      margin: 'auto',
    },
    tableContainer: {
      marginTop: theme.spacing(2),
      width: '100%',
      overflow: 'hidden',
    },
    deleteButton: {
      color: '#294D55',
    },
  })
)
