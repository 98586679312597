import { useMemo } from 'react'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { Badge, Button, Drawer, IconButton, ListItemText, Typography, ButtonBase, ListItem } from '@mui/material'
import Alert from './Alert'
import { useGetAlerts } from '../../../../api/alerts/alerts'
import { useTranslation } from 'react-i18next'
import { useReadAllAlerts } from '../../../../api/alerts/read-all-alerts'
import useToast from '../../../hooks/useToast'

import { faBell } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { theme } from '@/theme/theme'

type AlertPanelProps = {
  open: boolean
  handleClose: () => void
  handleOpen: () => void
}

export default function AlertPanel({ open, handleClose, handleOpen }: AlertPanelProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { data: alerts } = useGetAlerts()
  const [readAll] = useReadAllAlerts()
  const { errorToast } = useToast()
  const unreadAlerts = useMemo(() => {
    return alerts && alerts.length ? alerts.filter((alert) => !alert.read) : []
  }, [alerts])

  async function handleReadAll() {
    try {
      await readAll()
    } catch (e) {
      errorToast(t('dashboard.alerts.errors.failedToReadAll'))
    }
  }

  return (
    <>
      <ButtonBase className={classes.flex} onClick={open ? handleClose : handleOpen}>
        <Badge color="secondary" badgeContent={unreadAlerts?.length ?? 0}>
          <IconButton className={classes.icon} size={'small'}>
            <FontAwesomeIcon icon={faBell as IconProp} fontSize={20} />
          </IconButton>
        </Badge>
        <ListItem>
          <ListItemText
            className={classes.text}
            primary={t('menu.notifications')}
            primaryTypographyProps={{
              style: { fontWeight: theme.typography.fontWeightMedium },
            }}
          />
        </ListItem>
      </ButtonBase>
      <Drawer
        classes={{
          paper: classes.drawerPaper,
        }}
        open={open}
        onClose={handleClose}
        anchor={'right'}
      >
        <div className={classes.list}>
          <div className={classes.listHeader}>
            <Typography variant={'button'}>{t('dashboard.alerts.alerts')}</Typography>
            <Button disabled={unreadAlerts.length === 0} onClick={handleReadAll}>
              {t('dashboard.alerts.seeAll')}
            </Button>
          </div>
          <div className={classes.listBody}>
            {unreadAlerts && unreadAlerts?.length > 0 ? (
              unreadAlerts.map((alert) => <Alert key={alert._id} alert={alert} />)
            ) : (
              <div className={classes.emptyList}>
                <Typography variant={'h6'} color={'textSecondary'}>
                  {t('dashboard.alerts.noAlerts')}
                </Typography>
              </div>
            )}
          </div>
        </div>
      </Drawer>
    </>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    drawerPaper: {
      width: 350,
    },
    icon: {
      color: theme.palette.common.white,
      marginLeft: theme.spacing(1.5),
    },
    text: {
      marginLeft: theme.spacing(2),
      color: theme.palette.common.white,
    },
    iconWrapper: {
      fontSize: 28,
      width: 64,
      margin: 0,
      marginLeft: 0,
    },
    flex: {
      padding: 2,
      // marginLeft: theme.spacing(1),
      color: theme.palette.common.white,
      textTransform: 'capitalize',
      '&:hover': {
        background: 'transparent',
      },
    },
    list: {
      width: '100%',
      padding: theme.spacing(2, 3),
    },
    listHeader: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    listBody: {
      paddingTop: theme.spacing(4),
      width: '100%',
      height: '100%',
    },
    emptyList: {
      width: '100%',
      padding: theme.spacing(6, 2),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${theme.palette.divider}`,
    },
  })
)
