import React, { useEffect, ReactNode } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { useAuth } from '../context/Auth'
import Popup from '@/common/components/Popup'
import { Trans, useTranslation } from 'react-i18next'
import { useTermsModal } from '@/context/TermsContext'

import termsModal from '@/assets/images/popup/terms-modal.svg'

function ProtectedRoute({
  children,
  redirectTo = '/login',
  enabled = true,
}: {
  children: ReactNode
  redirectTo?: string
  enabled?: boolean
}) {
  const { user, isLoading } = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { showTermsModal, setShowTermsModal, handleAcceptTerms, handleDeclineTerms } = useTermsModal()

  useEffect(() => {
    if (!isLoading) {
      if (!user || !enabled) {
        navigate(redirectTo)
      } else if (user && !user.accepted_latest_terms) {
        setShowTermsModal(true)
      }
    }
  }, [user, isLoading, enabled, navigate, redirectTo, setShowTermsModal])

  if (isLoading) return null

  return (
    <>
      <Popup
        open={showTermsModal}
        onConfirm={handleAcceptTerms}
        onClose={handleDeclineTerms}
        disableBackdropClick
        buttonLabels={{
          confirm: t('routes.termsPopup.accept'),
          cancel: t('routes.termsPopup.decline'),
        }}
        icon={termsModal}>
        <h4>{t('routes.termsPopup.title')}</h4>
        <Trans
          i18nKey="routes.termsPopup.description"
          components={{
            1: <Link to={'https://cywareness.io/terms-of-service/'} target="_blank" />,
            2: <Link to={'https://cywareness.io/privacy-policy/'} target="_blank" />,
            3: <br />,
          }}
        />
      </Popup>
      {user && enabled ? children : null}
    </>
  )
}

export default ProtectedRoute
