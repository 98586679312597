import axios from 'axios';
import { API_URL } from '../constants';
import { useMutation, useQueryCache } from 'react-query';
import { ServerError } from '../types';
import { EducationModule } from './modules';

export async function deleteModule(_id: string) {
  const url = `${API_URL}/education_modules/delete/${_id}`;
  const { data } = await axios.get(url, { withCredentials: true });
  return data.message;
}

export function useDeleteEducationModule() {
  const queryCache = useQueryCache();
  return useMutation<string, ServerError, string>(deleteModule, {
    throwOnError: true,
    onMutate: (_id) => {
      queryCache.cancelQueries('modules');
      const previousTemplates = queryCache.getQueryData('modules') as EducationModule[];
      const updatedTemplates = (previousTemplates || []).filter((template) => template._id !== _id);
      //@ts-ignore
      queryCache.setQueryData('modules', () => updatedTemplates);
      return () => queryCache.setQueryData('modules', previousTemplates);
    },
    //@ts-ignore
    onError: (err, variables, rollback) => rollback(),
    onSettled: () => {
      queryCache.invalidateQueries('modules');
    },
  });
}
