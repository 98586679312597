import axios from "axios";
import { API_URL } from "../constants";
import { useMutation, useQueryCache } from "react-query";
import { ServerError } from "../types";

export type RemoveUsersFromGroupParams = { _id: string; users: string[] };

export async function removeUsersFromGroup({
  _id,
  users = [],
}: RemoveUsersFromGroupParams): Promise<string> {
  const url = `${API_URL}/groups/${_id}/users/`;
  const { data } = await axios({
    url: url,
    method: "delete",
    data: { users },
    withCredentials: true,
  });
  return data;
}

export function useRemoveUsersFromGroup() {
  const queryCache = useQueryCache();
  return useMutation<string, ServerError, RemoveUsersFromGroupParams>(
    removeUsersFromGroup,
    {
      throwOnError: true,
      onSettled: (response, error, variables) => {
        queryCache.invalidateQueries("groups");
        queryCache.invalidateQueries(["groups", variables._id]);
        queryCache.invalidateQueries([["group", "users", variables._id]]);

        // this is because the UsersTable in the GroupProfile page is using the `users` paginating query.
        queryCache.invalidateQueries(["users"]);
      },
    }
  );
}
