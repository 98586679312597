import axios from "axios";
import { EVENTS_API_URL } from "../constants";

export const EVENT_TYPES = {
  FEEDBACK_TIPS_CLICKED: "show_feedback_tips",
  FEEDBACK_COMPLETED: "feedback_completed",
  FEEDBACK_ACKNOWLEDGED: "feedback_acknowledged",
  FEEDBACK_CLOSED: "feedback_tab_closed",
  FEEDBACK_OPENED: "feedback_opened",
  TRAINING_OPENED: "training_opened",
  TRAINING_PAUSED: "training_paused",
  TRAINING_COMPLETED: "training_completed",
  FEEDBACK_PAGE_ITERATION: "feedback_page_iteration",
  VIDEO_PAGE_LOAD: "video_page_load",
  VIDEO_RUNNING: "video_running",
  VIDEO_TAB_BLUR: "video_tab_blur",
  VIDEO_TAB_FOCUS: "video_tab_focus",
  VIDEO_TAB_CLOSE: "video_tab_close",
  SUBTITLE_CHANGE: "subtitle_change",
  QUIZ_STARTED: "quiz_started",
  QUIZ_COMPLETED: "quiz_completed",
  QUIZ_ANSWER: "quiz_answer",
  QUIZ_LANGUAGE_CHANGE: "quiz_language_changed",
};
type EventTypeKeys = keyof typeof EVENT_TYPES;
type LogEventTypes = (typeof EVENT_TYPES)[EventTypeKeys];

export const CAMPAIGN_TYPES = {
  TRAINING: "training_campaign",
  SMS: "sms_campaign",
  EMAIL: "email_campaign",
};
type CampaignTypeKeys = keyof typeof CAMPAIGN_TYPES;
type CampaignTypes = (typeof CAMPAIGN_TYPES)[CampaignTypeKeys];

export type LogEventParams = {
  campaignToken: string;
  eventType: LogEventTypes;
  campaignType: CampaignTypes;
  additionalData?: { [key: string]: any };
};

export async function logEvent({
  campaignToken,
  eventType,
  campaignType,
  additionalData = {},
}: LogEventParams) {
  const url = `${EVENTS_API_URL}/events/new?campaign_type=${campaignType}&campaign=${campaignToken}&event_type=${eventType}&additional_data=${encodeURIComponent(
    JSON.stringify(additionalData)
  )}`;
  const { data } = await axios.get(url, { withCredentials: true });
  return data.data;
}
