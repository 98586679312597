import axios from "axios";
import { useQuery } from "react-query";

import { API_URL } from "../constants";
import { ServerError } from "../types";
import { useAuth } from "../../context/Auth";

export type App = {
  _id: string;
  title: string;
  description: string;
  url: string;
  enabled: boolean;
  external: boolean;
  onboarded: boolean;
  key: string;
  package?: string;
};

export async function getApps() {
  const appsUrl = `${API_URL}/apps/all`;
  const {
    data: { data: allApps },
  } = await axios.get(appsUrl, { withCredentials: true });
  return allApps;
}

export function useApps() {
  const { user } = useAuth();

  return useQuery<App[], ServerError>("apps", getApps, {
    refetchOnWindowFocus: false,
    enabled: !!user,
  });
}
