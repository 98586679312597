import { FC } from 'react'
import { makeStyles, createStyles } from '@mui/styles'
import { PhishingSimulation, PhishingSimulationVector } from '@/types/phishingSimulations'
import { Campaign } from '@/types/campaigns'
import { Gmail } from '@/common/components/DeviceFrame/DeviceFrame'
import WhatsAppPreview from '@/common/components/MobilePhonePreview/WhatsAppPreview'
import SmsAppPreview from '@/common/components/MobilePhonePreview/SmsAppPreview'
import MobilePhonePreview from '@/common/components/MobilePhonePreview'
import isLanguageRTL from '@/common/utils/isLanguageRTL'
import { Box, styled } from '@mui/material'

type PreviewProps = {
  campaignData?: Campaign
  simulationsData: PhishingSimulation
}

const SimulationPreview: FC<PreviewProps> = ({ campaignData, simulationsData }) => {
  const classes = useStyles()
  const isRtl = isLanguageRTL(simulationsData?.languages.message[0])

  switch (campaignData?.asset_metadata?.vector || simulationsData?.vectors[0]) {
    case PhishingSimulationVector.Email: {
      return (
        <StyledNoClickableBox>
          <Gmail
            htmlDoc={simulationsData?.html_contents.message}
            sender={`${simulationsData?.sender.mailbox}@${simulationsData?.sender.subdomain}.com`}
            senderName={simulationsData?.sender.name}
            subject={simulationsData?.subject}
          />
        </StyledNoClickableBox>
      )
    }
    case PhishingSimulationVector.WhatsApp:
      return (
        <div className={classes.smartphoneContainer}>
          <MobilePhonePreview fullScreenButton>
            <WhatsAppPreview
              sender={simulationsData?.sender.name}
              message={simulationsData?.html_contents.message}
              isRtl={isRtl}
            />
          </MobilePhonePreview>
        </div>
      )
    default:
      return (
        <div className={classes.smartphoneContainer}>
          <MobilePhonePreview fullScreenButton>
            <SmsAppPreview
              sender={simulationsData?.sender.name}
              message={simulationsData?.html_contents.message}
              isRtl={isRtl}
            />
          </MobilePhonePreview>
        </div>
      )
  }
}

const StyledNoClickableBox = styled(Box)(() => ({
  pointerEvents: 'none',
}))
const useStyles = makeStyles(() =>
  createStyles({
    smartphoneContainer: {
      width: 300,
      margin: 'auto',
    },
  })
)

export default SimulationPreview
