import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faXmark } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, IconButton, styled } from '@mui/material'
import Modal from '@mui/material/Modal'
import { FC, ReactNode } from 'react'

import { theme } from '@/theme/theme'

type PopupLargeProps = {
  open: boolean
  onClose: () => void
  title: string
  children: ReactNode | ReactNode[]
}

// replace with Modal common component

const PopupLarge: FC<PopupLargeProps> = ({ open, onClose, title, children }) => {
  return (
    <Modal open={open} onClose={(event, reason) => reason !== 'backdropClick' && onClose}>
      <ModalConent>
        <TitleBarStyled>
          <IconButton onClick={onClose}>
            <FontAwesomeIcon icon={faXmark as IconProp} fontSize={20} color={theme.palette.cyan[500]} />
          </IconButton>
          {title}
        </TitleBarStyled>
        <Box padding={theme.spacing(3, 5)}>{children}</Box>
      </ModalConent>
    </Modal>
  )
}

export const ModalConent = styled(Box)(() => ({
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -40%)',
  width: '800px',
  overflow: 'hidden',
  backgroundColor: theme.palette.white,
  borderRadius: '10px',
}))

export const ButtonsWrapper = styled(Box)(() => ({
  marginTop: theme.spacing(4),
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.spacing(2),
}))

const TitleBarStyled = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  color: theme.palette.cyan[500],
  background: theme.palette.black,
  padding: theme.spacing(2),
  fontSize: '18px',
}))

export default PopupLarge
