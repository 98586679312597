import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography, styled } from '@mui/material'
import { Dispatch, FC, SetStateAction } from 'react'

import { theme } from '@/theme/theme'
import { withCommas } from '../utils'
import TableSection from './TableSection'

const MainTable: FC<{
  data: any
  tailoredCost: number
  liteCost: number
  humanfirewallCost: number
  setTailoredCost: Dispatch<SetStateAction<number>>
  setVideosCount: Dispatch<SetStateAction<number>>
  setVideosCost: Dispatch<SetStateAction<number>>
}> = ({ data, tailoredCost, liteCost, humanfirewallCost, setTailoredCost, setVideosCount, setVideosCost }) => {
  return (
    <Box sx={{ width: '100%', minWidth: '1020px' }}>
      <Box
        sx={{
          padding: theme.spacing(0, 2),
        }}>
        <Table>
          <TableHead>
            <TableRow sx={{ display: 'flex', justifyContent: 'flex-end', height: '100%' }}>
              <TableCell sx={{ padding: 0, margin: 0, border: 'none' }}></TableCell>
              <TableCell align="right" width={'240px'} sx={{ padding: 0, margin: 0, border: 'none' }}>
                <StyledHeadCell>
                  <StyledHeadCellTitle color={theme.palette.cyan[50]}>
                    <Typography fontSize={20} fontWeight={700} textAlign="center" color={theme.palette.blueDianne[900]}>
                      LITE
                    </Typography>
                  </StyledHeadCellTitle>
                  <StyledHeadCellPrice>
                    <Typography fontSize={31} fontWeight={700}>
                      ${withCommas(liteCost)}
                    </Typography>
                    <Typography fontSize={12} fontWeight={400} textAlign="center">
                      per campaign/ <br />
                      per member/ <br />
                      per year
                    </Typography>
                  </StyledHeadCellPrice>
                </StyledHeadCell>
              </TableCell>
              <TableCell align="right" sx={{ padding: 0, margin: 0, border: 'none' }} width={'240px'}>
                <StyledHeadCell>
                  <StyledHeadCellTitle color={theme.palette.cyan[100]}>
                    <Typography fontSize={20} fontWeight={700} textAlign="center" color={theme.palette.blueDianne[900]}>
                      TAILORED
                    </Typography>
                  </StyledHeadCellTitle>
                  <StyledHeadCellPrice>
                    <Typography fontSize={31} fontWeight={700}>
                      ${withCommas(tailoredCost)}
                    </Typography>
                    <Typography fontSize={12} fontWeight={400} textAlign="center">
                      per member/ <br />
                      per year
                    </Typography>
                  </StyledHeadCellPrice>
                </StyledHeadCell>
              </TableCell>
              <TableCell align="right" sx={{ padding: 0, margin: 0, border: 'none' }} width={'240px'}>
                <StyledHeadCell sx={{ borderColor: theme.palette.cyan[500], borderWidth: '2px 4px' }}>
                  <StyledHeadCellTitle color={theme.palette.cyan[500]}>
                    <Typography fontSize={20} fontWeight={700} textAlign="center" color={theme.palette.blueDianne[900]}>
                      HUMAN FIREWALL
                    </Typography>
                  </StyledHeadCellTitle>
                  <StyledHeadCellPrice>
                    <Typography fontSize={31} fontWeight={700}>
                      ${withCommas(humanfirewallCost)}
                    </Typography>
                    <Typography fontSize={12} fontWeight={400} textAlign="center">
                      per member/ <br />
                      per year
                    </Typography>
                  </StyledHeadCellPrice>
                </StyledHeadCell>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
        {Object.keys(data).map((section: string) => {
          return (
            <TableSection
              section={data[section]}
              title={section}
              setTailoredCost={setTailoredCost}
              setVideosCost={setVideosCost}
              setVideosCount={setVideosCount}
            />
          )
        })}
        <Box sx={{ paddingRight: '11px' }}>
          <Table padding="none" sx={{ width: '100%' }}>
            <TableBody sx={{ width: '100%' }}>
              <TableRow
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                  '&:last-child td, &:last-child th': { border: 0 },
                  margin: '0 1px',
                  padding: 0,
                }}>
                <TableCell
                  align="right"
                  sx={{
                    padding: 0,
                    margin: 0,
                    width: 220,
                    minWidth: 220,
                    height: '10px',
                    paddingLeft: theme.spacing(2.4),
                  }}></TableCell>
                <TableCell
                  align="right"
                  sx={{
                    padding: 0,
                    margin: 0,
                    width: '240px',
                    minWidth: '240px',

                    paddingLeft: theme.spacing(2.4),
                  }}>
                  <StyledBottomCell></StyledBottomCell>
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    padding: 0,
                    margin: 0,
                    width: '240px',
                    minWidth: '240px',
                    paddingLeft: theme.spacing(2.4),
                  }}>
                  <StyledBottomCell></StyledBottomCell>
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    padding: 0,
                    margin: 0,
                    width: '240px',
                    minWidth: '240px',
                    paddingLeft: theme.spacing(2.4),
                  }}>
                  <StyledBottomCell
                    sx={{ borderColor: theme.palette.cyan[500], borderWidth: '2px 4px' }}></StyledBottomCell>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Box>
    </Box>
  )
}

const StyledHeadCell = styled(Box)(({ theme }) => ({
  border: `2px solid ${theme.palette.grey[400]}`,
  borderBottom: 'none',
  borderRadius: '10px 10px 0px 0px',
  margin: theme.spacing(0, 1.2),
  overflow: 'hidden',
}))
const StyledBottomCell = styled(Box)(({ theme }) => ({
  height: '20px',
  border: `2px solid ${theme.palette.grey[400]}`,
  borderRadius: '0px 0px 10px 10px',
  borderTop: 'none',
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxSizing: 'border-box',
  padding: theme.spacing(0),
}))
const StyledHeadCellTitle = styled(Box)(({ color }: { color: string }) => ({
  background: color,
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(2),
  height: '65px',
}))
const StyledHeadCellPrice = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(1),
  height: '120px',
}))

export default MainTable
