import axios from 'axios'
import { API_URL } from '@/api/constants'
import { useMutation, useQueryClient } from '@tanstack/react-query'

const campaignCancel = async (id: string) => {
  const { data } = await axios.delete(`${API_URL}/campaigns/${id}/`, {
    withCredentials: true,
  })

  return data
}

export const useCampaignCancel = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: campaignCancel,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['campaigns'],
      })
    },
  })
}
