import { Box, Typography, styled } from '@mui/material'
import { FC } from 'react'
import StatusBar from './StatusBar'
import { Parser as HtmlToReactParser } from 'html-to-react'

import { Sender } from '@/types/phishingSimulations'
import GmailAppPreview from '@/assets/images/MobilePreview/EmailAppPreview/gmail-frame.svg'
import { useTranslation } from 'react-i18next'

type EmailAppPreviewProps = {
  sender: Sender
  message: string
  subject: string
}

const htmlToReactParser = new (HtmlToReactParser as any)()

const EmailAppPreview: FC<EmailAppPreviewProps> = ({ sender, message, subject }) => {
  const { t } = useTranslation()
  const env = import.meta.env.VITE_ENV
  const domain = env === 'development' || env === 'dev' ? t('feedbackPage.domainDev') : t('feedbackPage.domain')
  return (
    <BackGround>
      <StatusBar color="black" backgroundColor="white" />
      <ScrollBox>
        <AppImageContainer>
          <SubjectContainer>
            <Subject id="subject">{`${subject}`}</Subject>
          </SubjectContainer>
          <SenderNameContainer>
            <EmailSenderName id="sender_name">{`${sender.name}`}</EmailSenderName>
          </SenderNameContainer>
          <SenderContainer>
            <EmailSender id="sender">{`${sender.name} <${sender.mailbox}@${
              (!!sender.subdomain ? sender.subdomain + '.' : '') + domain
            }>`}</EmailSender>
          </SenderContainer>
          <ReplyToContainer>
            <ReplyTo>reply-to@parse.authenticationn.com</ReplyTo>
          </ReplyToContainer>
        </AppImageContainer>
        <MessageContainer>
          <Box bgcolor="#fff">{htmlToReactParser.parse(message)}</Box>
        </MessageContainer>
      </ScrollBox>
    </BackGround>
  )
}

const BackGround = styled(Box)(() => ({
  position: 'absolute',
  backgroundColor: 'white',
  width: '100%',
  height: '100%',
  marginTop: '2.8px',
}))

const EmailSender = styled(Typography)(() => ({
  width: '100%',
  margin: '20% 20% 0% 26%',
  fontSize: '50%',
  overflow: 'clip',
}))

const ReplyTo = styled(Typography)(() => ({
  width: '100%',
  margin: '14% 20% 0% 40%',
  fontSize: '65%',
  overflow: 'clip',
}))

const EmailSenderName = styled(Typography)(() => ({
  width: 'fit-content',
  margin: '6px 0 0 46px',
}))

const Subject = styled(Typography)(() => ({
  width: 'fit-content',
  padding: '10% 20% 0% 0%',
  color: 'black',
  fontSize: '65%',
  display: 'flex',
  justifyContent: 'left',
  overflow: 'clip',
}))

const ScrollBox = styled(Box)(() => ({
  top: '7%',
  width: '100%',
  height: '92%',
  overflowY: 'scroll',
  paddingLeft: '0.4rem',
  '::-webkit-scrollbar': {
    width: '0.4rem',
  },
}))

const SenderContainer = styled(Box)(() => ({
  width: 'fit-content',
  height: 'fit-content',
  fontSize: '18px',
}))

const SenderNameContainer = styled(Box)(() => ({
  width: 'fit-content',
  height: 'fit-content',
  fontSize: '12px',
}))

const ReplyToContainer = styled(Box)(() => ({
  width: 'fit-content',
  height: 'fit-content',
  fontSize: '12px',
}))

const SubjectContainer = styled(Box)(() => ({
  width: 'fit-content',
  height: 'fit-content',
  fontSize: '18px',
}))

const AppImageContainer = styled(Box)(() => ({
  width: '100%',
  height: '55%',
  backgroundImage: `url(${GmailAppPreview})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'fill',
}))

const MessageContainer = styled(Box)(() => ({
  height: 'fit-content',
  overflowY: 'scroll',
}))
export default EmailAppPreview
