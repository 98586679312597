import axios from 'axios';
import { API_URL } from '../constants';
import { useMutation, useQueryCache } from 'react-query';
import { ServerError } from '../types';
import { TrainingCampaign } from './campaign-by-id';

type NewTrainingCampaignVariables = {
  users: string[];
  group_ids: string[];
  office_ids: string[];
  education_module_id: string;
  scheduled: number;
  duration: number;
  name: string;
};

export async function newCampaign(campaignVariables: NewTrainingCampaignVariables) {
  const url = `${API_URL}/training_campaigns/new`;
  const { data } = await axios.post(url, campaignVariables, { withCredentials: true });
  return data.data;
}

export function useNewTrainingCampaign() {
  const queryCache = useQueryCache();
  return useMutation<TrainingCampaign, ServerError, NewTrainingCampaignVariables>(newCampaign, {
    throwOnError: true,
    //@ts-ignore
    onSettled: () => {
      queryCache.invalidateQueries('campaigns');
    },
  });
}
