import axios from 'axios'
import { API_URL } from '../constants'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export async function enableAdmin(_id: string) {
  const url = `${API_URL}/admins/enable/${_id}`
  const { data } = await axios.put(url, {}, { withCredentials: true })
  return data
}

export function useEnableAdmin() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: enableAdmin,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['admins'],
      })
    },
  })
}
