import axios from 'axios'
import { API_URL } from '../constants'
import { useQueryClient } from '@tanstack/react-query'
import { useMutation, useQueryCache } from 'react-query'
import { ServerError } from '../types'
import { User } from './users'
import { NewUserParams } from './new-user'

export type EditUserParams = { _id: string; params: Partial<NewUserParams> }

export async function editUser({ _id, params }: EditUserParams): Promise<User> {
  const url = `${API_URL}/users/edit/${_id}`

  if (typeof params.member_of === 'string') {
    params.member_of = [params.member_of]
  }

  const { data } = await axios.post(url, params, { withCredentials: true })
  return data.data
}

export function useEditUser() {
  const queryClient = useQueryClient()
  const queryCache = useQueryCache()
  return useMutation<User, ServerError, EditUserParams>(editUser, {
    throwOnError: true,
    onSettled: () => {
      queryCache.invalidateQueries('users')
      queryClient.invalidateQueries({ queryKey: ['members'] })
    },
  })
}
