import { IconButton } from '@mui/material'
import { createStyles, makeStyles, useTheme } from '@mui/styles'
import { faHeart as heartIcon, faTrash } from '@fortawesome/pro-light-svg-icons'
import { faHeart as fullHeartIcon } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FC } from 'react'

import { useClientDetails } from '@/api/client/client'
import { PhishingSimulation } from '@/types/phishingSimulations'

const useStyles = makeStyles((theme) =>
  createStyles({
    actionsContainer: { display: 'flex', alignItems: 'center' },
    button: { marginLeft: theme.spacing(1) },
  })
)

type SimulationPageActionsProps = {
  simulation: PhishingSimulation
  editSimulation: (simulation: PhishingSimulation) => void
  deleteSimulation: (simulation: PhishingSimulation) => void
  saveSimulation: (simulation: PhishingSimulation) => void
  isSaved: boolean
}

const SimulationPageActions: FC<SimulationPageActionsProps> = ({
  simulation,
  editSimulation,
  deleteSimulation,
  saveSimulation,
  isSaved,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const { data: clientData } = useClientDetails()

  return (
    <div className={classes.actionsContainer}>
      {/* <IconButton
        disabled={simulation.organization_id !== clientData?._id}
        className={classes.button}
        onClick={() => editTemplate(simulation)}
        size="small"
        color={'inherit'}>
        <EditIcon />
      </IconButton> */}

      <IconButton className={classes.button} onClick={() => saveSimulation(simulation)} size="small" color={'inherit'}>
        <FontAwesomeIcon
          icon={(isSaved ? fullHeartIcon : heartIcon) as IconProp}
          fontSize={22}
          color={theme.palette.pink[500]}
        />
      </IconButton>

      {simulation.organization_id === clientData?._id && (
        <IconButton
          disabled={!simulation.ai_generated}
          className={classes.button}
          onClick={() => deleteSimulation(simulation)}
          size="small"
          color={'inherit'}
        >
          <FontAwesomeIcon icon={faTrash as IconProp} fontSize={22} />
        </IconButton>
      )}
    </div>
  )
}

export default SimulationPageActions
