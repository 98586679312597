import axios from 'axios'
import { useMutation } from 'react-query'

import { API_URL } from '../constants'
import { ServerError } from '../types'

export async function getCampaignReport(id: string): Promise<{ data: Blob; filename: string }> {
  const url = `${API_URL}/campaigns/${id}/report/`
  const { headers, data } = await axios.get(url, {
    withCredentials: true,
    responseType: 'blob',
  })

  let filename = 'report.pdf'
  const contentDisposition = headers['content-disposition']
  const match = contentDisposition?.match(/filename="([^"]+)"/i)
  if (match && match[1]) {
    filename = match[1]
  }
  return { data, filename }
}

export function useGetCampaignReport(onError) {
  return useMutation<{ data: Blob; filename: string }, ServerError, string>(getCampaignReport, {
    onSuccess: ({ data, filename }) => {
      const decodedFilename = decodeURIComponent(filename)

      const link = document.createElement('a')
      link.href = URL.createObjectURL(data)
      link.download = decodedFilename
      link.click()
      URL.revokeObjectURL(link.href)
    },
    onError: (error) => {
      onError && onError(error)
    },
  })
}
