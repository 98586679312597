import { useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { Checkbox, FormControl, OutlinedInput, InputLabel, ListItemText, MenuItem, Select } from '@mui/material'

export type DifficultyFilterProps = {
  onChange: (values: string[]) => void
  values: string[]
  label: string
}

const DIFFICULTIES = ['1', '2', '3', '4', '5']

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  getContentAnchorEl: null,
}

export default function DifficultyFilter({ values, onChange, label }: DifficultyFilterProps) {
  const classes = useStyles()
  const [isFocused, setIsFocused] = useState(false)

  function handleChange(e) {
    onChange(e.target.value as string[])
  }

  return (
    <FormControl className={classes.formControl}>
      <InputLabel size="small" margin="dense" variant="outlined" id={`${label}-label`}>
        {label}
      </InputLabel>
      <Select
        size="small"
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        margin="dense"
        fullWidth
        labelId={`${label}-label`}
        multiple
        value={values}
        onChange={handleChange}
        input={
          <OutlinedInput
            notched={!!values?.length || isFocused}
            label={label}
            style={{ overflow: 'hidden' }}
            classes={{ notchedOutline: classes.notchedOutline }}
          />
        }
        renderValue={(selected) => (selected as string[]).join(', ')}
        //@ts-ignore
        MenuProps={MenuProps}
        variant="outlined"
      >
        {DIFFICULTIES.map((difficulty, index) => (
          <MenuItem key={index} value={difficulty}>
            <Checkbox checked={values.includes(difficulty)} />
            <ListItemText primary={difficulty} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    notchedOutline: {
      '&:hover': {
        borderColor: 'blue',
      },
      borderColor: `${theme.palette.cyan[500]} !important`,
      borderWidth: '1px !important',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 200,
    },
  })
)
