import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Grid, Typography } from "@mui/material";
import ComingSoonImage from "../../../assets/images/coming-soon.svg";
import { useTranslation } from "react-i18next";

type ComingSoonProps = {
  details?: { title: string; bullets: string[] }[];
};

export default function ComingSoon({ details }: ComingSoonProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Grid container spacing={8}>
        <Grid item sm={4} xs={12}>
          <div className={classes.textContainer}>
            <Typography className={classes.title} variant="h4" gutterBottom>
              {t("comingSoon.title")}{" "}
            </Typography>
            <Typography variant="h4">{t("comingSoon.description")}</Typography>
          </div>
        </Grid>
        <Grid
          item
          sm={details?.length ? 4 : 8}
          xs={12}
          alignItems="center"
          justifyContent="center"
        >
          <div className={classes.imgContainer}>
            <img src={ComingSoonImage} alt="Coming Soon" />
          </div>
        </Grid>
        {details?.length ? (
          <Grid item sm={4} xs={12} alignItems="center" justifyContent="center">
            {details.map((detail) => {
              return (
                <div className={classes.detailsSection}>
                  <Typography variant="subtitle2">{detail.title}</Typography>
                  <ul>
                    {detail.bullets.map((bullet) => {
                      return <li>{bullet}</li>;
                    })}
                  </ul>
                </div>
              );
            })}
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: { height: "100%", padding: theme.spacing(4, 8) },
    textContainer: {
      paddingTop: theme.spacing(10),
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      marginBottom: theme.spacing(4),
    },
    title: { fontWeight: 700 },
    progress: {
      marginBottom: theme.spacing(1),
    },
    imgContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingTop: theme.spacing(4),
    },
    detailsSection: { marginBottom: theme.spacing(2) },
  })
);
