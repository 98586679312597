import { theme } from '@/theme/theme'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Typography, styled } from '@mui/material'
import { FC } from 'react'

const StepperIcon: FC<{ faIcon: IconProp; label: string }> = ({ faIcon, label }) => {
  return (
    <StyledCircle>
      <FontAwesomeIcon icon={faIcon} fontSize={28} />
      <Typography fontSize={12}>{label}</Typography>
    </StyledCircle>
  )
}

const StyledCircle = styled(Box)(() => ({
  backgroundColor: theme.palette.blueGray[900],
  zIndex: 1,
  color: theme.palette.cyan[500],
  width: 85,
  height: 85,
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: '-28px',
}))

export default StepperIcon
