import { FC, ReactNode } from 'react'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import useLottie from '@/common/hooks/useLottie'
import defaultAnimationData from '@/assets/lottie/surprised.json'
import { Typography } from '@mui/material'

type InTableMessageWithAnimationProps = {
  animation?: string
  height?: number
  children?: ReactNode | ReactNode[]
}

const InTableMessageWithAnimation: FC<InTableMessageWithAnimationProps> = ({
  animation = defaultAnimationData,
  height,
  children,
}) => {
  const classes = useStyles({ height })
  const { LottieAnimation } = useLottie({
    animationData: animation,
    height: 119,
    width: 119,
  })
  return (
    <div className={classes.root}>
      {LottieAnimation}
      <Typography variant={'subtitle1'} color={'textSecondary'}>
        {children}
      </Typography>
    </div>
  )
}

export default InTableMessageWithAnimation

const useStyles = makeStyles<Theme, { height: number | undefined }>(() =>
  createStyles({
    root: {
      height: ({ height }) => height ?? 300,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      textAlign: 'center',
    },
  })
)
