import React, { useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Plugins from "../Settings/Plugins/Plugins";

import { Button, CircularProgress, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useApps } from "../../api/apps/apps";
import { useNavigate } from "react-router-dom";
import { useOnboardApp } from "../../api/apps/onboard";
import useToast from "../../common/hooks/useToast";

export default function ReportsOnboarding() {
  const classes = useStyles();
  const navigateTo = useNavigate();
  const { t } = useTranslation();
  const { errorToast } = useToast();
  const { data: apps, isLoading } = useApps();
  const [onboardApp, { isLoading: isOnboarding }] = useOnboardApp();
  const reportsApp =
    apps && apps.find((value) => value.title.toLowerCase() === "reports");

  async function handleOnboarding() {
    if (reportsApp) {
      try {
        await onboardApp(reportsApp.key);
        navigateTo("/response-console");
      } catch (e) {
        errorToast(t("apps.errors.failedToOnboard"));
      }
    }
  }

  useEffect(() => {
    if (!isLoading) {
      if (reportsApp?.onboarded) {
        //navigateTo('/');
      }
    }
  }, [isLoading, reportsApp]);

  return (
    <div className={classes.root}>
      <Typography
        className={classes.title}
        color={"inherit"}
        variant={"button"}
      >
        {t("reportsOnboarding.reportsOnboarding")}
      </Typography>
      <Plugins />
      <Button
        onClick={handleOnboarding}
        className={classes.button}
        variant={"contained"}
        color={"primary"}
      >
        {isOnboarding ? (
          <CircularProgress color={"inherit"} size={24} />
        ) : (
          t("examinerOnboarding.getStarted")
        )}
      </Button>
    </div>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: "100%",
      width: "100%",
      padding: theme.spacing(2, 4, 4),
    },
    title: {
      color: theme.palette.text.secondary,
      textTransform: "capitalize",
    },
    button: {
      marginTop: theme.spacing(2),
    },
  })
);
