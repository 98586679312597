import {
  SET_SIMULATION_TYPE,
  SET_VECTOR_TYPE,
  SET_WIZARD_CURRENT_STEP,
  WizardAction,
  WizardState,
} from "./Types";

export function wizardReducer(
  state: WizardState,
  action: WizardAction
): WizardState {
  switch (action.type) {
    case SET_WIZARD_CURRENT_STEP:
      return { ...state, currentStep: action.payload };

    case SET_VECTOR_TYPE:
      return { ...state, vectorType: action.payload };

    case SET_SIMULATION_TYPE:
      return { ...state, simulationType: action.payload };

    default:
      return state;
  }
}
