import axios from 'axios'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { API_URL } from '../constants'

type EnableMembersParam = {
  users: string[]
}

const enableMembers = async (params: EnableMembersParam) => {
  //TODO Replace this endpoint with members{PUT}
  const url = `${API_URL}/users/enable`
  const { data } = await axios.post(url, params, { withCredentials: true })

  return data
}

const useEnableMembers = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: enableMembers,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['members'],
      })
    },
  })
}

export default useEnableMembers
