import React from "react";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import TextField from "@mui/material/TextField";

type EditableCellProps = {
  value: any;
  cell: {
    value: any;
  };
  row: {
    index: number;
  };
  column: {
    id: number;
  };
  updateMyData: (index: number, id: number, value: any) => void;
  error?: boolean;
  updateWhileTyping?: boolean;
};

export default function EditableCell({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
  error = false,
  updateWhileTyping = true,
}: EditableCellProps) {
  const classes = useStyles();
  const [value, setValue] = React.useState(initialValue);

  const onChange = (e) => {
    setValue(e.target.value);
    if (updateWhileTyping) {
      updateMyData(index, id, e.target.value);
    }
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value);
  };

  // If the initialValue is changed externall, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <TextField
      error={error}
      className={classes.input}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    input: { padding: 0, margin: 0, border: 0, background: "transparent" },
  })
);
