import { FC, useMemo } from 'react'
import { useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { EducationModule } from '@/api/education-modules/modules'
import { Box, Chip, IconButton, Tooltip, Typography } from '@mui/material'
import Tags from '@/common/components/Tags/Tags'
import { useTranslation } from 'react-i18next'
import { ColumnInterface, Row } from 'react-table'
import SimpleTable from '@/common/components/Tables/SimpleTable'

import {
  faHeart as heartIcon,
  faCircleInfo,
  faTrash,
  faPenPaintbrush,
  faGraduationCap,
} from '@fortawesome/pro-light-svg-icons'
import { faHeart as fullHeartIcon } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '@/context/Auth'
import { secondsToTimestamp } from '@/common/utils/secondsToTimestamp'
import { shortenString } from '@/common/utils/shortenString'

type VideoLibraryTableProps = {
  openPreviewModal: (video: EducationModule) => void
  deleteModule: (video: EducationModule) => void
  launchModule: (video: EducationModule) => void
  saveModule: (video: EducationModule) => void
  videosByTopic: {
    [topic: string]: EducationModule[]
  }
  savedVideos: string[]
}

const VideoLibraryTable: FC<VideoLibraryTableProps> = ({
  deleteModule,
  saveModule,
  videosByTopic = {},
  savedVideos = [],
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const { user } = useAuth()
  const navigateTo = useNavigate()

  const videos = useMemo(() => {
    return videosByTopic ? Object.values(videosByTopic).flat() : []
  }, [videosByTopic])

  const columns: ColumnInterface[] = useMemo(
    () => [
      {
        Header: t('videoLibrary.table.title'),
        accessor: 'title',
        width: 250,
        Cell: ({ value, row }) => {
          const video = row.original
          const isCustom = video.client_id === user?.current_client_id
          const isLesson = video.additional_resources?.lesson_plan
          return (
            <Box display="flex" alignItems="center">
              <Typography>{value}</Typography>
              {isCustom && !isLesson ? (
                <Chip
                  size="small"
                  label={t('videoLibrary.custom')}
                  icon={<FontAwesomeIcon icon={faPenPaintbrush as IconProp}></FontAwesomeIcon>}
                />
              ) : null}
              {isLesson ? (
                <Chip
                  size="small"
                  label={t('videoLibrary.lesson')}
                  sx={{ background: theme.palette.lightGreen[300] }}
                  icon={<FontAwesomeIcon icon={faGraduationCap as IconProp}></FontAwesomeIcon>}
                />
              ) : null}
            </Box>
          )
        },
      },
      {
        Header: t('videoLibrary.table.duration'),
        accessor: 'duration',
        cellProps: {
          align: 'center',
        },
        Cell: ({ value }) => {
          return value ? secondsToTimestamp(value) : ''
        },
      },
      {
        Header: t('videoLibrary.table.topics'),
        accessor: 'tags',
        cellProps: {
          align: 'center',
        },
        Cell: ({ value }) => (
          <Tags
            justify="center"
            useChips={false}
            tags={(value ?? []).map((tag) => ({
              label: tag,
            }))}
            limit={1}
          />
        ),
        disableSortBy: true,
      },
      {
        Header: t('videoLibrary.table.quizQuestions'),
        accessor: 'quizzes',
        cellProps: {
          align: 'center',
        },
        Cell: ({ value }) => {
          return (
            <Typography className={classes.questionCount}>{value?.length ? value[0].questions.length : ''}</Typography>
          )
        },
      },
      //  TODO Uncomment once training programs exist
      //   {
      //     Header: t("videoLibrary.table.partOf"),
      //     accessor: "training_modules",
      //     disableSortBy: true,
      //     Cell: ({ value }) => (value || []).join(", "),
      //   },
      {
        id: 'delete',
        Cell: (data) => {
          const video = data.row.original
          const isSaved = savedVideos?.includes(video._id)
          const isCustom = video.client_id === user?.current_client_id
          const isLesson = video.additional_resources?.lesson_plan

          return (
            <div className={classes.actionsContainer}>
              <IconButton
                className={classes.button}
                onClick={(e) => {
                  e.stopPropagation()
                  saveModule(video)
                }}
                size="small"
                color={'inherit'}>
                <FontAwesomeIcon
                  icon={(isSaved ? fullHeartIcon : heartIcon) as IconProp}
                  fontSize={22}
                  color={theme.palette.pink[500]}
                />
              </IconButton>
              <Tooltip
                title={
                  <div className={classes.tooltip}>
                    <Typography variant="subtitle2">{t('videoLibrary.summary')}</Typography>
                    <Typography variant="body1">{shortenString(video.description, 150)}</Typography>
                  </div>
                }
                placement={'top'}>
                <FontAwesomeIcon icon={faCircleInfo as IconProp} fontSize={22} />
              </Tooltip>

              <IconButton
                disabled={!isCustom || isLesson}
                className={classes.button}
                onClick={(e) => {
                  e.stopPropagation()
                  deleteModule(video)
                }}
                size="small"
                color={'inherit'}>
                <FontAwesomeIcon icon={faTrash as IconProp} fontSize={22} />
              </IconButton>
            </div>
          )
        },
      },
    ],
    [
      t,
      user,
      classes.lessonChip,
      classes.customChip,
      classes.questionCount,
      classes.actionsContainer,
      classes.button,
      classes.tooltip,
      savedVideos,
      theme.palette.blueGray[900],
      saveModule,
      deleteModule,
    ]
  )

  function handleOpenPreview({ original }: Row) {
    if (!original?._id) {
      return
    }
    navigateTo(`/content-library/videos/${original._id}`)
  }
  return (
    <div className={classes.root}>
      <SimpleTable
        onRowClick={handleOpenPreview}
        columns={columns}
        data={videos}
        setData={() => {}}
        noResultsTextId={'library.noResults'}
        enableCheckbox={false}
        initialPageSize={10}
      />
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2, 0, 4),
    },
    paper: {
      overflow: 'hidden',
      '& .MuiTableCell-head': {
        textAlign: 'center',
        paddingLeft: 40,
        '&:first-child': {
          paddingLeft: 15,
          textAlign: 'left',
        },
      },
      '& .MuiTableCell-body': {
        textAlign: 'center',
        '&:first-child': {
          textAlign: 'left',
        },
      },
    },
    actionsContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    button: {
      margin: theme.spacing(0, 0.5),
      color: theme.palette.blueGray[900],
    },
    tooltip: {
      padding: theme.spacing(1, 0.5),
    },
    questionCount: {
      // paddingLeft: theme.spacing(7),
    },
  })
)
export default VideoLibraryTable
