import { Dispatch, SetStateAction } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Chip from '@mui/material/Chip'
import Paper from '@mui/material/Paper'

type ChipsArrayProps = {
  chipData: { label: string; _id: string }[]
  setChipData: Dispatch<SetStateAction<{ label: string; _id: string }[]>>
}

export default function ChipsArray({ chipData, setChipData }: ChipsArrayProps) {
  const classes = useStyles()

  // const handleDelete = (chipToDelete) => () => {
  //   setChipData((chips) =>
  //     chips.filter((chip) => chip.label !== chipToDelete.label)
  //   );
  // };

  return (
    <Paper component="ul" className={classes.root}>
      {chipData.map((data) => {
        return (
          <li key={data.label}>
            <Chip
              label={data.label}
              // onDelete={data._id ? undefined : handleDelete(data)}
            />
          </li>
        )
      })}
    </Paper>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
}))
