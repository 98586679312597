import { Paper, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { CircularProgressbarWithChildren } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { useTranslation } from 'react-i18next'
import useMeasure from 'react-use-measure'

type CompletionRateProps = {
  value: number
  textId?: string
  showPercent?: boolean
  color?: string
  size?: number
}

export default function CompletionRate({
  value = 0,
  textId = 'trainingCampaignDashboard.completedTraining',
  showPercent = true,
  color = '#41c5c9',
  size,
}: CompletionRateProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [ref, { height }] = useMeasure()

  return (
    <div className={classes.root} ref={ref}>
      <div style={{ width: size || height, height: size || height, overflow: 'visible' }}>
        <CircularProgressbarWithChildren
          strokeWidth={20}
          value={value}
          styles={{
            // Customize the root svg element
            root: {},
            // Customize the path, i.e. the "completed progress"
            path: {
              // Path color
              stroke: color,
              // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
              strokeLinecap: 'butt',
              // Customize transition animation
              transition: 'stroke-dashoffset 0.5s ease 0s',
              // Rotate the path
            },
            // Customize the circle behind the path, i.e. the "total progress"
            trail: {
              // Trail color
              stroke: '#D8D8D8',
              // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
              strokeLinecap: 'butt',
              // Rotate the trail
              transform: 'rotate(0.625turn)',
              transformOrigin: 'center center',
            },
            // Customize the text
            text: {
              // Text color
              fill: '#f88',
              // Text size
              fontSize: '16px',
            },
            // Customize background - only used when the `background` prop is true
            background: {
              fill: '#3e98c7',
            },
          }}
        >
          <Paper elevation={4} className={classes.textContainer}>
            <Typography
              variant={'h4'}
              color={'textPrimary'}
              style={{
                fontWeight: 700,
                textAlign: 'center',
                marginLeft: showPercent ? 16 : 0,
              }}
            >
              {value < 0 ? '-' : value}
              {showPercent ? '%' : ''}
            </Typography>
            <Typography
              variant={'subtitle1'}
              color={'textPrimary'}
              style={{ fontWeight: 700, textAlign: 'center', lineHeight: 1.2 }}
            >
              {t(textId)}
            </Typography>
          </Paper>
        </CircularProgressbarWithChildren>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'visible',
    },
    textContainer: {
      color: theme.palette.common.white,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 700,
      width: '75%',
      height: '75%',
      borderRadius: '50%',
    },
  })
)
