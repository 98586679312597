import axios from "axios";
import { API_URL } from "../constants";
import { useQuery } from "react-query";
import { ServerError } from "../types";

export type Category = {
  _id: string;
  name: string;
  tags: string[];
};

type CategoriesResponse = {
  categories: Category[];
  categories_by_tag: {
    [key: string]: Category[];
  };
};

export async function getAllCategories() {
  const url = `${API_URL}/v1/training-videos/categories`;
  const { data } = await axios.get(url, { withCredentials: true });
  return data;
}

export function useCategories() {
  return useQuery<CategoriesResponse, ServerError>(
    "categories",
    getAllCategories
  );
}
