import { FC } from 'react'
import { Tooltip } from '@mui/material'

interface TruncatedTextProps {
  text: string
  maxLength?: number
}

const TruncatedText: FC<TruncatedTextProps> = ({ text, maxLength = 15 }) => {
  const isTruncated = text.length > maxLength
  const truncatedText = isTruncated ? `${text.slice(0, maxLength)}...` : text

  return isTruncated ? (
    <Tooltip title={text} placement="top-end">
      <span>{truncatedText}</span>
    </Tooltip>
  ) : (
    <span>{truncatedText}</span>
  )
}

export default TruncatedText
