import { DefinedInitialDataOptions, useQuery } from '@tanstack/react-query'
import axios from 'axios'

import { API_URL } from '@/api/constants'
import { PhishingSimulationPackage } from '@/types/phishingSimulations'

const getPhishingSimulationPackages = async () => {
  const url = `${API_URL}/phishing-simulations/packages`
  const { data } = await axios.get(url, {
    withCredentials: true,
  })

  return data
}

const getPhishingSimulationPackage = async (id: string | undefined) => {
  const url = `${API_URL}/phishing-simulations/packages/${id}`
  const { data } = await axios.get(url, {
    withCredentials: true,
  })

  return data
}

export const usePhishingSimulationPackages = (options?: DefinedInitialDataOptions<PhishingSimulationPackage[]>) => {
  return useQuery<PhishingSimulationPackage[]>({
    queryKey: ['phishing-simulations-packages'],
    queryFn: getPhishingSimulationPackages,
    ...options,
  })
}

export const usePhishingSimulationPackage = (
  id: string | undefined,
  options?: DefinedInitialDataOptions<PhishingSimulationPackage>
) => {
  return useQuery<PhishingSimulationPackage>({
    queryKey: ['phishing-simulations-packages', id],
    queryFn: () => getPhishingSimulationPackage(id),
    ...options,
  })
}
