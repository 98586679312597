import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import questionIcon from '@/assets/images/popup/question.svg'
import Popup from '@/common/components/Popup'
import { Admin } from '@/types/admins'

type AdminStatusPopupProps = {
  open: boolean
  onClose: () => void
  onConfirm: (admin: Admin) => void
  admin?: Admin
}

const AdminStatusPopup: FC<AdminStatusPopupProps> = ({ open, onClose, onConfirm, admin }) => {
  const { t } = useTranslation()

  return (
    <Popup
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
      disableBackdropClick
      buttonLabels={{
        confirm: t('settings.admins.disableModal.confirmButton'),
        // cancel: t('settings.admins.disableModal.cancelButton'),
      }}
      icon={questionIcon}>
      <h4>{t('settings.admins.disableModal.title')}</h4>
      <p>
        {t('settings.admins.disableModal.message1', {
          admin_name: admin?.full_name,
        })}
      </p>
      <p>{t('settings.admins.disableModal.message2')}</p>
    </Popup>
  )
}

export default AdminStatusPopup
