import { Box, Button, Typography, styled } from '@mui/material'
import Modal from '@mui/material/Modal'
import { Form, Formik } from 'formik'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
//@ts-ignore
import * as Yup from 'yup'

import { useInviteAdmin } from '@/api/admins/invite-admin'
import { getErrorMessage } from '@/api/utils/get-error'
import TextField from '@/common/components/Inputs/TextField'
import useToast from '@/common/hooks/useToast'

type CInviteAdminModalProps = {
  buttonClass?: string
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  onClose?: () => void
}

const InviteAdminModal = ({ buttonClass, open, setOpen, onClose = () => {} }: CInviteAdminModalProps) => {
  const { t } = useTranslation()
  const { mutateAsync: createNewAdmin } = useInviteAdmin()
  const { successToast, errorToast } = useToast()
  const handleClose = () => {
    onClose()
    setOpen(false)
  }

  const handleInvite = async (values: { fullName: string; email: string }) => {
    try {
      const { fullName, email } = values
      await createNewAdmin({ username: email.toLowerCase(), full_name: fullName })
      handleClose()
      successToast(t('settings.admins.modal.invitationSent') + fullName)
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      errorToast(
        t('settings.admins.modal.errors.failedToSendInvitation', {
          value: errorMessage ? errorMessage : '',
        })
      )
    }
  }
  const validationSchema = Yup.object().shape({
    fullName: Yup.string()
      .min(2, t('settings.admins.table.tooShort'))
      .max(50, t('settings.admins.table.tooLong'))
      .required(t('settings.admins.table.required')),
    email: Yup.string().email(t('settings.admins.table.invalidEmail')).required(t('settings.admins.table.required')),
  })

  const initialValues = { fullName: '', email: '' }
  return (
    <>
      <Button onClick={() => setOpen(true)} className={buttonClass} variant={'outlined'}>
        {t('settings.admins.table.inviteAdmin')}
      </Button>
      <Modal open={open} onClose={handleClose}>
        <ModalConent>
          <TitleBar>
            <Typography variant="h6" component="h2">
              {t('settings.admins.table.inviteNewAdmin')}
            </Typography>
          </TitleBar>
          <Box sx={{ p: '16px', overflowY: 'auto', maxHeight: '90vh' }}>
            <Formik onSubmit={handleInvite} initialValues={initialValues} validationSchema={validationSchema}>
              {({ setFieldValue, isSubmitting, values, isValid, dirty }) => (
                <Form>
                  <FieldsWrapper>
                    <Box sx={{ height: '50px' }}>
                      <TextField
                        onChange={(value) => {
                          setFieldValue('fullName', value.target.value)
                        }}
                        required
                        label={values.fullName && t('settings.admins.table.fullName')}
                        name="fullName"
                        placeholder={t('settings.admins.table.fullName')}
                      />
                    </Box>
                    <Box sx={{ height: '50px' }}>
                      <TextField
                        onChange={(value) => {
                          setFieldValue('email', value.target.value)
                        }}
                        required
                        label={values.fullName && t('settings.admins.table.email')}
                        name="email"
                        placeholder={t('settings.admins.table.email')}
                      />
                    </Box>
                  </FieldsWrapper>
                  <ButtonsWrapper>
                    <Button variant="outlined" onClick={handleClose}>
                      {t('settings.admins.table.cancel')}
                    </Button>
                    <Button
                      disableRipple
                      type="submit"
                      disabled={!dirty || !isValid || isSubmitting}
                      variant="contained">
                      {t('settings.admins.table.invite')}
                    </Button>
                  </ButtonsWrapper>
                </Form>
              )}
            </Formik>
          </Box>
        </ModalConent>
      </Modal>
    </>
  )
}

const FieldsWrapper = styled(Box)(() => ({
  padding: '20px 20px 40px',
  display: 'flex',
  gap: 16,
  flexDirection: 'column',
}))
const ButtonsWrapper = styled(Box)(() => ({
  padding: '0px 20px 10px',
  display: 'flex',
  gap: 40,
  flexDirection: 'row',
  justifyContent: 'center',
}))
const TitleBar = styled(Box)(() => ({
  display: 'flex',
  color: '#05DBF2',
  alignItems: 'center',
  padding: '15px 26px',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  background: '#000',
  borderRadius: '10px 10px 0px 0px',
}))

const ModalConent = styled(Box)(() => ({
  position: 'absolute',
  top: '35%',
  left: '50%',
  transform: 'translate(-50%, -35%)',
  width: 400,
  backgroundColor: 'white',
  p: 4,
  '&:focus-visible': {
    outline: 'none',
  },
  borderRadius: '10px',
}))

export default InviteAdminModal
