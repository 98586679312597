import { Findings } from "../../components/DifficultyLevelSelector";

export const SET_EMAIL_LANGUAGE = "SET_EMAIL_LANGUAGE";
export const SET_EMAIL_SUBJECT = "SET_EMAIL_SUBJECT";
export const SET_EMAIL_BODY = "SET_EMAIL_BODY";
export const SET_SENDER_NAME = "SET_SENDER_NAME";
export const SET_TONE_OF_VOICE = "SET_TONE_OF_VOICE";
export const SET_SENDER_POSITION = "SET_SENDER_POSITION";
export const SET_INSTRUCTIONS = "SET_INSTRUCTIONS";
export const SET_SUBJECT_OUTPUT = "SET_SUBJECT_OUTPUT";
export const SET_BODY_OUTPUT = "SET_BODY_OUTPUT";
export const SET_DESIGNED_EMAIL = "SET_DESIGNED_EMAIL";
export const SET_APP_NAME = "SET_APP_NAME";
export const SET_CUSTOM_LOGO = "SET_CUSTOM_LOGO";
export const SET_ICON_THEME = "SET_ICON_THEME";
export const SET_BUTTON_COLOR = "SET_BUTTON_COLOR";
export const SET_BUTTON_TEXT_COLOR = "SET_BUTTON_TEXT_COLOR";
export const SET_EMAIL_SENDER_SIGNATURE = "SET_EMAIL_SENDER_SIGNATURE";
export const SET_DIFFICULTY_LEVEL = "SET_DIFFICULTY_LEVEL";
export const SET_FINDINGS = "SET_FINDINGS";
export const SET_FINDINGS_INSTRUCTIONS = "SET_FINDINGS_INSTRUCTIONS";
export const RESET_EMAIL_STATE = "RESET_EMAIL_STATE";
export const SET_PERSOANLIZED_TEMPLATE = "SET_PORSOANLIZED_EMAIL";
export const SET_APP_LANDING_TEMPLATE = "SET_APP_LANDING_TEMPLATE";

export interface EmailState {
  language: string;
  emailSubject: string;
  emailBody: string;
  senderName: string;
  toneOfVoice: string;
  senderPosition: string;
  instructions: string;
  subjectOutput: string;
  bodyOutput: string;
  designedEmail: boolean;
  appName: string;
  emailSenderSignature: string;
  customLogo: string;
  iconTheme: string;
  difficultyLevel: number;
  findings: Findings[];
  findingsInstructions: string;
  buttonColor: string;
  buttonTextColor: string;
  personalizedTemplate: boolean;
  appLandingTemplate: string;
}

export type EmailAction =
  | { type: typeof SET_EMAIL_LANGUAGE; payload: string }
  | { type: typeof SET_EMAIL_SUBJECT; payload: string }
  | { type: typeof SET_EMAIL_BODY; payload: string }
  | { type: typeof SET_SENDER_NAME; payload: string }
  | { type: typeof SET_TONE_OF_VOICE; payload: string }
  | { type: typeof SET_SENDER_POSITION; payload: string }
  | { type: typeof SET_INSTRUCTIONS; payload: string }
  | { type: typeof SET_SUBJECT_OUTPUT; payload: string }
  | { type: typeof SET_BODY_OUTPUT; payload: string }
  | { type: typeof SET_DESIGNED_EMAIL; payload: boolean }
  | { type: typeof SET_APP_NAME; payload: string }
  | { type: typeof SET_EMAIL_SENDER_SIGNATURE; payload: string }
  | { type: typeof SET_CUSTOM_LOGO; payload: string }
  | { type: typeof SET_ICON_THEME; payload: string }
  | { type: typeof SET_DIFFICULTY_LEVEL; payload: number }
  | { type: typeof SET_FINDINGS; payload: Findings[] }
  | { type: typeof SET_FINDINGS_INSTRUCTIONS; payload: string }
  | { type: typeof SET_BUTTON_COLOR; payload: string }
  | { type: typeof SET_BUTTON_TEXT_COLOR; payload: string }
  | { type: typeof SET_PERSOANLIZED_TEMPLATE; payload: boolean }
  | { type: typeof RESET_EMAIL_STATE }
  | { type: typeof SET_APP_LANDING_TEMPLATE; payload: string };
