import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { Tooltip, TooltipProps } from '@mui/material'
import { faClockRotateLeft } from '@fortawesome/pro-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC } from 'react'

interface InfoTooltipProps extends Omit<TooltipProps, 'children' | 'title'> {
  title: string
}
const InfoTooltip: FC<InfoTooltipProps> = ({ title, ...rest }) => {
  const classes = useStyles()
  return (
    <Tooltip title={title} {...rest}>
      <FontAwesomeIcon icon={faClockRotateLeft as IconProp} className={classes.icon} />
    </Tooltip>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    icon: {
      marginLeft: theme.spacing(0.6),
      marginTop: theme.spacing(0.2),
      color: '#FF0000',
    },
  })
)
export default InfoTooltip
