import { theme } from '../../theme/theme'

export function difficultyToColor(difficulty: number): string {
  return difficulty > 60
    ? theme.palette.error.main
    : difficulty < 40
    ? theme.palette.success.main
    : theme.palette.warning.main
}

export function templateDifficultyToColor(difficulty: number): string {
  switch (difficulty) {
    case 1:
      return theme.palette.success.light
    case 2:
      return theme.palette.success.main
    case 3:
      return theme.palette.warning.light
    case 4:
      return theme.palette.warning.main
    case 5:
      return theme.palette.error.main
    default:
      return theme.palette.success.main
  }
}

export function scoreToColor(score: number, max = 10): string {
  const percentage = score / max
  return score <= 0 || isNaN(score)
    ? theme.palette.grey['400']
    : percentage <= 0.25
    ? theme.palette.error.main
    : percentage <= 0.5
    ? theme.palette.warning.main
    : percentage <= 0.75
    ? theme.palette.warning.light
    : theme.palette.success.main
}

export function difficultyToSvgGradient(difficulty: number): string {
  return difficulty > 60 ? 'errorGradient' : difficulty < 40 ? 'successGradient' : 'warningGradient'
}

export function difficultyToGradient(difficulty: number): string {
  return difficulty > 60
    ? (theme.palette.background.errorGradient as string)
    : difficulty < 40
    ? (theme.palette.background.successGradient as string)
    : (theme.palette.background.warningGradient as string)
}
