import { useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { useDropzone } from 'react-dropzone'
import { IconButton, TextField, CircularProgress, OutlinedTextFieldProps } from '@mui/material'
import { useUpload } from '../../../api/upload/upload'
import { faUpload } from '@fortawesome/pro-light-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useToast from '../../hooks/useToast'

type FileUploadProps = {
  label: string
  onUpload: (url: string) => void
  textFieldProps?: OutlinedTextFieldProps
  initialValue?: string
}

export default function FileUpload({ label, onUpload, textFieldProps, initialValue }: FileUploadProps) {
  const classes = useStyles()
  const { errorToast } = useToast()
  const [fileName, setFileName] = useState(initialValue ? initialValue : 'Click To Upload File')
  const [uploadFile, { isLoading }] = useUpload()
  const { getInputProps, open: openFileSelector } = useDropzone({
    onDrop: handleUpload,
    noDrag: true,
    multiple: false,
    accept: '.jpg, .png, .mp4, .srt, .vtt',
  })

  async function handleUpload(acceptedFiles) {
    try {
      const files = await uploadFile(acceptedFiles[0])
      if (files) {
        setFileName(acceptedFiles[0].name)
        onUpload(files[0].url)
      } else {
        errorToast('Woops')
      }
    } catch (e) {
      errorToast('Woops')
    }
  }

  return (
    <div className={classes.root} onClick={openFileSelector}>
      <TextField
        label={label}
        variant={'outlined'}
        value={fileName}
        InputProps={{
          endAdornment: (
            <IconButton
              onClick={(e) => {
                e.stopPropagation()
                openFileSelector()
              }}
              size="large"
            >
              {isLoading ? (
                <CircularProgress color={'primary'} size={24} />
              ) : (
                <FontAwesomeIcon icon={faUpload as IconProp} fontSize={20} />
              )}
            </IconButton>
          ),
        }}
        {...textFieldProps}
      />
      <input id={'fileUpload'} {...getInputProps()} />
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
  })
)
