import { Dialog, Grid, Box, DialogContent, DialogContentText, Typography, DialogActions, Button } from '@mui/material/'
import { makeStyles, createStyles } from '@mui/styles'
import ConfirmationModalImage from '../../../assets/images/Integrations/Modal/ConfirmationModal.png'
import { theme } from '@/theme/theme'

type InactiveMembersModalProps = {
  confirmInactiveOpen: boolean
  handleConfirmInactive: () => void
  handleCancelInactive: () => void
}

const InactiveMembersModal = ({
  confirmInactiveOpen,
  handleConfirmInactive,
  handleCancelInactive,
}: InactiveMembersModalProps) => {
  const classes = useStyles()

  return (
    <Dialog
      open={confirmInactiveOpen}
      onClose={handleCancelInactive}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="sm"
    >
      <Grid container spacing={2} className={classes.gridWrapper}>
        <Grid item xs={3}>
          <Box component="img" src={ConfirmationModalImage} alt="Confirmation" className={classes.confirmModalImage} />
        </Grid>
        <Grid item xs={9}>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" className={classes.dialogContentText}>
              <Typography className={classes.bold}>Hey, Wait!!</Typography>
              <Typography gutterBottom></Typography>
              <Typography gutterBottom>
                Are you sure you want to move your members to <strong>inactive</strong>?
              </Typography>
              <Typography gutterBottom></Typography>
              <Typography gutterBottom></Typography>
              <Typography className={classes.bold}>Inactive members can't be used for anything.</Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.dialogWrapper}>
            <Button onClick={handleCancelInactive} variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleConfirmInactive} autoFocus variant="contained">
              Proceed
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default InactiveMembersModal

const useStyles = makeStyles(() =>
  createStyles({
    gridWrapper: {
      padding: '10px 10px 10px 0px',
    },
    dialogContentText: {
      '&.MuiTypography-root': {
        color: theme.palette.blueDianne[900],
      },
    },
    confirmModalImage: {
      width: '186px',
      height: '190px',
    },
    bold: {
      fontWeight: 'bold',
    },
    dialogWrapper: {
      marginTop: 15,
    },
  })
)
