import { Box, Typography, styled } from '@mui/material'
import senderImage from '@/assets/images/MobilePreview/WhatsAppPreview/WhatsAppSender.svg'
import bottomImage from '@/assets/images/MobilePreview/WhatsAppPreview/WhatsAppBottom.svg'
import todayImage from '@/assets/images/MobilePreview/WhatsAppPreview/WhatsAppToday.svg'
import backgroundImage from '@/assets/images/MobilePreview/WhatsAppPreview/WhatsAppBackGround.png'
import { FC } from 'react'
import StatusBar from './StatusBar'

type WhatsAppPreviewProps = {
  sender: string
  message: string
  isRtl?: boolean
}
const WhatsAppPreview: FC<WhatsAppPreviewProps> = ({ sender, message, isRtl = false }) => {
  return (
    <BackGround>
      <StatusBar color="white" backgroundColor="#008069" />
      <SenderConainer id="sender">
        <Sender>
          {/* TODO: use translation */}
          {sender || '{ Sender }'}
        </Sender>
      </SenderConainer>
      {/* </StatusBar> */}
      <ScrollBox>
        <TodayBox />
        <MessagwBoxTop isRtl={isRtl} />
        <MessagwBox>
          {/* TODO: use translation */}
          <Message
            isRtl={isRtl}
            dangerouslySetInnerHTML={{
              __html: message !== '' ? message : 'Your Message will be displayed here...',
            }}
          />
        </MessagwBox>
        <MessagwBoxBottom />
      </ScrollBox>
      <Bottom />
    </BackGround>
  )
}

const BackGround = styled(Box)(() => ({
  position: 'absolute',
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: '104%',
  marginTop: '1.5%',
  width: '100%',
  height: '100%',
}))
const SenderConainer = styled(Box)(() => ({
  backgroundImage: `url(${senderImage})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  backgroundColor: '#008069',
  height: '8%',
}))
const Sender = styled(Typography)(() => ({
  width: '100%',
  padding: '4% 28% 0% 22%',
  color: 'white',
  fontSize: '85%',
  display: 'flex',
  justifyContent: 'center',
  overflow: 'clip',
  fontWeight: 'bold',
}))

const ScrollBox = styled(Box)(() => ({
  width: '100%',
  height: '76%',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignContent: 'flex-start',
  overflowY: 'scroll',
  paddingLeft: '0.4rem',
  '::-webkit-scrollbar': {
    width: '0.4rem',
  },
}))
const TodayBox = styled(Box)(() => ({
  display: 'block',
  backgroundImage: `url(${todayImage})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  width: '80%',
  height: '20.2%',
}))
const MessagwBoxTop = styled(Box)(({ isRtl }: { isRtl: boolean }) => ({
  display: 'block',
  backgroundColor: 'white',
  width: '88%',
  padding: '3%',
  marginRight: isRtl ? 'auto' : '7.5%',
  marginLeft: isRtl ? '7.5%' : 'auto',
  borderTopRightRadius: isRtl ? '0rem' : '3rem',
  borderBottomLeftRadius: isRtl ? '0rem' : '5rem',
  borderTopleftRadius: isRtl ? '3rem' : '0rem',
  borderBottomRightRadius: isRtl ? '5rem' : '0rem',
}))
const MessagwBoxBottom = styled(Box)(() => ({
  display: 'block',
  backgroundColor: 'white',
  width: '85%',
  padding: '2%',
  borderBottomLeftRadius: '5rem',
  borderBottomRightRadius: '5rem',
}))
const MessagwBox = styled(Box)(() => ({
  display: 'block',
  backgroundColor: 'white',
  width: '85%',
  marginTop: '-2%',
  padding: '0% 5%',
}))
const Message = styled(Typography)(({ isRtl }: { isRtl: boolean }) => ({
  display: 'block',
  width: '100%',
  color: 'black',
  fontSize: '70%',
  overflowWrap: 'anywhere',
  pointerEvents: 'none',
  direction: isRtl ? 'rtl' : 'ltr',
}))

const Bottom = styled(Box)(() => ({
  position: 'absolute',
  width: '100%',
  height: '9.5%',
  bottom: 0,
  backgroundImage: `url(${bottomImage})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
}))

export default WhatsAppPreview
