import { useEffect } from 'react'
import { Autocomplete, TextField, Box, Chip } from '@mui/material'
import { TONE_OF_VOICE } from '../constants/tone-of-voice'
import { useAiSimulationContext } from '../state'

export const ToneOfVoiceSelect = () => {
  const { toneOfVoice, setToneOfVoice } = useAiSimulationContext()

  useEffect(() => {
    if (!toneOfVoice) {
      setToneOfVoice('Urgent')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderOptionsStyle = (option: String) => {
    const detail = TONE_OF_VOICE[option as keyof typeof TONE_OF_VOICE]
    if (!detail) return <></>
    return (
      <Box sx={{ alignItems: 'right' }}>
        <span style={{ marginRight: '8px' }}>{detail.emoji}</span>
      </Box>
    )
  }

  return (
    <Autocomplete
      freeSolo
      size="small"
      fullWidth
      options={Object.keys(TONE_OF_VOICE)}
      value={toneOfVoice}
      onChange={(event, newValue) => setToneOfVoice(newValue)}
      renderInput={(params) => (
        <TextField
          {...(params as any)}
          label="Tone of Voice"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: toneOfVoice ? (
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>{renderOptionsStyle(toneOfVoice)}</Box>
            ) : null,
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '10px',
            },
          }}
        />
      )}
      isOptionEqualToValue={(option, value) => option === value}
      disableClearable
      renderOption={(props, option) => {
        const detail = TONE_OF_VOICE[option as keyof typeof TONE_OF_VOICE]
        return (
          <Box
            component="li"
            {...props}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Box sx={{ alignItems: 'center' }}>{option}</Box>
            <Box sx={{ alignItems: 'center', margin: 'auto' }}>
              {detail.recommended && <Chip label="Recommended" size="small" />}
            </Box>
            <Box sx={{ alignItems: 'center' }}>{detail.emoji}</Box>
          </Box>
        )
      }}
    />
  )
}
