export const MuiPaper = {
  defaultProps: {
    elevation: 0,
  },
  styleOverrides: {
    root: {
      backgroundColor: '#fff',
      border: '1.5px solid rgba(0,0,0,0.1)',
    },
  },
}
