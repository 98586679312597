import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faBlockQuestion, faBoxOpen, faClapperboardPlay, faFishingRod } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Typography, styled } from '@mui/material'
import { FC, ReactElement } from 'react'
import { CampaignListItem, AssetType } from '@/types/campaigns'
import { theme } from '@/theme/theme'

const TableCellCampaignType: FC<CampaignListItem> = (campaign) => {
  const icons: ReactElement[] = []
  if (campaign?.asset_metadata && campaign.asset_metadata.include_quiz) {
    icons.push(
      <StyledTypeBox>
        <FontAwesomeIcon icon={faBlockQuestion as IconProp} fontSize={24} color={theme.palette.black} />
        <Typography variant="caption" color="primary">
          Quiz
        </Typography>
      </StyledTypeBox>
    )
  }
  if (campaign.asset_ids && campaign.asset_ids.length > 1) {
    icons.push(
      <StyledTypeBox>
        <FontAwesomeIcon icon={faBoxOpen as IconProp} fontSize={24} color={theme.palette.black} />
        <Typography variant="caption" color="primary">
          Package
        </Typography>
      </StyledTypeBox>
    )
  } else if (campaign.asset_type === AssetType.phishing_simulation) {
    icons.push(
      <StyledTypeBox>
        <FontAwesomeIcon icon={faFishingRod as IconProp} fontSize={24} color={theme.palette.black} />
        <Typography variant="caption" color="primary">
          Simulation
        </Typography>
      </StyledTypeBox>
    )
  } else if (campaign.asset_type === AssetType.training_video) {
    icons.push(
      <StyledTypeBox>
        <FontAwesomeIcon icon={faClapperboardPlay as IconProp} fontSize={24} color={theme.palette.black} />
        <Typography variant="caption" color="primary">
          Video
        </Typography>
      </StyledTypeBox>
    )
  }

  return (
    <div
      style={{
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {icons}
    </div>
  )
}

const StyledTypeBox = styled(Box)(() => ({
  padding: '0 10px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}))

export default TableCellCampaignType
