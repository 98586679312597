import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { API_URL } from '../constants'

export type ActivateAccountParams = {
  token: string
  password?: string
  accept_terms: boolean
  terms: string
}

export async function activateAccount(params: ActivateAccountParams): Promise<boolean> {
  const url = `${API_URL}/auth/activate-account/`

  try {
    await await axios.post(url, params, { withCredentials: true })
    return true
  } catch (error) {
    return false
  }
}

export function useActivateAccount() {
  return useMutation({ mutationFn: activateAccount })
}
