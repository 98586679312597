import Modal from '@mui/material/Modal'
import { FC, ReactNode } from 'react'

import { Button } from '@mui/material'
import {
  ButtonsWrapper,
  ContentWrapper,
  ImageWrapper,
  ModalConent,
  StyledTypography,
} from '../AccountCenter/PopupsStyledComponents'

type PopupProps = {
  open: boolean
  onClose: () => void
  onConfirm?: (params?: any) => void
  buttonLabels?: { confirm?: string; cancel?: string }
  icon: string
  children: ReactNode[]
  disableBackdropClick?: boolean
}

// icon should be 192X192

const Popup: FC<PopupProps> = ({ open, onClose, onConfirm, buttonLabels, icon, children, disableBackdropClick }) => {
  const handleClose = (_: React.SyntheticEvent, reason: 'backdropClick') => {
    if (reason === 'backdropClick' && disableBackdropClick) {
      return
    }
    onClose()
  }

  // Button group variant
  // 1. Pass label and configHandle
  // 2. Custom - Pass as as part of the children wrappetb with buttonwrapper

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalConent>
        <ImageWrapper>
          <img src={icon} alt="invite" />
        </ImageWrapper>

        <ContentWrapper>
          <StyledTypography>{children}</StyledTypography>

          <ButtonsWrapper>
            {!!buttonLabels?.cancel && (
              <Button variant="outlined" onClick={onClose}>
                {buttonLabels?.cancel}
              </Button>
            )}
            {Boolean(onConfirm) && (
              <Button onClick={onConfirm} variant="contained">
                {buttonLabels?.confirm}
              </Button>
            )}
          </ButtonsWrapper>
        </ContentWrapper>
      </ModalConent>
    </Modal>
  )
}

export default Popup
