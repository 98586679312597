import axios from 'axios';
import { API_URL } from '../constants';
import { useMutation } from 'react-query';
import { ServerError } from '../types';
import { TrainingCampaign } from './campaign-by-id';

type EditTrainingCampaignVariables = {
  _id: string;
  name?: string;
  education_module_id?: string;
  scheduled?: number;
  duration?: number;
};

export async function editCampaign(campaignVariables: EditTrainingCampaignVariables) {
  const url = `${API_URL}/training_campaigns/edit/${campaignVariables._id}`;
  const { data } = await axios.post(url, campaignVariables, { withCredentials: true });
  return data.data;
}

export function useEditTrainingCampaign() {
  return useMutation<TrainingCampaign, ServerError, EditTrainingCampaignVariables>(editCampaign, {
    throwOnError: true,
  });
}
