import { palette } from '../palette'
import { typography } from '../typography'

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    tag: true
    filter: true
  }
}

export const MuiChip = {
  defaultProps: {
    variant: 'filled',
    color: 'primary',
    size: 'medium',
  },
  styleOverrides: {
    root: {
      minWidth: 'fit-content',
      fontWeight: typography.fontWeightMedium,
      fontSize: '14px',
      padding: '1px',
      margin: '4px',
      height: '32px',
      borderRadius: 100,
      background: palette.blue[100],
      color: palette.black,
      '& .MuiChip-icon': {
        width: '12px',
        height: '12px',
      },
      '&:hover': {
        background: palette.blue[300],
      },
      '&:active': {
        background: palette.blue[500],
      },
      '&.Mui-disabled': {
        color: palette.grey[600],
        background: palette.grey[300],
        border: '1px solid',
        borderColor: palette.grey[400],
        opacity: 1,
        '.MuiChip-deleteIcon': {
          color: palette.grey[500],
        },
        '.MuiChip-avatar': {
          color: palette.grey[600],
          background: palette.grey[500],
        },
      },
    },
    sizeSmall: {
      fontSize: '12px',
      height: '24px',
    },

    outlined: {
      background: palette.white,
      borderColor: palette.blue[100],
      '&:hover': {
        background: palette.lightBlue[50],
      },
      '&:active': {
        background: palette.lightBlue[100],
      },
    },

    deleteIcon: {
      color: palette.blue[600],
      '&:hover': {
        color: palette.blue[600],
      },
    },
    deleteIconColorSecondary: {
      color: palette.blueDianne[600],
      '&:hover': {
        color: palette.blueDianne[600],
      },
    },

    avatar: {
      color: palette.black,
      background: palette.grey[400],
    },
    colorSecondary: {
      background: palette.blueDianne[200],
      color: palette.black,
      '&:hover': {
        background: palette.blueDianne[300],
      },
      '&:active': {
        background: palette.blueDianne[400],
      },
      '&.Mui-disabled': {
        color: palette.grey[600],
        background: palette.grey[300],
        border: '1px solid',
        borderColor: palette.grey[400],
      },
    },
  },
  variants: [
    {
      props: { variant: 'tag' },
      style: {
        backgroundColor: palette.cyan[100],
        color: palette.blueDianne[900],
        borderRadius: '5px',
        '&:hover': {
          background: palette.cyan[100],
        },
        '&:active': {
          background: palette.cyan[100],
        },
        '&.MuiChip-colorSecondary': {
          backgroundColor: palette.pink[600],
          color: palette.white,
          fontWeight: 'bold',
          '&.Mui-disabled': {
            color: palette.grey[600],
            background: palette.grey[300],
            border: '1px solid',
            borderColor: palette.grey[400],
          },
        },
      },
    },
    {
      props: { variant: 'filter' },
      style: {
        backgroundColor: palette.grey[400],
        color: palette.blueDianne[900],
        borderRadius: '5px',
        cursor: 'pointer',
        '&:hover': {
          background: palette.grey[300],
        },
        '&:active': {
          background: palette.grey[500],
        },
        '&.MuiChip-colorSecondary': {
          backgroundColor: palette.cyan[500],
          '&:hover': {
            background: palette.blueDianne[600],
          },
          '&:active': {
            background: palette.blueDianne[700],
          },
        },
      },
    },
  ],
}
