import { FC } from 'react'
import Box from '@mui/material/Box'
import MuiCard from '@mui/material/Card'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import { theme } from '@/theme/theme'

interface CreateIntegrationCardProps {
  disabled: boolean
  onClick: () => void
}

const CreateIntegrationCard: FC<CreateIntegrationCardProps> = ({ disabled, onClick }) => {
  const styles = {
    '&:hover': {
      '& .overlay': {
        opacity: disabled ? 0 : 1,
      },
    },
  }

  return (
    <Card onClick={(disabled && onClick) || undefined} sx={styles}>
      <CardContent>+</CardContent>
      <CardOverlay className="overlay">
        <Typography variant="h6">All integration are already implemented</Typography>
      </CardOverlay>
    </Card>
  )
}

const Card = styled(MuiCard)(() => ({
  position: 'relative',
  minWidth: '250px',
  padding: '24px 0',
  height: '250px',
  borderRadius: '10px',
  background: theme.palette.cyan[500],
  color: theme.palette.black,
  textAlign: 'center',
  fontSize: '140px',
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightSemiBold,
  lineHeight: 'normal',
  boxShadow: '0px 6px 15px 0px rgba(0, 0, 0, 0.25)',
  '&:hover': {
    background: theme.palette.blueDianne[600],
    cursor: 'pointer',
  },
}))

const CardOverlay = styled(Box)(() => ({
  position: 'absolute',
  padding: '10px',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.75)',
  color: 'white',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  opacity: 0,
  transition: 'opacity 0.3s ease-in-out',
}))

export default CreateIntegrationCard
