import { useLocation } from "react-router-dom";

export function useUrlQuery() {
  return new URLSearchParams(useLocation().search);
}

export function useUrlSearchQuery() {
  const searchQuery = useLocation().search.replace("?", "");

  if (searchQuery === "") return {};

  const parsedSearchQuery = searchQuery
    .split("&")
    .map((pair) => pair.split("="))
    .reduce((acc, [key, value]) => {
      acc[decodeURIComponent(key)] = decodeURIComponent(value);
      return acc;
    }, {});

  return parsedSearchQuery;
}
