import { DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { FC } from 'react'

const Gsuite: FC = () => (
  <>
    <DialogTitle>Whitelisting Cywareness - Gsuite</DialogTitle>
    <DialogContent>
      <DialogContentText>
        <p>
          <span style={{ fontWeight: 400 }}>
            The below instructions will show you how to whitelist Cywareness&rsquo; simulated phishing emails and
            training notifications by IP address in your GSuite environment.&nbsp; This setting is recommended if you do
            not have a cloud-based spam filter in front of GSuite. If you do have a cloud-based spam filter, you should
            whitelist us by our IP addresses in the filter, and whitelist by header in GSuite.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>The email header used is:</span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>X-Cywareness-Campaign</span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontWeight: 400 }}>The IP address used is:</span>
        </p>
        <p>
          <span style={{ fontWeight: 400 }}>149.72.52.80</span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{ fontWeight: 400 }}>If you are using GSuite, follow these steps:</span>
        </p>
        <p>
          <strong>Part 1:</strong>
        </p>
        <ol>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Log in to </span>
            <a href="https://admin.google.com/">
              <span style={{ fontWeight: 400 }}>https://admin.google.com</span>
            </a>
            <span style={{ fontWeight: 400 }}> and select </span>
            <strong>Apps</strong>
            <span style={{ fontWeight: 400 }}>.</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Navigate to </span>
            <strong>Apps</strong>
            <span style={{ fontWeight: 400 }}> &gt; </span>
            <strong>G Suite</strong>
            <span style={{ fontWeight: 400 }}> &gt; </span>
            <strong>Gmail</strong>
            <span style={{ fontWeight: 400 }}> &gt; </span>
            <strong>Advanced settings</strong>
            <span style={{ fontWeight: 400 }}>.</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>In the </span>
            <strong>Organizations</strong>
            <span style={{ fontWeight: 400 }}>
              {' '}
              section, highlight your Domain. Do not select an organizational unit (OU).
            </span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>In the </span>
            <strong>Email whitelist</strong>
            <span style={{ fontWeight: 400 }}> section, enter our </span>
            <strong>IP address (149.72.52.80)</strong>
            <span style={{ fontWeight: 400 }}>.&nbsp;</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Click </span>
            <strong>Save</strong>
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          <strong>Part 2:</strong>
        </p>
        <ol>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Log in to your Google Admin Console.</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Navigate to </span>
            <strong>Apps</strong>
            <span style={{ fontWeight: 400 }}> &gt; </span>
            <strong>G Suite</strong>
            <span style={{ fontWeight: 400 }}> &gt; </span>
            <strong>Gmail</strong>
            <span style={{ fontWeight: 400 }}> &gt; </span>
            <strong>Advanced settings</strong>
            <span style={{ fontWeight: 400 }}>.</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Scroll down to the </span>
            <strong>Inbound Gateway</strong>
            <span style={{ fontWeight: 400 }}> setting located under the </span>
            <strong>Spam</strong>
            <span style={{ fontWeight: 400 }}> section. Hover over the setting and click the </span>
            <strong>Edit</strong>
            <span style={{ fontWeight: 400 }}> button. This will open the </span>
            <strong>Inbound gateway</strong>
            <span style={{ fontWeight: 400 }}> screen.</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Configure the </span>
            <strong>Inbound gateway</strong>
            <span style={{ fontWeight: 400 }}> using the settings below:</span>
          </li>
          <ol>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>Under </span>
              <strong>Gateway </strong>
              <span style={{ fontWeight: 400 }}>IPs add the Cywareness IP address (</span>
              <strong>149.72.52.80</strong>
              <span style={{ fontWeight: 400 }}>)</span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>Leave the </span>
              <strong>Reject all mail not from gateway IPs</strong>
              <span style={{ fontWeight: 400 }}> option unchecked.</span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>Check </span>
              <strong>Require TLS for connections from the email gateways listed above</strong>
              <span style={{ fontWeight: 400 }}>.</span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>Under </span>
              <strong>Message Tagging</strong>
              <span style={{ fontWeight: 400 }}> enter text for the </span>
              <strong>Spam Header Tag</strong>
              <span style={{ fontWeight: 400 }}>
                {' '}
                that is unlikely to be found in an email. This field is required. Something like{' '}
              </span>
              <span style={{ fontWeight: 400 }}>kzndsfgklinjvsdnfioasmnfroipdsmfs </span>
              <span style={{ fontWeight: 400 }}>will work.</span>
            </li>
          </ol>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Select the </span>
            <strong>Disable Gmail spam evaluation on mail from this gateway; only use header value</strong>
            <span style={{ fontWeight: 400 }}> option.</span>
          </li>
          <li style={{ fontWeight: 400 }}>
            <span style={{ fontWeight: 400 }}>Click the </span>
            <strong>SAVE</strong>
            <span style={{ fontWeight: 400 }}> button.</span>
          </li>
        </ol>
        <p>
          <br />
          <br />
        </p>
      </DialogContentText>
    </DialogContent>
  </>
)

export default Gsuite
