import { theme } from '@/theme/theme'
import { Typography } from '@mui/material'

export const SummaryTitle = ({ title, value = '' }) => {
  return (
    <Typography fontWeight={theme.typography.fontWeightRegular} sx={{ display: 'flex', alignItems: 'baseline' }}>
      <span style={{ fontWeight: theme.typography.fontWeightMedium }}>{title}</span>
      {value ? `:  ${value}` : ''}
    </Typography>
  )
}
