import axios from "axios";
import { API_URL } from "../constants";
import { useMutation, useQueryCache } from "react-query";
import { ServerError } from "../types";

export type AddUsersToGroupParams = { _id: string; users: string[] };

export async function addUsersToGroup({
  _id,
  users = [],
}: AddUsersToGroupParams): Promise<string> {
  const url = `${API_URL}/groups/${_id}/users/`;
  const { data } = await axios.post(url, { users }, { withCredentials: true });
  return data;
}

export function useAddUsersToGroup() {
  const queryCache = useQueryCache();
  return useMutation<string, ServerError, AddUsersToGroupParams>(
    addUsersToGroup,
    {
      throwOnError: true,
      onSettled: (response, error, variables) => {
        queryCache.invalidateQueries("groups");
        queryCache.invalidateQueries(["groups", variables._id]);
        queryCache.invalidateQueries([["group", "users", variables._id]]);
        // this is because the UsersTable in the GroupProfile page is using the `users` paginating query.
        queryCache.invalidateQueries(["users"]);
      },
    }
  );
}
