import { useTheme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { Chip, Tooltip, Typography, Badge } from '@mui/material'

type Tag = {
  label: string
  color?: string
  textColor?: string
}

type TagsProps = {
  fontSize?: number
  tags: Tag[]
  limit: number
  disableTooltip?: boolean
  totalCount?: number
  useChips?: boolean
  justify?: 'flex-start' | 'center' | 'flex-end' | 'space-between'
  inPackageCard?: boolean
}

export default function Tags({
  fontSize = 16,
  tags = [],
  limit,
  disableTooltip = false,
  totalCount,
  useChips = true,
  justify = 'flex-start',
  inPackageCard = false,
}: TagsProps) {
  const classes = useStyles()
  const theme = useTheme()
  const additionalItemsCount = totalCount
    ? tags.length > limit
      ? totalCount - tags.length - limit
      : totalCount - tags.length
    : tags.length - limit
  return (
    <div className={classes.root} style={{ justifyContent: justify }}>
      {useChips ? (
        tags.slice(0, limit).map((tag, i) => <Chip key={i} label={tag.label} />)
      ) : (
        <Typography variant={'body2'} className={classes.tag} style={{ fontSize: fontSize }}>
          {tags
            .slice(0, limit)
            .map((tag) => tag.label)
            .join(', ')}
        </Typography>
      )}
      {disableTooltip && additionalItemsCount > 0 ? (
        <Badge style={{ fontSize: fontSize }}>{additionalItemsCount}+</Badge>
      ) : (
        tags.length > limit && (
          <Tooltip
            placement={'right'}
            title={tags
              .slice(limit)
              .map((group) => group.label)
              .join(', ')}
          >
            {useChips ? (
              <div color="primary" className={classes.moreTags}>
                +{tags.length - limit}
              </div>
            ) : (
              <Chip label={`+${tags.length - limit}`} variant="outlined" size={'small'} />
            )}
          </Tooltip>
        )
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    moreTags: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.text.primary,
    },
    coloredMoreTags: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.common.white,
    },
    outlinedChip: {
      border: `1px solid ${theme.palette.text.primary}`,
      background: 'transparent',
      color: theme.palette.text.primary,
      fontWeight: 500,
    },
  })
)
