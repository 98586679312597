import { TextField, Box } from '@mui/material'
import AiHint from './AiHint'
import { useAiSimulationContext } from '../state'
import { useTranslation } from 'react-i18next'
import { theme } from '@/theme/theme'

export type EmailBodyProps = {
  simulationVectorType: string
}
export const EmailBody = ({ simulationVectorType }: EmailBodyProps) => {
  const { t } = useTranslation()
  const { emailBody, setEmailBody } = useAiSimulationContext()
  const textLabel = simulationVectorType.charAt(0).toUpperCase() + simulationVectorType.slice(1) + ' body'
  const textPlaceholder = t(`simulationLibrary.ai.inputFields.${simulationVectorType}.body`)
  const aiHintText = t(`simulationLibrary.ai.inputFields.${simulationVectorType}.bodyTooltip`)

  return (
    <TextField
      fullWidth
      placeholder={textPlaceholder}
      value={emailBody}
      onChange={(e) => setEmailBody(e.target.value)}
      multiline
      rows={4}
      label={textLabel}
      variant="outlined"
      sx={{
        '& .MuiOutlinedInput-root': {
          borderRadius: '10px',
        },
      }}
      InputLabelProps={{
        shrink: true,
        style: {
          display: 'block',
          whiteSpace: 'normal',
        },
      }}
      InputProps={{
        style: {
          alignItems: 'flex-start',
          padding: theme.spacing(1, 0.5, 0, 2),
        },
        endAdornment: (
          <Box display="flex" flexDirection="column" justifyContent="flex-start">
            <AiHint text={aiHintText} />
          </Box>
        ),
      }}
    />
  )
}
