import useLottie from '@/common/hooks/useLottie'
import darkAnimationData from '@/assets/lottie/darkNewLabel.json'
import lightAnimationData from '@/assets/lottie/lightNewLabel.json'
import { CSSProperties } from 'react'

type NewLabelAnimationProps = {
  style?: CSSProperties
  isDark?: boolean
  size?: number
}
const NewLabelAnimation = ({ style, isDark, size }: NewLabelAnimationProps) => {
  const { LottieAnimation } = useLottie({
    animationData: isDark ? darkAnimationData : lightAnimationData,
    height: size || 50,
    width: size ? 1.5 * size : 100,
  })

  return <div style={style}>{LottieAnimation}</div>
}
export default NewLabelAnimation
