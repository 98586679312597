import { App } from '../../api/apps/apps'
export const getAppsObject = (apps: App[]) => {
  const appsObject: {
    [key: string]: App
  } = {}
  apps.forEach((app) => {
    appsObject[app.title.toLowerCase()] = app
  })
  return appsObject
}
