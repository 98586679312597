const IntegrationType = () => {
  return (
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="integration icons">
        <path
          id="Vector"
          d="M14.6667 1.25C15.3427 1.25 15.8889 1.80859 15.8889 2.5V7.5H17.1111V2.5C17.1111 1.12109 16.0149 0 14.6667 0H12.2222H9.77778H7.33333C5.98507 0 4.88889 1.12109 4.88889 2.5V7.5H6.11111V2.5C6.11111 1.80859 6.65729 1.25 7.33333 1.25H8.55556V3.4375C8.55556 4.30078 9.23924 5 10.0833 5H11.9167C12.7608 5 13.4444 4.30078 13.4444 3.4375V1.25H14.6667ZM9.77778 1.25H12.2222V3.4375C12.2222 3.60938 12.0847 3.75 11.9167 3.75H10.0833C9.91528 3.75 9.77778 3.60938 9.77778 3.4375V1.25ZM12.5125 20H19.5556C20.9038 20 22 18.8789 22 17.5V11.25C22 9.87109 20.9038 8.75 19.5556 8.75H17.1111H14.6667H12.5125C12.8295 9.10938 13.0778 9.53516 13.2382 10H13.4483V11.2461C13.4483 11.2461 13.4483 11.2461 13.4483 11.25V12.1875C13.4483 13.0508 14.1319 13.75 14.976 13.75H16.8094C17.6535 13.75 18.3372 13.0508 18.3372 12.1875V10H19.5594C20.2354 10 20.7816 10.5586 20.7816 11.25V17.5C20.7816 18.1914 20.2354 18.75 19.5594 18.75H13.2382C13.0778 19.2148 12.8295 19.6406 12.5125 20ZM14.6667 10H17.1111V12.1875C17.1111 12.3594 16.9736 12.5 16.8056 12.5H14.9722C14.8042 12.5 14.6667 12.3594 14.6667 12.1875V10ZM9.77778 10C10.4538 10 11 10.5586 11 11.25V17.5C11 18.1914 10.4538 18.75 9.77778 18.75H2.44444C1.7684 18.75 1.22222 18.1914 1.22222 17.5V11.25C1.22222 10.5586 1.7684 10 2.44444 10H3.66667V12.1875C3.66667 13.0508 4.35035 13.75 5.19444 13.75H7.02778C7.87187 13.75 8.55556 13.0508 8.55556 12.1875V10H9.77778ZM4.88889 12.1875V10H7.33333V12.1875C7.33333 12.3594 7.19583 12.5 7.02778 12.5H5.19444C5.02639 12.5 4.88889 12.3594 4.88889 12.1875ZM9.77778 8.75H8.55556H7.33333H4.88889H3.66667H2.44444C1.09618 8.75 0 9.87109 0 11.25V17.5C0 18.8789 1.09618 20 2.44444 20H9.77778C11.126 20 12.2222 18.8789 12.2222 17.5V11.25C12.2222 9.87109 11.126 8.75 9.77778 8.75Z"
          fill="#05DBF2"
        />
      </g>
    </svg>
  )
}

export default IntegrationType
