import React, { useMemo, useState } from 'react'
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import useForm, { Field } from '../Form/useForm'
import { string } from 'yup'
import Form from '../Form/Form'
import { NewGroupParams, useNewGroup } from '@/api/groups/new-group'
import Autocomplete from '@mui/material/Autocomplete'
import { useUsers } from '@/api/users/users'
import { useOffices } from '@/api/offices/offices'
import useToast from '@/common/hooks/useToast'

type CreateGroupModalProps = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  onClose?: () => void
}

type SelectedUser = {
  _id: string
  label: string
}

export default function CreateGroupModal({ open, setOpen, onClose = () => {} }: CreateGroupModalProps) {
  const { t } = useTranslation()
  const [newUsers, setNewUsers] = useState<SelectedUser[]>([])
  const [newManagers, setNewManagers] = useState<SelectedUser[]>([])
  const [isUsersError, setIsUsersError] = useState(false)
  const { data: offices } = useOffices()
  const [query, setQuery] = useState('')
  const { successToast, errorToast } = useToast()
  const [createNewGroup] = useNewGroup()
  const { data: users } = useUsers({
    fullname: query,
    email: '',
    offset: 50,
    startIndex: 0,
    offices: [],
    groups: [],
  })

  const usersList = useMemo(() => {
    return users
      ? users.items.map((user) => ({
          label: `${user.first_name} ${user.last_name}`,
          _id: user._id,
        }))
      : []
  }, [users])

  function handleChange(event, newValue) {
    setNewUsers(newValue)
    setIsUsersError(false)
  }

  function handleChangeManagers(event, newValue) {
    setNewManagers(newValue)
  }

  function handleQueryChange(event, newInputValue) {
    setQuery(newInputValue)
  }

  const fields: Field[] = useMemo(
    () => [
      {
        name: 'name',
        label: 'createGroupModal.name',
        validationRule: string().required('createGroupModal.errors.missingName'),
        type: 'text',
        required: true,
        autoFocus: false,
        gridItemProps: {
          xs: 12,
        },
      },
      {
        name: 'offices',
        initialValue: [],
        label: 'createUserModal.office',
        validationRule: string().required('createUserModal.errors.missingOffice'),
        type: 'text',
        required: true,
        autoFocus: false,
        gridItemProps: {
          xs: 12,
        },
        textFieldProps: {
          variant: 'outlined',
          select: true,
          SelectProps: {
            multiple: (offices?.array || []).length > 1,
          },
        },
        options: offices
          ? offices.array.map((office) => ({
              label: office.name,
              value: office._id,
            }))
          : [],
      },
    ],
    [offices]
  )

  function handleClose() {
    onClose()
    setOpen(false)
    clearForm()
    setIsUsersError(false)
    setNewUsers([])
  }

  const { formProps, handleSubmit, clearForm } = useForm({
    fields,
    onSubmit: handleSumbit,
    clearOnSubmit: false,
  })

  async function handleCreateGroup({ name, users = [], offices = [], managers = [] }: NewGroupParams) {
    try {
      await createNewGroup({ name, users, offices, managers })
      successToast(t('users.groupsTable.newGroupSuccess'))
      handleClose()
    } catch (e) {
      const errorMessage = e.response?.data?.message ?? t('users.groupsTable.errors.faliedNewGroup')
      errorToast(errorMessage)
    }
  }

  function handleSumbit(values: { name: string; offices: string | string[] }) {
    const { name, offices } = values
    if (!newUsers.length) {
      setIsUsersError(true)
      return
    } else {
      handleCreateGroup({
        name,
        users: newUsers.map((user) => user._id),
        offices: typeof offices === 'string' ? [offices] : offices,
        managers: newManagers.map((user) => user._id),
      })
    }
  }

  return (
    <>
      <Dialog maxWidth={'sm'} fullWidth open={open} onClose={handleClose} aria-labelledby="group-dialog-title">
        <DialogTitle id="group-dialog-title">{t('createGroupModal.newGroup')}</DialogTitle>
        <DialogContent style={{ overflowY: 'hidden' }}>
          <DialogContentText>{t('createGroupModal.instructions')}</DialogContentText>
          <Form {...formProps} />
          <div style={{ marginTop: 16 }}>
            <Autocomplete
              value={newUsers}
              onChange={handleChange}
              onInputChange={handleQueryChange}
              multiple
              id="tags-filled"
              options={usersList.map((option) => option)}
              getOptionLabel={(option) => option.label}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  return <Chip label={option.label} {...getTagProps({ index })} />
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={t('createGroupModal.addUsers')}
                  placeholder={t('createGroupModal.searchUsers')}
                  required
                  error={isUsersError}
                  helperText={isUsersError ? t('createGroupModal.errors.missingUsers') : ''}
                />
              )}
            />
          </div>
          <div style={{ marginTop: 16 }}>
            <Autocomplete
              value={newManagers}
              onChange={handleChangeManagers}
              onInputChange={handleQueryChange}
              multiple
              id="tags-filled"
              options={newUsers}
              getOptionLabel={(option) => option.label}
              noOptionsText={t('createGroupModal.noUsers')}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  return <Chip label={option.label} {...getTagProps({ index })} />
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={t('createGroupModal.groupManagers')}
                  placeholder={t('createGroupModal.searchUsers')}
                />
              )}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            {t('createGroupModal.cancel')}
          </Button>
          <Button onClick={handleSubmit} variant="contained">
            {t('createGroupModal.save')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
