import { useState } from 'react'
import { useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Tour from 'reactour'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faLightbulbOn } from '@fortawesome/pro-light-svg-icons'

const CLASSES = {
  SPEED: '.video-react-playback-rate',
  CAPTIONS: '.video-react-closed-caption',
  SOURCE: '.video-react-source-selector',
  PLAY: '.video-react',
}

export default function TrainingCampaignTour() {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const [isTourOpen, setIsTourOpen] = useState(false)

  const steps = [
    {
      selector: CLASSES.CAPTIONS,
      content: ({ inDOM }) => (
        <div className={classes.tipContainer}>
          <Typography variant={'body1'}>{t('trainingCampaignTour.subtitles')}</Typography>
        </div>
      ),
    },
    {
      selector: CLASSES.SOURCE,
      content: ({ inDOM }) => (
        <div className={classes.tipContainer}>
          <Typography variant={'body1'}>{t('trainingCampaignTour.dubbing')}</Typography>
        </div>
      ),
    },
    {
      selector: CLASSES.SPEED,
      content: ({ inDOM }) => (
        <div className={classes.tipContainer}>
          <Typography variant={'body1'}>{t('trainingCampaignTour.playback')}</Typography>
        </div>
      ),
    },
    {
      selector: CLASSES.PLAY,
      content: ({ inDOM }) =>
        inDOM ? (
          <div className={classes.tipContainer}>
            <Typography variant={'body1'}>{t('trainingCampaignTour.getStarted')}</Typography>
          </div>
        ) : null,
    },
  ]

  function handleDisableBodyScroll(target) {
    disableBodyScroll(target)
  }
  function handleEnableBodyScroll(target) {
    enableBodyScroll(target)
  }

  function showControlBar() {
    const el = document.getElementsByClassName('video-react-control-bar')?.[0]
    // make the element visible
    if (el) {
      // @ts-ignore
      el.style.display = 'inline-flex'
    }
  }

  function handleOpenTour() {
    showControlBar()
    setIsTourOpen(true)
  }

  return (
    <div>
      <Button
        className={classes.button}
        variant="contained"
        color="secondary"
        onClick={handleOpenTour}
        startIcon={<FontAwesomeIcon icon={faLightbulbOn as IconProp} />}
      >
        {t('trainingCampaignTour.takeTour')}
      </Button>
      <Tour
        accentColor={theme.palette.cyan[500]}
        rounded={8}
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => {
          setIsTourOpen(false)
        }}
        onAfterOpen={handleDisableBodyScroll}
        onBeforeClose={handleEnableBodyScroll}
        lastStepNextButton={
          <Button
            color={'secondary'}
            variant={'contained'}
            onClick={() => {
              setIsTourOpen(false)
            }}
          >
            {t('trainingCampaignTour.start')}
          </Button>
        }
      />
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      minWidth: 100,
    },
    tipContainer: {
      padding: theme.spacing(2),
    },
  })
)
