import { Box, Button, styled } from '@mui/material'
import Modal from '@mui/material/Modal'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { useSwitchClientV5 } from '@/api/profile/switch-client'
import { getErrorMessage } from '@/api/utils/get-error'
import useToast from '@/common/hooks/useToast'
import { ButtonsWrapper, ImageWrapper, ContentWrapper, ModalConent, StyledTypography } from './PopupsStyledComponents'
import { ModalProps } from './TreeChart'

import noPermissionImg from '@/assets/images/account-center/no-permissions.svg'
import switchAccountImg from '@/assets/images/account-center/switch-account.svg'

const ClientSwitchPopup: FC<ModalProps> = ({ open, setOpen, organization }) => {
  const { t } = useTranslation()
  const { errorToast } = useToast()
  const { mutateAsync: switchClient } = useSwitchClientV5()

  const handleClose = () => {
    setOpen(false)
  }

  const handleLogin = async () => {
    try {
      await switchClient(organization.id)
      window.location.href = '/'
      handleClose()
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      errorToast('Login error ' + errorMessage || '')
    }
  }

  return (
    <Modal open={open} onClose={handleClose}>
      {organization.is_admin_authorized ? (
        <ModalConent>
          <ImageWrapper>
            <img src={switchAccountImg} alt="switch-account" />
          </ImageWrapper>

          <ContentWrapper>
            <StyledTypography>
              <h4>{t('accountCenter.clientSwtichPopup.title')}</h4>
              <p>{t('accountCenter.clientSwtichPopup.message', { organizationName: organization.name })}</p>
            </StyledTypography>

            <ButtonsWrapper>
              <Button variant="outlined" onClick={handleClose}>
                {t('accountCenter.clientSwtichPopup.cancel')}
              </Button>
              <Button onClick={handleLogin} variant="contained">
                {t('accountCenter.clientSwtichPopup.submit')}
              </Button>
            </ButtonsWrapper>
          </ContentWrapper>
        </ModalConent>
      ) : (
        <ModalConent>
          <ImageWrapper>
            <img src={noPermissionImg} alt="no-permissions" />
          </ImageWrapper>

          <ContentWrapper>
            <StyledTypography>
              <h4>{t('accountCenter.clientSwtichPopup.noPermissionsTitle')}</h4>
              <span>{t('accountCenter.clientSwtichPopup.noPermissionsMessage')}</span>
            </StyledTypography>

            <ButtonsWrapper>
              <Button variant="contained" onClick={handleClose}>
                {t('accountCenter.clientSwtichPopup.noPermissionsCancel')}
              </Button>
            </ButtonsWrapper>
          </ContentWrapper>
        </ModalConent>
      )}
    </Modal>
  )
}

export default ClientSwitchPopup
