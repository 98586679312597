import axios from 'axios';
import { API_URL } from '../constants';
import { useQuery } from 'react-query';
import { ServerError } from '../types';
import { EducationModule } from '../education-modules/modules';
import { User } from '../users/users';

export interface TrainingCampaign {
  _id: string;
  education_module_id: string;
  education_module: EducationModule;
  users: User[];
  client_id: string;
  status: string;
  scheduled: number;
  duration: number;
  name: string;
  total_users: number;
  campaign_end: number;
  has_quiz: boolean;
  average_quiz_score: number;
}

export async function getCampaignById(key: string, _id: string) {
  if (_id) {
    const url = `${API_URL}/training_campaigns/${_id}`;
    const { data } = await axios.get(url, { withCredentials: true });
    return data.data;
  } else {
    return undefined;
  }
}

export function useTrainingCampaignById(_id: string) {
  return useQuery<TrainingCampaign, ServerError>(['training-campaigns', _id], getCampaignById, {
    refetchOnWindowFocus: false,
  });
}
