/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { Button, Chip, Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ReportObjectFull } from '../../api/reports/reports'
import { startCase } from 'lodash'
import HtmlToReact from 'html-to-react'
import CodeEditor from '../../common/components/CodeEditor/CodeEditor'
import { formatHtml } from '@/common/utils/htmlHandler'
import { LineWidget } from 'codemirror'
import Tour, { ReactourStep } from 'reactour'
import Alert from '../../common/components/Alert/Alert'

const htmlToReactParser = new (HtmlToReact.Parser as any)()

type Finding = {
  line: number[]
  title: string
  description: string
}

export default function ReportFindings({ data }: { data: ReportObjectFull }) {
  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()
  const editor = useRef<CodeEditor>(null)
  const [findings, setFindings] = useState<{ [key: string]: Finding }>({})
  const [steps, setSteps] = useState<ReactourStep[]>([])
  const [showHtml, setShowHtml] = useState(false)
  const [isTourOpen, setIsTourOpen] = useState(false)
  const [alertIsOpen, setAlertIsOpen] = useState(false)

  function handleScrollToFinding(finding: Finding) {
    if (editor?.current?.editor) {
      editor?.current?.editor?.scrollIntoView({ line: finding.line[0], ch: 0 }, 100)
    }
  }

  useEffect(() => {
    const steps = Object.entries(findings).map(([key, finding]) => ({
      selector: `#${key}`,
      content: ({ inDOM }) => (
        <div>
          <Typography variant={'h6'}>{inDOM && finding.title}</Typography>
          <Typography variant={'body1'}>{inDOM && finding.description}</Typography>
        </div>
      ),
    }))
    setSteps(steps)
  }, [findings])

  useEffect(() => {
    const lineWidgets: LineWidget[] = []
    if (editor?.current?.editor) {
      const doc = editor?.current?.editor.getDoc()
      const findings: { [key: string]: Finding } = {}
      Object.keys(data.findings).forEach((key) => {
        const finding: Finding = {
          line: [],
          description: data.findings[key].description,
          title: data.findings[key].title,
        }
        const cursor = doc.getSearchCursor(key)
        while (cursor.findNext()) {
          const { line } = cursor.from()
          finding.line.push(line)
          const el = document.createElement('div')
          el.setAttribute('class', classes.widget)
          el.innerHTML = `The above line contains a ${data.findings[key].title}: ${data.findings[key].description}`
          const lineWidget = editor?.current?.editor?.addLineWidget(line, el)
          lineWidgets.push(lineWidget as LineWidget)
          //@ts-ignore
          const annotations = editor?.current?.editor?.annotateScrollbar(classes.annotation)
          annotations.update([{ from: cursor.from(), to: cursor.to() }])
        }
        findings[key] = finding
      })
      setFindings(findings)
    }
    return () => {
      //@ts-ignore
      const annotations = editor?.current?.editor?.annotateScrollbar(classes.annotation)
      lineWidgets.length > 0 && lineWidgets.forEach((widget) => widget.clear())
      annotations && annotations.clear()
    }
  }, [data.findings, classes.widget, classes.annotation])

  const html = formatHtml(data.raw_html)

  function handleShowHtml() {
    setAlertIsOpen(true)
  }

  function handleCloseAlert() {
    setAlertIsOpen(false)
  }

  function handleHideHtml() {
    setShowHtml(false)
  }

  function handleStartTour() {
    setIsTourOpen(true)
  }

  return (
    <>
      <Alert
        handleClose={handleCloseAlert}
        open={alertIsOpen}
        dialogTitle={t('reportPage.htmlWarningTitle')}
        dialogText={[t('reportPage.htmlWarningText'), t('reportPage.confirmShowHtml')]}
        buttonLabel={t('reportPage.showMalicious')}
        cancelButton
        onConfirm={() => setShowHtml(true)}
      />
      <Tour
        accentColor={theme.palette.cyan[500]}
        steps={steps}
        isOpen={isTourOpen}
        rounded={8}
        onRequestClose={() => setIsTourOpen(false)}
      />
      <div className={classes.titleContainer}>
        <div className={classes.titleContainer}>
          <Typography className={classes.title} variant={'h6'}>
            {t('reportPage.reportFindings')}
          </Typography>
          <Button onClick={showHtml ? handleHideHtml : handleShowHtml}>
            {!showHtml ? t('reportPage.viewAsHtml') : t('reportPage.viewAsCode')}
          </Button>
        </div>
        <div className={classes.chipContainer}>
          {!showHtml ? (
            Object.values(findings).map((finding) => (
              <Chip
                onClick={() => handleScrollToFinding(finding)}
                color="secondary"
                classes={{ root: classes.chip }}
                label={startCase(finding.title)}
              />
            ))
          ) : (
            <Button
              disabled={Object.values(findings).length < 1}
              variant={'contained'}
              color={'primary'}
              onClick={handleStartTour}
            >
              {t('reportPage.showFindings')}
            </Button>
          )}
        </div>
      </div>
      <Paper className={showHtml ? classes.paper : `${classes.paper} ${classes.darkBackground}`}>
        <div>{!showHtml ? <CodeEditor ref={editor} text={html} /> : htmlToReactParser.parse(data.raw_html)}</div>
      </Paper>
    </>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    titleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.spacing(0.5),
    },
    title: {
      marginRight: theme.spacing(2),
    },
    chipContainer: {},
    chip: {
      marginLeft: theme.spacing(2),
      transition: 'all .2s ease-in-out',
    },
    chipSelected: {},
    annotation: {
      backgroundColor: theme.palette.error.main,
    },
    widget: {
      backgroundColor: theme.palette.error.main,
      fontFamily: 'Roboto',
      color: theme.palette.common.white,
      padding: theme.spacing(1, 2),
    },
    paper: {
      minHeight: 320,
      width: '100%',
      overflowY: 'auto',
      position: 'relative',
    },
    darkBackground: {
      background: '#292e3e',
    },
    tooltip: {
      padding: theme.spacing(1, 2),
      fontWeight: 'bold',
      backgroundColor: theme.palette.cyan[500],
    },
    arrow: {
      color: theme.palette.cyan[500],
    },
    code: {
      fontFamily: 'monospace',
    },
  })
)
