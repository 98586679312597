import React, { useCallback, useEffect, useRef } from "react";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Calendar from "../../common/components/Calendar/Calendar";
import { ReportObjectFull } from "../../api/reports/reports";
import { fromUnixTime, isSameDay, sub } from "date-fns";
import { useTranslation } from "react-i18next";
import useInterval from "../../common/hooks/useInterval";

export default function ReportCalendar({ data }: { data: ReportObjectFull }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const modifiers = {
    received: (date) => isSameDay(date, fromUnixTime(data?.date_recieved)), // Highlight day attack was received
    reported: (date) => isSameDay(date, fromUnixTime(data?.date_reported)), // Highlight day attack was reported
  };

  const modifiersClassNames = {
    reported: "nice-dates-day -selected -reported -tooltip",
    received: "nice-dates-day -selected -received",
  };

  const tooltipCoords = useRef({
    top: "auto",
    bottom: "50px",
    right: "auto",
    left: "auto",
  });

  function calculateTooltipPosition() {
    const container = document.querySelector(".nice-dates-grid");
    const tooltipText = document.querySelector(".tooltiptext");
    if (container && tooltipText) {
      const containerRect = container.getBoundingClientRect();
      const tooltipRect = tooltipText.getBoundingClientRect();
      const isOutRight = containerRect.right - tooltipRect.right < 8;
      const isOutLeft = tooltipRect.left - containerRect.left < 8;
      const isOutTop = tooltipRect.top - containerRect.top < 8;
      const isOutBottom = tooltipRect.bottom - containerRect.bottom > 8;

      if (isOutRight) {
        tooltipCoords.current.right = `${
          -(containerRect.right - tooltipRect.right) / 2
        }px`;
        tooltipCoords.current.left = "auto";
      }
      if (isOutLeft) {
        tooltipCoords.current.left = `${
          -(tooltipRect.left - containerRect.left) / 2
        }px`;
        tooltipCoords.current.right = "auto";
      }
      if (isOutTop) {
        tooltipCoords.current.top = "50px";
        tooltipCoords.current.bottom = "auto";
      }
      if (isOutBottom) {
        tooltipCoords.current.top = "auto";
        tooltipCoords.current.bottom = "50px";
      }
      tooltipText.setAttribute(
        "style",
        `top: ${tooltipCoords.current.top}; bottom: ${tooltipCoords.current.bottom}; right: ${tooltipCoords.current.right}; left: ${tooltipCoords.current.left};`
      );
    }
  }

  const insertTooltip = useCallback(() => {
    if (data) {
      const tooltipNode = document.querySelector(".-tooltip");
      const existingTooltip = document.querySelector(".tooltiptext");
      if (!existingTooltip && tooltipNode) {
        const tooltipText = document.createElement("span");
        tooltipText.innerHTML = t("reportPage.details.reported", {
          value: data?.reported_by,
        });
        tooltipText.setAttribute("class", "tooltiptext");
        tooltipNode.appendChild(tooltipText);
        calculateTooltipPosition();
      } else {
        calculateTooltipPosition();
      }
    }
  }, [data, t]);

  useInterval(insertTooltip, 500);

  useEffect(() => {
    insertTooltip();
    return () => {
      tooltipCoords.current = {
        top: "auto",
        bottom: "50px",
        right: "auto",
        left: "auto",
      };
    };
  }, [insertTooltip]);

  return (
    <div className={classes.root}>
      <Calendar
        startDate={fromUnixTime(data?.date_recieved)}
        endDate={sub(fromUnixTime(data?.date_reported), { days: 1 })}
        modifiers={modifiers}
        modifiersClassNames={modifiersClassNames}
      />
    </div>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
  })
);
