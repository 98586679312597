import { Avatar, Box, Chip, styled, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { US_DATE_WITH_MONTH_NAME } from '@/api/constants'
import { theme } from '@/theme/theme'
import { Admin } from '@/types/admins'

const AdminProfileHeader: FC<{ adminData: Admin }> = ({ adminData }) => {
  const { t } = useTranslation()
  return (
    <Box display="flex" gap={theme.spacing(2)} alignItems="center">
      <StyledAvatar>
        <Typography fontSize={48} color={theme.palette.blueDianne[900]}>
          {adminData.full_name ? adminData.full_name[0] : ''}
        </Typography>
      </StyledAvatar>
      <Box display="flex" flexDirection="column" gap={theme.spacing(1)} flexWrap={'wrap'}>
        <Box display="flex" alignItems="center" gap={theme.spacing(1)} flexWrap={'wrap'}>
          <Typography whiteSpace="nowrap">{t(`settings.admins.profile.lastSignIn`)}</Typography>
          <Chip
            label={
              dayjs.utc(adminData?.last_sign_in).isValid()
                ? dayjs.utc(adminData?.last_sign_in).format(US_DATE_WITH_MONTH_NAME)
                : '-'
            }></Chip>
          <Typography whiteSpace="nowrap"> {t(`settings.admins.profile.joinedOn`)}</Typography>
          <Chip label={dayjs.utc(adminData?.joined_date).format(US_DATE_WITH_MONTH_NAME)}></Chip>
          {!!adminData?.invited_by?.name && (
            <Typography whiteSpace="nowrap">
              {t(`settings.admins.profile.by`)} {'' + adminData?.invited_by?.name}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export const StyledAvatar = styled(Avatar)(() => ({
  background: theme.palette.cyan[500],
  width: '72px',
  height: '72px',
}))

export default AdminProfileHeader
