import useLottie from '@/common/hooks/useLottie'
import darkAnimationData from '@/assets/lottie/darkNewBadge.json'
import lightAnimationData from '@/assets/lottie/lightNewBadge.json'
import darkComingSoonAnimationData from '@/assets/lottie/darkComingSoonBadge.json'
import { CSSProperties } from 'react'

type NewBadgeAnimationProps = {
  style?: CSSProperties
  isDark?: boolean
  isComingSoon?: boolean
  size?: number
}
const NewBadgeAnimation = ({ style, isDark, size, isComingSoon }: NewBadgeAnimationProps) => {
  const { LottieAnimation } = useLottie({
    animationData: isComingSoon ? darkComingSoonAnimationData : isDark ? darkAnimationData : lightAnimationData,
    height: size || 70,
    width: size || 70,
  })

  return <div style={style}>{LottieAnimation}</div>
}
export default NewBadgeAnimation
