import axios from 'axios'
import { API_URL } from '../constants'
import { useQuery } from '@tanstack/react-query'

export type MemberStatsRequest = {
  id: string
}

type EngagementObject = {
  day: string
  value: number
  counter: number
}

type ExposureObject = {
  label: string
  value: number
}

type CampaignsObject = {
  day: string
  value: number
  counter: number
  events: {
    [key: string]: number
  }
}

export type MemberStatsObject = {
  engagement: EngagementObject[]
  exposure: ExposureObject[]
  campaigns: CampaignsObject[]
}

const getMemberStatsById = async ({ id }: MemberStatsRequest) => {
  const url = `${API_URL}/members/stats/${id}/`
  const { data } = await axios.get(url, { withCredentials: true })
  return data
}

export const useMemberStatsById = ({ id }: MemberStatsRequest) => {
  return useQuery({
    queryKey: ['members', 'stats', id],
    queryFn: () => {
      return getMemberStatsById({ id })
    },
    placeholderData: {
      engagement: [],
      campaigns: [],
      exposure: [],
    },
  })
}
