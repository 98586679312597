import axios from 'axios'
import { API_URL } from '@/api/constants'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { PhishingSimulationPackageRequest } from '@/types/phishingSimulations'

const phishingSimulationPackageCreate = async (phishingSimulationPackagePayload: PhishingSimulationPackageRequest) => {
  const { data } = await axios.post(`${API_URL}/phishing-simulations/packages`, phishingSimulationPackagePayload, {
    withCredentials: true,
  })

  return data
}

export const usePhishingSimulationPackageCreate = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: phishingSimulationPackageCreate,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['phishing-simulations-packages'],
      })
    },
  })
}
