import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/styles'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import Tags from '@/common/components/Tags/Tags'
import { PhishingSimulation, PhishingSimulationPackageExtended } from '@/types/phishingSimulations'

type SimulationPageTopicsProps = {
  simulation: PhishingSimulation | PhishingSimulationPackageExtended
}

const SimulationPageTopics: FC<SimulationPageTopicsProps> = ({ simulation }) => {
  const { t } = useTranslation()
  const theme = useTheme()

  if (!simulation) {
    return <></>
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'baseline',
        flexDirection: 'row',
        marginRight: theme.spacing(2),
      }}
    >
      <Typography marginRight={theme.spacing(1)} fontWeight="medium">
        {t('simulationLibrary.topics')}:
      </Typography>

      <Tags
        useChips={false}
        tags={simulation?.tags?.map((tag) => ({
          label: tag,
          color: 'transparent',
          textColor: theme.palette.text.secondary,
        }))}
        limit={2}
      />
    </Box>
  )
}

export default SimulationPageTopics
