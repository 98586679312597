import React, { FC, useEffect, useState } from 'react'
import SimpleTable from '@/common/components/Tables/SimpleTable'
import { Trans, useTranslation } from 'react-i18next'
import { ColumnInterface } from 'react-table'
import MoreMenu from '@/common/components/MoreMenu/MoreMenu'
import { useOffices } from '@/api/offices/offices'
import { Group } from '@/api/groups/groups'
import { filter, intersection } from 'lodash'
import { useTheme } from '@mui/material'
import { NavLink, useNavigate } from 'react-router-dom'
import Tags from '@/common/components/Tags/Tags'
import { getDefaultTableSize, TABLE_SIZE_REFERENCE_KEYS, setDefaultTableSize } from '@/common/utils/table-size'
import HumanizedScore from '@/common/components/HumanizedScore/HumanizedScore'
import { useMembers } from '@/api/members/get'
import InTableMessageWithAnimation from '@/common/components/Tables/InTableMessageWithAnimation'

type GroupTableProps = {
  groups: any[]
  handleDeleteGroup: (_id: string) => void
  queryFilters: {
    search: string
    groups: string[]
    offices: string[]
    languages: string[]
    timezones: string[]
    status: boolean[]
    skip: number
    limit: number
    sort_by: string
    sort_order: string
    scores: string[]
  }
}

export default function GroupsTable({ groups, handleDeleteGroup, queryFilters }: GroupTableProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const navigateTo = useNavigate()
  const [data, setData] = useState<Group[]>(groups)
  const { data: offices } = useOffices()
  const { data: members } = useMembers()
  const defaultRowsPerPage = getDefaultTableSize(TABLE_SIZE_REFERENCE_KEYS.ALL_USERS_TABLE)

  function handleChangeRowsPerPage(pageSize: number) {
    setDefaultTableSize(TABLE_SIZE_REFERENCE_KEYS.ALL_USERS_TABLE, pageSize)
  }

  const columns: ColumnInterface[] = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'No. of Members',
        accessor: 'user_count',
        Cell: ({ value }) => <div>{value}</div>,
      },
      {
        Header: 'Group Managers',
        accessor: 'managers',
        disableSortBy: true,
        Cell: ({ value }) => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {value?.length > 0 ? (
              <Tags
                useChips={false}
                tags={
                  value &&
                  value.map((manager) => ({
                    label: `${manager.first_name} ${manager.last_name}` || '-',
                    color: theme.palette.success.light,
                  }))
                }
                limit={1}
              />
            ) : (
              '-'
            )}
          </div>
        ),
      },
      {
        Header: t('users.groupsTable.score'),
        accessor: 'awareness_score',
        Cell: ({ value }) => {
          return <HumanizedScore score={value} />
        },
      },
      {
        id: 'delete',
        width: 80,
        align: 'center',
        Cell: (data) => {
          const group = data.row.original
          const groupId = group._id
          return (
            <MoreMenu
              items={[
                {
                  label: 'users.groupsTable.remove',
                  textColor: theme.palette.error.main,
                  onClick: () => handleDeleteGroup(groupId),
                },
              ]}
              ariaLabel={'users.groupsTable.moreActions'}
            />
          )
        },
      },
    ],
    [t]
  )

  function getGroupTextualScore(score: number) {
    if (score < 0) {
      return '-'
    } else if (score >= 0 && score <= 25) {
      return 'bad'
    } else if (score > 25 && score <= 50) {
      return 'low'
    } else if (score > 50 && score <= 75) {
      return 'average'
    } else {
      return 'excellent'
    }
  }

  useEffect(() => {
    groups.forEach((group) => {
      group.languages = []
      group.timezones = []
      group.offices?.forEach((office) => {
        const officeData = offices?.byId[office] ?? null
        if (officeData) {
          group.languages.push(officeData.language)
          group.timezones.push(officeData.time_zone)
        }
      })
    })

    let filteredGroups = [...groups]
    if (queryFilters.search || queryFilters.offices || queryFilters.scores) {
      filteredGroups = filter(groups, (group) => {
        const nameMatch = queryFilters.search
          ? group.name.toLowerCase().startsWith(queryFilters.search.toLowerCase())
          : true
        const officeMatch =
          queryFilters.offices.length > 0 ? intersection(group.offices, queryFilters.offices).length > 0 : true
        const scoreMatch =
          queryFilters.scores.length > 0
            ? queryFilters.scores.includes(getGroupTextualScore(group.awareness_score))
            : true
        const languagesMatch =
          queryFilters.languages.length > 0 ? intersection(group.languages, queryFilters.languages).length > 0 : true
        const timezoneMatch =
          queryFilters.timezones.length > 0 ? intersection(group.timezones, queryFilters.timezones).length > 0 : true
        return nameMatch && officeMatch && scoreMatch && languagesMatch && timezoneMatch
      })
    }
    setData(filteredGroups)
  }, [groups, offices, queryFilters])

  function handleNavigateToGroupProfile({ original }) {
    navigateTo(`/recipients/groups/${original._id}`)
  }

  return (
    <SimpleTable
      columns={columns}
      enableCheckbox={false}
      data={data}
      setData={() => {}}
      noResultsContent={<NoResults officesCount={offices?.array.length} membersCount={members?.total} />}
      onRowClick={handleNavigateToGroupProfile}
      initialPageSize={defaultRowsPerPage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  )
}

const NoResults: FC<{ officesCount?: number; membersCount?: number }> = ({ officesCount, membersCount }) => {
  const { t } = useTranslation()
  return (
    <InTableMessageWithAnimation>
      <p>{t(officesCount ? 'noResults.noGroupAdded' : 'noResults.noOfficeAdded')}</p>
      <p>
        {officesCount ? (
          membersCount ? (
            <Trans i18nKey={'noResults.addGroup'} components={{ 1: <NavLink to={'/recipients/groups'} /> }} />
          ) : (
            <Trans
              i18nKey={'noResults.addMemberForGroup'}
              components={{
                1: <NavLink to={'/recipients/members'} />,
                2: <NavLink to={'/recipients/groups'} />,
              }}
            />
          )
        ) : (
          <Trans i18nKey={'noResults.addOffice'} components={{ 1: <NavLink to={'/recipients/offices'} /> }} />
        )}
      </p>
    </InTableMessageWithAnimation>
  )
}
