import { palette } from '../palette'

export const MuiRadio = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      width: '38px',
      height: '38px',
      '&.Mui-checked': {
        '& .MuiSvgIcon-root': {
          background: palette.blueDianne[900],
          border: `2.5px solid ${palette.cyan[500]}`,
          width: '14px',
          height: '14px',
          overFlow: 'hidden',
        },
        '&:hover': {
          background: palette.cyan[50],
        },
        '&:active': {
          background: palette.cyan[200],
        },
      },
      '& .MuiSvgIcon-root': {
        borderRadius: 100,
        background: palette.white,
        boxSizing: 'border-box',
        boxShadow: `0 0 0 2px ${palette.blueDianne[900]}`,
        fill: 'transparent',
        width: '14px',
        height: '14px',
        overFlow: 'hidden',
      },
      '&:hover': {
        background: palette.grey[300],
      },
      '&:active': {
        background: palette.grey[400],
      },
      '&.Mui-disabled': {
        '&.Mui-checked .MuiSvgIcon-root': {
          background: palette.grey[500],
          borderColor: palette.grey[50],
        },
        '& .MuiSvgIcon-root': {
          boxShadow: `0 0 0 2px ${palette.grey[500]}`,
          background: palette.grey[50],
        },
        '&:hover': {
          background: 'none',
        },
      },
    },
  },
}
