import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { useADSecret } from "../../../api/active-directory/get-secret";
import LoadingContainer from "../../../common/components/LoadingContainer/LoadingContainer";
import AdCredentials from "./AdCredentials";
import ConnectedActiveDirectory from "./ConnectedActiveDirectory";

export default function ActiveDirectory() {
  const classes = useStyles();
  const { data, isLoading } = useADSecret();
  if (isLoading && !data) return <LoadingContainer />;
  return (
    <div className={classes.root}>
      {data?.client_secret ? <ConnectedActiveDirectory /> : <AdCredentials />}
    </div>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
    },
  })
);
