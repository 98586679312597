import { keepPreviousData, useQuery } from '@tanstack/react-query'
import axios from 'axios'

import { API_URL } from '../constants'
import { paramsSerializer } from '../utils/serialize-array'
import { Admin } from '@/types/admins'

type AdminRequest = {
  id: string | undefined
}

type AdminsRequest = {
  query: Admin
}
type AdminsResponse = {
  total: number
  results: Admin[]
}

const getAdmin = async (id: string | undefined) => {
  const url = `${API_URL}/admins/${id}`
  const { data } = await axios.get(url, { withCredentials: true, paramsSerializer: paramsSerializer })

  return data
}

const getAllAdmins = async (params: Admin) => {
  const url = `${API_URL}/admins/`
  const { data } = await axios.get(url, { withCredentials: true, params, paramsSerializer: paramsSerializer })

  return data
}

export const useAdmin = ({ id }: AdminRequest) => {
  return useQuery<Admin>({
    queryKey: ['admins', id],
    queryFn: () => getAdmin(id),
  })
}

export const useAdmins = ({ query }: AdminsRequest) => {
  return useQuery<AdminsResponse>({
    queryKey: ['admins', query],
    queryFn: () => {
      return getAllAdmins(query)
    },
    placeholderData: keepPreviousData,
  })
}
