import axios from 'axios'
import { API_URL } from '../constants'

import { ServerError } from '../types'
import { useQuery } from '@tanstack/react-query'
import { FeedbackEducationalModule } from '@/types/educationModules'

const getTrainingByToken = async (token: string): Promise<FeedbackEducationalModule> => {
  if (token) {
    const url = `${API_URL}/education_modules/token/${token}`
    const { data } = await axios.get<FeedbackEducationalModule>(url)
    return data
  } else {
    throw new Error('Token is undefined')
  }
}

const useTrainingByToken = (token: string) => {
  return useQuery<FeedbackEducationalModule, ServerError>({
    queryKey: ['training-campaigns-token', token],
    queryFn: () => getTrainingByToken(token),
    enabled: !!token,
  })
}

export { useTrainingByToken }
