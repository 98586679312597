import { Chip, Paper, TextField, Typography } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Moment } from 'moment'
import { Dispatch, SetStateAction, useMemo, useState } from 'react'

import { EU_DATE_TIME_12HR } from '@/api/constants'
import { useTranslation } from 'react-i18next'
import { useGroups } from '@/api/groups/groups'
import { useOffices } from '@/api/offices/offices'
import { useUsers } from '@/api/users/users'
import { AutoCompleteItem } from './CreateTrainingCampaign'

type CampaignDetailsProps = {
  isEditMode: boolean
  dates: { start: Moment; end: Moment }
  setDates: Dispatch<SetStateAction<{ start: Moment; end: Moment }>>
  selectedUsers: AutoCompleteItem[]
  setSelectedUsers: Dispatch<SetStateAction<AutoCompleteItem[]>>
  selectedGroups: AutoCompleteItem[]
  setSelectedGroups: Dispatch<SetStateAction<AutoCompleteItem[]>>
  selectedOffices: AutoCompleteItem[]
  setSelectedOffices: Dispatch<SetStateAction<AutoCompleteItem[]>>
  isDemoCampaign: boolean
  setIsDemoCampaign: Dispatch<SetStateAction<boolean>>
  setCampaignName: Dispatch<SetStateAction<string>>
  campaignName: string
}

export default function TrainingCampaignDetails({
  isEditMode,
  selectedUsers,
  setSelectedUsers,
  selectedGroups,
  setSelectedGroups,
  selectedOffices,
  setSelectedOffices,
  dates,
  setDates,
  campaignName,
  setCampaignName,
}: CampaignDetailsProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [query, setQuery] = useState('')
  const { data: users } = useUsers({
    fullname: query,
    email: '',
    offset: 50,
    startIndex: 0,
    offices: [],
    groups: [],
  })
  const { data: offices } = useOffices()
  const { data: groups } = useGroups()

  const data = useMemo(() => {
    const userEntries: AutoCompleteItem[] = users
      ? users.items.map((user) => ({
          label: `${user.first_name} ${user.last_name}`,
          _id: user._id,
          type: 'users',
        }))
      : []
    const groupEntries: AutoCompleteItem[] = groups
      ? groups.map((group) => ({
          label: group.name,
          _id: group._id,
          type: 'groups',
        }))
      : []
    const officeEntries: AutoCompleteItem[] = offices
      ? offices.array.map((office) => ({
          label: office.name,
          _id: office._id,
          type: 'offices',
        }))
      : []
    return [...userEntries, ...groupEntries, ...officeEntries]
  }, [users, groups, offices])

  function handleChange(event, selectedValues) {
    setSelectedUsers(selectedValues.filter((value) => value.type === 'users'))
    setSelectedOffices(selectedValues.filter((value) => value.type === 'offices'))
    setSelectedGroups(selectedValues.filter((value) => value.type === 'groups'))
  }

  function handleQueryChange(event, newInputValue) {
    setQuery(newInputValue)
  }

  const handleDateChange = (key: string) => (date: Moment | null) => {
    setDates((prevState) => ({ ...prevState, [key]: date }))
  }

  const autoCompleteValues = useMemo(() => {
    return [...selectedUsers, ...selectedOffices, ...selectedGroups]
  }, [selectedUsers, selectedOffices, selectedGroups])

  return (
    <Paper className={classes.paper}>
      <Typography gutterBottom variant={'h5'}>
        {t('createTrainingCampaign.details.campaignDetails')}
      </Typography>
      <TextField
        fullWidth
        variant="standard"
        label={t('createTrainingCampaign.details.name')}
        value={campaignName}
        onChange={(e) => setCampaignName(e.target.value)}
      />
      <Autocomplete
        disabled={isEditMode}
        value={autoCompleteValues}
        onChange={handleChange}
        onInputChange={handleQueryChange}
        multiple
        id="tags-filled"
        options={data.map((option) => option)}
        getOptionLabel={(option) => option.label}
        groupBy={(option) => t(`createTrainingCampaign.details.${option.type}`)}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            return <Chip label={option.label} {...getTagProps({ index })} />
          })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label={t('createTrainingCampaign.details.recipients')}
            placeholder={isEditMode ? '' : t('createTrainingCampaign.details.addUsers')}
          />
        )}
      />
      <div className={classes.dateContainer}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            inputFormat={EU_DATE_TIME_12HR}
            className={classes.datePicker}
            renderInput={(params) => <TextField {...params} />}
            componentsProps={{
              actionBar: {
                actions: ['clear'],
              },
            }}
            disablePast
            label={t('createTrainingCampaign.details.startDate')}
            value={dates.start}
            onChange={handleDateChange('start')}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            inputFormat={EU_DATE_TIME_12HR}
            className={classes.datePicker}
            renderInput={(params) => <TextField {...params} />}
            componentsProps={{
              actionBar: {
                actions: ['clear'],
              },
            }}
            disablePast
            label={t('createTrainingCampaign.details.endDate')}
            value={dates.end}
            onChange={handleDateChange('end')}
          />
        </LocalizationProvider>
      </div>
    </Paper>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    paper: { padding: theme.spacing(2) },
    datePicker: {
      width: 240,
      marginRight: theme.spacing(4),
    },
    dateContainer: {
      width: '100%',
      marginTop: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
    },
  })
)
