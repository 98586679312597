import React from "react";
import useLottie from "@/common/hooks/useLottie";

import animationData from "@/assets/lottie/fingerprint.json";

export default function AiLoader() {
  const { LottieAnimation } = useLottie({
    animationData: animationData,
    height: 150,
    width: 250,
  });

  return <>{LottieAnimation}</>;
}
