import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ColumnInterface } from "react-table";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import PaginatingTable from "../../common/components/Tables/PaginatingTable";
import { useQueryCache } from "react-query";
import LoadingContainer from "../../common/components/LoadingContainer/LoadingContainer";
import moment from "moment";
import { Paper } from "@mui/material";
import {
  Activity,
  ClientActivityQueryVariables,
  useClientActivity,
} from "../../api/client-activity/client-activity";
import DateFilter from "../../common/components/Tables/DateFilter";
import {
  TABLE_SIZE_REFERENCE_KEYS,
  getDefaultTableSize,
  setDefaultTableSize,
} from "../../common/utils/table-size";

const INITIAL_QUERY_FILTERS = {
  startDate: 0,
  endDate: moment().unix(),
  offset: 5,
  startIndex: 0,
};

type ClientActivityTableProps = {
  setExportData: (data: Activity[]) => void;
};
export default function ClientActivityTable({
  setExportData,
}: ClientActivityTableProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const queryCache = useQueryCache();
  const [queryFilters, setQueryFilters] =
    useState<ClientActivityQueryVariables>(INITIAL_QUERY_FILTERS);
  const {
    data: clientActivityData,
    isLoading,
    isFetching,
  } = useClientActivity(queryFilters);
  const { items: activities, count } = clientActivityData ?? {
    count: 0,
    items: [],
  };
  const [pageCount, setPageCount] = React.useState(0);
  const defaultRowsPerPage = getDefaultTableSize(
    TABLE_SIZE_REFERENCE_KEYS.LOGS_TABLE
  );

  function handleChangeRowsPerPage(pageSize: number) {
    setDefaultTableSize(TABLE_SIZE_REFERENCE_KEYS.LOGS_TABLE, pageSize);
  }

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      setQueryFilters((prevState) => ({
        ...prevState,
        startIndex: prevState.offset * pageIndex,
        offset: pageSize,
      }));
      // Only update the data if this is the latest fetch
      setPageCount(Math.ceil(count / pageSize));
      queryCache.prefetchQuery([
        "client-activity",
        {
          ...queryFilters,
          startIndex: pageSize * (pageIndex + 1),
          offset: pageSize,
        },
      ]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [count]
  );

  const columns: ColumnInterface[] = useMemo(
    () => [
      {
        Header: t("clientActivityDashboard.action"),
        accessor: "text",
        disableSortBy: true,
      },
      {
        Header: t("clientActivityDashboard.date"),
        accessor: "timestamp",
        Cell: ({ value }) =>
          value ? moment.unix(value).format("DD/MM/YY HH:mm:ss") : "",
      },
    ],
    [t]
  );

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setExportData(activities);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isFetching]);

  return !isLoading ? (
    <>
      <DateFilter
        queryFilters={queryFilters}
        setQueryFilters={setQueryFilters}
      />
      <Paper className={classes.table}>
        <PaginatingTable
          enableCheckbox={false}
          noResultsTextId={"clientActivityDashboard.noResults"}
          columns={columns}
          data={activities ?? []}
          fetchData={fetchData}
          loading={isLoading || isFetching}
          pageCount={pageCount}
          count={count}
          initialPageSize={defaultRowsPerPage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  ) : (
    <LoadingContainer />
  );
}
const useStyles = makeStyles(() =>
  createStyles({
    table: {
      "& .MuiTableCell-head": {
        textAlign: "center",
        paddingLeft: 40,
        "&:first-child": {
          paddingLeft: 15,
          textAlign: "left",
        },
      },
      "& .MuiTableCell-body": {
        textAlign: "center",
        "&:first-child": {
          textAlign: "left",
        },
      },
    },
  })
);
