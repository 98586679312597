import React, { FC } from 'react'
import { useField, FieldHookConfig } from 'formik'
import MuiTextField, { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField'

interface TextFieldProps
  extends Omit<MuiTextFieldProps, 'name' | 'value' | 'error' | 'onChange' | 'onBlur'>,
    Pick<FieldHookConfig<string>, 'name'> {
  disableFormik?: boolean
  value?: MuiTextFieldProps['value']
  onChange?: MuiTextFieldProps['onChange']
  onBlur?: MuiTextFieldProps['onBlur']
}

const TextField: FC<TextFieldProps> = ({
  name,
  label,
  placeholder,
  InputProps,
  InputLabelProps,
  disableFormik = false,
  onChange,
  onBlur,
  ...otherProps
}) => {
  const [field, meta] = useField(name)

  const combinedInputProps = {
    style: {
      height: '20px',
      padding: '8px 8px 8px 16px',
    },
    ...InputProps,
  }

  const combinedInputLabelProps = {
    shrink: true,
    ...InputLabelProps,
  }

  const configTextField: MuiTextFieldProps = {
    ...otherProps,
    fullWidth: true,
    label: label,
    placeholder: placeholder,
    InputLabelProps: combinedInputLabelProps,
    inputProps: combinedInputProps as any,
  }

  if (!disableFormik) {
    // Merge Formik-related props if Formik is enabled
    Object.assign(configTextField, {
      ...field,
      error: meta.touched && Boolean(meta.error),
      helperText: meta.touched && meta.error,
      onChange: onChange
        ? (event) => {
            field.onChange(event)
            onChange(event)
          }
        : field.onChange,
      onBlur: onBlur
        ? (event) => {
            field.onBlur(event)
            onBlur(event)
          }
        : field.onBlur,
    })
  } else {
    // Attach provided onChange and onBlur handlers for non-Formik usage
    if (onChange) configTextField.onChange = onChange
    if (onBlur) configTextField.onBlur = onBlur
  }

  return <MuiTextField {...configTextField} />
}

export default TextField
