import axios from 'axios'
import { API_URL } from '../constants'
import { useQuery } from '@tanstack/react-query'
import { ServerError } from '../types'
import { DynamicGroup, DynamicGroupMembersRequest, DynamicGroupMembersResponse } from '@/types/dynamicGroups'

const getDynamicGroup = async (id: string) => {
  const url = `${API_URL}/dynamic-groups/${id}`
  const { data } = await axios.get(url, {
    withCredentials: true,
  })

  return data
}

export const getDynamicGroupMembers = async ({
  id,
  params = {
    skip: 0,
    limit: 10,
    sort_by: 'first_name',
    sort_order: 'desc',
    search: '',
  },
}: DynamicGroupMembersRequest) => {
  const url = `${API_URL}/dynamic-groups/${id}/members`
  const { data } = await axios.get(url, {
    params,
    withCredentials: true,
  })

  return data
}

const getDynamicGroups = async ({ params }) => {
  const url = `${API_URL}/dynamic-groups/`
  const { data } = await axios.get(url, {
    params,
    withCredentials: true,
  })

  return data
}

export const useDynamicGroups = ({ params } = {}) =>
  useQuery<DynamicGroup[], ServerError>({
    queryKey: ['dynamic-groups', { ...params }],
    queryFn: () => getDynamicGroups({ params }),
  })

export const useDynamicGroup = (id: string) =>
  useQuery<DynamicGroup, ServerError>({
    queryKey: ['dynamic-group', id],
    queryFn: () => getDynamicGroup(id),
  })

export const useDynamicGroupMemebers = ({ id, params }: DynamicGroupMembersRequest) =>
  useQuery<DynamicGroupMembersResponse, ServerError>({
    queryKey: ['dynamic-group', id, 'members', { ...params }],
    queryFn: () => getDynamicGroupMembers({ id, params }),
  })
