import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { useParams, useNavigate } from "react-router-dom";
import LoadingContainer from "../../common/components/LoadingContainer/LoadingContainer";
import { Typography } from "@mui/material";
import InvalidToken from "../TrainingCampaignView/InvalidToken";
import { useEducationModuleById } from "../../api/education-modules/module-by-id";
import { useUrlSearchQuery } from "../../common/hooks/useUrlQuery";
import { isValidLanguageKey } from "../../common/utils/language-utils";
import VideoPlayerV2 from "../../common/components/VideoPlayerV2/VideoPlayerV2";
import TrainingCampaignDemoViewHeader from "./TrainingCampaignDemoViewHeader";
import Logo from "../../assets/images/logo-clean-blue.svg";
import UnfinishedAlert from "../TrainingCampaignView/UnfinishedAlert";

export default function TrainingCampaignView() {
  const classes = useStyles();
  const { token } = useParams();
  const queryParams = useUrlSearchQuery();
  const { data, isLoading, isError } = useEducationModuleById(
    "62ac533785a8da1709a0e6f5"
  );
  const [currentLanguage, setCurrentLanguage] = useState(
    isValidLanguageKey(queryParams.language) ? queryParams.language : "en"
  );
  const [videoState, setVideoState] = useState({
    currentTime: 0,
    duration: data?.duration || 100,
  });
  const navigateTo = useNavigate();
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const isRtl = currentLanguage === "he" || currentLanguage === "ar";

  const percentagePlayedRef = useRef(0);

  const changeLanguage = React.useCallback(async (value) => {
    if (isValidLanguageKey(value)) {
      setCurrentLanguage(value);
    }
  }, []);

  useEffect(() => {
    if (isValidLanguageKey(queryParams.language)) {
      changeLanguage(queryParams.language);
    }
  }, [changeLanguage, queryParams.language]);

  const handleProgress = useCallback(
    (state: { currentTime: number; duration: number }) => {
      setVideoState(state);
      const percentage = Math.ceil((state.currentTime / state.duration) * 100);
      percentagePlayedRef.current = percentage;

      // if (percentage >= 90) {
      //   setHasViewedEntireVideo(true);
      // }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [token]
  );

  const videoPlayerProps = useMemo(() => {
    return {
      videoTracks: data?.video_tracks ? data.video_tracks : {},
      subtitleTracks: data?.subtitle_tracks ? data.subtitle_tracks : {},
      thumbnail: data?.thumbnail ? data?.thumbnail : "",
      onProgress: handleProgress,
      defaultLanguage: currentLanguage,
    };
  }, [currentLanguage, data, handleProgress]);

  function handleStartQuiz() {
    if (data?.quizzes?.length) {
      navigateTo(
        `/genesis/training-campaign-demo/quiz/preview/${data.quizzes[0]._id}`
      );
    }
  }

  return (
    <div className={classes.root}>
      <UnfinishedAlert
        open={alertIsOpen}
        handleClose={() => setAlertIsOpen(false)}
      />
      {!isLoading ? (
        isError || !data ? (
          <InvalidToken />
        ) : (
          <>
            <TrainingCampaignDemoViewHeader
              hasAnsweredQuiz={false}
              quizEnabled={true}
              hasQuiz={true}
              videoProgress={Math.ceil(
                (videoState.currentTime / videoState.duration) * 100
              )}
              startQuiz={handleStartQuiz}
            />
            <div className={classes.flexCenter}>
              <div className={classes.videoContainer}>
                <VideoPlayerV2 {...videoPlayerProps} />
                <Typography
                  gutterBottom
                  id={"subject"}
                  variant={"h6"}
                  style={{
                    textAlign: isRtl ? "right" : "left",
                    direction: isRtl ? "rtl" : "ltr",
                  }}
                >
                  {data.title}
                </Typography>
              </div>
            </div>
          </>
        )
      ) : (
        <LoadingContainer />
      )}
      <img className={classes.logoWatermark} src={Logo} alt={"logo"} />
    </div>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      paddingBottom: theme.spacing(4),
      height: "100%",
      overflow: "hidden",
    },
    container: {
      padding: theme.spacing(2, 4),
      marginBottom: theme.spacing(4),
    },
    flexCenter: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },

    videoContainer: {
      width: "100%",
      maxWidth: "60%",
      minHeight: 400,
    },
    logoWatermark: {
      position: "absolute",
      bottom: -100,
      left: -50,
      opacity: 0.4,
      zIndex: -1,
      height: 400,
      transform: "rotate(20deg)",
    },
  })
);
