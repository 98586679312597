import { Box, Typography, styled } from '@mui/material'
import senderImage from '@/assets/images/MobilePreview/SmsPreviewApp/SmsTop.svg'
import bottomImage from '@/assets/images/MobilePreview/SmsPreviewApp/SmsBottom.svg'
import tipImage from '@/assets/images/MobilePreview/SmsPreviewApp/SmsMessageTip.svg'
import hookBackground from '@/assets/images/MobilePreview/SmsPreviewApp/hook-background.gif'

import { FC } from 'react'
import StatusBar from './StatusBar'
import { theme } from '@/theme/theme'

type SmsAppPreviewProps = {
  sender: string
  message: string
  isHookBG?: boolean
  isRtl?: boolean
}

const SmsAppPreview: FC<SmsAppPreviewProps> = ({ sender, message, isHookBG = false, isRtl = false }) => {
  return (
    <BackGround>
      <StatusBar color="black" backgroundColor="white" />
      <SenderContainer isRtl={isRtl} id="sender">
        <Sender>{sender || '{ Sender }'}</Sender>
      </SenderContainer>
      <ScrollBox isHookBG={isHookBG}>
        <TodayText isHookBG={isHookBG}>Text Message Today 1:26 PM</TodayText>
        <MessageBoxTop isRtl={isRtl} />
        <MessageBox isRtl={isRtl}>
          <Message
            isRtl={isRtl}
            dangerouslySetInnerHTML={{
              __html: message !== '' ? message : 'Your Message will be displayed here...',
            }}
          />
        </MessageBox>
        <MessageBoxBottom isRtl={isRtl}>
          <Tip></Tip>
        </MessageBoxBottom>
      </ScrollBox>
      <Bottom />
    </BackGround>
  )
}

const BackGround = styled(Box)(() => ({
  position: 'absolute',
  backgroundColor: 'white',
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  marginTop: '2.8px',
}))

const SenderContainer = styled(Box)(({ isRtl }: { isRtl: boolean }) => ({
  position: 'absolute',
  top: '6.5%',
  left: '-0.7%',
  width: '100%',
  backgroundColor: '#E4E4E4',
  height: '10.9%',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundImage: `url(${senderImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    transform: isRtl ? 'scaleX(-1)' : 'none',
    zIndex: 0,
  },
  '& > *': {
    position: 'relative',
    zIndex: 1,
  },
}))

const Sender = styled(Typography)(() => ({
  width: '100%',
  padding: '15% 20% 0% 20%',
  color: 'black',
  fontSize: '65%',
  display: 'flex',
  justifyContent: 'center',
  overflow: 'clip',
}))

const ScrollBox = styled(Box)(({ isHookBG }: { isHookBG: boolean }) => ({
  position: 'absolute',
  backgroundColor: theme.palette.white,
  backgroundImage: isHookBG ? `url(${hookBackground})` : 'none',
  backgroundSize: 'cover',
  top: '17.4%',
  width: '100%',
  height: '76%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  overflowY: 'scroll',
  padding: '0.4rem',
  '::-webkit-scrollbar': {
    width: '0.4rem',
  },
}))

const MessageBoxTop = styled(Box)(({ isRtl }: { isRtl: boolean }) => ({
  display: 'block',
  backgroundColor: '#E4E4E4',
  width: '75%',
  padding: '3%',
  borderTopRightRadius: '3rem',
  borderTopLeftRadius: '3rem',
  marginTop: '2%',
  marginLeft: isRtl ? 'auto' : '2%',
  marginRight: isRtl ? '2%' : 'auto',
}))

const MessageBoxBottom = styled(Box)(({ isRtl }: { isRtl: boolean }) => ({
  display: 'block',
  backgroundColor: '#E4E4E4',
  width: '75%',
  padding: '2%',
  borderBottomLeftRadius: '3rem',
  borderBottomRightRadius: '3rem',
  marginLeft: isRtl ? 'auto' : '2%',
  marginRight: isRtl ? '2%' : 'auto',
  position: 'relative',
}))

const TodayText = styled(Typography)(({ isHookBG }: { isHookBG: boolean }) => ({
  display: 'block',
  fontSize: '56%',
  marginTop: '8%',
  color: isHookBG ? theme.palette.white : theme.palette.black,
  alignItem: 'center',
}))

const Tip = styled(Box)(() => ({
  position: 'absolute',
  width: '7%',
  height: '7%',
  left: '1%',
  transform: 'translate(0,-35%)',
  backgroundImage: `url(${tipImage})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
}))

const MessageBox = styled(Box)(({ isRtl }: { isRtl: boolean }) => ({
  display: 'block',
  backgroundColor: '#E4E4E4',
  width: '75%',
  marginTop: '-2%',
  padding: '0% 5%',
  marginLeft: isRtl ? 'auto' : '2%',
  marginRight: isRtl ? '2%' : 'auto',
}))

const Message = styled(Typography)(({ isRtl }: { isRtl: boolean }) => ({
  display: 'block',
  width: '100%',
  color: 'black',
  fontSize: '70%',
  overflowWrap: 'anywhere',
  pointerEvents: 'none',
  direction: isRtl ? 'rtl' : 'ltr',
}))

const Bottom = styled(Box)(() => ({
  position: 'absolute',
  width: '110%',
  height: '8%',
  bottom: 0,
  backgroundColor: '#F6F6F6',
  backgroundImage: `url(${bottomImage})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
}))

export default SmsAppPreview
