import { Button, CircularProgress, Dialog, Paper, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { string } from 'yup'

import Form from '@/common/components/Form/Form'
import useForm, { Field } from '@/common/components/Form/useForm'
import Gsuite from './Gsuite'
import Outlook from './Outlook'

const fields: Field[] = [
  {
    name: 'token',
    placeholder: 'simulatorOnboarding.whitelisting.enterVerificationCode',
    validationRule: string().required('simulatorOnboarding.whitelisting.missingCode'),
    type: 'text',
    autoComplete: 'none',
    required: true,
    autoFocus: false,
    textFieldProps: {
      variant: 'outlined',
      margin: 'dense',
      fullWidth: false,
    },
    gridItemProps: {
      style: {
        display: 'flex',
        alignItems: 'center',
      },
    },
  },
]

type WhitelistingInstructionsProps = {
  handleSendEmail: () => void
  handleVerifyToken: (token: string | number) => void
  isSendingEmail: boolean
  isVerifyingToken: boolean
}

const WhitelistingInstructions: FC<WhitelistingInstructionsProps> = ({
  handleSendEmail,
  handleVerifyToken,
  isSendingEmail,
  isVerifyingToken,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { formProps, handleSubmit } = useForm({
    fields,
    onSubmit: ({ token }) => handleVerifyToken(Number(token)),
  })

  const [dialogIsOpen, setDialogIsOpen] = useState(false)
  const [dialogContent, setDialogContent] = useState<'gsuite' | 'outlook'>('gsuite')

  const handleClose = () => {
    setDialogIsOpen(false)
  }

  const handleOpenDialog = (content: 'gsuite' | 'outlook') => {
    setDialogContent(content)
    setDialogIsOpen(true)
  }

  return (
    <>
      <Dialog open={dialogIsOpen} onClose={handleClose} scroll={'body'}>
        {dialogContent === 'gsuite' ? <Gsuite /> : <Outlook />}
      </Dialog>
      <Paper className={classes.body}>
        <div>
          <Typography gutterBottom variant={'subtitle1'}>
            {t('simulatorOnboarding.whitelisting.whitelistingExplain')}
          </Typography>
          <Typography gutterBottom variant={'subtitle1'}>
            <span className={classes.bold}>1.</span> {t('simulatorOnboarding.whitelisting.selectPlatform')}
          </Typography>

          <div className={classes.buttonsContainer}>
            <Button onClick={() => handleOpenDialog('gsuite')} variant={'contained'} color={'primary'}>
              {t('simulatorOnboarding.whitelisting.gsuite')}
            </Button>
            <Button
              className={classes.button}
              onClick={() => handleOpenDialog('outlook')}
              variant={'contained'}
              color={'primary'}
            >
              {t('simulatorOnboarding.whitelisting.outlook')}
            </Button>
          </div>
        </div>
        <Typography gutterBottom variant={'subtitle1'}>
          <span className={classes.bold}>2.</span> {t('simulatorOnboarding.whitelisting.verifyWhitelisting')}
        </Typography>
        <div className={classes.buttonsContainer}>
          <Button className={classes.sendEmailButton} onClick={handleSendEmail} variant={'contained'} color={'primary'}>
            {isSendingEmail ? (
              <CircularProgress color={'inherit'} size={24} />
            ) : (
              t('simulatorOnboarding.whitelisting.sendTest')
            )}
          </Button>
        </div>
        <Typography gutterBottom variant={'subtitle1'}>
          <span className={classes.bold}>3.</span> {t('simulatorOnboarding.whitelisting.enterCode')}
        </Typography>
        <div className={classes.verifyContainer}>
          <div>
            <Form gridContainerProps={{ spacing: 0 }} {...formProps} />
          </div>

          <Button
            disabled={!formProps.values?.token}
            className={classes.verifyButton}
            onClick={handleSubmit}
            variant={'contained'}
            color={'primary'}
          >
            {isVerifyingToken ? (
              <CircularProgress color={'inherit'} size={24} />
            ) : (
              t('simulatorOnboarding.whitelisting.verify')
            )}
          </Button>
        </div>
      </Paper>
    </>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      padding: theme.spacing(8, 4, 4),
      display: 'flex',
      flexDirection: 'column',
    },
    body: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(4),
    },
    buttonsContainer: {
      display: 'flex',
      margin: theme.spacing(1, 0, 2, 0),
    },
    button: {
      marginLeft: theme.spacing(2),
    },
    verifyButton: {
      margin: theme.spacing(0.5, 0, 0, 2),
      width: 80,
    },
    sendEmailButton: {
      width: 180,
    },
    bold: {
      fontWeight: 'bold',
    },
    verifyContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    title: { marginRight: theme.spacing(2) },
  })
)

export default WhitelistingInstructions
