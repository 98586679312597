import endent from 'endent'
import { SIMULATION_TYPES, VectorType } from '../state'

const WHATSAPP_FINDINGS = `
    - Make a typo in the 7th or 8th word.
`

const createHumanWhatsAppPrompt = (
  senderName: string,
  senderPosition: string,
  emailBody: string,
  toneOfVoice: string,
  language: string,
  instructions?: string,
  emailSenderSignature?: string
): string => {
  console.log('senderName', senderName)
  console.log('senderPosition', senderPosition)
  return endent`
    1. Act as an WhatsApp messages content writer.
    2.1 Compose a message from ${senderName}, ${senderPosition} about: ${emailBody}.
    2.2 IMPORTANT! Use {{link_click_url}} in the WhatsApp message, not hidden behind anything, just in plain text.
    3. Use tone of voice: ${toneOfVoice}.
    4. Write the WhatsApp message in ${language}.
    5. Limit the content to 160 characters.
    ${WHATSAPP_FINDINGS}
    6. ${
      instructions
        ? `${instructions}, never add placeholders for time, dates, etc.`
        : `never add placeholders for time, dates, etc.`
    }
    7. Add a signature at the end of the message using ${senderName} and ${senderPosition}
  `.trim()
}

const createAppWhatsAppPrompt = (
  appName: string,
  language: string,
  toneOfVoice: string,
  emailBody: string,
  instructions?: string,
  customLogo?: string
): string => {
  return endent`
    1. Act as an WhatsApp messages content writer.
    2.1 Compose a message from: ${appName} about: ${emailBody}.
    2.2 IMPORTANT! Use {{link_click_url}} in the WhatsApp message, not hidden behind anything, just in plain text.
    3. Use tone of voice: ${toneOfVoice}.
    4. Write the message in ${language}.
    5. Limit the content to 160 characters.
    ${WHATSAPP_FINDINGS}
    6. ${
      instructions
        ? `${instructions}, never add placeholders for time, dates, etc.`
        : `never add placeholders for time, dates, etc.`
    }
  `.trim()
}

const createAppWhatsAppPromptNoDesign = (
  appName: string,
  language: string,
  toneOfVoice: string,
  emailBody: string,
  instructions?: string,
  customLogo?: string
): string => {
  return endent`
      1. Act as an WhatsApp messages content writer. 
      2. Compose a concise message sent from: ${appName} about: ${emailBody}.
      2.2 IMPORTANT! Use {{link_click_url}} in the WhatsApp message, not hidden behind anything, just in plain text.
      3. Use tone of voice: ${toneOfVoice}.
      4. Write the WhatsApp message in ${language}.
      5. Limit the content to 160 characters.
      ${WHATSAPP_FINDINGS}
      6. ${
        instructions
          ? `${instructions}, never add placeholders for time, dates, etc.`
          : `never add placeholders for time, dates, etc.`
      }
  `.trim()
}

export const createWhatsAppBodyPrompt = (
  selectedSimulationType: string,
  language: string,
  toneOfVoice: string,
  emailBody: string,
  instructions: string | null,
  senderName: string | null,
  senderPosition: string | null,
  vectorType: string,
  designedEmail: boolean,
  appName: string,
  emailSenderSignature: string | null,
  customLogo: string | null
) => {
  if (selectedSimulationType === SIMULATION_TYPES.HUMAN && vectorType === VectorType.WHATSAPP) {
    return createHumanWhatsAppPrompt(
      senderName!,
      senderPosition!,
      emailBody,
      toneOfVoice,
      language,
      instructions!,
      emailSenderSignature!
    )
  } else if (selectedSimulationType === SIMULATION_TYPES.APP && vectorType === VectorType.WHATSAPP && !designedEmail) {
    return createAppWhatsAppPromptNoDesign(appName!, language, toneOfVoice, emailBody, instructions!)
  } else if (selectedSimulationType === SIMULATION_TYPES.APP && vectorType === VectorType.WHATSAPP) {
    return createAppWhatsAppPrompt(appName, language, toneOfVoice, emailBody, instructions!)
  }
  return ''
}
