const useLocalStorage = () => {
  const getLocalValue = (key: string) => {
    const value = localStorage.getItem(key)
    if (value) {
      try {
        return JSON.parse(value)
      } catch {
        return value
      }
    }
    return null
  }

  const setLocalValue = (key: string, value: string | number | boolean | object) => {
    if (typeof value === 'object') {
      localStorage.setItem(key, JSON.stringify(value))
    } else {
      localStorage.setItem(key, value.toString())
    }
  }

  return { getLocalValue, setLocalValue }
}

export default useLocalStorage
