import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { MenuButton } from 'video-react'
import { faGlobe } from '@fortawesome/pro-light-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
type Source = {
  label: string
  language: string
  source: string
  default: boolean
}
type SourceSelectorProps = {
  sources: Source[]
  onChange: (source: Source) => void
  currentSource: Source
  order?: number
}

export default function SourceSelector({ sources, onChange, currentSource, order }: SourceSelectorProps) {
  const classes = useStyles()
  const selectedIndex = sources.findIndex((source) => source.source === currentSource.source)
  return (
    <MenuButton
      className="video-react-source-selector"
      order={order}
      onSelectItem={(index) => {
        onChange(sources[index])
      }}
      items={sources}
      selectedIndex={selectedIndex}
    >
      <div className={classes.button}>
        <FontAwesomeIcon icon={faGlobe as IconProp} fontSize={14}></FontAwesomeIcon>
      </div>
      <span className="video-react-control-text">Closed Caption</span>
    </MenuButton>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    button: {
      height: '100%',
      width: '100%',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    icon: {
      height: 18,
      width: 18,
      color: theme.palette.common.white,
      backgroundColor: 'transparent',
      borderRadius: '50%',
      '&:hover': {
        // add glow effect
        boxShadow: '0 0 10px 1px rgba(255, 255, 255, 0.5)',
      },
    },
  })
)
