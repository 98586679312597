import { Box, Button, Typography, styled } from '@mui/material'

export const ModalConent = styled(Box)(() => ({
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -40%)',
  width: '760px',
  padding: '30px',
  display: 'flex',
  gap: '48px',
  backgroundColor: 'white',
  borderRadius: '10px',
  '&:focus-visible': {
    outline: 'none',
  },
}))

export const ImageWrapper = styled(Box)(() => ({
  width: '192px',
  height: '192px',
  img: {
    width: '100%',
    height: '100%',
  },
}))

export const ContentWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  flex: 1,
  gap: '30px',
}))

export const StyledTypography = styled(Box)(() => ({
  fontFamily: 'Montserrat',
  fontSize: '14px',
  h4: {
    margin: '0 0 16px',
    fontSize: '18px',
    fontWeight: 700,
  },
}))

export const ButtonsWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '8px',
}))
