import axios from "axios";
import { API_URL } from "../constants";
import { useMutation, useQueryCache } from "react-query";
import { ServerError } from "../types";
import { User } from "../users/users";

export async function toggleSavedContentAsset(assetId: string) {
  const { data } = await axios.post(
    `${API_URL}/profile/toggle-saved-asset/${assetId}/`,
    {},
    { withCredentials: true }
  );
  return data.data;
}

export function useToggleSavedContent() {
  const queryCache = useQueryCache();
  return useMutation<string, ServerError, string>(toggleSavedContentAsset, {
    throwOnError: true,
    onMutate: (assetId: string) => {
      queryCache.cancelQueries("user");
      const previousState: User | undefined = queryCache.getQueryData("user");
      if (!previousState) return;

      const previousSavedAssets = [...(previousState.saved_assets || [])];
      const index = previousSavedAssets.indexOf(assetId);
      if (index !== -1) {
        previousSavedAssets.splice(index, 1);
      } else {
        previousSavedAssets.push(assetId);
      }

      const updatedState = {
        ...previousState,
        saved_assets: previousSavedAssets,
      };

      queryCache.setQueryData("user", () => updatedState);
      return () => queryCache.setQueryData("user", previousState);
    },
    //@ts-ignore
    onError: (err, variables, rollback) => rollback(),
    onSettled: (response, error, variables) => {
      queryCache.invalidateQueries("user");
    },
  });
}
