import { OPENAI_MODEL } from '@/api/constants'

type OpenAIRequestProps = {
  prompt: string
  maxTokens?: number
  temperature?: number
}

export const OpenAIRequest = async ({ prompt, maxTokens = 200, temperature = 0 }: OpenAIRequestProps) => {
  const res = await fetch(`https://api.openai.com/v1/chat/completions`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${import.meta.env.VITE_OPENAI_API_KEY}`,
    },
    method: 'POST',
    body: JSON.stringify({
      messages: [{ role: 'system', content: prompt }],
      max_tokens: maxTokens,
      model: OPENAI_MODEL,
      temperature,
      stream: false,
    }),
  })

  const decoder = new TextDecoder()

  if (res.status !== 200) {
    const statusText = res.statusText
    const result = await res.body?.getReader().read()
    throw new Error(`OpenAI API returned an error: ${decoder.decode(result?.value) || statusText}`)
  }

  const data = res.body

  if (!data) {
    return
  }

  const reader = data.getReader()
  let done = false
  let lastMessage = ''

  while (!done) {
    const { value, done: doneReading } = await reader.read()
    done = doneReading
    const chunkValue = decoder.decode(value)

    lastMessage = lastMessage + chunkValue
  }

  return lastMessage
}
