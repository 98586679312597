import { FC } from 'react'
import { makeStyles } from '@mui/styles'
import { createStyles } from '@mui/styles'
import { Box, Typography, Link } from '@mui/material'
import UseLottie from '@/common/hooks/useLottie'
import underConstruction from '@/assets/lottie/underConstruction.json'
import { Trans, useTranslation } from 'react-i18next'

const SimulationBuilder: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { LottieAnimation } = UseLottie({
    animationData: underConstruction,
    height: 400,
    width: 400,
    options: {
      loop: true,
      autoplay: true,
      animationData: underConstruction,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    },
  })

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>{t('contentLibrary.liquidBuilder.underConstruction.title')}</Typography>
      {LottieAnimation}
      <Typography className={classes.text}>
        {t('contentLibrary.liquidBuilder.underConstruction.mainMessage')}
      </Typography>
      <Typography className={classes.text}>
        <Trans
          i18nKey={t('contentLibrary.liquidBuilder.underConstruction.description')}
          components={{
            1: <br />,
            2: <Link href={'mailto:support@cywareness.io'} className={classes.link} />,
          }}
        />
      </Typography>
      <Typography className={classes.text}>{t('contentLibrary.liquidBuilder.underConstruction.stayTuned')}</Typography>
    </Box>
  )
}

const useStyles = makeStyles((theme) => {
  return createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      flexDirection: 'column',
    },
    title: {
      fontFamily: 'Pattaya',
      fontSize: 80,
      fontWeight: 400,
      background: 'linear-gradient(182.66deg, #96B3BA -10.31%, #294D55 63.43%, #1E1E1E 97.66%)',
      WebkitTextFillColor: 'transparent',
      WebkitBackgroundClip: 'text',
      wordWrap: 'break-word',
      padding: theme.spacing(0, 2, 2, 0),
      margin: theme.spacing(2),
    },
    text: {
      fontFamily: 'Montserrat',
      fontSize: 16,
      fontWeight: 500,
      wordWrap: 'break-word',
      textAlign: 'center',
      margin: theme.spacing(2),
    },
    link: {
      color: '#003CD6',
    },
  })
})

export default SimulationBuilder
