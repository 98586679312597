import { theme } from '@/theme/theme'
import { TextField, styled } from '@mui/material'

export const StyledTextField = styled(TextField)(() => ({
  '& .MuiInputBase-input': {
    textAlign: 'center',
    fontSize: '14px',
    padding: '2px 8px',
  },

  '& .MuiInputLabel-root[data-shrink="false"]': {
    marginTop: '-8px',
  },

  '& .MuiOutlinedInput-root': {
    height: '40px',
    borderRadius: '10px',
    '& fieldset': {
      borderRadius: '10px',
    },
    '&:hover fieldset': {
      borderColor: theme.palette.cyan[500],
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.cyan[500],
    },
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: theme.palette.cyan[500],
  },
}))
