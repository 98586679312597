import {
  EmailAction,
  EmailState,
  RESET_EMAIL_STATE,
  SET_APP_NAME,
  SET_BODY_OUTPUT,
  SET_CUSTOM_LOGO,
  SET_DESIGNED_EMAIL,
  SET_DIFFICULTY_LEVEL,
  SET_EMAIL_BODY,
  SET_EMAIL_LANGUAGE,
  SET_EMAIL_SENDER_SIGNATURE,
  SET_EMAIL_SUBJECT,
  SET_FINDINGS,
  SET_FINDINGS_INSTRUCTIONS,
  SET_INSTRUCTIONS,
  SET_SENDER_NAME,
  SET_SENDER_POSITION,
  SET_SUBJECT_OUTPUT,
  SET_ICON_THEME,
  SET_BUTTON_COLOR,
  SET_BUTTON_TEXT_COLOR,
  SET_TONE_OF_VOICE,
  SET_PERSOANLIZED_TEMPLATE,
  SET_APP_LANDING_TEMPLATE,
} from "./Types";
import { initialEmailState } from "../Context";

export function emailReducer(
  state: EmailState,
  action: EmailAction
): EmailState {
  switch (action.type) {
    case SET_EMAIL_LANGUAGE:
      return { ...state, language: action.payload };
    case SET_EMAIL_SUBJECT:
      return { ...state, emailSubject: action.payload };
    case SET_EMAIL_BODY:
      return { ...state, emailBody: action.payload };
    case SET_SENDER_NAME:
      return { ...state, senderName: action.payload };
    case SET_SENDER_POSITION:
      return { ...state, senderPosition: action.payload };
    case SET_TONE_OF_VOICE:
      return { ...state, toneOfVoice: action.payload };
    case SET_INSTRUCTIONS:
      return { ...state, instructions: action.payload };
    case SET_SUBJECT_OUTPUT:
      return { ...state, subjectOutput: action.payload };
    case SET_BODY_OUTPUT:
      return { ...state, bodyOutput: action.payload };
    case SET_DESIGNED_EMAIL:
      return { ...state, designedEmail: action.payload };
    case SET_APP_NAME:
      return { ...state, appName: action.payload };
    case SET_ICON_THEME:
      return { ...state, iconTheme: action.payload };
    case SET_BUTTON_COLOR:
      return { ...state, buttonColor: action.payload };
    case SET_BUTTON_TEXT_COLOR:
      return { ...state, buttonTextColor: action.payload };
    case SET_APP_LANDING_TEMPLATE:
      return { ...state, appLandingTemplate: action.payload };
    case SET_EMAIL_SENDER_SIGNATURE:
      return { ...state, emailSenderSignature: action.payload };
    case SET_CUSTOM_LOGO:
      return { ...state, customLogo: action.payload };
    case SET_DIFFICULTY_LEVEL:
      return { ...state, difficultyLevel: action.payload };
    case SET_FINDINGS:
      return { ...state, findings: action.payload };
    case SET_FINDINGS_INSTRUCTIONS:
      return { ...state, findingsInstructions: action.payload };
    case SET_PERSOANLIZED_TEMPLATE:
      return { ...state, personalizedTemplate: action.payload };

    case RESET_EMAIL_STATE:
      return initialEmailState;

    default:
      return state;
  }
}
