import React, { useEffect, useMemo, useState } from "react";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import VerifyDomains from "./VerifyDomains";
import SidebarStepper from "../../common/components/SidebarStepper/SidebarStepper";
import { useClientDetails } from "../../api/client/client";
import { useUsers } from "../../api/users/users";
import LoadingContainer from "../../common/components/LoadingContainer/LoadingContainer";

export type Step = {
  name: string;
  path: string;
  completed: boolean;
};

export type OnboardingSteps = {
  verifyDomains: Step;
};

const INITIAL_STEPS: OnboardingSteps = {
  verifyDomains: {
    name: "onboarding.steps.setNetwork",
    path: "/onboarding/verify-domains",
    completed: false,
  },
};

export default function Onboarding() {
  const classes = useStyles();
  const navigateTo = useNavigate();
  const { data: clientDetails, isLoading } = useClientDetails();
  const { data: users } = useUsers();
  const [steps, setSteps] = useState(INITIAL_STEPS);
  const stepperSteps = useMemo(() => Object.values(steps), [steps]);

  const handleUpdateStep =
    (key: keyof OnboardingSteps) => (isCompleted: boolean) => {
      setSteps((prevState) => ({
        ...prevState,
        [key]: { ...prevState[key], completed: isCompleted },
      }));
    };

  useEffect(() => {
    if (!isLoading) {
      if (clientDetails?.onboarding) {
        navigateTo("/");
      } else {
        handleUpdateStep("verifyDomains")(
          !!(clientDetails?.domains && clientDetails.domains.length > 0)
        );
      }
    }
  }, [clientDetails, isLoading, navigateTo, users]);

  return (
    <div className={classes.root}>
      {isLoading ? (
        <LoadingContainer />
      ) : (
        <>
          <SidebarStepper
            steps={stepperSteps}
            title={"onboarding.steps.title"}
          />
          <Routes>
            <Route
              path="/"
              element={<Navigate to="verify-domains" replace />}
            />

            <Route
              path="verify-domains"
              element={
                <VerifyDomains updateStep={handleUpdateStep("verifyDomains")} />
              }
            />
          </Routes>
        </>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: "100%",
      width: "100%",
      display: "flex",
    },
    container: {
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    mainComponent: {
      flexGrow: 1,
    },
    footer: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: theme.spacing(2),
      padding: theme.spacing(0, 4),
    },
  })
);
