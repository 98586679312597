import {
  LandingPageAction,
  SET_LANDING_PAGE_OUTPUT,
  SET_LANDING_PAGE_TYPE,
  SET_LANDING_PAGE_URL,
  SET_PAGE_INSTRUCTIONS,
  SET_REDIRECT_OPTION,
} from "./Types";
import { LandingPageOptionProps } from "../../components/LandingPageTypeSelection";

export const setPageInstructions = (payload: string): LandingPageAction => ({
  type: SET_PAGE_INSTRUCTIONS,
  payload,
});
export const setLandingPageType = (
  payload: LandingPageOptionProps | null
): LandingPageAction => ({
  type: SET_LANDING_PAGE_TYPE,
  payload,
});
export const setLandingPageOutput = (payload: string): LandingPageAction => ({
  type: SET_LANDING_PAGE_OUTPUT,
  payload,
});
export const setLandingPageUrl = (payload: string): LandingPageAction => ({
  type: SET_LANDING_PAGE_URL,
  payload,
});
export const setRedirectOption = (payload: string): LandingPageAction => ({
  type: SET_REDIRECT_OPTION,
  payload,
});

// export const resetEmail = (): EmailAction => ({
//   type: RESET_EMAIL_STATE,
// });
