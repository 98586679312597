import React from 'react';
export const outlookText = () => (
  <div>
    <p>
      <span>Steps to deploy the Microsoft Cywareness report plugin:</span>
    </p>
    <ol>
      <li>
        <span>Go to your Microsoft Admin Center&nbsp;</span>
      </li>
      <li>
        <span>
          On the left side, in the sidebar, you should see &ldquo;settings&rdquo;. Click on the dropdown near
          &ldquo;settings&rdquo;.
        </span>
      </li>
      <li>
        <span>You should now see the &ldquo;integrated apps&rdquo;, click on that option</span>
      </li>
      <li>
        <span>
          Under &ldquo;integrated apps&rdquo;, above the table, you should see the &ldquo;Add-ins&rdquo; option, click
          that
        </span>
      </li>
      <li>
        <span>
          This page will show you all the addons you have in your Microsoft account. We want to deploy a new one. On the
          top left corner you should see the &ldquo;Deploy Add-in&rdquo; button, click on that
        </span>
      </li>
      <li>
        <span>Click on &ldquo;Next&rdquo;</span>
      </li>
      <li>
        <span>Choose the &ldquo;Upload custom apps&rdquo;</span>
      </li>
      <li>
        <span>Click on &ldquo;I have a URL for the manifest file&rdquo; and insert the following link: </span>
        <span>
          <br />
        </span>
        <a href="https://content.cywareness.io/cywareness_manifest_report.xml">
          <span>https://</span>
          <span>content.cywareness.io/cywareness_manifest_report.xml</span>
        </a>
      </li>
      <li>
        <span>Click on &ldquo;Upload&rdquo;</span>
      </li>
      <li>
        <span>&nbsp;Under &ldquo;Assigned users&rdquo; select &ldquo;Everyone&rdquo;</span>
      </li>
      <li>
        <span>&nbsp;Under &ldquo;Deployment method&rdquo; choose &ldquo;Fixed&rdquo;</span>
      </li>
      <li>
        <span>&nbsp;Click &ldquo;Deploy&rdquo;</span>
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      <span>The plugin should now be deployed to all the users in your organization in the next 24 hours.</span>
      <span>
        <br />
        <br />
      </span>
    </p>
    <p>
      <br />
      <br />
    </p>
  </div>
);
