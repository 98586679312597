import { Box, Button } from '@mui/material'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

type StepSubmitProps = {
  handleClose: () => void
  confirmHandler: () => void
  isSubmitDisabled?: boolean
  ExtraButton?: JSX.Element
  sx?: any
}

export const StepSubmit: FC<StepSubmitProps> = ({ handleClose, confirmHandler, isSubmitDisabled, ExtraButton, sx }) => {
  const { t } = useTranslation()
  return (
    <Box display="flex" width={1} justifyContent="flex-end" gap={1} marginTop={2} sx={sx}>
      {ExtraButton ?? null}
      <Button variant="outlined" onClick={handleClose}>
        {t('bulkUserUpload.cancel')}
      </Button>
      <Button variant="contained" disabled={isSubmitDisabled} onClick={confirmHandler}>
        {t('bulkUserUpload.continue')}
      </Button>
    </Box>
  )
}

export default StepSubmit
