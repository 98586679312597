import React, { useMemo } from "react";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import useForm, { Field } from "../../../common/components/Form/useForm";
import { string } from "yup";
import Form from "../../../common/components/Form/Form";
import { Button, CircularProgress, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCreateActiveDirectory } from "../../../api/active-directory/create-ad";
import useToast from "../../../common/hooks/useToast";

export default function AdCredentials() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [createActiveDirectory, { isLoading }] = useCreateActiveDirectory();
  const { errorToast } = useToast();

  const fields: Field[] = useMemo(
    () => [
      {
        name: "tenant",
        initialValue: "",
        label: "users.activeDirectory.tenant",
        validationRule: string().required(
          "users.activeDirectory.errors.missingTenant"
        ),
        type: "text",
        required: true,
        autoFocus: false,
        gridItemProps: {
          xs: 12,
        },
      },
      {
        name: "ad_client_id",
        initialValue: "",
        label: "users.activeDirectory.clientId",
        validationRule: string().required(
          "users.activeDirectory.errors.missingClient"
        ),
        type: "text",
        required: true,
        autoFocus: false,
        gridItemProps: {
          xs: 12,
        },
      },
      {
        name: "ad_client_secret",
        initialValue: "",
        label: "users.activeDirectory.secret",
        validationRule: string().required(
          "users.activeDirectory.errors.missingSecret"
        ),
        type: "text",
        required: true,
        autoFocus: false,
        gridItemProps: {
          xs: 12,
        },
      },
    ],
    []
  );

  const { formProps, handleSubmit } = useForm({
    fields,
    onSubmit: handleSumbit,
    clearOnSubmit: false,
  });

  async function handleSumbit(values) {
    try {
      await createActiveDirectory(values);
    } catch (e) {
      errorToast(t("users.activeDirectory.errors.failedToCreate"));
    }
  }
  return (
    <div className={classes.root}>
      <Typography variant={"subtitle2"} className={classes.text}>
        {t("users.activeDirectory.instructions")}
      </Typography>
      <Form {...formProps} />
      <Button
        className={classes.button}
        onClick={handleSubmit}
        variant={"contained"}
        color="primary"
        disabled={isLoading}
      >
        {isLoading ? (
          <CircularProgress color={"inherit"} size={24} />
        ) : (
          t("users.activeDirectory.connect")
        )}
      </Button>
    </div>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      maxWidth: 400,
      marginLeft: theme.spacing(4),
    },
    text: {
      marginBottom: theme.spacing(2),
    },
    button: {
      marginTop: theme.spacing(2),
      minWidth: 120,
    },
  })
);
