import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faAtom, faBriefcase, faUser, faUserGroup } from '@fortawesome/pro-light-svg-icons'
import { faXmarkCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, Chip, Divider, Grid, Paper, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { isEmpty } from 'lodash'
import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getDynamicGroupMembers } from '@/api/dynamic-groups/get'
import { EducationModule } from '@/api/education-modules/modules'
import { Group } from '@/api/groups/groups'
import { Member } from '@/api/members/get'
import { Office } from '@/api/offices/offices'
import { User } from '@/api/users/users'
import { Targets } from '@/types/campaigns'
import { DynamicGroup } from '@/types/dynamicGroups'
import { PhishingSimulation } from '@/types/phishingSimulations'
import LoadingContainer from '../LoadingContainer/LoadingContainer'
import MainTab from '../Tabs/MainTab'
import MainTabPage from '../Tabs/MainTabPage'
import MainTabs from '../Tabs/MainTabs'
import MainTabsWrapper from '../Tabs/MainTabsWrapper'
import LaunchWizardDynamicGroupsTable from './LaunchWizardDynamicGroupsTable'
import LaunchWizardGroupsTable from './LaunchWizardGroupsTable'
import LaunchWizardOfficesTable from './LaunchWizardOfficesTable'
import LaunchWizardUsersTable from './LaunchWizardUsersTable'
import { useClientDetails } from '@/api/client/client'

export type RecepientsType = 'groups' | 'users' | 'offices' | 'dynamic_groups'

type Recipient = {
  label: string
  type: RecepientsType
  _id: string
  userCount: number
  fullObject: User | Group | Office | DynamicGroup
}

type UserPickerProps = {
  setRecipients: (recipients: { groups: Group[]; offices: Office[]; users: User[] }) => void
  hideRecipientSummary?: boolean
  distributionTargets: Targets
  assets?: PhishingSimulation[] | EducationModule[]
  setDistributionTargets: (targets: Targets) => void
}

const UserPicker: FC<UserPickerProps> = ({
  setRecipients,
  hideRecipientSummary,
  distributionTargets,
  assets,
  setDistributionTargets,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const [currentTabIndex, setCurrentTabIndex] = useState(0)
  const [isLoadingRecipiants, setIsLoadingRecipiants] = useState(false)
  const [selectedRecipients, setSelectedRecipients] = useState<{
    [id: string]: Recipient
  }>({})

  const { data: clientDetails } = useClientDetails()

  const recipientIds = useMemo(() => {
    const idsByType: {
      groups: string[]
      dynamic_groups: string[]
      offices: string[]
      users: string[]
    } = {
      groups: [],
      dynamic_groups: [],
      offices: [],
      users: [],
    }
    Object.values(selectedRecipients).forEach((r) => {
      idsByType[r.type].push(r._id)
    })
    return idsByType
  }, [selectedRecipients])

  const selectedDynamicGroupsMembers = useMemo(() => {
    return Object.values(selectedRecipients)
      .map((r) => {
        if (r.type === 'dynamic_groups') {
          return (r.fullObject as DynamicGroup).users
        } else {
          return [r._id]
        }
      })
      .flat()
  }, [selectedRecipients])

  const recipientObjects = useMemo(() => {
    const recipientsByType: {
      groups: Group[]
      dynamic_groups: string[]
      offices: Office[]
      users: User[]
    } = {
      groups: [],
      dynamic_groups: [],
      offices: [],
      users: [],
    }
    Object.values(selectedRecipients).forEach((r) =>
      // @ts-ignore
      recipientsByType[r.type].push(r.fullObject)
    )
    return recipientsByType
  }, [selectedRecipients])

  const handleAddUsers = (userIds: string[], users: User[]) => {
    const usersObject: { [id: string]: Recipient } = {}
    users.forEach((user) => {
      usersObject[user._id] = {
        _id: user._id,
        type: 'users',
        label: `${user.first_name} ${user.last_name}`,
        userCount: 1,
        fullObject: user,
      }
    })
    setSelectedRecipients((prevState) => ({ ...prevState, ...usersObject }))
  }

  const handleAddGroups = (groupIds: string[], groups: Group[]) => {
    const groupsObject: { [id: string]: Recipient } = {}
    groups.forEach((group) => {
      if (group.user_count) {
        groupsObject[group._id] = {
          _id: group._id,
          type: 'groups',
          label: group.name,
          userCount: group.user_count,
          fullObject: group,
        }
      }
    })
    setSelectedRecipients((prevState) => ({ ...prevState, ...groupsObject }))
  }

  const handleAddDynamicGroups = async (groupIds: string[], groups: DynamicGroup[]) => {
    setIsLoadingRecipiants(true)
    const dynamicGroupsObject: { [id: string]: Recipient } = {}

    const promises = groups.map(async (group) => {
      const data = await getDynamicGroupMembers({
        id: group.id,
        params: { limit: group.count, skip: 0, sort_by: 'first_name', sort_order: 'desc' },
      })

      group.users = data?.results.map((user: Member) => user._id)

      dynamicGroupsObject[group.id] = {
        _id: group.id,
        type: 'dynamic_groups',
        label: group.name,
        userCount: group.count,
        fullObject: group,
      }
    })

    await Promise.all(promises)
    setSelectedRecipients((prevState) => ({ ...prevState, ...dynamicGroupsObject }))
    setIsLoadingRecipiants(false)
  }

  const handleAddOffices = (officesIds: string[], offices: Office[]) => {
    const officesObject: { [id: string]: Recipient } = {}
    offices.forEach((office) => {
      if (office.user_count) {
        officesObject[office._id] = {
          _id: office._id,
          type: 'offices',
          label: office.name,
          userCount: office?.user_count || 1,
          fullObject: office,
        }
      }
    })
    setSelectedRecipients((prevState) => ({ ...prevState, ...officesObject }))
  }

  const handleRemoveFromList = (_id: string | string[]) => {
    const selected = { ...selectedRecipients }
    if (Array.isArray(_id)) {
      _id.forEach((id) => {
        delete selected[id]
      })
    } else {
      delete selected[_id]
    }
    setSelectedRecipients(selected)
  }

  const handleDistributionTargetsChange = (type: RecepientsType, itemId: string, value: string) => {
    setDistributionTargets((prevState) => {
      if (value === 'single_random') {
        delete prevState[type][itemId]
      } else {
        prevState[type][itemId] = value
      }

      return prevState
    })
  }

  const getSelectedRecipientCount = () => {
    // Build nested array with all user ids by returing users array for groups and offices and _id for users
    // Flatten array
    // Filter out duplicates by checking the index of the first occurrence of each value

    //some counted twice
    // const dynamicRecipientsCount = Object.values(selectedRecipients)
    //   .map((item) => item.fullObject.count || 0)
    //   .reduce((a, b) => a + b, 0)
    const selectedRecipientsCount = Object.values(selectedRecipients)
      .map((item) => (item.fullObject && 'users' in item.fullObject ? item.fullObject.users : [item.fullObject._id]))
      .flat()
      .filter((value, index, array) => array.indexOf(value) === index).length

    return selectedRecipientsCount
  }

  useEffect(() => {
    setRecipients(recipientObjects)
  }, [recipientObjects])

  return (
    <div className={classes.root}>
      <MainTabsWrapper>
        <MainTabs
          value={currentTabIndex}
          onChange={(e, tab) => {
            setCurrentTabIndex(tab)
          }}>
          <MainTab
            label={t('launchWizard.userPicker.groups')}
            icon={
              <Box>
                <FontAwesomeIcon icon={faUserGroup as IconProp} fontSize={18} />
              </Box>
            }
            iconPosition="start"
          />
          <MainTab
            disableRipple
            label={t('launchWizard.userPicker.dynamicGroups')}
            iconPosition="start"
            icon={<FontAwesomeIcon icon={faAtom as IconProp} fontSize={18} color={theme.palette.purple[500]} />}
          />
          <MainTab
            disableRipple
            label={t('launchWizard.userPicker.offices')}
            iconPosition="start"
            icon={<FontAwesomeIcon icon={faBriefcase as IconProp} fontSize={18} />}
          />
          <MainTab
            disableRipple
            label={t('launchWizard.userPicker.users')}
            iconPosition="start"
            icon={<FontAwesomeIcon icon={faUser as IconProp} fontSize={18} />}
          />
        </MainTabs>
      </MainTabsWrapper>
      <MainTabPage className={classes.mainTabPage}>
        <Grid container spacing={2}>
          <Grid item xs={8} xl={9}>
            {currentTabIndex === 0 && (
              <LaunchWizardGroupsTable
                handleAddGroups={handleAddGroups}
                handleRemoveFromList={handleRemoveFromList}
                handleDistributionTargetsChange={handleDistributionTargetsChange}
                distributionTargets={distributionTargets}
                selectedGroups={recipientIds.groups}
                assets={assets}
                clientEnforceDomain={!clientDetails?.allow_unverified_domains}
              />
            )}
            {currentTabIndex === 1 && (
              <LaunchWizardDynamicGroupsTable
                handleAddDynamicGroups={handleAddDynamicGroups}
                handleRemoveFromList={handleRemoveFromList}
                handleDistributionTargetsChange={handleDistributionTargetsChange}
                distributionTargets={distributionTargets}
                assets={assets}
                clientEnforceDomain={!clientDetails?.allow_unverified_domains}
                selectedDynamicGroups={recipientIds.dynamic_groups}
              />
            )}
            {currentTabIndex === 2 && (
              <LaunchWizardOfficesTable
                handleAddOffices={handleAddOffices}
                handleRemoveFromList={handleRemoveFromList}
                handleDistributionTargetsChange={handleDistributionTargetsChange}
                distributionTargets={distributionTargets}
                selectedOffices={recipientIds.offices}
                assets={assets}
                clientEnforceDomain={!clientDetails?.allow_unverified_domains}
              />
            )}
            {currentTabIndex === 3 && (
              <LaunchWizardUsersTable
                handleAddUsers={handleAddUsers}
                handleRemoveFromList={handleRemoveFromList}
                handleDistributionTargetsChange={handleDistributionTargetsChange}
                distributionTargets={distributionTargets}
                selectedUsers={recipientIds.users}
                selectedOffices={recipientIds.offices}
                selectedGroups={recipientIds.groups}
                selectedDynamicGroupsMembers={selectedDynamicGroupsMembers as string[]}
                assets={assets}
                clientEnforceDomain={!clientDetails?.allow_unverified_domains}
              />
            )}
          </Grid>

          <Grid item xs={4} xl={3}>
            {!hideRecipientSummary && (
              <Paper className={classes.paper}>
                <div className={classes.chipContainerHeader}>
                  <Typography variant="h6" style={{ fontWeight: 500, color: theme.palette.cyan[500] }}>
                    {' '}
                    {t('launchWizard.details.recipients')}
                  </Typography>
                  <Button
                    disabled={isEmpty(selectedRecipients)}
                    variant={'text'}
                    onClick={() => setSelectedRecipients({})}
                    color="secondary">
                    {t('launchWizard.userPicker.clear')}
                  </Button>
                </div>
                <div className={classes.paperBody}>
                  <div className={classes.chipContainer}>
                    {isLoadingRecipiants ? (
                      <LoadingContainer />
                    ) : (
                      Object.values(selectedRecipients).map((data) => {
                        return (
                          <Chip
                            size="small"
                            key={data._id}
                            label={data.label}
                            onDelete={() => handleRemoveFromList(data._id)}
                            deleteIcon={<FontAwesomeIcon icon={faXmarkCircle as IconProp} />}
                          />
                        )
                      })
                    )}
                  </div>
                </div>

                <div className={classes.counterContainer}>
                  <Divider className={classes.divider} />

                  <div className={classes.counterItem}>
                    <FontAwesomeIcon icon={faUser as IconProp} className={classes.counterIcon} width={20} />
                    <div className={classes.spaceBetween}>
                      <Typography>{t('launchWizard.details.users')}</Typography>
                      <Typography>
                        {Object.values(selectedRecipients).filter((i) => i.type === 'users').length}
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.counterItem}>
                    <FontAwesomeIcon icon={faUserGroup as IconProp} className={classes.counterIcon} width={20} />
                    <div className={classes.spaceBetween}>
                      <Typography>{t('launchWizard.details.groups')}</Typography>
                      <Typography>
                        {Object.values(selectedRecipients).filter((i) => i.type === 'groups').length}
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.counterItem}>
                    <FontAwesomeIcon
                      icon={faAtom as IconProp}
                      className={classes.counterIcon}
                      color={theme.palette.purple[500]}
                      width={18}
                      fontSize={20}
                    />
                    <div className={classes.spaceBetween}>
                      <Typography>{t('launchWizard.details.dynamicGroups')}</Typography>
                      <Typography>
                        {Object.values(selectedRecipients).filter((i) => i.type === 'dynamic_groups').length}
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.counterItem}>
                    <FontAwesomeIcon icon={faBriefcase as IconProp} className={classes.counterIcon} width={20} />
                    <div className={classes.spaceBetween}>
                      <Typography>{t('launchWizard.details.offices')}</Typography>
                      <Typography>
                        {Object.values(selectedRecipients).filter((i) => i.type === 'offices').length}
                      </Typography>
                    </div>
                  </div>
                  <Divider className={classes.divider} />
                  <div className={classes.spaceBetween}>
                    <Typography>{t('launchWizard.details.totalUsers')}</Typography>
                    <Typography>{getSelectedRecipientCount()}</Typography>
                  </div>
                </div>
              </Paper>
            )}
          </Grid>
        </Grid>
      </MainTabPage>
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: theme.spacing(-3.5, 0, 3.5, 3.5),
    },
    tabs: { marginBottom: theme.spacing(2) },
    paper: {
      minHeight: 400,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },
    paperBody: { flex: '1 1 auto' },
    chipContainerHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      background: theme.palette.blueGray[900],
      padding: theme.spacing(2, 0, 2, 2),
      color: theme.palette.common.white,
      textAlign: 'right',
    },

    chipContainer: {
      display: 'flex',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
      flex: '0 1 auto',
      padding: theme.spacing(0.5),
      margin: 0,
    },
    counterContainer: {
      width: '100%',
      padding: theme.spacing(0, 1, 2),
      justifySelf: 'end',
    },
    divider: {
      marginBottom: theme.spacing(1),
    },
    counterItem: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(1),
    },
    counterIcon: {
      margin: theme.spacing(0, 1),
    },
    spaceBetween: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    mainTabPage: {
      padding: '15px',
      minWidth: '680px',
    },
  })
)

export default UserPicker
