import axios from 'axios';
import { API_URL } from '../constants';
import { useMutation, useQueryCache } from 'react-query';
import { ServerError } from '../types';
import { Office } from './offices';
import { NewOfficeParams } from './new-office';
import { serializeArray, Serialized } from '../utils/serialize-array';

export type EditOfficeParams = {
  _id: string;
  params: NewOfficeParams;
};

export async function editOffice({ _id, params }: EditOfficeParams): Promise<Office> {
  const url = `${API_URL}/offices/edit/${_id}`;
  const { data } = await axios.post(url, params, { withCredentials: true });
  return data.data;
}

export function useEditOffice() {
  const queryCache = useQueryCache();
  return useMutation<Office, ServerError, EditOfficeParams>(editOffice, {
    throwOnError: true,
    onMutate: ({ _id, params }) => {
      queryCache.cancelQueries('offices');
      const previousOffices = (queryCache.getQueryData('offices') as Serialized<Office>) || { array: [], byId: {} };
      const officeIndex = previousOffices.array.findIndex((office) => office._id === _id);
      const updatedOffices = [...previousOffices.array];
      updatedOffices[officeIndex] = { ...updatedOffices[officeIndex], ...params };
      queryCache.setQueryData('offices', () => serializeArray(updatedOffices));
      return () => queryCache.setQueryData('offices', previousOffices);
    },
    //@ts-ignore
    onError: (err, variables, rollback) => rollback(),
    onSettled: (response, error, variables) => {
      queryCache.invalidateQueries('offices');
      queryCache.invalidateQueries(['office', variables._id]);
    },
  });
}
