import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Paper, Typography } from "@mui/material";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { useTranslation } from "react-i18next";

type SummaryProps = {
  totalQuestions: number;
  correctAnswers: number;
};

export default function Summary({
  totalQuestions = 1,
  correctAnswers = 0,
}: SummaryProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const percentage = Math.ceil((correctAnswers / totalQuestions) * 100);

  return (
    <div className={classes.root}>
      <Typography variant={"h5"} gutterBottom className={classes.title}>
        {t("quizPage.results")}
      </Typography>
      <div className={classes.chartContainer}>
        <CircularProgressbarWithChildren
          strokeWidth={20}
          value={percentage}
          styles={{
            // Customize the root svg element
            root: {},
            // Customize the path, i.e. the "completed progress"
            path: {
              // Path color
              stroke: "#41c5c9",
              // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
              strokeLinecap: "butt",
              // Customize transition animation
              transition: "stroke-dashoffset 0.5s ease 0s",
              // Rotate the path
            },
            // Customize the circle behind the path, i.e. the "total progress"
            trail: {
              // Trail color
              stroke: "#D8D8D8",
              // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
              strokeLinecap: "butt",
              // Rotate the trail
              transform: "rotate(0.625turn)",
              transformOrigin: "center center",
            },
            // Customize the text
            text: {
              // Text color
              fill: "#f88",
              // Text size
              fontSize: "16px",
            },
            // Customize background - only used when the `background` prop is true
            background: {
              fill: "#3e98c7",
            },
          }}
        >
          <Paper elevation={4} className={classes.textContainer}>
            <Typography
              variant={"h4"}
              color={"textPrimary"}
              style={{ fontWeight: 700, textAlign: "center", marginLeft: 16 }}
            >
              {percentage}%
            </Typography>
            <Typography
              variant={"subtitle1"}
              color={"textPrimary"}
              style={{ fontWeight: 700, textAlign: "center", lineHeight: 1.2 }}
            >
              {t(
                correctAnswers === 1
                  ? "quizPage.correctAnswers"
                  : "quizPage.correctAnswers_plural",
                {
                  value: correctAnswers,
                }
              )}
            </Typography>
          </Paper>
        </CircularProgressbarWithChildren>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      overflow: "visible",
    },
    textContainer: {
      color: theme.palette.common.white,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: 700,
      width: "75%",
      height: "75%",
      borderRadius: "50%",
    },
    chartContainer: {
      height: 250,
      width: 250,
    },
    title: {
      marginBottom: theme.spacing(2),
    },
  })
);
