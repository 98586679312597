import {
  RESET_SUMMARY_PAGE,
  SET_SIMULATION_NAME,
  SET_TOPICS,
  SummaryPageAction,
  SummaryPageState,
} from "./Types";
import { initialSummaryPageState } from "../Context";

export function summaryPageReducer(
  state: SummaryPageState,
  action: SummaryPageAction
): SummaryPageState {
  switch (action.type) {
    case SET_TOPICS:
      return { ...state, topics: action.payload };

    case SET_SIMULATION_NAME:
      return { ...state, simulationName: action.payload };

    case RESET_SUMMARY_PAGE:
      return initialSummaryPageState;

    default:
      return state;
  }
}
