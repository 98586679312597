import { Box, Divider, Typography, styled } from '@mui/material'
import { FC } from 'react'

import { theme } from '@/theme/theme'

type MainPlanCardProps = {
  title: string
  price: string
  perCampaign?: boolean
  perMember?: boolean
  preYear?: boolean
  limited?: boolean
  subTitle: string
  description: string
  colored?: boolean
}
const MainPlanCard: FC<MainPlanCardProps> = ({
  title,
  price,
  perCampaign,
  perMember,
  preYear,
  limited,
  subTitle,
  description,
  colored,
}) => {
  return (
    <Box
      sx={{
        border: `2px solid ${colored ? theme.palette.cyan[500] : theme.palette.blueDianne[900]}`,
        background: colored ? theme.palette.cyan[50] : theme.palette.white,
      }}
      borderRadius={'15px'}
      margin={1}
      padding={theme.spacing(3, 2, 8)}
      display="flex"
      flexDirection="column"
      alignItems="center">
      <Typography fontSize={31} fontWeight={700}>
        {title}
      </Typography>
      <Typography fontSize={20} fontWeight={400}>
        {limited ? 'LIMITED' : 'UNLIMITED'}
      </Typography>
      <Box display="flex" gap={2} marginTop={3}>
        <Typography fontSize={46} fontWeight={400}>
          $
        </Typography>
        <Typography fontSize={76} fontWeight={700} marginTop={-4}>
          {price}
        </Typography>
        <Typography fontSize={12} fontWeight={400}>
          {perCampaign && (
            <>
              per campaign / <br />
            </>
          )}
          {perMember && (
            <>
              per member / <br />
            </>
          )}
          {preYear && <>per year</>}
        </Typography>
      </Box>
      <Typography
        fontSize={12}
        fontWeight={700}
        sx={{
          background: colored ? theme.palette.blueDianne[900] : 'transparent',
          color: colored ? theme.palette.cyan[500] : theme.palette.blueDianne[900],
        }}
        padding={1}
        borderRadius={2}>
        {subTitle}
      </Typography>
      <CustomDivider
        sx={{
          width: '100%',
          marginTop: 6,
          marginBottom: 2,
        }}
      />
      <Typography fontSize={20} fontWeight={600} textAlign="center" width={350}>
        {description}
      </Typography>
    </Box>
  )
}

const CustomDivider = styled(Divider)(() => ({
  borderColor: theme.palette.cyan[500],
  '&::before': {
    borderTop: `thin solid ${theme.palette.cyan[500]}`,
  },
  '&::after': {
    borderColor: theme.palette.cyan[500],
  },
  textAlign: 'center',
}))
export default MainPlanCard
