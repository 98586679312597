import { byPrefixAndName } from '@awesome.me/kit-39de79544e/icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  styled,
  Typography,
} from '@mui/material'
import { Form, Formik } from 'formik'
import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
//@ts-ignore
import * as Yup from 'yup'

import { useDisableAdmin } from '@/api/admins/disable-admin'
import { useEditAdminRole } from '@/api/admins/edit-role'
import { useEnableAdmin } from '@/api/admins/enable-admin'
import { useLogout } from '@/api/auth/logout'
import { PHONE_NUMNER_E164_REGEX } from '@/api/constants'
import { CurrentUser } from '@/api/profile/current-user'
import { Profile, useEditProfile } from '@/api/profile/edit'
import { getErrorMessage } from '@/api/utils/get-error'
import PhoneNumberField from '@/common/components/Inputs/PhoneNumberField/index.'
import TextField from '@/common/components/Inputs/TextFieldV2'
import useToast from '@/common/hooks/useToast'
import { theme } from '@/theme/theme'
import { Admin, Role, Status } from '@/types/admins'
import AdminStatusPopup from '../AdminStatusPopup'
import EmailConfirmModal from '../EmailConfirmModal'
import { StyledButtonsWraper, StyledTitle } from '.'

const AdminProfileDetailsSection: FC<{ adminData: Admin; user: CurrentUser | undefined }> = ({ adminData, user }) => {
  const { successToast, errorToast } = useToast()
  const { mutateAsync: enableAdmin } = useEnableAdmin()
  const { mutateAsync: disableAdmin } = useDisableAdmin()
  const { t } = useTranslation()
  const [emailConfirmModalState, setEmailConfirmModalState] = useState<Partial<Profile> & { old_email: string }>() //Partial profule & {old_email:string}
  const [emailConfirmModalOpen, setEmailConfirmModalOpen] = useState(false)
  const { mutateAsync: editAdminRole } = useEditAdminRole()
  const [disableWarningModalOpen, setDisableWarningModalOpen] = useState(false)
  const navigateTo = useNavigate()
  const [logout] = useLogout()

  const { mutateAsync: editProfile } = useEditProfile()
  const isCurrnetUser = user?.id === adminData?._id

  const memberValidationSchema = Yup.object().shape(
    isCurrnetUser
      ? {
          full_name: Yup.string().required('Name is required'),
          username: Yup.string().email('Invalid email').required('Email is required'),
          phone_number: Yup.string()
            .matches(PHONE_NUMNER_E164_REGEX, 'Invalid phone number')
            .required('Phone number is required'),
        }
      : {}
  )

  const handleEmailChangeConfirm = async (profilePayload: any) => {
    if (!adminData?._id) return

    try {
      await editProfile(profilePayload)

      await logout()
      navigateTo('/login')

      setEmailConfirmModalOpen(false)
      successToast(t(`settings.admins.profile.profileUpdated`))
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      errorToast(errorMessage || t(`settings.admins.profile.failedToUpdate`))
    }
  }

  const handleEnableAdmin = useCallback(
    async (admin: Admin) => {
      try {
        await enableAdmin(admin._id)
      } catch (e) {
        errorToast(t('settings.admins.table.errors.failedToEnable'))
      }
    },
    [enableAdmin, successToast, errorToast, t]
  )

  const handleDisableAdmin = useCallback(async () => {
    try {
      await disableAdmin(adminData?._id || '')
    } catch (e) {
      errorToast(t('settings.admins.table.errors.failedToDisable'))
    }
  }, [disableAdmin, successToast, errorToast, t])

  const handleSubmitAdminDetails = async (values: any) => {
    if (!adminData?._id) return
    if (isCurrnetUser) {
      if (values.state !== adminData.status) {
        if (values.state === Status.enabled) {
          handleEnableAdmin(adminData)
        } else {
          handleDisableAdmin()
        }
      }

      if (user && values.username && values.username !== user.username) {
        setEmailConfirmModalState({
          full_name: values.full_name,
          email: values.username,
          phone_number: values.phone_number,
          old_email: user.username || '',
        })
        setEmailConfirmModalOpen(true)
        return
      }
    }
    try {
      const adminPayload = {
        email: values.username,
        ...values,
      }
      if (values.role !== adminData.role) {
        await editAdminRole({ id: adminData._id, role: values.role })
      }
      // TODO Replace CurrentUser with ProfileRequest type
      if (isCurrnetUser) await editProfile(adminPayload as CurrentUser)

      successToast(t(`settings.admins.profile.profileUpdated`))
    } catch (e) {
      errorToast(t(`settings.admins.profile.failedToUpdate`))
    }
  }

  return (
    <>
      <Formik
        onSubmit={handleSubmitAdminDetails}
        initialValues={{
          full_name: adminData.full_name,
          username: adminData.username,
          phone_number: adminData.phone_number || '',
          state: adminData.status,
          role: adminData.role,
        }}
        validationSchema={memberValidationSchema}>
        {({ isSubmitting, setFieldValue, values }) => (
          <Form>
            <Box display="flex" flexWrap="wrap">
              <Box display="flex" width="920px" gap={theme.spacing(6)}>
                <Box width={'100%'}>
                  <StyledTitle>{t(`settings.admins.profile.adminDetails`)}</StyledTitle>
                  <Box width="100%" padding={theme.spacing(2, 0, 0, 2)} display="flex" flexDirection="column">
                    <Box width="100%">
                      <TextField label="Name" name="full_name" disabled={!isCurrnetUser} isLocked={!isCurrnetUser} />
                    </Box>
                    <Box width="100%">
                      <TextField label="Email" name="username" disabled={!isCurrnetUser} isLocked={!isCurrnetUser} />
                    </Box>
                    <Box width="100%">
                      <PhoneNumberField
                        name="phone_number"
                        label="Phone Number*"
                        disabled={!isCurrnetUser}
                        isLocked={!isCurrnetUser}
                      />
                    </Box>
                  </Box>{' '}
                  <Box display="flex" alignItems="center" padding={theme.spacing(1, 2.5)}>
                    <Typography fontWeight={theme.typography.fontWeightMedium} noWrap minWidth={106}>
                      {t(`settings.admins.profile.adminState`)}
                    </Typography>
                    <RadioGroup
                      row
                      sx={{ padding: theme.spacing(0, 1) }}
                      key="admin_state"
                      value={values.state}
                      onChange={() => {
                        if (values.state === Status.enabled) {
                          setFieldValue('state', Status.disabled)
                          setDisableWarningModalOpen(true)
                        } else {
                          setFieldValue('state', Status.enabled)
                        }
                      }}>
                      <FormControlLabel
                        value={Status.enabled}
                        control={<Radio disabled={!isCurrnetUser} />}
                        label={t(`settings.admins.profile.active`)}
                      />
                      <FormControlLabel
                        value={Status.disabled}
                        control={<Radio disabled={!isCurrnetUser} />}
                        label={t(`settings.admins.profile.inactive`)}
                      />
                    </RadioGroup>
                  </Box>
                </Box>

                <Box width={'100%'}>
                  <StyledTitle>{t(`settings.admins.profile.permissions`)}</StyledTitle>
                  <StyledFormControl fullWidth size="small" variant="outlined">
                    <InputLabel htmlFor="style-select">{t(`settings.admins.profile.role`)}</InputLabel>
                    <Select
                      disabled={user?.role !== Role.support}
                      size="small"
                      value={values.role}
                      name="role"
                      placeholder={t('settings.configurationTab.organizationSettings.organizationOrigin')}
                      onChange={(value) => {
                        setFieldValue('role', value.target.value)
                      }}
                      input={<StyledOutlinedInput label={t(`settings.admins.profile.role`)} />}>
                      [
                      <MenuItem value={Role.admin}>
                        <Box display="flex" alignItems="center" gap={1}>
                          <FontAwesomeIcon
                            icon={byPrefixAndName.fak['light-earth-americas-user'] as IconProp}></FontAwesomeIcon>
                          <Typography>{t(`settings.admins.profile.admin`)}</Typography>
                        </Box>
                      </MenuItem>
                      ,
                      <MenuItem value={Role.support}>
                        <Box display="flex" alignItems="center" gap={1}>
                          <FontAwesomeIcon
                            icon={byPrefixAndName.fak['light-screwdriver-wrench-user'] as IconProp}></FontAwesomeIcon>
                          <Typography>{t(`settings.admins.profile.supportAdmin`)}</Typography>
                        </Box>
                      </MenuItem>
                      ]
                    </Select>
                  </StyledFormControl>
                  <StyledPermissionDescriptionContainer>
                    <FontAwesomeIcon fontSize={30} icon={faCircleInfo} />
                    <Box>
                      <Typography fontWeight={theme.typography.fontWeightMedium} paddingBottom={1}>
                        {t(`settings.admins.profile.permissionDescriptionTitle`)}
                      </Typography>
                      <Typography>{t(`settings.admins.profile.${values.role}Description`)}</Typography>
                    </Box>
                  </StyledPermissionDescriptionContainer>
                </Box>
              </Box>

              <StyledButtonsWraper>
                <LoadingButton type="submit" loading={isSubmitting} variant="contained">
                  {t(`settings.admins.profile.saveChanges`)}
                </LoadingButton>
              </StyledButtonsWraper>
            </Box>
          </Form>
        )}
      </Formik>
      <AdminStatusPopup
        open={disableWarningModalOpen}
        onClose={() => setDisableWarningModalOpen(false)}
        onConfirm={() => setDisableWarningModalOpen(false)}
        admin={adminData}
      />
      <EmailConfirmModal
        title={t('settings.admins.emailConfirmModal.title')}
        open={emailConfirmModalOpen}
        onClose={() => setEmailConfirmModalOpen(false)}
        onConfirm={handleEmailChangeConfirm}
        state={emailConfirmModalState}
      />
    </>
  )
}

const StyledPermissionDescriptionContainer = styled(Box)(() => ({
  background: theme.palette.blue[50],
  display: 'flex',
  padding: theme.spacing(2),
  margin: theme.spacing(2, 2, 0, 0),
  border: `1px solid ${theme.palette.grey[400]}`,
  borderRadius: '10px',
  alignItems: 'center',
  gap: theme.spacing(2),
}))
const StyledFormControl = styled(FormControl)(() => ({
  paddingRight: theme.spacing(2),
  marginTop: theme.spacing(2),
  label: {
    fontSize: '12px',
    padding: '2px 8px',
    margin: '1px 0 0',
  },
}))
const StyledOutlinedInput = styled(OutlinedInput)(() => ({
  '& MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  fieldset: {
    borderRadius: '10px',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderWidth: '0.5px',
  },
  '&:hover fieldset': {
    borderWidth: '0.5px',
  },
}))

export default AdminProfileDetailsSection
