import axios from 'axios'
import { API_URL } from '../constants'
import { useQuery } from 'react-query'
import { ServerError } from '../types'
import { EducationModule } from './modules'

export async function getEducationModuleById(key: string, _id: string) {
  if (_id) {
    const url = `${API_URL}/education_modules/${_id}`
    const { data } = await axios.get(url, { withCredentials: true })
    return data.data
  } else {
    return undefined
  }
}

export function useEducationModuleById(_id: string | undefined, options?: any) {
  return useQuery<EducationModule, ServerError>(['modules', _id], getEducationModuleById, {
    refetchOnWindowFocus: false,
    ...options,
  })
}
