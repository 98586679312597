import axios from 'axios'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useQueryCache } from 'react-query'
import { ServerError } from '../types'
import { API_URL } from '../constants'

type CreateMembersOfficeRequest = {
  ids: string[]
  office: string
}

type CreateMembersOfficeResponse = {
  message: boolean
}

const createMembersOffice = async (values: CreateMembersOfficeRequest) => {
  const url = `${API_URL}/members/office/`
  const { data } = await axios.put<CreateMembersOfficeResponse>(url, values, {
    withCredentials: true,
  })

  return data
}

const useCreateMembersOffices = () => {
  const queryClient = useQueryClient()
  const queryCache = useQueryCache()

  return useMutation<CreateMembersOfficeResponse, ServerError, CreateMembersOfficeRequest>({
    mutationFn: createMembersOffice,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['members'] })
      queryCache.invalidateQueries('offices')
    },
    throwOnError: true,
  })
}

export default useCreateMembersOffices
