import { useEffect, useMemo, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { ResponsiveCalendar } from '@nivo/calendar'
import moment from 'moment'
import { Typography, Tabs, Tab, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { User } from '@/api/users/users'
import { uniq } from 'lodash'
import { useMemberStatsById } from '@/api/members/getMemberStats'

type EventTypes = {
  email_open: number
  sms_link_click: number
  email_link_click: number
  submit: number
  reported: number
  feedback_link_click: number
}

type EngagementChartProps = {
  id: string
}

function getEventScore(events: EventTypes) {
  if (events.submit > 0) {
    return 4
  } else if (events.email_link_click > 0 || events.sms_link_click > 0) {
    return 3
  } else if (events.email_open > 0) {
    return 2
  } else {
    return 1
  }
}

export default function EngagementChart({ id }: EngagementChartProps) {
  const theme = useTheme()
  const classes = useStyles()
  const { t } = useTranslation()
  const [currentTab, setCurrentTab] = useState(0)
  const [engagementYears, setEngagementYears] = useState<number[]>([])
  const [campaignYears, setCampaignYears] = useState<number[]>([])
  const [displayedYear, setDisplayedYear] = useState<number>(moment().year())
  const [displayCampaigns, setDisplayCampaigns] = useState(true)
  const { data: memberStats } = useMemberStatsById({ id: id })

  useEffect(() => {
    const engagement = uniq(memberStats?.engagement.map((item) => parseInt(item.day.split('-')[0])).sort())
    if (!engagement.includes(moment().year())) {
      engagement.push(moment().year())
    }
    const campaigns = uniq(memberStats?.campaigns.map((item) => parseInt(item.day.split('-')[0])).sort())
    if (!campaigns.includes(moment().year())) {
      campaigns.push(moment().year())
    }
    setEngagementYears(engagement.length > 3 ? engagement.slice(engagementYears.length - 3) : engagement)
    setCampaignYears(campaigns.length > 3 ? campaigns.slice(campaigns.length - 3) : campaigns)
  }, [memberStats?.engagement, memberStats?.campaigns, engagementYears.length])

  const formattedCampaignsData = useMemo(() => {
    return memberStats?.campaigns.map((campaign) => {
      return { ...campaign, value: getEventScore(campaign.events) }
    })
  }, [memberStats?.campaigns])

  function handleSwitchYear(year: number) {
    setDisplayedYear(year)
  }

  function handleTabChange(e, tab) {
    setCurrentTab(tab)
    setDisplayCampaigns(tab === 0)
  }

  const startOfYear = moment().year(displayedYear).startOf('year').format('YYYY-MM-DD')
  const endOfYear = moment().year(displayedYear).endOf('year').format('YYYY-MM-DD')

  function colorScaleFn(value: number) {
    const colors = displayCampaigns
      ? [
          theme.palette.success.light,
          theme.palette.success.main,
          theme.palette.warning.light,
          theme.palette.warning.main,
          theme.palette.error.main,
        ]
      : [
          theme.palette.warning.main,
          theme.palette.warning.light,
          theme.palette.success.light,
          theme.palette.success.main,
        ]
    return colors[value] || colors[colors.length - 1]
  }

  return (
    <>
      <div className={classes.header}>
        <Tabs className={classes.tabs} value={currentTab} textColor="inherit" onChange={handleTabChange}>
          <Tab label={t('userProfile.campaigns')} />
          <Tab label={t('userProfile.engagement')} />
        </Tabs>
        <div className={classes.chipsContainer}>
          {(displayCampaigns ? campaignYears : engagementYears).map((year) => (
            <Button size="small" onClick={() => handleSwitchYear(year)}>
              {year}
            </Button>
          ))}
        </div>
      </div>
      {
        //@ts-ignore
        <ResponsiveCalendar
          data={displayCampaigns ? formattedCampaignsData : memberStats?.engagement}
          from={startOfYear}
          to={endOfYear}
          emptyColor={theme.palette.grey['300']}
          colorScale={colorScaleFn}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          yearSpacing={40}
          monthBorderColor="#ffffff"
          dayBorderWidth={2}
          dayBorderColor="#ffffff"
          onClick={(point) => {
            // console.log(point);
          }}
          //@ts-ignore
          tooltip={(point: { date: string; value: number; day: string; data: User['campaigns'][0] }) => {
            return (
              <div className={classes.tooltip}>
                <Typography variant={'subtitle2'}>{moment(point.day, 'YYYY-MM-DD').format('MMM DD')}</Typography>
                <Typography variant={'subtitle2'}>
                  {t(displayCampaigns ? 'userProfile.campaigns' : 'userProfile.report', {
                    count: point.data.counter ?? 0,
                  })}
                  : {point.data.counter ?? 0}
                </Typography>
                {!displayCampaigns ? null : (
                  <>
                    <Typography variant={'subtitle2'}>
                      {t('userProfile.opened')}: {point?.data?.events?.email_open ?? 0}
                    </Typography>
                    <Typography variant={'subtitle2'}>
                      {t('userProfile.clicked')}:{' '}
                      {(point?.data?.events?.sms_link_click || 0) + (point?.data?.events?.email_link_click || 0) ?? 0}
                    </Typography>
                    <Typography variant={'subtitle2'}>
                      {t('userProfile.submited')}: {point?.data?.events?.submit ?? 0}
                    </Typography>
                  </>
                )}
              </div>
            )
          }}
        />
      }
    </>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    tooltip: {
      background: theme.palette.background.secondaryGradient,
      minWidth: 160,
      borderRadius: 16,
      display: 'flex',
      flexDirection: 'column',
      flex: '1 1 auto',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      padding: theme.spacing(1, 2),
      marginBottom: theme.spacing(1),
      color: theme.palette.common.white,
      fontWeight: 700,
      boxShadow: theme.shadows[6],
      boxSizing: 'border-box',
      border: `1px solid rgba(255,255,255,0.6)`,
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    chipsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    tabs: {
      marginTop: theme.spacing(-1),
    },
  })
)
