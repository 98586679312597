import { useTranslation } from 'react-i18next'
import { ReactNode, FC, useState, memo, useEffect } from 'react'
import { Box, Modal, Button, Typography, CircularProgress, styled } from '@mui/material'
import { faAnglesUpDown, faExpand, faMinus, faX, faPaperPlane } from '@fortawesome/pro-light-svg-icons'
import { faRotateForward } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import MonitorImage from '@/assets/images/PC-Simulation/pc-screen.png'
import hookBackground from '@/assets/images/PC-Simulation/hook-background.gif'
import cywailLogo from '@/assets/images/PC-Simulation/cywail-logo.svg'
import cymagnifyLogo from '@/assets/images/PC-Simulation/cymagnify-logo.svg'
import { theme } from '@/theme/theme'

type MonitorPreviewProps = {
  isLoading: boolean
  hasContent: boolean
  children: ReactNode
  onFullscreenToggle: (isFullscreen: boolean) => void
  pageType: 'email' | 'landing' | 'micro-training'
  subject?: string
  onSubjectRefresh?: () => void
  subjectLoading?: boolean
}

const MonitorPreview: FC<MonitorPreviewProps> = ({
  children,
  pageType,
  isLoading = false,
  onFullscreenToggle = () => {},
  subject,
  onSubjectRefresh,
  subjectLoading,
}) => {
  const { t } = useTranslation()
  const [isFullscreen, setIsFullscreen] = useState(false)

  const handleFullscreenOpen = () => {
    setIsFullscreen(true)
    onFullscreenToggle(true)
  }

  const handleFullscreenClose = () => {
    setIsFullscreen(false)
    onFullscreenToggle(false)
  }

  const handleEsc = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      handleFullscreenClose()
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleEsc)
    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [handleEsc])

  const WindowComponent: FC<{ isFullscreen?: boolean }> = ({ isFullscreen = false }) => (
    <WindowBoxStyled centerd={isFullscreen}>
      <TopBarBoxStyled>
        {pageType === 'email' && (
          <Box component="img" alt="cywail Logo" height="100%" padding="0.5% 2%" src={cywailLogo} />
        )}
        {pageType === 'landing' && (
          <FontAwesomeIcon
            icon={faPaperPlane as IconProp}
            fontSize={isFullscreen ? '100%' : '80%'}
            style={{ padding: '0.5% 2%' }}
            transform={{ rotate: 35 }}
          ></FontAwesomeIcon>
        )}
        {pageType === 'micro-training' && (
          <Box component="img" alt="cymagnify Logo" height="100%" padding="0.5% 2%" src={cymagnifyLogo} />
        )}
        <Box paddingRight="3%">
          <TopBarIconsStyled
            icon={faMinus as IconProp}
            fontSize={isFullscreen ? '70%' : '40%'}
            isFullscreen={isFullscreen}
          />
          <TopBarIconsStyled
            icon={faAnglesUpDown as IconProp}
            fontSize={isFullscreen ? '70%' : '40%'}
            isFullscreen={isFullscreen}
          />
          <TopBarIconsStyled
            icon={faX as IconProp}
            fontSize={isFullscreen ? '70%' : '40%'}
            isFullscreen={isFullscreen}
          />
        </Box>
      </TopBarBoxStyled>
      {pageType === 'email' && (
        <InnerBarBoxStyled>
          <Box style={{ paddingRight: '2%' }}>
            {subjectLoading ? (
              <CircularProgress size={isFullscreen ? 14 : 13} style={{ cursor: 'pointer', paddingRight: '2%' }} />
            ) : (
              <FontAwesomeIcon
                icon={faRotateForward as IconProp}
                fontSize={isFullscreen ? '100%' : '90%'}
                onClick={onSubjectRefresh}
                cursor={'pointer'}
                transform={{ rotate: 45 }}
              />
            )}
          </Box>
          <SubjectBoxStyled>
            <Typography fontSize={'80%'} fontWeight={'bold'} paddingRight={'1%'}>
              {t('simulationLibrary.ai.subject')}
            </Typography>
            <Typography fontSize={'80%'}>{subject}</Typography>
          </SubjectBoxStyled>
        </InnerBarBoxStyled>
      )}
      <Box
        zIndex={2}
        sx={{
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            width: '4px',
          },
          position: 'relative',
          width: '100%',
          height: pageType === 'email' ? '80%' : '94%',
        }}
      >
        {children}
      </Box>
    </WindowBoxStyled>
  )
  return (
    <>
      <Modal open={isFullscreen} onClose={handleFullscreenClose} style={{ background: 'rgba(0, 0, 0, 0.8)' }}>
        <>
          <Box
            sx={{
              width: '1110px',
              height: '672px',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <Box
              component="img"
              alt="monitor-background"
              borderRadius={'10px'}
              border={'2px solid' + theme.palette.cyan[400]}
              display="flex"
              width="100%"
              height="100%"
              src={hookBackground}
            />
            <WindowComponent isFullscreen />
          </Box>
        </>
      </Modal>
      <Box display="flex" alignItems="center" position="relative" justifyContent="center">
        <Box component="img" alt="monitor" src={MonitorImage} />
        <Box
          component="img"
          alt="monitor-background"
          top="9px"
          position="absolute"
          width="calc(100% - 14px)"
          height="calc(100% - 98px)"
          src={hookBackground}
        />
        <ExpandButtonStyled
          onClick={handleFullscreenOpen}
          variant="contained"
          color="secondary"
          size="small"
          disableElevation
          disabled={isLoading}
          startIcon={<FontAwesomeIcon icon={faExpand as IconProp} width={12} />}
        >
          {t('simulationLibrary.ai.fullscreen')}
        </ExpandButtonStyled>
        <WindowComponent />
      </Box>
    </>
  )
}

const ExpandButtonStyled = styled(Button)(() => ({
  left: '5%',
  zIndex: '3',
  bottom: '25%',
  height: '24px',
  position: 'absolute',
  borderRadius: '30px',
}))

const WindowBoxStyled = styled(Box)(({ centerd }: { centerd: boolean }) => ({
  position: 'absolute',
  background: 'rgba(256,256,256,0.2)',
  height: '70%',
  width: '90%',
  borderRadius: '10px',
  overflow: 'hidden',
  top: centerd ? '50%' : '5%',
  left: centerd ? '50%' : 'none',
  transform: centerd ? 'translate(-50%, -50%)' : 'none',
}))

const TopBarBoxStyled = styled(Box)(() => ({
  position: 'relative',
  width: '100%',
  height: '6%',
  background: theme.palette.white,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))
const InnerBarBoxStyled = styled(Box)(() => ({
  background: theme.palette.grey[400],
  display: 'flex',
  padding: '1.5%',
  alignItems: 'center',
}))

const SubjectBoxStyled = styled(Box)(() => ({
  background: theme.palette.lightBlue[50],
  borderRadius: '10px',
  width: '100%',
  display: 'flex',
  padding: '1%',
}))

const TopBarIconsStyled = styled(FontAwesomeIcon)(({ isFullscreen }: { isFullscreen?: boolean }) => ({
  background: theme.palette.blueGray[800],
  color: theme.palette.blueGray[50],
  padding: `1.5px ${isFullscreen ? 3 : 2}px`,
  borderRadius: '50px',
  marginLeft: theme.spacing(1),
}))

export default memo(MonitorPreview)
