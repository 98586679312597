import axios from 'axios'
import { API_URL } from '../constants'
import { Terms } from '@/types/terms'
import { useQuery } from '@tanstack/react-query'

const getLatestTerms = async () => {
  const { data } = await axios.get<Terms>(`${API_URL}/terms/latest`)
  return data
}

const useGetLatestTerms = () => {
  return useQuery<Terms, Error>({
    queryKey: ['latest-terms'],
    queryFn: getLatestTerms,
  })
}

export { getLatestTerms, useGetLatestTerms }
