import axios from "axios";
import { API_URL } from "../constants";
import { useMutation, useQueryCache } from "react-query";
import { ServerError } from "../types";
import { ActiveDirectoryUser, ActiveDirectoryBoardRequest } from "./users";

export async function offboardUsers(
  users: ActiveDirectoryBoardRequest
): Promise<string> {
  const url = `${API_URL}/active_directory/offboard`;
  const { data } = await axios.post(url, users, { withCredentials: true });
  return data.data;
}

export function useOffboardActiveDirectoryUsers() {
  const queryCache = useQueryCache();
  return useMutation<string, ServerError, ActiveDirectoryBoardRequest>(
    offboardUsers,
    {
      throwOnError: true,
      onMutate: (users) => {
        queryCache.cancelQueries(["ad-users"]);
        const previousUsers =
          (queryCache.getQueryData(["ad-users"]) as ActiveDirectoryUser[]) ??
          [];
        const userIds = users.users.map((user) => {
          return user.external_id;
        });

        const updatedUsers = previousUsers.map((user) =>
          userIds.includes(user.external_id)
            ? { ...user, onboarded: false }
            : user
        );
        queryCache.setQueryData(["ad-users"], () => updatedUsers);
        return () => queryCache.setQueryData(["ad-users"], previousUsers);
      },
      //@ts-ignore
      onError: (err, variables, rollback) => rollback(),
      //@ts-ignore
      onSettled: () => {
        queryCache.invalidateQueries(["ad-users"]);
      },
    }
  );
}
