import { Avatar, Box, Container, Paper, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useTranslation } from 'react-i18next'

import { useLogin } from '@/api/auth/login'
import LogoIcon from '@/assets/images/logo.svg'
import Login from './Login'
import MFA from './MFA'

export default function LoginContainer() {
  const classes = useStyles()
  const { t } = useTranslation()
  const [login, { isLoading, error }] = useLogin()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [showMfaScreen, setShowMfaScreen] = useState(false)

  async function handleLogin(formValues) {
    try {
      const recaptcha = executeRecaptcha && (await executeRecaptcha('login'))
      const data = await login({ ...formValues, recaptcha })
      if (data?.mfa) {
        setShowMfaScreen(true)
      }
    } catch (e) {}
  }

  return (
    <Container component="main" maxWidth="xs">
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <img alt={'Cywareness Logo'} src={LogoIcon} height={24} width={22} />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t(`login.signIn`)}
        </Typography>
        {showMfaScreen ? <MFA /> : <Login isLoading={isLoading} error={error} handleLogin={handleLogin} />}
      </Paper>
      <Box mt={8}>
        <Typography variant="body2" color="textSecondary" align="center">
          {t('login.copyright') + ' © '}
          Cywareness {new Date().getFullYear()}
          {'.'}
        </Typography>
      </Box>
    </Container>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paper: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.blueGray[900],
  },
}))
