import React from "react";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function ResetPasswordSuccess() {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigateTo = useNavigate();

  return (
    <div className={classes.root}>
      <div className={classes.textContainer}>
        <Typography variant={"h5"}>
          {t("resetPasswordSuccess.success")}
        </Typography>
        <Typography variant={"subtitle1"}>
          {t("resetPasswordSuccess.passwordChanged")}
        </Typography>
      </div>
      <Button
        onClick={() => navigateTo("/login")}
        variant={"contained"}
        color={"primary"}
      >
        {t("resetPasswordSuccess.toLogin")}
      </Button>
    </div>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    textContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(8),
    },
  })
);
