import { FC, useEffect, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { useParams, useNavigate } from 'react-router-dom'
import InvalidToken from '../TrainingCampaignView/InvalidToken'
import { Button, CircularProgress, IconButton, LinearProgress, Typography, useTheme } from '@mui/material'
import LoadingContainer from '@/common/components/LoadingContainer/LoadingContainer'
import { useTrainingByToken } from '@/api/training-campaigns/training-by-token'
import { useTranslation } from 'react-i18next'
import { languages } from '@/assets/locales/languages'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX } from '@fortawesome/pro-solid-svg-icons'
import Answer from './Answer'
import { useCheckAnswer } from '@/api/quizzes/check-answer'
import useToast from '@/common/hooks/useToast'
import Summary from '@/api/quizzes/Summary'
import { LanguageSelect } from './LanguageSelect'
import { useQuizById } from '@/api/quizzes/quiz-by-id'
import { useUrlSearchQuery } from '@/common/hooks/useUrlQuery'
import { isValidLanguageKey } from '@/common/utils/language-utils'
import useLogEvent from '@/common/hooks/useLogEvent'
import { CAMPAIGN_TYPES, EVENT_TYPES } from '@/api/events/log'
import cywarenessLogoDark from '@/assets/images/logo-dark.svg'
import cywarenessLogoBlue from '@/assets/images/logo-blue.svg'

const Quiz: FC = () => {
  const classes = useStyles()
  const theme = useTheme()
  const navigateTo = useNavigate()
  const { t } = useTranslation()
  const { campaignToken, quizId } = useParams()
  const queryParams = useUrlSearchQuery()
  const { log } = useLogEvent()
  const { errorToast } = useToast()
  const { data: quizData, isLoading: isQuizLoading } = useQuizById({
    quiz_id: quizId as string,
    campaign: campaignToken as string,
  })
  const { data: campaignData } = useTrainingByToken(campaignToken !== 'preview' ? campaignToken : '')
  const [checkAnswer, { isLoading: isCheckingAnswer }] = useCheckAnswer()
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)

  const quiz = campaignData?.quizzes[0] || quizData
  const totalQuestions = quiz?.questions.length || 1
  const isFinalQuestion = currentQuestionIndex + 1 === totalQuestions
  const [showSummary, setShowSummary] = useState(false)
  const [correctQuestions, setCorrectQuestions] = useState(0)
  const [hasAnsweredQuestion, setHasAnsweredQuestion] = useState(false)
  const [checkedAnswers, setCheckedAnswers] = useState<{
    [id: string]: { isCorrect: boolean }
  }>({})

  const [selectedLanguage, setSelectedLanguage] = useState('en')
  const isPreview = campaignToken === 'preview'
  const isRtl = selectedLanguage === 'ar' || selectedLanguage === 'he'

  useEffect(() => {
    log({
      campaignToken,
      campaignType: CAMPAIGN_TYPES.TRAINING,
      eventType: EVENT_TYPES.QUIZ_STARTED,
    })
  }, [])

  useEffect(() => {
    const isValidLanguageParam = isValidLanguageKey(queryParams.language)
    if (quizData) {
      if (isValidLanguageParam && quizData.languages.includes(queryParams.language)) {
        setSelectedLanguage(queryParams.language)
      } else if (quizData.languages.length && !quizData.languages.includes('en')) {
        setSelectedLanguage(quizData.languages[0])
      }
    }
  }, [quizData, queryParams.language])

  function handleExit() {
    navigateTo(`/content/completed-training`)
  }

  async function handleCheckAnswer(answerId: string) {
    if (quiz) {
      try {
        const result = await checkAnswer({
          answerId,
          campaignToken,
          questionId: quiz.questions[currentQuestionIndex]?._id,
        })
        log({
          campaignToken: campaignToken,
          eventType: EVENT_TYPES.QUIZ_ANSWER,
          campaignType: CAMPAIGN_TYPES.TRAINING,
          additionalData: {
            question: result?.question,
            answer: result?.answer,
            isCorrect: result?.is_correct,
          },
        })
        if (result) {
          if (result.is_correct) {
            setCorrectQuestions(correctQuestions + 1)
            setCheckedAnswers((prevState) => ({
              ...prevState,
              [answerId]: {
                isCorrect: true,
              },
            }))
          } else {
            setCheckedAnswers((prevState) => ({
              ...prevState,
              [answerId]: {
                isCorrect: false,
              },
              [result.correct_answers[0]]: { isCorrect: true },
            }))
          }

          setHasAnsweredQuestion(true)
        }
      } catch (e) {
        console.log(e)
        if (isPreview) {
          errorToast(t('quizPage.errors.previewCampaign'))
        } else {
          errorToast(t('quizPage.errors.generic'))
        }
      }
    }
  }

  function isCorrectAnswer(answerId: string): boolean | undefined {
    return checkedAnswers[answerId] ? checkedAnswers[answerId].isCorrect : undefined
  }

  function handleNext() {
    setCurrentQuestionIndex(currentQuestionIndex + 1)
    setHasAnsweredQuestion(false)
  }

  function handleFinish() {
    setShowSummary(true)
    if (!isPreview) {
      log({
        campaignToken,
        campaignType: CAMPAIGN_TYPES.TRAINING,
        eventType: EVENT_TYPES.QUIZ_COMPLETED,
      })
    }
  }

  function handleChangeLanguage(language: string) {
    setSelectedLanguage(language)
    log({
      campaignToken,
      campaignType: CAMPAIGN_TYPES.TRAINING,
      eventType: EVENT_TYPES.QUIZ_LANGUAGE_CHANGE,
      additionalData: {
        language: language,
      },
    })
  }

  return (
    <div className={classes.root}>
      {!isQuizLoading ? (
        !quiz ? (
          <InvalidToken />
        ) : (
          <div className={classes.columnContainer}>
            <div className={classes.container}>
              <div className={classes.titleContainer}>
                <img className={classes.logo} alt={'Cywareness Logo'} src={cywarenessLogoDark} />
                <div className={classes.flexCenter}>
                  <Typography
                    id={'subject'}
                    variant={'h4'}
                    style={{
                      textAlign: isRtl ? 'right' : 'left',
                      direction: isRtl ? 'rtl' : 'ltr',
                    }}>
                    {campaignData?.title}
                  </Typography>
                </div>
                <div className={classes.languageContainer}>
                  <LanguageSelect
                    options={quiz.languages.map((language) => ({
                      label: languages[language].name,
                      value: language,
                    }))}
                    value={selectedLanguage}
                    handleChange={handleChangeLanguage}
                  />
                  <IconButton
                    className={classes.closeButton}
                    onClick={handleExit}
                    size={'small'}
                    style={{ background: theme.palette.grey.A100 }}>
                    <FontAwesomeIcon icon={faX as IconProp} />
                  </IconButton>
                </div>
              </div>
              <div className={classes.flexCenter}>
                {showSummary ? (
                  <Summary totalQuestions={totalQuestions} correctAnswers={correctQuestions} />
                ) : (
                  <>
                    {' '}
                    <Typography
                      gutterBottom
                      id={'subject'}
                      variant={'h6'}
                      style={{
                        textAlign: isRtl ? 'right' : 'left',
                        direction: isRtl ? 'rtl' : 'ltr',
                      }}>
                      {quiz.questions[currentQuestionIndex]?.question[selectedLanguage]}
                    </Typography>
                    <div className={classes.answersContainer}>
                      {quiz.questions[currentQuestionIndex].answers.map((answer, index) => (
                        <Answer
                          answer={answer.answer[selectedLanguage]}
                          questionIndex={index}
                          isCorrect={isCorrectAnswer(answer._id)}
                          handleClick={() => handleCheckAnswer(answer._id)}
                          disabled={hasAnsweredQuestion}
                        />
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className={classes.footer}>
              {showSummary ? (
                <div className={classes.flexRow}>
                  <Button
                    disabled={!hasAnsweredQuestion}
                    style={{ width: 200 }}
                    onClick={handleExit}
                    variant={'contained'}
                    color={'secondary'}
                    size={'large'}
                    fullWidth>
                    {t('quizPage.finish')}
                  </Button>
                </div>
              ) : (
                <div className={classes.flexRow}>
                  <div className={classes.progressContainer}>
                    <div className={classes.progressBar}>
                      <LinearProgress
                        value={((currentQuestionIndex + 1) / totalQuestions) * 100}
                        variant={'determinate'}
                        color={'secondary'}
                      />
                    </div>
                    <Typography>
                      {currentQuestionIndex + 1} / {totalQuestions}
                    </Typography>
                  </div>
                  <Button
                    disabled={!hasAnsweredQuestion && !isPreview}
                    style={{ width: 200 }}
                    onClick={isFinalQuestion ? handleFinish : handleNext}
                    variant={'contained'}
                    color={'secondary'}
                    size={'large'}
                    fullWidth>
                    {isCheckingAnswer ? (
                      <CircularProgress color={'inherit'} size={24} />
                    ) : (
                      t(isFinalQuestion ? 'quizPage.checkResults' : 'quizPage.continue')
                    )}
                  </Button>
                </div>
              )}

              <img className={classes.textLogo} alt={'Cywareness Logo'} src={cywarenessLogoBlue} />
            </div>
          </div>
        )
      ) : (
        <LoadingContainer />
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: '100vh',
      paddingTop: theme.spacing(1),
    },
    columnContainer: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    closeButton: {
      marginLeft: theme.spacing(2),
    },
    container: {
      padding: theme.spacing(2, 4),
      height: '100%',
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    flexCenter: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      flexGrow: 1,
    },
    flexRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    avatar: {
      height: 40,
      width: 40,
    },
    titleContainer: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(0, 0, 4),
    },
    languageContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      right: 0,
    },
    innerTitleContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    logo: {
      height: 40,
      position: 'absolute',
      left: 0,
    },
    textLogo: {
      height: 20,
      marginRight: theme.spacing(2),
      position: 'absolute',
      right: 0,
      float: 'right',
    },
    footer: {
      position: 'relative',
      padding: theme.spacing(2, 0),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: theme.palette.grey.A100,
      flexShrink: 1,
    },
    progressContainer: {
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(6),
    },
    progressBar: {
      width: 240,
      marginRight: theme.spacing(2),
    },
    answersContainer: {
      marginTop: theme.spacing(2),

      maxWidth: 600,
    },
  })
)
export default Quiz
