import axios from "axios";
import { API_URL } from "../constants";
import { useMutation } from "react-query";
import { ServerError } from "../types";

type CheckAnswerResponse = {
  is_correct: boolean;
  question: string;
  answer: string;
  correct_answers: string[];
};
export type CheckAnswerParams = {
  campaignToken: string;
  questionId: string;
  answerId: string;
};

export async function checkAnswer(
  params: CheckAnswerParams
): Promise<CheckAnswerResponse> {
  const url = `${API_URL}/quizzes/check_answer?campaign=${params.campaignToken}`;
  const { data } = await axios.post(
    url,
    { question_id: params.questionId, answer_id: params.answerId },
    { withCredentials: true }
  );
  return data;
}

export function useCheckAnswer() {
  return useMutation<CheckAnswerResponse, ServerError, CheckAnswerParams>(
    checkAnswer,
    {
      throwOnError: true,
    }
  );
}
