import { SortOrder } from '@/types/common'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faCaretUp } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Checkbox, TableCell, TableHead, TableRow, TableSortLabel, styled } from '@mui/material'
import { ChangeEvent, FC, MouseEvent } from 'react'

import { theme } from '@/theme/theme'
import { TalbeColumn } from '.'

type CustomTableHeadProps = {
  columns: TalbeColumn[]
  numSelected: number
  onRequestSort: (event: MouseEvent<unknown>, property: keyof any) => void
  onSelectAllClick: (event: ChangeEvent<HTMLInputElement>) => void
  sortOrder?: SortOrder.asc | SortOrder.desc
  sortBy: string
  rowCount: number
  selectable?: boolean
}

const CustomTableHead: FC<CustomTableHeadProps> = ({
  columns,
  onSelectAllClick,
  sortOrder,
  sortBy,
  numSelected,
  rowCount,
  onRequestSort,
  selectable,
}) => {
  const handleSort = (property: string) => (event: MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {selectable && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell>
        )}
        {columns.map((col, index) => (
          <TableCell
            key={col.id}
            align={index ? 'center' : 'left'}
            padding={!col.disablePadding ? 'normal' : 'none'}
            sortDirection={sortBy === col.id ? sortOrder : false}>
            {!col.sortable ? (
              <>{col.label}</>
            ) : (
              <TableSortLabel
                active={sortBy === col.id}
                direction={sortBy === col.id ? sortOrder : SortOrder.asc}
                onClick={handleSort(col.id)}
                IconComponent={(props) => <StyledIcon sortOrder={sortOrder} icon={faCaretUp as IconProp} {...props} />}>
                {col.label}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const StyledIcon = styled(FontAwesomeIcon)(({ sortOrder }: { sortOrder?: SortOrder }) => ({
  ransform: `rotate(${sortOrder === SortOrder.asc ? 180 : 0}deg)`,
  padding: theme.spacing(1),
}))

export default CustomTableHead
