import { useMemo } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import useForm, { Field } from "../Form/useForm";
import Form from "../Form/Form";
import { Group } from "../../../api/groups/groups";

type AddUserGroupsModalProps = {
  handleClose: () => void;
  handleSave: (values: any) => void;
  open: boolean;
  userIds?: String[];
  groups?: Group[];
};

export default function AddUserGroupsModal({
  open,
  userIds,
  groups,
  handleClose,
  handleSave,
}: AddUserGroupsModalProps) {
  const { t } = useTranslation();

  const fields: Field[] = useMemo(
    () => [
      {
        name: "groups",
        initialValue: [],
        label: "editUserModal.assignGroupsField",
        validationRule: String(),
        type: "text",
        required: false,
        autoFocus: false,
        gridItemProps: {
          xs: 12,
        },
        textFieldProps: {
          variant: "outlined",
          select: true,
          SelectProps: {
            multiple: true,
          },
        },
        options: groups
          ? groups.map((group) => ({ label: group.name, value: group._id }))
          : [],
      },
    ],
    [groups]
  );

  const { formProps, handleSubmit } = useForm({
    fields,
    onSubmit: handleSumbit,
    clearOnSubmit: true,
  });

  function handleSumbit(values: any) {
    handleSave({ ids: userIds, groups: values.groups });
  }

  return (
    <>
      <Dialog
        maxWidth={"sm"}
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="user-dialog-title"
      >
        <DialogTitle id="user-dialog-title">
          {t("editUserModal.assignGroups")}
        </DialogTitle>
        <DialogContent>
          <Form {...formProps} />
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            padding: "0 24px 24px 24px",
          }}
        >
          <Button
            onClick={handleClose}
            sx={{
              border: "1px solid #05DBF2",
              padding: "0px 20px",
            }}
          >
            {t("createUserModal.cancel")}
          </Button>
          <Button
            onClick={handleSubmit}
            sx={{
              border: "1px solid #05DBF2",
              backgroundColor: "#05DBF2",
              padding: "0px 20px",
            }}
          >
            {t("createUserModal.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
