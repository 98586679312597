import { useEffect, useMemo, useState, Dispatch, SetStateAction } from 'react'
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import useForm, { Field } from '@/common/components/Form/useForm'
import { string } from 'yup'
import Form from '@/common/components/Form/Form'
import { useEditGroup } from '@/api/groups/edit-group'
import { useUsers } from '@/api/users/users'
import Autocomplete from '@mui/material/Autocomplete'
import { useOffices } from '@/api/offices/offices'
import { FullGroupObject } from '@/api/groups/group'
import { useAddUsersToGroup } from '@/api/groups/add-users-to-group'
import useToast from '@/common/hooks/useToast'

type CreateGroupModalProps = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  onClose?: () => void
  group?: FullGroupObject
  mode: 'group' | 'users'
}

type SelectedUser = {
  _id: string
  label: string
}

export default function EditGroupModal({ open, setOpen, group, onClose = () => {}, mode }: CreateGroupModalProps) {
  const { t } = useTranslation()
  const { data: offices } = useOffices()
  const [newUsers, setNewUsers] = useState<SelectedUser[]>([])
  const [newManagers, setNewManagers] = useState<SelectedUser[]>([])
  const [query, setQuery] = useState('')
  const [editGroup] = useEditGroup()
  const [addUsersToGroup] = useAddUsersToGroup()
  const { successToast, errorToast } = useToast()
  const { data: users } = useUsers({
    fullname: query,
    email: '',
    offset: 50,
    startIndex: 0,
    offices: [],
    groups: group && mode === 'group' ? [group._id] : [],
  })

  const usersList = useMemo(() => {
    return users
      ? users.items.map((user) => ({
          label: `${user.first_name} ${user.last_name}`,
          _id: user._id,
        }))
      : []
  }, [users])

  const optionalManagers = useMemo(() => {
    const managersIds = newManagers.map((manager) => manager._id)
    return usersList.filter((user) => !managersIds.includes(user._id))
  }, [newManagers, usersList])

  const managersList = useMemo(
    () =>
      (group?.managers || []).map(({ first_name, last_name, _id }) => ({
        label: `${first_name} ${last_name}`,
        _id: _id,
      })),
    [group]
  )

  useEffect(() => {
    setNewManagers(managersList)
  }, [managersList])

  function handleChange(event, newValue) {
    setNewUsers(newValue)
  }

  function handleChangeManagers(event, newValue) {
    setNewManagers(newValue)
  }

  function handleQueryChange(event, newInputValue) {
    setQuery(newInputValue)
  }

  const fields: Field[] = useMemo(
    () => [
      {
        name: 'name',
        initialValue: group?.name ?? '',
        label: 'createGroupModal.name',
        validationRule: string().required('createGroupModal.errors.missingName'),
        type: 'text',
        required: true,
        autoFocus: false,
        gridItemProps: {
          xs: 12,
        },
      },
      {
        name: 'offices',
        initialValue: group?.offices ?? [],
        label: 'createUserModal.office',
        validationRule: mode === 'users' ? undefined : string().required('createUserModal.errors.missingOffice'),
        type: 'text',
        required: true,
        autoFocus: false,
        gridItemProps: {
          xs: 12,
        },
        textFieldProps: {
          variant: 'outlined',
          select: true,
          SelectProps: {
            multiple: (offices?.array || []).length > 1,
          },
        },
        options: offices
          ? offices.array.map((office) => ({
              label: office.name,
              value: office._id,
            }))
          : [],
      },
    ],
    [group, offices, mode]
  )

  function handleClose() {
    onClose()
    setOpen(false)
    setNewUsers([])
    setNewManagers(managersList)
    clearForm()
  }

  const { formProps, handleSubmit, clearForm } = useForm({
    fields,
    onSubmit: handleSumbit,
    clearOnSubmit: false,
  })

  async function handleSaveEdit({ name, offices }) {
    if (group) {
      try {
        const users = newUsers.map((user) => user._id)
        await editGroup({
          _id: group._id,
          name,
          offices,
          managers: newManagers.map((user) => user._id),
        })
        if (users.length > 0) {
          await addUsersToGroup({ users, _id: group._id })
        }
        successToast(t('groupProfile.groupEdited'))
        handleClose()
      } catch (e) {
        const errorMessage = e.response?.data?.message ?? t('groupProfile.errors.faliedEditGroup')
        console.log('error', e)
        errorToast(errorMessage)
      }
    }
  }

  function handleSumbit(values) {
    const { name, offices } = values
    handleSaveEdit({ name, offices })
  }

  return (
    <Dialog maxWidth={'sm'} fullWidth open={open} onClose={handleClose} aria-labelledby="group-dialog-title">
      <DialogTitle id="group-dialog-title">
        {mode === 'group' ? t('groupProfile.groupModal.editGroup') : t('groupProfile.groupModal.addUsers')}
      </DialogTitle>
      <DialogContent style={{ overflowY: 'hidden' }}>
        {mode === 'group' ? (
          <>
            <Form {...formProps} />
            <div style={{ marginTop: 16 }}>
              <Autocomplete
                value={newManagers}
                onChange={handleChangeManagers}
                onInputChange={handleQueryChange}
                multiple
                id="tags-filled"
                options={optionalManagers}
                getOptionLabel={(option) => option.label}
                noOptionsText={t('createGroupModal.noAdditionalUsers')}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => {
                    return <Chip label={option.label} {...getTagProps({ index })} />
                  })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={t('createGroupModal.groupManagers')}
                    placeholder={t('createGroupModal.searchUsers')}
                  />
                )}
              />
            </div>
          </>
        ) : (
          <Autocomplete
            value={newUsers}
            onChange={handleChange}
            onInputChange={handleQueryChange}
            multiple
            id="tags-filled"
            options={usersList.map((option) => option)}
            getOptionLabel={(option) => option.label}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                return <Chip label={option.label} {...getTagProps({ index })} />
              })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={t('groupProfile.groupModal.newUsers')}
                placeholder={t('groupProfile.groupModal.searchUsers')}
              />
            )}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          {t('groupProfile.groupModal.cancel')}
        </Button>
        <Button onClick={handleSubmit} variant="contained">
          {t('groupProfile.groupModal.save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
