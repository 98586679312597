import { API_URL } from '@/api/constants'
import { paramsSerializer } from '@/api/utils/serialize-array'
import { Campaign, CampaignListItem, CampaignListQuery } from '@/types/campaigns'
import { PagedResult } from '@/types/common'
import { DefinedInitialDataOptions, keepPreviousData, useQuery } from '@tanstack/react-query'
import axios from 'axios'

const getCampaign = async (id: string | undefined) => {
  const url = `${API_URL}/campaigns/${id}/`
  const { data } = await axios.get(url, { withCredentials: true })

  return data
}

const getCampaigns = async (params: CampaignListQuery) => {
  const url = `${API_URL}/campaigns/`
  const { data } = await axios.get(url, {
    withCredentials: true,
    params,
    paramsSerializer,
  })

  return data
}

export const useCampaign = (id: string | undefined, options?: Partial<DefinedInitialDataOptions<Campaign>>) => {
  return useQuery<Campaign>({
    queryKey: ['campaigns', id],
    queryFn: () => getCampaign(id),
    enabled: !!id,
    ...options,
  })
}

export const useCampaigns = (queryParams: CampaignListQuery) => {
  return useQuery<PagedResult<CampaignListItem>>({
    queryKey: ['campaigns', queryParams],
    queryFn: () => getCampaigns(queryParams),
    // refetchInterval: 10_000,
    placeholderData: keepPreviousData, //
  })
}
