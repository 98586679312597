import React, { forwardRef, useRef, useEffect, MutableRefObject } from "react";
import Checkbox from "@mui/material/Checkbox";

const IndeterminateCheckbox = forwardRef(
  ({ indeterminate, ...rest }: { indeterminate: boolean }, ref) => {
    const defaultRef = useRef<{ indeterminate: boolean }>();
    //@ts-ignore
    const resolvedRef: MutableRefObject<{ indeterminate: boolean }> =
      ref || defaultRef;

    useEffect(() => {
      if ("current" in resolvedRef) {
        resolvedRef.current.indeterminate = indeterminate;
      }
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <Checkbox ref={resolvedRef as MutableRefObject<any>} {...rest} />
      </>
    );
  }
);

export default IndeterminateCheckbox;
