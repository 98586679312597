import { Typography } from '@mui/material'
import { FC } from 'react'

import defaultAnimationData from '@/assets/lottie/surprised.json'
import useLottie from '@/common/hooks/useLottie'

const TableNoResults: FC = () => {
  const { LottieAnimation } = useLottie({
    animationData: defaultAnimationData,
    height: 120,
    width: 120,
  })

  return (
    <>
      {LottieAnimation}
      <Typography mt={2}>No results found</Typography>
    </>
  )
}

export default TableNoResults
