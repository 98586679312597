import React, { useEffect, useState } from "react";
import { Box, TextField } from "@mui/material";
import UploadImageModal from "../../../../common/components/UploadImageModal/UploadImageModal";
import { useAiSimulationContext } from "../state";

export const EmailSenderSignature = () => {
  const { emailSenderSignature, setEmailSenderSignature } =
    useAiSimulationContext();

  const [imageModalIsOpen, setImageModalIsOpen] = useState(false);

  function handleOnFinishImageUpload(url: string) {
    setEmailSenderSignature(url);
  }

  return (
    <Box>
      <TextField
        size="small"
        fullWidth
        label="Upload your logo for the email signature"
        value={emailSenderSignature}
        onClick={() => setImageModalIsOpen(true)}
        variant="outlined"
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: "10px",
          },
        }}
      />
      <UploadImageModal
        open={imageModalIsOpen}
        setOpen={setImageModalIsOpen}
        onFinishUpload={handleOnFinishImageUpload}
      />
    </Box>
  );
};
