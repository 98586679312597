import { palette } from '../palette'

export const MuiSwitch = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    switchBase: {
      padding: 8,
      color: palette.white,
      '&.Mui-checked': {
        color: palette.blueDianne[900],
        transform: 'translateX(16px)',
        '& + .MuiSwitch-track': {
          backgroundColor: `${palette.cyan[500]} !important`,
          outline: `1px solid ${palette.blueDianne[900]}`,
          opacity: 1,
        },
        '&:hover': {
          background: `${palette.cyan[500]}33`,
        },
      },
      '&.Mui-disabled': {
        color: palette.grey[600],
        '& + .MuiSwitch-track': {
          backgroundColor: `${palette.grey[300]} !important`,
          outline: `1px solid ${palette.grey[400]}`,
          opacity: 1,
        },
      },
    },
    track: {
      borderRadius: 15,
      backgroundColor: palette.grey[300],
      outline: `1px solid ${palette.grey[400]}`,
      height: 16,
      width: 32,
      position: 'relative',
    },
    thumb: {
      boxShadow: 'none',
      width: 14,
      height: 14,
      margin: 5,
    },
    colorSecondary: {
      color: palette.cyan[500],
      '&:hover': {
        background: `${palette.cyan[500]}33`,
      },
      '& + .MuiSwitch-track': {
        backgroundColor: `${palette.blueDianne[900]} !important`,
        outline: `1px solid ${palette.cyan[500]}`,
        opacity: 1,
      },
      '&.Mui-checked': {
        color: palette.cyan[500],
        transform: 'translateX(16px)',
        '& + .MuiSwitch-track': {
          backgroundColor: `${palette.blueDianne[900]} !important`,
          outline: `1px solid ${palette.cyan[500]}`,
          opacity: 1,
        },
        '&:hover': {
          background: `${palette.cyan[500]}33`,
        },
      },
    },
  },
}
