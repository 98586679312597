type TabIndexMap = {
  [tab: string]: number;
};

export function tabToIndex(tabIndexMap: TabIndexMap, tab: string): number {
  return tabIndexMap.hasOwnProperty(tab) ? tabIndexMap[tab] : 0;
}

export function indexToTab(tabIndexMap: TabIndexMap, index: number): string {
  let tab = Object.keys(tabIndexMap)[0];
  Object.entries(tabIndexMap).forEach(([route, i]) => {
    if (i === index) {
      tab = route;
    }
  });
  return tab;
}
