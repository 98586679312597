import { Box, Typography, styled } from '@mui/material'
import { ReactNode } from 'react'
import bottomSvg from '@/assets/images/MobilePreview/LandingPagePreview/Bottom.svg'
import StatusBar from './StatusBar'

const BrowserAppPreview = ({
  backgroundColor = 'white',
  fakeUrl = 'onlyhuman.cywareness.io',
  children,
}: {
  backgroundColor?: string
  fakeUrl?: string
  children?: ReactNode | ReactNode[]
}) => {
  return (
    <BackGround color={backgroundColor}>
      <StatusBar></StatusBar>
      <LandingPage>{children}</LandingPage>
      <Bottom>
        <FakeUrl>{fakeUrl}</FakeUrl>
      </Bottom>
    </BackGround>
  )
}

const BackGround = styled(Box)(({ color }: { color: string }) => ({
  position: 'absolute',
  backgroundColor: color,
  backgroundSize: 'contain',
  width: '100%',
  height: '100%',
}))
const FakeUrl = styled(Typography)(() => ({
  color: 'black',
  flex: '1',
  fontSize: '75%',
  overflowX: 'clip',
}))
const LandingPage = styled(Box)(() => ({
  position: 'absolute',
  flex: 1,
  display: 'flex',
  width: '100%',
  height: '79%',
}))
const Bottom = styled(Box)(() => ({
  position: 'absolute',
  display: 'flex',
  backgroundImage: `url(${bottomSvg})`,
  backgroundColor: '#EEEEEE',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  width: '100%',
  bottom: 0,
  height: '14%',
  padding: '5.4% 18% 0% 35.4%',
}))
export default BrowserAppPreview
