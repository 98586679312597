import axios from 'axios';
import { API_URL } from '../constants';
import { useQuery } from 'react-query';
import { ServerError } from '../types';

export type ActiveDirectorySecretResult = {
  client_id: string;
  client_secret: string;
  tenant: string;
};

export async function getActiveDirectorySecret() {
  const url = `${API_URL}/active_directory/`;
  const { data } = await axios.get(url, { withCredentials: true });
  return data.data;
}

export function useADSecret() {
  return useQuery<ActiveDirectorySecretResult, ServerError>('active_directory', getActiveDirectorySecret, {
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnMount: false,
  });
}
