import { ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { Theme, useTheme } from '@mui/material/styles'

import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

type MenuItemProps = {
  onClick: () => void
  selected: boolean
  text: string
  children: React.ReactNode
}
export default function MenuItem({ onClick, selected, text, children }: MenuItemProps) {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <ListItem
      button
      onClick={onClick}
      style={{
        backgroundColor: selected ? theme.palette.cyan[500] : 'inherit',
        color: selected ? theme.palette.common.black : theme.palette.common.white,
        paddingLeft: 0,
      }}
    >
      <ListItemIcon className={classes.icon}>{children}</ListItemIcon>
      <ListItemText
        primary={text}
        primaryTypographyProps={{
          style: { fontWeight: theme.typography.fontWeightMedium },
        }}
      />
    </ListItem>
  )
}

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    icon: {
      display: 'flex',
      fontSize: '20px',
      justifyContent: 'space-around',
      color: 'inherit',
      marginRight: '20px',
    },
  })
)
