import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useClientDetails } from '@/api/client/client'
import useDeleteIntegration from '@/api/integrations/delete'
import useExecuteIntegration from '@/api/integrations/execute'
import useIntegrations, { IntegrationType } from '@/api/integrations/integrations'
import AdAzure from '@/assets/images/Integrations/Types/AdAzure.svg'
import GoogleWorkspaceIcon from '@/assets/images/Integrations/Types/AdGoogleWorkspace.svg'
import AdOnPremise from '@/assets/images/Integrations/Types/AdOnPremise.svg'
import deskIcon from '@/assets/images/popup/desk.svg'
import deleteIcon from '@/assets/images/popup/delete.svg'
import Popup from '@/common/components/Popup'
import { theme } from '@/theme/theme'
import CreateIntegrationCard from './components/CreateIntegrationCard'
import IntegrationCard from './components/IntegrationCard'
import IntegrationModal from './IntegrationModal'
export interface IntefrationProps {
  formValues: any
  isSubmitting?: boolean
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
}

export default function IntegrationsPage() {
  const [isIntegrationModalOpen, setIntegrationModalOpen] = useState(false)
  const [currentIntegration, setCurrentIntegration] = useState<IntegrationType | undefined>()
  const { data: clientDetails } = useClientDetails()
  const [deleteIntegration] = useDeleteIntegration()
  const [executeIntegration] = useExecuteIntegration()
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
  const [isSyncingModalOpen, setSyncingModalOpen] = useState(false)

  const integrationTypes = [
    ...[
      ...(clientDetails?.gws_enabled
        ? [
            {
              label: 'Google Worksapce',
              value: 'google-workspace',
              icon: GoogleWorkspaceIcon,
            },
          ]
        : []),
    ],
    {
      label: 'Microsoft Entra ID',
      value: 'ms-graph',
      icon: AdAzure,
    },
    {
      label: 'AD OnPremise',
      value: 'ldap',
      icon: AdOnPremise,
    },
  ]
  const [selectedIntegrationId, setSelectedIntegrationId] = useState<string | undefined>(undefined)

  const { data: integrations, isFetched: areIntegrationsFetched } = useIntegrations()

  const enabledIntegrations =
    (areIntegrationsFetched && Array.from(new Set(integrations?.map((integration) => integration.type)))) || undefined

  const availableIntegrations =
    enabledIntegrations &&
    integrationTypes.filter(
      (integrationType) => !integrationType.disabled && !enabledIntegrations.includes(integrationType.value)
    )
  const { t } = useTranslation()

  const handleOpen = () => setIntegrationModalOpen(true)
  const handleClose = () => setIntegrationModalOpen(false)

  const handleOpenDeleteModal = async (id) => {
    setSelectedIntegrationId(id)
    setDeleteModalOpen(true)
  }

  const handleCloseDeleteModal = async () => {
    setDeleteModalOpen(false)
    setSelectedIntegrationId(undefined)
  }

  const getIntegrationByType = (integrationType: string) => {
    if (!areIntegrationsFetched) return
    return integrations?.find((integration) => integration.type === integrationType)
  }

  const handleIntegrationRun = async (id) => {
    setSyncingModalOpen(true)
    await executeIntegration(id)
  }

  const handleIntegrationDelete = async () => {
    if (selectedIntegrationId) {
      await deleteIntegration(selectedIntegrationId)
      handleCloseDeleteModal()
    }
  }

  return (
    <>
      <Popup
        open={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        onConfirm={handleIntegrationDelete}
        icon={deleteIcon}
        buttonLabels={{
          cancel: t('settings.integrations.deletePopup.cancel'),
          confirm: t('settings.integrations.deletePopup.delete'),
        }}>
        <h4>{t('settings.integrations.deletePopup.title')}</h4>
        <p>{t('settings.integrations.deletePopup.message')}</p>
      </Popup>
      <Popup
        open={isSyncingModalOpen}
        onClose={() => setSyncingModalOpen(false)}
        onConfirm={() => setSyncingModalOpen(false)}
        icon={deskIcon}
        buttonLabels={{
          confirm: t('settings.integrations.syncPopup.gotIt'),
        }}>
        <h4>{t('settings.integrations.syncPopup.title')}</h4>
        <p>{t('settings.integrations.syncPopup.message')}</p>
      </Popup>
      {areIntegrationsFetched && (
        <Box>
          <Title> {t('settings.integrations.myIntegrations')}</Title>
          <IntegrationsListContainer>
            <CreateIntegrationCard
              disabled={(availableIntegrations || []).length > 0}
              onClick={() => {
                setCurrentIntegration(undefined)

                handleOpen()
              }}
            />
            {integrationTypes.map(
              ({ label, value, icon }, index) =>
                getIntegrationByType(value) && (
                  <IntegrationCard
                    key={index}
                    label={label}
                    icon={icon}
                    integration={getIntegrationByType(value)}
                    onClick={() => {
                      setCurrentIntegration(getIntegrationByType(value))
                      handleOpen()
                    }}
                    onSync={handleIntegrationRun}
                    onDelete={handleOpenDeleteModal}
                  />
                )
            )}
          </IntegrationsListContainer>
        </Box>
      )}
      <IntegrationModal
        title={!currentIntegration ? 'New Integration' : 'Edit Integration'}
        integration={currentIntegration}
        isOpen={isIntegrationModalOpen}
        onClose={handleClose}
        integrationTypes={integrationTypes}
        existIntegrationsTypes={integrations?.map((integration) => integration.type) || []}
      />
    </>
  )
}
const Title = styled(Typography)(() => ({
  color: theme.palette.black,
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: 'normal',
  marginBottom: '20px',
}))

const IntegrationsListContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: '40px',
}))
