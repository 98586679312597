export const loadingPhrases: string[] = [
  "Enlisting cyber ninjas for email defense... They've got moves and encryption skills!",
  "Outsmarting phishing with AI agents... Disguises and fake mustaches included.",
  "Coding magic with cybersecurity gnomes... They sprinkle whimsy and protection.",
  "Consulting tech-savvy penguins for Antarctic-grade security... Waddling with expertise.",
  "Summoning digital wizards to charm away those pesky phishes... Expect sparks and encrypted potions!",
  "Hiring email superheroes to battle phishing villains... Spandex suits and keyboard kung fu, anyone?",
  "Recruiting alien AI overlords to guard against cyber invasions... They've got their intergalactic firewalls ready.",
  "Calling in the email sentinels, armed with virtual shields and cyber boomerangs... Ready to deflect those phishing shots!",
  "Unleashing cyber dolphins armed with encryption bubbles... They're here to make a splash against phishing!",
  "Bringing in the pixelated knights of digital realms... Swords made of code and armor crafted from firewalls.",
  "Drafting email Jedi masters to wield the force of cybersecurity... May the encryption be with you.",
  "Employing tech-savvy owls as the night watchmen of your inbox... Hooting away malicious links!",
  "Rallying the email samurais, armed with virtual katanas and phishing-deflecting moves... Slicing through threats!",
  "Enrolling email astronauts to protect your inbox... Because in space, no one can hear your phish.",
];
