export const MuiAlert = {
  styleOverrides: {
    root: {
      borderRadius: '16px',
      alignItems: 'center',
      padding: '16px',
    },
    icon: {
      fontSize: '48px',
      marginRight: '24px',
    },
    action: {
      padding: '0',
      marginRight: '0',
      marginLeft: '24px',
    },
  },
}
