import { Box, TextField } from '@mui/material'
import AiHint from './AiHint'
import { useAiSimulationContext } from '../state'
import { useTranslation } from 'react-i18next'
import { theme } from '@/theme/theme'

export type InstructionsProps = {
  simulationVectorType: string
}

export const Instructions = ({ simulationVectorType }: InstructionsProps) => {
  const { t } = useTranslation()
  const { instructions, setInstructions } = useAiSimulationContext()

  const aiPlaceHolderText = t(`simulationLibrary.ai.inputFields.${simulationVectorType}.instructions`)
  const aiHintText = t(`simulationLibrary.ai.inputFields.${simulationVectorType}.instructionsTooltip`)

  return (
    <TextField
      fullWidth
      placeholder={aiPlaceHolderText}
      value={instructions}
      onChange={(e) => setInstructions(e.target.value)}
      multiline
      rows={5}
      label="Instructions"
      variant="outlined"
      sx={{
        '& .MuiOutlinedInput-root': {
          borderRadius: '10px',
        },
      }}
      InputLabelProps={{
        shrink: true,
        style: {
          display: 'block',
          whiteSpace: 'normal',
        },
      }}
      InputProps={{
        style: {
          alignItems: 'flex-start',
          padding: theme.spacing(1, 0.5, 0, 2),
        },
        endAdornment: (
          <Box display="flex" flexDirection="column" justifyContent="flex-start">
            <AiHint text={aiHintText} />
          </Box>
        ),
      }}
    />
  )
}
