import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import englishTranslations from "./assets/locales/en/translation";
import hebrewTranslations from "./assets/locales/he/translation";

const resources = {
  en: { translation: englishTranslations },
  he: { translation: hebrewTranslations },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      wait: true,
    },
  });

export default i18n;
