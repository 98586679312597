import React, { useMemo, useState } from 'react'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Switch,
  TextField,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import useForm, { Field } from '../Form/useForm'
import { mixed, string } from 'yup'
import Form from '../Form/Form'
import moment, { Moment } from 'moment'
import momentTz from 'moment-timezone'
import { languages } from '@/assets/locales/languages'
import { countriesArray } from '@/assets/countries'
import { NewOfficeParams } from '@/api/offices/new-office'
import { Office } from '@/api/offices/offices'
import { EditOfficeParams } from '@/api/offices/edit-office'
import { usStates } from '@/assets/usStates'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { ClockPickerView } from '@mui/x-date-pickers/ClockPicker'

type CreateOfficeModalProps = {
  createNewOffice: (params: NewOfficeParams) => void
  saveOfficeEdit: (params: EditOfficeParams) => void
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  onClose?: () => void
  office?: Office
}

const CreateOfficeModal = ({
  createNewOffice,
  saveOfficeEdit,
  open,
  setOpen,
  office,
  onClose = () => {},
}: CreateOfficeModalProps) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [days, setDays] = useState({
    sun: false,
    mon: true,
    tue: true,
    wed: true,
    thu: true,
    fri: true,
    sat: false,
  })
  const [startingHour, setStartingHour] = useState<null | Moment>(moment().hour(8).minute(0))
  const [closingHour, setClosingHour] = useState<null | Moment>(moment().hour(17).minute(0))
  const [isCustomHours, setIsCustomnHours] = useState<boolean>(true)

  const fields: Field[] = useMemo(
    () => [
      {
        name: 'name',
        initialValue: office?.name ?? '',
        label: 'createOfficeModal.name',
        validationRule: string().required('createOfficeModal.errors.missingName'),
        type: 'text',
        required: true,
        autoFocus: false,
        gridItemProps: {
          sm: 6,
          xs: 12,
        },
      },
      {
        name: 'geo_location',
        initialValue: office?.geo_location ?? '',
        label: 'createOfficeModal.country',
        validationRule: string().required('createOfficeModal.errors.missingCountry'),
        type: 'text',
        required: true,
        autoFocus: false,
        isAutoComplete: true,
        gridItemProps: {
          sm: 6,
          xs: 12,
        },
        textFieldProps: {
          variant: 'outlined',
          select: true,
        },
        options: countriesArray.map((country) => ({
          label: country.name,
          value: country.code,
        })),
      },
      {
        name: 'state',
        initialValue: office?.state ?? '',
        label: 'createOfficeModal.state',
        validationRule: mixed().notRequired(),
        type: 'text',
        required: true,
        autoFocus: false,
        disabled: (values) => values.geo_location !== 'US',
        isAutoComplete: true,
        gridItemProps: {
          sm: 6,
          xs: 12,
        },
        textFieldProps: {
          variant: 'outlined',
          select: true,
        },
        options: Object.entries(usStates).map(([key, value]) => ({
          label: value,
          value: key,
        })),
      },
      {
        name: 'language',
        initialValue: office?.language ?? '',
        label: 'createOfficeModal.language',
        validationRule: string().required('createOfficeModal.errors.missingLanguage'),
        type: 'text',
        required: true,
        autoFocus: false,
        isAutoComplete: true,
        gridItemProps: {
          sm: 6,
          xs: 12,
        },
        textFieldProps: {
          variant: 'outlined',
          select: true,
        },
        options: Object.entries(languages).map(([key, value]) => ({
          label: value.name,
          value: key,
        })),
      },
      {
        name: 'time_zone',
        initialValue: office?.time_zone ?? '',
        label: 'createOfficeModal.timezone',
        validationRule: string().required('createOfficeModal.errors.missingTimezone'),
        type: 'text',
        required: true,
        autoFocus: false,
        isAutoComplete: true,
        gridItemProps: {
          sm: 6,
          xs: 12,
        },
        textFieldProps: {
          variant: 'outlined',
          select: true,
        },
        options: momentTz.tz.names().map((value) => ({ label: value, value: value })),
      },
    ],
    [office]
  )

  function handleClose() {
    onClose()
    setOpen(false)
    clearForm()
  }

  const { formProps, handleSubmit, clearForm } = useForm({
    fields,
    onSubmit: handleSave,
    clearOnSubmit: false,
  })

  function handleChangeDays(day: string) {
    setDays((prevState) => ({ ...prevState, [day]: !prevState[day] }))
  }

  async function handleSaveEdit(params) {
    if (office) {
      try {
        saveOfficeEdit({ _id: office._id, params })
        handleClose()
      } catch (e) {}
    }
  }

  async function handleCreateOffice(params) {
    try {
      await createNewOffice(params)
      handleClose()
    } catch (e) {}
  }

  function handleSave(values) {
    const params = {
      ...values,
      state: values.geo_location === 'US' ? values.state : '',
      working_hours: {
        days,
        opening: {
          h: isCustomHours ? moment(startingHour).hours() : 0,
          m: isCustomHours ? moment(startingHour).minutes() : 0,
        },
        closing: {
          h: isCustomHours ? moment(closingHour).hours() : 23,
          m: isCustomHours ? moment(closingHour).minutes() : 59,
        },
      },
    }
    office ? handleSaveEdit(params) : handleCreateOffice(params)
  }

  const getShouldDisableFunction = (
    otherClockTime: moment.Moment | null,
    isClosingTime: boolean
  ): ((timeValue: number, clockType: ClockPickerView) => boolean) => {
    return (timeValue, clockType) => {
      if (clockType === 'hours') return timeValue === otherClockTime?.hour()
      else if (clockType === 'minutes')
        return (
          (startingHour &&
            startingHour.hour() + 1 === closingHour?.hour() &&
            otherClockTime &&
            (isClosingTime ? timeValue > otherClockTime.minute() : timeValue < otherClockTime.minute())) ||
          false
        )
      return false
    }
  }

  return (
    <>
      <Dialog
        maxWidth={'sm'}
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="office-dialog-title"
        scroll={'body'}
      >
        <DialogTitle id="office-dialog-title">
          {' '}
          {office ? t('createOfficeModal.editOffice') : t('createOfficeModal.newOffice')}
        </DialogTitle>
        <DialogContent>
          <Form {...formProps} />
          <FormControl
            className={classes.daysContainer}
            required
            error={!Boolean(Object.values(days).filter((value) => value).length > 0)}
            component="fieldset"
          >
            <FormLabel component="legend">{t('createOfficeModal.workingDays')}</FormLabel>
            <FormGroup row className={classes.daysContainer}>
              {Object.entries(days).map(([day, value]) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={value}
                      onChange={() => handleChangeDays(day)}
                      name={t(`createOfficeModal.days.${day}`)}
                    />
                  }
                  label={t(`createOfficeModal.days.${day}`)}
                />
              ))}
            </FormGroup>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Switch
                checked={isCustomHours}
                onChange={() => {
                  setIsCustomnHours((isCustomHours) => !isCustomHours)
                }}
              />
              <FormLabel disabled={isCustomHours}>{t('createOfficeModal.open24H')}</FormLabel>
            </div>
          </FormControl>

          {isCustomHours ? (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                error={!startingHour}
                className={classes.timePicker}
                clearable={false}
                ampm={false}
                shouldDisableTime={getShouldDisableFunction(closingHour, true)}
                label={t('createOfficeModal.openingTime')}
                value={startingHour}
                onChange={(time) => {
                  if (time) setStartingHour(moment().hour(time.hour()).minute(time.minute()))
                }}
                renderInput={(params) => <TextField {...params} />}
              />
              <TimePicker
                error={!closingHour}
                className={classes.timePicker}
                clearable={false}
                ampm={false}
                shouldDisableTime={getShouldDisableFunction(startingHour, false)}
                label={t('createOfficeModal.closingTime')}
                value={closingHour}
                onChange={(time) => {
                  if (time) setClosingHour(moment().hour(time.hour()).minute(time.minute()))
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          ) : (
            <></>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            {t('createOfficeModal.cancel')}
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            disabled={!Boolean(Object.values(days).filter((value) => value).length > 0)}
          >
            {t('createOfficeModal.save')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    daysContainer: {
      margin: theme.spacing(1, 0),
    },
    timePicker: {
      marginRight: theme.spacing(2),
    },
  })
)

export default CreateOfficeModal
