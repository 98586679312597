import { API_URL } from '@/api/constants'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

const deletePhishingSimulationPackage = async (id: string | undefined) => {
  const url = `${API_URL}/phishing-simulations/packages/${id}`
  const { data } = await axios.delete(url, { withCredentials: true })

  return data
}

export const usePhishingSimulationPackageDelete = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: deletePhishingSimulationPackage,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['phishing-simulations-packages'],
      })
    },
  })
}
