import React, { FC, ReactNode, useRef, useState } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import SlickSlider from 'react-slick'
import { Box, IconButton, Typography, useMediaQuery } from '@mui/material'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { faChevronRight, faChevronLeft } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { debounce } from '@mui/material/utils'

const sliderSettings = {
  slidesToScroll: 1,
  autoplay: false,
  pauseOnHover: false,
  pauseOnFocus: false,
  dots: false,
  arrows: false,
  centerMode: false,
  infinite: false,
  variableWidth: true,
}

type CarouselProps = {
  children: React.ReactNode[]
  title?: string
  sliderProps?: {
    slidesToScroll?: number
    slidesToShow?: number
    infinite?: boolean
  }
  icon?: ReactNode
}
const Carousel: FC<CarouselProps> = ({ children, title, icon, sliderProps = {} }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const isXl = useMediaQuery(theme.breakpoints.up('xl'))
  const isMd = useMediaQuery(theme.breakpoints.down('lg'))
  const numOfSlides = isMobile ? 1 : isMd ? 2 : isXl ? 4 : 3
  const sliderRef = useRef(null)
  const [currentSlide, setCurrentSlide] = useState(0)

  function afterChange(current) {
    setCurrentSlide(current)
  }

  function next() {
    if (sliderRef.current) {
      //@ts-ignore
      sliderRef.current.slickNext()
    }
  }

  function previous() {
    if (sliderRef.current) {
      //@ts-ignore
      sliderRef.current.slickPrev()
    }
  }

  const onWheelSlider = debounce((e) => {
    if (sliderRef.current) {
      if (e.deltaX > 0) {
        //@ts-ignore
        sliderRef.current.slickNext()
      } else if (e.deltaX < 0) {
        //@ts-ignore
        sliderRef.current.slickPrev()
      }
    }
  }, 20)

  return (
    <div
      className={classes.root}
      onWheel={(e) => {
        e.persist()
        onWheelSlider(e)
      }}
    >
      <StyledTitleBox>
        {icon}
        <Typography className={classes.title} fontWeight={theme.typography.fontWeightSemiBold}>
          {title}
        </Typography>
      </StyledTitleBox>
      <IconButton disabled={!(currentSlide > 0)} onClick={previous} className={classes.previous} size="small">
        <FontAwesomeIcon icon={faChevronLeft as IconProp} />
      </IconButton>
      <IconButton
        disabled={!(currentSlide < children.length - numOfSlides + 1)}
        onClick={next}
        className={classes.next}
        size="small"
      >
        <FontAwesomeIcon icon={faChevronRight as IconProp} />
      </IconButton>

      <div>
        <SlickSlider
          ref={sliderRef}
          {...sliderSettings}
          // slidesToShow={Math.min(numOfSlides, children.length)}
          {...sliderProps}
          className={classes.sliderContainer}
          afterChange={afterChange}
        >
          {children}
        </SlickSlider>
      </div>
    </div>
  )
}

const StyledTitleBox = styled(Box)(() => ({
  display: 'flex',
  gap: '4px',
}))

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(0, 4),
      position: 'relative',
      '& .MuiIconButton-root.Mui-disabled': {
        opacity: 0.2,
      },
    },
    next: {
      position: 'absolute',
      right: 10,
      top: '45%',
    },
    previous: {
      position: 'absolute',
      left: 10,
      top: '45%',
    },
    title: {
      marginBottom: theme.spacing(-2.5),
    },
    sliderContainer: {
      height: 250,
      '& .slick-list': {
        padding: theme.spacing(2, 0, 1),
      },
    },
  })
)
export default Carousel
