import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ReportsAnalyticsObject } from '../../api/reports/reports-analytics'
import { secondsToTimestamp } from '../../common/utils/secondsToTimestamp'
import CircularProgressWithLabel from '../../common/components/CircularProgressWithLabel/CircularProgressWithLabel'
import ReportsChart from './ReportsChart'

export default function ReportsAnalytics({ data }: { data: ReportsAnalyticsObject }) {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <div className={classes.total}>
          <Typography variant={'h4'} className={classes.stat}>
            {data?.total_reports}
          </Typography>
          <Typography variant={'subtitle1'} className={classes.label}>
            {t('responseConsole.analytics.total')}
          </Typography>
        </div>
        <div className={classes.malicious}>
          <Typography variant={'h4'} className={classes.stat}>
            {data?.malicious_reports}
          </Typography>
          <Typography variant={'subtitle1'} className={classes.label}>
            {t('responseConsole.analytics.malicious')}
          </Typography>
        </div>
        <div className={classes.response}>
          <Typography variant={'h4'} className={classes.stat}>
            {secondsToTimestamp(data?.average_response)}
          </Typography>
          <Typography variant={'subtitle1'} className={classes.label}>
            {t('responseConsole.analytics.avgResponse')}
          </Typography>
        </div>
        <div className={classes.accuracy}>
          <CircularProgressWithLabel size={55} className={classes.progressIndicator} value={data.report_accuracy} />
          <div>
            <Typography variant={'body1'} className={classes.label}>
              {t('responseConsole.analytics.reporting')}
            </Typography>
            <Typography variant={'body1'} className={classes.label}>
              {t('responseConsole.analytics.accuracy')}
            </Typography>
          </div>
        </div>
      </div>
      <div className={classes.chartContainer}>
        <ReportsChart data={data} />
      </div>
    </Paper>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: 250,
      width: '100%',
      position: 'relative',
      padding: theme.spacing(2, 0),
    },
    header: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    total: {
      display: 'flex',
      alignItems: 'flex-end',
      marginRight: theme.spacing(4),
      color: theme.palette.blueGray[900],
    },
    malicious: {
      display: 'flex',
      alignItems: 'flex-end',
      marginRight: theme.spacing(4),
      color: theme.palette.error.main,
    },
    response: {
      display: 'flex',
      alignItems: 'flex-end',
      marginRight: theme.spacing(4),
      color: theme.palette.text.primary,
    },
    accuracy: {
      display: 'flex',
      alignItems: 'flex-end',
      marginRight: theme.spacing(4),
    },
    progressIndicator: {
      marginRight: theme.spacing(1),
    },
    stat: {
      marginRight: theme.spacing(1),
    },
    label: {},
    chartContainer: {
      height: 160,
      width: '100%',
    },
  })
)
