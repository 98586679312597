import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import CustomBreadcrumbs from '@/common/components/Breadcrumbs/Breadcrumbs'
import Button from '@mui/material/Button'
import { useTranslation } from 'react-i18next'

interface VideoPageTopBarProps {
  onLaunch?: () => void
  onSave?: () => void
  loading?: boolean
}
function VideoPageTopBar({ onLaunch, onSave, loading }: VideoPageTopBarProps) {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <AppBar position="static" className={classes.appBar} elevation={0}>
      <Toolbar className={classes.toolbar}>
        <CustomBreadcrumbs />
        <div>
          {onLaunch && !loading ? (
            <Button
              disableElevation
              onClick={(e) => {
                onLaunch()
              }}
              variant={'contained'}
            >
              {t('videoLibrary.launch')}
            </Button>
          ) : (
            <></>
          )}

          {onSave && !loading ? (
            <Button
              disableElevation
              onClick={(e) => {
                onSave()
              }}
              variant={'outlined'}
              size="small"
              className={classes.saveButton}
            >
              {t('createVideoModal.save')}
            </Button>
          ) : (
            <></>
          )}
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default VideoPageTopBar

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      marginTop: theme.spacing(-2),
      marginBottom: theme.spacing(2),
      height: 66,
      width: '100%',
      top: 0,
      left: 0,
      background: theme.palette.background.default,
      border: 'none',
    },
    toolbar: {
      minHeight: 66,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    saveButton: {
      color: theme.palette.common.black,
      borderColor: theme.palette.common.black,
      fontWeight: 'normal',
      padding: theme.spacing(0, 2),
      fontSize: 18,
    },
  })
)
