import { palette } from '../palette'

export const MuiMenuItem = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      '&:hover': {
        background: palette.blueDianne[50],
      },
      '&:active': {
        background: palette.blueDianne[100],
      },
    },
  },
}
