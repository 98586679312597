import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faX } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LoadingButton from '@mui/lab/LoadingButton'
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment-timezone'
import { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useCampaignCreate } from '@/api/campaigns/create'
import { useClientDetails } from '@/api/client/client'
import { ISO_DATE_TIME } from '@/api/constants'
import { EducationModule } from '@/api/education-modules/modules'
import { Group } from '@/api/groups/groups'
import { Office, useOffices } from '@/api/offices/offices'
import { User } from '@/api/users/users'
import { getErrorMessage } from '@/api/utils/get-error'
import scoreMax from '@/assets/images/popup/score-max.svg'
import packageLaunchAnimationData from '@/assets/lottie/rocketsUnpackaged.json'
import FeedbackAnimation from '@/common/animations/FeedbackAnimation'
import useToast from '@/common/hooks/useToast'
import { AssetAssignmentFallback, AssetAssignmentType, AssetType, PER_USER, Targets } from '@/types/campaigns'
import { DynamicGroup } from '@/types/dynamicGroups'
import { PhishingSimulation } from '@/types/phishingSimulations'
import Popup from '../Popup'
import CampaignDetails from './CampaignDetails'

export type AutoCompleteItem = {
  _id: string
  label: string
  type: 'users' | 'groups' | 'offices'
}

type LaunchWizardProps = {
  open: boolean
  onClose: () => void
  campaignType?: AssetType
  assets: PhishingSimulation[] | EducationModule[]
  packageName?: string
  launchAnimation?: boolean
}

const generateDates = () => {
  const startDate = new Date(new Date().getTime() + 5 * 60 * 1000)
  const endDate = new Date(startDate.getTime() + 7 * 24 * 60 * 60 * 1000)

  return {
    start: startDate,
    end: endDate,
    span: 0,
    timezone: PER_USER,
  }
}

const INITIAL_ASSSET_METADATA = {
  isDemo: false,
  includeQuiz: true,
  preferUserLanguage: true,
  redirectUrl: '',
}
const INITIAL_RECIPIENTS = { offices: [], groups: [], users: [], dynamic_groups: [] }

const LaunchWizard: FC<LaunchWizardProps> = ({
  open,
  onClose,
  campaignType = AssetType.training_video,
  assets,
  packageName,
  launchAnimation,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { errorToast, successToast } = useToast()
  const { data: officesData } = useOffices()
  const [campaignName, setCampaignName] = useState<string>('')
  const [selectedRecipients, setSelectedRecipients] = useState<{
    offices: Office[]
    groups: Group[]
    users: User[]
    dynamic_groups: DynamicGroup[]
  }>(INITIAL_RECIPIENTS)
  const [dates, setDates] = useState(generateDates)
  const [assetMetadata, setAssetMetadata] = useState(INITIAL_ASSSET_METADATA)
  const [errors, setErrors] = useState<{ [key: string]: any }>({})
  const [warginModalOpen, setWarginModalOpen] = useState(false)
  const navigateTo = useNavigate()
  const [distributionTargets, setDistributionTargets] = useState<Targets>({
    groups: {},
    dynamic_groups: {},
    offices: {},
    users: {},
  })
  const [customizeMicroTraining, setCustomizeMicroTraining] = useState({})

  const validateStartTime = useCallback(
    (officeIds) => {
      if (!officesData) return false

      // if (errors.dates) return errors.dates;

      const timeZones = officeIds.map((officeId) => officesData.byId[officeId].time_zone)
      if (dates.timezone === PER_USER) {
        const passedTimezones = timeZones.filter((tz) => !moment(dates.start).tz(tz, true).isAfter())

        if (passedTimezones.length > 0) {
          return t('launchWizard.errors.timezoneMismatch', {
            count: passedTimezones.length,
            timezones: passedTimezones.join(', '),
          })
        }
      }
      return false
    },
    [dates.start, dates.timezone, officesData, t, errors.dates]
  )

  const validateLaunchWizardInput = () => {
    let hasErrors = false
    const officeIds = [
      ...selectedRecipients.users.map((user) => user.office_id),
      ...selectedRecipients.groups.flatMap((group) => group.offices),
      ...selectedRecipients.offices.map((office) => office._id),
    ]
    const datesError = validateStartTime(officeIds)

    if (datesError) {
      setErrors((prevState) => ({
        ...prevState,
        dates: datesError,
      }))
      hasErrors = true
    }
    if (!campaignName) {
      setErrors((prevState) => ({
        ...prevState,
        campaignName: t('launchWizard.errors.missingCampaignName'),
      }))
      hasErrors = true
    }
    if (
      [
        ...selectedRecipients.users,
        ...selectedRecipients.groups,
        ...selectedRecipients.offices,
        ...selectedRecipients.dynamic_groups,
      ].length < 1
    ) {
      setErrors((prevState) => ({
        ...prevState,
        recipients: t('launchWizard.errors.missingRecipients'),
      }))
      hasErrors = true
    }
    return hasErrors
  }

  const resetFields = () => {
    setCampaignName('')
    setSelectedRecipients(INITIAL_RECIPIENTS)
    setDates(generateDates)
    setAssetMetadata(INITIAL_ASSSET_METADATA)
    setErrors({})
  }

  const handleClose = () => {
    resetFields()
    onClose()
  }

  useEffect(() => {
    if (open) {
      setDates(generateDates)
    }
  }, [open, generateDates])

  const { data: clientDetails } = useClientDetails()
  const { mutateAsync: createCampaign, isPending } = useCampaignCreate()
  const handleLaunchCampaign = async () => {
    const isPackage = assets && assets.length > 1

    if (!assets || assets?.length < 1) {
      errorToast(t('launchWizard.errors.noAsset'))
      return
    }

    const { users, offices, groups, dynamic_groups } = selectedRecipients
    const userIds = users.map((user) => user._id)
    const officeIds = offices.map((office) => office._id)
    const groupIds = groups.map((group) => group._id)
    const dynamicGroupIds = dynamic_groups.map((dynaicGroup) => dynaicGroup.id)
    const start = moment(dates.start)
    const end = moment(dates.end)
    const hasErrors = validateLaunchWizardInput()

    const totalDistributionTargets = Object.keys({
      ...distributionTargets.groups,
      ...distributionTargets.dynamic_groups,
      ...distributionTargets.offices,
      ...distributionTargets.users,
    }).length

    const distributionTargetsClened = { ...distributionTargets } as Targets
    for (const recepientsType in distributionTargetsClened) {
      // If recepientType is empty, remove it from distributionTargets
      if (Object.keys(distributionTargetsClened[recepientsType as keyof Targets] || {}).length === 0) {
        delete distributionTargetsClened[recepientsType as keyof Targets]
      } else {
        // Loop throught the distributionTargets and remove the unselected distribution targets
        Object.keys(distributionTargetsClened[recepientsType as keyof Targets]).forEach((id) => {
          if (!selectedRecipients[recepientsType as keyof Targets].find((item) => item._id === id || item.id === id)) {
            delete distributionTargetsClened[recepientsType as keyof Targets][id]
          }
        })
      }
    }

    const asset_assignment_logic =
      totalDistributionTargets > 0
        ? {
            type: AssetAssignmentType.targeted,
            targets: distributionTargetsClened,
            fallback: AssetAssignmentFallback.random_per_user,
          }
        : isPackage
        ? {
            type: AssetAssignmentType.random_per_user,
          }
        : {
            type: AssetAssignmentType.single_random,
          }

    if (hasErrors) {
      errorToast(t('launchWizard.errors.missingFields'))
      return
    }

    try {
      const data = await createCampaign({
        name: campaignName,
        users: userIds,
        offices: officeIds,
        groups: groupIds,
        dynamic_groups: dynamicGroupIds,
        asset_type: campaignType,
        asset_ids: assets.map((asset) => asset.id || asset._id),
        asset_metadata: {
          include_quiz:
            campaignType !== AssetType.training_video
              ? undefined
              : assets[0].quizzes?.length // Debug this
              ? assetMetadata.includeQuiz
              : false,
          language: assetMetadata.preferUserLanguage ? PER_USER : undefined,
          redirect_url: assetMetadata.redirectUrl,
        },
        asset_assignment_logic: asset_assignment_logic,
        schedule: {
          start: start.format(ISO_DATE_TIME),
          end: end.format(ISO_DATE_TIME),
          end_tz: moment.tz.guess(),
          start_tz: dates.timezone,
          send_on_start: dates.span === 0,
          send_over_days: dates.span,
        },
        is_test: assetMetadata.isDemo,
        custom_microtraining_urls: customizeMicroTraining,
      })

      handleClose()

      successToast(t('launchWizard.success'))

      const { _id, asset_type } = data
      if (asset_type === AssetType.training_video) {
        navigateTo(`/training-campaigns/${_id}`)
      } else {
        navigateTo(`/campaign/${_id}`)
      }
    } catch (e: any) {
      //TODO: After backend provides error code we should update this
      if (e.response.data.message === 'Campaign limit exceeded') {
        setWarginModalOpen(true)
      } else {
        const errorMessage = getErrorMessage(e)
        errorToast(errorMessage || t('launchWizard.errors.failedToLaunch'))
      }
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose()
          }
        }}
        maxWidth={'xl'}
        fullWidth
        scroll={'body'}
        className={classes.dialog}
        sx={{ height: '100vh', overflow: 'scroll' }}>
        <DialogTitle className={classes.header}>
          <div className={classes.flex}>
            <IconButton className={classes.closeButton} onClick={handleClose} size="small">
              <FontAwesomeIcon icon={faX as IconProp} />
            </IconButton>
            <Typography variant="h6" color="inherit">
              {t('launchWizard.title')}
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent className={classes.root}>
          <Grid container spacing={2} style={{ padding: '20px 30px' }}>
            <Grid item xs={12}>
              <CampaignDetails
                assets={assets}
                dates={dates}
                setDates={setDates}
                setRecipients={setSelectedRecipients}
                campaignName={campaignName}
                setCampaignName={setCampaignName}
                assetMetadata={assetMetadata}
                setAssetMetadata={setAssetMetadata}
                distributionTargets={distributionTargets}
                setDistributionTargets={setDistributionTargets}
                errors={errors}
                setErrors={setErrors}
                campaignType={campaignType}
                packageName={packageName}
                customizeMicroTraining={customizeMicroTraining}
                setCustomizeMicroTraining={setCustomizeMicroTraining}
              />
            </Grid>
          </Grid>
          <div className={classes.buttonContainer}>
            <LoadingButton variant="contained" size="medium" onClick={handleLaunchCampaign} loading={isPending}>
              {t('launchWizard.launch')}
            </LoadingButton>
          </div>
        </DialogContent>
        {launchAnimation && <FeedbackAnimation animationData={packageLaunchAnimationData} />}
      </Dialog>

      <Popup
        open={warginModalOpen}
        onClose={() => setWarginModalOpen(false)}
        onConfirm={() => setWarginModalOpen(false)}
        buttonLabels={{
          confirm: t('users.campaignQuotePopup.confirmButton'),
          cancel: t('users.campaignQuotePopup.cancelButton'),
        }}
        icon={scoreMax}>
        <h4>{t('users.campaignQuotePopup.title')}</h4>
        <p>
          {t('users.campaignQuotePopup.message', {
            parentAccount: clientDetails?.name,
          })}
        </p>
      </Popup>
    </>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      padding: theme.spacing(1, 4, 4),
    },
    dialog: {
      // position: "relative",
      '& .MuiDialog-paper': {
        border: 'none',
      },
    },
    header: {
      backgroundColor: theme.palette.blueGray[900],
      color: theme.palette.cyan[500],
      padding: theme.spacing(1),
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
    },
    closeButton: {
      marginRight: theme.spacing(1),
      color: theme.palette.cyan[500],
    },
    buttonContainer: {
      padding: theme.spacing(0, 0, 0, 22),
      position: 'absolute',
      bottom: 40,
      right: theme.spacing(7),
      background: 'linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%)',
    },
    submitButton: {
      width: 120,
    },
  })
)

export default LaunchWizard
