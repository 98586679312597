// from MUI colors
const white = '#ffffff'
const black = '#000000'
const grey = {
  50: '#FAFAFA',
  100: '#F5F5F5',
  200: '#EEEEEE',
  300: '#E0E0E0',
  400: '#BDBDBD',
  500: '#9E9E9E',
  600: '#757575',
  700: '#616161',
  800: '#424242',
  900: '#212121',
}

const blue = {
  50: '#E3F2FD',
  100: '#BBDEFB',
  200: '#90CAF9',
  300: '#64B5F6',
  400: '#42A5F5',
  500: '#2196F3',
  600: '#1E88E5',
  700: '#1976D2',
  800: '#1565C0',
  900: '#0D47A1',
}

const red = {
  50: '#FFEBEE',
  100: '#FFCDD2',
  200: '#EF9A9A',
  300: '#E57373',
  400: '#EF5350',
  500: '#F44336',
  600: '#E53935',
  700: '#D32F2F',
  800: '#C62828',
  900: '#B71C1C',
}

const deepPurple = {
  50: '#EDE7F6',
  100: '#D1C4E9',
  200: '#B39DDB',
  300: '#9575CD',
  400: '#7E57C2',
  500: '#673AB7',
  600: '#5E35B1',
  700: '#512DA8',
  800: '#4527A0',
  900: '#311B92',
}

const indigo = {
  50: '#E8EAF6',
  100: '#C5CAE9',
  200: '#9FA8DA',
  300: '#7986CB',
  400: '#5C6BC0',
  500: '#3F51B5',
  600: '#3949AB',
  700: '#303F9F',
  800: '#283593',
  900: '#1A237E',
}

const purple = {
  50: '#F3E5F5',
  100: '#E1BEE7',
  200: '#CE93D8',
  300: '#BA68C8',
  400: '#AB47BC',
  500: '#9C27B0',
  600: '#8E24AA',
  700: '#7B1FA2',
  800: '#6A1B9A',
  900: '#4A148C',
}

const pink = {
  50: '#FCE4EC',
  100: '#F8BBD0',
  200: '#F48FB1',
  300: '#F06292',
  400: '#EC407A',
  500: '#E91E63',
  600: '#D81B60',
  700: '#C2185B',
  800: '#AD1457',
  900: '#880E4F',
}

const amber = {
  50: '#FFF8E1',
  100: '#FFECB3',
  200: '#FFE082',
  300: '#FFD54F',
  400: '#FFCA28',
  500: '#FFC107',
  600: '#FFB300',
  700: '#FFA000',
  800: '#FF8F00',
  900: '#FF6F00',
}

const green = {
  50: '#E8F5E9',
  100: '#C8E6C9',
  200: '#A5D6A7',
  300: '#81C784',
  400: '#66BB6A',
  500: '#4CAF50',
  600: '#43A047',
  700: '#388E3C',
  800: '#2E7D32',
  900: '#1B5E20',
}

const lime = {
  50: '#F9FBE7',
  100: '#F0F4C3',
  200: '#E6EE9C',
  300: '#DCE775',
  400: '#D4E157',
  500: '#CDDC39',
  600: '#C0CA33',
  700: '#AFB42B',
  800: '#9E9D24',
  900: '#827717',
}

// const brown = {
//   50: '#EFEBE9',
//   100: '#D7CCC8',
//   200: '#BCAAA4',
//   300: '#A1887F',
//   400: '#8D6E63',
//   500: '#795548',
//   600: '#6D4C41',
//   700: '#5D4037',
//   800: '#4E342E',
//   900: '#3E2723',
// }

// const deepOrange = {
//   50: '#FBE9E7',
//   100: '#FFCCBC',
//   200: '#FFAB91',
//   300: '#FF8A65',
//   400: '#FF7043',
//   500: '#FF5722',
//   600: '#F4511E',
//   700: '#E64A19',
//   800: '#D84315',
//   900: '#BF360C',
// }

// const yellow = {
//   50: '#FFFDE7',
//   100: '#FFF9C4',
//   200: '#FFF59D',
//   300: '#FFF176',
//   400: '#FFEE58',
//   500: '#FFEB3B',
//   600: '#FDD835',
//   700: '#FBC02D',
//   800: '#F9A825',
//   900: '#F57F17',
// }

// Custom and override colors:
const blueGray = {
  50: '#E9EEF5',
  100: '#CFDCE8',
  200: '#A4BDD5',
  300: '#739ABD',
  400: '#517FA6',
  500: '#3E658B',
  600: '#335171',
  700: '#2E465E',
  800: '#2A3C50',
  900: '#141C26',
}
const cyan = {
  50: '#E1FBFD',
  100: '#B4F4FB',
  200: '#82EDF9',
  300: '#50E6F6',
  400: '#2BE0F4',
  500: '#05DBF2',
  600: '#04D7F0',
  700: '#04D2EE',
  800: '#03CDEC',
  900: '#01C4E8',
}
const teal = {
  50: '#E1FCFB',
  100: '#B5F8F6',
  200: '#83F3F0',
  300: '#51EEE9',
  400: '#2CEBE5',
  500: '#07E7E0',
  600: '#06E4DC',
  700: '#05E0D8',
  800: '#04DDD3',
  900: '#02D7CB',
}

const lightBlue = {
  50: '#E1F5FE',
  100: '#B3E5FC',
  200: '#81D4FA',
  300: '#4FC3F7',
  400: '#29B6F6',
  500: '#03A9F4',
  600: '#039BE5',
  700: '#0288D1',
  800: '#0277BD',
  900: '#01579B',
}
const blueDianne = {
  50: '#F0FBFB',
  100: '#DAF3F3',
  200: '#B9E7E8',
  300: '#88D6D8',
  400: '#50BBC0',
  500: '#349FA6',
  600: '#2E838C',
  700: '#2B6A73',
  800: '#2B585F',
  900: '#294D55',
}
const orange = {
  50: '#FEF1E0',
  100: '#FDDBB3',
  200: '#FCC380',
  300: '#FAAB4D',
  400: '#F99926',
  500: '#F88700',
  600: '#F77F00',
  700: '#F67400',
  800: '#F56A00',
  900: '#F35700',
}
const lightGreen = {
  50: '#F8FEF1',
  100: '#EDFEDB',
  200: '#E1FDC3',
  300: '#D4FCAB',
  400: '#CBFB99',
  500: '#C2FA87',
  600: '#BCF97F',
  700: '#B4F974',
  800: '#ACF86A',
  900: '#9FF657',
}

export const palette = {
  // colors
  lightGreen,
  orange,
  blueDianne,
  lightBlue,
  teal,
  cyan,
  blueGray,
  amber,
  pink,
  purple,
  indigo,
  deepPurple,
  red,
  blue,
  grey,
  lime,
  green,
  black,
  white,

  //link is free - not in use
  link: {
    main: blue[900],
  },
  common: { black: black, white: white, focusBackground: grey[400] }, // remove focusBackground: grey[400]
  background: {
    paper: white,
    default: white,
    dark: blueGray[900],
    logo: cyan[500],
    codeBlock: blueGray[50],
    secondaryGradient: 'linear-gradient(136.67deg, rgba(70, 78, 147, 0.8) 8.34%, rgba(42, 38, 137, 0.8) 95.26%)',
    successGradient: 'linear-gradient(246.26deg,  #00b09b, #96c93d)',
    errorGradient: 'linear-gradient(246.26deg, #cb2d3e, #ef473a)',
    warningGradient: 'linear-gradient(246.26deg, #ffb75e, #ed8f03)',
    neutralGradient:
      'linear-gradient(180deg, #464E93 0%, rgba(173, 89, 164, 0.441435) 99.99%, rgba(255, 98, 177, 0.0001) 100%)',
  },
  divider: 'rgba(0,0,0,0.1)',
  //primary is free - not in use
  primary: {
    light: grey[800],
    main: blueGray[900],
    dark: black,
    contrastText: cyan[500],
  },
  //secondary is free - not in use
  secondary: {
    light: cyan[300],
    main: cyan[500],
    dark: blueDianne[600],
    contrastText: black,
  },
  error: {
    light: purple[100],
    main: red[900],
    dark: pink[800],
    contrastText: white,
  },
  success: {
    light: '#2dce9f',
    main: '#2bb596',
    dark: '#008468',
    contrastText: black,
  },
  warning: {
    light: amber[300],
    main: orange[400],
    dark: amber[700],
    contrastText: black,
  },
  text: {
    primary: blueDianne[900],
    disabled: '#858f9f',
    code: '#ea205c',
  },
}
