export const SENDER_POSITION = [
  "CEO",
  "HR Director",
  "IT Support",
  "Customer Support",
  "Marketing Director",
  "Sales Representative",
  "Financial Advisor",
  "Project Manager",
  "Operations Manager",
];
