import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useMembersUpload, { UploadMembersRequest } from '@/api/members/upload'
import { getErrorMessage } from '@/api/utils/get-error'
import Modal from '@/common/components/Modal'
import useToast from '@/common/hooks/useToast'
import { BulkUploadConflict, UploadMemberConflictResolutionStrategy } from '@/types/members'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import Step5 from './Step5'

type BulkUserUploadModalProps = {
  open: boolean
  onClose: () => void
}

export type FileError = {
  code: 'format_error' | 'wrong_headers' | 'empty_file' | 'file_max'
  title: string
  message: string
}

enum ValidationErrorsTypes {
  missing_required_field = 'missing_required_field',
  group_not_found = 'group_not_found',
  office_not_found = 'office_not_found',
  duplicate_email = 'duplicate_email',
  invalid_email = 'invalid_email',
  invalid_phone_number = 'invalid_phone_number',
  format_error = 'format_error',
}

type ValidationError = {
  [key in ValidationErrorsTypes]?: any
}

export type ConflictResolution = {
  strategy: UploadMemberConflictResolutionStrategy | undefined
  members_to_keep: string[]
  members_to_overwrite: string[]
}

export type ImportStats = {
  active_imported_count: number
  inactive_imported_count: number
  active_newly_imported_count: number
  inactive_newly_imported_count: number
  imported_by_domain: { domain: string; count: number; verified: boolean }[]
}

const BulkUserUploadModal: FC<BulkUserUploadModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation()
  const [step, setStep] = useState(1)
  const [csvFile, setCsvFile] = useState<any>()
  const [conflictResolution, setConflictResolution] = useState<ConflictResolution>({
    strategy: undefined,
    members_to_keep: [],
    members_to_overwrite: [],
  })

  const [conflicts, setConflicts] = useState<BulkUploadConflict[]>([] as BulkUploadConflict[])
  const [importStats, setImportStats] = useState<ImportStats>()
  const [formatErrors, setFormatErrors] = useState<ValidationError>([] as ValidationError)

  const { mutateAsync: uploadMembers, isPending } = useMembersUpload()
  const { successToast } = useToast()

  const handleClose = () => {
    onClose()
    setStep(1)
    setConflictResolution({ strategy: undefined, members_to_keep: [], members_to_overwrite: [] })
  }

  const fileUpload = async (dryRun = true, withResolutionStrategy = true) => {
    try {
      const payload = { dry_run: dryRun, file: csvFile } as UploadMembersRequest
      if (withResolutionStrategy) {
        payload['conflict_resolution_strategy'] = conflictResolution.strategy
        payload['members_to_keep'] = conflictResolution.members_to_keep
        payload['members_to_overwrite'] = conflictResolution.members_to_overwrite
      }

      const response = await uploadMembers(payload)

      setFormatErrors(response.errors)
      setImportStats(response.stats)
      setConflicts(response.conflicts)

      if (!dryRun) {
        onClose()
        setStep(1)
        successToast('Members uploaded successfully')
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      setFormatErrors({ format_error: errorMessage })
    }
  }

  return (
    <Modal
      isOpen={open}
      title={t('bulkUserUpload.modalTitle', { index: step, title: t(`bulkUserUpload.titleStep${step}`) })}
      onClose={handleClose}
      size="large">
      {/* Step 1 */}
      {step === 1 && (
        <Step1 setCsvFile={setCsvFile} fileUpload={fileUpload} handleClose={handleClose} setStep={setStep} />
      )}
      {/* Step 1 */}

      {/* Step 2 */}
      {step === 2 && (
        <Step2 formatErrors={formatErrors} isLoading={isPending} handleClose={handleClose} setStep={setStep} />
      )}
      {/* Step 2 */}

      {/* Step 3 */}
      {step === 3 && (
        <Step3
          conflicts={conflicts}
          conflictResolution={conflictResolution}
          setConflictResolution={setConflictResolution}
          handleClose={handleClose}
          setStep={setStep}
        />
      )}
      {/* Step 3 */}

      {/* Step 4 */}
      {step === 4 && (
        <Step4
          conflicts={conflicts}
          conflictResolution={conflictResolution}
          handleClose={handleClose}
          setStep={setStep}
          isLoading={isPending}
          fileUpload={fileUpload}
          importedDomain={importStats?.imported_by_domain || []}
        />
      )}
      {/* Step 4 */}

      {/* Step 5 */}
      {step === 5 && (
        <Step5 fileUpload={fileUpload} isLoading={isPending} handleClose={handleClose} importStats={importStats} />
      )}
      {/* Step 5 */}
    </Modal>
  )
}

export default BulkUserUploadModal
