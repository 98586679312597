import axios from "axios";
import { API_URL } from "../constants";
import { useMutation, useQueryCache } from "react-query";

async function logout() {
  const url = `${API_URL}/auth/logout/`;
  await axios.post(url, {}, { withCredentials: true });
}

export function useLogout() {
  const queryCache = useQueryCache();
  return useMutation(logout, {
    onSuccess: () => {
      queryCache.invalidateQueries();
      queryCache.clear();
    },
  });
}
